import React, { useState, memo } from "react";
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  handleFocus,
} from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
// CheckCircleOutlined
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { validationHandle } from "../fieldmap";
// import { QrCodeScanner } from "@mui/icons-material";
import QrCode from "./QrCode";

const HISInput = ({
  data,
  step,
  value,
  status,
  loading,
  handleClick,
}: {
  data: Field;
  step: string;
  value: string;
  status: boolean;
  loading: boolean;
  handleClick?: () => void;
}) => {
  const dispatch = useDispatch();
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const { focus } = useAppSelector((state: any) => state.inspection);
  const [fieldValue, setFieldValue] = useState<string>(value);
  const [scan, setScan] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<string>("");

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset]);

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
    dispatch(handleFocus(!focus));
    if (handleClick) {
      handleClick();
    }
  };

  const handleInputChange = (event: any) => {
    if (data.validate) {
      const { isError, infoDetails } = validationHandle(
        data.validate,
        event.target.value,
        data
      );
      setIsError(isError);
      setFieldInfo(infoDetails);
    }
    setFieldValue(event.target.value);
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
        {data.validate && fieldValue && (
          <Tooltip
            className={`sm-tootip-true ${isError ? "sm-tootip-false" : ""}`}
            title={fieldInfo}
            arrow
          >
            <Button>
              {isError ? (
                <ExclamationCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )}
            </Button>
          </Tooltip>
        )}
      </InputLabel>
      {/* <div style={{ display: "flex", gap: "5px" }}> */}
      <TextField
        disabled={!status}
        id={data.key}
        value={fieldValue}
        placeholder="Enter Value"
        onChange={handleInputChange}
      />
      {/* <QrCodeScanner
          onClick={() => setScan(!scan)}
          style={{ height: "100%", marginBlock: "auto", cursor: "pointer" }}
        />
      </div> */}
      {!loading ? (
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={fieldValue ? false : true}
        >
          Save
        </Button>
      ) : (
        <Button variant="contained">
          <span className="report_loader"></span>Loading
        </Button>
      )}
      <QrCode open={scan} setOpen={setScan} setModuleId={setFieldValue} />
    </FormControl>
  );
};

export default memo(HISInput);
