import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, FormControl, Input, InputLabel, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import * as XLSX from "xlsx";
import { CheckCircleOutline } from "@mui/icons-material";
import { fileReadValidateHandle } from "../fieldmap";

const FileReadField = ({
  data,
  step,
  value,
  status,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
}) => {
  const dispatch = useDispatch();
  const fileRef = React.useRef<any>(null);
  const [fieldValue, setFieldValue] = React.useState(value);
  const [fieldRowValue, setFieldRowValue] = React.useState(value);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = React.useState<string>("");
  const reset = useAppSelector((state: any) => state.inspection).toggle;

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset]);

  React.useEffect(() => {
    const validateData = data.file_validate;
    if (fieldRowValue && validateData) {
      const new_val = fieldRowValue.split("\n");
      let errMessage = "";
      let errorExist = false;
      for (let i = 0; i < validateData.length; i++) {
        const findEleINdex: number = data.read_field?.findIndex(
          (field: string) => validateData[i].field === field
        ) as number;
        const currentFieldVal = new_val[findEleINdex];
        const { isError, infoDetails } = fileReadValidateHandle(
          validateData[i],
          currentFieldVal,
          data.name
        );
        errMessage = errMessage + " " + infoDetails;
        if (isError) {
          errorExist = isError;
        }
      }

      setIsError(errorExist);
      setFieldInfo(errMessage);
    }
  }, [fieldRowValue]);

  const handleSave = () => {
    const new_val = fieldRowValue.split("\n");
    if (data.read_field) {
      for (let i = 0; i < data.read_field?.length; i++) {
        dispatch(
          handleFieldData({
            step: step,
            value: new_val[i],
            key: data.read_field[i],
          })
        );
      }
    }
  };

  const handleUpload = () => {
    if (fileRef) {
      fileRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files;
    if (file?.length > 0) {
      if (
        file[0]?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(event.target.value.toLowerCase())) {
          if (typeof FileReader !== "undefined") {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
              reader.onload = (e) => {
                setFieldRowValue(processExcel(reader.result));
              };
              reader.readAsBinaryString(file[0]);
            }
          } else {
            console.log("This browser does not support HTML5.");
          }
        } else {
          console.log("Please upload a valid Excel file.");
        }

        setFieldValue(file[0]);
      } else {
        return;
      }
    }
  };

  function processExcel(data: any) {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_csv(workbook.Sheets[firstSheet]);
    return excelRows;
  }

  const handleRemoveFile = () => {
    fileRef.current.value = null;
    setIsError(false);
    setFieldInfo("");
    setFieldValue(null);
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
        {data.file_validate && fieldRowValue && (
          <Tooltip
            className={`sm-tootip-true ${isError ? "sm-tootip-false" : ""}`}
            title={fieldInfo}
            arrow
          >
            <Button>
              {isError ? <ExclamationCircleOutlined /> : <CheckCircleOutline />}
            </Button>
          </Tooltip>
        )}
      </InputLabel>
      <input
        hidden
        type="file"
        id={data.key}
        onChange={handleFileUpload}
        ref={fileRef}
      />
      {fieldValue ? (
        <div>
          <p>{fieldValue.name}</p>
          <CloseCircleOutlined onClick={handleRemoveFile} />
        </div>
      ) : (
        <Button variant="contained" onClick={handleUpload} disabled={!status}>
          <CloudUploadOutlined />
          Upload
        </Button>
      )}
      <Button
        variant="contained"
        onClick={handleSave}
        disabled={fieldValue ? false : true}
      >
        Save
      </Button>
    </FormControl>
  );
};

export default memo(FileReadField);
