import React, { useEffect, useState, useCallback } from "react";
import { Button, FormControl } from "@mui/material";
import { getBinDetailsService } from "../../../../service/apiService";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import { successToast } from "../../serviceCallback";
import { toast } from "react-toastify";

export const CustomBinBtn = ({
  handleGetBinDetails,
  status,
  module_id,
}: {
  handleGetBinDetails: (value: any) => void;
  status: boolean;
  module_id: string;
}) => {
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const getBinDetails = () => {
    const authToken = localStorage.getItem("accessToken") || "";
    setLoading(true);
    const value = module_id;
    const trimmedValue = value.trim();
    getBinDetailsService(authToken, trimmedValue).then((res: any) => {
      console.log("%c Line:21 🍩 res", "color:#93c0a4", res);

      handleGetBinDetails(res.data);

      if (res.success) {
        if (res?.data?.data && Object.keys(res?.data?.data).length > 0) {
          successToast("Bin ID fetched successfully");
        } else {
          toast.error(`${module_id} has no data to show`, {
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      }
      if (res.status !== 200) {
        setErOpen(true);
        const new_err = [];
        new_err.push(res?.message?.message);
        setParamError([res?.message?.message]);
      }
      setLoading(false);
    });
  };
  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  return (
    <>
      <FormControl variant="standard" className="sm-form-control">
        {!loading ? (
          <Button variant="contained" onClick={getBinDetails} disabled={status}>
            Show Bin ID
          </Button>
        ) : (
          <Button variant="contained">
            <span className="report_loader"></span>Loading
          </Button>
        )}
      </FormControl>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />
    </>
  );
};
