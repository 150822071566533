import React, { useState } from "react";
import SopHeader from "./StepForms/sopHeader";
import GaugeChart from "react-gauge-chart";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";

const Assembler = () => {
  const [assembler, setAssembler] = useState();
  const [isShow, setIsShow] = useState(false);
  const [chart3] = useState({
    series: [
      {
        name: "Value",
        data: [18, 35, 29, 6],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Incoming Bins",
          "In-Progress",
          "Outgoing Count",
          "Modules Packaged",
        ],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Modules",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"Assembler Portal"} link={""} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box common common-kpi">
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">Assembler</InputLabel>
                <Select
                  variant="outlined"
                  value={assembler}
                  onChange={(e: any) => setAssembler(e?.target?.value)}
                >
                  <MenuItem value="RevPar">RevPar</MenuItem>
                  <MenuItem value="Precise">Precise</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (assembler) {
                      setIsShow(true);
                    }
                  }}
                >
                  Save
                </Button>
              </FormControl>
              {isShow && (
                <>
                  {" "}
                  <div
                    className="graph_heading"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div>
                      <h4 style={{ textAlign: "center", marginBottom: "12px" }}>
                        On Time Delivery
                      </h4>
                      <GaugeChart
                        colors={["#ee3838", "#f39a32", "#448615"]}
                        className="gauge-chart"
                        hideText={true}
                        percent={35 / 100}
                        cornerRadius={3}
                        arcPadding={0.02}
                        needleColor="#C1C1C3"
                        needleBaseColor="#C1C1C3"
                      />
                    </div>
                    <div>
                      <h4 style={{ textAlign: "center", marginBottom: "12px" }}>
                        Yield
                      </h4>
                      <GaugeChart
                        colors={["#ee3838", "#f39a32", "#448615"]}
                        className="gauge-chart"
                        hideText={true}
                        percent={75 / 100}
                        cornerRadius={3}
                        arcPadding={0.02}
                        needleColor="#C1C1C3"
                        needleBaseColor="#C1C1C3"
                      />
                    </div>
                  </div>
                  <div
                    className="graph_heading"
                    style={{ marginTop: "30px", justifyContent: "center" }}
                  >
                    <h4 style={{ textAlign: "center" }}>Inventory Snapshot</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid sm={12} md={12} xs={12} item>
                      {/* {loading3 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : ( */}
                      <ReactApexChart
                        options={chart3.options as any}
                        series={chart3.series as any}
                        type="bar"
                        height={350}
                      />
                      {/* )} */}
                    </Grid>
                  </Grid>
                </>
              )}
            </div>{" "}
          </div>
        </div>
        {isShow && (
          <>
            {" "}
            <div className="sm-stepForm-wrap">
              <div className="sm-colm-12">
                <div className="sm-commen-box common common-kpi">
                  <div className="graph_heading">
                    <h4>Quality</h4>
                  </div>

                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      Pack Build Rejects/Scrap
                    </InputLabel>
                    <Select variant="outlined">
                      <MenuItem value="Faulty parts from mold/fabricator supplier">
                        Faulty parts from mold/fabricator supplier
                      </MenuItem>
                      <MenuItem value="Bad steel">Bad steel</MenuItem>
                      <MenuItem value="Modules damaged during shipment">
                        Modules damaged during shipment
                      </MenuItem>
                    </Select>
                    <Button variant="contained">Save</Button>
                  </FormControl>
                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      High level view of our Module rejects and disposition
                    </InputLabel>
                    <TextField placeholder="Enter Value" />
                    <Button variant="contained">Save</Button>
                  </FormControl>
                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      Final QC check
                    </InputLabel>
                    <Select variant="outlined">
                      <MenuItem value="Voltage">Voltage</MenuItem>
                      <MenuItem value="OCV">OCV</MenuItem>
                      <MenuItem value="Visual">Visual</MenuItem>
                      <MenuItem value="Verify Communications">
                        Verify Communications
                      </MenuItem>
                    </Select>
                    <Button variant="contained">Save</Button>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="sm-stepForm-wrap">
              <div className="sm-colm-12">
                <div className="sm-commen-box common common-kpi">
                  <div className="graph_heading">
                    <h4>Inventory</h4>
                  </div>

                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      How many packs they have on site
                    </InputLabel>
                    <TextField placeholder="Enter Value" />
                    <Button variant="contained">Save</Button>
                  </FormControl>
                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      How much material they have on site to complete pack
                      builds
                    </InputLabel>
                    <TextField placeholder="Enter Value" />
                    <Button variant="contained">Save</Button>
                  </FormControl>

                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      KANBAN snapshot
                    </InputLabel>
                    <input
                      type="file"
                      style={{
                        border: "1px solid #c4c4c4",
                        // height: "1.4375em",
                        padding: "16.5px 10px",
                        borderRadius: "4px",
                        maxWidth: "250px",
                      }}
                    />
                    <Button variant="contained">Save</Button>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="sm-stepForm-wrap">
              <div className="sm-colm-12">
                <div className="sm-commen-box common common-kpi">
                  <div className="graph_heading">
                    <h4>Shipping</h4>
                  </div>

                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">
                      Customers
                    </InputLabel>
                    <Select variant="outlined">
                      <MenuItem value="OTD">OTD</MenuItem>
                      <MenuItem value="LVT">LVT</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                    <Button variant="contained">Save</Button>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="sm-stepForm-wrap">
              <div className="sm-colm-12">
                <div className="sm-commen-box common common-kpi">
                  <div className="graph_heading">
                    <h4>Purchase Orders</h4>
                  </div>

                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">Executed</InputLabel>
                    <TextField placeholder="Enter Value" />
                    <Button variant="contained">Save</Button>
                  </FormControl>
                  <FormControl variant="standard" className="sm-form-control">
                    <InputLabel htmlFor="component-simple">Pending</InputLabel>
                    <TextField placeholder="Enter Value" />
                    <Button variant="contained">Save</Button>
                  </FormControl>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default Assembler;
