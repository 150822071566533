import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { IoCloseSharp } from "react-icons/io5";
import moment from "moment";

const Detail = ({
  open,
  setOpen,
  data,
}: {
  open: boolean;
  data?: any;
  setOpen?: () => void;
}) => {
  const handleClose = () => {
    if (setOpen) {
      setOpen();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <h2>{data?.battery_module}</h2>
          <IoCloseSharp
            onClick={handleClose}
            style={{ cursor: "pointer", width: "26px", height: "26px" }}
          />
        </DialogTitle>
        <DialogContent>
          <div className="container-modal">
            <p>Battery Module :</p>
            <p>{data?.battery_module} </p>
          </div>
          <div className="container-modal">
            <p>Create Date :</p>
            <p>{moment(data?.createdAt).format("MM/DD/YYYY")} </p>
          </div>
          <div className="container-modal">
            <p>Completed Phase :</p>
            <p>{data?.completedPhase} </p>
          </div>
          <div className="container-modal">
            <p>OEM Serial Id :</p>
            <p>{data?.oem_serial_id} </p>
          </div>
          <div className="container-modal">
            <p>Module Thickness :</p>
            <p>{data?.module_thickness} </p>
          </div>
          <div className="container-modal">
            <p>Module Weight :</p>
            <p>{data?.weight} </p>
          </div>
          <div className="container-modal">
            <p>Test Channel ID ACIR :</p>
            <p>{data?.testIdAcir} </p>
          </div>
          <div className="container-modal">
            <p>Test Channel ID EIS :</p>
            <p>{data?.testIdEis} </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Detail;
