import React, { useState } from "react";
import { Button, Modal } from "@mui/material";
import QrReader from "react-qr-scanner";
type ModelProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setModuleId: (value: string) => void;
};

const QrCode: React.FC<ModelProps> = ({ open, setOpen, setModuleId }) => {
  const [data, setData] = useState<string | null>(null);

  const handleScan = (result: any | null) => {
    if (result) {
      //   setData(result?.text);
      setModuleId(result?.text);
      setOpen(false);
    }
  };

  const handleError = (error: any) => {
    console.error(error);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="general_popup validation_popup">
        <div className="modal_header">
          <h1>QR Reader</h1>
        </div>
        <QrReader
          onError={handleError}
          onScan={handleScan}
          delay={300}
          // width={300}
          // height={300}
          style={{ width: "300px" }}
        />
        <p>{data ? `Scanned Code: ${data}` : "No code scanned yet."}</p>
        <div className="modal_footer">
          <Button variant="contained" onClick={() => setOpen(false)}>
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default QrCode;
