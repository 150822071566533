import React from "react";
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";

export const ScanField = ({
  data,
  step,
  value,
  status,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
}) => {
  const [fieldValue, setFieldValue] = React.useState(value);
  const [isScannig, setIsScannig] = React.useState(false);
  const dispatch = useDispatch();
  const reset = useAppSelector((state: any) => state.inspection).toggle;

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
      setIsScannig(false);
    }
  }, [reset]);

  const generateRandomId = (): string => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 10;
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
      if (i === 4) {
        result += "-";
      }
    }
    return result;
  };

  const handleScan = () => {
    const randId = generateRandomId();
    setIsScannig(true);
    setTimeout(() => {
      dispatch(
        handleFieldData({
          step: step,
          value: randId,
          key: data.key,
        })
      );
      setFieldValue(randId);
      setIsScannig(false);
    }, 500);
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
      </InputLabel>
      {isScannig && (
        <p>
          <svg viewBox="25 25 50 50">
            <circle r="20" cy="50" cx="50"></circle>
          </svg>
        </p>
      )}
      {!isScannig && fieldValue && <p>{fieldValue}</p>}
      <Button variant="contained" onClick={handleScan} disabled={!status}>
        {data.name}
      </Button>
    </FormControl>
  );
};
