import React from "react";
import { Button, FormControl } from "@mui/material";
import { Field } from "../../types";

const SerialNumberGenerate = ({
  data,
  status,
  loading,
  onClick,
}: {
  data: Field;
  status: boolean;
  loading?: boolean;
  onClick?: () => void;
}) => {
  return (
    <>
      <FormControl variant="standard" className="sm-form-control">
        {!loading ? (
          <Button variant="contained" onClick={onClick} disabled={!status}>
            {data.name}
          </Button>
        ) : (
          <Button variant="contained">
            <span className="report_loader"></span>Loading
          </Button>
        )}
      </FormControl>
    </>
  );
};

export default React.memo(SerialNumberGenerate);
