import React, { useEffect, useState } from "react";
// import imag from "../../../../../assets/images/HIS1_1.jpg"
// import pallet_3 from "../../../../../assets/images/pallet_3.png"
// import passport1 from "../../../../../assets/images/passport1.jpg"
import passport2 from "../../../../../assets/images/passport2.jpg";
import new1 from "../../../../../assets/images/new1.jpeg";
import new2 from "../../../../../assets/images/new2.jpeg";
import new3 from "../../../../../assets/images/new3.jpeg";
import new4 from "../../../../../assets/images/new4.jpeg";
import intake from "../../../../../assets/images/intake.jpg";
// import n_battery from "../../../../../assets/images/n-battery.jpg"
import Footer from "./Footer";
import Header from "./Header";
import { useParams } from "react-router-dom";
import { getPassport } from "../../../../../service/apiService";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ReportPDF2 = () => {
  const { passport } = useParams();
  const [data, setData] = useState<any>([]);
  console.log("%c Line:23 🥚 data", "color:#e41a6a", data);

  const handlePassportData = () => {
    getPassport(passport)
      .then((res) => {
        console.log("%c Line:15 🥓 res", "color:#ffdd4d", res);
        if (res?.success) {
          setData(res?.data?.data);
        }
      })
      .catch((e) => console.log("e=>", e));
  };
  React.useEffect(() => {
    handlePassportData();
  }, []);

  const Image = () => {
    return (
      <div className="flex-row jusify-center" style={{ gap: "30px" }}>
        <img src={new1} alt="" style={{ width: "220px", height: "220px" }} />
        <img
          src={new2}
          alt=""
          className="passport-img"
          style={{ width: "220px", height: "220px" }}
        />
        <img
          src={new3}
          alt=""
          className="passport-img"
          style={{ width: "220px", height: "220px" }}
        />
        <img
          src={new4}
          alt=""
          className="passport-img"
          style={{ width: "220px", height: "220px" }}
        />
      </div>
    );
  };
  const TableTile = () => {
    return (
      <div className="flex-row jusify-center" style={{ gap: "60px" }}>
        {/* <div className="flex-col  w-50"> */}
        <div>
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#3498d2" }}>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    color: "white",
                    border: "2px solid #3498d2",
                    fontSize: "15px",
                  }}
                >
                  Health Indicator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  component="th"
                  scope="row"
                >
                  Parameter
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Units
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Make & Model
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  Nissan Gen 4
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  -
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Chemistry
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  Li-ion
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  -
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div>
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#3498d2" }}>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    color: "white",
                    border: "2px solid #3498d2",
                    fontSize: "15px",
                  }}
                >
                  Health Indicator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  component="th"
                  scope="row"
                >
                  Parameter
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Units
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Max Voltage
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  33.6
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  V
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Min Voltage
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  20
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  V
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div>
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#3498d2" }}>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    color: "white",
                    border: "2px solid #3498d2",
                    fontSize: "15px",
                  }}
                >
                  Health Indicator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  component="th"
                  scope="row"
                >
                  Parameter
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Units
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Nominal Voltage
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  28.8
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  V
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Rated Capacity
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  112
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  Ah
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };
  const Intake = () => {
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p
              className="headText packId"
              style={{
                width: "80%",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "700",
                color: "#3498d2",
                marginInline: "auto",
                marginTop: "25px",
              }}
            >
              Pack Id: {passport}
            </p>
            <Image />
            <TableTile />
            <div
              className="flex-col w-80 gap-8"
              style={{
                paddingTop: "15px",
                background: "#3498d2",
                minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                }}
              >
                Battery Inspection Intake
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                <div className="con">
                  <p className="bold">Reverse Logistics Supply Date</p>
                  <p className="bold">10/24/2023</p>
                </div>
                <div className="con">
                  <p className="bold">Inspection Site</p>
                  <p className="bold">Houston</p>
                </div>
                <div className="con">
                  <p className="bold">Incoming LOT ID</p>
                  <p className="bold">101823-AAU-AA</p>
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot Image</p>
                  <img
                    src={intake}
                    alt=""
                    className="passport-img"
                    style={{
                      width: "250px",
                      height: "200px",
                      marginInline: "auto",
                    }}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Reverse = () => {
    return (
      <>
        {" "}
        <div style={{ minHeight: "80vh", marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p
              className="headText"
              style={{
                width: "80%",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "700",
                color: "#3498d2",
                marginInline: "auto",
                marginTop: "25px",
              }}
            >
              Pack Id: {passport}
            </p>
            <Image />
            <TableTile />
            <div
              className="flex-col w-80 gap-8"
              style={{
                paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                }}
              >
                Battery Pack Reverse Logistics Intake
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "45vh",
                  background: "#f7f8fa",
                }}
              >
                <div className="con">
                  <p className="bold">Reverse Logistics Supply Date</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection Site</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot #</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot Image</p>
                  {/* <img
                  src={imag}
                  alt=""
                  className="passport-img"
                  style={{width: "300px", height: "300px"}}
                /> */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Inspection = () => {
    return (
      <>
        <div style={{ minHeight: "80vh", marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p
              className="headText"
              style={{
                width: "80%",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "700",
                color: "#3498d2",
                marginInline: "auto",
                marginTop: "25px",
              }}
            >
              Pack Id: {passport}
            </p>
            <Image />
            <TableTile />
            <div
              className="flex-col w-80 gap-8"
              style={{
                paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                }}
              >
                Battery Inspection Report
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  paddingBlock: "15px",
                  // background: "black",
                  minHeight: "45vh",
                  maxWidth: "full",
                  background: "#f7f8fa",
                }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        style={{
                          border: "2px solid #3498d2",
                          fontWeight: "600",
                          color: "black",
                        }}
                        component="th"
                        scope="row"
                      >
                        Module Id
                      </TableCell>
                      <TableCell
                        style={{
                          border: "2px solid #3498d2",
                          fontWeight: "600",
                          color: "black",
                        }}
                        align="right"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        style={{
                          border: "2px solid #3498d2",
                          fontWeight: "600",
                          color: "black",
                        }}
                        align="right"
                      >
                        Link
                      </TableCell>
                    </TableRow>{" "}
                    {data &&
                      data?.moduleids &&
                      data?.moduleids?.length > 0 &&
                      data?.moduleids?.map((obj: any) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                color: "black",
                              }}
                              component="th"
                              scope="row"
                            >
                              {obj?.battery_module}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                color: "black",
                              }}
                              align="right"
                            >
                              {data &&
                                moment(data?.timeOfHis6).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                color: "black",
                              }}
                              align="right"
                            >
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open(`${obj?.reportUrl}`, "_blank")
                                }
                              >
                                {" "}
                                {`${obj?.reportUrl}`}
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* <TableRow>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        component="th"
                        scope="row">
                        Voltage: Maximum
                      </TableCell>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        align="right">
                        8.4
                      </TableCell>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        align="right">
                        V
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        component="th"
                        scope="row">
                        Voltage: Nominal
                      </TableCell>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        align="right">
                        7.2
                      </TableCell>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        align="right">
                        V
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        component="th"
                        scope="row">
                        Voltage: Minimum
                      </TableCell>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        align="right">
                        5
                      </TableCell>
                      <TableCell
                        style={{border: "2px solid #3498d2"}}
                        align="right">
                        V
                      </TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
                {/* <div className="con">
                  <p className="bold">Serial Number</p>
                  <p className="bold">{data && data?.pack_id} </p>
                </div>
                <div className="con">
                  <p className="bold">Inspection Completion Date</p>
                  <p className="bold">02/03/2024</p>
                </div>
                <div className="con">
                  <p className="bold">Inspection Report Link</p>
                  <p className="bold">https://samsar.com/</p>
                </div> */}
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Assembly = () => {
    return (
      <>
        <div style={{ height: "80%", marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p
              className="headText"
              style={{
                width: "80%",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "700",
                color: "#3498d2",
                marginInline: "auto",
                marginTop: "25px",
              }}
            >
              Pack Id: {passport}
            </p>
            <Image />
            <TableTile />
            <div
              className="flex-col w-80 gap-8"
              style={{
                paddingTop: "15px",
                background: "#3498d2",
                border: "2px solid #3498d2",
                // minHeight: "60vh",
              }}
            >
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                }}
              >
                Battery Pack Assembly
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  paddingBlock: "15px",
                  // background: "black",
                  maxWidth: "full",
                  background: "#f7f8fa",
                }}
              >
                {/* <div className="con">
                  <p className="bold">Battery Pack QR Code</p>
                  <p className="bold">12cds21561 </p>
                </div> */}
                <div className="con">
                  <p className="bold">Battery Pack Assembly Date</p>
                  <p className="bold">01/14/2024</p>
                </div>
                <div className="con">
                  <p className="bold">Battery Pack Assembly Report Link</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot Image</p>
                  <img
                    src={passport2}
                    alt=""
                    className="passport-img"
                    style={{
                      width: "250px",
                      height: "200px",
                      marginInline: "auto",
                    }}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Deployment = () => {
    return (
      <>
        <div style={{ height: "80%", marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p
              className="headText"
              style={{
                width: "80%",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "700",
                color: "#3498d2",
                marginInline: "auto",
                marginTop: "25px",
              }}
            >
              Pack Id: {passport}
            </p>
            <Image />
            <TableTile />
            <div
              className="flex-col w-80 gap-8"
              style={{
                paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                }}
              >
                Battery Pack Field Deployment
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                {/* <div className="con">
                  <p className="bold">Battery Pack QR Code</p>
                  <p className="bold">12cds21561 </p>
                </div> */}
                <div className="con">
                  <p className="bold">Field Market Segment</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Customer Name</p>
                  <p className="bold">{data && data?.columb?.manufacturer}</p>
                </div>
                <div className="con">
                  <p className="bold">Customer ID #</p>
                  <p className="bold">{data && data?.columb?.packID}</p>
                </div>
                <div className="con">
                  <p className="bold">Battery Pack Field Deployment Date</p>
                  <p className="bold">
                    {data && data?.columb?.onboardingDate
                      ? moment(data?.columb?.onboardingDate).format(
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </p>
                </div>
                <div className="con">
                  <p className="bold">Battery Pack Field Use Report Link</p>
                  <p className="bold"></p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Recycle = () => {
    return (
      <>
        <div style={{ height: "80%", marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p
              className="headText"
              style={{
                width: "80%",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "700",
                color: "#3498d2",
                marginInline: "auto",
                marginTop: "25px",
              }}
            >
              Pack Id: {passport}
            </p>
            <Image />
            <TableTile />
            <div
              className="flex-col w-80 gap-8"
              style={{
                paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                }}
              >
                Battery Recycling
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                <div className="con">
                  <p className="bold">Recycling Date </p>
                  <p className="bold"> </p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Site</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Firm Name</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Firm ID #</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Completion Date</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Proof of Recycling Certificate Link</p>
                  <p className="bold"></p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  return (
    // <div className="passport" style={{ marginTop: "-100vh" }}>
    //   <Header />
    //   {tab === "intake" && <Intake />}
    //   {tab === "deployment" && <Deployment />}
    //   {tab === "reverse" && <Reverse />}
    //   {tab === "recycling" && <Recycle />}
    //   {tab === "inspection" && <Inspection />}
    //   {tab === "assembly" && <Assembly />}
    //   <Footer handleChange={setTab} tab={tab} />
    // </div>
    <div className="passport" style={{ marginTop: "-100vh" }}>
      <Header />
      <Intake />
      <Header />
      <Inspection />
      <Header />
      <Assembly />
      <Header />
      <Deployment />
      <Header />
      <Reverse />
      <Header />
      <Recycle />
      {/* <Footer handleChange={setTab} tab={tab} /> */}
    </div>
  );
};

export default ReportPDF2;
