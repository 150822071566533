import React, { useState, memo } from "react";
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { validationHandle } from "../fieldmap";

const SelectField = ({
  data,
  step,
  value,
  status,
  loading,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
  loading?: boolean;
}) => {
  const dispatch = useDispatch();
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const [fieldValue, setFieldValue] = useState(value);
  const [isError, setIsError] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<string>("");

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset, value]);

  const handleChange = (event: SelectChangeEvent) => {
    if (data.validate) {
      const { isError, infoDetails } = validationHandle(
        data.validate,
        event.target.value,
        data
      );
      setIsError(isError);
      setFieldInfo(infoDetails);
    }
    setFieldValue(event.target.value as string);
  };

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
        {data.validate && fieldValue && (
          <Tooltip
            className={`sm-tootip-true ${isError ? "sm-tootip-false" : ""}`}
            title={fieldInfo}
            arrow
          >
            <Button>
              {isError ? (
                <ExclamationCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )}
            </Button>
          </Tooltip>
        )}
      </InputLabel>
      <Select
        disabled={!status}
        variant="outlined"
        value={fieldValue}
        onChange={handleChange}
      >
        <MenuItem value="" style={{ display: "none" }}>
          {data.name}
        </MenuItem>
        {!loading &&
          data.option?.map((option: any, index) => {
            return (
              <MenuItem
                value={data.optionVal ? option[data.optionVal] : option}
                key={index}
              >
                {data.optionVal ? option.name : option}
              </MenuItem>
            );
          })}
      </Select>

      <Button
        variant="contained"
        onClick={handleSave}
        disabled={fieldValue ? false : true}
      >
        Save
      </Button>
    </FormControl>
  );
};

export default memo(SelectField);
