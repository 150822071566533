import {lazy} from "react"

import Loadable from "../components/Loadable"
import MainLayout from "../layout/MainLayout"
import QRGeneration from "../page/qrgeneration"
import {SamsarInspection} from "../page/inspection"
import ReportPDF2 from "../page/inspection/fieldComponants/CustomAction/Report2/ReportPDF2"
import BatteryReport from "../page/inspection/fieldComponants/CustomAction/BatteryReport/BatteryReport"

const Projection = Loadable(lazy(() => import("../page/projection")))

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/batterypassport/:id",
      element: <Projection />,
    },
    // {
    //   path: "/battery-report/:id",
    //   element: <BatteryReport />,
    // },
    {
      path: "/batteryqr",
      element: <QRGeneration />,
    },
    // {
    //   path: "/passport/:passport",
    //   element: <ReportPDF2 />,
    // },
    {
      path: "/inspection",
      element: <SamsarInspection />,
    },
  ],
}

export default MainRoutes

// AAUAAD0624-111
