import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const ScrollTop = ({ children }: Props): JSX.Element => {
  const location = useLocation();
  const { pathname } = location;

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return <React.Fragment>{children}</React.Fragment>;
};

ScrollTop.propTypes = {
  children: PropTypes.node,
};

export default ScrollTop;
