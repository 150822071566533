import React, { useEffect, useState } from "react";
import InspectionForm from "./StepForms/InspectionForm";
import { ProgressSidebar } from "./ProgressSidebar";
import { fieldmap, stepSop } from "./fieldmap";
import { useDispatch, useSelector } from "react-redux";
import {
  handleFormData,
  handleStep,
  resetStepData,
  resetToggle,
} from "../../reducers/inspectionReducer";
import DailyPrepForm from "./StepForms/DailyPrep";
import SortingBinnig from "./StepForms/SortingBinning";
import ModulePackagingForm from "./StepForms/ModulePacking";
import KPI from "./KPI";
import Schedular from "./fieldComponants/Schedular";
import BatteryData from "./StepForms/Battery-Data/BatteryData";
import { useAppSelector } from "../../store/rtctype";
import VendorForm from "./StepForms/Vendor";
import BulkDownload from "./BulkDownload";
import Assembler from "./Assembler";
import CustomerPortal from "./CustomerPortal";
import QisForm from "./fieldComponants/QIS/QisForm";
import QISKPI from "./QISKPI";

export const SamsarInspection = () => {
  const formData = useSelector((state: any) => state.inspection);
  // console.log("%c Line:23 🍤 formData", "color:#e41a6a", formData);
  const formDataOne = useAppSelector((state: any) => state.inspection).formData;
  const { user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const step = localStorage.getItem("step");
    if (step) {
      dispatch(handleStep(step));
    }
  }, []);

  useEffect(() => {
    if (
      user?.username === "operator" ||
      user?.username === "myohanna" ||
      user?.username === "hgavi"
    ) {
      dispatch(handleStep("step_1"));
    }
  }, [user]);

  useEffect(() => {
    if (formDataOne && formDataOne[formData?.step]) {
      const step = localStorage.getItem("step");
      const currentDataObj = { ...formDataOne[formData?.step] };

      const objectKeys = Object.keys(currentDataObj);
      for (let i = 0; i < objectKeys.length; i++) {
        currentDataObj[objectKeys[i]] = "";
      }
      dispatch(resetStepData({ step: step, value: currentDataObj }));
      dispatch(resetToggle(!formData.toggle));
    }
  }, [formData?.step]);
  useEffect(() => {
    if (formDataOne && formDataOne["step_18"] && fieldmap["step_18"]) {
      // const step = localStorage.getItem("step");
      const currentDataObj = { ...formDataOne["step_18"] };

      const objectKeys = Object.keys(currentDataObj);
      for (let i = 0; i < objectKeys.length; i++) {
        currentDataObj[objectKeys[i]] = "";
      }
      dispatch(resetStepData({ step: "step_18", value: currentDataObj }));
      dispatch(resetToggle(!formData.toggle));
      fieldmap["step_18"].fields = [
        {
          key: "kpi1",
          name: "Scan Module ID",
          type: "HIS",
        },
      ];
      // setHeader("");
      // setHeaderRed("");
      stepSop.step_18 = "";
      delete fieldmap["step_18"].view_media;
      delete fieldmap["step_18"].upload_media;
    }
  }, [formData?.step]);

  useEffect(() => {
    const stepArray = Object.keys(fieldmap);
    const dataValue: any = {};
    for (let i = 0; i < stepArray.length; i++) {
      dataValue[stepArray[i]] = {};
      const stepField = fieldmap[stepArray[i]].fields;
      for (let j = 0; j < stepField.length; j++) {
        if (
          stepField[j]["type"] === "field" &&
          stepField[j]["type"] === "file" &&
          stepField[j]["type"] === "select" &&
          stepField[j]["type"] === "scan"
        ) {
          dataValue[stepArray[i]][stepField[j]["key"]] = "";
        } else if (stepField[j]["type"] === "status") {
          dataValue[stepArray[i]]["rejected"] = false;
          dataValue[stepArray[i]]["rejection_reason"] = "";
        } else if (stepField[j]["type"] === "file_read") {
          const fieldOption = stepField[j].read_field;
          if (fieldOption) {
            for (let k = 0; k < fieldOption?.length; k++) {
              dataValue[stepArray[i]][fieldOption[k]] = "";
            }
          }
        }
      }
    }
    dispatch(handleFormData(dataValue));
  }, []);

  return (
    <div>
      <div className="sm-stepForm-wrapper">
        <div className="row">
          {windowWidth > 768 && <ProgressSidebar type="Main" />}
          <div className={`sm-colm-9`}>
            {formData?.formData ? (
              formData?.step === "step_16" ? (
                <Schedular step="step_16" />
              ) : formData?.step === "step_4" ? (
                <DailyPrepForm step="step_4" />
              ) : formData?.step === "step_11" ? (
                <SortingBinnig step="step_11" />
              ) : formData?.step === "step_12" ? (
                <ModulePackagingForm step="step_12" />
              ) : formData?.step === "step_17" ? (
                <BatteryData step="step_17" />
              ) : formData?.step === "step_19" ? (
                <VendorForm step="step_19" />
              ) : formData?.step === "step_20" ? (
                <BulkDownload />
              ) : formData?.step === "step_21" ? (
                <Assembler />
              ) : formData?.step === "step_22" ? (
                <CustomerPortal />
              ) : formData?.step === "step_23" ? (
                <QisForm step={"step_23"} />
              ) : formData?.step === "step_24" ? (
                <QisForm step={"step_24"} />
              ) : formData?.step === "step_25" ? (
                <QisForm step={"step_25"} />
              ) : formData?.step === "step_26" ? (
                <QisForm step={"step_26"} />
              ) : formData?.step === "step_27" ? (
                <QisForm step={"step_27"} />
              ) : formData?.step === "step_28" ? (
                <QisForm step={"step_28"} />
              ) : formData?.step === "step_29" ? (
                <QISKPI />
              ) : formData?.step === "step_14" ? (
                user?.username === "operator" ||
                user?.username === "myohanna" ||
                user?.username === "hgavi" ? (
                  ""
                ) : (
                  <KPI />
                )
              ) : (
                <InspectionForm step={formData?.step} />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
