import React, { useEffect } from "react";
import { HelpReport } from "./HelpReport";

function BatteryReport() {
  return (
    <div style={{ marginTop: "-100vh", minWidth: "1024px" }}>
      <HelpReport />
    </div>
  );
}

export default BatteryReport;
