import React from "react"
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {useAppSelector} from "../../store/rtctype"

const MinimalLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    const authToken = localStorage.getItem("accessToken")

    if (authToken) {
      navigate("/inspection")
    }
  }, [location.pathname])

  return (
    <>
      <Outlet />
    </>
  )
}

export default MinimalLayout
