import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    width: "100%",
  },
  smPageInner: {
    height: "95vh",
    position: "relative",
  },
  smPageInner2: {
    padding: "20px 15px 0 15px",
  },
  section: {
    margin: 10,
    marginBottom: "15px",
    marginTop: "0",
  },
  smMb_3: {
    marginBottom: "50px",
  },
  headingText: {
    fontSize: "10px",
    color: "#ffffff",
    padding: "5px",
    fontWeight: 500,
    backgroundColor: "#212121",
    width: "100%",
    flex: "0 0 25%",
    maxWidth: "25%",
    margin: 0,
  },
  smPdfheading: {
    textAlign: "center",
    margin: "0",
    fontSize: "22px",
    lineHeight: "1.3",
    color: "#3498d2",
    fontWeight: 600,
    maxWidth: "70%",
  },
  smPdfSubheading: {
    fontSize: "14px",
    lineHeight: "1.3",
    fontWeight: 500,
    color: "#3498d2",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "10px",
    paddingBottom: "0",
  },
  contentText: {
    fontSize: "10px",
    lineHeight: "1.3",
    fontWeight: 400,
    color: "#000",
    marginTop: "0",
  },
  secondText: {
    fontSize: "10px",
    color: "#212121",
    flex: "0 0 25%",
    maxWidth: "25%",
    padding: "5px",
    borderWidth: 1,
    borderColor: "#3498d2",
    margin: 0,
    textAlign: "right",
  },
  smColmn_6: {
    flex: "0 0 50%",
    maxWidth: "50%",
    padding: "0 6px",
  },
  smColmn_8: {
    flex: "0 0 60%",
    maxWidth: "60%",
  },
  smColmn_4: {
    flex: "0 0 40%",
    maxWidth: "40%",
  },
  rowSection: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 -6px",
  },
  innerSecRow: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  fixWidth: {
    flex: "0 0 75%",
    maxWidth: "75%",
    paddingRight: "10px",
    textAlign: "left",
  },
  smBlueBox: {
    minHeight: "120px",
    padding: "15px",
    borderRadius: "6px",
    backgroundColor: "#1f50a2",
  },
  smBlueBoxHeading: {
    fontSize: "16px",
    lineHeight: "1.3",
    fontWeight: 600,
    color: "#FFFFFF",
  },
  logo: {
    width: "100px",
    margin: "0 auto",
    paddingBottom: "10px",
  },
  smChartBox: {
    position: "relative",
    marginBottom: "15px",
    height: "250px",
  },
  chartBoxRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chartBoxHeading: {
    fontSize: "16px",
    lineHeight: "1.3",
    fontWeight: 500,
    color: "#3498d2",
    backgroundColor: "#FFF",
    width: "auto",
    maxWidth: "auto",
    paddingLeft: "8px",
    textAlign: "left",
    marginBottom: "8px",
  },
  chartBox65: {
    flex: "0 0 100%",
    maxWidth: "100%",
  },
  chartBox35: {
    flex: "0 0 35%",
    maxWidth: "35%",
    backgroundColor: "#212121",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
  },
  smpdfCtn: {
    fontSize: "16px",
    lineHeight: "1.3",
    color: "#212121",
  },
  chartImg: {
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    borderRadius: "4px",
    objectFit: "contain",
  },
  smChartInner: {
    border: "1px solid #808080",
    borderRadius: "30px",
    overflow: "hidden",
    padding: "3px",
    maxHeight: "120px",
  },
  smNBCRow: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 -6px",
    gap: "10px 0",
    marginBottom: "20px",
  },
  smNBCimageCol: {
    flex: "0 0 50%",
    maxWidth: "50%",
    padding: "0 6px",
  },
  smNBCimageInner: {
    border: "1px solid #212121",
    borderRadius: "8px",
    minHeight: "90px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  smNBCimage: {
    objectFit: "cover",
    maxWidth: "100%",
    height: "auto",
  },
  contentAlign: {
    alignItems: "center",
  },
  chartBoxInner: {
    border: "1px solid #3498d2",
    borderRadius: "4px",
    minHeight: "200px",
    height: "280px",
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "1",
  },
  smColmn_12: {
    flex: "0 0 100%",
    maxWidth: "100%",
  },
  blueText: {
    backgroundColor: "#3498d2",
  },
  widthFull: {
    width: "100%",
  },
  termsText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.3,
    marginBottom: "15px",
  },
  marinBottom: {
    marginBottom: "70px",
  },
});
