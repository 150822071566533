import React, { memo } from "react";

import SopHeader from "../StepForms/sopHeader";

const LabelInput = ({
  title,
  value,
  status,
}: {
  title: string;
  value: string;
  status: boolean;
}) => {
  return (
    <div className="sm-stepForm-banner">
      <SopHeader title={title} link={""} />
    </div>
  );
};

export default memo(LabelInput);
