import React, { useEffect, useState, useCallback } from "react";
import { fieldmap, stepSop } from "../../fieldmap";
import InputField from "../../fieldComponants/InputField";
import { Field } from "../../types";
import { FormDetails } from "../../formDetails/FormDetails";
import { useAppDispatch, useAppSelector } from "../../../../store/rtctype";
import {} from "../../serviceCallback";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import SopHeader from "../sopHeader";
import { CustomBinBtn } from "./CustomBinBtn";
import { ConfirmBinBtn } from "./ConfirmBinBtn";
import {
  resetStepData,
  resetToggle,
} from "../../../../reducers/inspectionReducer";

export default function SortingBinnig({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [eropen, setErOpen] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const [binId, setBinId] = useState<any>(null);
  // console.log("%c Line:23 🍔 binId", "color:#e41a6a", binId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData, step]);

  const errorHandle = (errors: any) => {
    const new_err = [];
    // for (const error in errors) {
    //   const stepField = fieldmap[step].fields;
    //   const stepFieldIndex = stepField.findIndex(
    //     (field: any) => field.key == error
    //   );

    //   if (stepFieldIndex >= 0) {
    //     new_err.push(`${stepField[stepFieldIndex].name} : ${errors[error]}`);
    //   }
    // }
    new_err.push(errors?.message);
    return new_err;
  };

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  const handleReset = () => {
    const currentDataObj = { ...formData[step] };
    const objectKeys = Object.keys(currentDataObj);
    for (let i = 0; i < objectKeys.length; i++) {
      currentDataObj[objectKeys[i]] = "";
    }
    dispatch(resetStepData({ step: step, value: currentDataObj }));
    dispatch(resetToggle(!formData.toggle));
  };

  const handleSubmit = () => {
    handleReset();
    setBinId(null);
  };

  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={fieldmap[step]?.title} link={stepSop[step]} />
          <FormDetails step={step} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {fieldmap[step]?.fields?.map((field: Field, index: number) => {
                if (field.type === "field") {
                  return (
                    <InputField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                }
                return null;
              })}

              {formData && formData[step] && (
                <CustomBinBtn
                  handleGetBinDetails={setBinId}
                  status={formData[step]["module_id"] ? false : true}
                  module_id={formData[step]["module_id"]}
                />
              )}

              {binId && binId?.data && Object.keys(binId?.data).length > 0 && (
                <>
                  <div className="sm-bindetails">
                    <label>Bin Id</label>
                    <p>{binId?.data?.outgoing_bin_id}</p>
                  </div>
                  <div className="sm-bindetails">
                    <label>Remaining Capacity</label>
                    <p>{binId?.data?.capacity_left}</p>
                  </div>

                  <div className="sm-bindetails">
                    <label>SOH</label>
                    <p>{Number(binId?.data?.soh)?.toFixed(2)}</p>
                  </div>
                  {binId?.data?.attached_battery_module && (
                    <div className="sm-bindetails">
                      <label>Attached Module ID</label>
                      <p>{binId?.data?.attached_battery_module}</p>
                    </div>
                  )}
                  {binId?.data?.attached_battery_soh && (
                    <div className="sm-bindetails">
                      <label>Attached Module ID's SOH</label>
                      <p>{binId?.data?.attached_battery_soh?.toFixed(2)}</p>
                    </div>
                  )}

                  {binId?.data?.attached_modules && (
                    <div className="sm-bindetails">
                      <label>Bin Status</label>
                      <p style={{ maxWidth: "48.2%" }}>
                        {binId?.data?.attached_modules}
                      </p>
                    </div>
                  )}
                </>
              )}

              {formData && formData[step] && (
                <ConfirmBinBtn
                  handleSubmit={handleSubmit}
                  status={
                    formData[step]["module_id"] &&
                    binId &&
                    Object.keys(binId?.data).length > 0
                      ? false
                      : true
                  }
                  module_id={formData[step]["module_id"]}
                  bin_id={binId?.data?.outgoing_bin_id}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />
    </React.Fragment>
  );
}
