import React, { memo } from "react";
import { Typography } from "@mui/material";
import placeholder from "../../../assets/svg/default-img.svg";
import { Field, uploadMedia } from "../types";
import { useAppSelector } from "../../../store/rtctype";
import { handleImageLoading } from "../../../reducers/authReducer";
import { useDispatch } from "react-redux";

const UploadImage = ({
  value,
  field,
  step,
}: {
  value: any;
  field: uploadMedia;
  step: string;
}) => {
  const dispatch = useDispatch();
  const [displayImage, setDisplayImage] = React.useState("");
  const { imgLoading } = useAppSelector((state) => state.user);

  React.useEffect(() => {
    if (value) {
      if (step !== "step_18") {
        setDisplayImage(URL.createObjectURL(value));
        dispatch(handleImageLoading(false));
      } else {
        setDisplayImage(value);
        dispatch(handleImageLoading(false));
      }
    }
  }, [value, imgLoading]);

  return (
    <div className="sm-stepform-img">
      {value ? (
        <>
          <div className="sm-uplImg">
            <img src={value} style={{ width: "100%" }} alt={field.name} />
          </div>
          <div className="sm-uplImg-name">
            <Typography variant="body2">{field.name}</Typography>
          </div>
        </>
      ) : (
        <>
          <div className="sm-placeholeder">
            <img src={placeholder} alt="pallet img" className="default-img" />
          </div>
          <Typography variant="body2">{field.name}</Typography>
        </>
      )}
    </div>
  );
};

export default memo(UploadImage);
