import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

interface PaginationProps {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: React.FC<PaginationProps> = ({ pageCount, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    onPageChange(selectedItem);
    console.log("%c Line:15 🌰 selectedItem", "color:#fca650", selectedItem);
  };

  return (
    <div className="pagination-div">
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageClick}
        forcePage={currentPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        containerClassName={"battery-Pagination"}
        activeClassName={"battery-Pagination-active"}
        previousLabel={
          <GrFormPrevious style={{ width: "26px", height: "26px" }} />
        }
        nextLabel={<GrFormNext style={{ width: "26px", height: "26px" }} />}
      />
    </div>
  );
};

export default Pagination;
