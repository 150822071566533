import React, { useEffect } from "react";
import { HelpReportDownload } from "./HelpReportDownload";

function BatteryReportDownload() {
  return (
    <div style={{ marginTop: "-100vh", minWidth: "1024px" }}>
      <HelpReportDownload />
    </div>
  );
}

export default BatteryReportDownload;
