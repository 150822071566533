import React, { useRef, useState } from "react";
import { Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import { CloseCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { getSignUrl, postSupport } from "../../../service/apiService";
import { toast } from "react-toastify";

type ModelProps = {
  open: boolean,
  setOpen: (value: boolean) => void,
};

const module = [
  "Schedule Tool",
  "KPI Dashboard",
  "Incoming Lot",
  "Incoming Package",
  "Inbound WIP",
  "Daily Prep",
  "HIS Progress",
  "Sorting & Binning",
  "Module Packaging",
  "Report",
  "Passport",
  "Report Bulk Download",
  "Vendor",
];
const SupportModal: React.FC<ModelProps> = ({ open, setOpen }) => {
  const fileRef = useRef();
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [data, setData] = useState({
    title: "",
    desc: "",
    module: "",
    image: "",
  });
  const handleUpload = () => {
    if (fileRef) {
      fileRef.current.click();
    }
  };

  const handleSubmit = () => {
    if (!data?.title) {
      toast.warn("Plaese Enter Title");
      return;
    }
    if (!data?.module) {
      toast.warn("Plaese Select Module");
      return;
    }
    let formData = new FormData();

    formData.append("title", data?.title);
    if (data?.desc) formData.append("discripition", data?.desc);
    formData.append("module", data?.module);
    if (data?.image) formData.append(`image`, JSON.stringify(data?.image));

    setLoading(true);
    postSupport(formData)
      .then((res) => {
        console.log("%c Line:45 🥖 res", "color:#3f7cff", res);
        if (res?.success) {
          setOpen(false);
          setData({
            title: "",
            desc: "",
            module: "",
            image: "",
          });
          setFieldValue("");
        }
      })
      .catch((e) => {
        console.log("%c Line:48 🍒 e", "color:#f5ce50", e);
      })
      .finally(() => setLoading(false));
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files[0];
    setFieldValue(file);
    console.log("%c Line:36 🎂 file", "color:#42b983", file);
    const vFiles = file;

    getSignUrl({ fileName: file?.name }).then((res: any) => {
      if (res?.success && res?.data && res?.data?.data) {
        // setLoadingSign(false);
        setData({ ...data, image: res?.data?.data?.url });
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", res?.data?.data?.signUrls, true);
        xhr.setRequestHeader("Content-Type", vFiles?.type);
        // xhr.onreadystatechange = () => {
        //   if (
        //     xhr.readyState === XMLHttpRequest.DONE &&
        //     xhr.status === 200
        //   ) {

        //   }
        // };
        xhr.send(vFiles);
      }
    });
  };

  const handleRemoveFile = () => {
    fileRef.current.value = null;
    setFieldValue("file");
    setData({ ...data, image: "" });
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
          setOpen(!open);
        },
      }}
    >
      <div
        className="general_popup width_inc validation_popup"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="modal_header"
          style={{
            background: "#0a0a0a",
            color: "#fff",
            padding: "20px",
            borderRadius: "7px 7px 0px 0px",
          }}
        >
          <h1>Contact Admin</h1>
        </div>
        <div
          style={{
            width: "full",
            padding: "10px 12px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <h1>Title</h1>
          <TextField
            placeholder="Enter Title"
            value={data?.title}
            onChange={(e) => setData({ ...data, title: e?.target?.value })}
          />
          <h1>Process</h1>
          <Select
            variant="outlined"
            // placeholder="Select Process"
            value={data?.module}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return <em style={{ color: "#b3b3b3" }}>Select Process</em>;
              }
              return selected;
            }}
            onChange={(e) => setData({ ...data, module: e?.target?.value })}
          >
            {/* <MenuItem value="" disabled>
              Select Process
            </MenuItem> */}
            {module?.map((obj: string) => {
              return <MenuItem value={obj}>{obj}</MenuItem>;
            })}
          </Select>
          <h1>Description</h1>
          <TextField
            multiline
            rows={4}
            placeholder="Enter Description"
            value={data?.desc}
            onChange={(e) => setData({ ...data, desc: e?.target?.value })}
          />
          <h1>Image/video</h1>
          {data?.image ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "13px 8px",
                border: "1px solid #cfcfcf",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <p>{fieldValue?.name}</p>
              <CloseCircleOutlined onClick={handleRemoveFile} />
            </div>
          ) : (
            <Button variant="contained" onClick={handleUpload}>
              <CloudUploadOutlined style={{ marginRight: "10px" }} />
              Upload
            </Button>
          )}
          <input
            hidden
            type="file"
            // id={data.key}
            onChange={handleFileUpload}
            ref={fileRef}
          />
        </div>
        <div
          className="modal_footer"
          style={{ display: "flex", gap: "10px", padding: "15px 20px" }}
        >
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SupportModal;
