export const grapOne = [
    [
        5.0399,
        95.3808
    ],
    [
        5.0869,
        95.2772
    ],
    [
        5.1403,
        95.1736
    ],
    [
        5.1995,
        95.07
    ],
    [
        5.2647,
        94.9664
    ],
    [
        5.3324,
        94.8627
    ],
    [
        5.4044,
        94.7591
    ],
    [
        5.4764,
        94.6555
    ],
    [
        5.5467,
        94.5519
    ],
    [
        5.6109,
        94.4483
    ],
    [
        5.6714,
        94.3446
    ],
    [
        5.7249,
        94.241
    ],
    [
        5.7707,
        94.1374
    ],
    [
        5.8119,
        94.0338
    ],
    [
        5.8479,
        93.9302
    ],
    [
        5.8814,
        93.8266
    ],
    [
        5.9132,
        93.7229
    ],
    [
        5.9436,
        93.6193
    ],
    [
        5.9725,
        93.5157
    ],
    [
        5.9994,
        93.4121
    ],
    [
        6.0256,
        93.3085
    ],
    [
        6.0513,
        93.2049
    ],
    [
        6.0752,
        93.1012
    ],
    [
        6.0979,
        92.9976
    ],
    [
        6.1195,
        92.894
    ],
    [
        6.1414,
        92.7904
    ],
    [
        6.1611,
        92.6868
    ],
    [
        6.1809,
        92.5831
    ],
    [
        6.2002,
        92.4795
    ],
    [
        6.2207,
        92.3759
    ],
    [
        6.2376,
        92.2723
    ],
    [
        6.2548,
        92.1687
    ],
    [
        6.2718,
        92.065
    ],
    [
        6.288,
        91.9614
    ],
    [
        6.3044,
        91.8578
    ],
    [
        6.32,
        91.7542
    ],
    [
        6.3352,
        91.6506
    ],
    [
        6.3498,
        91.547
    ],
    [
        6.3639,
        91.4433
    ],
    [
        6.3794,
        91.3397
    ],
    [
        6.3939,
        91.2361
    ],
    [
        6.4059,
        91.1325
    ],
    [
        6.4157,
        91.0289
    ],
    [
        6.4283,
        90.9252
    ],
    [
        6.4433,
        90.8216
    ],
    [
        6.4564,
        90.718
    ],
    [
        6.4675,
        90.6144
    ],
    [
        6.4772,
        90.5108
    ],
    [
        6.4893,
        90.4072
    ],
    [
        6.4991,
        90.3035
    ],
    [
        6.5108,
        90.1999
    ],
    [
        6.5221,
        90.0963
    ],
    [
        6.5314,
        89.9927
    ],
    [
        6.5418,
        89.8891
    ],
    [
        6.5508,
        89.7854
    ],
    [
        6.5589,
        89.6818
    ],
    [
        6.5671,
        89.5782
    ],
    [
        6.5741,
        89.4746
    ],
    [
        6.5837,
        89.371
    ],
    [
        6.5927,
        89.2674
    ],
    [
        6.6022,
        89.1637
    ],
    [
        6.6104,
        89.0601
    ],
    [
        6.6183,
        88.9565
    ],
    [
        6.624,
        88.8529
    ],
    [
        6.6289,
        88.7493
    ],
    [
        6.635,
        88.6456
    ],
    [
        6.6396,
        88.542
    ],
    [
        6.6461,
        88.4384
    ],
    [
        6.6515,
        88.3348
    ],
    [
        6.6574,
        88.2312
    ],
    [
        6.6617,
        88.1276
    ],
    [
        6.6677,
        88.0239
    ],
    [
        6.6721,
        87.9203
    ],
    [
        6.676,
        87.8167
    ],
    [
        6.6801,
        87.7131
    ],
    [
        6.6841,
        87.6095
    ],
    [
        6.6885,
        87.5058
    ],
    [
        6.6916,
        87.4022
    ],
    [
        6.6952,
        87.2986
    ],
    [
        6.699,
        87.195
    ],
    [
        6.7011,
        87.0914
    ],
    [
        6.7042,
        86.9878
    ],
    [
        6.7068,
        86.8841
    ],
    [
        6.7096,
        86.7805
    ],
    [
        6.713,
        86.6769
    ],
    [
        6.7157,
        86.5733
    ],
    [
        6.7179,
        86.4697
    ],
    [
        6.7206,
        86.366
    ],
    [
        6.7228,
        86.2624
    ],
    [
        6.7253,
        86.1588
    ],
    [
        6.7275,
        86.0552
    ],
    [
        6.7293,
        85.9516
    ],
    [
        6.7325,
        85.848
    ],
    [
        6.734,
        85.7443
    ],
    [
        6.7352,
        85.6407
    ],
    [
        6.7373,
        85.5371
    ],
    [
        6.739,
        85.4335
    ],
    [
        6.741,
        85.3299
    ],
    [
        6.7437,
        85.2262
    ],
    [
        6.745,
        85.1226
    ],
    [
        6.7466,
        85.019
    ],
    [
        6.7481,
        84.9154
    ],
    [
        6.7497,
        84.8118
    ],
    [
        6.7512,
        84.7082
    ],
    [
        6.7535,
        84.6045
    ],
    [
        6.7556,
        84.5009
    ],
    [
        6.7566,
        84.3973
    ],
    [
        6.7582,
        84.2937
    ],
    [
        6.7601,
        84.1901
    ],
    [
        6.7616,
        84.0864
    ],
    [
        6.7635,
        83.9828
    ],
    [
        6.7651,
        83.8792
    ],
    [
        6.7668,
        83.7756
    ],
    [
        6.7695,
        83.672
    ],
    [
        6.7706,
        83.5683
    ],
    [
        6.7723,
        83.4647
    ],
    [
        6.774,
        83.3611
    ],
    [
        6.7755,
        83.2575
    ],
    [
        6.7765,
        83.1539
    ],
    [
        6.7786,
        83.0503
    ],
    [
        6.7794,
        82.9466
    ],
    [
        6.7816,
        82.843
    ],
    [
        6.7832,
        82.7394
    ],
    [
        6.7844,
        82.6358
    ],
    [
        6.7863,
        82.5322
    ],
    [
        6.787,
        82.4286
    ],
    [
        6.7885,
        82.3249
    ],
    [
        6.7898,
        82.2213
    ],
    [
        6.791,
        82.1177
    ],
    [
        6.7935,
        82.0141
    ],
    [
        6.7941,
        81.9105
    ],
    [
        6.7955,
        81.8068
    ],
    [
        6.7974,
        81.7032
    ],
    [
        6.7973,
        81.5996
    ],
    [
        6.7993,
        81.496
    ],
    [
        6.8007,
        81.3924
    ],
    [
        6.8024,
        81.2887
    ],
    [
        6.8035,
        81.1851
    ],
    [
        6.8046,
        81.0815
    ],
    [
        6.8059,
        80.9779
    ],
    [
        6.807,
        80.8743
    ],
    [
        6.8082,
        80.7707
    ],
    [
        6.8096,
        80.667
    ],
    [
        6.8097,
        80.5634
    ],
    [
        6.8121,
        80.4598
    ],
    [
        6.8126,
        80.3562
    ],
    [
        6.8137,
        80.2526
    ],
    [
        6.8159,
        80.1489
    ],
    [
        6.8169,
        80.0453
    ],
    [
        6.8184,
        79.9417
    ],
    [
        6.8195,
        79.8381
    ],
    [
        6.8209,
        79.7345
    ],
    [
        6.8223,
        79.6309
    ],
    [
        6.8238,
        79.5272
    ],
    [
        6.8258,
        79.4236
    ],
    [
        6.8254,
        79.32
    ],
    [
        6.8262,
        79.2164
    ],
    [
        6.8277,
        79.1128
    ],
    [
        6.8293,
        79.0091
    ],
    [
        6.8304,
        78.9055
    ],
    [
        6.8322,
        78.8019
    ],
    [
        6.8331,
        78.6983
    ],
    [
        6.8335,
        78.5947
    ],
    [
        6.8358,
        78.4911
    ],
    [
        6.8367,
        78.3874
    ],
    [
        6.8371,
        78.2838
    ],
    [
        6.8387,
        78.1802
    ],
    [
        6.8409,
        78.0766
    ],
    [
        6.841,
        77.973
    ],
    [
        6.8425,
        77.8693
    ],
    [
        6.8441,
        77.7657
    ],
    [
        6.8451,
        77.6621
    ],
    [
        6.8458,
        77.5585
    ],
    [
        6.847,
        77.4549
    ],
    [
        6.8475,
        77.3512
    ],
    [
        6.85,
        77.2476
    ],
    [
        6.8503,
        77.144
    ],
    [
        6.8523,
        77.0404
    ],
    [
        6.8534,
        76.9368
    ],
    [
        6.8537,
        76.8331
    ],
    [
        6.8554,
        76.7295
    ],
    [
        6.857,
        76.6259
    ],
    [
        6.8584,
        76.5223
    ],
    [
        6.8597,
        76.4187
    ],
    [
        6.8601,
        76.3151
    ],
    [
        6.8616,
        76.2114
    ],
    [
        6.8626,
        76.1078
    ],
    [
        6.8641,
        76.0042
    ],
    [
        6.8649,
        75.9006
    ],
    [
        6.8658,
        75.797
    ],
    [
        6.8667,
        75.6933
    ],
    [
        6.8685,
        75.5897
    ],
    [
        6.8693,
        75.4861
    ],
    [
        6.8708,
        75.3825
    ],
    [
        6.8714,
        75.2789
    ],
    [
        6.8723,
        75.1753
    ],
    [
        6.8734,
        75.0716
    ],
    [
        6.8755,
        74.968
    ],
    [
        6.8758,
        74.8644
    ],
    [
        6.8767,
        74.7608
    ],
    [
        6.8779,
        74.6572
    ],
    [
        6.8795,
        74.5535
    ],
    [
        6.8808,
        74.4499
    ],
    [
        6.8807,
        74.3463
    ],
    [
        6.8831,
        74.2427
    ],
    [
        6.8834,
        74.1391
    ],
    [
        6.8846,
        74.0354
    ],
    [
        6.886,
        73.9318
    ],
    [
        6.8869,
        73.8282
    ],
    [
        6.8876,
        73.7246
    ],
    [
        6.8886,
        73.621
    ],
    [
        6.8903,
        73.5174
    ],
    [
        6.891,
        73.4137
    ],
    [
        6.8925,
        73.3101
    ],
    [
        6.893,
        73.2065
    ],
    [
        6.8954,
        73.1029
    ],
    [
        6.8956,
        72.9993
    ],
    [
        6.8972,
        72.8956
    ],
    [
        6.8979,
        72.792
    ],
    [
        6.8989,
        72.6884
    ],
    [
        6.9004,
        72.5848
    ],
    [
        6.9018,
        72.4812
    ],
    [
        6.903,
        72.3776
    ],
    [
        6.9032,
        72.2739
    ],
    [
        6.9048,
        72.1703
    ],
    [
        6.9069,
        72.0667
    ],
    [
        6.9073,
        71.9631
    ],
    [
        6.9077,
        71.8595
    ],
    [
        6.9088,
        71.7558
    ],
    [
        6.9106,
        71.6522
    ],
    [
        6.912,
        71.5486
    ],
    [
        6.9134,
        71.445
    ],
    [
        6.9138,
        71.3414
    ],
    [
        6.915,
        71.2377
    ],
    [
        6.9159,
        71.1341
    ],
    [
        6.9171,
        71.0305
    ],
    [
        6.9184,
        70.9269
    ],
    [
        6.9197,
        70.8233
    ],
    [
        6.9207,
        70.7197
    ],
    [
        6.9208,
        70.616
    ],
    [
        6.9227,
        70.5124
    ],
    [
        6.9235,
        70.4088
    ],
    [
        6.9249,
        70.3052
    ],
    [
        6.9258,
        70.2016
    ],
    [
        6.9267,
        70.0979
    ],
    [
        6.9285,
        69.9943
    ],
    [
        6.929,
        69.8907
    ],
    [
        6.93,
        69.7871
    ],
    [
        6.9314,
        69.6835
    ],
    [
        6.9329,
        69.5798
    ],
    [
        6.9335,
        69.4762
    ],
    [
        6.9349,
        69.3726
    ],
    [
        6.9359,
        69.269
    ],
    [
        6.9367,
        69.1654
    ],
    [
        6.9381,
        69.0618
    ],
    [
        6.9393,
        68.9581
    ],
    [
        6.9402,
        68.8545
    ],
    [
        6.941,
        68.7509
    ],
    [
        6.9426,
        68.6473
    ],
    [
        6.9431,
        68.5437
    ],
    [
        6.944,
        68.44
    ],
    [
        6.9453,
        68.3364
    ],
    [
        6.9465,
        68.2328
    ],
    [
        6.9477,
        68.1292
    ],
    [
        6.9493,
        68.0256
    ],
    [
        6.9496,
        67.922
    ],
    [
        6.9504,
        67.8183
    ],
    [
        6.9513,
        67.7147
    ],
    [
        6.9533,
        67.6111
    ],
    [
        6.9541,
        67.5075
    ],
    [
        6.9548,
        67.4039
    ],
    [
        6.9562,
        67.3002
    ],
    [
        6.9565,
        67.1966
    ],
    [
        6.958,
        67.093
    ],
    [
        6.9588,
        66.9894
    ],
    [
        6.9591,
        66.8858
    ],
    [
        6.9605,
        66.7821
    ],
    [
        6.9618,
        66.6785
    ],
    [
        6.9624,
        66.5749
    ],
    [
        6.9644,
        66.4713
    ],
    [
        6.9642,
        66.3677
    ],
    [
        6.9655,
        66.2641
    ],
    [
        6.9661,
        66.1604
    ],
    [
        6.9671,
        66.0568
    ],
    [
        6.9682,
        65.9532
    ],
    [
        6.9691,
        65.8496
    ],
    [
        6.9703,
        65.746
    ],
    [
        6.9709,
        65.6423
    ],
    [
        6.9729,
        65.5387
    ],
    [
        6.9735,
        65.4351
    ],
    [
        6.9738,
        65.3315
    ],
    [
        6.9749,
        65.2279
    ],
    [
        6.9757,
        65.1243
    ],
    [
        6.9763,
        65.0206
    ],
    [
        6.9776,
        64.917
    ],
    [
        6.9792,
        64.8134
    ],
    [
        6.9797,
        64.7098
    ],
    [
        6.9807,
        64.6062
    ],
    [
        6.9817,
        64.5025
    ],
    [
        6.983,
        64.3989
    ],
    [
        6.9835,
        64.2953
    ],
    [
        6.9852,
        64.1917
    ],
    [
        6.9856,
        64.0881
    ],
    [
        6.9849,
        63.9845
    ],
    [
        6.9878,
        63.8808
    ],
    [
        6.9882,
        63.7772
    ],
    [
        6.99,
        63.6736
    ],
    [
        6.9901,
        63.57
    ],
    [
        6.9915,
        63.4664
    ],
    [
        6.9928,
        63.3627
    ],
    [
        6.9928,
        63.2591
    ],
    [
        6.9946,
        63.1555
    ],
    [
        6.9954,
        63.0519
    ],
    [
        6.9959,
        62.9483
    ],
    [
        6.9975,
        62.8446
    ],
    [
        6.9984,
        62.741
    ],
    [
        6.9989,
        62.6374
    ],
    [
        7.0007,
        62.5338
    ],
    [
        7.0009,
        62.4302
    ],
    [
        7.0018,
        62.3266
    ],
    [
        7.0034,
        62.2229
    ],
    [
        7.0041,
        62.1193
    ],
    [
        7.0045,
        62.0157
    ],
    [
        7.0055,
        61.9121
    ],
    [
        7.0073,
        61.8085
    ],
    [
        7.0086,
        61.7048
    ],
    [
        7.0098,
        61.6012
    ],
    [
        7.0106,
        61.4976
    ],
    [
        7.0116,
        61.394
    ],
    [
        7.0132,
        61.2904
    ],
    [
        7.0136,
        61.1868
    ],
    [
        7.0144,
        61.0831
    ],
    [
        7.0157,
        60.9795
    ],
    [
        7.0158,
        60.8759
    ],
    [
        7.0169,
        60.7723
    ],
    [
        7.0184,
        60.6687
    ],
    [
        7.0197,
        60.565
    ],
    [
        7.0202,
        60.4614
    ],
    [
        7.0216,
        60.3578
    ],
    [
        7.0221,
        60.2542
    ],
    [
        7.0239,
        60.1506
    ],
    [
        7.0243,
        60.0469
    ],
    [
        7.0255,
        59.9433
    ],
    [
        7.0265,
        59.8397
    ],
    [
        7.028,
        59.7361
    ],
    [
        7.029,
        59.6325
    ],
    [
        7.0288,
        59.5289
    ],
    [
        7.0304,
        59.4252
    ],
    [
        7.0317,
        59.3216
    ],
    [
        7.0331,
        59.218
    ],
    [
        7.0343,
        59.1144
    ],
    [
        7.0342,
        59.0108
    ],
    [
        7.0354,
        58.9071
    ],
    [
        7.0366,
        58.8035
    ],
    [
        7.0375,
        58.6999
    ],
    [
        7.0388,
        58.5963
    ],
    [
        7.0399,
        58.4927
    ],
    [
        7.0412,
        58.389
    ],
    [
        7.0418,
        58.2854
    ],
    [
        7.0431,
        58.1818
    ],
    [
        7.0442,
        58.0782
    ],
    [
        7.0451,
        57.9746
    ],
    [
        7.0457,
        57.871
    ],
    [
        7.047,
        57.7673
    ],
    [
        7.0483,
        57.6637
    ],
    [
        7.0492,
        57.5601
    ],
    [
        7.0503,
        57.4565
    ],
    [
        7.0519,
        57.3529
    ],
    [
        7.0526,
        57.2492
    ],
    [
        7.0542,
        57.1456
    ],
    [
        7.0554,
        57.042
    ],
    [
        7.0561,
        56.9384
    ],
    [
        7.0569,
        56.8348
    ],
    [
        7.058,
        56.7312
    ],
    [
        7.0589,
        56.6275
    ],
    [
        7.0598,
        56.5239
    ],
    [
        7.0609,
        56.4203
    ],
    [
        7.0631,
        56.3167
    ],
    [
        7.064,
        56.2131
    ],
    [
        7.0658,
        56.1094
    ],
    [
        7.0665,
        56.0058
    ],
    [
        7.0666,
        55.9022
    ],
    [
        7.0681,
        55.7986
    ],
    [
        7.0692,
        55.695
    ],
    [
        7.0709,
        55.5913
    ],
    [
        7.0715,
        55.4877
    ],
    [
        7.0728,
        55.3841
    ],
    [
        7.0748,
        55.2805
    ],
    [
        7.0753,
        55.1769
    ],
    [
        7.0762,
        55.0733
    ],
    [
        7.0768,
        54.9696
    ],
    [
        7.0791,
        54.866
    ],
    [
        7.0798,
        54.7624
    ],
    [
        7.0809,
        54.6588
    ],
    [
        7.0819,
        54.5552
    ],
    [
        7.0837,
        54.4515
    ],
    [
        7.0853,
        54.3479
    ],
    [
        7.0861,
        54.2443
    ],
    [
        7.0878,
        54.1407
    ],
    [
        7.089,
        54.0371
    ],
    [
        7.0898,
        53.9334
    ],
    [
        7.091,
        53.8298
    ],
    [
        7.0921,
        53.7262
    ],
    [
        7.0929,
        53.6226
    ],
    [
        7.0941,
        53.519
    ],
    [
        7.0953,
        53.4154
    ],
    [
        7.0968,
        53.3117
    ],
    [
        7.0971,
        53.2081
    ],
    [
        7.0991,
        53.1045
    ],
    [
        7.1004,
        53.0009
    ],
    [
        7.1012,
        52.8973
    ],
    [
        7.1025,
        52.7936
    ],
    [
        7.104,
        52.69
    ],
    [
        7.1052,
        52.5864
    ],
    [
        7.1063,
        52.4828
    ],
    [
        7.1075,
        52.3792
    ],
    [
        7.1098,
        52.2756
    ],
    [
        7.1106,
        52.1719
    ],
    [
        7.1122,
        52.0683
    ],
    [
        7.1128,
        51.9647
    ],
    [
        7.1144,
        51.8611
    ],
    [
        7.1151,
        51.7575
    ],
    [
        7.1166,
        51.6538
    ],
    [
        7.1187,
        51.5502
    ],
    [
        7.12,
        51.4466
    ],
    [
        7.1203,
        51.343
    ],
    [
        7.1218,
        51.2394
    ],
    [
        7.1235,
        51.1357
    ],
    [
        7.1249,
        51.0321
    ],
    [
        7.1262,
        50.9285
    ],
    [
        7.1275,
        50.8249
    ],
    [
        7.129,
        50.7213
    ],
    [
        7.1304,
        50.6177
    ],
    [
        7.1314,
        50.514
    ],
    [
        7.1324,
        50.4104
    ],
    [
        7.1335,
        50.3068
    ],
    [
        7.1355,
        50.2032
    ],
    [
        7.1367,
        50.0996
    ],
    [
        7.1381,
        49.9959
    ],
    [
        7.1399,
        49.8923
    ],
    [
        7.1405,
        49.7887
    ],
    [
        7.1425,
        49.6851
    ],
    [
        7.1434,
        49.5815
    ],
    [
        7.1447,
        49.4779
    ],
    [
        7.1459,
        49.3742
    ],
    [
        7.1477,
        49.2706
    ],
    [
        7.149,
        49.167
    ],
    [
        7.1508,
        49.0634
    ],
    [
        7.1521,
        48.9598
    ],
    [
        7.1535,
        48.8561
    ],
    [
        7.1548,
        48.7525
    ],
    [
        7.1562,
        48.6489
    ],
    [
        7.1574,
        48.5453
    ],
    [
        7.1594,
        48.4417
    ],
    [
        7.1606,
        48.3381
    ],
    [
        7.1625,
        48.2344
    ],
    [
        7.1639,
        48.1308
    ],
    [
        7.1655,
        48.0272
    ],
    [
        7.166,
        47.9236
    ],
    [
        7.1678,
        47.82
    ],
    [
        7.1695,
        47.7163
    ],
    [
        7.1707,
        47.6127
    ],
    [
        7.1722,
        47.5091
    ],
    [
        7.1741,
        47.4055
    ],
    [
        7.1755,
        47.3019
    ],
    [
        7.1764,
        47.1982
    ],
    [
        7.1782,
        47.0946
    ],
    [
        7.1797,
        46.991
    ],
    [
        7.1814,
        46.8874
    ],
    [
        7.183,
        46.7838
    ],
    [
        7.1845,
        46.6802
    ],
    [
        7.1867,
        46.5765
    ],
    [
        7.187,
        46.4729
    ],
    [
        7.1894,
        46.3693
    ],
    [
        7.1906,
        46.2657
    ],
    [
        7.1932,
        46.1621
    ],
    [
        7.1936,
        46.0584
    ],
    [
        7.1957,
        45.9548
    ],
    [
        7.1975,
        45.8512
    ],
    [
        7.198,
        45.7476
    ],
    [
        7.2005,
        45.644
    ],
    [
        7.2009,
        45.5403
    ],
    [
        7.2038,
        45.4367
    ],
    [
        7.205,
        45.3331
    ],
    [
        7.2069,
        45.2295
    ],
    [
        7.2085,
        45.1259
    ],
    [
        7.2101,
        45.0223
    ],
    [
        7.2117,
        44.9186
    ],
    [
        7.2132,
        44.815
    ],
    [
        7.2142,
        44.7114
    ],
    [
        7.2168,
        44.6078
    ],
    [
        7.2175,
        44.5042
    ],
    [
        7.2192,
        44.4005
    ],
    [
        7.2214,
        44.2969
    ],
    [
        7.223,
        44.1933
    ],
    [
        7.2242,
        44.0897
    ],
    [
        7.2259,
        43.9861
    ],
    [
        7.2287,
        43.8825
    ],
    [
        7.2288,
        43.7788
    ],
    [
        7.2315,
        43.6752
    ],
    [
        7.2334,
        43.5716
    ],
    [
        7.235,
        43.468
    ],
    [
        7.2367,
        43.3644
    ],
    [
        7.2381,
        43.2607
    ],
    [
        7.2395,
        43.1571
    ],
    [
        7.242,
        43.0535
    ],
    [
        7.2435,
        42.9499
    ],
    [
        7.2451,
        42.8463
    ],
    [
        7.2472,
        42.7426
    ],
    [
        7.2488,
        42.639
    ],
    [
        7.2505,
        42.5354
    ],
    [
        7.2515,
        42.4318
    ],
    [
        7.254,
        42.3282
    ],
    [
        7.2559,
        42.2246
    ],
    [
        7.2571,
        42.1209
    ],
    [
        7.2584,
        42.0173
    ],
    [
        7.2602,
        41.9137
    ],
    [
        7.2634,
        41.8101
    ],
    [
        7.2641,
        41.7065
    ],
    [
        7.2664,
        41.6028
    ],
    [
        7.2679,
        41.4992
    ],
    [
        7.2702,
        41.3956
    ],
    [
        7.2716,
        41.292
    ],
    [
        7.2729,
        41.1884
    ],
    [
        7.2753,
        41.0847
    ],
    [
        7.2773,
        40.9811
    ],
    [
        7.2783,
        40.8775
    ],
    [
        7.2798,
        40.7739
    ],
    [
        7.2823,
        40.6703
    ],
    [
        7.2839,
        40.5667
    ],
    [
        7.2857,
        40.463
    ],
    [
        7.2878,
        40.3594
    ],
    [
        7.2901,
        40.2558
    ],
    [
        7.2914,
        40.1522
    ],
    [
        7.2942,
        40.0486
    ],
    [
        7.2951,
        39.9449
    ],
    [
        7.2975,
        39.8413
    ],
    [
        7.2993,
        39.7377
    ],
    [
        7.3008,
        39.6341
    ],
    [
        7.3023,
        39.5305
    ],
    [
        7.3045,
        39.4269
    ],
    [
        7.3065,
        39.3232
    ],
    [
        7.3084,
        39.2196
    ],
    [
        7.3104,
        39.116
    ],
    [
        7.3119,
        39.0124
    ],
    [
        7.3138,
        38.9088
    ],
    [
        7.3166,
        38.8051
    ],
    [
        7.3177,
        38.7015
    ],
    [
        7.3196,
        38.5979
    ],
    [
        7.3221,
        38.4943
    ],
    [
        7.3239,
        38.3907
    ],
    [
        7.3251,
        38.287
    ],
    [
        7.3268,
        38.1834
    ],
    [
        7.3292,
        38.0798
    ],
    [
        7.3315,
        37.9762
    ],
    [
        7.3338,
        37.8726
    ],
    [
        7.3356,
        37.769
    ],
    [
        7.337,
        37.6653
    ],
    [
        7.3387,
        37.5617
    ],
    [
        7.3416,
        37.4581
    ],
    [
        7.3429,
        37.3545
    ],
    [
        7.3442,
        37.2509
    ],
    [
        7.3469,
        37.1472
    ],
    [
        7.3487,
        37.0436
    ],
    [
        7.3504,
        36.94
    ],
    [
        7.3528,
        36.8364
    ],
    [
        7.3548,
        36.7328
    ],
    [
        7.3569,
        36.6291
    ],
    [
        7.3593,
        36.5255
    ],
    [
        7.3607,
        36.4219
    ],
    [
        7.3624,
        36.3183
    ],
    [
        7.3646,
        36.2147
    ],
    [
        7.3669,
        36.1111
    ],
    [
        7.3687,
        36.0074
    ],
    [
        7.3711,
        35.9038
    ],
    [
        7.3729,
        35.8002
    ],
    [
        7.375,
        35.6966
    ],
    [
        7.3772,
        35.593
    ],
    [
        7.3796,
        35.4893
    ],
    [
        7.3803,
        35.3857
    ],
    [
        7.3818,
        35.2821
    ],
    [
        7.3847,
        35.1785
    ],
    [
        7.3868,
        35.0749
    ],
    [
        7.3887,
        34.9712
    ],
    [
        7.3907,
        34.8676
    ],
    [
        7.3928,
        34.764
    ],
    [
        7.3946,
        34.6604
    ],
    [
        7.3969,
        34.5568
    ],
    [
        7.3992,
        34.4532
    ],
    [
        7.4009,
        34.3495
    ],
    [
        7.4032,
        34.2459
    ],
    [
        7.4048,
        34.1423
    ],
    [
        7.4074,
        34.0387
    ],
    [
        7.4097,
        33.9351
    ],
    [
        7.412,
        33.8314
    ],
    [
        7.4141,
        33.7278
    ],
    [
        7.4159,
        33.6242
    ],
    [
        7.4182,
        33.5206
    ],
    [
        7.4194,
        33.417
    ],
    [
        7.4215,
        33.3134
    ],
    [
        7.4243,
        33.2097
    ],
    [
        7.4261,
        33.1061
    ],
    [
        7.4282,
        33.0025
    ],
    [
        7.4305,
        32.8989
    ],
    [
        7.432,
        32.7953
    ],
    [
        7.435,
        32.6916
    ],
    [
        7.4365,
        32.588
    ],
    [
        7.4395,
        32.4844
    ],
    [
        7.4407,
        32.3808
    ],
    [
        7.4434,
        32.2772
    ],
    [
        7.4449,
        32.1736
    ],
    [
        7.4468,
        32.0699
    ],
    [
        7.4491,
        31.9663
    ],
    [
        7.4507,
        31.8627
    ],
    [
        7.4538,
        31.7591
    ],
    [
        7.4553,
        31.6555
    ],
    [
        7.4574,
        31.5518
    ],
    [
        7.4598,
        31.4482
    ],
    [
        7.4608,
        31.3446
    ],
    [
        7.4638,
        31.241
    ],
    [
        7.4654,
        31.1374
    ],
    [
        7.4677,
        31.0337
    ],
    [
        7.4703,
        30.9301
    ],
    [
        7.4725,
        30.8265
    ],
    [
        7.4746,
        30.7229
    ],
    [
        7.4759,
        30.6193
    ],
    [
        7.478,
        30.5157
    ],
    [
        7.4805,
        30.412
    ],
    [
        7.4827,
        30.3084
    ],
    [
        7.4847,
        30.2048
    ],
    [
        7.4868,
        30.1012
    ],
    [
        7.4892,
        29.9976
    ],
    [
        7.4912,
        29.8939
    ],
    [
        7.4945,
        29.7903
    ],
    [
        7.496,
        29.6867
    ],
    [
        7.4978,
        29.5831
    ],
    [
        7.5008,
        29.4795
    ],
    [
        7.5024,
        29.3759
    ],
    [
        7.5048,
        29.2722
    ],
    [
        7.5069,
        29.1686
    ],
    [
        7.5093,
        29.065
    ],
    [
        7.5112,
        28.9614
    ],
    [
        7.5138,
        28.8578
    ],
    [
        7.5154,
        28.7541
    ],
    [
        7.5177,
        28.6505
    ],
    [
        7.5202,
        28.5469
    ],
    [
        7.5222,
        28.4433
    ],
    [
        7.5245,
        28.3397
    ],
    [
        7.527,
        28.2361
    ],
    [
        7.529,
        28.1324
    ],
    [
        7.531,
        28.0288
    ],
    [
        7.5332,
        27.9252
    ],
    [
        7.5355,
        27.8216
    ],
    [
        7.5374,
        27.718
    ],
    [
        7.5408,
        27.6143
    ],
    [
        7.5426,
        27.5107
    ],
    [
        7.5443,
        27.4071
    ],
    [
        7.5473,
        27.3035
    ],
    [
        7.5498,
        27.1999
    ],
    [
        7.5517,
        27.0962
    ],
    [
        7.5546,
        26.9926
    ],
    [
        7.5558,
        26.889
    ],
    [
        7.5589,
        26.7854
    ],
    [
        7.5612,
        26.6818
    ],
    [
        7.5633,
        26.5782
    ],
    [
        7.5651,
        26.4745
    ],
    [
        7.5676,
        26.3709
    ],
    [
        7.5698,
        26.2673
    ],
    [
        7.5723,
        26.1637
    ],
    [
        7.5748,
        26.0601
    ],
    [
        7.578,
        25.9564
    ],
    [
        7.5804,
        25.8528
    ],
    [
        7.5819,
        25.7492
    ],
    [
        7.5846,
        25.6456
    ],
    [
        7.5872,
        25.542
    ],
    [
        7.5898,
        25.4384
    ],
    [
        7.5921,
        25.3347
    ],
    [
        7.5945,
        25.2311
    ],
    [
        7.5977,
        25.1275
    ],
    [
        7.6004,
        25.0239
    ],
    [
        7.6022,
        24.9203
    ],
    [
        7.6047,
        24.8166
    ],
    [
        7.6068,
        24.713
    ],
    [
        7.6093,
        24.6094
    ],
    [
        7.612,
        24.5058
    ],
    [
        7.6148,
        24.4022
    ],
    [
        7.6175,
        24.2985
    ],
    [
        7.6192,
        24.1949
    ],
    [
        7.6221,
        24.0913
    ],
    [
        7.6238,
        23.9877
    ],
    [
        7.6265,
        23.8841
    ],
    [
        7.6294,
        23.7805
    ],
    [
        7.6317,
        23.6768
    ],
    [
        7.6342,
        23.5732
    ],
    [
        7.6367,
        23.4696
    ],
    [
        7.6385,
        23.366
    ],
    [
        7.6411,
        23.2624
    ],
    [
        7.6443,
        23.1587
    ],
    [
        7.6462,
        23.0551
    ],
    [
        7.6492,
        22.9515
    ],
    [
        7.6509,
        22.8479
    ],
    [
        7.6532,
        22.7443
    ],
    [
        7.6565,
        22.6406
    ],
    [
        7.6592,
        22.537
    ],
    [
        7.6616,
        22.4334
    ],
    [
        7.6637,
        22.3298
    ],
    [
        7.6671,
        22.2262
    ],
    [
        7.669,
        22.1226
    ],
    [
        7.6715,
        22.0189
    ],
    [
        7.6744,
        21.9153
    ],
    [
        7.6774,
        21.8117
    ],
    [
        7.6794,
        21.7081
    ],
    [
        7.6815,
        21.6045
    ],
    [
        7.6838,
        21.5008
    ],
    [
        7.6869,
        21.3972
    ],
    [
        7.6898,
        21.2936
    ],
    [
        7.6925,
        21.19
    ],
    [
        7.6945,
        21.0864
    ],
    [
        7.697,
        20.9827
    ],
    [
        7.7007,
        20.8791
    ],
    [
        7.7015,
        20.7755
    ],
    [
        7.7047,
        20.6719
    ],
    [
        7.7073,
        20.5683
    ],
    [
        7.7101,
        20.4647
    ],
    [
        7.7118,
        20.361
    ],
    [
        7.7146,
        20.2574
    ],
    [
        7.7174,
        20.1538
    ],
    [
        7.7196,
        20.0502
    ],
    [
        7.7224,
        19.9466
    ],
    [
        7.7239,
        19.8429
    ],
    [
        7.7268,
        19.7393
    ],
    [
        7.7289,
        19.6357
    ],
    [
        7.7325,
        19.5321
    ],
    [
        7.7342,
        19.4285
    ],
    [
        7.7361,
        19.3249
    ],
    [
        7.7384,
        19.2212
    ],
    [
        7.7414,
        19.1176
    ],
    [
        7.7434,
        19.014
    ],
    [
        7.7461,
        18.9104
    ],
    [
        7.749,
        18.8068
    ],
    [
        7.7513,
        18.7031
    ],
    [
        7.7533,
        18.5995
    ],
    [
        7.7564,
        18.4959
    ],
    [
        7.7586,
        18.3923
    ],
    [
        7.7613,
        18.2887
    ],
    [
        7.7635,
        18.185
    ],
    [
        7.7659,
        18.0814
    ],
    [
        7.7688,
        17.9778
    ],
    [
        7.7711,
        17.8742
    ],
    [
        7.7736,
        17.7706
    ],
    [
        7.776,
        17.667
    ],
    [
        7.7787,
        17.5633
    ],
    [
        7.7808,
        17.4597
    ],
    [
        7.7831,
        17.3561
    ],
    [
        7.7863,
        17.2525
    ],
    [
        7.7878,
        17.1489
    ],
    [
        7.7901,
        17.0452
    ],
    [
        7.7924,
        16.9416
    ],
    [
        7.7954,
        16.838
    ],
    [
        7.7973,
        16.7344
    ],
    [
        7.8004,
        16.6308
    ],
    [
        7.8021,
        16.5272
    ],
    [
        7.8047,
        16.4235
    ],
    [
        7.8072,
        16.3199
    ],
    [
        7.8109,
        16.2163
    ],
    [
        7.8121,
        16.1127
    ],
    [
        7.814,
        16.0091
    ],
    [
        7.8168,
        15.9054
    ],
    [
        7.819,
        15.8018
    ],
    [
        7.8222,
        15.6982
    ],
    [
        7.8237,
        15.5946
    ],
    [
        7.8269,
        15.491
    ],
    [
        7.8296,
        15.3873
    ],
    [
        7.8313,
        15.2837
    ],
    [
        7.8346,
        15.1801
    ],
    [
        7.8365,
        15.0765
    ],
    [
        7.8393,
        14.9729
    ],
    [
        7.841,
        14.8693
    ],
    [
        7.8445,
        14.7656
    ],
    [
        7.847,
        14.662
    ],
    [
        7.8492,
        14.5584
    ],
    [
        7.852,
        14.4548
    ],
    [
        7.8538,
        14.3512
    ],
    [
        7.8562,
        14.2475
    ],
    [
        7.8593,
        14.1439
    ],
    [
        7.8611,
        14.0403
    ],
    [
        7.8637,
        13.9367
    ],
    [
        7.8662,
        13.8331
    ],
    [
        7.8685,
        13.7295
    ],
    [
        7.8713,
        13.6258
    ],
    [
        7.8732,
        13.5222
    ],
    [
        7.8761,
        13.4186
    ],
    [
        7.8778,
        13.315
    ],
    [
        7.8805,
        13.2114
    ],
    [
        7.8831,
        13.1077
    ],
    [
        7.8852,
        13.0041
    ],
    [
        7.8887,
        12.9005
    ],
    [
        7.8909,
        12.7969
    ],
    [
        7.893,
        12.6933
    ],
    [
        7.8956,
        12.5897
    ],
    [
        7.8975,
        12.486
    ],
    [
        7.9,
        12.3824
    ],
    [
        7.9025,
        12.2788
    ],
    [
        7.9048,
        12.1752
    ],
    [
        7.9076,
        12.0716
    ],
    [
        7.9099,
        11.9679
    ],
    [
        7.9119,
        11.8643
    ],
    [
        7.9145,
        11.7607
    ],
    [
        7.9175,
        11.6571
    ],
    [
        7.9199,
        11.5535
    ],
    [
        7.9222,
        11.4498
    ],
    [
        7.9249,
        11.3462
    ],
    [
        7.9267,
        11.2426
    ],
    [
        7.929,
        11.139
    ],
    [
        7.9314,
        11.0354
    ],
    [
        7.9351,
        10.9318
    ],
    [
        7.9371,
        10.8281
    ],
    [
        7.9393,
        10.7245
    ],
    [
        7.942,
        10.6209
    ],
    [
        7.9444,
        10.5173
    ],
    [
        7.9473,
        10.4137
    ],
    [
        7.9492,
        10.31
    ],
    [
        7.9523,
        10.2064
    ],
    [
        7.9542,
        10.1028
    ],
    [
        7.9576,
        9.9992
    ],
    [
        7.9595,
        9.8956
    ],
    [
        7.9621,
        9.792
    ],
    [
        7.9649,
        9.6883
    ],
    [
        7.9678,
        9.5847
    ],
    [
        7.9693,
        9.4811
    ],
    [
        7.9718,
        9.3775
    ],
    [
        7.974,
        9.2739
    ],
    [
        7.9766,
        9.1702
    ],
    [
        7.9803,
        9.0666
    ],
    [
        7.9828,
        8.963
    ],
    [
        7.9857,
        8.8594
    ],
    [
        7.9879,
        8.7558
    ],
    [
        7.9893,
        8.6521
    ],
    [
        7.9925,
        8.5485
    ],
    [
        7.9944,
        8.4449
    ],
    [
        7.9973,
        8.3413
    ],
    [
        8.0001,
        8.2377
    ],
    [
        8.0025,
        8.1341
    ],
    [
        8.0051,
        8.0304
    ],
    [
        8.0079,
        7.9268
    ],
    [
        8.0105,
        7.8232
    ],
    [
        8.0131,
        7.7196
    ],
    [
        8.0155,
        7.616
    ],
    [
        8.0187,
        7.5123
    ],
    [
        8.021,
        7.4087
    ],
    [
        8.0234,
        7.3051
    ],
    [
        8.025,
        7.2015
    ],
    [
        8.0279,
        7.0979
    ],
    [
        8.0309,
        6.9943
    ],
    [
        8.0341,
        6.8906
    ],
    [
        8.036,
        6.787
    ],
    [
        8.039,
        6.6834
    ],
    [
        8.0414,
        6.5798
    ],
    [
        8.0435,
        6.4762
    ],
    [
        8.046,
        6.3725
    ],
    [
        8.0497,
        6.2689
    ],
    [
        8.0517,
        6.1653
    ],
    [
        8.0547,
        6.0617
    ],
    [
        8.0574,
        5.9581
    ],
    [
        8.0595,
        5.8544
    ],
    [
        8.0631,
        5.7508
    ],
    [
        8.0648,
        5.6472
    ],
    [
        8.0674,
        5.5436
    ],
    [
        8.0702,
        5.44
    ],
    [
        8.0731,
        5.3364
    ],
    [
        8.0759,
        5.2327
    ],
    [
        8.0778,
        5.1291
    ],
    [
        8.0805,
        5.0255
    ],
    [
        8.0835,
        4.9219
    ],
    [
        8.0859,
        4.8183
    ],
    [
        8.089,
        4.7146
    ],
    [
        8.0918,
        4.611
    ],
    [
        8.0939,
        4.5074
    ],
    [
        8.0966,
        4.4038
    ],
    [
        8.1002,
        4.3002
    ],
    [
        8.102,
        4.1966
    ],
    [
        8.1053,
        4.0929
    ],
    [
        8.1081,
        3.9893
    ],
    [
        8.1112,
        3.8857
    ],
    [
        8.1139,
        3.7821
    ],
    [
        8.1161,
        3.6785
    ],
    [
        8.1191,
        3.5748
    ],
    [
        8.1216,
        3.4712
    ],
    [
        8.1252,
        3.3676
    ],
    [
        8.128,
        3.264
    ],
    [
        8.1303,
        3.1604
    ],
    [
        8.1337,
        3.0567
    ],
    [
        8.1367,
        2.9531
    ],
    [
        8.1398,
        2.8495
    ],
    [
        8.142,
        2.7459
    ],
    [
        8.1457,
        2.6423
    ],
    [
        8.1483,
        2.5387
    ],
    [
        8.1519,
        2.435
    ],
    [
        8.1553,
        2.3314
    ],
    [
        8.1588,
        2.2278
    ],
    [
        8.1618,
        2.1242
    ],
    [
        8.1641,
        2.0206
    ],
    [
        8.1686,
        1.9169
    ],
    [
        8.1717,
        1.8133
    ],
    [
        8.1753,
        1.7097
    ],
    [
        8.1793,
        1.6061
    ],
    [
        8.1838,
        1.5025
    ],
    [
        8.1871,
        1.3988
    ],
    [
        8.1918,
        1.2952
    ],
    [
        8.1958,
        1.1916
    ],
    [
        8.1986,
        1.088
    ],
    [
        8.2043,
        0.9844
    ],
    [
        8.2093,
        0.8808
    ],
    [
        8.2149,
        0.7771
    ],
    [
        8.2205,
        0.6735
    ],
    [
        8.2265,
        0.5699
    ],
    [
        8.2328,
        0.4663
    ],
    [
        8.2417,
        0.3627
    ],
    [
        8.2497,
        0.259
    ],
    [
        8.2606,
        0.1554
    ],
    [
        8.275,
        0.0518
    ]
]
export const grapTwo = [
    [
        8.4002,
        95.5747
    ],
    [
        8.4,
        95.5732
    ],
    [
        8.3997,
        95.5715
    ],
    [
        8.4001,
        95.57
    ],
    [
        8.3996,
        95.5684
    ],
    [
        8.4,
        95.5666
    ],
    [
        8.3996,
        95.5649
    ],
    [
        8.3998,
        95.5631
    ],
    [
        8.4003,
        95.5612
    ],
    [
        8.3997,
        95.5593
    ],
    [
        8.4002,
        95.5573
    ],
    [
        8.3999,
        95.5553
    ],
    [
        8.4001,
        95.5533
    ],
    [
        8.4003,
        95.5515
    ],
    [
        8.4004,
        95.5494
    ],
    [
        8.4001,
        95.5474
    ],
    [
        8.4003,
        95.5452
    ],
    [
        8.3993,
        95.5431
    ],
    [
        8.3997,
        95.541
    ],
    [
        8.3993,
        95.5389
    ],
    [
        8.4003,
        95.5371
    ],
    [
        8.4005,
        95.5351
    ],
    [
        8.4002,
        95.533
    ],
    [
        8.4001,
        95.5308
    ],
    [
        8.3998,
        95.5286
    ],
    [
        8.4002,
        95.5265
    ],
    [
        8.3993,
        95.5245
    ],
    [
        8.3993,
        95.5224
    ],
    [
        8.4005,
        95.5205
    ],
    [
        8.4002,
        95.5187
    ],
    [
        8.4003,
        95.5166
    ],
    [
        8.4001,
        95.5144
    ],
    [
        8.3998,
        95.5122
    ],
    [
        8.3999,
        95.5099
    ],
    [
        8.3996,
        95.5076
    ],
    [
        8.4,
        95.5055
    ],
    [
        8.4004,
        95.5035
    ],
    [
        8.3995,
        95.5012
    ],
    [
        8.3998,
        95.4987
    ],
    [
        8.4004,
        95.4964
    ],
    [
        8.4003,
        95.4941
    ],
    [
        8.4003,
        95.4918
    ],
    [
        8.4,
        95.4894
    ],
    [
        8.3999,
        95.487
    ],
    [
        8.4006,
        95.4845
    ],
    [
        8.4001,
        95.4821
    ],
    [
        8.3998,
        95.4796
    ],
    [
        8.4,
        95.477
    ],
    [
        8.4005,
        95.4745
    ],
    [
        8.4,
        95.4721
    ],
    [
        8.4003,
        95.4695
    ],
    [
        8.3999,
        95.4671
    ],
    [
        8.3998,
        95.4646
    ],
    [
        8.4003,
        95.462
    ],
    [
        8.3997,
        95.4593
    ],
    [
        8.4003,
        95.4565
    ],
    [
        8.4008,
        95.4538
    ],
    [
        8.4,
        95.4511
    ],
    [
        8.3995,
        95.4483
    ],
    [
        8.4001,
        95.4456
    ],
    [
        8.4005,
        95.4429
    ],
    [
        8.3999,
        95.4401
    ],
    [
        8.3998,
        95.4372
    ],
    [
        8.4003,
        95.4341
    ],
    [
        8.4005,
        95.4313
    ],
    [
        8.3997,
        95.4287
    ],
    [
        8.3999,
        95.4261
    ],
    [
        8.4002,
        95.4234
    ],
    [
        8.3999,
        95.4206
    ],
    [
        8.4001,
        95.4176
    ],
    [
        8.4001,
        95.4145
    ],
    [
        8.3992,
        95.4115
    ],
    [
        8.4,
        95.4086
    ],
    [
        8.3998,
        95.4056
    ],
    [
        8.4002,
        95.4024
    ],
    [
        8.3997,
        95.3992
    ],
    [
        8.3999,
        95.3961
    ],
    [
        8.4005,
        95.393
    ],
    [
        8.4005,
        95.3899
    ],
    [
        8.4004,
        95.3865
    ],
    [
        8.4008,
        95.3831
    ],
    [
        8.3997,
        95.3798
    ],
    [
        8.4001,
        95.3766
    ],
    [
        8.4,
        95.3732
    ],
    [
        8.3997,
        95.3698
    ],
    [
        8.4002,
        95.3664
    ],
    [
        8.3997,
        95.363
    ],
    [
        8.4001,
        95.3596
    ],
    [
        8.3999,
        95.3563
    ],
    [
        8.3997,
        95.3529
    ],
    [
        8.3999,
        95.3495
    ],
    [
        8.4001,
        95.3461
    ],
    [
        8.3996,
        95.3427
    ],
    [
        8.4006,
        95.3392
    ],
    [
        8.4003,
        95.3356
    ],
    [
        8.4001,
        95.3317
    ],
    [
        8.4004,
        95.328
    ],
    [
        8.3998,
        95.3244
    ],
    [
        8.3997,
        95.321
    ],
    [
        8.4,
        95.3175
    ],
    [
        8.3998,
        95.3138
    ],
    [
        8.3991,
        95.3102
    ],
    [
        8.3995,
        95.3066
    ],
    [
        8.4004,
        95.3028
    ],
    [
        8.4002,
        95.299
    ],
    [
        8.3995,
        95.2953
    ],
    [
        8.4001,
        95.2914
    ],
    [
        8.4004,
        95.2872
    ],
    [
        8.3998,
        95.283
    ],
    [
        8.3999,
        95.2786
    ],
    [
        8.4005,
        95.2745
    ],
    [
        8.3995,
        95.2704
    ],
    [
        8.4001,
        95.2662
    ],
    [
        8.3993,
        95.2619
    ],
    [
        8.3997,
        95.2578
    ],
    [
        8.4006,
        95.2535
    ],
    [
        8.4006,
        95.2493
    ],
    [
        8.3997,
        95.2452
    ],
    [
        8.4,
        95.2411
    ],
    [
        8.4,
        95.237
    ],
    [
        8.3999,
        95.233
    ],
    [
        8.3991,
        95.2286
    ],
    [
        8.4007,
        95.2243
    ],
    [
        8.4001,
        95.2199
    ],
    [
        8.3999,
        95.2154
    ],
    [
        8.3995,
        95.2106
    ],
    [
        8.4002,
        95.2057
    ],
    [
        8.3997,
        95.2008
    ],
    [
        8.3994,
        95.196
    ],
    [
        8.4002,
        95.1913
    ],
    [
        8.4001,
        95.1864
    ],
    [
        8.4005,
        95.1815
    ],
    [
        8.3999,
        95.1766
    ],
    [
        8.4004,
        95.1716
    ],
    [
        8.3997,
        95.1667
    ],
    [
        8.3997,
        95.1618
    ],
    [
        8.3997,
        95.1566
    ],
    [
        8.3999,
        95.1515
    ],
    [
        8.3998,
        95.1464
    ],
    [
        8.3996,
        95.1414
    ],
    [
        8.4008,
        95.1364
    ],
    [
        8.4,
        95.1315
    ],
    [
        8.3995,
        95.1264
    ],
    [
        8.4004,
        95.1212
    ],
    [
        8.3997,
        95.116
    ],
    [
        8.4,
        95.1109
    ],
    [
        8.4003,
        95.1058
    ],
    [
        8.4001,
        95.1007
    ],
    [
        8.3995,
        95.0952
    ],
    [
        8.4,
        95.0898
    ],
    [
        8.4001,
        95.0846
    ],
    [
        8.4007,
        95.0791
    ],
    [
        8.3998,
        95.0737
    ],
    [
        8.4004,
        95.0681
    ],
    [
        8.3998,
        95.0625
    ],
    [
        8.4006,
        95.0569
    ],
    [
        8.3995,
        95.0513
    ],
    [
        8.4001,
        95.0459
    ],
    [
        8.4005,
        95.0403
    ],
    [
        8.3996,
        95.0343
    ],
    [
        8.4001,
        95.0281
    ],
    [
        8.3996,
        95.022
    ],
    [
        8.4,
        95.0159
    ],
    [
        8.4004,
        95.0099
    ],
    [
        8.3994,
        95.0037
    ],
    [
        8.4006,
        94.9976
    ],
    [
        8.3999,
        94.9913
    ],
    [
        8.4002,
        94.9849
    ],
    [
        8.4003,
        94.9784
    ],
    [
        8.3995,
        94.972
    ],
    [
        8.401,
        94.9654
    ],
    [
        8.4007,
        94.9588
    ],
    [
        8.3999,
        94.9521
    ],
    [
        8.3998,
        94.9454
    ],
    [
        8.4001,
        94.9386
    ],
    [
        8.4003,
        94.932
    ],
    [
        8.3999,
        94.925
    ],
    [
        8.4003,
        94.9179
    ],
    [
        8.4004,
        94.9105
    ],
    [
        8.401,
        94.903
    ],
    [
        8.4001,
        94.8959
    ],
    [
        8.4004,
        94.8885
    ],
    [
        8.3997,
        94.881
    ],
    [
        8.4003,
        94.8737
    ],
    [
        8.4001,
        94.8662
    ],
    [
        8.4007,
        94.8588
    ],
    [
        8.4002,
        94.8512
    ],
    [
        8.4,
        94.8436
    ],
    [
        8.3999,
        94.8358
    ],
    [
        8.4003,
        94.8279
    ],
    [
        8.4,
        94.82
    ],
    [
        8.3999,
        94.812
    ],
    [
        8.3998,
        94.804
    ],
    [
        8.4002,
        94.7959
    ],
    [
        8.4003,
        94.7876
    ],
    [
        8.4001,
        94.7792
    ],
    [
        8.4003,
        94.7705
    ],
    [
        8.3996,
        94.762
    ],
    [
        8.3998,
        94.7534
    ],
    [
        8.4,
        94.7447
    ],
    [
        8.4,
        94.736
    ],
    [
        8.4005,
        94.7273
    ],
    [
        8.3996,
        94.7184
    ],
    [
        8.3999,
        94.7097
    ],
    [
        8.4008,
        94.7011
    ],
    [
        8.3994,
        94.6925
    ],
    [
        8.3997,
        94.6837
    ],
    [
        8.4001,
        94.6748
    ],
    [
        8.3999,
        94.6655
    ],
    [
        8.3999,
        94.6562
    ],
    [
        8.3996,
        94.6468
    ],
    [
        8.4,
        94.6373
    ],
    [
        8.3999,
        94.6276
    ],
    [
        8.3999,
        94.6177
    ],
    [
        8.3996,
        94.6079
    ],
    [
        8.4,
        94.5981
    ],
    [
        8.4005,
        94.5883
    ],
    [
        8.4002,
        94.5783
    ],
    [
        8.3997,
        94.5681
    ],
    [
        8.4003,
        94.5578
    ],
    [
        8.3999,
        94.5474
    ],
    [
        8.4003,
        94.5369
    ],
    [
        8.3999,
        94.5262
    ],
    [
        8.4001,
        94.5153
    ],
    [
        8.3998,
        94.5044
    ],
    [
        8.3997,
        94.4936
    ],
    [
        8.3997,
        94.4828
    ],
    [
        8.4005,
        94.4718
    ],
    [
        8.4001,
        94.4607
    ],
    [
        8.3996,
        94.4494
    ],
    [
        8.4001,
        94.438
    ],
    [
        8.3999,
        94.4264
    ],
    [
        8.4004,
        94.4147
    ],
    [
        8.4,
        94.4032
    ],
    [
        8.4005,
        94.3913
    ],
    [
        8.4004,
        94.3792
    ],
    [
        8.4003,
        94.3669
    ],
    [
        8.3996,
        94.3545
    ],
    [
        8.3997,
        94.3419
    ],
    [
        8.4003,
        94.3293
    ],
    [
        8.3994,
        94.3164
    ],
    [
        8.4,
        94.3036
    ],
    [
        8.4001,
        94.2907
    ],
    [
        8.4006,
        94.2776
    ],
    [
        8.3988,
        94.2643
    ],
    [
        8.4003,
        94.2512
    ],
    [
        8.4001,
        94.2376
    ],
    [
        8.4004,
        94.2239
    ],
    [
        8.4004,
        94.2098
    ],
    [
        8.3996,
        94.1957
    ],
    [
        8.3999,
        94.1813
    ],
    [
        8.3997,
        94.1669
    ],
    [
        8.4003,
        94.1525
    ],
    [
        8.4003,
        94.1381
    ],
    [
        8.3999,
        94.1234
    ],
    [
        8.4003,
        94.1084
    ],
    [
        8.4,
        94.0931
    ],
    [
        8.3999,
        94.0778
    ],
    [
        8.4005,
        94.0624
    ],
    [
        8.3999,
        94.0469
    ],
    [
        8.4005,
        94.0311
    ],
    [
        8.3997,
        94.0152
    ],
    [
        8.3997,
        93.999
    ],
    [
        8.4003,
        93.9825
    ],
    [
        8.3993,
        93.9661
    ],
    [
        8.3995,
        93.9498
    ],
    [
        8.3998,
        93.9334
    ],
    [
        8.4,
        93.9165
    ],
    [
        8.4002,
        93.8995
    ],
    [
        8.4001,
        93.8823
    ],
    [
        8.3996,
        93.8648
    ],
    [
        8.3996,
        93.8472
    ],
    [
        8.4005,
        93.8295
    ],
    [
        8.4,
        93.8114
    ],
    [
        8.4001,
        93.7931
    ],
    [
        8.4,
        93.7748
    ],
    [
        8.3998,
        93.7561
    ],
    [
        8.4007,
        93.7376
    ],
    [
        8.3996,
        93.7186
    ],
    [
        8.4002,
        93.6993
    ],
    [
        8.4005,
        93.6801
    ],
    [
        8.4002,
        93.6607
    ],
    [
        8.4005,
        93.641
    ],
    [
        8.3997,
        93.6209
    ],
    [
        8.4003,
        93.6006
    ],
    [
        8.3998,
        93.5802
    ],
    [
        8.4003,
        93.5597
    ],
    [
        8.3996,
        93.5389
    ],
    [
        8.3996,
        93.5178
    ],
    [
        8.4006,
        93.4965
    ],
    [
        8.3991,
        93.4749
    ],
    [
        8.3999,
        93.4531
    ],
    [
        8.3999,
        93.4307
    ],
    [
        8.4,
        93.4081
    ],
    [
        8.3998,
        93.3853
    ],
    [
        8.3998,
        93.3622
    ],
    [
        8.4001,
        93.3392
    ],
    [
        8.4003,
        93.316
    ],
    [
        8.4002,
        93.2925
    ],
    [
        8.4004,
        93.2687
    ],
    [
        8.4004,
        93.2444
    ],
    [
        8.3996,
        93.2199
    ],
    [
        8.4005,
        93.1953
    ],
    [
        8.3999,
        93.1704
    ],
    [
        8.4001,
        93.1452
    ],
    [
        8.401,
        93.1198
    ],
    [
        8.3998,
        93.0941
    ],
    [
        8.4,
        93.0681
    ],
    [
        8.3998,
        93.0417
    ],
    [
        8.4003,
        93.015
    ],
    [
        8.3997,
        92.9882
    ],
    [
        8.3998,
        92.961
    ],
    [
        8.3998,
        92.9336
    ],
    [
        8.4007,
        92.9058
    ],
    [
        8.4007,
        92.8778
    ],
    [
        8.4003,
        92.8492
    ],
    [
        8.4004,
        92.8203
    ],
    [
        8.3996,
        92.7909
    ],
    [
        8.3999,
        92.7613
    ],
    [
        8.4,
        92.7314
    ],
    [
        8.4003,
        92.7013
    ],
    [
        8.3996,
        92.6708
    ],
    [
        8.3999,
        92.6399
    ],
    [
        8.4005,
        92.6087
    ],
    [
        8.4005,
        92.5772
    ],
    [
        8.3999,
        92.5451
    ],
    [
        8.4,
        92.5128
    ],
    [
        8.4001,
        92.4801
    ],
    [
        8.4003,
        92.4472
    ],
    [
        8.4006,
        92.4141
    ],
    [
        8.3997,
        92.3805
    ],
    [
        8.3999,
        92.3465
    ],
    [
        8.3998,
        92.312
    ],
    [
        8.4005,
        92.2773
    ],
    [
        8.4,
        92.2423
    ],
    [
        8.4001,
        92.2069
    ],
    [
        8.3997,
        92.171
    ],
    [
        8.3995,
        92.1345
    ],
    [
        8.4002,
        92.0976
    ],
    [
        8.4003,
        92.0603
    ],
    [
        8.3991,
        92.0226
    ],
    [
        8.4006,
        91.9846
    ],
    [
        8.4001,
        91.9461
    ],
    [
        8.4004,
        91.9074
    ],
    [
        8.4,
        91.868
    ],
    [
        8.4002,
        91.8283
    ],
    [
        8.3999,
        91.7879
    ],
    [
        8.4004,
        91.7469
    ],
    [
        8.4005,
        91.7055
    ],
    [
        8.3997,
        91.6636
    ],
    [
        8.3998,
        91.6214
    ],
    [
        8.4009,
        91.5788
    ],
    [
        8.3998,
        91.5353
    ],
    [
        8.4,
        91.4914
    ],
    [
        8.3994,
        91.4471
    ],
    [
        8.4004,
        91.4023
    ],
    [
        8.3997,
        91.3569
    ],
    [
        8.4003,
        91.3109
    ],
    [
        8.4003,
        91.2644
    ],
    [
        8.3997,
        91.2176
    ],
    [
        8.3998,
        91.1701
    ],
    [
        8.3999,
        91.1221
    ],
    [
        8.4001,
        91.0735
    ],
    [
        8.4002,
        91.0244
    ],
    [
        8.3999,
        90.9748
    ],
    [
        8.3998,
        90.9244
    ],
    [
        8.4,
        90.8736
    ],
    [
        8.4005,
        90.8221
    ],
    [
        8.4001,
        90.7699
    ],
    [
        8.4012,
        90.7169
    ],
    [
        8.4004,
        90.6633
    ],
    [
        8.3999,
        90.6091
    ],
    [
        8.4002,
        90.5542
    ],
    [
        8.3998,
        90.4985
    ],
    [
        8.4003,
        90.4421
    ],
    [
        8.3999,
        90.3853
    ],
    [
        8.4007,
        90.3279
    ],
    [
        8.4007,
        90.2698
    ],
    [
        8.3998,
        90.2111
    ],
    [
        8.4003,
        90.1517
    ],
    [
        8.4002,
        90.0915
    ],
    [
        8.3997,
        90.0305
    ],
    [
        8.4006,
        89.9688
    ],
    [
        8.4002,
        89.9061
    ],
    [
        8.4001,
        89.8427
    ],
    [
        8.4004,
        89.7784
    ],
    [
        8.4002,
        89.7136
    ],
    [
        8.4,
        89.6481
    ],
    [
        8.4002,
        89.5819
    ],
    [
        8.4001,
        89.5149
    ],
    [
        8.4006,
        89.447
    ],
    [
        8.4004,
        89.3783
    ],
    [
        8.4002,
        89.3086
    ],
    [
        8.4,
        89.2381
    ],
    [
        8.4002,
        89.1667
    ],
    [
        8.4001,
        89.0945
    ],
    [
        8.4,
        89.0212
    ],
    [
        8.4002,
        88.947
    ],
    [
        8.3998,
        88.8721
    ],
    [
        8.4011,
        88.7962
    ],
    [
        8.3996,
        88.7191
    ],
    [
        8.4,
        88.6411
    ],
    [
        8.4001,
        88.5622
    ],
    [
        8.3998,
        88.4824
    ],
    [
        8.4002,
        88.4015
    ],
    [
        8.4004,
        88.3195
    ],
    [
        8.4007,
        88.2365
    ],
    [
        8.4002,
        88.1524
    ],
    [
        8.4009,
        88.0672
    ],
    [
        8.3999,
        87.9807
    ],
    [
        8.4002,
        87.8932
    ],
    [
        8.4,
        87.8044
    ],
    [
        8.4005,
        87.7144
    ],
    [
        8.4006,
        87.623
    ],
    [
        8.4002,
        87.5303
    ],
    [
        8.4007,
        87.4361
    ],
    [
        8.4005,
        87.3405
    ],
    [
        8.3996,
        87.2433
    ],
    [
        8.4003,
        87.1445
    ],
    [
        8.4003,
        87.0438
    ],
    [
        8.4003,
        86.9411
    ],
    [
        8.3979,
        86.8376
    ],
    [
        8.3958,
        86.7339
    ],
    [
        8.3923,
        86.6303
    ],
    [
        8.39,
        86.5267
    ],
    [
        8.3878,
        86.4231
    ],
    [
        8.3849,
        86.3195
    ],
    [
        8.3823,
        86.2159
    ],
    [
        8.3791,
        86.1123
    ],
    [
        8.378,
        86.0087
    ],
    [
        8.3749,
        85.905
    ],
    [
        8.3729,
        85.8014
    ],
    [
        8.3702,
        85.6978
    ],
    [
        8.368,
        85.5942
    ],
    [
        8.3662,
        85.4906
    ],
    [
        8.3634,
        85.387
    ],
    [
        8.3602,
        85.2834
    ],
    [
        8.3585,
        85.1798
    ],
    [
        8.3559,
        85.0762
    ],
    [
        8.3538,
        84.9725
    ],
    [
        8.3516,
        84.8689
    ],
    [
        8.3493,
        84.7653
    ],
    [
        8.3462,
        84.6617
    ],
    [
        8.3435,
        84.5581
    ],
    [
        8.3423,
        84.4545
    ],
    [
        8.3389,
        84.3509
    ],
    [
        8.337,
        84.2473
    ],
    [
        8.3339,
        84.1436
    ],
    [
        8.3318,
        84.04
    ],
    [
        8.3295,
        83.9364
    ],
    [
        8.3273,
        83.8328
    ],
    [
        8.3248,
        83.7292
    ],
    [
        8.3219,
        83.6256
    ],
    [
        8.3195,
        83.522
    ],
    [
        8.3174,
        83.4184
    ],
    [
        8.3147,
        83.3147
    ],
    [
        8.3128,
        83.2111
    ],
    [
        8.3102,
        83.1075
    ],
    [
        8.3077,
        83.0039
    ],
    [
        8.3053,
        82.9003
    ],
    [
        8.3036,
        82.7967
    ],
    [
        8.3009,
        82.6931
    ],
    [
        8.2983,
        82.5895
    ],
    [
        8.2965,
        82.4859
    ],
    [
        8.2936,
        82.3823
    ],
    [
        8.2916,
        82.2786
    ],
    [
        8.2888,
        82.175
    ],
    [
        8.2865,
        82.0714
    ],
    [
        8.2838,
        81.9678
    ],
    [
        8.2817,
        81.8642
    ],
    [
        8.2792,
        81.7606
    ],
    [
        8.2776,
        81.657
    ],
    [
        8.2754,
        81.5534
    ],
    [
        8.2723,
        81.4498
    ],
    [
        8.2693,
        81.3461
    ],
    [
        8.2673,
        81.2425
    ],
    [
        8.2654,
        81.1389
    ],
    [
        8.2623,
        81.0353
    ],
    [
        8.2609,
        80.9317
    ],
    [
        8.2577,
        80.8281
    ],
    [
        8.2556,
        80.7245
    ],
    [
        8.254,
        80.6209
    ],
    [
        8.2508,
        80.5172
    ],
    [
        8.2486,
        80.4136
    ],
    [
        8.2465,
        80.31
    ],
    [
        8.244,
        80.2064
    ],
    [
        8.2422,
        80.1028
    ],
    [
        8.2398,
        79.9992
    ],
    [
        8.2373,
        79.8956
    ],
    [
        8.2347,
        79.792
    ],
    [
        8.2325,
        79.6883
    ],
    [
        8.2301,
        79.5847
    ],
    [
        8.2278,
        79.4811
    ],
    [
        8.225,
        79.3775
    ],
    [
        8.2232,
        79.2739
    ],
    [
        8.2211,
        79.1703
    ],
    [
        8.2185,
        79.0667
    ],
    [
        8.2163,
        78.9631
    ],
    [
        8.2142,
        78.8594
    ],
    [
        8.2114,
        78.7558
    ],
    [
        8.2091,
        78.6522
    ],
    [
        8.2061,
        78.5486
    ],
    [
        8.2038,
        78.445
    ],
    [
        8.2029,
        78.3414
    ],
    [
        8.2001,
        78.2378
    ],
    [
        8.1973,
        78.1342
    ],
    [
        8.1959,
        78.0306
    ],
    [
        8.1931,
        77.9269
    ],
    [
        8.1907,
        77.8233
    ],
    [
        8.1889,
        77.7197
    ],
    [
        8.1873,
        77.6161
    ],
    [
        8.1847,
        77.5125
    ],
    [
        8.1827,
        77.4089
    ],
    [
        8.1798,
        77.3053
    ],
    [
        8.1777,
        77.2017
    ],
    [
        8.1763,
        77.098
    ],
    [
        8.1735,
        76.9944
    ],
    [
        8.1705,
        76.8908
    ],
    [
        8.1684,
        76.7872
    ],
    [
        8.1661,
        76.6836
    ],
    [
        8.1643,
        76.58
    ],
    [
        8.1625,
        76.4764
    ],
    [
        8.1592,
        76.3728
    ],
    [
        8.157,
        76.2691
    ],
    [
        8.1548,
        76.1655
    ],
    [
        8.1526,
        76.0619
    ],
    [
        8.1497,
        75.9583
    ],
    [
        8.148,
        75.8547
    ],
    [
        8.1463,
        75.7511
    ],
    [
        8.143,
        75.6475
    ],
    [
        8.1419,
        75.5439
    ],
    [
        8.1401,
        75.4402
    ],
    [
        8.1375,
        75.3366
    ],
    [
        8.1344,
        75.233
    ],
    [
        8.1327,
        75.1294
    ],
    [
        8.1306,
        75.0258
    ],
    [
        8.1285,
        74.9222
    ],
    [
        8.1258,
        74.8186
    ],
    [
        8.1236,
        74.715
    ],
    [
        8.1215,
        74.6114
    ],
    [
        8.1199,
        74.5077
    ],
    [
        8.1174,
        74.4041
    ],
    [
        8.1148,
        74.3005
    ],
    [
        8.1122,
        74.1969
    ],
    [
        8.1115,
        74.0933
    ],
    [
        8.1091,
        73.9897
    ],
    [
        8.1073,
        73.8861
    ],
    [
        8.1043,
        73.7825
    ],
    [
        8.1019,
        73.6788
    ],
    [
        8.0998,
        73.5752
    ],
    [
        8.0978,
        73.4716
    ],
    [
        8.095,
        73.368
    ],
    [
        8.0929,
        73.2644
    ],
    [
        8.091,
        73.1608
    ],
    [
        8.0893,
        73.0572
    ],
    [
        8.0863,
        72.9535
    ],
    [
        8.084,
        72.8499
    ],
    [
        8.0828,
        72.7463
    ],
    [
        8.0806,
        72.6427
    ],
    [
        8.0779,
        72.5391
    ],
    [
        8.0762,
        72.4355
    ],
    [
        8.0732,
        72.3319
    ],
    [
        8.0724,
        72.2283
    ],
    [
        8.0695,
        72.1246
    ],
    [
        8.0676,
        72.021
    ],
    [
        8.0658,
        71.9174
    ],
    [
        8.0633,
        71.8138
    ],
    [
        8.0611,
        71.7102
    ],
    [
        8.0588,
        71.6066
    ],
    [
        8.057,
        71.503
    ],
    [
        8.0538,
        71.3994
    ],
    [
        8.0523,
        71.2958
    ],
    [
        8.0498,
        71.1921
    ],
    [
        8.0479,
        71.0885
    ],
    [
        8.046,
        70.9849
    ],
    [
        8.0445,
        70.8813
    ],
    [
        8.0422,
        70.7777
    ],
    [
        8.0393,
        70.6741
    ],
    [
        8.037,
        70.5705
    ],
    [
        8.035,
        70.4669
    ],
    [
        8.0336,
        70.3632
    ],
    [
        8.0313,
        70.2596
    ],
    [
        8.0283,
        70.156
    ],
    [
        8.0272,
        70.0524
    ],
    [
        8.0244,
        69.9488
    ],
    [
        8.0229,
        69.8452
    ],
    [
        8.0206,
        69.7416
    ],
    [
        8.0186,
        69.6379
    ],
    [
        8.0162,
        69.5343
    ],
    [
        8.0135,
        69.4307
    ],
    [
        8.0121,
        69.3271
    ],
    [
        8.0096,
        69.2235
    ],
    [
        8.0078,
        69.1199
    ],
    [
        8.0058,
        69.0163
    ],
    [
        8.0037,
        68.9127
    ],
    [
        8.002,
        68.809
    ],
    [
        7.9992,
        68.7054
    ],
    [
        7.9971,
        68.6018
    ],
    [
        7.9955,
        68.4982
    ],
    [
        7.9928,
        68.3946
    ],
    [
        7.9909,
        68.291
    ],
    [
        7.9892,
        68.1874
    ],
    [
        7.9875,
        68.0838
    ],
    [
        7.9843,
        67.9801
    ],
    [
        7.9824,
        67.8765
    ],
    [
        7.9805,
        67.7729
    ],
    [
        7.9782,
        67.6693
    ],
    [
        7.9759,
        67.5657
    ],
    [
        7.9743,
        67.4621
    ],
    [
        7.9721,
        67.3585
    ],
    [
        7.9695,
        67.2549
    ],
    [
        7.9687,
        67.1512
    ],
    [
        7.9657,
        67.0476
    ],
    [
        7.964,
        66.944
    ],
    [
        7.9614,
        66.8404
    ],
    [
        7.9595,
        66.7368
    ],
    [
        7.9576,
        66.6332
    ],
    [
        7.9552,
        66.5296
    ],
    [
        7.9533,
        66.426
    ],
    [
        7.951,
        66.3223
    ],
    [
        7.9488,
        66.2187
    ],
    [
        7.9467,
        66.1151
    ],
    [
        7.9449,
        66.0115
    ],
    [
        7.9431,
        65.9079
    ],
    [
        7.9412,
        65.8043
    ],
    [
        7.939,
        65.7007
    ],
    [
        7.9362,
        65.5971
    ],
    [
        7.9349,
        65.4934
    ],
    [
        7.9333,
        65.3898
    ],
    [
        7.931,
        65.2862
    ],
    [
        7.9286,
        65.1826
    ],
    [
        7.9265,
        65.079
    ],
    [
        7.9248,
        64.9754
    ],
    [
        7.9224,
        64.8718
    ],
    [
        7.9202,
        64.7682
    ],
    [
        7.9188,
        64.6645
    ],
    [
        7.9175,
        64.5609
    ],
    [
        7.9144,
        64.4573
    ],
    [
        7.9122,
        64.3537
    ],
    [
        7.9099,
        64.2501
    ],
    [
        7.9087,
        64.1465
    ],
    [
        7.9065,
        64.0429
    ],
    [
        7.9049,
        63.9393
    ],
    [
        7.9024,
        63.8356
    ],
    [
        7.9005,
        63.732
    ],
    [
        7.8981,
        63.6284
    ],
    [
        7.8957,
        63.5248
    ],
    [
        7.8944,
        63.4212
    ],
    [
        7.8926,
        63.3176
    ],
    [
        7.8904,
        63.214
    ],
    [
        7.8886,
        63.1104
    ],
    [
        7.8865,
        63.0067
    ],
    [
        7.8836,
        62.9031
    ],
    [
        7.8824,
        62.7995
    ],
    [
        7.8808,
        62.6959
    ],
    [
        7.8784,
        62.5923
    ],
    [
        7.8758,
        62.4887
    ],
    [
        7.8743,
        62.3851
    ],
    [
        7.8724,
        62.2815
    ],
    [
        7.8704,
        62.1778
    ],
    [
        7.8679,
        62.0742
    ],
    [
        7.8657,
        61.9706
    ],
    [
        7.8648,
        61.867
    ],
    [
        7.8621,
        61.7634
    ],
    [
        7.8607,
        61.6598
    ],
    [
        7.8589,
        61.5562
    ],
    [
        7.8567,
        61.4526
    ],
    [
        7.8552,
        61.3489
    ],
    [
        7.8528,
        61.2453
    ],
    [
        7.8509,
        61.1417
    ],
    [
        7.8494,
        61.0381
    ],
    [
        7.8472,
        60.9345
    ],
    [
        7.8454,
        60.8309
    ],
    [
        7.8435,
        60.7273
    ],
    [
        7.8412,
        60.6237
    ],
    [
        7.8391,
        60.5201
    ],
    [
        7.837,
        60.4164
    ],
    [
        7.8353,
        60.3128
    ],
    [
        7.8327,
        60.2092
    ],
    [
        7.8315,
        60.1056
    ],
    [
        7.8295,
        60.002
    ],
    [
        7.8278,
        59.8984
    ],
    [
        7.8266,
        59.7948
    ],
    [
        7.8234,
        59.6912
    ],
    [
        7.8214,
        59.5875
    ],
    [
        7.8199,
        59.4839
    ],
    [
        7.8178,
        59.3803
    ],
    [
        7.816,
        59.2767
    ],
    [
        7.8143,
        59.1731
    ],
    [
        7.8125,
        59.0695
    ],
    [
        7.8104,
        58.9659
    ],
    [
        7.8086,
        58.8623
    ],
    [
        7.806,
        58.7586
    ],
    [
        7.805,
        58.655
    ],
    [
        7.8037,
        58.5514
    ],
    [
        7.803,
        58.4478
    ],
    [
        7.7996,
        58.3442
    ],
    [
        7.7969,
        58.2406
    ],
    [
        7.7954,
        58.137
    ],
    [
        7.7933,
        58.0334
    ],
    [
        7.7915,
        57.9297
    ],
    [
        7.7899,
        57.8261
    ],
    [
        7.7881,
        57.7225
    ],
    [
        7.7866,
        57.6189
    ],
    [
        7.7841,
        57.5153
    ],
    [
        7.7822,
        57.4117
    ],
    [
        7.7806,
        57.3081
    ],
    [
        7.7785,
        57.2045
    ],
    [
        7.7768,
        57.1008
    ],
    [
        7.7752,
        56.9972
    ],
    [
        7.7733,
        56.8936
    ],
    [
        7.7712,
        56.79
    ],
    [
        7.7701,
        56.6864
    ],
    [
        7.7682,
        56.5828
    ],
    [
        7.7659,
        56.4792
    ],
    [
        7.7645,
        56.3756
    ],
    [
        7.7626,
        56.2719
    ],
    [
        7.761,
        56.1683
    ],
    [
        7.7586,
        56.0647
    ],
    [
        7.7569,
        55.9611
    ],
    [
        7.7559,
        55.8575
    ],
    [
        7.7533,
        55.7539
    ],
    [
        7.752,
        55.6503
    ],
    [
        7.7502,
        55.5466
    ],
    [
        7.7481,
        55.443
    ],
    [
        7.7459,
        55.3394
    ],
    [
        7.7442,
        55.2358
    ],
    [
        7.7434,
        55.1322
    ],
    [
        7.7407,
        55.0286
    ],
    [
        7.7393,
        54.925
    ],
    [
        7.738,
        54.8214
    ],
    [
        7.7362,
        54.7178
    ],
    [
        7.7338,
        54.6141
    ],
    [
        7.7333,
        54.5105
    ],
    [
        7.7301,
        54.4069
    ],
    [
        7.7289,
        54.3033
    ],
    [
        7.7277,
        54.1997
    ],
    [
        7.7258,
        54.0961
    ],
    [
        7.7243,
        53.9925
    ],
    [
        7.7224,
        53.8888
    ],
    [
        7.7206,
        53.7852
    ],
    [
        7.7192,
        53.6816
    ],
    [
        7.7178,
        53.578
    ],
    [
        7.7157,
        53.4744
    ],
    [
        7.714,
        53.3708
    ],
    [
        7.7115,
        53.2672
    ],
    [
        7.7111,
        53.1636
    ],
    [
        7.709,
        53.06
    ],
    [
        7.707,
        52.9563
    ],
    [
        7.7064,
        52.8527
    ],
    [
        7.7039,
        52.7491
    ],
    [
        7.7026,
        52.6455
    ],
    [
        7.7005,
        52.5419
    ],
    [
        7.6995,
        52.4383
    ],
    [
        7.6976,
        52.3347
    ],
    [
        7.6954,
        52.231
    ],
    [
        7.6944,
        52.1274
    ],
    [
        7.6927,
        52.0238
    ],
    [
        7.6913,
        51.9202
    ],
    [
        7.6888,
        51.8166
    ],
    [
        7.6872,
        51.713
    ],
    [
        7.6858,
        51.6094
    ],
    [
        7.6848,
        51.5058
    ],
    [
        7.6829,
        51.4021
    ],
    [
        7.6809,
        51.2985
    ],
    [
        7.6796,
        51.1949
    ],
    [
        7.6789,
        51.0913
    ],
    [
        7.6759,
        50.9877
    ],
    [
        7.6755,
        50.8841
    ],
    [
        7.6739,
        50.7805
    ],
    [
        7.6727,
        50.6769
    ],
    [
        7.6708,
        50.5732
    ],
    [
        7.6684,
        50.4696
    ],
    [
        7.6676,
        50.366
    ],
    [
        7.6664,
        50.2624
    ],
    [
        7.665,
        50.1588
    ],
    [
        7.6631,
        50.0552
    ],
    [
        7.6613,
        49.9516
    ],
    [
        7.6603,
        49.848
    ],
    [
        7.6578,
        49.7443
    ],
    [
        7.6568,
        49.6407
    ],
    [
        7.6549,
        49.5371
    ],
    [
        7.6544,
        49.4335
    ],
    [
        7.6518,
        49.3299
    ],
    [
        7.6512,
        49.2263
    ],
    [
        7.6497,
        49.1227
    ],
    [
        7.6476,
        49.0191
    ],
    [
        7.6461,
        48.9155
    ],
    [
        7.6445,
        48.8118
    ],
    [
        7.6439,
        48.7082
    ],
    [
        7.6412,
        48.6046
    ],
    [
        7.64,
        48.501
    ],
    [
        7.6385,
        48.3974
    ],
    [
        7.6378,
        48.2938
    ],
    [
        7.6363,
        48.1902
    ],
    [
        7.6345,
        48.0866
    ],
    [
        7.6331,
        47.9829
    ],
    [
        7.6324,
        47.8793
    ],
    [
        7.6308,
        47.7757
    ],
    [
        7.6287,
        47.6721
    ],
    [
        7.6278,
        47.5685
    ],
    [
        7.626,
        47.4649
    ],
    [
        7.6245,
        47.3613
    ],
    [
        7.6228,
        47.2577
    ],
    [
        7.6221,
        47.154
    ],
    [
        7.6199,
        47.0504
    ],
    [
        7.6188,
        46.9468
    ],
    [
        7.6178,
        46.8432
    ],
    [
        7.6169,
        46.7396
    ],
    [
        7.6151,
        46.636
    ],
    [
        7.6139,
        46.5324
    ],
    [
        7.6123,
        46.4288
    ],
    [
        7.6106,
        46.3251
    ],
    [
        7.6097,
        46.2215
    ],
    [
        7.6077,
        46.1179
    ],
    [
        7.6065,
        46.0143
    ],
    [
        7.6053,
        45.9107
    ],
    [
        7.6038,
        45.8071
    ],
    [
        7.6022,
        45.7035
    ],
    [
        7.6011,
        45.5998
    ],
    [
        7.6008,
        45.4962
    ],
    [
        7.5988,
        45.3926
    ],
    [
        7.5975,
        45.289
    ],
    [
        7.5959,
        45.1854
    ],
    [
        7.5942,
        45.0818
    ],
    [
        7.5939,
        44.9782
    ],
    [
        7.592,
        44.8746
    ],
    [
        7.59,
        44.771
    ],
    [
        7.5892,
        44.6673
    ],
    [
        7.5878,
        44.5637
    ],
    [
        7.5858,
        44.4601
    ],
    [
        7.5851,
        44.3565
    ],
    [
        7.5839,
        44.2529
    ],
    [
        7.583,
        44.1493
    ],
    [
        7.5808,
        44.0457
    ],
    [
        7.5803,
        43.9421
    ],
    [
        7.5793,
        43.8384
    ],
    [
        7.5784,
        43.7348
    ],
    [
        7.5762,
        43.6312
    ],
    [
        7.5756,
        43.5276
    ],
    [
        7.5739,
        43.424
    ],
    [
        7.5729,
        43.3204
    ],
    [
        7.5711,
        43.2168
    ],
    [
        7.571,
        43.1132
    ],
    [
        7.5694,
        43.0095
    ],
    [
        7.5682,
        42.9059
    ],
    [
        7.5663,
        42.8023
    ],
    [
        7.5659,
        42.6987
    ],
    [
        7.5641,
        42.5951
    ],
    [
        7.5633,
        42.4915
    ],
    [
        7.562,
        42.3879
    ],
    [
        7.5605,
        42.2843
    ],
    [
        7.5597,
        42.1806
    ],
    [
        7.5583,
        42.077
    ],
    [
        7.5572,
        41.9734
    ],
    [
        7.5557,
        41.8698
    ],
    [
        7.555,
        41.7662
    ],
    [
        7.5539,
        41.6626
    ],
    [
        7.5525,
        41.559
    ],
    [
        7.5517,
        41.4554
    ],
    [
        7.5504,
        41.3517
    ],
    [
        7.5488,
        41.2481
    ],
    [
        7.5477,
        41.1445
    ],
    [
        7.5464,
        41.0409
    ],
    [
        7.5454,
        40.9373
    ],
    [
        7.5441,
        40.8337
    ],
    [
        7.5435,
        40.7301
    ],
    [
        7.5422,
        40.6264
    ],
    [
        7.5411,
        40.5228
    ],
    [
        7.5401,
        40.4192
    ],
    [
        7.5392,
        40.3156
    ],
    [
        7.5381,
        40.212
    ],
    [
        7.5377,
        40.1084
    ],
    [
        7.5364,
        40.0048
    ],
    [
        7.5346,
        39.9012
    ],
    [
        7.5334,
        39.7976
    ],
    [
        7.5328,
        39.6939
    ],
    [
        7.5313,
        39.5903
    ],
    [
        7.5303,
        39.4867
    ],
    [
        7.5288,
        39.3831
    ],
    [
        7.5279,
        39.2795
    ],
    [
        7.5264,
        39.1759
    ],
    [
        7.5257,
        39.0723
    ],
    [
        7.5254,
        38.9686
    ],
    [
        7.524,
        38.865
    ],
    [
        7.5231,
        38.7614
    ],
    [
        7.5212,
        38.6578
    ],
    [
        7.5207,
        38.5542
    ],
    [
        7.5196,
        38.4506
    ],
    [
        7.518,
        38.347
    ],
    [
        7.5181,
        38.2434
    ],
    [
        7.5163,
        38.1398
    ],
    [
        7.516,
        38.0361
    ],
    [
        7.5136,
        37.9325
    ],
    [
        7.5134,
        37.8289
    ],
    [
        7.5122,
        37.7253
    ],
    [
        7.5121,
        37.6217
    ],
    [
        7.5099,
        37.5181
    ],
    [
        7.5098,
        37.4145
    ],
    [
        7.5087,
        37.3108
    ],
    [
        7.5072,
        37.2072
    ],
    [
        7.5059,
        37.1036
    ],
    [
        7.5052,
        37
    ],
    [
        7.504,
        36.8964
    ],
    [
        7.503,
        36.7928
    ],
    [
        7.5027,
        36.6892
    ],
    [
        7.5015,
        36.5856
    ],
    [
        7.5005,
        36.4819
    ],
    [
        7.4991,
        36.3783
    ],
    [
        7.4981,
        36.2747
    ],
    [
        7.497,
        36.1711
    ],
    [
        7.4967,
        36.0675
    ],
    [
        7.4958,
        35.9639
    ],
    [
        7.4954,
        35.8603
    ],
    [
        7.4944,
        35.7567
    ],
    [
        7.4933,
        35.653
    ],
    [
        7.4922,
        35.5494
    ],
    [
        7.492,
        35.4458
    ],
    [
        7.4893,
        35.3422
    ],
    [
        7.4885,
        35.2386
    ],
    [
        7.4895,
        35.135
    ],
    [
        7.4874,
        35.0314
    ],
    [
        7.487,
        34.9278
    ],
    [
        7.4854,
        34.8242
    ],
    [
        7.4843,
        34.7205
    ],
    [
        7.4836,
        34.6169
    ],
    [
        7.4831,
        34.5133
    ],
    [
        7.4817,
        34.4097
    ],
    [
        7.4813,
        34.3061
    ],
    [
        7.4794,
        34.2025
    ],
    [
        7.4789,
        34.0989
    ],
    [
        7.4781,
        33.9953
    ],
    [
        7.4782,
        33.8916
    ],
    [
        7.4764,
        33.788
    ],
    [
        7.4765,
        33.6844
    ],
    [
        7.4749,
        33.5808
    ],
    [
        7.4739,
        33.4772
    ],
    [
        7.4734,
        33.3736
    ],
    [
        7.4724,
        33.27
    ],
    [
        7.4709,
        33.1664
    ],
    [
        7.4708,
        33.0627
    ],
    [
        7.4695,
        32.9591
    ],
    [
        7.4689,
        32.8555
    ],
    [
        7.4676,
        32.7519
    ],
    [
        7.4666,
        32.6483
    ],
    [
        7.4659,
        32.5447
    ],
    [
        7.4653,
        32.4411
    ],
    [
        7.4639,
        32.3375
    ],
    [
        7.463,
        32.2338
    ],
    [
        7.4622,
        32.1302
    ],
    [
        7.4606,
        32.0266
    ],
    [
        7.4601,
        31.923
    ],
    [
        7.4594,
        31.8194
    ],
    [
        7.458,
        31.7158
    ],
    [
        7.4574,
        31.6122
    ],
    [
        7.4563,
        31.5086
    ],
    [
        7.4558,
        31.4049
    ],
    [
        7.4548,
        31.3013
    ],
    [
        7.4543,
        31.1977
    ],
    [
        7.4533,
        31.0941
    ],
    [
        7.4515,
        30.9905
    ],
    [
        7.4507,
        30.8869
    ],
    [
        7.4503,
        30.7833
    ],
    [
        7.449,
        30.6797
    ],
    [
        7.4483,
        30.576
    ],
    [
        7.4472,
        30.4724
    ],
    [
        7.4463,
        30.3688
    ],
    [
        7.4452,
        30.2652
    ],
    [
        7.4434,
        30.1616
    ],
    [
        7.4437,
        30.058
    ],
    [
        7.4431,
        29.9544
    ],
    [
        7.4413,
        29.8508
    ],
    [
        7.4402,
        29.7471
    ],
    [
        7.4399,
        29.6435
    ],
    [
        7.4384,
        29.5399
    ],
    [
        7.4375,
        29.4363
    ],
    [
        7.4364,
        29.3327
    ],
    [
        7.4361,
        29.2291
    ],
    [
        7.4341,
        29.1255
    ],
    [
        7.4332,
        29.0219
    ],
    [
        7.4323,
        28.9182
    ],
    [
        7.4311,
        28.8146
    ],
    [
        7.4301,
        28.711
    ],
    [
        7.4294,
        28.6074
    ],
    [
        7.4284,
        28.5038
    ],
    [
        7.4269,
        28.4002
    ],
    [
        7.4263,
        28.2966
    ],
    [
        7.4248,
        28.193
    ],
    [
        7.4234,
        28.0893
    ],
    [
        7.422,
        27.9857
    ],
    [
        7.4215,
        27.8821
    ],
    [
        7.4203,
        27.7785
    ],
    [
        7.4191,
        27.6749
    ],
    [
        7.4177,
        27.5713
    ],
    [
        7.4166,
        27.4677
    ],
    [
        7.4154,
        27.3641
    ],
    [
        7.415,
        27.2604
    ],
    [
        7.4138,
        27.1568
    ],
    [
        7.412,
        27.0532
    ],
    [
        7.411,
        26.9496
    ],
    [
        7.4098,
        26.846
    ],
    [
        7.4077,
        26.7424
    ],
    [
        7.4068,
        26.6388
    ],
    [
        7.4063,
        26.5352
    ],
    [
        7.4042,
        26.4315
    ],
    [
        7.4033,
        26.3279
    ],
    [
        7.4022,
        26.2243
    ],
    [
        7.401,
        26.1207
    ],
    [
        7.3994,
        26.0171
    ],
    [
        7.3988,
        25.9135
    ],
    [
        7.3968,
        25.8099
    ],
    [
        7.3961,
        25.7063
    ],
    [
        7.3944,
        25.6026
    ],
    [
        7.3931,
        25.499
    ],
    [
        7.3919,
        25.3954
    ],
    [
        7.3907,
        25.2918
    ],
    [
        7.3897,
        25.1882
    ],
    [
        7.3882,
        25.0846
    ],
    [
        7.3869,
        24.981
    ],
    [
        7.385,
        24.8774
    ],
    [
        7.3847,
        24.7737
    ],
    [
        7.3833,
        24.6701
    ],
    [
        7.3807,
        24.5665
    ],
    [
        7.3804,
        24.4629
    ],
    [
        7.379,
        24.3593
    ],
    [
        7.3765,
        24.2557
    ],
    [
        7.3754,
        24.1521
    ],
    [
        7.3741,
        24.0485
    ],
    [
        7.3725,
        23.9448
    ],
    [
        7.372,
        23.8412
    ],
    [
        7.3703,
        23.7376
    ],
    [
        7.3685,
        23.634
    ],
    [
        7.3668,
        23.5304
    ],
    [
        7.3654,
        23.4268
    ],
    [
        7.3649,
        23.3232
    ],
    [
        7.3631,
        23.2196
    ],
    [
        7.3615,
        23.1159
    ],
    [
        7.3607,
        23.0123
    ],
    [
        7.3583,
        22.9087
    ],
    [
        7.3564,
        22.8051
    ],
    [
        7.3554,
        22.7015
    ],
    [
        7.3543,
        22.5979
    ],
    [
        7.3526,
        22.4943
    ],
    [
        7.3514,
        22.3907
    ],
    [
        7.3494,
        22.287
    ],
    [
        7.3485,
        22.1834
    ],
    [
        7.3468,
        22.0798
    ],
    [
        7.3451,
        21.9762
    ],
    [
        7.3439,
        21.8726
    ],
    [
        7.3429,
        21.769
    ],
    [
        7.3406,
        21.6654
    ],
    [
        7.3394,
        21.5618
    ],
    [
        7.3368,
        21.4581
    ],
    [
        7.336,
        21.3545
    ],
    [
        7.3345,
        21.2509
    ],
    [
        7.3329,
        21.1473
    ],
    [
        7.3317,
        21.0437
    ],
    [
        7.3299,
        20.9401
    ],
    [
        7.3282,
        20.8365
    ],
    [
        7.3273,
        20.7329
    ],
    [
        7.3263,
        20.6292
    ],
    [
        7.324,
        20.5256
    ],
    [
        7.3214,
        20.422
    ],
    [
        7.3215,
        20.3184
    ],
    [
        7.3196,
        20.2148
    ],
    [
        7.318,
        20.1112
    ],
    [
        7.3161,
        20.0076
    ],
    [
        7.3149,
        19.904
    ],
    [
        7.3138,
        19.8003
    ],
    [
        7.3122,
        19.6967
    ],
    [
        7.3103,
        19.5931
    ],
    [
        7.3089,
        19.4895
    ],
    [
        7.3082,
        19.3859
    ],
    [
        7.3071,
        19.2823
    ],
    [
        7.3047,
        19.1787
    ],
    [
        7.3041,
        19.0751
    ],
    [
        7.3025,
        18.9714
    ],
    [
        7.3006,
        18.8678
    ],
    [
        7.2994,
        18.7642
    ],
    [
        7.2984,
        18.6606
    ],
    [
        7.2968,
        18.557
    ],
    [
        7.2955,
        18.4534
    ],
    [
        7.2939,
        18.3498
    ],
    [
        7.292,
        18.2462
    ],
    [
        7.2907,
        18.1425
    ],
    [
        7.2895,
        18.0389
    ],
    [
        7.2887,
        17.9353
    ],
    [
        7.2871,
        17.8317
    ],
    [
        7.2851,
        17.7281
    ],
    [
        7.2842,
        17.6245
    ],
    [
        7.283,
        17.5209
    ],
    [
        7.2813,
        17.4173
    ],
    [
        7.2802,
        17.3136
    ],
    [
        7.2783,
        17.21
    ],
    [
        7.2767,
        17.1064
    ],
    [
        7.2761,
        17.0028
    ],
    [
        7.2746,
        16.8992
    ],
    [
        7.2727,
        16.7956
    ],
    [
        7.2712,
        16.692
    ],
    [
        7.27,
        16.5884
    ],
    [
        7.2693,
        16.4847
    ],
    [
        7.2675,
        16.3811
    ],
    [
        7.2667,
        16.2775
    ],
    [
        7.2647,
        16.1739
    ],
    [
        7.2634,
        16.0703
    ],
    [
        7.2615,
        15.9667
    ],
    [
        7.2603,
        15.8631
    ],
    [
        7.2585,
        15.7595
    ],
    [
        7.2578,
        15.6558
    ],
    [
        7.2552,
        15.5522
    ],
    [
        7.2552,
        15.4486
    ],
    [
        7.2529,
        15.345
    ],
    [
        7.2515,
        15.2414
    ],
    [
        7.2509,
        15.1378
    ],
    [
        7.2489,
        15.0342
    ],
    [
        7.248,
        14.9306
    ],
    [
        7.2459,
        14.8269
    ],
    [
        7.2454,
        14.7233
    ],
    [
        7.2433,
        14.6197
    ],
    [
        7.242,
        14.5161
    ],
    [
        7.241,
        14.4125
    ],
    [
        7.2381,
        14.3089
    ],
    [
        7.2374,
        14.2053
    ],
    [
        7.2368,
        14.1017
    ],
    [
        7.2346,
        13.998
    ],
    [
        7.2332,
        13.8944
    ],
    [
        7.2316,
        13.7908
    ],
    [
        7.2312,
        13.6872
    ],
    [
        7.2293,
        13.5836
    ],
    [
        7.2278,
        13.48
    ],
    [
        7.2262,
        13.3764
    ],
    [
        7.2243,
        13.2728
    ],
    [
        7.2232,
        13.1691
    ],
    [
        7.2225,
        13.0655
    ],
    [
        7.2202,
        12.9619
    ],
    [
        7.2193,
        12.8583
    ],
    [
        7.2175,
        12.7547
    ],
    [
        7.2161,
        12.6511
    ],
    [
        7.2151,
        12.5475
    ],
    [
        7.2138,
        12.4439
    ],
    [
        7.2122,
        12.3402
    ],
    [
        7.2112,
        12.2366
    ],
    [
        7.2099,
        12.133
    ],
    [
        7.2087,
        12.0294
    ],
    [
        7.206,
        11.9258
    ],
    [
        7.2053,
        11.8222
    ],
    [
        7.2037,
        11.7186
    ],
    [
        7.2028,
        11.615
    ],
    [
        7.201,
        11.5113
    ],
    [
        7.1999,
        11.4077
    ],
    [
        7.1983,
        11.3041
    ],
    [
        7.197,
        11.2005
    ],
    [
        7.1947,
        11.0969
    ],
    [
        7.1941,
        10.9933
    ],
    [
        7.1923,
        10.8897
    ],
    [
        7.1909,
        10.7861
    ],
    [
        7.1899,
        10.6824
    ],
    [
        7.1882,
        10.5788
    ],
    [
        7.1869,
        10.4752
    ],
    [
        7.185,
        10.3716
    ],
    [
        7.1835,
        10.268
    ],
    [
        7.1829,
        10.1644
    ],
    [
        7.1804,
        10.0608
    ],
    [
        7.1799,
        9.9572
    ],
    [
        7.1778,
        9.8535
    ],
    [
        7.1764,
        9.7499
    ],
    [
        7.1746,
        9.6463
    ],
    [
        7.1742,
        9.5427
    ],
    [
        7.1728,
        9.4391
    ],
    [
        7.1717,
        9.3355
    ],
    [
        7.1702,
        9.2319
    ],
    [
        7.1685,
        9.1283
    ],
    [
        7.1677,
        9.0246
    ],
    [
        7.1656,
        8.921
    ],
    [
        7.165,
        8.8174
    ],
    [
        7.1628,
        8.7138
    ],
    [
        7.1609,
        8.6102
    ],
    [
        7.1601,
        8.5066
    ],
    [
        7.1579,
        8.403
    ],
    [
        7.1554,
        8.2994
    ],
    [
        7.1533,
        8.1958
    ],
    [
        7.1515,
        8.0921
    ],
    [
        7.1491,
        7.9885
    ],
    [
        7.1468,
        7.8849
    ],
    [
        7.1432,
        7.7813
    ],
    [
        7.1401,
        7.6777
    ],
    [
        7.1368,
        7.5741
    ],
    [
        7.1332,
        7.4705
    ],
    [
        7.1284,
        7.3669
    ],
    [
        7.1256,
        7.2632
    ],
    [
        7.1207,
        7.1596
    ],
    [
        7.1159,
        7.056
    ],
    [
        7.1107,
        6.9524
    ],
    [
        7.106,
        6.8488
    ],
    [
        7.1001,
        6.7452
    ],
    [
        7.0949,
        6.6416
    ],
    [
        7.0893,
        6.538
    ],
    [
        7.0823,
        6.4343
    ],
    [
        7.0759,
        6.3307
    ],
    [
        7.0687,
        6.2271
    ],
    [
        7.0621,
        6.1235
    ],
    [
        7.0545,
        6.0199
    ],
    [
        7.0468,
        5.9163
    ],
    [
        7.0383,
        5.8127
    ],
    [
        7.0305,
        5.709
    ],
    [
        7.0226,
        5.6054
    ],
    [
        7.0148,
        5.5018
    ],
    [
        7.0066,
        5.3982
    ],
    [
        6.9965,
        5.2946
    ],
    [
        6.9884,
        5.191
    ],
    [
        6.9786,
        5.0874
    ],
    [
        6.9688,
        4.9838
    ],
    [
        6.9594,
        4.8801
    ],
    [
        6.9496,
        4.7765
    ],
    [
        6.9404,
        4.6729
    ],
    [
        6.9297,
        4.5693
    ],
    [
        6.9196,
        4.4657
    ],
    [
        6.909,
        4.3621
    ],
    [
        6.8977,
        4.2585
    ],
    [
        6.887,
        4.1549
    ],
    [
        6.8767,
        4.0512
    ],
    [
        6.8652,
        3.9476
    ],
    [
        6.8527,
        3.844
    ],
    [
        6.8399,
        3.7404
    ],
    [
        6.8283,
        3.6368
    ],
    [
        6.8135,
        3.5332
    ],
    [
        6.8022,
        3.4296
    ],
    [
        6.7897,
        3.326
    ],
    [
        6.7763,
        3.2223
    ],
    [
        6.7618,
        3.1187
    ],
    [
        6.7467,
        3.0151
    ],
    [
        6.7326,
        2.9115
    ],
    [
        6.7173,
        2.8079
    ],
    [
        6.7021,
        2.7043
    ],
    [
        6.6858,
        2.6007
    ],
    [
        6.6693,
        2.4971
    ],
    [
        6.6527,
        2.3934
    ],
    [
        6.6333,
        2.2898
    ],
    [
        6.6182,
        2.1862
    ],
    [
        6.5986,
        2.0826
    ],
    [
        6.576,
        1.979
    ],
    [
        6.5573,
        1.8754
    ],
    [
        6.5384,
        1.7718
    ],
    [
        6.5153,
        1.6682
    ],
    [
        6.4925,
        1.5645
    ],
    [
        6.4683,
        1.4609
    ],
    [
        6.443,
        1.3573
    ],
    [
        6.4152,
        1.2537
    ],
    [
        6.3917,
        1.1501
    ],
    [
        6.3593,
        1.0465
    ],
    [
        6.3299,
        0.9429
    ],
    [
        6.2973,
        0.8393
    ],
    [
        6.2631,
        0.7356
    ],
    [
        6.2266,
        0.632
    ],
    [
        6.1852,
        0.5284
    ],
    [
        6.1413,
        0.4248
    ],
    [
        6.0911,
        0.3212
    ],
    [
        6.0312,
        0.2176
    ],
    [
        5.954,
        0.114
    ],
    [
        5.8481,
        0.0104
    ]
]
export const voltage= [
    [
        0,
        7.0607,
        0
    ],
    [
        0,
        7.0613,
        0.0167
    ],
    [
        0,
        7.0612,
        0.0333
    ],
    [
        0,
        7.0609,
        0.05
    ],
    [
        0,
        7.0615,
        0.0667
    ],
    [
        0,
        7.0611,
        0.0833
    ],
    [
        0,
        7.0611,
        0.1
    ],
    [
        0,
        7.0617,
        0.1167
    ],
    [
        0,
        7.0615,
        0.1333
    ],
    [
        0,
        7.0616,
        0.15
    ],
    [
        0,
        7.0616,
        0.1667
    ],
    [
        60.0067,
        7.193,
        0.1833
    ],
    [
        59.9909,
        7.2997,
        0.2
    ],
    [
        59.9953,
        7.3035,
        0.2167
    ],
    [
        59.9951,
        7.3074,
        0.2333
    ],
    [
        59.9914,
        7.3104,
        0.25
    ],
    [
        59.9931,
        7.3133,
        0.2667
    ],
    [
        59.9945,
        7.3158,
        0.2833
    ],
    [
        59.9933,
        7.3177,
        0.3
    ],
    [
        59.9922,
        7.3194,
        0.3167
    ],
    [
        59.9946,
        7.3213,
        0.3333
    ],
    [
        59.9947,
        7.3242,
        0.35
    ],
    [
        59.9931,
        7.3263,
        0.3667
    ],
    [
        59.9914,
        7.3281,
        0.3833
    ],
    [
        59.9924,
        7.3295,
        0.4
    ],
    [
        59.9926,
        7.3313,
        0.4167
    ],
    [
        59.9923,
        7.333,
        0.4333
    ],
    [
        59.9953,
        7.3353,
        0.45
    ],
    [
        59.9939,
        7.3365,
        0.4667
    ],
    [
        59.9922,
        7.3385,
        0.4833
    ],
    [
        59.9933,
        7.3395,
        0.5
    ],
    [
        59.9943,
        7.3405,
        0.5167
    ],
    [
        59.9926,
        7.3424,
        0.5333
    ],
    [
        59.9934,
        7.3442,
        0.55
    ],
    [
        59.9939,
        7.3456,
        0.5667
    ],
    [
        59.9934,
        7.3469,
        0.5833
    ],
    [
        59.9935,
        7.348,
        0.6
    ],
    [
        59.9942,
        7.349,
        0.6167
    ],
    [
        59.9909,
        7.3505,
        0.6333
    ],
    [
        59.9935,
        7.3513,
        0.65
    ],
    [
        59.9938,
        7.3526,
        0.6667
    ],
    [
        59.9943,
        7.3542,
        0.6833
    ],
    [
        59.9932,
        7.3558,
        0.7
    ],
    [
        59.9923,
        7.3566,
        0.7167
    ],
    [
        59.9925,
        7.3583,
        0.7333
    ],
    [
        59.993,
        7.3591,
        0.75
    ],
    [
        59.9937,
        7.3602,
        0.7667
    ],
    [
        59.993,
        7.3608,
        0.7833
    ],
    [
        59.9943,
        7.3626,
        0.8
    ],
    [
        59.9931,
        7.3631,
        0.8167
    ],
    [
        59.9921,
        7.365,
        0.8333
    ],
    [
        59.9937,
        7.3657,
        0.85
    ],
    [
        59.9922,
        7.3664,
        0.8667
    ],
    [
        59.9953,
        7.3669,
        0.8833
    ],
    [
        59.9934,
        7.3688,
        0.9
    ],
    [
        59.9914,
        7.3699,
        0.9167
    ],
    [
        59.9924,
        7.3704,
        0.9333
    ],
    [
        59.9952,
        7.3714,
        0.95
    ],
    [
        59.9919,
        7.3725,
        0.9667
    ],
    [
        59.9938,
        7.3739,
        0.9833
    ],
    [
        59.9947,
        7.3743,
        1
    ],
    [
        59.9938,
        7.3755,
        1.0167
    ],
    [
        59.9936,
        7.3756,
        1.0333
    ],
    [
        59.9925,
        7.3777,
        1.05
    ],
    [
        59.9913,
        7.3785,
        1.0667
    ],
    [
        59.9951,
        7.3791,
        1.0833
    ],
    [
        59.9927,
        7.3804,
        1.1
    ],
    [
        59.9931,
        7.3807,
        1.1167
    ],
    [
        59.9956,
        7.382,
        1.1333
    ],
    [
        59.9912,
        7.3831,
        1.15
    ],
    [
        59.9942,
        7.384,
        1.1667
    ],
    [
        59.9942,
        7.3846,
        1.1833
    ],
    [
        59.9935,
        7.3855,
        1.2
    ],
    [
        59.9926,
        7.3863,
        1.2167
    ],
    [
        59.9925,
        7.3877,
        1.2333
    ],
    [
        59.9943,
        7.3883,
        1.25
    ],
    [
        59.9933,
        7.3887,
        1.2667
    ],
    [
        59.9934,
        7.3897,
        1.2833
    ],
    [
        59.9887,
        7.3911,
        1.3
    ],
    [
        59.9985,
        7.3912,
        1.3167
    ],
    [
        59.9917,
        7.3916,
        1.3333
    ],
    [
        59.9918,
        7.3928,
        1.35
    ],
    [
        59.9945,
        7.394,
        1.3667
    ],
    [
        59.9926,
        7.3949,
        1.3833
    ],
    [
        59.9909,
        7.3955,
        1.4
    ],
    [
        59.9929,
        7.3966,
        1.4167
    ],
    [
        59.9907,
        7.3968,
        1.4333
    ],
    [
        59.9937,
        7.3978,
        1.45
    ],
    [
        59.9924,
        7.3995,
        1.4667
    ],
    [
        59.9933,
        7.3994,
        1.4833
    ],
    [
        59.9933,
        7.4006,
        1.5
    ],
    [
        59.992,
        7.4007,
        1.5167
    ],
    [
        59.9931,
        7.402,
        1.5333
    ],
    [
        59.9916,
        7.4033,
        1.55
    ],
    [
        59.993,
        7.4036,
        1.5667
    ],
    [
        59.9929,
        7.4039,
        1.5833
    ],
    [
        59.9971,
        7.4047,
        1.6
    ],
    [
        59.9948,
        7.4056,
        1.6167
    ],
    [
        59.9931,
        7.4074,
        1.6333
    ],
    [
        59.9925,
        7.4078,
        1.65
    ],
    [
        59.9931,
        7.4078,
        1.6667
    ],
    [
        59.9945,
        7.4083,
        1.6833
    ],
    [
        59.9956,
        7.4093,
        1.7
    ],
    [
        59.9929,
        7.4102,
        1.7167
    ],
    [
        59.9928,
        7.4115,
        1.7333
    ],
    [
        59.9936,
        7.4121,
        1.75
    ],
    [
        59.9939,
        7.4132,
        1.7667
    ],
    [
        59.9936,
        7.4133,
        1.7833
    ],
    [
        59.9936,
        7.4137,
        1.8
    ],
    [
        59.9948,
        7.4146,
        1.8167
    ],
    [
        59.9926,
        7.4145,
        1.8333
    ],
    [
        59.9925,
        7.417,
        1.85
    ],
    [
        59.9934,
        7.4167,
        1.8667
    ],
    [
        59.993,
        7.4178,
        1.8833
    ],
    [
        59.9929,
        7.4173,
        1.9
    ],
    [
        59.9929,
        7.4183,
        1.9167
    ],
    [
        59.9937,
        7.419,
        1.9333
    ],
    [
        59.9969,
        7.4202,
        1.95
    ],
    [
        59.9939,
        7.421,
        1.9667
    ],
    [
        59.9919,
        7.4218,
        1.9833
    ],
    [
        59.993,
        7.4219,
        2
    ],
    [
        59.9936,
        7.4225,
        2.0167
    ],
    [
        59.9927,
        7.4238,
        2.0333
    ],
    [
        59.9921,
        7.4244,
        2.05
    ],
    [
        59.9964,
        7.4246,
        2.0667
    ],
    [
        59.9939,
        7.4247,
        2.0833
    ],
    [
        59.9917,
        7.4259,
        2.1
    ],
    [
        59.9939,
        7.4261,
        2.1167
    ],
    [
        59.9936,
        7.4265,
        2.1333
    ],
    [
        59.9913,
        7.4282,
        2.15
    ],
    [
        59.9935,
        7.4284,
        2.1667
    ],
    [
        59.9951,
        7.429,
        2.1833
    ],
    [
        59.993,
        7.4288,
        2.2
    ],
    [
        59.9949,
        7.4301,
        2.2167
    ],
    [
        59.9934,
        7.4304,
        2.2333
    ],
    [
        59.9928,
        7.4317,
        2.25
    ],
    [
        59.9956,
        7.4318,
        2.2667
    ],
    [
        59.9937,
        7.4323,
        2.2833
    ],
    [
        59.9993,
        7.4323,
        2.3
    ],
    [
        59.9945,
        7.4331,
        2.3167
    ],
    [
        59.9916,
        7.4339,
        2.3333
    ],
    [
        59.9931,
        7.4343,
        2.35
    ],
    [
        59.9925,
        7.4354,
        2.3667
    ],
    [
        59.9948,
        7.436,
        2.3833
    ],
    [
        59.993,
        7.4368,
        2.4
    ],
    [
        59.9929,
        7.4371,
        2.4167
    ],
    [
        59.9917,
        7.4382,
        2.4333
    ],
    [
        59.9956,
        7.4392,
        2.45
    ],
    [
        59.9928,
        7.4389,
        2.4667
    ],
    [
        59.9944,
        7.4396,
        2.4833
    ],
    [
        59.993,
        7.4398,
        2.5
    ],
    [
        59.9947,
        7.4412,
        2.5167
    ],
    [
        59.9924,
        7.4415,
        2.5333
    ],
    [
        59.9894,
        7.4423,
        2.55
    ],
    [
        59.9876,
        7.4417,
        2.5667
    ],
    [
        59.9934,
        7.4424,
        2.5833
    ],
    [
        59.9936,
        7.443,
        2.6
    ],
    [
        59.9922,
        7.4445,
        2.6167
    ],
    [
        59.9933,
        7.445,
        2.6333
    ],
    [
        60.0015,
        7.4459,
        2.65
    ],
    [
        59.9928,
        7.4463,
        2.6667
    ],
    [
        59.9952,
        7.4469,
        2.6833
    ],
    [
        59.9929,
        7.4479,
        2.7
    ],
    [
        59.9942,
        7.4478,
        2.7167
    ],
    [
        59.9953,
        7.4492,
        2.7333
    ],
    [
        59.9932,
        7.449,
        2.75
    ],
    [
        59.9905,
        7.45,
        2.7667
    ],
    [
        59.9926,
        7.4504,
        2.7833
    ],
    [
        59.9957,
        7.4519,
        2.8
    ],
    [
        59.9945,
        7.4523,
        2.8167
    ],
    [
        59.9936,
        7.4518,
        2.8333
    ],
    [
        59.9951,
        7.4523,
        2.85
    ],
    [
        59.9952,
        7.4531,
        2.8667
    ],
    [
        59.9899,
        7.4533,
        2.8833
    ],
    [
        59.9936,
        7.4544,
        2.9
    ],
    [
        59.9927,
        7.4546,
        2.9167
    ],
    [
        59.9942,
        7.4558,
        2.9333
    ],
    [
        59.9892,
        7.456,
        2.95
    ],
    [
        59.9983,
        7.4575,
        2.9667
    ],
    [
        59.9908,
        7.4571,
        2.9833
    ],
    [
        59.995,
        7.4581,
        3
    ],
    [
        59.9919,
        7.4583,
        3.0167
    ],
    [
        59.9935,
        7.4593,
        3.0333
    ],
    [
        59.9911,
        7.4598,
        3.05
    ],
    [
        60.0002,
        7.4596,
        3.0667
    ],
    [
        59.9968,
        7.4606,
        3.0833
    ],
    [
        59.9982,
        7.4606,
        3.1
    ],
    [
        59.9918,
        7.4622,
        3.1167
    ],
    [
        59.995,
        7.4616,
        3.1333
    ],
    [
        59.9934,
        7.4628,
        3.15
    ],
    [
        59.9945,
        7.4634,
        3.1667
    ],
    [
        59.9898,
        7.464,
        3.1833
    ],
    [
        59.9939,
        7.4639,
        3.2
    ],
    [
        59.994,
        7.4646,
        3.2167
    ],
    [
        59.9936,
        7.4652,
        3.2333
    ],
    [
        59.9958,
        7.4663,
        3.25
    ],
    [
        59.9936,
        7.4668,
        3.2667
    ],
    [
        59.9946,
        7.4674,
        3.2833
    ],
    [
        59.9929,
        7.4684,
        3.3
    ],
    [
        59.992,
        7.4683,
        3.3167
    ],
    [
        59.9924,
        7.4693,
        3.3333
    ],
    [
        59.9927,
        7.4694,
        3.35
    ],
    [
        59.9913,
        7.4703,
        3.3667
    ],
    [
        59.9945,
        7.4709,
        3.3833
    ],
    [
        59.9954,
        7.4714,
        3.4
    ],
    [
        59.9954,
        7.4717,
        3.4167
    ],
    [
        59.994,
        7.4727,
        3.4333
    ],
    [
        59.9927,
        7.4731,
        3.45
    ],
    [
        59.9937,
        7.4729,
        3.4667
    ],
    [
        59.9936,
        7.4736,
        3.4833
    ],
    [
        59.9929,
        7.4745,
        3.5
    ],
    [
        59.9902,
        7.4754,
        3.5167
    ],
    [
        59.9895,
        7.476,
        3.5333
    ],
    [
        59.9935,
        7.4758,
        3.55
    ],
    [
        59.9945,
        7.4763,
        3.5667
    ],
    [
        59.9914,
        7.4774,
        3.5833
    ],
    [
        59.992,
        7.4778,
        3.6
    ],
    [
        59.9927,
        7.4782,
        3.6167
    ],
    [
        59.996,
        7.4784,
        3.6333
    ],
    [
        59.9939,
        7.4791,
        3.65
    ],
    [
        59.9941,
        7.4799,
        3.6667
    ],
    [
        59.9935,
        7.4804,
        3.6833
    ],
    [
        59.9928,
        7.4809,
        3.7
    ],
    [
        59.9929,
        7.4811,
        3.7167
    ],
    [
        59.9921,
        7.4816,
        3.7333
    ],
    [
        59.9929,
        7.4816,
        3.75
    ],
    [
        59.9951,
        7.4825,
        3.7667
    ],
    [
        59.9946,
        7.4832,
        3.7833
    ],
    [
        59.9925,
        7.4837,
        3.8
    ],
    [
        59.9924,
        7.4844,
        3.8167
    ],
    [
        59.9925,
        7.485,
        3.8333
    ],
    [
        59.9919,
        7.4851,
        3.85
    ],
    [
        59.9926,
        7.4859,
        3.8667
    ],
    [
        59.9928,
        7.4858,
        3.8833
    ],
    [
        59.9955,
        7.4864,
        3.9
    ],
    [
        59.9935,
        7.4878,
        3.9167
    ],
    [
        59.9948,
        7.4877,
        3.9333
    ],
    [
        59.9875,
        7.4882,
        3.95
    ],
    [
        59.9943,
        7.4886,
        3.9667
    ],
    [
        59.9929,
        7.4895,
        3.9833
    ],
    [
        59.9929,
        7.4894,
        4
    ],
    [
        59.9924,
        7.4906,
        4.0167
    ],
    [
        59.9929,
        7.4909,
        4.0333
    ],
    [
        59.9942,
        7.4923,
        4.05
    ],
    [
        59.9894,
        7.492,
        4.0667
    ],
    [
        59.9885,
        7.4931,
        4.0833
    ],
    [
        59.9866,
        7.4929,
        4.1
    ],
    [
        59.9929,
        7.4927,
        4.1167
    ],
    [
        59.991,
        7.4938,
        4.1333
    ],
    [
        59.9957,
        7.4943,
        4.15
    ],
    [
        59.9916,
        7.4939,
        4.1667
    ],
    [
        59.9933,
        7.4948,
        4.1833
    ],
    [
        59.9902,
        7.4962,
        4.2
    ],
    [
        59.9954,
        7.4962,
        4.2167
    ],
    [
        59.9908,
        7.4975,
        4.2333
    ],
    [
        59.9906,
        7.4973,
        4.25
    ],
    [
        59.9926,
        7.4977,
        4.2667
    ],
    [
        59.995,
        7.4975,
        4.2833
    ],
    [
        59.9837,
        7.4982,
        4.3
    ],
    [
        59.9927,
        7.4993,
        4.3167
    ],
    [
        59.9931,
        7.4999,
        4.3333
    ],
    [
        59.9905,
        7.4999,
        4.35
    ],
    [
        59.9923,
        7.5006,
        4.3667
    ],
    [
        59.9924,
        7.5007,
        4.3833
    ],
    [
        59.9956,
        7.5021,
        4.4
    ],
    [
        59.9917,
        7.5022,
        4.4167
    ],
    [
        59.9929,
        7.5019,
        4.4333
    ],
    [
        59.9933,
        7.5024,
        4.45
    ],
    [
        59.9919,
        7.5037,
        4.4667
    ],
    [
        59.9924,
        7.5035,
        4.4833
    ],
    [
        59.9936,
        7.5037,
        4.5
    ],
    [
        59.9947,
        7.5048,
        4.5167
    ],
    [
        59.9912,
        7.5045,
        4.5333
    ],
    [
        59.9932,
        7.5058,
        4.55
    ],
    [
        59.9956,
        7.5058,
        4.5667
    ],
    [
        59.9926,
        7.5069,
        4.5833
    ],
    [
        59.9923,
        7.5072,
        4.6
    ],
    [
        59.9937,
        7.5073,
        4.6167
    ],
    [
        59.9909,
        7.5072,
        4.6333
    ],
    [
        59.9857,
        7.5082,
        4.65
    ],
    [
        59.9936,
        7.5087,
        4.6667
    ],
    [
        59.9936,
        7.5099,
        4.6833
    ],
    [
        59.9929,
        7.5092,
        4.7
    ],
    [
        59.9972,
        7.5105,
        4.7167
    ],
    [
        59.9936,
        7.5109,
        4.7333
    ],
    [
        59.9931,
        7.5115,
        4.75
    ],
    [
        59.9886,
        7.5117,
        4.7667
    ],
    [
        59.9957,
        7.512,
        4.7833
    ],
    [
        59.9942,
        7.5126,
        4.8
    ],
    [
        59.9911,
        7.5125,
        4.8167
    ],
    [
        59.9871,
        7.5145,
        4.8333
    ],
    [
        59.9919,
        7.514,
        4.85
    ],
    [
        59.9937,
        7.5141,
        4.8667
    ],
    [
        59.9929,
        7.5142,
        4.8833
    ],
    [
        59.9918,
        7.5152,
        4.9
    ],
    [
        59.9971,
        7.5156,
        4.9167
    ],
    [
        59.9975,
        7.516,
        4.9333
    ],
    [
        59.9935,
        7.5166,
        4.95
    ],
    [
        59.9925,
        7.5172,
        4.9667
    ],
    [
        59.9933,
        7.5176,
        4.9833
    ],
    [
        59.9921,
        7.5174,
        5
    ],
    [
        59.9929,
        7.5176,
        5.0167
    ],
    [
        59.9938,
        7.5187,
        5.0333
    ],
    [
        59.9929,
        7.5192,
        5.05
    ],
    [
        59.988,
        7.5198,
        5.0667
    ],
    [
        59.9946,
        7.5207,
        5.0833
    ],
    [
        59.9947,
        7.5208,
        5.1
    ],
    [
        59.9938,
        7.5206,
        5.1167
    ],
    [
        59.9942,
        7.5216,
        5.1333
    ],
    [
        59.992,
        7.5218,
        5.15
    ],
    [
        59.9931,
        7.5222,
        5.1667
    ],
    [
        59.9911,
        7.5222,
        5.1833
    ],
    [
        59.9969,
        7.523,
        5.2
    ],
    [
        59.9931,
        7.5231,
        5.2167
    ],
    [
        59.9936,
        7.5235,
        5.2333
    ],
    [
        59.9947,
        7.524,
        5.25
    ],
    [
        59.9951,
        7.5245,
        5.2667
    ],
    [
        59.999,
        7.5251,
        5.2833
    ],
    [
        59.9921,
        7.525,
        5.3
    ],
    [
        59.9929,
        7.5255,
        5.3167
    ],
    [
        59.9941,
        7.5268,
        5.3333
    ],
    [
        59.9983,
        7.5272,
        5.35
    ],
    [
        59.9866,
        7.5268,
        5.3667
    ],
    [
        59.9929,
        7.5278,
        5.3833
    ],
    [
        59.9935,
        7.5282,
        5.4
    ],
    [
        59.9943,
        7.528,
        5.4167
    ],
    [
        59.9953,
        7.5289,
        5.4333
    ],
    [
        59.993,
        7.529,
        5.45
    ],
    [
        59.9933,
        7.529,
        5.4667
    ],
    [
        59.9951,
        7.5296,
        5.4833
    ],
    [
        59.9909,
        7.5306,
        5.5
    ],
    [
        59.9932,
        7.5308,
        5.5167
    ],
    [
        59.9933,
        7.5318,
        5.5333
    ],
    [
        59.9941,
        7.5323,
        5.55
    ],
    [
        59.9936,
        7.5319,
        5.5667
    ],
    [
        59.9915,
        7.5329,
        5.5833
    ],
    [
        59.9911,
        7.5326,
        5.6
    ],
    [
        59.9936,
        7.5334,
        5.6167
    ],
    [
        59.9925,
        7.534,
        5.6333
    ],
    [
        59.9935,
        7.5338,
        5.65
    ],
    [
        59.9964,
        7.5344,
        5.6667
    ],
    [
        59.9951,
        7.5344,
        5.6833
    ],
    [
        59.9941,
        7.5354,
        5.7
    ],
    [
        59.9956,
        7.5355,
        5.7167
    ],
    [
        59.9937,
        7.5352,
        5.7333
    ],
    [
        59.9909,
        7.5365,
        5.75
    ],
    [
        59.9944,
        7.537,
        5.7667
    ],
    [
        59.9906,
        7.5373,
        5.7833
    ],
    [
        59.995,
        7.5372,
        5.8
    ],
    [
        59.9946,
        7.5384,
        5.8167
    ],
    [
        59.9915,
        7.5386,
        5.8333
    ],
    [
        59.9944,
        7.5391,
        5.85
    ],
    [
        59.9927,
        7.5398,
        5.8667
    ],
    [
        59.9962,
        7.5397,
        5.8833
    ],
    [
        59.9927,
        7.5396,
        5.9
    ],
    [
        59.9945,
        7.5402,
        5.9167
    ],
    [
        59.9909,
        7.5408,
        5.9333
    ],
    [
        59.9916,
        7.5405,
        5.95
    ],
    [
        59.9988,
        7.5414,
        5.9667
    ],
    [
        59.995,
        7.5418,
        5.9833
    ],
    [
        59.9923,
        7.5425,
        6
    ],
    [
        59.993,
        7.542,
        6.0167
    ],
    [
        59.9946,
        7.5425,
        6.0333
    ],
    [
        59.9935,
        7.5438,
        6.05
    ],
    [
        59.9927,
        7.5444,
        6.0667
    ],
    [
        59.9936,
        7.5435,
        6.0833
    ],
    [
        59.9933,
        7.5447,
        6.1
    ],
    [
        59.9906,
        7.5447,
        6.1167
    ],
    [
        59.9928,
        7.5448,
        6.1333
    ],
    [
        59.9948,
        7.5447,
        6.15
    ],
    [
        59.9979,
        7.5454,
        6.1667
    ],
    [
        59.9928,
        7.5464,
        6.1833
    ],
    [
        59.9923,
        7.5458,
        6.2
    ],
    [
        59.9938,
        7.5472,
        6.2167
    ],
    [
        59.9932,
        7.5471,
        6.2333
    ],
    [
        59.9945,
        7.5476,
        6.25
    ],
    [
        59.9925,
        7.5477,
        6.2667
    ],
    [
        59.9931,
        7.5469,
        6.2833
    ],
    [
        59.9954,
        7.5487,
        6.3
    ],
    [
        59.9846,
        7.5491,
        6.3167
    ],
    [
        59.9909,
        7.5501,
        6.3333
    ],
    [
        59.9908,
        7.5498,
        6.35
    ],
    [
        59.9951,
        7.5507,
        6.3667
    ],
    [
        59.9929,
        7.5503,
        6.3833
    ],
    [
        59.995,
        7.5515,
        6.4
    ],
    [
        59.9944,
        7.5517,
        6.4167
    ],
    [
        59.9921,
        7.5519,
        6.4333
    ],
    [
        59.9903,
        7.5519,
        6.45
    ],
    [
        59.9873,
        7.5522,
        6.4667
    ],
    [
        59.9939,
        7.5526,
        6.4833
    ],
    [
        59.9903,
        7.5532,
        6.5
    ],
    [
        59.9952,
        7.5539,
        6.5167
    ],
    [
        59.9929,
        7.5545,
        6.5333
    ],
    [
        59.9921,
        7.5547,
        6.55
    ],
    [
        59.9914,
        7.5545,
        6.5667
    ],
    [
        59.9919,
        7.5537,
        6.5833
    ],
    [
        59.9949,
        7.5548,
        6.6
    ],
    [
        59.987,
        7.5559,
        6.6167
    ],
    [
        59.9925,
        7.5562,
        6.6333
    ],
    [
        59.9908,
        7.557,
        6.65
    ],
    [
        59.9929,
        7.5577,
        6.6667
    ],
    [
        59.9917,
        7.5568,
        6.6833
    ],
    [
        59.9927,
        7.5569,
        6.7
    ],
    [
        59.9944,
        7.558,
        6.7167
    ],
    [
        59.9892,
        7.5584,
        6.7333
    ],
    [
        59.9918,
        7.5582,
        6.75
    ],
    [
        59.9944,
        7.5593,
        6.7667
    ],
    [
        59.9956,
        7.559,
        6.7833
    ],
    [
        59.9928,
        7.56,
        6.8
    ],
    [
        59.9929,
        7.5601,
        6.8167
    ],
    [
        59.994,
        7.5599,
        6.8333
    ],
    [
        59.9956,
        7.5608,
        6.85
    ],
    [
        59.9928,
        7.5615,
        6.8667
    ],
    [
        59.9882,
        7.5614,
        6.8833
    ],
    [
        59.9936,
        7.5622,
        6.9
    ],
    [
        59.9941,
        7.5623,
        6.9167
    ],
    [
        59.9921,
        7.5625,
        6.9333
    ],
    [
        59.9928,
        7.5631,
        6.95
    ],
    [
        59.9924,
        7.5634,
        6.9667
    ],
    [
        59.9903,
        7.5635,
        6.9833
    ],
    [
        59.9951,
        7.5639,
        7
    ],
    [
        59.9937,
        7.5637,
        7.0167
    ],
    [
        59.9948,
        7.5646,
        7.0333
    ],
    [
        59.9935,
        7.565,
        7.05
    ],
    [
        59.9934,
        7.5651,
        7.0667
    ],
    [
        59.9939,
        7.5659,
        7.0833
    ],
    [
        59.9917,
        7.5659,
        7.1
    ],
    [
        59.9966,
        7.5658,
        7.1167
    ],
    [
        59.9932,
        7.566,
        7.1333
    ],
    [
        59.9906,
        7.5663,
        7.15
    ],
    [
        59.9943,
        7.5672,
        7.1667
    ],
    [
        59.9933,
        7.5671,
        7.1833
    ],
    [
        59.9926,
        7.567,
        7.2
    ],
    [
        59.9932,
        7.5677,
        7.2167
    ],
    [
        59.9899,
        7.5688,
        7.2333
    ],
    [
        59.9939,
        7.5684,
        7.25
    ],
    [
        59.9928,
        7.569,
        7.2667
    ],
    [
        59.997,
        7.5689,
        7.2833
    ],
    [
        59.9919,
        7.5697,
        7.3
    ],
    [
        59.9922,
        7.5702,
        7.3167
    ],
    [
        59.994,
        7.5703,
        7.3333
    ],
    [
        59.994,
        7.5712,
        7.35
    ],
    [
        59.9956,
        7.5715,
        7.3667
    ],
    [
        59.9916,
        7.5719,
        7.3833
    ],
    [
        59.9948,
        7.5718,
        7.4
    ],
    [
        59.9958,
        7.5722,
        7.4167
    ],
    [
        59.996,
        7.5722,
        7.4333
    ],
    [
        59.9915,
        7.5724,
        7.45
    ],
    [
        59.9944,
        7.5735,
        7.4667
    ],
    [
        59.9931,
        7.5733,
        7.4833
    ],
    [
        59.9931,
        7.5739,
        7.5
    ],
    [
        59.993,
        7.5745,
        7.5167
    ],
    [
        59.9913,
        7.5745,
        7.5333
    ],
    [
        59.99,
        7.5749,
        7.55
    ],
    [
        59.9978,
        7.5757,
        7.5667
    ],
    [
        59.9932,
        7.576,
        7.5833
    ],
    [
        59.9929,
        7.5757,
        7.6
    ],
    [
        59.9947,
        7.5761,
        7.6167
    ],
    [
        59.9931,
        7.5768,
        7.6333
    ],
    [
        59.9943,
        7.5775,
        7.65
    ],
    [
        59.9931,
        7.5773,
        7.6667
    ],
    [
        59.9929,
        7.5784,
        7.6833
    ],
    [
        59.9935,
        7.5783,
        7.7
    ],
    [
        59.9928,
        7.5786,
        7.7167
    ],
    [
        59.9916,
        7.579,
        7.7333
    ],
    [
        59.9909,
        7.5781,
        7.75
    ],
    [
        59.9942,
        7.5795,
        7.7667
    ],
    [
        59.9928,
        7.5787,
        7.7833
    ],
    [
        59.9939,
        7.5796,
        7.8
    ],
    [
        59.9926,
        7.5804,
        7.8167
    ],
    [
        59.9926,
        7.5804,
        7.8333
    ],
    [
        59.9916,
        7.5807,
        7.85
    ],
    [
        59.9929,
        7.5815,
        7.8667
    ],
    [
        59.9932,
        7.582,
        7.8833
    ],
    [
        59.9891,
        7.5811,
        7.9
    ],
    [
        59.9944,
        7.5826,
        7.9167
    ],
    [
        59.9934,
        7.5819,
        7.9333
    ],
    [
        59.9935,
        7.582,
        7.95
    ],
    [
        59.9914,
        7.5827,
        7.9667
    ],
    [
        59.9905,
        7.584,
        7.9833
    ],
    [
        59.9953,
        7.5837,
        8
    ],
    [
        59.9921,
        7.5843,
        8.0167
    ],
    [
        59.9938,
        7.584,
        8.0333
    ],
    [
        59.9925,
        7.5842,
        8.05
    ],
    [
        59.9944,
        7.5856,
        8.0667
    ],
    [
        59.9908,
        7.5855,
        8.0833
    ],
    [
        59.9908,
        7.5854,
        8.1
    ],
    [
        59.9856,
        7.5851,
        8.1167
    ],
    [
        59.9929,
        7.5868,
        8.1333
    ],
    [
        59.9929,
        7.5865,
        8.15
    ],
    [
        59.9937,
        7.5869,
        8.1667
    ],
    [
        59.9936,
        7.5871,
        8.1833
    ],
    [
        59.9921,
        7.5878,
        8.2
    ],
    [
        59.9955,
        7.5874,
        8.2167
    ],
    [
        59.9914,
        7.5877,
        8.2333
    ],
    [
        59.9864,
        7.5884,
        8.25
    ],
    [
        59.9913,
        7.5885,
        8.2667
    ],
    [
        59.9953,
        7.5887,
        8.2833
    ],
    [
        59.9927,
        7.589,
        8.3
    ],
    [
        59.9928,
        7.5895,
        8.3167
    ],
    [
        59.991,
        7.5893,
        8.3333
    ],
    [
        59.9922,
        7.5902,
        8.35
    ],
    [
        59.9919,
        7.5904,
        8.3667
    ],
    [
        59.9896,
        7.5906,
        8.3833
    ],
    [
        59.9936,
        7.5909,
        8.4
    ],
    [
        59.9941,
        7.5918,
        8.4167
    ],
    [
        59.9947,
        7.5916,
        8.4333
    ],
    [
        59.986,
        7.5926,
        8.45
    ],
    [
        59.9922,
        7.5928,
        8.4667
    ],
    [
        59.9944,
        7.5924,
        8.4833
    ],
    [
        59.9931,
        7.5925,
        8.5
    ],
    [
        59.9936,
        7.5925,
        8.5167
    ],
    [
        59.9991,
        7.5922,
        8.5333
    ],
    [
        59.9934,
        7.5927,
        8.55
    ],
    [
        59.993,
        7.5938,
        8.5667
    ],
    [
        59.9958,
        7.5944,
        8.5833
    ],
    [
        59.9921,
        7.594,
        8.6
    ],
    [
        59.9928,
        7.5947,
        8.6167
    ],
    [
        59.9931,
        7.5949,
        8.6333
    ],
    [
        59.9933,
        7.5948,
        8.65
    ],
    [
        59.9957,
        7.5964,
        8.6667
    ],
    [
        59.9939,
        7.5967,
        8.6833
    ],
    [
        59.9957,
        7.5963,
        8.7
    ],
    [
        59.9945,
        7.5966,
        8.7167
    ],
    [
        59.9937,
        7.597,
        8.7333
    ],
    [
        59.9938,
        7.5973,
        8.75
    ],
    [
        59.9955,
        7.5971,
        8.7667
    ],
    [
        59.9908,
        7.598,
        8.7833
    ],
    [
        59.9947,
        7.598,
        8.8
    ],
    [
        59.9912,
        7.5986,
        8.8167
    ],
    [
        59.9926,
        7.5988,
        8.8333
    ],
    [
        59.994,
        7.5987,
        8.85
    ],
    [
        59.9921,
        7.5985,
        8.8667
    ],
    [
        59.9942,
        7.5995,
        8.8833
    ],
    [
        59.9936,
        7.5995,
        8.9
    ],
    [
        59.9909,
        7.5998,
        8.9167
    ],
    [
        59.9935,
        7.6004,
        8.9333
    ],
    [
        59.9991,
        7.601,
        8.95
    ],
    [
        59.993,
        7.6013,
        8.9667
    ],
    [
        59.9935,
        7.5999,
        8.9833
    ],
    [
        59.9914,
        7.6015,
        9
    ],
    [
        59.9936,
        7.6014,
        9.0167
    ],
    [
        59.993,
        7.6022,
        9.0333
    ],
    [
        59.9928,
        7.6027,
        9.05
    ],
    [
        59.9941,
        7.6025,
        9.0667
    ],
    [
        59.9912,
        7.603,
        9.0833
    ],
    [
        59.9994,
        7.6027,
        9.1
    ],
    [
        59.9913,
        7.6025,
        9.1167
    ],
    [
        59.9893,
        7.6033,
        9.1333
    ],
    [
        59.9904,
        7.6044,
        9.15
    ],
    [
        59.9929,
        7.6036,
        9.1667
    ],
    [
        59.9936,
        7.6047,
        9.1833
    ],
    [
        59.9936,
        7.6052,
        9.2
    ],
    [
        59.9916,
        7.6051,
        9.2167
    ],
    [
        59.9953,
        7.6051,
        9.2333
    ],
    [
        59.9948,
        7.6048,
        9.25
    ],
    [
        59.9923,
        7.605,
        9.2667
    ],
    [
        59.9941,
        7.6059,
        9.2833
    ],
    [
        59.9956,
        7.606,
        9.3
    ],
    [
        59.9936,
        7.6062,
        9.3167
    ],
    [
        59.9937,
        7.6067,
        9.3333
    ],
    [
        59.9926,
        7.6071,
        9.35
    ],
    [
        59.9921,
        7.6073,
        9.3667
    ],
    [
        59.9919,
        7.608,
        9.3833
    ],
    [
        59.9933,
        7.6082,
        9.4
    ],
    [
        59.9925,
        7.6088,
        9.4167
    ],
    [
        59.9937,
        7.6082,
        9.4333
    ],
    [
        59.9961,
        7.6091,
        9.45
    ],
    [
        59.9939,
        7.6098,
        9.4667
    ],
    [
        59.9949,
        7.6092,
        9.4833
    ],
    [
        59.9935,
        7.6099,
        9.5
    ],
    [
        59.991,
        7.6101,
        9.5167
    ],
    [
        59.9972,
        7.6109,
        9.5333
    ],
    [
        59.993,
        7.6103,
        9.55
    ],
    [
        59.9941,
        7.6108,
        9.5667
    ],
    [
        59.9943,
        7.6116,
        9.5833
    ],
    [
        59.9918,
        7.6115,
        9.6
    ],
    [
        59.9909,
        7.612,
        9.6167
    ],
    [
        59.9964,
        7.6118,
        9.6333
    ],
    [
        59.9972,
        7.6122,
        9.65
    ],
    [
        59.9929,
        7.6131,
        9.6667
    ],
    [
        59.9897,
        7.6126,
        9.6833
    ],
    [
        59.9915,
        7.613,
        9.7
    ],
    [
        59.9908,
        7.6125,
        9.7167
    ],
    [
        59.9916,
        7.6143,
        9.7333
    ],
    [
        59.994,
        7.6137,
        9.75
    ],
    [
        59.9918,
        7.6138,
        9.7667
    ],
    [
        59.9965,
        7.6145,
        9.7833
    ],
    [
        59.9946,
        7.6143,
        9.8
    ],
    [
        59.988,
        7.6156,
        9.8167
    ],
    [
        59.993,
        7.6156,
        9.8333
    ],
    [
        59.9941,
        7.616,
        9.85
    ],
    [
        59.993,
        7.6161,
        9.8667
    ],
    [
        59.9941,
        7.6155,
        9.8833
    ],
    [
        59.9949,
        7.6156,
        9.9
    ],
    [
        59.9949,
        7.6157,
        9.9167
    ],
    [
        59.9919,
        7.6168,
        9.9333
    ],
    [
        59.9936,
        7.6171,
        9.95
    ],
    [
        59.9926,
        7.6178,
        9.9667
    ],
    [
        59.9905,
        7.6171,
        9.9833
    ],
    [
        59.9899,
        7.6176,
        10
    ],
    [
        59.9924,
        7.6175,
        10.0167
    ],
    [
        59.9949,
        7.6182,
        10.0333
    ],
    [
        59.9926,
        7.6184,
        10.05
    ],
    [
        59.995,
        7.6186,
        10.0667
    ],
    [
        59.9985,
        7.6183,
        10.0833
    ],
    [
        60.0013,
        7.6192,
        10.1
    ],
    [
        59.9905,
        7.6192,
        10.1167
    ],
    [
        59.9941,
        7.6193,
        10.1333
    ],
    [
        59.9926,
        7.6192,
        10.15
    ],
    [
        59.9927,
        7.6202,
        10.1667
    ],
    [
        59.9933,
        7.6203,
        10.1833
    ],
    [
        0,
        7.5609,
        10.2
    ],
    [
        0,
        7.3885,
        10.2167
    ],
    [
        0,
        7.3841,
        10.2333
    ],
    [
        0,
        7.3795,
        10.25
    ],
    [
        0,
        7.3773,
        10.2667
    ],
    [
        0,
        7.374,
        10.2833
    ],
    [
        0,
        7.3719,
        10.3
    ],
    [
        0,
        7.3694,
        10.3167
    ],
    [
        0,
        7.3672,
        10.3333
    ],
    [
        0,
        7.3655,
        10.35
    ],
    [
        0,
        7.3634,
        10.3667
    ],
    [
        0,
        7.3617,
        10.3833
    ],
    [
        0,
        7.3598,
        10.4
    ],
    [
        0,
        7.3578,
        10.4167
    ],
    [
        0,
        7.3568,
        10.4333
    ],
    [
        0,
        7.3543,
        10.45
    ],
    [
        0,
        7.3527,
        10.4667
    ],
    [
        0,
        7.3521,
        10.4833
    ],
    [
        0,
        7.351,
        10.5
    ],
    [
        0,
        7.3496,
        10.5167
    ],
    [
        0,
        7.3486,
        10.5333
    ],
    [
        0,
        7.3468,
        10.55
    ],
    [
        0,
        7.3456,
        10.5667
    ],
    [
        0,
        7.3439,
        10.5833
    ],
    [
        0,
        7.3431,
        10.6
    ],
    [
        0,
        7.3411,
        10.6167
    ],
    [
        0,
        7.3404,
        10.6333
    ],
    [
        0,
        7.3395,
        10.65
    ],
    [
        0,
        7.3388,
        10.6667
    ],
    [
        0,
        7.3381,
        10.6833
    ],
    [
        0,
        7.3371,
        10.7
    ],
    [
        0,
        7.335,
        10.7167
    ],
    [
        0,
        7.3341,
        10.7333
    ],
    [
        0,
        7.3332,
        10.75
    ],
    [
        0,
        7.3321,
        10.7667
    ],
    [
        0,
        7.3313,
        10.7833
    ],
    [
        0,
        7.3314,
        10.8
    ],
    [
        0,
        7.33,
        10.8167
    ],
    [
        0,
        7.3289,
        10.8333
    ],
    [
        0,
        7.3273,
        10.85
    ],
    [
        0,
        7.3272,
        10.8667
    ],
    [
        0,
        7.3263,
        10.8833
    ],
    [
        0,
        7.3255,
        10.9
    ],
    [
        0,
        7.3244,
        10.9167
    ],
    [
        0,
        7.3234,
        10.9333
    ],
    [
        0,
        7.3226,
        10.95
    ],
    [
        0,
        7.3209,
        10.9667
    ],
    [
        0,
        7.3206,
        10.9833
    ],
    [
        0,
        7.3205,
        11
    ],
    [
        0,
        7.3186,
        11.0167
    ],
    [
        0,
        7.3175,
        11.0333
    ],
    [
        0,
        7.3167,
        11.05
    ],
    [
        0,
        7.3168,
        11.0667
    ],
    [
        0,
        7.3167,
        11.0833
    ],
    [
        0,
        7.3152,
        11.1
    ],
    [
        0,
        7.3147,
        11.1167
    ],
    [
        0,
        7.3137,
        11.1333
    ],
    [
        0,
        7.3129,
        11.15
    ],
    [
        0,
        7.3128,
        11.1667
    ],
    [
        0,
        7.3122,
        11.1833
    ],
    [
        0,
        7.3117,
        11.2
    ],
    [
        0,
        7.3106,
        11.2167
    ],
    [
        0,
        7.31,
        11.2333
    ],
    [
        0,
        7.3095,
        11.25
    ],
    [
        0,
        7.3087,
        11.2667
    ],
    [
        0,
        7.3082,
        11.2833
    ],
    [
        0,
        7.307,
        11.3
    ],
    [
        0,
        7.3064,
        11.3167
    ],
    [
        0,
        7.3059,
        11.3333
    ],
    [
        0,
        7.3052,
        11.35
    ],
    [
        0,
        7.3044,
        11.3667
    ],
    [
        0,
        7.3042,
        11.3833
    ],
    [
        0,
        7.3036,
        11.4
    ],
    [
        0,
        7.3028,
        11.4167
    ],
    [
        0,
        7.3019,
        11.4333
    ],
    [
        0,
        7.302,
        11.45
    ],
    [
        0,
        7.3017,
        11.4667
    ],
    [
        0,
        7.3007,
        11.4833
    ],
    [
        0,
        7.3007,
        11.5
    ],
    [
        0,
        7.3,
        11.5167
    ],
    [
        0,
        7.2991,
        11.5333
    ],
    [
        0,
        7.2991,
        11.55
    ],
    [
        0,
        7.2984,
        11.5667
    ],
    [
        0,
        7.2977,
        11.5833
    ],
    [
        0,
        7.2968,
        11.6
    ],
    [
        0,
        7.2964,
        11.6167
    ],
    [
        0,
        7.2959,
        11.6333
    ],
    [
        0,
        7.2956,
        11.65
    ],
    [
        0,
        7.2949,
        11.6667
    ],
    [
        0,
        7.2946,
        11.6833
    ],
    [
        0,
        7.2947,
        11.7
    ],
    [
        0,
        7.2941,
        11.7167
    ],
    [
        0,
        7.2932,
        11.7333
    ],
    [
        0,
        7.2929,
        11.75
    ],
    [
        0,
        7.2922,
        11.7667
    ],
    [
        0,
        7.2918,
        11.7833
    ],
    [
        0,
        7.2919,
        11.8
    ],
    [
        0,
        7.2914,
        11.8167
    ],
    [
        0,
        7.291,
        11.8333
    ],
    [
        0,
        7.2909,
        11.85
    ],
    [
        0,
        7.2903,
        11.8667
    ],
    [
        0,
        7.2899,
        11.8833
    ],
    [
        0,
        7.29,
        11.9
    ],
    [
        0,
        7.2891,
        11.9167
    ],
    [
        0,
        7.2889,
        11.9333
    ],
    [
        0,
        7.2884,
        11.95
    ],
    [
        0,
        7.2884,
        11.9667
    ],
    [
        0,
        7.2882,
        11.9833
    ],
    [
        0,
        7.2877,
        12
    ],
    [
        0,
        7.2881,
        12.0167
    ],
    [
        0,
        7.2875,
        12.0333
    ],
    [
        0,
        7.2867,
        12.05
    ],
    [
        0,
        7.2872,
        12.0667
    ],
    [
        0,
        7.286,
        12.0833
    ],
    [
        0,
        7.2863,
        12.1
    ],
    [
        0,
        7.2857,
        12.1167
    ],
    [
        0,
        7.2851,
        12.1333
    ],
    [
        0,
        7.2852,
        12.15
    ],
    [
        0,
        7.2849,
        12.1667
    ],
    [
        0,
        7.2838,
        12.1833
    ],
    [
        0,
        7.2841,
        12.2
    ],
    [
        0,
        7.2841,
        12.2167
    ],
    [
        0,
        7.2832,
        12.2333
    ],
    [
        0,
        7.2827,
        12.25
    ],
    [
        0,
        7.2819,
        12.2667
    ],
    [
        0,
        7.2826,
        12.2833
    ],
    [
        0,
        7.2816,
        12.3
    ],
    [
        0,
        7.2811,
        12.3167
    ],
    [
        0,
        7.2809,
        12.3333
    ],
    [
        0,
        7.2811,
        12.35
    ],
    [
        0,
        7.2812,
        12.3667
    ],
    [
        0,
        7.2805,
        12.3833
    ],
    [
        0,
        7.2806,
        12.4
    ],
    [
        0,
        7.2797,
        12.4167
    ],
    [
        0,
        7.2797,
        12.4333
    ],
    [
        0,
        7.2797,
        12.45
    ],
    [
        0,
        7.279,
        12.4667
    ],
    [
        0,
        7.2787,
        12.4833
    ],
    [
        0,
        7.2783,
        12.5
    ],
    [
        0,
        7.2776,
        12.5167
    ],
    [
        0,
        7.2778,
        12.5333
    ],
    [
        0,
        7.2775,
        12.55
    ],
    [
        0,
        7.2771,
        12.5667
    ],
    [
        0,
        7.2771,
        12.5833
    ],
    [
        0,
        7.2757,
        12.6
    ],
    [
        0,
        7.2764,
        12.6167
    ],
    [
        0,
        7.2761,
        12.6333
    ],
    [
        0,
        7.2764,
        12.65
    ],
    [
        0,
        7.2753,
        12.6667
    ],
    [
        0,
        7.2755,
        12.6833
    ],
    [
        0,
        7.2754,
        12.7
    ],
    [
        0,
        7.2749,
        12.7167
    ],
    [
        0,
        7.2744,
        12.7333
    ],
    [
        0,
        7.2746,
        12.75
    ],
    [
        0,
        7.2744,
        12.7667
    ],
    [
        0,
        7.2745,
        12.7833
    ],
    [
        0,
        7.2741,
        12.8
    ],
    [
        0,
        7.2733,
        12.8167
    ],
    [
        0,
        7.2737,
        12.8333
    ],
    [
        0,
        7.273,
        12.85
    ],
    [
        0,
        7.2723,
        12.8667
    ],
    [
        0,
        7.2724,
        12.8833
    ],
    [
        0,
        7.272,
        12.9
    ],
    [
        0,
        7.2718,
        12.9167
    ],
    [
        0,
        7.2717,
        12.9333
    ],
    [
        0,
        7.2718,
        12.95
    ],
    [
        0,
        7.2718,
        12.9667
    ],
    [
        0,
        7.2717,
        12.9833
    ],
    [
        0,
        7.2709,
        13
    ],
    [
        0,
        7.2711,
        13.0167
    ],
    [
        0,
        7.271,
        13.0333
    ],
    [
        0,
        7.271,
        13.05
    ],
    [
        0,
        7.2705,
        13.0667
    ],
    [
        0,
        7.2699,
        13.0833
    ],
    [
        0,
        7.27,
        13.1
    ],
    [
        0,
        7.2699,
        13.1167
    ],
    [
        0,
        7.2699,
        13.1333
    ],
    [
        0,
        7.2694,
        13.15
    ],
    [
        0,
        7.2695,
        13.1667
    ],
    [
        0,
        7.2681,
        13.1833
    ],
    [
        0,
        7.2691,
        13.2
    ],
    [
        0,
        7.2686,
        13.2167
    ],
    [
        0,
        7.268,
        13.2333
    ],
    [
        0,
        7.2681,
        13.25
    ],
    [
        0,
        7.2682,
        13.2667
    ],
    [
        0,
        7.2676,
        13.2833
    ],
    [
        0,
        7.2676,
        13.3
    ],
    [
        0,
        7.2668,
        13.3167
    ],
    [
        0,
        7.2666,
        13.3333
    ],
    [
        0,
        7.2671,
        13.35
    ],
    [
        0,
        7.2664,
        13.3667
    ],
    [
        0,
        7.2666,
        13.3833
    ],
    [
        0,
        7.2663,
        13.4
    ],
    [
        0,
        7.2663,
        13.4167
    ],
    [
        0,
        7.2662,
        13.4333
    ],
    [
        0,
        7.266,
        13.45
    ],
    [
        0,
        7.2658,
        13.4667
    ],
    [
        0,
        7.2656,
        13.4833
    ],
    [
        0,
        7.2658,
        13.5
    ],
    [
        0,
        7.2651,
        13.5167
    ],
    [
        0,
        7.2651,
        13.5333
    ],
    [
        0,
        7.264,
        13.55
    ],
    [
        0,
        7.2646,
        13.5667
    ],
    [
        0,
        7.264,
        13.5833
    ],
    [
        0,
        7.2642,
        13.6
    ],
    [
        0,
        7.2643,
        13.6167
    ],
    [
        0,
        7.2638,
        13.6333
    ],
    [
        0,
        7.2643,
        13.65
    ],
    [
        0,
        7.2639,
        13.6667
    ],
    [
        0,
        7.2639,
        13.6833
    ],
    [
        0,
        7.2634,
        13.7
    ],
    [
        0,
        7.2634,
        13.7167
    ],
    [
        0,
        7.2633,
        13.7333
    ],
    [
        0,
        7.263,
        13.75
    ],
    [
        0,
        7.2633,
        13.7667
    ],
    [
        0,
        7.263,
        13.7833
    ],
    [
        0,
        7.2633,
        13.8
    ],
    [
        0,
        7.2625,
        13.8167
    ],
    [
        0,
        7.2634,
        13.8333
    ],
    [
        0,
        7.2625,
        13.85
    ],
    [
        0,
        7.262,
        13.8667
    ],
    [
        0,
        7.2623,
        13.8833
    ],
    [
        0,
        7.2618,
        13.9
    ],
    [
        0,
        7.2627,
        13.9167
    ],
    [
        0,
        7.262,
        13.9333
    ],
    [
        0,
        7.2622,
        13.95
    ],
    [
        0,
        7.2615,
        13.9667
    ],
    [
        0,
        7.261,
        13.9833
    ],
    [
        0,
        7.2608,
        14
    ],
    [
        0,
        7.2607,
        14.0167
    ],
    [
        0,
        7.2618,
        14.0333
    ],
    [
        0,
        7.2608,
        14.05
    ],
    [
        0,
        7.2608,
        14.0667
    ],
    [
        0,
        7.2604,
        14.0833
    ],
    [
        0,
        7.2603,
        14.1
    ],
    [
        0,
        7.2594,
        14.1167
    ],
    [
        0,
        7.2602,
        14.1333
    ],
    [
        0,
        7.2597,
        14.15
    ],
    [
        0,
        7.2604,
        14.1667
    ],
    [
        0,
        7.26,
        14.1833
    ],
    [
        0,
        7.2597,
        14.2
    ],
    [
        0,
        7.2598,
        14.2167
    ],
    [
        0,
        7.2594,
        14.2333
    ],
    [
        0,
        7.2594,
        14.25
    ],
    [
        0,
        7.2595,
        14.2667
    ],
    [
        0,
        7.259,
        14.2833
    ],
    [
        0,
        7.2598,
        14.3
    ],
    [
        0,
        7.2587,
        14.3167
    ],
    [
        0,
        7.2587,
        14.3333
    ],
    [
        0,
        7.2589,
        14.35
    ],
    [
        0,
        7.2585,
        14.3667
    ],
    [
        0,
        7.259,
        14.3833
    ],
    [
        0,
        7.2583,
        14.4
    ],
    [
        0,
        7.2588,
        14.4167
    ],
    [
        0,
        7.2587,
        14.4333
    ],
    [
        0,
        7.2582,
        14.45
    ],
    [
        0,
        7.2586,
        14.4667
    ],
    [
        0,
        7.2586,
        14.4833
    ],
    [
        0,
        7.2576,
        14.5
    ],
    [
        0,
        7.2583,
        14.5167
    ],
    [
        0,
        7.2573,
        14.5333
    ],
    [
        0,
        7.2582,
        14.55
    ],
    [
        0,
        7.257,
        14.5667
    ],
    [
        0,
        7.2576,
        14.5833
    ],
    [
        0,
        7.2573,
        14.6
    ],
    [
        0,
        7.2564,
        14.6167
    ],
    [
        0,
        7.257,
        14.6333
    ],
    [
        0,
        7.2569,
        14.65
    ],
    [
        0,
        7.2564,
        14.6667
    ],
    [
        0,
        7.2565,
        14.6833
    ],
    [
        0,
        7.2572,
        14.7
    ],
    [
        0,
        7.2565,
        14.7167
    ],
    [
        0,
        7.2566,
        14.7333
    ],
    [
        0,
        7.2569,
        14.75
    ],
    [
        0,
        7.2559,
        14.7667
    ],
    [
        0,
        7.2564,
        14.7833
    ],
    [
        0,
        7.2556,
        14.8
    ],
    [
        0,
        7.2561,
        14.8167
    ],
    [
        0,
        7.2558,
        14.8333
    ],
    [
        0,
        7.2556,
        14.85
    ],
    [
        0,
        7.2553,
        14.8667
    ],
    [
        0,
        7.2561,
        14.8833
    ],
    [
        0,
        7.2562,
        14.9
    ],
    [
        0,
        7.2551,
        14.9167
    ],
    [
        0,
        7.2551,
        14.9333
    ],
    [
        0,
        7.2553,
        14.95
    ],
    [
        0,
        7.2552,
        14.9667
    ],
    [
        0,
        7.2549,
        14.9833
    ],
    [
        0,
        7.2542,
        15
    ],
    [
        0,
        7.2543,
        15.0167
    ],
    [
        0,
        7.2538,
        15.0333
    ],
    [
        0,
        7.2545,
        15.05
    ],
    [
        0,
        7.2543,
        15.0667
    ],
    [
        0,
        7.2538,
        15.0833
    ],
    [
        0,
        7.2547,
        15.1
    ],
    [
        0,
        7.2541,
        15.1167
    ],
    [
        0,
        7.2538,
        15.1333
    ],
    [
        0,
        7.254,
        15.15
    ],
    [
        0,
        7.2534,
        15.1667
    ],
    [
        0,
        7.2543,
        15.1833
    ],
    [
        0,
        7.2537,
        15.2
    ],
    [
        0,
        7.2539,
        15.2167
    ],
    [
        0,
        7.2535,
        15.2333
    ],
    [
        0,
        7.2533,
        15.25
    ],
    [
        0,
        7.2536,
        15.2667
    ],
    [
        0,
        7.2538,
        15.2833
    ],
    [
        0,
        7.2543,
        15.3
    ],
    [
        0,
        7.2536,
        15.3167
    ],
    [
        0,
        7.2535,
        15.3333
    ],
    [
        0,
        7.2529,
        15.35
    ],
    [
        0,
        7.2532,
        15.3667
    ],
    [
        0,
        7.2532,
        15.3833
    ],
    [
        0,
        7.2529,
        15.4
    ],
    [
        0,
        7.253,
        15.4167
    ],
    [
        0,
        7.252,
        15.4333
    ],
    [
        0,
        7.2531,
        15.45
    ],
    [
        0,
        7.2528,
        15.4667
    ],
    [
        0,
        7.2528,
        15.4833
    ],
    [
        0,
        7.2533,
        15.5
    ],
    [
        0,
        7.2528,
        15.5167
    ],
    [
        0,
        7.2521,
        15.5333
    ],
    [
        0,
        7.2517,
        15.55
    ],
    [
        0,
        7.2527,
        15.5667
    ],
    [
        0,
        7.2523,
        15.5833
    ],
    [
        0,
        7.2524,
        15.6
    ],
    [
        0,
        7.252,
        15.6167
    ],
    [
        0,
        7.2515,
        15.6333
    ],
    [
        0,
        7.2522,
        15.65
    ],
    [
        0,
        7.252,
        15.6667
    ],
    [
        0,
        7.2519,
        15.6833
    ],
    [
        0,
        7.2513,
        15.7
    ],
    [
        0,
        7.2509,
        15.7167
    ],
    [
        0,
        7.2509,
        15.7333
    ],
    [
        0,
        7.2514,
        15.75
    ],
    [
        0,
        7.2507,
        15.7667
    ],
    [
        0,
        7.251,
        15.7833
    ],
    [
        0,
        7.2515,
        15.8
    ],
    [
        0,
        7.2511,
        15.8167
    ],
    [
        0,
        7.2508,
        15.8333
    ],
    [
        0,
        7.2513,
        15.85
    ],
    [
        0,
        7.2508,
        15.8667
    ],
    [
        0,
        7.2507,
        15.8833
    ],
    [
        0,
        7.2511,
        15.9
    ],
    [
        0,
        7.2499,
        15.9167
    ],
    [
        0,
        7.2503,
        15.9333
    ],
    [
        0,
        7.2503,
        15.95
    ],
    [
        0,
        7.2505,
        15.9667
    ],
    [
        0,
        7.2506,
        15.9833
    ],
    [
        0,
        7.25,
        16
    ],
    [
        0,
        7.2505,
        16.0167
    ],
    [
        0,
        7.2502,
        16.0333
    ],
    [
        0,
        7.2501,
        16.05
    ],
    [
        0,
        7.2497,
        16.0667
    ],
    [
        0,
        7.2502,
        16.0833
    ],
    [
        0,
        7.2499,
        16.1
    ],
    [
        0,
        7.2502,
        16.1167
    ],
    [
        0,
        7.2504,
        16.1333
    ],
    [
        0,
        7.2501,
        16.15
    ],
    [
        0,
        7.2495,
        16.1667
    ],
    [
        0,
        7.2496,
        16.1833
    ],
    [
        0,
        7.2501,
        16.2
    ],
    [
        0,
        7.2496,
        16.2167
    ],
    [
        0,
        7.2494,
        16.2333
    ],
    [
        0,
        7.2495,
        16.25
    ],
    [
        0,
        7.2497,
        16.2667
    ],
    [
        0,
        7.2494,
        16.2833
    ],
    [
        0,
        7.2497,
        16.3
    ],
    [
        0,
        7.2491,
        16.3167
    ],
    [
        0,
        7.2496,
        16.3333
    ],
    [
        0,
        7.2499,
        16.35
    ],
    [
        0,
        7.2495,
        16.3667
    ],
    [
        0,
        7.2494,
        16.3833
    ],
    [
        0,
        7.2495,
        16.4
    ],
    [
        0,
        7.2493,
        16.4167
    ],
    [
        0,
        7.2493,
        16.4333
    ],
    [
        0,
        7.2494,
        16.45
    ],
    [
        0,
        7.249,
        16.4667
    ],
    [
        0,
        7.2494,
        16.4833
    ],
    [
        0,
        7.2492,
        16.5
    ],
    [
        0,
        7.2495,
        16.5167
    ],
    [
        0,
        7.2485,
        16.5333
    ],
    [
        0,
        7.2489,
        16.55
    ],
    [
        0,
        7.2485,
        16.5667
    ],
    [
        0,
        7.2486,
        16.5833
    ],
    [
        0,
        7.2481,
        16.6
    ],
    [
        0,
        7.2488,
        16.6167
    ],
    [
        0,
        7.2486,
        16.6333
    ],
    [
        0,
        7.2484,
        16.65
    ],
    [
        0,
        7.2479,
        16.6667
    ],
    [
        0,
        7.2481,
        16.6833
    ],
    [
        0,
        7.2483,
        16.7
    ],
    [
        0,
        7.2478,
        16.7167
    ],
    [
        0,
        7.2478,
        16.7333
    ],
    [
        0,
        7.248,
        16.75
    ],
    [
        0,
        7.2475,
        16.7667
    ],
    [
        0,
        7.2475,
        16.7833
    ],
    [
        0,
        7.2479,
        16.8
    ],
    [
        0,
        7.2473,
        16.8167
    ],
    [
        0,
        7.2473,
        16.8333
    ],
    [
        0,
        7.2479,
        16.85
    ],
    [
        0,
        7.2479,
        16.8667
    ],
    [
        0,
        7.2475,
        16.8833
    ],
    [
        0,
        7.2474,
        16.9
    ],
    [
        0,
        7.2471,
        16.9167
    ],
    [
        0,
        7.2478,
        16.9333
    ],
    [
        0,
        7.2471,
        16.95
    ],
    [
        0,
        7.2472,
        16.9667
    ],
    [
        0,
        7.2478,
        16.9833
    ],
    [
        0,
        7.2476,
        17
    ],
    [
        0,
        7.2472,
        17.0167
    ],
    [
        0,
        7.2472,
        17.0333
    ],
    [
        0,
        7.2469,
        17.05
    ],
    [
        0,
        7.247,
        17.0667
    ],
    [
        0,
        7.2472,
        17.0833
    ],
    [
        0,
        7.2474,
        17.1
    ],
    [
        0,
        7.2462,
        17.1167
    ],
    [
        0,
        7.247,
        17.1333
    ],
    [
        0,
        7.2464,
        17.15
    ],
    [
        0,
        7.2473,
        17.1667
    ],
    [
        0,
        7.2467,
        17.1833
    ],
    [
        0,
        7.2473,
        17.2
    ],
    [
        0,
        7.2466,
        17.2167
    ],
    [
        0,
        7.2466,
        17.2333
    ],
    [
        0,
        7.2466,
        17.25
    ],
    [
        0,
        7.2472,
        17.2667
    ],
    [
        0,
        7.2465,
        17.2833
    ],
    [
        0,
        7.2472,
        17.3
    ],
    [
        0,
        7.2462,
        17.3167
    ],
    [
        0,
        7.2466,
        17.3333
    ],
    [
        0,
        7.2469,
        17.35
    ],
    [
        0,
        7.2468,
        17.3667
    ],
    [
        0,
        7.2467,
        17.3833
    ],
    [
        0,
        7.246,
        17.4
    ],
    [
        0,
        7.2462,
        17.4167
    ],
    [
        0,
        7.2458,
        17.4333
    ],
    [
        0,
        7.246,
        17.45
    ],
    [
        0,
        7.2459,
        17.4667
    ],
    [
        0,
        7.246,
        17.4833
    ],
    [
        0,
        7.2459,
        17.5
    ],
    [
        0,
        7.2457,
        17.5167
    ],
    [
        0,
        7.2461,
        17.5333
    ],
    [
        0,
        7.246,
        17.55
    ],
    [
        0,
        7.246,
        17.5667
    ],
    [
        0,
        7.2461,
        17.5833
    ],
    [
        0,
        7.2453,
        17.6
    ],
    [
        0,
        7.2457,
        17.6167
    ],
    [
        0,
        7.2464,
        17.6333
    ],
    [
        0,
        7.246,
        17.65
    ],
    [
        0,
        7.2453,
        17.6667
    ],
    [
        0,
        7.2462,
        17.6833
    ],
    [
        0,
        7.2458,
        17.7
    ],
    [
        0,
        7.2459,
        17.7167
    ],
    [
        0,
        7.2452,
        17.7333
    ],
    [
        0,
        7.2449,
        17.75
    ],
    [
        0,
        7.2455,
        17.7667
    ],
    [
        0,
        7.2453,
        17.7833
    ],
    [
        0,
        7.2455,
        17.8
    ],
    [
        0,
        7.2449,
        17.8167
    ],
    [
        0,
        7.2454,
        17.8333
    ],
    [
        0,
        7.2455,
        17.85
    ],
    [
        0,
        7.2455,
        17.8667
    ],
    [
        0,
        7.2454,
        17.8833
    ],
    [
        0,
        7.2454,
        17.9
    ],
    [
        0,
        7.2456,
        17.9167
    ],
    [
        0,
        7.2458,
        17.9333
    ],
    [
        0,
        7.2453,
        17.95
    ],
    [
        0,
        7.245,
        17.9667
    ],
    [
        0,
        7.2448,
        17.9833
    ],
    [
        0,
        7.2449,
        18
    ],
    [
        0,
        7.2455,
        18.0167
    ],
    [
        0,
        7.2449,
        18.0333
    ],
    [
        0,
        7.2448,
        18.05
    ],
    [
        0,
        7.2452,
        18.0667
    ],
    [
        0,
        7.2452,
        18.0833
    ],
    [
        0,
        7.245,
        18.1
    ],
    [
        0,
        7.2444,
        18.1167
    ],
    [
        0,
        7.2447,
        18.1333
    ],
    [
        0,
        7.245,
        18.15
    ],
    [
        0,
        7.2451,
        18.1667
    ],
    [
        0,
        7.2453,
        18.1833
    ],
    [
        0,
        7.2447,
        18.2
    ],
    [
        0,
        7.2456,
        18.2167
    ],
    [
        0,
        7.2452,
        18.2333
    ],
    [
        0,
        7.2444,
        18.25
    ],
    [
        0,
        7.2447,
        18.2667
    ],
    [
        0,
        7.245,
        18.2833
    ],
    [
        0,
        7.2444,
        18.3
    ],
    [
        0,
        7.2451,
        18.3167
    ],
    [
        0,
        7.2438,
        18.3333
    ],
    [
        0,
        7.2439,
        18.35
    ],
    [
        0,
        7.2444,
        18.3667
    ],
    [
        0,
        7.2444,
        18.3833
    ],
    [
        0,
        7.2438,
        18.4
    ],
    [
        0,
        7.2439,
        18.4167
    ],
    [
        0,
        7.2441,
        18.4333
    ],
    [
        0,
        7.2433,
        18.45
    ],
    [
        0,
        7.2436,
        18.4667
    ],
    [
        0,
        7.2442,
        18.4833
    ],
    [
        0,
        7.2432,
        18.5
    ],
    [
        0,
        7.2435,
        18.5167
    ],
    [
        0,
        7.2432,
        18.5333
    ],
    [
        0,
        7.2437,
        18.55
    ],
    [
        0,
        7.2433,
        18.5667
    ],
    [
        0,
        7.2435,
        18.5833
    ],
    [
        0,
        7.2429,
        18.6
    ],
    [
        0,
        7.2434,
        18.6167
    ],
    [
        0,
        7.2427,
        18.6333
    ],
    [
        0,
        7.2434,
        18.65
    ],
    [
        0,
        7.243,
        18.6667
    ],
    [
        0,
        7.2433,
        18.6833
    ],
    [
        0,
        7.2429,
        18.7
    ],
    [
        0,
        7.2429,
        18.7167
    ],
    [
        0,
        7.2433,
        18.7333
    ],
    [
        0,
        7.2424,
        18.75
    ],
    [
        0,
        7.2435,
        18.7667
    ],
    [
        0,
        7.2434,
        18.7833
    ],
    [
        0,
        7.243,
        18.8
    ],
    [
        0,
        7.2428,
        18.8167
    ],
    [
        0,
        7.243,
        18.8333
    ],
    [
        0,
        7.2427,
        18.85
    ],
    [
        0,
        7.243,
        18.8667
    ],
    [
        0,
        7.2432,
        18.8833
    ],
    [
        0,
        7.2429,
        18.9
    ],
    [
        0,
        7.2424,
        18.9167
    ],
    [
        0,
        7.2425,
        18.9333
    ],
    [
        0,
        7.2426,
        18.95
    ],
    [
        0,
        7.2421,
        18.9667
    ],
    [
        0,
        7.2427,
        18.9833
    ],
    [
        0,
        7.2422,
        19
    ],
    [
        0,
        7.2426,
        19.0167
    ],
    [
        0,
        7.242,
        19.0333
    ],
    [
        0,
        7.242,
        19.05
    ],
    [
        0,
        7.2424,
        19.0667
    ],
    [
        0,
        7.242,
        19.0833
    ],
    [
        0,
        7.2421,
        19.1
    ],
    [
        0,
        7.2413,
        19.1167
    ],
    [
        0,
        7.2418,
        19.1333
    ],
    [
        0,
        7.242,
        19.15
    ],
    [
        0,
        7.2419,
        19.1667
    ],
    [
        0,
        7.2418,
        19.1833
    ],
    [
        0,
        7.242,
        19.2
    ],
    [
        0,
        7.2418,
        19.2167
    ],
    [
        0,
        7.2416,
        19.2333
    ],
    [
        0,
        7.2417,
        19.25
    ],
    [
        0,
        7.2415,
        19.2667
    ],
    [
        0,
        7.2419,
        19.2833
    ],
    [
        0,
        7.2419,
        19.3
    ],
    [
        0,
        7.2417,
        19.3167
    ],
    [
        0,
        7.2417,
        19.3333
    ],
    [
        0,
        7.2419,
        19.35
    ],
    [
        0,
        7.2418,
        19.3667
    ],
    [
        0,
        7.2414,
        19.3833
    ],
    [
        0,
        7.2423,
        19.4
    ],
    [
        0,
        7.2424,
        19.4167
    ],
    [
        0,
        7.2419,
        19.4333
    ],
    [
        0,
        7.2418,
        19.45
    ],
    [
        0,
        7.242,
        19.4667
    ],
    [
        0,
        7.2418,
        19.4833
    ],
    [
        0,
        7.2418,
        19.5
    ],
    [
        0,
        7.2416,
        19.5167
    ],
    [
        0,
        7.2412,
        19.5333
    ],
    [
        0,
        7.2416,
        19.55
    ],
    [
        0,
        7.2419,
        19.5667
    ],
    [
        0,
        7.2422,
        19.5833
    ],
    [
        0,
        7.2417,
        19.6
    ],
    [
        0,
        7.2425,
        19.6167
    ],
    [
        0,
        7.2413,
        19.6333
    ],
    [
        0,
        7.2411,
        19.65
    ],
    [
        0,
        7.2408,
        19.6667
    ],
    [
        0,
        7.242,
        19.6833
    ],
    [
        0,
        7.2413,
        19.7
    ],
    [
        0,
        7.241,
        19.7167
    ],
    [
        0,
        7.2414,
        19.7333
    ],
    [
        0,
        7.2412,
        19.75
    ],
    [
        0,
        7.2409,
        19.7667
    ],
    [
        0,
        7.2412,
        19.7833
    ],
    [
        0,
        7.2407,
        19.8
    ],
    [
        0,
        7.2411,
        19.8167
    ],
    [
        0,
        7.2412,
        19.8333
    ],
    [
        0,
        7.2407,
        19.85
    ],
    [
        0,
        7.2416,
        19.8667
    ],
    [
        0,
        7.2417,
        19.8833
    ],
    [
        0,
        7.2417,
        19.9
    ],
    [
        0,
        7.2417,
        19.9167
    ],
    [
        0,
        7.2412,
        19.9333
    ],
    [
        0,
        7.2413,
        19.95
    ],
    [
        0,
        7.2411,
        19.9667
    ],
    [
        0,
        7.2411,
        19.9833
    ],
    [
        0,
        7.2412,
        20
    ],
    [
        0,
        7.2414,
        20.0167
    ],
    [
        0,
        7.2418,
        20.0333
    ],
    [
        0,
        7.241,
        20.05
    ],
    [
        0,
        7.2414,
        20.0667
    ],
    [
        0,
        7.2415,
        20.0833
    ],
    [
        0,
        7.2411,
        20.1
    ],
    [
        0,
        7.2411,
        20.1167
    ],
    [
        0,
        7.2409,
        20.1333
    ],
    [
        0,
        7.2417,
        20.15
    ],
    [
        0,
        7.2412,
        20.1667
    ],
    [
        0,
        7.2409,
        20.1833
    ],
    [
        0,
        7.2411,
        20.2
    ],
    [
        -60.0004,
        7.086,
        20.2167
    ],
    [
        -60.0017,
        7.0156,
        20.2333
    ],
    [
        -60.0026,
        7.0102,
        20.25
    ],
    [
        -60.0042,
        7.0069,
        20.2667
    ],
    [
        -60.0015,
        7.003,
        20.2833
    ],
    [
        -60.0018,
        7.0013,
        20.3
    ],
    [
        -60.0023,
        6.9975,
        20.3167
    ],
    [
        -60.0022,
        6.996,
        20.3333
    ],
    [
        -60.0038,
        6.9929,
        20.35
    ],
    [
        -59.9999,
        6.9909,
        20.3667
    ],
    [
        -60.0035,
        6.9885,
        20.3833
    ],
    [
        0,
        7.1166,
        20.4
    ],
    [
        0,
        7.214,
        20.4167
    ],
    [
        0,
        7.2164,
        20.4333
    ],
    [
        0,
        7.218,
        20.45
    ],
    [
        0,
        7.2196,
        20.4667
    ],
    [
        0,
        7.2201,
        20.4833
    ],
    [
        0,
        7.2213,
        20.5
    ],
    [
        0,
        7.2219,
        20.5167
    ],
    [
        0,
        7.2229,
        20.5333
    ],
    [
        0,
        7.2243,
        20.55
    ],
    [
        0,
        7.2247,
        20.5667
    ],
    [
        0,
        7.2248,
        20.5833
    ],
    [
        0,
        7.2253,
        20.6
    ],
    [
        0,
        7.2251,
        20.6167
    ],
    [
        0,
        7.2259,
        20.6333
    ],
    [
        0,
        7.2259,
        20.65
    ],
    [
        0,
        7.2262,
        20.6667
    ],
    [
        0,
        7.2261,
        20.6833
    ],
    [
        0,
        7.2272,
        20.7
    ],
    [
        0,
        7.2265,
        20.7167
    ],
    [
        0,
        7.2276,
        20.7333
    ],
    [
        0,
        7.2277,
        20.75
    ],
    [
        0,
        7.2279,
        20.7667
    ],
    [
        0,
        7.2274,
        20.7833
    ],
    [
        0,
        7.2276,
        20.8
    ],
    [
        0,
        7.2282,
        20.8167
    ],
    [
        0,
        7.2284,
        20.8333
    ],
    [
        0,
        7.228,
        20.85
    ],
    [
        0,
        7.2285,
        20.8667
    ],
    [
        0,
        7.2288,
        20.8833
    ],
    [
        0,
        7.2282,
        20.9
    ],
    [
        0,
        7.2286,
        20.9167
    ],
    [
        0,
        7.2288,
        20.9333
    ],
    [
        0,
        7.2287,
        20.95
    ],
    [
        0,
        7.2289,
        20.9667
    ],
    [
        0,
        7.2291,
        20.9833
    ],
    [
        0,
        7.2293,
        21
    ],
    [
        0,
        7.2302,
        21.0167
    ],
    [
        0,
        7.2297,
        21.0333
    ],
    [
        0,
        7.2296,
        21.05
    ],
    [
        0,
        7.2307,
        21.0667
    ],
    [
        0,
        7.2304,
        21.0833
    ],
    [
        0,
        7.2307,
        21.1
    ],
    [
        0,
        7.2305,
        21.1167
    ],
    [
        0,
        7.2302,
        21.1333
    ],
    [
        0,
        7.2305,
        21.15
    ],
    [
        0,
        7.23,
        21.1667
    ],
    [
        0,
        7.2309,
        21.1833
    ],
    [
        0,
        7.2305,
        21.2
    ],
    [
        0,
        7.2305,
        21.2167
    ],
    [
        0,
        7.2307,
        21.2333
    ],
    [
        0,
        7.2306,
        21.25
    ],
    [
        0,
        7.2307,
        21.2667
    ],
    [
        0,
        7.2308,
        21.2833
    ],
    [
        0,
        7.2304,
        21.3
    ],
    [
        0,
        7.2305,
        21.3167
    ],
    [
        0,
        7.2312,
        21.3333
    ],
    [
        0,
        7.2313,
        21.35
    ],
    [
        0,
        7.2316,
        21.3667
    ],
    [
        0,
        7.2314,
        21.3833
    ],
    [
        0,
        7.2314,
        21.4
    ],
    [
        0,
        7.2319,
        21.4167
    ],
    [
        0,
        7.2317,
        21.4333
    ],
    [
        0,
        7.2317,
        21.45
    ],
    [
        0,
        7.2322,
        21.4667
    ],
    [
        0,
        7.2319,
        21.4833
    ],
    [
        0,
        7.2322,
        21.5
    ],
    [
        0,
        7.2321,
        21.5167
    ],
    [
        0,
        7.2319,
        21.5333
    ],
    [
        0,
        7.2326,
        21.55
    ],
    [
        0,
        7.2317,
        21.5667
    ],
    [
        0,
        7.2316,
        21.5833
    ],
    [
        0,
        7.2323,
        21.6
    ],
    [
        0,
        7.232,
        21.6167
    ],
    [
        0,
        7.232,
        21.6333
    ],
    [
        0,
        7.2318,
        21.65
    ],
    [
        0,
        7.2322,
        21.6667
    ],
    [
        0,
        7.2324,
        21.6833
    ],
    [
        0,
        7.2318,
        21.7
    ],
    [
        0,
        7.2319,
        21.7167
    ],
    [
        0,
        7.2323,
        21.7333
    ],
    [
        0,
        7.2324,
        21.75
    ],
    [
        0,
        7.2324,
        21.7667
    ],
    [
        0,
        7.2321,
        21.7833
    ],
    [
        0,
        7.2322,
        21.8
    ],
    [
        0,
        7.2325,
        21.8167
    ],
    [
        0,
        7.2324,
        21.8333
    ],
    [
        0,
        7.2327,
        21.85
    ],
    [
        0,
        7.2328,
        21.8667
    ],
    [
        0,
        7.233,
        21.8833
    ],
    [
        0,
        7.2325,
        21.9
    ],
    [
        0,
        7.2337,
        21.9167
    ],
    [
        0,
        7.233,
        21.9333
    ],
    [
        0,
        7.2329,
        21.95
    ],
    [
        0,
        7.2331,
        21.9667
    ],
    [
        0,
        7.233,
        21.9833
    ],
    [
        0,
        7.2336,
        22
    ],
    [
        0,
        7.2337,
        22.0167
    ],
    [
        0,
        7.2325,
        22.0333
    ],
    [
        0,
        7.2333,
        22.05
    ],
    [
        0,
        7.2343,
        22.0667
    ],
    [
        0,
        7.2332,
        22.0833
    ],
    [
        0,
        7.2331,
        22.1
    ],
    [
        0,
        7.2329,
        22.1167
    ],
    [
        0,
        7.2331,
        22.1333
    ],
    [
        0,
        7.2333,
        22.15
    ],
    [
        0,
        7.2338,
        22.1667
    ],
    [
        0,
        7.2332,
        22.1833
    ],
    [
        0,
        7.2335,
        22.2
    ],
    [
        0,
        7.233,
        22.2167
    ],
    [
        0,
        7.2333,
        22.2333
    ],
    [
        0,
        7.2329,
        22.25
    ],
    [
        0,
        7.2332,
        22.2667
    ],
    [
        0,
        7.2331,
        22.2833
    ],
    [
        0,
        7.2331,
        22.3
    ],
    [
        0,
        7.233,
        22.3167
    ],
    [
        0,
        7.233,
        22.3333
    ],
    [
        0,
        7.2339,
        22.35
    ],
    [
        0,
        7.2332,
        22.3667
    ],
    [
        0,
        7.2328,
        22.3833
    ],
    [
        0,
        7.2331,
        22.4
    ],
    [
        0,
        7.2327,
        22.4167
    ],
    [
        0,
        7.2334,
        22.4333
    ],
    [
        0,
        7.233,
        22.45
    ],
    [
        0,
        7.233,
        22.4667
    ],
    [
        0,
        7.2331,
        22.4833
    ],
    [
        0,
        7.2327,
        22.5
    ],
    [
        0,
        7.2332,
        22.5167
    ],
    [
        0,
        7.2335,
        22.5333
    ],
    [
        0,
        7.2332,
        22.55
    ],
    [
        0,
        7.2337,
        22.5667
    ],
    [
        0,
        7.2333,
        22.5833
    ],
    [
        0,
        7.2334,
        22.6
    ],
    [
        0,
        7.2337,
        22.6167
    ],
    [
        0,
        7.2335,
        22.6333
    ],
    [
        0,
        7.2332,
        22.65
    ],
    [
        0,
        7.2338,
        22.6667
    ],
    [
        0,
        7.2338,
        22.6833
    ],
    [
        0,
        7.2338,
        22.7
    ],
    [
        0,
        7.2338,
        22.7167
    ],
    [
        0,
        7.2337,
        22.7333
    ],
    [
        0,
        7.234,
        22.75
    ],
    [
        0,
        7.2335,
        22.7667
    ],
    [
        0,
        7.2333,
        22.7833
    ],
    [
        0,
        7.2334,
        22.8
    ],
    [
        0,
        7.234,
        22.8167
    ],
    [
        0,
        7.2335,
        22.8333
    ],
    [
        0,
        7.2339,
        22.85
    ],
    [
        0,
        7.234,
        22.8667
    ],
    [
        0,
        7.2334,
        22.8833
    ],
    [
        0,
        7.2332,
        22.9
    ],
    [
        0,
        7.2338,
        22.9167
    ],
    [
        0,
        7.2337,
        22.9333
    ],
    [
        0,
        7.2337,
        22.95
    ],
    [
        0,
        7.2338,
        22.9667
    ],
    [
        0,
        7.2336,
        22.9833
    ],
    [
        0,
        7.2331,
        23
    ],
    [
        0,
        7.2335,
        23.0167
    ],
    [
        0,
        7.2336,
        23.0333
    ],
    [
        0,
        7.2329,
        23.05
    ],
    [
        0,
        7.2339,
        23.0667
    ],
    [
        0,
        7.2333,
        23.0833
    ],
    [
        0,
        7.2337,
        23.1
    ],
    [
        0,
        7.2331,
        23.1167
    ],
    [
        0,
        7.2343,
        23.1333
    ],
    [
        0,
        7.2344,
        23.15
    ],
    [
        0,
        7.2346,
        23.1667
    ],
    [
        0,
        7.2331,
        23.1833
    ],
    [
        0,
        7.2334,
        23.2
    ],
    [
        0,
        7.2335,
        23.2167
    ],
    [
        0,
        7.2342,
        23.2333
    ],
    [
        0,
        7.2342,
        23.25
    ],
    [
        0,
        7.2336,
        23.2667
    ],
    [
        0,
        7.2331,
        23.2833
    ],
    [
        0,
        7.2341,
        23.3
    ],
    [
        0,
        7.2338,
        23.3167
    ],
    [
        0,
        7.2339,
        23.3333
    ],
    [
        0,
        7.234,
        23.35
    ],
    [
        0,
        7.2333,
        23.3667
    ],
    [
        0,
        7.2343,
        23.3833
    ],
    [
        0,
        7.2337,
        23.4
    ],
    [
        0,
        7.2337,
        23.4167
    ],
    [
        0,
        7.2336,
        23.4333
    ],
    [
        0,
        7.2336,
        23.45
    ],
    [
        0,
        7.2334,
        23.4667
    ],
    [
        0,
        7.2335,
        23.4833
    ],
    [
        0,
        7.2338,
        23.5
    ],
    [
        0,
        7.2339,
        23.5167
    ],
    [
        0,
        7.2338,
        23.5333
    ],
    [
        0,
        7.2336,
        23.55
    ],
    [
        0,
        7.2335,
        23.5667
    ],
    [
        0,
        7.2338,
        23.5833
    ],
    [
        0,
        7.2333,
        23.6
    ],
    [
        0,
        7.2333,
        23.6167
    ],
    [
        0,
        7.2338,
        23.6333
    ],
    [
        0,
        7.2338,
        23.65
    ],
    [
        0,
        7.2338,
        23.6667
    ],
    [
        0,
        7.2342,
        23.6833
    ],
    [
        0,
        7.2343,
        23.7
    ],
    [
        0,
        7.2344,
        23.7167
    ],
    [
        0,
        7.2341,
        23.7333
    ],
    [
        0,
        7.2346,
        23.75
    ],
    [
        0,
        7.2338,
        23.7667
    ],
    [
        0,
        7.2346,
        23.7833
    ],
    [
        0,
        7.2338,
        23.8
    ],
    [
        0,
        7.234,
        23.8167
    ],
    [
        0,
        7.2339,
        23.8333
    ],
    [
        0,
        7.234,
        23.85
    ],
    [
        0,
        7.2336,
        23.8667
    ],
    [
        0,
        7.2335,
        23.8833
    ],
    [
        0,
        7.2331,
        23.9
    ],
    [
        0,
        7.2342,
        23.9167
    ],
    [
        0,
        7.2337,
        23.9333
    ],
    [
        0,
        7.2336,
        23.95
    ],
    [
        0,
        7.2338,
        23.9667
    ],
    [
        0,
        7.2341,
        23.9833
    ],
    [
        0,
        7.2338,
        24
    ],
    [
        0,
        7.2346,
        24.0167
    ],
    [
        0,
        7.2347,
        24.0333
    ],
    [
        0,
        7.2342,
        24.05
    ],
    [
        0,
        7.234,
        24.0667
    ],
    [
        0,
        7.2337,
        24.0833
    ],
    [
        0,
        7.2341,
        24.1
    ],
    [
        0,
        7.2346,
        24.1167
    ],
    [
        0,
        7.2341,
        24.1333
    ],
    [
        0,
        7.2342,
        24.15
    ],
    [
        0,
        7.2345,
        24.1667
    ],
    [
        0,
        7.234,
        24.1833
    ],
    [
        0,
        7.2341,
        24.2
    ],
    [
        0,
        7.2344,
        24.2167
    ],
    [
        0,
        7.2342,
        24.2333
    ],
    [
        0,
        7.2345,
        24.25
    ],
    [
        0,
        7.2341,
        24.2667
    ],
    [
        0,
        7.2341,
        24.2833
    ],
    [
        0,
        7.2333,
        24.3
    ],
    [
        0,
        7.2337,
        24.3167
    ],
    [
        0,
        7.2339,
        24.3333
    ],
    [
        0,
        7.2343,
        24.35
    ],
    [
        0,
        7.2346,
        24.3667
    ],
    [
        0,
        7.2341,
        24.3833
    ],
    [
        0,
        7.2342,
        24.4
    ],
    [
        0,
        7.2338,
        24.4167
    ],
    [
        0,
        7.234,
        24.4333
    ],
    [
        0,
        7.2338,
        24.45
    ],
    [
        0,
        7.2338,
        24.4667
    ],
    [
        0,
        7.2341,
        24.4833
    ],
    [
        0,
        7.2339,
        24.5
    ],
    [
        0,
        7.2346,
        24.5167
    ],
    [
        0,
        7.2344,
        24.5333
    ],
    [
        0,
        7.2334,
        24.55
    ],
    [
        0,
        7.2338,
        24.5667
    ],
    [
        0,
        7.234,
        24.5833
    ],
    [
        0,
        7.2341,
        24.6
    ],
    [
        0,
        7.2338,
        24.6167
    ],
    [
        0,
        7.2341,
        24.6333
    ],
    [
        0,
        7.2336,
        24.65
    ],
    [
        0,
        7.2335,
        24.6667
    ],
    [
        0,
        7.2339,
        24.6833
    ],
    [
        0,
        7.2333,
        24.7
    ],
    [
        0,
        7.233,
        24.7167
    ],
    [
        0,
        7.233,
        24.7333
    ],
    [
        0,
        7.233,
        24.75
    ],
    [
        0,
        7.2332,
        24.7667
    ],
    [
        0,
        7.2336,
        24.7833
    ],
    [
        0,
        7.2332,
        24.8
    ],
    [
        0,
        7.2332,
        24.8167
    ],
    [
        0,
        7.2332,
        24.8333
    ],
    [
        0,
        7.2337,
        24.85
    ],
    [
        0,
        7.2335,
        24.8667
    ],
    [
        0,
        7.2331,
        24.8833
    ],
    [
        0,
        7.2338,
        24.9
    ],
    [
        0,
        7.2333,
        24.9167
    ],
    [
        0,
        7.2335,
        24.9333
    ],
    [
        0,
        7.2336,
        24.95
    ],
    [
        0,
        7.2333,
        24.9667
    ],
    [
        0,
        7.2332,
        24.9833
    ],
    [
        0,
        7.2335,
        25
    ],
    [
        0,
        7.2334,
        25.0167
    ],
    [
        0,
        7.2331,
        25.0333
    ],
    [
        0,
        7.2337,
        25.05
    ],
    [
        0,
        7.2341,
        25.0667
    ],
    [
        0,
        7.2341,
        25.0833
    ],
    [
        0,
        7.2333,
        25.1
    ],
    [
        0,
        7.2337,
        25.1167
    ],
    [
        0,
        7.234,
        25.1333
    ],
    [
        0,
        7.2337,
        25.15
    ],
    [
        0,
        7.2333,
        25.1667
    ],
    [
        0,
        7.2333,
        25.1833
    ],
    [
        0,
        7.2336,
        25.2
    ],
    [
        0,
        7.2334,
        25.2167
    ],
    [
        0,
        7.2334,
        25.2333
    ],
    [
        0,
        7.2343,
        25.25
    ],
    [
        0,
        7.2331,
        25.2667
    ],
    [
        0,
        7.2333,
        25.2833
    ],
    [
        0,
        7.233,
        25.3
    ],
    [
        0,
        7.2334,
        25.3167
    ],
    [
        0,
        7.2337,
        25.3333
    ],
    [
        0,
        7.2335,
        25.35
    ],
    [
        0,
        7.2336,
        25.3667
    ],
    [
        0,
        7.2334,
        25.3833
    ],
    [
        0,
        7.2341,
        25.4
    ],
    [
        0,
        7.2334,
        25.4167
    ],
    [
        0,
        7.2337,
        25.4333
    ],
    [
        0,
        7.2339,
        25.45
    ],
    [
        0,
        7.2336,
        25.4667
    ],
    [
        0,
        7.2338,
        25.4833
    ],
    [
        0,
        7.2336,
        25.5
    ],
    [
        0,
        7.2332,
        25.5167
    ],
    [
        0,
        7.233,
        25.5333
    ],
    [
        0,
        7.2333,
        25.55
    ],
    [
        0,
        7.2331,
        25.5667
    ],
    [
        0,
        7.2332,
        25.5833
    ],
    [
        0,
        7.233,
        25.6
    ],
    [
        0,
        7.2336,
        25.6167
    ],
    [
        0,
        7.2333,
        25.6333
    ],
    [
        0,
        7.2329,
        25.65
    ],
    [
        0,
        7.233,
        25.6667
    ],
    [
        0,
        7.2335,
        25.6833
    ],
    [
        0,
        7.2341,
        25.7
    ],
    [
        0,
        7.2337,
        25.7167
    ],
    [
        0,
        7.2339,
        25.7333
    ],
    [
        0,
        7.233,
        25.75
    ],
    [
        0,
        7.234,
        25.7667
    ],
    [
        0,
        7.2332,
        25.7833
    ],
    [
        0,
        7.2333,
        25.8
    ],
    [
        0,
        7.2331,
        25.8167
    ],
    [
        0,
        7.2332,
        25.8333
    ],
    [
        0,
        7.2334,
        25.85
    ],
    [
        0,
        7.2338,
        25.8667
    ],
    [
        0,
        7.2334,
        25.8833
    ],
    [
        0,
        7.2339,
        25.9
    ],
    [
        0,
        7.2333,
        25.9167
    ],
    [
        0,
        7.2336,
        25.9333
    ],
    [
        0,
        7.2339,
        25.95
    ],
    [
        0,
        7.2339,
        25.9667
    ],
    [
        0,
        7.2337,
        25.9833
    ],
    [
        0,
        7.2341,
        26
    ],
    [
        0,
        7.2337,
        26.0167
    ],
    [
        0,
        7.2336,
        26.0333
    ],
    [
        0,
        7.2339,
        26.05
    ],
    [
        0,
        7.2334,
        26.0667
    ],
    [
        0,
        7.2338,
        26.0833
    ],
    [
        0,
        7.2333,
        26.1
    ],
    [
        0,
        7.2335,
        26.1167
    ],
    [
        0,
        7.2335,
        26.1333
    ],
    [
        0,
        7.2338,
        26.15
    ],
    [
        0,
        7.2338,
        26.1667
    ],
    [
        0,
        7.2334,
        26.1833
    ],
    [
        0,
        7.2336,
        26.2
    ],
    [
        0,
        7.2338,
        26.2167
    ],
    [
        0,
        7.2336,
        26.2333
    ],
    [
        0,
        7.2334,
        26.25
    ],
    [
        0,
        7.2334,
        26.2667
    ],
    [
        0,
        7.2335,
        26.2833
    ],
    [
        0,
        7.2331,
        26.3
    ],
    [
        0,
        7.2337,
        26.3167
    ],
    [
        0,
        7.2331,
        26.3333
    ],
    [
        0,
        7.2333,
        26.35
    ],
    [
        0,
        7.2336,
        26.3667
    ],
    [
        0,
        7.2329,
        26.3833
    ],
    [
        0,
        7.2338,
        26.4
    ],
    [
        0,
        7.2337,
        26.4167
    ],
    [
        0,
        7.2337,
        26.4333
    ],
    [
        0,
        7.2339,
        26.45
    ],
    [
        0,
        7.2337,
        26.4667
    ],
    [
        0,
        7.2339,
        26.4833
    ],
    [
        0,
        7.234,
        26.5
    ],
    [
        0,
        7.2339,
        26.5167
    ],
    [
        0,
        7.2332,
        26.5333
    ],
    [
        0,
        7.234,
        26.55
    ],
    [
        0,
        7.2335,
        26.5667
    ],
    [
        0,
        7.234,
        26.5833
    ],
    [
        0,
        7.2329,
        26.6
    ],
    [
        0,
        7.2342,
        26.6167
    ],
    [
        0,
        7.2341,
        26.6333
    ],
    [
        0,
        7.2334,
        26.65
    ],
    [
        0,
        7.2342,
        26.6667
    ],
    [
        0,
        7.2338,
        26.6833
    ],
    [
        0,
        7.2341,
        26.7
    ],
    [
        0,
        7.2334,
        26.7167
    ],
    [
        0,
        7.2337,
        26.7333
    ],
    [
        0,
        7.2335,
        26.75
    ],
    [
        0,
        7.2338,
        26.7667
    ],
    [
        0,
        7.2344,
        26.7833
    ],
    [
        0,
        7.2339,
        26.8
    ],
    [
        0,
        7.2337,
        26.8167
    ],
    [
        0,
        7.2335,
        26.8333
    ],
    [
        0,
        7.2331,
        26.85
    ],
    [
        0,
        7.2338,
        26.8667
    ],
    [
        0,
        7.2334,
        26.8833
    ],
    [
        0,
        7.2327,
        26.9
    ],
    [
        0,
        7.2332,
        26.9167
    ],
    [
        0,
        7.2334,
        26.9333
    ],
    [
        0,
        7.2333,
        26.95
    ],
    [
        0,
        7.2336,
        26.9667
    ],
    [
        0,
        7.2336,
        26.9833
    ],
    [
        0,
        7.2331,
        27
    ],
    [
        0,
        7.2335,
        27.0167
    ],
    [
        0,
        7.2334,
        27.0333
    ],
    [
        0,
        7.2334,
        27.05
    ],
    [
        0,
        7.2333,
        27.0667
    ],
    [
        0,
        7.2335,
        27.0833
    ],
    [
        0,
        7.2334,
        27.1
    ],
    [
        0,
        7.2331,
        27.1167
    ],
    [
        0,
        7.2326,
        27.1333
    ],
    [
        0,
        7.2328,
        27.15
    ],
    [
        0,
        7.2334,
        27.1667
    ],
    [
        0,
        7.2333,
        27.1833
    ],
    [
        0,
        7.233,
        27.2
    ],
    [
        0,
        7.2333,
        27.2167
    ],
    [
        0,
        7.2334,
        27.2333
    ],
    [
        0,
        7.2333,
        27.25
    ],
    [
        0,
        7.2332,
        27.2667
    ],
    [
        0,
        7.2329,
        27.2833
    ],
    [
        0,
        7.2332,
        27.3
    ],
    [
        0,
        7.2329,
        27.3167
    ],
    [
        0,
        7.233,
        27.3333
    ],
    [
        0,
        7.2336,
        27.35
    ],
    [
        0,
        7.2329,
        27.3667
    ],
    [
        0,
        7.2324,
        27.3833
    ],
    [
        0,
        7.2331,
        27.4
    ],
    [
        0,
        7.2329,
        27.4167
    ],
    [
        0,
        7.233,
        27.4333
    ],
    [
        0,
        7.2328,
        27.45
    ],
    [
        0,
        7.2325,
        27.4667
    ],
    [
        0,
        7.233,
        27.4833
    ],
    [
        0,
        7.2333,
        27.5
    ],
    [
        0,
        7.2329,
        27.5167
    ],
    [
        0,
        7.2331,
        27.5333
    ],
    [
        0,
        7.2327,
        27.55
    ],
    [
        0,
        7.2322,
        27.5667
    ],
    [
        0,
        7.2329,
        27.5833
    ],
    [
        0,
        7.2333,
        27.6
    ],
    [
        0,
        7.2332,
        27.6167
    ],
    [
        0,
        7.2331,
        27.6333
    ],
    [
        0,
        7.2338,
        27.65
    ],
    [
        0,
        7.2326,
        27.6667
    ],
    [
        0,
        7.2331,
        27.6833
    ],
    [
        0,
        7.2325,
        27.7
    ],
    [
        0,
        7.2331,
        27.7167
    ],
    [
        0,
        7.233,
        27.7333
    ],
    [
        0,
        7.2331,
        27.75
    ],
    [
        0,
        7.2327,
        27.7667
    ],
    [
        0,
        7.2326,
        27.7833
    ],
    [
        0,
        7.2331,
        27.8
    ],
    [
        0,
        7.233,
        27.8167
    ],
    [
        0,
        7.233,
        27.8333
    ],
    [
        0,
        7.2327,
        27.85
    ],
    [
        0,
        7.233,
        27.8667
    ],
    [
        0,
        7.233,
        27.8833
    ],
    [
        0,
        7.2328,
        27.9
    ],
    [
        0,
        7.2328,
        27.9167
    ],
    [
        0,
        7.2316,
        27.9333
    ],
    [
        0,
        7.233,
        27.95
    ],
    [
        0,
        7.2328,
        27.9667
    ],
    [
        0,
        7.2326,
        27.9833
    ],
    [
        0,
        7.2322,
        28
    ],
    [
        0,
        7.2326,
        28.0167
    ],
    [
        0,
        7.233,
        28.0333
    ],
    [
        0,
        7.2327,
        28.05
    ],
    [
        0,
        7.2328,
        28.0667
    ],
    [
        0,
        7.2317,
        28.0833
    ],
    [
        0,
        7.2328,
        28.1
    ],
    [
        0,
        7.2325,
        28.1167
    ],
    [
        0,
        7.2326,
        28.1333
    ],
    [
        0,
        7.2322,
        28.15
    ],
    [
        0,
        7.233,
        28.1667
    ],
    [
        0,
        7.2329,
        28.1833
    ],
    [
        0,
        7.2327,
        28.2
    ],
    [
        0,
        7.2329,
        28.2167
    ],
    [
        0,
        7.2325,
        28.2333
    ],
    [
        0,
        7.2329,
        28.25
    ],
    [
        0,
        7.2325,
        28.2667
    ],
    [
        0,
        7.2325,
        28.2833
    ],
    [
        0,
        7.2329,
        28.3
    ],
    [
        0,
        7.2334,
        28.3167
    ],
    [
        0,
        7.2325,
        28.3333
    ],
    [
        0,
        7.2329,
        28.35
    ],
    [
        0,
        7.2328,
        28.3667
    ],
    [
        0,
        7.2324,
        28.3833
    ],
    [
        0,
        7.2331,
        28.4
    ],
    [
        0,
        7.2325,
        28.4167
    ],
    [
        0,
        7.233,
        28.4333
    ],
    [
        0,
        7.2327,
        28.45
    ],
    [
        0,
        7.2328,
        28.4667
    ],
    [
        0,
        7.2328,
        28.4833
    ],
    [
        0,
        7.233,
        28.5
    ],
    [
        0,
        7.2325,
        28.5167
    ],
    [
        0,
        7.2329,
        28.5333
    ],
    [
        0,
        7.2327,
        28.55
    ],
    [
        0,
        7.2326,
        28.5667
    ],
    [
        0,
        7.2333,
        28.5833
    ],
    [
        0,
        7.2324,
        28.6
    ],
    [
        0,
        7.2326,
        28.6167
    ],
    [
        0,
        7.2327,
        28.6333
    ],
    [
        0,
        7.2326,
        28.65
    ],
    [
        0,
        7.2333,
        28.6667
    ],
    [
        0,
        7.2332,
        28.6833
    ],
    [
        0,
        7.2331,
        28.7
    ],
    [
        0,
        7.2332,
        28.7167
    ],
    [
        0,
        7.2332,
        28.7333
    ],
    [
        0,
        7.2332,
        28.75
    ],
    [
        0,
        7.2334,
        28.7667
    ],
    [
        0,
        7.2337,
        28.7833
    ],
    [
        0,
        7.2329,
        28.8
    ],
    [
        0,
        7.233,
        28.8167
    ],
    [
        0,
        7.2335,
        28.8333
    ],
    [
        0,
        7.2329,
        28.85
    ],
    [
        0,
        7.2323,
        28.8667
    ],
    [
        0,
        7.2331,
        28.8833
    ],
    [
        0,
        7.2324,
        28.9
    ],
    [
        0,
        7.2329,
        28.9167
    ],
    [
        0,
        7.2328,
        28.9333
    ],
    [
        0,
        7.2323,
        28.95
    ],
    [
        0,
        7.2329,
        28.9667
    ],
    [
        0,
        7.2331,
        28.9833
    ],
    [
        0,
        7.233,
        29
    ],
    [
        0,
        7.2332,
        29.0167
    ],
    [
        0,
        7.2327,
        29.0333
    ],
    [
        0,
        7.2329,
        29.05
    ],
    [
        0,
        7.2329,
        29.0667
    ],
    [
        0,
        7.2327,
        29.0833
    ],
    [
        0,
        7.2326,
        29.1
    ],
    [
        0,
        7.2325,
        29.1167
    ],
    [
        0,
        7.2321,
        29.1333
    ],
    [
        0,
        7.2324,
        29.15
    ],
    [
        0,
        7.2327,
        29.1667
    ],
    [
        0,
        7.2323,
        29.1833
    ],
    [
        0,
        7.232,
        29.2
    ],
    [
        0,
        7.2329,
        29.2167
    ],
    [
        0,
        7.2326,
        29.2333
    ],
    [
        0,
        7.2326,
        29.25
    ],
    [
        0,
        7.2323,
        29.2667
    ],
    [
        0,
        7.2328,
        29.2833
    ],
    [
        0,
        7.2333,
        29.3
    ],
    [
        0,
        7.2329,
        29.3167
    ],
    [
        0,
        7.2324,
        29.3333
    ],
    [
        0,
        7.2326,
        29.35
    ],
    [
        0,
        7.2326,
        29.3667
    ],
    [
        0,
        7.233,
        29.3833
    ],
    [
        0,
        7.2323,
        29.4
    ],
    [
        0,
        7.2329,
        29.4167
    ],
    [
        0,
        7.2328,
        29.4333
    ],
    [
        0,
        7.2327,
        29.45
    ],
    [
        0,
        7.2328,
        29.4667
    ],
    [
        0,
        7.2319,
        29.4833
    ],
    [
        0,
        7.2322,
        29.5
    ],
    [
        0,
        7.2329,
        29.5167
    ],
    [
        0,
        7.2327,
        29.5333
    ],
    [
        0,
        7.2324,
        29.55
    ],
    [
        0,
        7.2326,
        29.5667
    ],
    [
        0,
        7.2327,
        29.5833
    ],
    [
        0,
        7.232,
        29.6
    ],
    [
        0,
        7.2324,
        29.6167
    ],
    [
        0,
        7.2326,
        29.6333
    ],
    [
        0,
        7.2322,
        29.65
    ],
    [
        0,
        7.2323,
        29.6667
    ],
    [
        0,
        7.2325,
        29.6833
    ],
    [
        0,
        7.2324,
        29.7
    ],
    [
        0,
        7.2328,
        29.7167
    ],
    [
        0,
        7.2331,
        29.7333
    ],
    [
        0,
        7.2325,
        29.75
    ],
    [
        0,
        7.2322,
        29.7667
    ],
    [
        0,
        7.2324,
        29.7833
    ],
    [
        0,
        7.232,
        29.8
    ],
    [
        0,
        7.2325,
        29.8167
    ],
    [
        0,
        7.2323,
        29.8333
    ],
    [
        0,
        7.2318,
        29.85
    ],
    [
        0,
        7.2318,
        29.8667
    ],
    [
        0,
        7.2321,
        29.8833
    ],
    [
        0,
        7.2324,
        29.9
    ],
    [
        0,
        7.2325,
        29.9167
    ],
    [
        0,
        7.2329,
        29.9333
    ],
    [
        0,
        7.2322,
        29.95
    ],
    [
        0,
        7.2322,
        29.9667
    ],
    [
        0,
        7.2325,
        29.9833
    ],
    [
        0,
        7.2316,
        30
    ],
    [
        0,
        7.232,
        30.0167
    ],
    [
        0,
        7.2321,
        30.0333
    ],
    [
        0,
        7.232,
        30.05
    ],
    [
        0,
        7.2322,
        30.0667
    ],
    [
        0,
        7.2316,
        30.0833
    ],
    [
        0,
        7.2322,
        30.1
    ],
    [
        0,
        7.2318,
        30.1167
    ],
    [
        0,
        7.2321,
        30.1333
    ],
    [
        0,
        7.2329,
        30.15
    ],
    [
        0,
        7.2322,
        30.1667
    ],
    [
        0,
        7.2315,
        30.1833
    ],
    [
        0,
        7.2326,
        30.2
    ],
    [
        0,
        7.2323,
        30.2167
    ],
    [
        0,
        7.2324,
        30.2333
    ],
    [
        0,
        7.2323,
        30.25
    ],
    [
        0,
        7.2321,
        30.2667
    ],
    [
        0,
        7.2322,
        30.2833
    ],
    [
        0,
        7.2324,
        30.3
    ],
    [
        0,
        7.2321,
        30.3167
    ],
    [
        0,
        7.2318,
        30.3333
    ],
    [
        0,
        7.2322,
        30.35
    ],
    [
        0,
        7.2325,
        30.3667
    ],
    [
        0,
        7.232,
        30.3833
    ],
    [
        0,
        7.2324,
        30.4
    ],
    [
        -29.9985,
        7.155,
        30.4167
    ],
    [
        -30.0009,
        7.1187,
        30.4333
    ],
    [
        -30.0021,
        7.1158,
        30.45
    ],
    [
        -30.0018,
        7.1145,
        30.4667
    ],
    [
        -30.0011,
        7.113,
        30.4833
    ],
    [
        -30.0018,
        7.1115,
        30.5
    ],
    [
        -30.0003,
        7.1107,
        30.5167
    ],
    [
        -30.0008,
        7.1091,
        30.5333
    ],
    [
        -30.0019,
        7.1074,
        30.55
    ],
    [
        -30.0027,
        7.1062,
        30.5667
    ],
    [
        -30.0016,
        7.1055,
        30.5833
    ],
    [
        0,
        7.1361,
        30.6
    ],
    [
        0,
        7.2175,
        30.6167
    ],
    [
        0,
        7.2192,
        30.6333
    ],
    [
        0,
        7.2204,
        30.65
    ],
    [
        0,
        7.221,
        30.6667
    ],
    [
        0,
        7.2215,
        30.6833
    ],
    [
        0,
        7.2222,
        30.7
    ],
    [
        0,
        7.2227,
        30.7167
    ],
    [
        0,
        7.2225,
        30.7333
    ],
    [
        0,
        7.2231,
        30.75
    ],
    [
        0,
        7.2234,
        30.7667
    ],
    [
        0,
        7.2236,
        30.7833
    ],
    [
        0,
        7.2234,
        30.8
    ],
    [
        0,
        7.224,
        30.8167
    ],
    [
        0,
        7.2246,
        30.8333
    ],
    [
        0,
        7.2246,
        30.85
    ],
    [
        0,
        7.2239,
        30.8667
    ],
    [
        0,
        7.2244,
        30.8833
    ],
    [
        0,
        7.225,
        30.9
    ],
    [
        0,
        7.2251,
        30.9167
    ],
    [
        0,
        7.225,
        30.9333
    ],
    [
        0,
        7.2245,
        30.95
    ],
    [
        0,
        7.2252,
        30.9667
    ],
    [
        0,
        7.2247,
        30.9833
    ],
    [
        0,
        7.2245,
        31
    ],
    [
        0,
        7.2248,
        31.0167
    ],
    [
        0,
        7.2253,
        31.0333
    ],
    [
        0,
        7.2251,
        31.05
    ],
    [
        0,
        7.2252,
        31.0667
    ],
    [
        0,
        7.2251,
        31.0833
    ],
    [
        0,
        7.2254,
        31.1
    ],
    [
        0,
        7.2255,
        31.1167
    ],
    [
        0,
        7.2252,
        31.1333
    ],
    [
        0,
        7.2259,
        31.15
    ],
    [
        0,
        7.2261,
        31.1667
    ],
    [
        0,
        7.2258,
        31.1833
    ],
    [
        0,
        7.226,
        31.2
    ],
    [
        0,
        7.2258,
        31.2167
    ],
    [
        0,
        7.2262,
        31.2333
    ],
    [
        0,
        7.2263,
        31.25
    ],
    [
        0,
        7.2261,
        31.2667
    ],
    [
        0,
        7.2273,
        31.2833
    ],
    [
        0,
        7.2266,
        31.3
    ],
    [
        0,
        7.2266,
        31.3167
    ],
    [
        0,
        7.2266,
        31.3333
    ],
    [
        0,
        7.2264,
        31.35
    ],
    [
        0,
        7.2267,
        31.3667
    ],
    [
        0,
        7.2269,
        31.3833
    ],
    [
        0,
        7.2268,
        31.4
    ],
    [
        0,
        7.2262,
        31.4167
    ],
    [
        0,
        7.2272,
        31.4333
    ],
    [
        0,
        7.2269,
        31.45
    ],
    [
        0,
        7.2274,
        31.4667
    ],
    [
        0,
        7.2266,
        31.4833
    ],
    [
        0,
        7.2276,
        31.5
    ],
    [
        0,
        7.2275,
        31.5167
    ],
    [
        0,
        7.2271,
        31.5333
    ],
    [
        0,
        7.2279,
        31.55
    ],
    [
        0,
        7.2273,
        31.5667
    ],
    [
        0,
        7.227,
        31.5833
    ],
    [
        0,
        7.2272,
        31.6
    ],
    [
        0,
        7.227,
        31.6167
    ],
    [
        0,
        7.2265,
        31.6333
    ],
    [
        0,
        7.2269,
        31.65
    ],
    [
        0,
        7.2268,
        31.6667
    ],
    [
        0,
        7.2278,
        31.6833
    ],
    [
        0,
        7.2272,
        31.7
    ],
    [
        0,
        7.2272,
        31.7167
    ],
    [
        0,
        7.2274,
        31.7333
    ],
    [
        0,
        7.2274,
        31.75
    ],
    [
        0,
        7.2269,
        31.7667
    ],
    [
        0,
        7.2274,
        31.7833
    ],
    [
        0,
        7.2269,
        31.8
    ],
    [
        0,
        7.2272,
        31.8167
    ],
    [
        0,
        7.2276,
        31.8333
    ],
    [
        0,
        7.2272,
        31.85
    ],
    [
        0,
        7.2271,
        31.8667
    ],
    [
        0,
        7.2277,
        31.8833
    ],
    [
        0,
        7.2276,
        31.9
    ],
    [
        0,
        7.2274,
        31.9167
    ],
    [
        0,
        7.2273,
        31.9333
    ],
    [
        0,
        7.2276,
        31.95
    ],
    [
        0,
        7.2275,
        31.9667
    ],
    [
        0,
        7.2271,
        31.9833
    ],
    [
        0,
        7.2269,
        32
    ],
    [
        0,
        7.2275,
        32.0167
    ],
    [
        0,
        7.228,
        32.0333
    ],
    [
        0,
        7.2276,
        32.05
    ],
    [
        0,
        7.228,
        32.0667
    ],
    [
        0,
        7.2274,
        32.0833
    ],
    [
        0,
        7.2279,
        32.1
    ],
    [
        0,
        7.2275,
        32.1167
    ],
    [
        0,
        7.2274,
        32.1333
    ],
    [
        0,
        7.2275,
        32.15
    ],
    [
        0,
        7.2274,
        32.1667
    ],
    [
        0,
        7.2282,
        32.1833
    ],
    [
        0,
        7.2284,
        32.2
    ],
    [
        0,
        7.2277,
        32.2167
    ],
    [
        0,
        7.228,
        32.2333
    ],
    [
        0,
        7.2272,
        32.25
    ],
    [
        0,
        7.2271,
        32.2667
    ],
    [
        0,
        7.2273,
        32.2833
    ],
    [
        0,
        7.228,
        32.3
    ],
    [
        0,
        7.2279,
        32.3167
    ],
    [
        0,
        7.228,
        32.3333
    ],
    [
        0,
        7.228,
        32.35
    ],
    [
        0,
        7.2274,
        32.3667
    ],
    [
        0,
        7.2278,
        32.3833
    ],
    [
        0,
        7.2272,
        32.4
    ],
    [
        0,
        7.2276,
        32.4167
    ],
    [
        0,
        7.228,
        32.4333
    ],
    [
        0,
        7.2277,
        32.45
    ],
    [
        0,
        7.2276,
        32.4667
    ],
    [
        0,
        7.228,
        32.4833
    ],
    [
        0,
        7.228,
        32.5
    ],
    [
        0,
        7.2279,
        32.5167
    ],
    [
        0,
        7.2277,
        32.5333
    ],
    [
        0,
        7.2277,
        32.55
    ],
    [
        0,
        7.2281,
        32.5667
    ],
    [
        0,
        7.2276,
        32.5833
    ],
    [
        0,
        7.2277,
        32.6
    ],
    [
        0,
        7.228,
        32.6167
    ],
    [
        0,
        7.2282,
        32.6333
    ],
    [
        0,
        7.2282,
        32.65
    ],
    [
        0,
        7.2278,
        32.6667
    ],
    [
        0,
        7.2283,
        32.6833
    ],
    [
        0,
        7.2278,
        32.7
    ],
    [
        0,
        7.2285,
        32.7167
    ],
    [
        0,
        7.2287,
        32.7333
    ],
    [
        0,
        7.2285,
        32.75
    ],
    [
        0,
        7.2281,
        32.7667
    ],
    [
        0,
        7.2277,
        32.7833
    ],
    [
        0,
        7.2286,
        32.8
    ],
    [
        0,
        7.228,
        32.8167
    ],
    [
        0,
        7.2275,
        32.8333
    ],
    [
        0,
        7.2275,
        32.85
    ],
    [
        0,
        7.2281,
        32.8667
    ],
    [
        0,
        7.2282,
        32.8833
    ],
    [
        0,
        7.228,
        32.9
    ],
    [
        0,
        7.2284,
        32.9167
    ],
    [
        0,
        7.2282,
        32.9333
    ],
    [
        0,
        7.228,
        32.95
    ],
    [
        0,
        7.228,
        32.9667
    ],
    [
        0,
        7.2282,
        32.9833
    ],
    [
        0,
        7.2283,
        33
    ],
    [
        0,
        7.2285,
        33.0167
    ],
    [
        0,
        7.2284,
        33.0333
    ],
    [
        0,
        7.2281,
        33.05
    ],
    [
        0,
        7.2278,
        33.0667
    ],
    [
        0,
        7.2278,
        33.0833
    ],
    [
        0,
        7.2284,
        33.1
    ],
    [
        0,
        7.2282,
        33.1167
    ],
    [
        0,
        7.2282,
        33.1333
    ],
    [
        0,
        7.2279,
        33.15
    ],
    [
        0,
        7.2282,
        33.1667
    ],
    [
        0,
        7.2281,
        33.1833
    ],
    [
        0,
        7.228,
        33.2
    ],
    [
        0,
        7.2279,
        33.2167
    ],
    [
        0,
        7.2282,
        33.2333
    ],
    [
        0,
        7.2282,
        33.25
    ],
    [
        0,
        7.2284,
        33.2667
    ],
    [
        0,
        7.2285,
        33.2833
    ],
    [
        0,
        7.2281,
        33.3
    ],
    [
        0,
        7.2279,
        33.3167
    ],
    [
        0,
        7.2283,
        33.3333
    ],
    [
        0,
        7.228,
        33.35
    ],
    [
        0,
        7.2286,
        33.3667
    ],
    [
        0,
        7.2282,
        33.3833
    ],
    [
        0,
        7.2284,
        33.4
    ],
    [
        0,
        7.2288,
        33.4167
    ],
    [
        0,
        7.2287,
        33.4333
    ],
    [
        0,
        7.2288,
        33.45
    ],
    [
        0,
        7.2278,
        33.4667
    ],
    [
        0,
        7.2281,
        33.4833
    ],
    [
        0,
        7.2286,
        33.5
    ],
    [
        0,
        7.2285,
        33.5167
    ],
    [
        0,
        7.2282,
        33.5333
    ],
    [
        0,
        7.2282,
        33.55
    ],
    [
        0,
        7.228,
        33.5667
    ],
    [
        0,
        7.2281,
        33.5833
    ],
    [
        0,
        7.2283,
        33.6
    ],
    [
        0,
        7.2281,
        33.6167
    ],
    [
        0,
        7.2284,
        33.6333
    ],
    [
        0,
        7.2289,
        33.65
    ],
    [
        0,
        7.2287,
        33.6667
    ],
    [
        0,
        7.2287,
        33.6833
    ],
    [
        0,
        7.2287,
        33.7
    ],
    [
        0,
        7.2284,
        33.7167
    ],
    [
        0,
        7.2282,
        33.7333
    ],
    [
        0,
        7.2278,
        33.75
    ],
    [
        0,
        7.2278,
        33.7667
    ],
    [
        0,
        7.2288,
        33.7833
    ],
    [
        0,
        7.2287,
        33.8
    ],
    [
        0,
        7.2282,
        33.8167
    ],
    [
        0,
        7.2291,
        33.8333
    ],
    [
        0,
        7.2283,
        33.85
    ],
    [
        0,
        7.2281,
        33.8667
    ],
    [
        0,
        7.2282,
        33.8833
    ],
    [
        0,
        7.2285,
        33.9
    ],
    [
        0,
        7.2281,
        33.9167
    ],
    [
        0,
        7.2278,
        33.9333
    ],
    [
        0,
        7.2291,
        33.95
    ],
    [
        0,
        7.2287,
        33.9667
    ],
    [
        0,
        7.2286,
        33.9833
    ],
    [
        0,
        7.2291,
        34
    ],
    [
        0,
        7.2286,
        34.0167
    ],
    [
        0,
        7.2292,
        34.0333
    ],
    [
        0,
        7.2287,
        34.05
    ],
    [
        0,
        7.2284,
        34.0667
    ],
    [
        0,
        7.2283,
        34.0833
    ],
    [
        0,
        7.2285,
        34.1
    ],
    [
        0,
        7.2289,
        34.1167
    ],
    [
        0,
        7.2286,
        34.1333
    ],
    [
        0,
        7.2288,
        34.15
    ],
    [
        0,
        7.2284,
        34.1667
    ],
    [
        0,
        7.2292,
        34.1833
    ],
    [
        0,
        7.2283,
        34.2
    ],
    [
        0,
        7.2281,
        34.2167
    ],
    [
        0,
        7.2284,
        34.2333
    ],
    [
        0,
        7.2286,
        34.25
    ],
    [
        0,
        7.2283,
        34.2667
    ],
    [
        0,
        7.2284,
        34.2833
    ],
    [
        0,
        7.2284,
        34.3
    ],
    [
        0,
        7.2287,
        34.3167
    ],
    [
        0,
        7.2287,
        34.3333
    ],
    [
        0,
        7.2285,
        34.35
    ],
    [
        0,
        7.2285,
        34.3667
    ],
    [
        0,
        7.2291,
        34.3833
    ],
    [
        0,
        7.2286,
        34.4
    ],
    [
        0,
        7.2287,
        34.4167
    ],
    [
        0,
        7.229,
        34.4333
    ],
    [
        0,
        7.2285,
        34.45
    ],
    [
        0,
        7.2289,
        34.4667
    ],
    [
        0,
        7.2288,
        34.4833
    ],
    [
        0,
        7.2292,
        34.5
    ],
    [
        0,
        7.2285,
        34.5167
    ],
    [
        0,
        7.2284,
        34.5333
    ],
    [
        0,
        7.2294,
        34.55
    ],
    [
        0,
        7.2286,
        34.5667
    ],
    [
        0,
        7.2288,
        34.5833
    ],
    [
        0,
        7.2287,
        34.6
    ],
    [
        0,
        7.2287,
        34.6167
    ],
    [
        0,
        7.2284,
        34.6333
    ],
    [
        0,
        7.2289,
        34.65
    ],
    [
        0,
        7.2291,
        34.6667
    ],
    [
        0,
        7.2286,
        34.6833
    ],
    [
        0,
        7.2294,
        34.7
    ],
    [
        0,
        7.2291,
        34.7167
    ],
    [
        0,
        7.2289,
        34.7333
    ],
    [
        0,
        7.2287,
        34.75
    ],
    [
        0,
        7.2289,
        34.7667
    ],
    [
        0,
        7.229,
        34.7833
    ],
    [
        0,
        7.2288,
        34.8
    ],
    [
        0,
        7.2287,
        34.8167
    ],
    [
        0,
        7.2284,
        34.8333
    ],
    [
        0,
        7.2286,
        34.85
    ],
    [
        0,
        7.2286,
        34.8667
    ],
    [
        0,
        7.2289,
        34.8833
    ],
    [
        0,
        7.2284,
        34.9
    ],
    [
        0,
        7.2294,
        34.9167
    ],
    [
        0,
        7.2293,
        34.9333
    ],
    [
        0,
        7.2294,
        34.95
    ],
    [
        0,
        7.2292,
        34.9667
    ],
    [
        0,
        7.2291,
        34.9833
    ],
    [
        0,
        7.2293,
        35
    ],
    [
        0,
        7.2291,
        35.0167
    ],
    [
        0,
        7.2293,
        35.0333
    ],
    [
        0,
        7.229,
        35.05
    ],
    [
        0,
        7.2286,
        35.0667
    ],
    [
        0,
        7.2287,
        35.0833
    ],
    [
        0,
        7.2284,
        35.1
    ],
    [
        0,
        7.2287,
        35.1167
    ],
    [
        0,
        7.2291,
        35.1333
    ],
    [
        0,
        7.2285,
        35.15
    ],
    [
        0,
        7.2289,
        35.1667
    ],
    [
        0,
        7.2291,
        35.1833
    ],
    [
        0,
        7.2287,
        35.2
    ],
    [
        0,
        7.229,
        35.2167
    ],
    [
        0,
        7.2287,
        35.2333
    ],
    [
        0,
        7.2292,
        35.25
    ],
    [
        0,
        7.2293,
        35.2667
    ],
    [
        0,
        7.2293,
        35.2833
    ],
    [
        0,
        7.2292,
        35.3
    ],
    [
        0,
        7.2282,
        35.3167
    ],
    [
        0,
        7.2293,
        35.3333
    ],
    [
        0,
        7.2286,
        35.35
    ],
    [
        0,
        7.2287,
        35.3667
    ],
    [
        0,
        7.2291,
        35.3833
    ],
    [
        0,
        7.228,
        35.4
    ],
    [
        0,
        7.2285,
        35.4167
    ],
    [
        0,
        7.2285,
        35.4333
    ],
    [
        0,
        7.2287,
        35.45
    ],
    [
        0,
        7.2289,
        35.4667
    ],
    [
        0,
        7.2289,
        35.4833
    ],
    [
        0,
        7.2293,
        35.5
    ],
    [
        0,
        7.2292,
        35.5167
    ],
    [
        0,
        7.2285,
        35.5333
    ],
    [
        0,
        7.2288,
        35.55
    ],
    [
        0,
        7.229,
        35.5667
    ],
    [
        0,
        7.2291,
        35.5833
    ],
    [
        0,
        7.2287,
        35.6
    ],
    [
        0,
        7.2288,
        35.6167
    ],
    [
        0,
        7.2287,
        35.6333
    ],
    [
        0,
        7.229,
        35.65
    ],
    [
        0,
        7.2295,
        35.6667
    ],
    [
        0,
        7.2291,
        35.6833
    ],
    [
        0,
        7.2291,
        35.7
    ],
    [
        0,
        7.2284,
        35.7167
    ],
    [
        0,
        7.2291,
        35.7333
    ],
    [
        0,
        7.2287,
        35.75
    ],
    [
        0,
        7.2294,
        35.7667
    ],
    [
        0,
        7.229,
        35.7833
    ],
    [
        0,
        7.2289,
        35.8
    ],
    [
        0,
        7.2287,
        35.8167
    ],
    [
        0,
        7.2288,
        35.8333
    ],
    [
        0,
        7.2288,
        35.85
    ],
    [
        0,
        7.2291,
        35.8667
    ],
    [
        0,
        7.2285,
        35.8833
    ],
    [
        0,
        7.2284,
        35.9
    ],
    [
        0,
        7.2284,
        35.9167
    ],
    [
        0,
        7.2284,
        35.9333
    ],
    [
        0,
        7.2285,
        35.95
    ],
    [
        0,
        7.2288,
        35.9667
    ],
    [
        0,
        7.2289,
        35.9833
    ],
    [
        0,
        7.229,
        36
    ],
    [
        0,
        7.2287,
        36.0167
    ],
    [
        0,
        7.2284,
        36.0333
    ],
    [
        0,
        7.2285,
        36.05
    ],
    [
        0,
        7.2288,
        36.0667
    ],
    [
        0,
        7.2285,
        36.0833
    ],
    [
        0,
        7.2288,
        36.1
    ],
    [
        0,
        7.2283,
        36.1167
    ],
    [
        0,
        7.2284,
        36.1333
    ],
    [
        0,
        7.2289,
        36.15
    ],
    [
        0,
        7.2282,
        36.1667
    ],
    [
        0,
        7.2287,
        36.1833
    ],
    [
        0,
        7.2291,
        36.2
    ],
    [
        0,
        7.2291,
        36.2167
    ],
    [
        0,
        7.2294,
        36.2333
    ],
    [
        0,
        7.2288,
        36.25
    ],
    [
        0,
        7.2293,
        36.2667
    ],
    [
        0,
        7.2289,
        36.2833
    ],
    [
        0,
        7.2285,
        36.3
    ],
    [
        0,
        7.2287,
        36.3167
    ],
    [
        0,
        7.2288,
        36.3333
    ],
    [
        0,
        7.2286,
        36.35
    ],
    [
        0,
        7.2283,
        36.3667
    ],
    [
        0,
        7.228,
        36.3833
    ],
    [
        0,
        7.2279,
        36.4
    ],
    [
        0,
        7.2285,
        36.4167
    ],
    [
        0,
        7.2286,
        36.4333
    ],
    [
        0,
        7.2282,
        36.45
    ],
    [
        0,
        7.2289,
        36.4667
    ],
    [
        0,
        7.2291,
        36.4833
    ],
    [
        0,
        7.2284,
        36.5
    ],
    [
        0,
        7.2289,
        36.5167
    ],
    [
        0,
        7.2283,
        36.5333
    ],
    [
        0,
        7.2285,
        36.55
    ],
    [
        0,
        7.2284,
        36.5667
    ],
    [
        0,
        7.2283,
        36.5833
    ],
    [
        0,
        7.2284,
        36.6
    ],
    [
        0,
        7.2289,
        36.6167
    ],
    [
        0,
        7.2286,
        36.6333
    ],
    [
        0,
        7.2284,
        36.65
    ],
    [
        0,
        7.2282,
        36.6667
    ],
    [
        0,
        7.2286,
        36.6833
    ],
    [
        0,
        7.2288,
        36.7
    ],
    [
        0,
        7.228,
        36.7167
    ],
    [
        0,
        7.2284,
        36.7333
    ],
    [
        0,
        7.2282,
        36.75
    ],
    [
        0,
        7.2282,
        36.7667
    ],
    [
        0,
        7.2286,
        36.7833
    ],
    [
        0,
        7.2285,
        36.8
    ],
    [
        0,
        7.2291,
        36.8167
    ],
    [
        0,
        7.2286,
        36.8333
    ],
    [
        0,
        7.2289,
        36.85
    ],
    [
        0,
        7.2289,
        36.8667
    ],
    [
        0,
        7.2289,
        36.8833
    ],
    [
        0,
        7.229,
        36.9
    ],
    [
        0,
        7.229,
        36.9167
    ],
    [
        0,
        7.229,
        36.9333
    ],
    [
        0,
        7.2287,
        36.95
    ],
    [
        0,
        7.2295,
        36.9667
    ],
    [
        0,
        7.229,
        36.9833
    ],
    [
        0,
        7.2284,
        37
    ],
    [
        0,
        7.2287,
        37.0167
    ],
    [
        0,
        7.229,
        37.0333
    ],
    [
        0,
        7.2291,
        37.05
    ],
    [
        0,
        7.2292,
        37.0667
    ],
    [
        0,
        7.2289,
        37.0833
    ],
    [
        0,
        7.2286,
        37.1
    ],
    [
        0,
        7.2288,
        37.1167
    ],
    [
        0,
        7.2282,
        37.1333
    ],
    [
        0,
        7.228,
        37.15
    ],
    [
        0,
        7.2284,
        37.1667
    ],
    [
        0,
        7.2291,
        37.1833
    ],
    [
        0,
        7.2285,
        37.2
    ],
    [
        0,
        7.229,
        37.2167
    ],
    [
        0,
        7.2285,
        37.2333
    ],
    [
        0,
        7.2286,
        37.25
    ],
    [
        0,
        7.2286,
        37.2667
    ],
    [
        0,
        7.2289,
        37.2833
    ],
    [
        0,
        7.2288,
        37.3
    ],
    [
        0,
        7.229,
        37.3167
    ],
    [
        0,
        7.2289,
        37.3333
    ],
    [
        0,
        7.2293,
        37.35
    ],
    [
        0,
        7.2286,
        37.3667
    ],
    [
        0,
        7.2293,
        37.3833
    ],
    [
        0,
        7.2291,
        37.4
    ],
    [
        0,
        7.2294,
        37.4167
    ],
    [
        0,
        7.2289,
        37.4333
    ],
    [
        0,
        7.2283,
        37.45
    ],
    [
        0,
        7.2288,
        37.4667
    ],
    [
        0,
        7.2287,
        37.4833
    ],
    [
        0,
        7.2292,
        37.5
    ],
    [
        0,
        7.2293,
        37.5167
    ],
    [
        0,
        7.2287,
        37.5333
    ],
    [
        0,
        7.229,
        37.55
    ],
    [
        0,
        7.2289,
        37.5667
    ],
    [
        0,
        7.2288,
        37.5833
    ],
    [
        0,
        7.2282,
        37.6
    ],
    [
        0,
        7.2285,
        37.6167
    ],
    [
        0,
        7.2284,
        37.6333
    ],
    [
        0,
        7.2288,
        37.65
    ],
    [
        0,
        7.2289,
        37.6667
    ],
    [
        0,
        7.2282,
        37.6833
    ],
    [
        0,
        7.2284,
        37.7
    ],
    [
        0,
        7.228,
        37.7167
    ],
    [
        0,
        7.228,
        37.7333
    ],
    [
        0,
        7.2281,
        37.75
    ],
    [
        0,
        7.2281,
        37.7667
    ],
    [
        0,
        7.2284,
        37.7833
    ],
    [
        0,
        7.2283,
        37.8
    ],
    [
        0,
        7.2287,
        37.8167
    ],
    [
        0,
        7.228,
        37.8333
    ],
    [
        0,
        7.2284,
        37.85
    ],
    [
        0,
        7.2287,
        37.8667
    ],
    [
        0,
        7.2286,
        37.8833
    ],
    [
        0,
        7.2281,
        37.9
    ],
    [
        0,
        7.2286,
        37.9167
    ],
    [
        0,
        7.2284,
        37.9333
    ],
    [
        0,
        7.2284,
        37.95
    ],
    [
        0,
        7.2287,
        37.9667
    ],
    [
        0,
        7.2283,
        37.9833
    ],
    [
        0,
        7.2288,
        38
    ],
    [
        0,
        7.2281,
        38.0167
    ],
    [
        0,
        7.2288,
        38.0333
    ],
    [
        0,
        7.2292,
        38.05
    ],
    [
        0,
        7.2281,
        38.0667
    ],
    [
        0,
        7.2282,
        38.0833
    ],
    [
        0,
        7.2284,
        38.1
    ],
    [
        0,
        7.2292,
        38.1167
    ],
    [
        0,
        7.2288,
        38.1333
    ],
    [
        0,
        7.2289,
        38.15
    ],
    [
        0,
        7.2284,
        38.1667
    ],
    [
        0,
        7.2286,
        38.1833
    ],
    [
        0,
        7.2283,
        38.2
    ],
    [
        0,
        7.2287,
        38.2167
    ],
    [
        0,
        7.2287,
        38.2333
    ],
    [
        0,
        7.2285,
        38.25
    ],
    [
        0,
        7.2286,
        38.2667
    ],
    [
        0,
        7.2281,
        38.2833
    ],
    [
        0,
        7.2284,
        38.3
    ],
    [
        0,
        7.2285,
        38.3167
    ],
    [
        0,
        7.2286,
        38.3333
    ],
    [
        0,
        7.2289,
        38.35
    ],
    [
        0,
        7.229,
        38.3667
    ],
    [
        0,
        7.229,
        38.3833
    ],
    [
        0,
        7.2285,
        38.4
    ],
    [
        0,
        7.2289,
        38.4167
    ],
    [
        0,
        7.2289,
        38.4333
    ],
    [
        0,
        7.2285,
        38.45
    ],
    [
        0,
        7.2285,
        38.4667
    ],
    [
        0,
        7.2287,
        38.4833
    ],
    [
        0,
        7.2282,
        38.5
    ],
    [
        0,
        7.2284,
        38.5167
    ],
    [
        0,
        7.228,
        38.5333
    ],
    [
        0,
        7.2284,
        38.55
    ],
    [
        0,
        7.2286,
        38.5667
    ],
    [
        0,
        7.2293,
        38.5833
    ],
    [
        0,
        7.2286,
        38.6
    ],
    [
        0,
        7.2291,
        38.6167
    ],
    [
        0,
        7.2289,
        38.6333
    ],
    [
        0,
        7.229,
        38.65
    ],
    [
        0,
        7.229,
        38.6667
    ],
    [
        0,
        7.229,
        38.6833
    ],
    [
        0,
        7.2287,
        38.7
    ],
    [
        0,
        7.2287,
        38.7167
    ],
    [
        0,
        7.229,
        38.7333
    ],
    [
        0,
        7.229,
        38.75
    ],
    [
        0,
        7.2293,
        38.7667
    ],
    [
        0,
        7.229,
        38.7833
    ],
    [
        0,
        7.229,
        38.8
    ],
    [
        0,
        7.2286,
        38.8167
    ],
    [
        0,
        7.2292,
        38.8333
    ],
    [
        0,
        7.2282,
        38.85
    ],
    [
        0,
        7.2286,
        38.8667
    ],
    [
        0,
        7.2289,
        38.8833
    ],
    [
        0,
        7.2284,
        38.9
    ],
    [
        0,
        7.2284,
        38.9167
    ],
    [
        0,
        7.2289,
        38.9333
    ],
    [
        0,
        7.2284,
        38.95
    ],
    [
        0,
        7.2284,
        38.9667
    ],
    [
        0,
        7.2292,
        38.9833
    ],
    [
        0,
        7.2286,
        39
    ],
    [
        0,
        7.2282,
        39.0167
    ],
    [
        0,
        7.2284,
        39.0333
    ],
    [
        0,
        7.2282,
        39.05
    ],
    [
        0,
        7.2292,
        39.0667
    ],
    [
        0,
        7.2279,
        39.0833
    ],
    [
        0,
        7.2283,
        39.1
    ],
    [
        0,
        7.2282,
        39.1167
    ],
    [
        0,
        7.2281,
        39.1333
    ],
    [
        0,
        7.2288,
        39.15
    ],
    [
        0,
        7.228,
        39.1667
    ],
    [
        0,
        7.2283,
        39.1833
    ],
    [
        0,
        7.2283,
        39.2
    ],
    [
        0,
        7.2285,
        39.2167
    ],
    [
        0,
        7.2287,
        39.2333
    ],
    [
        0,
        7.2284,
        39.25
    ],
    [
        0,
        7.2287,
        39.2667
    ],
    [
        0,
        7.2278,
        39.2833
    ],
    [
        0,
        7.228,
        39.3
    ],
    [
        0,
        7.2285,
        39.3167
    ],
    [
        0,
        7.2284,
        39.3333
    ],
    [
        0,
        7.2291,
        39.35
    ],
    [
        0,
        7.2282,
        39.3667
    ],
    [
        0,
        7.2285,
        39.3833
    ],
    [
        0,
        7.2284,
        39.4
    ],
    [
        0,
        7.2284,
        39.4167
    ],
    [
        0,
        7.2277,
        39.4333
    ],
    [
        0,
        7.2282,
        39.45
    ],
    [
        0,
        7.2284,
        39.4667
    ],
    [
        0,
        7.2282,
        39.4833
    ],
    [
        0,
        7.2282,
        39.5
    ],
    [
        0,
        7.2282,
        39.5167
    ],
    [
        0,
        7.2285,
        39.5333
    ],
    [
        0,
        7.2278,
        39.55
    ],
    [
        0,
        7.2283,
        39.5667
    ],
    [
        0,
        7.2283,
        39.5833
    ],
    [
        0,
        7.2284,
        39.6
    ],
    [
        0,
        7.2286,
        39.6167
    ],
    [
        0,
        7.2287,
        39.6333
    ],
    [
        0,
        7.2281,
        39.65
    ],
    [
        0,
        7.2284,
        39.6667
    ],
    [
        0,
        7.2283,
        39.6833
    ],
    [
        0,
        7.2282,
        39.7
    ],
    [
        0,
        7.2288,
        39.7167
    ],
    [
        0,
        7.228,
        39.7333
    ],
    [
        0,
        7.2283,
        39.75
    ],
    [
        0,
        7.2286,
        39.7667
    ],
    [
        0,
        7.2281,
        39.7833
    ],
    [
        0,
        7.2283,
        39.8
    ],
    [
        0,
        7.2288,
        39.8167
    ],
    [
        0,
        7.2281,
        39.8333
    ],
    [
        0,
        7.2285,
        39.85
    ],
    [
        0,
        7.2284,
        39.8667
    ],
    [
        0,
        7.228,
        39.8833
    ],
    [
        0,
        7.2286,
        39.9
    ],
    [
        0,
        7.229,
        39.9167
    ],
    [
        0,
        7.2287,
        39.9333
    ],
    [
        0,
        7.2285,
        39.95
    ],
    [
        0,
        7.2285,
        39.9667
    ],
    [
        0,
        7.2277,
        39.9833
    ],
    [
        0,
        7.2283,
        40
    ],
    [
        0,
        7.2282,
        40.0167
    ],
    [
        0,
        7.2283,
        40.0333
    ],
    [
        0,
        7.2282,
        40.05
    ],
    [
        0,
        7.2286,
        40.0667
    ],
    [
        0,
        7.2286,
        40.0833
    ],
    [
        0,
        7.2289,
        40.1
    ],
    [
        0,
        7.2283,
        40.1167
    ],
    [
        0,
        7.2288,
        40.1333
    ],
    [
        0,
        7.2289,
        40.15
    ],
    [
        0,
        7.2282,
        40.1667
    ],
    [
        0,
        7.229,
        40.1833
    ],
    [
        0,
        7.2284,
        40.2
    ],
    [
        0,
        7.2283,
        40.2167
    ],
    [
        0,
        7.2285,
        40.2333
    ],
    [
        0,
        7.2281,
        40.25
    ],
    [
        0,
        7.2282,
        40.2667
    ],
    [
        0,
        7.2284,
        40.2833
    ],
    [
        0,
        7.2288,
        40.3
    ],
    [
        0,
        7.2291,
        40.3167
    ],
    [
        0,
        7.2291,
        40.3333
    ],
    [
        0,
        7.2283,
        40.35
    ],
    [
        0,
        7.2288,
        40.3667
    ],
    [
        0,
        7.2292,
        40.3833
    ],
    [
        0,
        7.2289,
        40.4
    ],
    [
        0,
        7.2287,
        40.4167
    ],
    [
        0,
        7.2291,
        40.4333
    ],
    [
        0,
        7.2292,
        40.45
    ],
    [
        0,
        7.2292,
        40.4667
    ],
    [
        0,
        7.2291,
        40.4833
    ],
    [
        0,
        7.2299,
        40.5
    ],
    [
        0,
        7.2289,
        40.5167
    ],
    [
        0,
        7.2287,
        40.5333
    ],
    [
        0,
        7.2288,
        40.55
    ],
    [
        0,
        7.2293,
        40.5667
    ],
    [
        0,
        7.2283,
        40.5833
    ],
    [
        0,
        7.2286,
        40.6
    ],
    [
        -14.9923,
        7.2052,
        40.6167
    ],
    [
        -15.0001,
        7.1722,
        40.6333
    ],
    [
        -15.0013,
        7.1703,
        40.65
    ],
    [
        -15.0006,
        7.17,
        40.6667
    ],
    [
        -15.0017,
        7.1687,
        40.6833
    ],
    [
        -15.0039,
        7.1685,
        40.7
    ],
    [
        -14.9985,
        7.1667,
        40.7167
    ],
    [
        -14.9994,
        7.1665,
        40.7333
    ],
    [
        -15.0035,
        7.1663,
        40.75
    ],
    [
        -15.0005,
        7.1649,
        40.7667
    ],
    [
        -15.0019,
        7.1647,
        40.7833
    ],
    [
        0,
        7.1863,
        40.8
    ],
    [
        0,
        7.2208,
        40.8167
    ],
    [
        0,
        7.2219,
        40.8333
    ],
    [
        0,
        7.2221,
        40.85
    ],
    [
        0,
        7.2229,
        40.8667
    ],
    [
        0,
        7.223,
        40.8833
    ],
    [
        0,
        7.2235,
        40.9
    ],
    [
        0,
        7.2232,
        40.9167
    ],
    [
        0,
        7.2234,
        40.9333
    ],
    [
        0,
        7.2232,
        40.95
    ],
    [
        0,
        7.224,
        40.9667
    ],
    [
        0,
        7.2242,
        40.9833
    ],
    [
        0,
        7.2239,
        41
    ],
    [
        0,
        7.2239,
        41.0167
    ],
    [
        0,
        7.2243,
        41.0333
    ],
    [
        0,
        7.2245,
        41.05
    ],
    [
        0,
        7.2241,
        41.0667
    ],
    [
        0,
        7.2243,
        41.0833
    ],
    [
        0,
        7.2242,
        41.1
    ],
    [
        0,
        7.2245,
        41.1167
    ],
    [
        0,
        7.2251,
        41.1333
    ],
    [
        0,
        7.2245,
        41.15
    ],
    [
        0,
        7.224,
        41.1667
    ],
    [
        0,
        7.2244,
        41.1833
    ],
    [
        0,
        7.2241,
        41.2
    ],
    [
        0,
        7.2249,
        41.2167
    ],
    [
        0,
        7.225,
        41.2333
    ],
    [
        0,
        7.2249,
        41.25
    ],
    [
        0,
        7.2254,
        41.2667
    ],
    [
        0,
        7.2249,
        41.2833
    ],
    [
        0,
        7.2247,
        41.3
    ],
    [
        0,
        7.225,
        41.3167
    ],
    [
        0,
        7.2252,
        41.3333
    ],
    [
        0,
        7.2253,
        41.35
    ],
    [
        0,
        7.2257,
        41.3667
    ],
    [
        0,
        7.2264,
        41.3833
    ],
    [
        0,
        7.2259,
        41.4
    ],
    [
        0,
        7.2252,
        41.4167
    ],
    [
        0,
        7.2254,
        41.4333
    ],
    [
        0,
        7.2253,
        41.45
    ],
    [
        0,
        7.2254,
        41.4667
    ],
    [
        0,
        7.2255,
        41.4833
    ],
    [
        0,
        7.2263,
        41.5
    ],
    [
        0,
        7.2256,
        41.5167
    ],
    [
        0,
        7.2259,
        41.5333
    ],
    [
        0,
        7.2259,
        41.55
    ],
    [
        0,
        7.2257,
        41.5667
    ],
    [
        0,
        7.2258,
        41.5833
    ],
    [
        0,
        7.2259,
        41.6
    ],
    [
        0,
        7.2258,
        41.6167
    ],
    [
        0,
        7.2256,
        41.6333
    ],
    [
        0,
        7.2257,
        41.65
    ],
    [
        0,
        7.226,
        41.6667
    ],
    [
        0,
        7.2258,
        41.6833
    ],
    [
        0,
        7.226,
        41.7
    ],
    [
        0,
        7.2268,
        41.7167
    ],
    [
        0,
        7.2263,
        41.7333
    ],
    [
        0,
        7.226,
        41.75
    ],
    [
        0,
        7.2266,
        41.7667
    ],
    [
        0,
        7.2259,
        41.7833
    ],
    [
        0,
        7.2262,
        41.8
    ],
    [
        0,
        7.2258,
        41.8167
    ],
    [
        0,
        7.2259,
        41.8333
    ],
    [
        0,
        7.2261,
        41.85
    ],
    [
        0,
        7.2263,
        41.8667
    ],
    [
        0,
        7.2261,
        41.8833
    ],
    [
        0,
        7.2263,
        41.9
    ],
    [
        0,
        7.2266,
        41.9167
    ],
    [
        0,
        7.2266,
        41.9333
    ],
    [
        0,
        7.2263,
        41.95
    ],
    [
        0,
        7.2269,
        41.9667
    ],
    [
        0,
        7.226,
        41.9833
    ],
    [
        0,
        7.2262,
        42
    ],
    [
        0,
        7.2261,
        42.0167
    ],
    [
        0,
        7.2266,
        42.0333
    ],
    [
        0,
        7.2263,
        42.05
    ],
    [
        0,
        7.2261,
        42.0667
    ],
    [
        0,
        7.2262,
        42.0833
    ],
    [
        0,
        7.2263,
        42.1
    ],
    [
        0,
        7.2261,
        42.1167
    ],
    [
        0,
        7.2266,
        42.1333
    ],
    [
        0,
        7.2267,
        42.15
    ],
    [
        0,
        7.2265,
        42.1667
    ],
    [
        0,
        7.2269,
        42.1833
    ],
    [
        0,
        7.2261,
        42.2
    ],
    [
        0,
        7.2269,
        42.2167
    ],
    [
        0,
        7.2271,
        42.2333
    ],
    [
        0,
        7.2267,
        42.25
    ],
    [
        0,
        7.2264,
        42.2667
    ],
    [
        0,
        7.2265,
        42.2833
    ],
    [
        0,
        7.2265,
        42.3
    ],
    [
        0,
        7.2268,
        42.3167
    ],
    [
        0,
        7.227,
        42.3333
    ],
    [
        0,
        7.2272,
        42.35
    ],
    [
        0,
        7.2266,
        42.3667
    ],
    [
        0,
        7.2269,
        42.3833
    ],
    [
        0,
        7.227,
        42.4
    ],
    [
        0,
        7.2263,
        42.4167
    ],
    [
        0,
        7.2268,
        42.4333
    ],
    [
        0,
        7.2273,
        42.45
    ],
    [
        0,
        7.227,
        42.4667
    ],
    [
        0,
        7.2263,
        42.4833
    ],
    [
        0,
        7.2263,
        42.5
    ],
    [
        0,
        7.2266,
        42.5167
    ],
    [
        0,
        7.2269,
        42.5333
    ],
    [
        0,
        7.2276,
        42.55
    ],
    [
        0,
        7.2269,
        42.5667
    ],
    [
        0,
        7.2265,
        42.5833
    ],
    [
        0,
        7.2271,
        42.6
    ],
    [
        0,
        7.2272,
        42.6167
    ],
    [
        0,
        7.2264,
        42.6333
    ],
    [
        0,
        7.2268,
        42.65
    ],
    [
        0,
        7.2272,
        42.6667
    ],
    [
        0,
        7.2268,
        42.6833
    ],
    [
        0,
        7.2271,
        42.7
    ],
    [
        0,
        7.2269,
        42.7167
    ],
    [
        0,
        7.2266,
        42.7333
    ],
    [
        0,
        7.2265,
        42.75
    ],
    [
        0,
        7.2272,
        42.7667
    ],
    [
        0,
        7.2273,
        42.7833
    ],
    [
        0,
        7.2269,
        42.8
    ],
    [
        0,
        7.2263,
        42.8167
    ],
    [
        0,
        7.226,
        42.8333
    ],
    [
        0,
        7.2266,
        42.85
    ],
    [
        0,
        7.2265,
        42.8667
    ],
    [
        0,
        7.2269,
        42.8833
    ],
    [
        0,
        7.2269,
        42.9
    ],
    [
        0,
        7.227,
        42.9167
    ],
    [
        0,
        7.2272,
        42.9333
    ],
    [
        0,
        7.2271,
        42.95
    ],
    [
        0,
        7.2265,
        42.9667
    ],
    [
        0,
        7.2273,
        42.9833
    ],
    [
        0,
        7.2266,
        43
    ],
    [
        0,
        7.2272,
        43.0167
    ],
    [
        0,
        7.227,
        43.0333
    ],
    [
        0,
        7.2275,
        43.05
    ],
    [
        0,
        7.227,
        43.0667
    ],
    [
        0,
        7.2271,
        43.0833
    ],
    [
        0,
        7.2268,
        43.1
    ],
    [
        0,
        7.2269,
        43.1167
    ],
    [
        0,
        7.2266,
        43.1333
    ],
    [
        0,
        7.2267,
        43.15
    ],
    [
        0,
        7.2267,
        43.1667
    ],
    [
        0,
        7.2271,
        43.1833
    ],
    [
        0,
        7.2268,
        43.2
    ],
    [
        0,
        7.2271,
        43.2167
    ],
    [
        0,
        7.227,
        43.2333
    ],
    [
        0,
        7.2268,
        43.25
    ],
    [
        0,
        7.2265,
        43.2667
    ],
    [
        0,
        7.227,
        43.2833
    ],
    [
        0,
        7.2268,
        43.3
    ],
    [
        0,
        7.2269,
        43.3167
    ],
    [
        0,
        7.2277,
        43.3333
    ],
    [
        0,
        7.2267,
        43.35
    ],
    [
        0,
        7.2271,
        43.3667
    ],
    [
        0,
        7.2266,
        43.3833
    ],
    [
        0,
        7.2269,
        43.4
    ],
    [
        0,
        7.2272,
        43.4167
    ],
    [
        0,
        7.227,
        43.4333
    ],
    [
        0,
        7.2274,
        43.45
    ],
    [
        0,
        7.2273,
        43.4667
    ],
    [
        0,
        7.2272,
        43.4833
    ],
    [
        0,
        7.227,
        43.5
    ],
    [
        0,
        7.2273,
        43.5167
    ],
    [
        0,
        7.2269,
        43.5333
    ],
    [
        0,
        7.2268,
        43.55
    ],
    [
        0,
        7.2272,
        43.5667
    ],
    [
        0,
        7.2274,
        43.5833
    ],
    [
        0,
        7.2269,
        43.6
    ],
    [
        0,
        7.2269,
        43.6167
    ],
    [
        0,
        7.2268,
        43.6333
    ],
    [
        0,
        7.2274,
        43.65
    ],
    [
        0,
        7.2266,
        43.6667
    ],
    [
        0,
        7.2271,
        43.6833
    ],
    [
        0,
        7.2268,
        43.7
    ],
    [
        0,
        7.2266,
        43.7167
    ],
    [
        0,
        7.2278,
        43.7333
    ],
    [
        0,
        7.2273,
        43.75
    ],
    [
        0,
        7.2275,
        43.7667
    ],
    [
        0,
        7.2274,
        43.7833
    ],
    [
        0,
        7.2277,
        43.8
    ],
    [
        0,
        7.2268,
        43.8167
    ],
    [
        0,
        7.2277,
        43.8333
    ],
    [
        0,
        7.2269,
        43.85
    ],
    [
        0,
        7.2269,
        43.8667
    ],
    [
        0,
        7.2269,
        43.8833
    ],
    [
        0,
        7.2274,
        43.9
    ],
    [
        0,
        7.2272,
        43.9167
    ],
    [
        0,
        7.2268,
        43.9333
    ],
    [
        0,
        7.227,
        43.95
    ],
    [
        0,
        7.2271,
        43.9667
    ],
    [
        0,
        7.2274,
        43.9833
    ],
    [
        0,
        7.2273,
        44
    ],
    [
        0,
        7.2265,
        44.0167
    ],
    [
        0,
        7.2266,
        44.0333
    ],
    [
        0,
        7.2266,
        44.05
    ],
    [
        0,
        7.2274,
        44.0667
    ],
    [
        0,
        7.2267,
        44.0833
    ],
    [
        0,
        7.2276,
        44.1
    ],
    [
        0,
        7.227,
        44.1167
    ],
    [
        0,
        7.2266,
        44.1333
    ],
    [
        0,
        7.2266,
        44.15
    ],
    [
        0,
        7.2266,
        44.1667
    ],
    [
        0,
        7.2272,
        44.1833
    ],
    [
        0,
        7.2267,
        44.2
    ],
    [
        0,
        7.2273,
        44.2167
    ],
    [
        0,
        7.2272,
        44.2333
    ],
    [
        0,
        7.2267,
        44.25
    ],
    [
        0,
        7.2271,
        44.2667
    ],
    [
        0,
        7.227,
        44.2833
    ],
    [
        0,
        7.2269,
        44.3
    ],
    [
        0,
        7.2274,
        44.3167
    ],
    [
        0,
        7.2271,
        44.3333
    ],
    [
        0,
        7.2269,
        44.35
    ],
    [
        0,
        7.2272,
        44.3667
    ],
    [
        0,
        7.2274,
        44.3833
    ],
    [
        0,
        7.2278,
        44.4
    ],
    [
        0,
        7.2267,
        44.4167
    ],
    [
        0,
        7.2273,
        44.4333
    ],
    [
        0,
        7.2279,
        44.45
    ],
    [
        0,
        7.2273,
        44.4667
    ],
    [
        0,
        7.2278,
        44.4833
    ],
    [
        0,
        7.2278,
        44.5
    ],
    [
        0,
        7.2276,
        44.5167
    ],
    [
        0,
        7.2274,
        44.5333
    ],
    [
        0,
        7.2272,
        44.55
    ],
    [
        0,
        7.2274,
        44.5667
    ],
    [
        0,
        7.2267,
        44.5833
    ],
    [
        0,
        7.2271,
        44.6
    ],
    [
        0,
        7.2273,
        44.6167
    ],
    [
        0,
        7.2269,
        44.6333
    ],
    [
        0,
        7.227,
        44.65
    ],
    [
        0,
        7.2276,
        44.6667
    ],
    [
        0,
        7.2272,
        44.6833
    ],
    [
        0,
        7.2272,
        44.7
    ],
    [
        0,
        7.227,
        44.7167
    ],
    [
        0,
        7.2271,
        44.7333
    ],
    [
        0,
        7.2266,
        44.75
    ],
    [
        0,
        7.2272,
        44.7667
    ],
    [
        0,
        7.2272,
        44.7833
    ],
    [
        0,
        7.2271,
        44.8
    ],
    [
        0,
        7.2268,
        44.8167
    ],
    [
        0,
        7.2275,
        44.8333
    ],
    [
        0,
        7.2271,
        44.85
    ],
    [
        0,
        7.2275,
        44.8667
    ],
    [
        0,
        7.227,
        44.8833
    ],
    [
        0,
        7.2275,
        44.9
    ],
    [
        0,
        7.2269,
        44.9167
    ],
    [
        0,
        7.2265,
        44.9333
    ],
    [
        0,
        7.227,
        44.95
    ],
    [
        0,
        7.2266,
        44.9667
    ],
    [
        0,
        7.2267,
        44.9833
    ],
    [
        0,
        7.2268,
        45
    ],
    [
        0,
        7.2264,
        45.0167
    ],
    [
        0,
        7.2266,
        45.0333
    ],
    [
        0,
        7.2258,
        45.05
    ],
    [
        0,
        7.227,
        45.0667
    ],
    [
        0,
        7.2267,
        45.0833
    ],
    [
        0,
        7.2266,
        45.1
    ],
    [
        0,
        7.2265,
        45.1167
    ],
    [
        0,
        7.2271,
        45.1333
    ],
    [
        0,
        7.2269,
        45.15
    ],
    [
        0,
        7.2267,
        45.1667
    ],
    [
        0,
        7.227,
        45.1833
    ],
    [
        0,
        7.2272,
        45.2
    ],
    [
        0,
        7.2269,
        45.2167
    ],
    [
        0,
        7.2272,
        45.2333
    ],
    [
        0,
        7.2271,
        45.25
    ],
    [
        0,
        7.2277,
        45.2667
    ],
    [
        0,
        7.2275,
        45.2833
    ],
    [
        0,
        7.2275,
        45.3
    ],
    [
        0,
        7.2274,
        45.3167
    ],
    [
        0,
        7.2269,
        45.3333
    ],
    [
        0,
        7.2269,
        45.35
    ],
    [
        0,
        7.2271,
        45.3667
    ],
    [
        0,
        7.227,
        45.3833
    ],
    [
        0,
        7.2269,
        45.4
    ],
    [
        0,
        7.2275,
        45.4167
    ],
    [
        0,
        7.2272,
        45.4333
    ],
    [
        0,
        7.2273,
        45.45
    ],
    [
        0,
        7.2266,
        45.4667
    ],
    [
        0,
        7.2273,
        45.4833
    ],
    [
        0,
        7.2276,
        45.5
    ],
    [
        0,
        7.2276,
        45.5167
    ],
    [
        0,
        7.2269,
        45.5333
    ],
    [
        0,
        7.2269,
        45.55
    ],
    [
        0,
        7.2277,
        45.5667
    ],
    [
        0,
        7.2278,
        45.5833
    ],
    [
        0,
        7.2279,
        45.6
    ],
    [
        0,
        7.2273,
        45.6167
    ],
    [
        0,
        7.2275,
        45.6333
    ],
    [
        0,
        7.2275,
        45.65
    ],
    [
        0,
        7.2271,
        45.6667
    ],
    [
        0,
        7.2278,
        45.6833
    ],
    [
        0,
        7.2268,
        45.7
    ],
    [
        0,
        7.2273,
        45.7167
    ],
    [
        0,
        7.2268,
        45.7333
    ],
    [
        0,
        7.2278,
        45.75
    ],
    [
        0,
        7.2277,
        45.7667
    ],
    [
        0,
        7.2271,
        45.7833
    ],
    [
        0,
        7.2273,
        45.8
    ],
    [
        0,
        7.2281,
        45.8167
    ],
    [
        0,
        7.2276,
        45.8333
    ],
    [
        0,
        7.2274,
        45.85
    ],
    [
        0,
        7.2276,
        45.8667
    ],
    [
        0,
        7.2279,
        45.8833
    ],
    [
        0,
        7.2275,
        45.9
    ],
    [
        0,
        7.2279,
        45.9167
    ],
    [
        0,
        7.2276,
        45.9333
    ],
    [
        0,
        7.2271,
        45.95
    ],
    [
        0,
        7.2272,
        45.9667
    ],
    [
        0,
        7.2269,
        45.9833
    ],
    [
        0,
        7.2269,
        46
    ],
    [
        0,
        7.2272,
        46.0167
    ],
    [
        0,
        7.2271,
        46.0333
    ],
    [
        0,
        7.2269,
        46.05
    ],
    [
        0,
        7.2272,
        46.0667
    ],
    [
        0,
        7.2267,
        46.0833
    ],
    [
        0,
        7.2275,
        46.1
    ],
    [
        0,
        7.2271,
        46.1167
    ],
    [
        0,
        7.2273,
        46.1333
    ],
    [
        0,
        7.2273,
        46.15
    ],
    [
        0,
        7.2273,
        46.1667
    ],
    [
        0,
        7.2271,
        46.1833
    ],
    [
        0,
        7.2272,
        46.2
    ],
    [
        0,
        7.2271,
        46.2167
    ],
    [
        0,
        7.2273,
        46.2333
    ],
    [
        0,
        7.2274,
        46.25
    ],
    [
        0,
        7.2272,
        46.2667
    ],
    [
        0,
        7.2266,
        46.2833
    ],
    [
        0,
        7.2271,
        46.3
    ],
    [
        0,
        7.2277,
        46.3167
    ],
    [
        0,
        7.2276,
        46.3333
    ],
    [
        0,
        7.2274,
        46.35
    ],
    [
        0,
        7.2275,
        46.3667
    ],
    [
        0,
        7.2271,
        46.3833
    ],
    [
        0,
        7.2272,
        46.4
    ],
    [
        0,
        7.2273,
        46.4167
    ],
    [
        0,
        7.2277,
        46.4333
    ],
    [
        0,
        7.2273,
        46.45
    ],
    [
        0,
        7.2272,
        46.4667
    ],
    [
        0,
        7.2273,
        46.4833
    ],
    [
        0,
        7.2271,
        46.5
    ],
    [
        0,
        7.2271,
        46.5167
    ],
    [
        0,
        7.227,
        46.5333
    ],
    [
        0,
        7.2278,
        46.55
    ],
    [
        0,
        7.2268,
        46.5667
    ],
    [
        0,
        7.2267,
        46.5833
    ],
    [
        0,
        7.2261,
        46.6
    ],
    [
        0,
        7.2269,
        46.6167
    ],
    [
        0,
        7.2272,
        46.6333
    ],
    [
        0,
        7.2273,
        46.65
    ],
    [
        0,
        7.2273,
        46.6667
    ],
    [
        0,
        7.227,
        46.6833
    ],
    [
        0,
        7.2267,
        46.7
    ],
    [
        0,
        7.2271,
        46.7167
    ],
    [
        0,
        7.2275,
        46.7333
    ],
    [
        0,
        7.2277,
        46.75
    ],
    [
        0,
        7.2276,
        46.7667
    ],
    [
        0,
        7.2274,
        46.7833
    ],
    [
        0,
        7.2273,
        46.8
    ],
    [
        0,
        7.227,
        46.8167
    ],
    [
        0,
        7.2276,
        46.8333
    ],
    [
        0,
        7.227,
        46.85
    ],
    [
        0,
        7.2269,
        46.8667
    ],
    [
        0,
        7.2268,
        46.8833
    ],
    [
        0,
        7.2262,
        46.9
    ],
    [
        0,
        7.2267,
        46.9167
    ],
    [
        0,
        7.2273,
        46.9333
    ],
    [
        0,
        7.2275,
        46.95
    ],
    [
        0,
        7.2276,
        46.9667
    ],
    [
        0,
        7.2268,
        46.9833
    ],
    [
        0,
        7.2267,
        47
    ],
    [
        0,
        7.2269,
        47.0167
    ],
    [
        0,
        7.2271,
        47.0333
    ],
    [
        0,
        7.2276,
        47.05
    ],
    [
        0,
        7.2273,
        47.0667
    ],
    [
        0,
        7.2273,
        47.0833
    ],
    [
        0,
        7.2271,
        47.1
    ],
    [
        0,
        7.2271,
        47.1167
    ],
    [
        0,
        7.2271,
        47.1333
    ],
    [
        0,
        7.2271,
        47.15
    ],
    [
        0,
        7.2273,
        47.1667
    ],
    [
        0,
        7.2276,
        47.1833
    ],
    [
        0,
        7.2276,
        47.2
    ],
    [
        0,
        7.2276,
        47.2167
    ],
    [
        0,
        7.2271,
        47.2333
    ],
    [
        0,
        7.2276,
        47.25
    ],
    [
        0,
        7.2271,
        47.2667
    ],
    [
        0,
        7.2278,
        47.2833
    ],
    [
        0,
        7.2271,
        47.3
    ],
    [
        0,
        7.2267,
        47.3167
    ],
    [
        0,
        7.2274,
        47.3333
    ],
    [
        0,
        7.227,
        47.35
    ],
    [
        0,
        7.2272,
        47.3667
    ],
    [
        0,
        7.2276,
        47.3833
    ],
    [
        0,
        7.2276,
        47.4
    ],
    [
        0,
        7.2276,
        47.4167
    ],
    [
        0,
        7.2276,
        47.4333
    ],
    [
        0,
        7.227,
        47.45
    ],
    [
        0,
        7.2272,
        47.4667
    ],
    [
        0,
        7.2277,
        47.4833
    ],
    [
        0,
        7.2275,
        47.5
    ],
    [
        0,
        7.227,
        47.5167
    ],
    [
        0,
        7.2269,
        47.5333
    ],
    [
        0,
        7.2274,
        47.55
    ],
    [
        0,
        7.2269,
        47.5667
    ],
    [
        0,
        7.2272,
        47.5833
    ],
    [
        0,
        7.2273,
        47.6
    ],
    [
        0,
        7.2274,
        47.6167
    ],
    [
        0,
        7.2274,
        47.6333
    ],
    [
        0,
        7.227,
        47.65
    ],
    [
        0,
        7.2268,
        47.6667
    ],
    [
        0,
        7.2275,
        47.6833
    ],
    [
        0,
        7.2267,
        47.7
    ],
    [
        0,
        7.2273,
        47.7167
    ],
    [
        0,
        7.2272,
        47.7333
    ],
    [
        0,
        7.227,
        47.75
    ],
    [
        0,
        7.227,
        47.7667
    ],
    [
        0,
        7.2276,
        47.7833
    ],
    [
        0,
        7.2274,
        47.8
    ],
    [
        0,
        7.2275,
        47.8167
    ],
    [
        0,
        7.2272,
        47.8333
    ],
    [
        0,
        7.2271,
        47.85
    ],
    [
        0,
        7.228,
        47.8667
    ],
    [
        0,
        7.2274,
        47.8833
    ],
    [
        0,
        7.2274,
        47.9
    ],
    [
        0,
        7.2273,
        47.9167
    ],
    [
        0,
        7.2265,
        47.9333
    ],
    [
        0,
        7.2269,
        47.95
    ],
    [
        0,
        7.2273,
        47.9667
    ],
    [
        0,
        7.2273,
        47.9833
    ],
    [
        0,
        7.2275,
        48
    ],
    [
        0,
        7.2273,
        48.0167
    ],
    [
        0,
        7.2272,
        48.0333
    ],
    [
        0,
        7.2271,
        48.05
    ],
    [
        0,
        7.2276,
        48.0667
    ],
    [
        0,
        7.2271,
        48.0833
    ],
    [
        0,
        7.2275,
        48.1
    ],
    [
        0,
        7.2281,
        48.1167
    ],
    [
        0,
        7.2279,
        48.1333
    ],
    [
        0,
        7.2271,
        48.15
    ],
    [
        0,
        7.2273,
        48.1667
    ],
    [
        0,
        7.2271,
        48.1833
    ],
    [
        0,
        7.2274,
        48.2
    ],
    [
        0,
        7.2272,
        48.2167
    ],
    [
        0,
        7.2274,
        48.2333
    ],
    [
        0,
        7.2267,
        48.25
    ],
    [
        0,
        7.2271,
        48.2667
    ],
    [
        0,
        7.2273,
        48.2833
    ],
    [
        0,
        7.2281,
        48.3
    ],
    [
        0,
        7.228,
        48.3167
    ],
    [
        0,
        7.2279,
        48.3333
    ],
    [
        0,
        7.2271,
        48.35
    ],
    [
        0,
        7.2274,
        48.3667
    ],
    [
        0,
        7.2274,
        48.3833
    ],
    [
        0,
        7.2272,
        48.4
    ],
    [
        0,
        7.2277,
        48.4167
    ],
    [
        0,
        7.2276,
        48.4333
    ],
    [
        0,
        7.2275,
        48.45
    ],
    [
        0,
        7.2275,
        48.4667
    ],
    [
        0,
        7.2276,
        48.4833
    ],
    [
        0,
        7.2275,
        48.5
    ],
    [
        0,
        7.2271,
        48.5167
    ],
    [
        0,
        7.2277,
        48.5333
    ],
    [
        0,
        7.2278,
        48.55
    ],
    [
        0,
        7.2281,
        48.5667
    ],
    [
        0,
        7.2278,
        48.5833
    ],
    [
        0,
        7.2269,
        48.6
    ],
    [
        0,
        7.2276,
        48.6167
    ],
    [
        0,
        7.2277,
        48.6333
    ],
    [
        0,
        7.2273,
        48.65
    ],
    [
        0,
        7.2269,
        48.6667
    ],
    [
        0,
        7.2275,
        48.6833
    ],
    [
        0,
        7.2271,
        48.7
    ],
    [
        0,
        7.2275,
        48.7167
    ],
    [
        0,
        7.2272,
        48.7333
    ],
    [
        0,
        7.2271,
        48.75
    ],
    [
        0,
        7.2273,
        48.7667
    ],
    [
        0,
        7.2272,
        48.7833
    ],
    [
        0,
        7.2277,
        48.8
    ],
    [
        0,
        7.2273,
        48.8167
    ],
    [
        0,
        7.2277,
        48.8333
    ],
    [
        0,
        7.2276,
        48.85
    ],
    [
        0,
        7.2275,
        48.8667
    ],
    [
        0,
        7.2278,
        48.8833
    ],
    [
        0,
        7.2279,
        48.9
    ],
    [
        0,
        7.2281,
        48.9167
    ],
    [
        0,
        7.2277,
        48.9333
    ],
    [
        0,
        7.2276,
        48.95
    ],
    [
        0,
        7.2276,
        48.9667
    ],
    [
        0,
        7.2273,
        48.9833
    ],
    [
        0,
        7.2274,
        49
    ],
    [
        0,
        7.2278,
        49.0167
    ],
    [
        0,
        7.2273,
        49.0333
    ],
    [
        0,
        7.2274,
        49.05
    ],
    [
        0,
        7.2269,
        49.0667
    ],
    [
        0,
        7.2277,
        49.0833
    ],
    [
        0,
        7.2273,
        49.1
    ],
    [
        0,
        7.227,
        49.1167
    ],
    [
        0,
        7.2272,
        49.1333
    ],
    [
        0,
        7.2274,
        49.15
    ],
    [
        0,
        7.2273,
        49.1667
    ],
    [
        0,
        7.2274,
        49.1833
    ],
    [
        0,
        7.2272,
        49.2
    ],
    [
        0,
        7.227,
        49.2167
    ],
    [
        0,
        7.2277,
        49.2333
    ],
    [
        0,
        7.2276,
        49.25
    ],
    [
        0,
        7.2278,
        49.2667
    ],
    [
        0,
        7.2275,
        49.2833
    ],
    [
        0,
        7.2275,
        49.3
    ],
    [
        0,
        7.2271,
        49.3167
    ],
    [
        0,
        7.2273,
        49.3333
    ],
    [
        0,
        7.2277,
        49.35
    ],
    [
        0,
        7.2273,
        49.3667
    ],
    [
        0,
        7.2273,
        49.3833
    ],
    [
        0,
        7.2276,
        49.4
    ],
    [
        0,
        7.2272,
        49.4167
    ],
    [
        0,
        7.2269,
        49.4333
    ],
    [
        0,
        7.2274,
        49.45
    ],
    [
        0,
        7.2269,
        49.4667
    ],
    [
        0,
        7.2274,
        49.4833
    ],
    [
        0,
        7.2271,
        49.5
    ],
    [
        0,
        7.228,
        49.5167
    ],
    [
        0,
        7.2273,
        49.5333
    ],
    [
        0,
        7.2275,
        49.55
    ],
    [
        0,
        7.2277,
        49.5667
    ],
    [
        0,
        7.2274,
        49.5833
    ],
    [
        0,
        7.2276,
        49.6
    ],
    [
        0,
        7.2276,
        49.6167
    ],
    [
        0,
        7.2277,
        49.6333
    ],
    [
        0,
        7.2276,
        49.65
    ],
    [
        0,
        7.2275,
        49.6667
    ],
    [
        0,
        7.2271,
        49.6833
    ],
    [
        0,
        7.2275,
        49.7
    ],
    [
        0,
        7.2275,
        49.7167
    ],
    [
        0,
        7.2272,
        49.7333
    ],
    [
        0,
        7.2273,
        49.75
    ],
    [
        0,
        7.2269,
        49.7667
    ],
    [
        0,
        7.2269,
        49.7833
    ],
    [
        0,
        7.2268,
        49.8
    ],
    [
        0,
        7.2273,
        49.8167
    ],
    [
        0,
        7.2272,
        49.8333
    ],
    [
        0,
        7.2274,
        49.85
    ],
    [
        0,
        7.2267,
        49.8667
    ],
    [
        0,
        7.2269,
        49.8833
    ],
    [
        0,
        7.2271,
        49.9
    ],
    [
        0,
        7.2271,
        49.9167
    ],
    [
        0,
        7.2269,
        49.9333
    ],
    [
        0,
        7.2273,
        49.95
    ],
    [
        0,
        7.2271,
        49.9667
    ],
    [
        0,
        7.2269,
        49.9833
    ],
    [
        0,
        7.2274,
        50
    ],
    [
        0,
        7.2272,
        50.0167
    ],
    [
        0,
        7.2272,
        50.0333
    ],
    [
        0,
        7.2268,
        50.05
    ],
    [
        0,
        7.2267,
        50.0667
    ],
    [
        0,
        7.227,
        50.0833
    ],
    [
        0,
        7.228,
        50.1
    ],
    [
        0,
        7.2262,
        50.1167
    ],
    [
        0,
        7.2269,
        50.1333
    ],
    [
        0,
        7.2274,
        50.15
    ],
    [
        0,
        7.2264,
        50.1667
    ],
    [
        0,
        7.227,
        50.1833
    ],
    [
        0,
        7.2272,
        50.2
    ],
    [
        0,
        7.2273,
        50.2167
    ],
    [
        0,
        7.2274,
        50.2333
    ],
    [
        0,
        7.2274,
        50.25
    ],
    [
        0,
        7.2267,
        50.2667
    ],
    [
        0,
        7.227,
        50.2833
    ],
    [
        0,
        7.2272,
        50.3
    ],
    [
        0,
        7.2274,
        50.3167
    ],
    [
        0,
        7.2274,
        50.3333
    ],
    [
        0,
        7.2268,
        50.35
    ],
    [
        0,
        7.2271,
        50.3667
    ],
    [
        0,
        7.2267,
        50.3833
    ],
    [
        0,
        7.2273,
        50.4
    ],
    [
        0,
        7.2277,
        50.4167
    ],
    [
        0,
        7.2275,
        50.4333
    ],
    [
        0,
        7.2277,
        50.45
    ],
    [
        0,
        7.2272,
        50.4667
    ],
    [
        0,
        7.227,
        50.4833
    ],
    [
        0,
        7.2276,
        50.5
    ],
    [
        0,
        7.227,
        50.5167
    ],
    [
        0,
        7.2273,
        50.5333
    ],
    [
        0,
        7.2272,
        50.55
    ],
    [
        0,
        7.227,
        50.5667
    ],
    [
        0,
        7.2273,
        50.5833
    ],
    [
        0,
        7.2269,
        50.6
    ],
    [
        0,
        7.2271,
        50.6167
    ],
    [
        0,
        7.2276,
        50.6333
    ],
    [
        0,
        7.2273,
        50.65
    ],
    [
        0,
        7.2278,
        50.6667
    ],
    [
        0,
        7.2272,
        50.6833
    ],
    [
        0,
        7.2281,
        50.7
    ],
    [
        0,
        7.2274,
        50.7167
    ],
    [
        0,
        7.2273,
        50.7333
    ],
    [
        0,
        7.2278,
        50.75
    ],
    [
        0,
        7.227,
        50.7667
    ],
    [
        0,
        7.2276,
        50.7833
    ],
    [
        0,
        7.2275,
        50.8
    ]
]

export const graphData = [
    {
        "Zreal (ohm)": 3.1351999999999998,
        " -Zimag (ohm)": 4.0283999999999995,
        "Fit1 - Zreal (ohm)": 3.066724,
        " Fit1 - -Zimag (ohm)": 4.01844
    },
    {
        "Zreal (ohm)": 3.0972,
        " -Zimag (ohm)": 2.7342,
        "Fit1 - Zreal (ohm)": 3.079526,
        " Fit1 - -Zimag (ohm)": 2.7228399999999997
    },
    {
        "Zreal (ohm)": 3.0902999999999996,
        " -Zimag (ohm)": 1.8134,
        "Fit1 - Zreal (ohm)": 3.0953299999999997,
        " Fit1 - -Zimag (ohm)": 1.8121159999999998
    },
    {
        "Zreal (ohm)": 3.1019,
        " -Zimag (ohm)": 1.191,
        "Fit1 - Zreal (ohm)": 3.114006,
        " Fit1 - -Zimag (ohm)": 1.19757
    },
    {
        "Zreal (ohm)": 3.1272,
        " -Zimag (ohm)": 0.7631,
        "Fit1 - Zreal (ohm)": 3.136111,
        " Fit1 - -Zimag (ohm)": 0.7760525
    },
    {
        "Zreal (ohm)": 3.1614,
        " -Zimag (ohm)": 0.4722,
        "Fit1 - Zreal (ohm)": 3.1621520000000003,
        " Fit1 - -Zimag (ohm)": 0.4841915
    },
    {
        "Zreal (ohm)": 3.1957999999999998,
        " -Zimag (ohm)": 0.2716,
        "Fit1 - Zreal (ohm)": 3.19245,
        " Fit1 - -Zimag (ohm)": 0.2813521
    },
    {
        "Zreal (ohm)": 3.2325,
        " -Zimag (ohm)": 0.1206,
        "Fit1 - Zreal (ohm)": 3.2269680000000003,
        " Fit1 - -Zimag (ohm)": 0.1407128
    },
    {
        "Zreal (ohm)": 3.2712000000000003,
        " -Zimag (ohm)": 0.040289610000000003,
        "Fit1 - Zreal (ohm)": 3.266091,
        " Fit1 - -Zimag (ohm)": 0.04243439
    },
    {
        "Zreal (ohm)": 3.3062,
        " -Zimag (ohm)": 0.02723805,
        "Fit1 - Zreal (ohm)": 3.3061879999999997,
        " Fit1 - -Zimag (ohm)": 0.020482820000000002
    },
    {
        "Zreal (ohm)": 3.3447,
        " -Zimag (ohm)": 0.061949569999999995,
        "Fit1 - Zreal (ohm)": 3.3492819999999996,
        " Fit1 - -Zimag (ohm)": 0.06210021999999999
    },
    {
        "Zreal (ohm)": 3.384,
        " -Zimag (ohm)": 0.08506103000000001,
        "Fit1 - Zreal (ohm)": 3.391314,
        " Fit1 - -Zimag (ohm)": 0.08502166
    },
    {
        "Zreal (ohm)": 3.4226,
        " -Zimag (ohm)": 0.0969,
        "Fit1 - Zreal (ohm)": 3.430514,
        " Fit1 - -Zimag (ohm)": 0.09432259999999999
    },
    {
        "Zreal (ohm)": 3.4600999999999997,
        " -Zimag (ohm)": 0.0979,
        "Fit1 - Zreal (ohm)": 3.464697,
        " Fit1 - -Zimag (ohm)": 0.0941594
    },
    {
        "Zreal (ohm)": 3.4903,
        " -Zimag (ohm)": 0.09086081,
        "Fit1 - Zreal (ohm)": 3.493273,
        " Fit1 - -Zimag (ohm)": 0.08840352
    },
    {
        "Zreal (ohm)": 3.5109999999999997,
        " -Zimag (ohm)": 0.080095,
        "Fit1 - Zreal (ohm)": 3.5160579999999997,
        " Fit1 - -Zimag (ohm)": 0.08034379
    },
    {
        "Zreal (ohm)": 3.5362,
        " -Zimag (ohm)": 0.07217345,
        "Fit1 - Zreal (ohm)": 3.533795,
        " Fit1 - -Zimag (ohm)": 0.07254499
    },
    {
        "Zreal (ohm)": 3.5503,
        " -Zimag (ohm)": 0.06700002000000001,
        "Fit1 - Zreal (ohm)": 3.548083,
        " Fit1 - -Zimag (ohm)": 0.06663859999999999
    },
    {
        "Zreal (ohm)": 3.5599999999999996,
        " -Zimag (ohm)": 0.06564426,
        "Fit1 - Zreal (ohm)": 3.559543,
        " Fit1 - -Zimag (ohm)": 0.06410742
    },
    {
        "Zreal (ohm)": 3.5711,
        " -Zimag (ohm)": 0.06537032,
        "Fit1 - Zreal (ohm)": 3.5697200000000002,
        " Fit1 - -Zimag (ohm)": 0.0654722
    },
    {
        "Zreal (ohm)": 3.5788,
        " -Zimag (ohm)": 0.07298639999999999,
        "Fit1 - Zreal (ohm)": 3.5798750000000004,
        " Fit1 - -Zimag (ohm)": 0.07118972
    },
    {
        "Zreal (ohm)": 3.5913999999999997,
        " -Zimag (ohm)": 0.08228589,
        "Fit1 - Zreal (ohm)": 3.591093,
        " Fit1 - -Zimag (ohm)": 0.08182590000000001
    },
    {
        "Zreal (ohm)": 3.6029999999999998,
        " -Zimag (ohm)": 0.0987,
        "Fit1 - Zreal (ohm)": 3.604223,
        " Fit1 - -Zimag (ohm)": 0.09790811999999999
    },
    {
        "Zreal (ohm)": 3.6183,
        " -Zimag (ohm)": 0.1192,
        "Fit1 - Zreal (ohm)": 3.620569,
        " Fit1 - -Zimag (ohm)": 0.1207604
    }
]
export const DVA = [
    [
        0,
        -4.995122781818182
    ],
    [
        0.0104,
        -5.2430669666666665
    ],
    [
        0.0207,
        -5.40908256923077
    ],
    [
        0.0311,
        -5.695562964285714
    ],
    [
        0.0414,
        -5.581542813333333
    ],
    [
        0.0518,
        -7.39136536875
    ],
    [
        0.0622,
        -7.510680776470588
    ],
    [
        0.0725,
        -7.396390194444444
    ],
    [
        0.0829,
        -8.37047199473684
    ],
    [
        0.0933,
        -8.422940315
    ],
    [
        0.1036,
        -8.401396961904762
    ],
    [
        0.114,
        -10.046141228571427
    ],
    [
        0.1243,
        -10.464795390476192
    ],
    [
        0.1347,
        -10.628063095238094
    ],
    [
        0.1451,
        -10.823651819047619
    ],
    [
        0.1554,
        -11.205452776190477
    ],
    [
        0.1658,
        -11.374135666666666
    ],
    [
        0.1761,
        -11.677782595238094
    ],
    [
        0.1865,
        -11.814839328571427
    ],
    [
        0.1969,
        -11.99987699047619
    ],
    [
        0.2072,
        -11.740181919047618
    ],
    [
        0.2176,
        -11.571975928571428
    ],
    [
        0.228,
        -12.0147921
    ],
    [
        0.2383,
        -12.014789833333333
    ],
    [
        0.2487,
        -12.271110385714286
    ],
    [
        0.259,
        -12.786212861904763
    ],
    [
        0.2694,
        -13.608533847619046
    ],
    [
        0.2798,
        -13.434097685714287
    ],
    [
        0.2901,
        -14.819136209523808
    ],
    [
        0.3005,
        -13.996789652380953
    ],
    [
        0.3109,
        -14.370578652380951
    ],
    [
        0.3212,
        -14.607807385714286
    ],
    [
        0.3316,
        -13.667948985714288
    ],
    [
        0.3419,
        -18.108589195238096
    ],
    [
        0.3523,
        -18.34844721904762
    ],
    [
        0.3627,
        -18.5677394
    ],
    [
        0.373,
        -19.595698395238095
    ],
    [
        0.3834,
        -19.4586448952381
    ],
    [
        0.3937,
        -20.609941190476192
    ],
    [
        0.4041,
        -20.746997038095238
    ],
    [
        0.4145,
        -21.240412914285713
    ],
    [
        0.4248,
        -21.94528970952381
    ],
    [
        0.4352,
        -22.201599061904762
    ],
    [
        0.4456,
        -22.36606079047619
    ],
    [
        0.4559,
        -22.462183447619047
    ],
    [
        0.4663,
        -22.990840285714288
    ],
    [
        0.4766,
        -22.990858428571432
    ],
    [
        0.487,
        -21.140596523809524
    ],
    [
        0.4974,
        -18.399405461904763
    ],
    [
        0.5077,
        -17.06306374761905
    ],
    [
        0.5181,
        -18.296608719047622
    ],
    [
        0.5284,
        -18.091017890476195
    ],
    [
        0.5388,
        -19.118924419047627
    ],
    [
        0.5492,
        -18.90745442380953
    ],
    [
        0.5595,
        -18.90749675238095
    ],
    [
        0.5699,
        -18.711689747619047
    ],
    [
        0.5803,
        -18.98580144285714
    ],
    [
        0.5906,
        -22.27521943333333
    ],
    [
        0.601,
        -22.243588719047615
    ],
    [
        0.6113,
        -21.585683957142855
    ],
    [
        0.6217,
        -21.037453504761903
    ],
    [
        0.6321,
        -20.544033395238095
    ],
    [
        0.6424,
        -14.905040200000004
    ],
    [
        0.6528,
        -14.748397890476193
    ],
    [
        0.6631,
        -14.583930871428574
    ],
    [
        0.6735,
        -14.957711214285714
    ],
    [
        0.6839,
        -14.957695342857143
    ],
    [
        0.6942,
        -14.957683247619048
    ],
    [
        0.7046,
        -15.985650623809528
    ],
    [
        0.715,
        -23.386814642857146
    ],
    [
        0.7253,
        -24.72318457619048
    ],
    [
        0.7357,
        -23.386851242857148
    ],
    [
        0.746,
        -21.125334485714287
    ],
    [
        0.7564,
        -19.891835366666673
    ],
    [
        0.7668,
        -19.3984194952381
    ],
    [
        0.7771,
        -14.91288221904762
    ],
    [
        0.7875,
        -17.02753225714286
    ],
    [
        0.7979,
        -21.139320619047616
    ],
    [
        0.8082,
        -16.910059847619046
    ],
    [
        0.8186,
        -17.14729020952381
    ],
    [
        0.8289,
        -18.627544176190476
    ],
    [
        0.8393,
        -19.61437169047619
    ],
    [
        0.8497,
        -19.669188623809525
    ],
    [
        0.86,
        -25.4256753952381
    ],
    [
        0.8704,
        -26.11097227619048
    ],
    [
        0.8807,
        -22.821547233333334
    ],
    [
        0.8911,
        -22.447763042857144
    ],
    [
        0.9015,
        -26.14833447142857
    ],
    [
        0.9118,
        -25.991703247619046
    ],
    [
        0.9222,
        -19.412839047619045
    ],
    [
        0.9326,
        -15.712257038095236
    ],
    [
        0.9429,
        -14.47868384285714
    ],
    [
        0.9533,
        -15.403831990476196
    ],
    [
        0.9636,
        -19.515655623809522
    ],
    [
        0.974,
        -19.553024552380947
    ],
    [
        0.9844,
        -19.553024552380947
    ],
    [
        0.9947,
        -19.65269939523809
    ],
    [
        1.0051,
        -16.198781933333336
    ],
    [
        1.0154,
        -11.713202328571427
    ],
    [
        1.0258,
        -13.475415480952382
    ],
    [
        1.0362,
        -13.269815833333334
    ],
    [
        1.0465,
        -9.15802041904762
    ],
    [
        1.0569,
        -9.404727295238095
    ],
    [
        1.0673,
        -9.843313538095238
    ],
    [
        1.0776,
        -13.955250042857145
    ],
    [
        1.088,
        -13.544050747619048
    ],
    [
        1.0983,
        -18.478145985714285
    ],
    [
        1.1087,
        -18.734476914285715
    ],
    [
        1.1191,
        -13.800381676190474
    ],
    [
        1.1294,
        -13.80037697142857
    ],
    [
        1.1398,
        -21.201562157142856
    ],
    [
        1.1502,
        -20.516295842857144
    ],
    [
        1.1605,
        -21.338680323809523
    ],
    [
        1.1709,
        -25.039325828571425
    ],
    [
        1.1812,
        -22.983435176190476
    ],
    [
        1.1916,
        -21.548062014285716
    ],
    [
        1.202,
        -22.781617571428573
    ],
    [
        1.2123,
        -23.46692973809524
    ],
    [
        1.2227,
        -26.098441557142856
    ],
    [
        1.233,
        -27.294624338095236
    ],
    [
        1.2434,
        -25.27608850476191
    ],
    [
        1.2538,
        -26.098465933333333
    ],
    [
        1.2641,
        -28.976705795238093
    ],
    [
        1.2745,
        -30.21021902380952
    ],
    [
        1.2849,
        -29.771637485714287
    ],
    [
        1.2952,
        -29.771552833333338
    ],
    [
        1.3056,
        -27.304519323809522
    ],
    [
        1.3159,
        -25.824337309523816
    ],
    [
        1.3263,
        -26.106267466666672
    ],
    [
        1.3367,
        -24.46155494285715
    ],
    [
        1.347,
        -24.73567134285714
    ],
    [
        1.3574,
        -22.973512614285713
    ],
    [
        1.3677,
        -23.247607852380945
    ],
    [
        1.3781,
        -26.948221609523806
    ],
    [
        1.3885,
        -22.630814199999996
    ],
    [
        1.3988,
        -23.206472052380953
    ],
    [
        1.4092,
        -24.193291099999993
    ],
    [
        1.4196,
        -24.193301680952374
    ],
    [
        1.4299,
        -23.507980109523803
    ],
    [
        1.4403,
        -23.50802243809523
    ],
    [
        1.4506,
        -23.096847833333335
    ],
    [
        1.461,
        -21.661440809523807
    ],
    [
        1.4714,
        -20.921294776190475
    ],
    [
        1.4817,
        -20.51015897142857
    ],
    [
        1.4921,
        -20.51015897142857
    ],
    [
        1.5024,
        -21.19546643333333
    ],
    [
        1.5128,
        -16.966114957142857
    ],
    [
        1.5232,
        -19.315696214285712
    ],
    [
        1.5335,
        -16.684125138095233
    ],
    [
        1.5439,
        -16.93085106190476
    ],
    [
        1.5543,
        -19.068973109523807
    ],
    [
        1.5646,
        -17.541726952380948
    ],
    [
        1.575,
        -17.54170880952381
    ],
    [
        1.5853,
        -17.952932795238095
    ],
    [
        1.5957,
        -13.635504223809521
    ],
    [
        1.6061,
        -12.031878828571426
    ],
    [
        1.6164,
        -12.03184496190476
    ],
    [
        1.6268,
        -12.480414466666664
    ],
    [
        1.6372,
        -13.713895947619047
    ],
    [
        1.6475,
        -14.399238685714286
    ],
    [
        1.6579,
        -15.221559671428574
    ],
    [
        1.6682,
        -14.536235742857142
    ],
    [
        1.6786,
        -17.9901786
    ],
    [
        1.689,
        -18.20165191904762
    ],
    [
        1.6993,
        -16.55691118095238
    ],
    [
        1.7097,
        -15.076699542857144
    ],
    [
        1.72,
        -14.336564095238097
    ],
    [
        1.7304,
        -12.926834695238096
    ],
    [
        1.7408,
        -12.670513661904765
    ],
    [
        1.7511,
        -9.381060399999999
    ],
    [
        1.7615,
        -8.764277333333336
    ],
    [
        1.7719,
        -8.270867809523812
    ],
    [
        1.7822,
        -9.79811396666667
    ],
    [
        1.7926,
        -9.915606533333335
    ],
    [
        1.8029,
        -9.504389600000001
    ],
    [
        1.8133,
        -10.121162085714287
    ],
    [
        1.8237,
        -10.285679900000002
    ],
    [
        1.834,
        -9.915650271428571
    ],
    [
        1.8444,
        -14.401176004761904
    ],
    [
        1.8547,
        -12.550911452380953
    ],
    [
        1.8651,
        -9.6726469
    ],
    [
        1.8755,
        -8.192469123809522
    ],
    [
        1.8858,
        -9.288925328571429
    ],
    [
        1.8962,
        -7.37007759047619
    ],
    [
        1.9066,
        -8.309864942857143
    ],
    [
        1.9169,
        -14.066492809523808
    ],
    [
        1.9273,
        -13.573074819047623
    ],
    [
        1.9376,
        -11.434966880952384
    ],
    [
        1.948,
        -13.373349342857146
    ],
    [
        1.9584,
        -13.911640442857147
    ],
    [
        1.9687,
        -13.911555785714286
    ],
    [
        1.9791,
        -14.117143085714284
    ],
    [
        1.9894,
        -19.05132298095238
    ],
    [
        1.9998,
        -19.215795647619046
    ],
    [
        2.0102,
        -18.941665138095235
    ],
    [
        2.0205,
        -19.764042566666664
    ],
    [
        2.0309,
        -20.17524539047619
    ],
    [
        2.0413,
        -25.931591066666662
    ],
    [
        2.0516,
        -26.137167785714286
    ],
    [
        2.062,
        -22.8478274
    ],
    [
        2.0723,
        -24.69813428095238
    ],
    [
        2.0827,
        -24.6981625
    ],
    [
        2.0931,
        -24.259544276190475
    ],
    [
        2.1034,
        -25.08193580952381
    ],
    [
        2.1138,
        -25.767238571428567
    ],
    [
        2.1242,
        -26.58957366666667
    ],
    [
        2.1345,
        -19.188176838095234
    ],
    [
        2.1449,
        -19.074311147619046
    ],
    [
        2.1552,
        -22.363679757142858
    ],
    [
        2.1656,
        -21.13015594761905
    ],
    [
        2.176,
        -15.209038485714283
    ],
    [
        2.1863,
        -20.591864847619053
    ],
    [
        2.1967,
        -19.769515642857144
    ],
    [
        2.207,
        -15.328762204761901
    ],
    [
        2.2174,
        -13.5195488
    ],
    [
        2.2278,
        -13.793636980952382
    ],
    [
        2.2381,
        -12.148910347619045
    ],
    [
        2.2485,
        -10.504183714285713
    ],
    [
        2.2589,
        -5.899107171428571
    ],
    [
        2.2692,
        -4.089921985714284
    ],
    [
        2.2796,
        -3.6786909428571426
    ],
    [
        2.2899,
        3.722748209523811
    ],
    [
        2.3003,
        1.5298546142857157
    ],
    [
        2.3107,
        1.25574291904762
    ],
    [
        2.321,
        3.722832866666668
    ],
    [
        2.3314,
        4.956377838095239
    ],
    [
        2.3417,
        5.778684714285716
    ],
    [
        2.3521,
        2.6066816904761922
    ],
    [
        2.3625,
        -1.9479459047619028
    ],
    [
        2.3728,
        -1.1256249142857127
    ],
    [
        2.3832,
        -3.5926725333333316
    ],
    [
        2.3936,
        -9.143709571428571
    ],
    [
        2.4039,
        -7.461623952380951
    ],
    [
        2.4143,
        -12.395888504761905
    ],
    [
        2.4246,
        -13.547160461904763
    ],
    [
        2.435,
        -15.356390795238099
    ],
    [
        2.4454,
        -15.356447233333334
    ],
    [
        2.4557,
        -16.06133007619048
    ],
    [
        2.4661,
        -16.06133007619048
    ],
    [
        2.4765,
        -16.11196136190476
    ],
    [
        2.4868,
        -15.865273533333333
    ],
    [
        2.4972,
        -14.631749723809524
    ],
    [
        2.5075,
        -20.388434023809527
    ],
    [
        2.5179,
        -20.827029671428576
    ],
    [
        2.5283,
        -18.771142547619053
    ],
    [
        2.5386,
        -19.387915033333336
    ],
    [
        2.549,
        -17.743216619047622
    ],
    [
        2.5593,
        -16.591944661904762
    ],
    [
        2.5697,
        -14.653550104761909
    ],
    [
        2.5801,
        -10.424253052380955
    ],
    [
        2.5904,
        -9.601903847619049
    ],
    [
        2.6008,
        -8.121692209523811
    ],
    [
        2.6112,
        -2.570655171428572
    ],
    [
        2.6215,
        -4.2975715714285725
    ],
    [
        2.6319,
        2.281419614285715
    ],
    [
        2.6422,
        2.939265119047619
    ],
    [
        2.6526,
        -1.0079772095238089
    ],
    [
        2.663,
        -1.1724526952380945
    ],
    [
        2.6733,
        -5.401580433333333
    ],
    [
        2.6837,
        -5.949832052380952
    ],
    [
        2.694,
        -0.6363408047619051
    ],
    [
        2.7044,
        -3.1034307523809526
    ],
    [
        2.7148,
        -0.6364254619047622
    ],
    [
        2.7251,
        -0.6364677904761907
    ],
    [
        2.7355,
        5.284531152380952
    ],
    [
        2.7459,
        3.5027557238095235
    ],
    [
        2.7562,
        6.586575828571429
    ],
    [
        2.7666,
        3.7083112761904755
    ],
    [
        2.7769,
        3.379388528571429
    ],
    [
        2.7873,
        0.9123620714285704
    ],
    [
        2.7977,
        3.2619433285714274
    ],
    [
        2.808,
        2.7136917095238084
    ],
    [
        2.8184,
        6.167659966666666
    ],
    [
        2.8287,
        0.685172014285714
    ],
    [
        2.8391,
        -1.2884830142857144
    ],
    [
        2.8495,
        2.001054904761904
    ],
    [
        2.8598,
        2.0010549047619057
    ],
    [
        2.8702,
        6.386907933333333
    ],
    [
        2.8806,
        7.990533328571429
    ],
    [
        2.8909,
        12.219673161904758
    ],
    [
        2.9013,
        11.945547357142855
    ],
    [
        2.9116,
        4.544658471428572
    ],
    [
        2.922,
        4.5447431285714295
    ],
    [
        2.9324,
        7.012002390476191
    ],
    [
        2.9427,
        6.847526904761905
    ],
    [
        2.9531,
        0.9265279619047633
    ],
    [
        2.9635,
        0.48796053333333383
    ],
    [
        2.9738,
        -1.9790870857142848
    ],
    [
        2.9842,
        -1.2937537523809504
    ],
    [
        2.9945,
        0.5154145000000018
    ],
    [
        3.0049,
        0.5153933380952389
    ],
    [
        3.0153,
        -1.6775566952380931
    ],
    [
        3.0256,
        1.3377425428571452
    ],
    [
        3.036,
        -2.774031709523808
    ],
    [
        3.0463,
        2.708456242857145
    ],
    [
        3.0567,
        4.682077404761906
    ],
    [
        3.0671,
        -1.2390400571428541
    ],
    [
        3.0774,
        -1.074536352380951
    ],
    [
        3.0878,
        0.2960550619047642
    ],
    [
        3.0982,
        -1.0256123619047595
    ],
    [
        3.1085,
        -0.32074161428571174
    ],
    [
        3.1189,
        0.5016358095238106
    ],
    [
        3.1292,
        1.3239003571428591
    ],
    [
        3.1396,
        5.024514114285715
    ],
    [
        3.15,
        -0.896569485714285
    ],
    [
        3.1603,
        -1.554471428571428
    ],
    [
        3.1707,
        -1.115885185714285
    ],
    [
        3.181,
        -1.115919047619048
    ],
    [
        3.1914,
        -3.5829666666666666
    ],
    [
        3.2018,
        -3.034733861904761
    ],
    [
        3.2121,
        -6.488515871428571
    ],
    [
        3.2225,
        -6.24184497142857
    ],
    [
        3.2329,
        -8.16064097142857
    ],
    [
        3.2432,
        -11.076242704761905
    ],
    [
        3.2536,
        -8.444747819047617
    ],
    [
        3.2639,
        -14.36583141904762
    ],
    [
        3.2743,
        -13.872404961904763
    ],
    [
        3.2847,
        -10.418419776190476
    ],
    [
        3.295,
        -10.089480095238097
    ],
    [
        3.3054,
        -11.89868220952381
    ],
    [
        3.3158,
        -13.660834890476192
    ],
    [
        3.3261,
        -14.894358700000002
    ],
    [
        3.3365,
        -17.361575633333334
    ],
    [
        3.3468,
        -14.072178804761904
    ],
    [
        3.3572,
        -14.072178804761904
    ],
    [
        3.3676,
        -13.533898476190478
    ],
    [
        3.3779,
        -13.122709761904762
    ],
    [
        3.3883,
        -13.808000766666666
    ],
    [
        3.3986,
        -14.465846271428571
    ],
    [
        3.409,
        -12.985617699999997
    ],
    [
        3.4194,
        -12.985617699999997
    ],
    [
        3.4297,
        -11.34103216190476
    ],
    [
        3.4401,
        -11.998877666666667
    ],
    [
        3.4505,
        -4.59773480952381
    ],
    [
        3.4608,
        0.784914542857143
    ],
    [
        3.4712,
        -0.6131270809523799
    ],
    [
        3.4815,
        0.3736919666666673
    ],
    [
        3.4919,
        2.0183903809523818
    ],
    [
        3.5023,
        -1.4355609428571432
    ],
    [
        3.5126,
        -3.4092836952380954
    ],
    [
        3.523,
        -4.889478404761904
    ],
    [
        3.5333,
        -3.039213852380952
    ],
    [
        3.5437,
        -3.4503884571428567
    ],
    [
        3.5541,
        -1.8055207285714285
    ],
    [
        3.5644,
        -1.8054642904761908
    ],
    [
        3.5748,
        -4.889358471428571
    ],
    [
        3.5852,
        -3.4540007047619037
    ],
    [
        3.5955,
        -3.8651894190476193
    ],
    [
        3.6059,
        -3.618484657142857
    ],
    [
        3.6162,
        -3.207310052380952
    ],
    [
        3.6266,
        -2.2204910047619046
    ],
    [
        3.637,
        -2.925385942857143
    ],
    [
        3.6473,
        2.831086719047619
    ],
    [
        3.6577,
        3.7709143857142857
    ],
    [
        3.6681,
        -1.1631808523809521
    ],
    [
        3.6784,
        -6.802146838095237
    ],
    [
        3.6888,
        -8.035656538095239
    ],
    [
        3.6991,
        -9.022509447619049
    ],
    [
        3.7095,
        -10.667207861904762
    ],
    [
        3.7199,
        -7.213341195238097
    ],
    [
        3.7302,
        -5.294441723809525
    ],
    [
        3.7406,
        -0.3602618285714277
    ],
    [
        3.7509,
        -0.12302454761904723
    ],
    [
        3.7613,
        3.1664287190476195
    ],
    [
        3.7717,
        -0.9453173142857134
    ],
    [
        3.782,
        -5.057204442857143
    ],
    [
        3.7924,
        -5.262791742857143
    ],
    [
        3.8028,
        -11.183875342857144
    ],
    [
        3.8131,
        -10.361469695238096
    ],
    [
        3.8235,
        -6.907603028571428
    ],
    [
        3.8338,
        -3.2070316
    ],
    [
        3.8442,
        -3.6555857142857144
    ],
    [
        3.8546,
        -3.7730399809523814
    ],
    [
        3.8649,
        -13.641230457142859
    ],
    [
        3.8753,
        -14.581114561904764
    ],
    [
        3.8856,
        -13.347633080952383
    ],
    [
        3.896,
        -13.091331838095243
    ],
    [
        3.9064,
        -11.446633423809526
    ],
    [
        3.9167,
        -15.393875752380954
    ],
    [
        3.9271,
        -17.03868703809524
    ],
    [
        3.9375,
        -21.972697619047622
    ],
    [
        3.9478,
        -21.424464814285717
    ],
    [
        3.9582,
        -24.878483861904765
    ],
    [
        3.9685,
        -24.498938076190484
    ],
    [
        3.9789,
        -26.848587866666673
    ],
    [
        3.9893,
        -22.407919085714287
    ],
    [
        3.9996,
        -18.9539339
    ],
    [
        4.01,
        -20.598632314285712
    ],
    [
        4.0203,
        -14.677582576190476
    ],
    [
        4.0307,
        -14.348659828571423
    ],
    [
        4.0411,
        -15.582183638095238
    ],
    [
        4.0514,
        -18.357612209523808
    ],
    [
        4.0618,
        -16.264359680952378
    ],
    [
        4.0722,
        -15.442010476190474
    ],
    [
        4.0825,
        -11.330264442857143
    ],
    [
        4.0929,
        -10.919033399999996
    ],
    [
        4.1032,
        -17.086610119047617
    ],
    [
        4.1136,
        -17.460418038095234
    ],
    [
        4.124,
        -17.460418038095234
    ],
    [
        4.1343,
        -17.460418038095234
    ],
    [
        4.1447,
        -16.309033209523808
    ],
    [
        4.1551,
        -16.309033209523808
    ],
    [
        4.1654,
        -14.664278357142853
    ],
    [
        4.1758,
        -14.91095772380952
    ],
    [
        4.1861,
        -15.359511838095235
    ],
    [
        4.1965,
        -13.667763990476189
    ],
    [
        4.2069,
        -13.996686738095237
    ],
    [
        4.2172,
        -12.516492028571427
    ],
    [
        4.2276,
        -10.666185152380953
    ],
    [
        4.2379,
        -13.297725190476193
    ],
    [
        4.2483,
        -7.870372809523813
    ],
    [
        4.2587,
        -9.432858876190474
    ],
    [
        4.269,
        -7.890996442857142
    ],
    [
        4.2794,
        -9.535694857142857
    ],
    [
        4.2898,
        -10.152456761904762
    ],
    [
        4.3001,
        -9.330107557142858
    ],
    [
        4.3105,
        -3.162657823809526
    ],
    [
        4.3208,
        1.1546543476190465
    ],
    [
        4.3312,
        2.9638508190476167
    ],
    [
        4.3416,
        2.5152967047619024
    ],
    [
        4.3519,
        8.15426269047619
    ],
    [
        4.3623,
        8.199119642857141
    ],
    [
        4.3726,
        15.600177842857141
    ],
    [
        4.383,
        13.338661085714286
    ],
    [
        4.3934,
        13.338703414285714
    ],
    [
        4.4037,
        15.431955942857144
    ],
    [
        4.4141,
        13.211494566666667
    ],
    [
        4.4245,
        12.80031996190476
    ],
    [
        4.4348,
        7.866224723809523
    ],
    [
        4.4452,
        7.6605880428571425
    ],
    [
        4.4555,
        4.371247657142858
    ],
    [
        4.4659,
        -2.20743311904762
    ],
    [
        4.4763,
        0.5884074428571409
    ],
    [
        4.4866,
        -1.2618994333333349
    ],
    [
        4.497,
        1.2050635285714268
    ],
    [
        4.5073,
        -0.6453068428571452
    ],
    [
        4.5177,
        -1.632092028571431
    ],
    [
        4.5281,
        -9.032980914285712
    ],
    [
        4.5384,
        -10.883330123809523
    ],
    [
        4.5488,
        -16.804752347619047
    ],
    [
        4.5592,
        -17.34298341904762
    ],
    [
        4.5695,
        -20.514986442857143
    ],
    [
        4.5799,
        -20.683157680952384
    ],
    [
        4.5902,
        -20.683157680952384
    ],
    [
        4.6006,
        -20.88882963809524
    ],
    [
        4.611,
        -18.832984842857144
    ],
    [
        4.6213,
        -21.300032461904763
    ],
    [
        4.6317,
        -18.42161270952381
    ],
    [
        4.6421,
        -17.736302890476193
    ],
    [
        4.6524,
        -15.817506890476192
    ],
    [
        4.6628,
        -16.639771442857146
    ],
    [
        4.6731,
        -16.640110066666672
    ],
    [
        4.6835,
        -17.462402833333336
    ],
    [
        4.6939,
        -18.284582723809525
    ],
    [
        4.7042,
        -18.21599013333334
    ],
    [
        4.7146,
        -19.038198242857145
    ],
    [
        4.7249,
        -18.215764380952383
    ],
    [
        4.7353,
        -18.0513284
    ],
    [
        4.7457,
        -15.584365438095233
    ],
    [
        4.756,
        -13.822103909523811
    ],
    [
        4.7664,
        -13.821934595238096
    ],
    [
        4.7768,
        -7.901054171428574
    ],
    [
        4.7871,
        -6.420740942857141
    ],
    [
        4.7975,
        -6.352251819047619
    ],
    [
        4.8078,
        -10.29952800952381
    ],
    [
        4.8182,
        -9.477094147619049
    ],
    [
        4.8286,
        -11.00428588095238
    ],
    [
        4.8389,
        -7.714889057142859
    ],
    [
        4.8493,
        -9.907970771428571
    ],
    [
        4.8596,
        -10.182077766666666
    ],
    [
        4.87,
        -7.989071295238094
    ],
    [
        4.8804,
        -6.344429319047617
    ],
    [
        4.8907,
        -2.1149145809523797
    ],
    [
        4.9011,
        -0.6346860095238094
    ],
    [
        4.9115,
        -4.746432042857141
    ],
    [
        4.9218,
        0.7358960000000015
    ],
    [
        4.9322,
        -1.7312362761904747
    ],
    [
        4.9425,
        -5.020802414285713
    ],
    [
        4.9529,
        0.7356420285714281
    ],
    [
        4.9633,
        -1.7314902476190484
    ],
    [
        4.9736,
        -5.9607147380952386
    ],
    [
        4.984,
        -2.0130999238095235
    ],
    [
        4.9943,
        -7.769544366666667
    ],
    [
        5.0047,
        -1.8486300809523801
    ],
    [
        5.0151,
        -2.1227558857142848
    ],
    [
        5.0254,
        -1.8760511238095237
    ],
    [
        5.0358,
        -2.6984003285714286
    ],
    [
        5.0462,
        -6.927624819047621
    ],
    [
        5.0565,
        -9.942905242857142
    ],
    [
        5.0669,
        -9.394634814285714
    ],
    [
        5.0772,
        -9.805837638095237
    ],
    [
        5.0876,
        -8.983544871428572
    ],
    [
        5.098,
        -9.600306776190477
    ],
    [
        5.1083,
        -10.540198938095239
    ],
    [
        5.1187,
        -9.55337989047619
    ],
    [
        5.1291,
        -7.908681476190476
    ],
    [
        5.1394,
        -14.487475128571429
    ],
    [
        5.1498,
        -14.898663842857145
    ],
    [
        5.1601,
        -7.4973516714285715
    ],
    [
        5.1705,
        -13.253796114285716
    ],
    [
        5.1809,
        -9.553140028571427
    ],
    [
        5.1912,
        -5.323963914285715
    ],
    [
        5.2016,
        -6.804192485714286
    ],
    [
        5.2119,
        -3.5147956619047624
    ],
    [
        5.2223,
        -10.915938519047618
    ],
    [
        5.2327,
        -10.798407657142857
    ],
    [
        5.243,
        -16.966365328571424
    ],
    [
        5.2534,
        -17.788827409523808
    ],
    [
        5.2638,
        -13.559554542857143
    ],
    [
        5.2741,
        -13.716192485714286
    ],
    [
        5.2845,
        -11.24906020952381
    ],
    [
        5.2948,
        -11.660234814285712
    ],
    [
        5.3052,
        -14.949688076190476
    ],
    [
        5.3156,
        -19.266682785714288
    ],
    [
        5.3259,
        -19.266682785714288
    ],
    [
        5.3363,
        -21.733645747619047
    ],
    [
        5.3466,
        -22.14486268095238
    ],
    [
        5.357,
        -18.033116647619046
    ],
    [
        5.3674,
        -18.444305361904764
    ],
    [
        5.3777,
        -25.84544821904762
    ],
    [
        5.3881,
        -26.667797428571426
    ],
    [
        5.3985,
        -29.545963214285713
    ],
    [
        5.4088,
        -31.308176366666668
    ],
    [
        5.4192,
        -29.252317461904763
    ],
    [
        5.4295,
        -30.897015876190476
    ],
    [
        5.4399,
        -29.41685502857143
    ],
    [
        5.4503,
        -33.64576508095239
    ],
    [
        5.4606,
        -30.356142504761902
    ],
    [
        5.471,
        -29.94485502857143
    ],
    [
        5.4814,
        -30.47351413809524
    ],
    [
        5.4917,
        -34.702907942857145
    ],
    [
        5.5021,
        -35.52528536666667
    ],
    [
        5.5124,
        -34.70293615714286
    ],
    [
        5.5228,
        -34.86734956190477
    ],
    [
        5.5332,
        -27.466629985714288
    ],
    [
        5.5435,
        -26.80867160952381
    ],
    [
        5.5539,
        -22.696953795238095
    ],
    [
        5.5642,
        -21.956763319047617
    ],
    [
        5.5746,
        -26.068622223809523
    ],
    [
        5.585,
        -26.890914990476194
    ],
    [
        5.5953,
        -21.956819752380955
    ],
    [
        5.6057,
        -18.667366485714286
    ],
    [
        5.6161,
        -17.471278623809525
    ],
    [
        5.6264,
        -15.004146347619047
    ],
    [
        5.6368,
        -19.527052871428573
    ],
    [
        5.6471,
        -20.657783028571426
    ],
    [
        5.6575,
        -18.966001319047617
    ],
    [
        5.6679,
        -14.525671161904759
    ],
    [
        5.6782,
        -11.236161461904759
    ],
    [
        5.6886,
        -12.469769928571427
    ],
    [
        5.6989,
        -11.684842561904762
    ],
    [
        5.7093,
        -5.763758961904762
    ],
    [
        5.7197,
        -8.395332861904762
    ],
    [
        5.73,
        -8.189745561904763
    ],
    [
        5.7404,
        -7.202926514285714
    ],
    [
        5.7508,
        -10.374760223809524
    ],
    [
        5.7611,
        -8.56555810952381
    ],
    [
        5.7715,
        -10.210312961904762
    ],
    [
        5.7818,
        -10.421808047619047
    ],
    [
        5.7922,
        -10.010520571428572
    ],
    [
        5.8026,
        -8.776996761904762
    ],
    [
        5.8129,
        -8.776996761904762
    ],
    [
        5.8233,
        -11.655360080952383
    ],
    [
        5.8336,
        -6.272710728571429
    ],
    [
        5.844,
        -6.7661541142857144
    ],
    [
        5.8544,
        -1.8320588761904764
    ],
    [
        5.8647,
        -3.9905562285714278
    ],
    [
        5.8751,
        -3.8730455238095245
    ],
    [
        5.8855,
        -3.9278518714285724
    ],
    [
        5.8958,
        -3.105671980952382
    ],
    [
        5.9062,
        -1.3434588285714275
    ],
    [
        5.9165,
        -3.3619523333333334
    ],
    [
        5.9269,
        -5.335658152380952
    ],
    [
        5.9373,
        -9.282934342857143
    ],
    [
        5.9476,
        -3.7320771999999995
    ],
    [
        5.958,
        -5.376775614285714
    ],
    [
        5.9683,
        -5.658723914285716
    ],
    [
        5.9787,
        -1.5473165047619037
    ],
    [
        5.9891,
        -1.5473165047619037
    ],
    [
        5.9994,
        -5.776540995238096
    ],
    [
        6.0098,
        -5.036426709523811
    ],
    [
        6.0202,
        1.131192338095239
    ],
    [
        6.0305,
        0.5829595333333343
    ],
    [
        6.0409,
        6.751001861904761
    ],
    [
        6.0512,
        -0.6501409952380948
    ],
    [
        6.0616,
        -0.7049304142857141
    ],
    [
        6.072,
        -2.3497416999999996
    ],
    [
        6.0823,
        1.1039556523809515
    ],
    [
        6.0927,
        -0.9519738000000013
    ],
    [
        6.1031,
        -1.3905600428571439
    ],
    [
        6.1134,
        -6.324485966666668
    ],
    [
        6.1238,
        -6.441916047619049
    ],
    [
        6.1341,
        -5.619566842857143
    ],
    [
        6.1445,
        -2.987981657142857
    ],
    [
        6.1549,
        0.7125051142857136
    ],
    [
        6.1652,
        -9.155685361904762
    ],
    [
        6.1756,
        -9.978034566666665
    ],
    [
        6.1859,
        -10.224823985714284
    ],
    [
        6.1963,
        -15.607134714285715
    ],
    [
        6.2067,
        -10.673039476190478
    ],
    [
        6.217,
        -10.673039476190477
    ],
    [
        6.2274,
        -11.166449
    ],
    [
        6.2378,
        -11.16611037619048
    ],
    [
        6.2481,
        -11.111287095238097
    ],
    [
        6.2585,
        -14.812281804761907
    ],
    [
        6.2688,
        -17.27966804761905
    ],
    [
        6.2792,
        -17.718186566666667
    ],
    [
        6.2896,
        -21.008147766666667
    ],
    [
        6.2999,
        -22.699837561904765
    ],
    [
        6.3103,
        -21.466313752380955
    ],
    [
        6.3206,
        -20.479494704761905
    ],
    [
        6.331,
        -18.834796290476188
    ],
    [
        6.3414,
        -22.94654232380952
    ],
    [
        6.3517,
        -21.30184390952381
    ],
    [
        6.3621,
        -25.413420628571423
    ],
    [
        6.3725,
        -25.41342062857143
    ],
    [
        6.3828,
        -19.65691974761905
    ],
    [
        6.3932,
        -18.423311280952383
    ],
    [
        6.4035,
        -18.834626976190478
    ],
    [
        6.4139,
        -18.79721924761905
    ],
    [
        6.4243,
        -23.183044057142858
    ],
    [
        6.4346,
        -19.071298023809526
    ],
    [
        6.445,
        -18.632711780952384
    ],
    [
        6.4554,
        -21.09992870952381
    ],
    [
        6.4657,
        -25.5402758
    ],
    [
        6.4761,
        -29.240931885714286
    ],
    [
        6.4864,
        -25.95119643809524
    ],
    [
        6.4968,
        -25.78679431904762
    ],
    [
        6.5072,
        -15.9179265952381
    ],
    [
        6.5175,
        -16.857754261904763
    ],
    [
        6.5279,
        -18.50245267619048
    ],
    [
        6.5382,
        -19.32485831904762
    ],
    [
        6.5486,
        -23.436604352380954
    ],
    [
        6.559,
        -16.857810699999998
    ],
    [
        6.5693,
        -17.84469747142857
    ],
    [
        6.5797,
        -15.99458107142857
    ],
    [
        6.5901,
        -14.31241849047619
    ],
    [
        6.6004,
        -15.514369919047619
    ],
    [
        6.6108,
        -15.267749814285716
    ],
    [
        6.6211,
        -16.089873271428573
    ],
    [
        6.6315,
        -20.612822123809526
    ],
    [
        6.6419,
        -22.39470101904762
    ],
    [
        6.6522,
        -22.805875623809523
    ],
    [
        6.6626,
        -24.724690438095237
    ],
    [
        6.6729,
        -28.014256576190476
    ],
    [
        6.6833,
        -20.61345234285714
    ],
    [
        6.6937,
        -18.96864105238095
    ],
    [
        6.704,
        -14.856895019047622
    ],
    [
        6.7144,
        -14.739416561904761
    ],
    [
        6.7248,
        -20.495861004761906
    ],
    [
        6.7351,
        -18.85116259047619
    ],
    [
        6.7455,
        -14.73941655714286
    ],
    [
        6.7558,
        -14.533772819047623
    ],
    [
        6.7662,
        -4.665582342857145
    ],
    [
        6.7766,
        -1.3761855190476207
    ],
    [
        6.7869,
        -2.856346366666665
    ],
    [
        6.7973,
        -3.226403509523808
    ],
    [
        6.8077,
        1.2594762380952365
    ],
    [
        6.818,
        1.0222601190476202
    ],
    [
        6.8284,
        2.99589821428571
    ],
    [
        6.8387,
        5.051771228571428
    ],
    [
        6.8491,
        14.919961704761905
    ],
    [
        6.8595,
        14.508871757142856
    ],
    [
        6.8698,
        10.807961704761905
    ],
    [
        6.8802,
        8.34125270952381
    ],
    [
        6.8905,
        6.696554295238094
    ],
    [
        6.9009,
        2.5846953904761882
    ],
    [
        6.9113,
        2.5847518285714273
    ],
    [
        6.9216,
        5.0517994476190475
    ],
    [
        6.932,
        5.308116080952379
    ],
    [
        6.9424,
        6.623908671428571
    ],
    [
        6.9527,
        4.979210257142857
    ],
    [
        6.9631,
        1.6898134333333306
    ],
    [
        6.9734,
        -0.1604722809523808
    ],
    [
        6.9838,
        -6.0813865666666675
    ],
    [
        6.9942,
        -11.837831009523809
    ],
    [
        7.0045,
        -6.903735771428571
    ],
    [
        7.0149,
        -8.38396434285714
    ],
    [
        7.0252,
        -13.935160109523812
    ],
    [
        7.0356,
        -18.252493442857137
    ],
    [
        7.046,
        -19.787545295238093
    ],
    [
        7.0563,
        -18.67145156666667
    ],
    [
        7.0667,
        -26.072594423809527
    ],
    [
        7.0771,
        -25.250245214285716
    ],
    [
        7.0874,
        -25.25024521428571
    ],
    [
        7.0978,
        -20.316488599999996
    ],
    [
        7.1081,
        -19.08296479047619
    ],
    [
        7.1185,
        -15.79345509047619
    ],
    [
        7.1289,
        -15.279486838095234
    ],
    [
        7.1392,
        -17.746534457142854
    ],
    [
        7.1496,
        -18.53150415238095
    ],
    [
        7.1599,
        -14.090852299999998
    ],
    [
        7.1703,
        -14.090852299999998
    ],
    [
        7.1807,
        -13.761912619047617
    ],
    [
        7.191,
        -12.528473466666664
    ],
    [
        7.2014,
        -10.554835371428572
    ],
    [
        7.2118,
        -11.37718458095238
    ],
    [
        7.2221,
        -16.311279819047616
    ],
    [
        7.2325,
        -14.666637842857142
    ],
    [
        7.2428,
        -18.983971176190476
    ],
    [
        7.2532,
        -16.516923557142857
    ],
    [
        7.2636,
        -17.20221456190476
    ],
    [
        7.2739,
        -22.84122892380952
    ],
    [
        7.2843,
        -21.36100035238095
    ],
    [
        7.2947,
        -18.07160352857143
    ],
    [
        7.305,
        -14.370693476190477
    ],
    [
        7.3154,
        -16.837741095238094
    ],
    [
        7.3257,
        -16.591104057142857
    ],
    [
        7.3361,
        -23.16989770952381
    ],
    [
        7.3465,
        -23.566387504761902
    ],
    [
        7.3568,
        -26.033435123809525
    ],
    [
        7.3672,
        -25.622260519047618
    ],
    [
        7.3775,
        -25.622260519047618
    ],
    [
        7.3879,
        -26.44460972380952
    ],
    [
        7.3983,
        -30.885295438095238
    ],
    [
        7.4086,
        -24.717591733333332
    ],
    [
        7.419,
        -27.349109195238093
    ],
    [
        7.4294,
        -26.526759985714286
    ],
    [
        7.4397,
        -25.704410780952383
    ],
    [
        7.4501,
        -29.816100376190477
    ],
    [
        7.4604,
        -26.115528947619048
    ],
    [
        7.4708,
        -23.64848132857143
    ],
    [
        7.4812,
        -23.119876642857143
    ],
    [
        7.4915,
        -19.83047981904762
    ],
    [
        7.5019,
        -16.376613152380955
    ],
    [
        7.5122,
        -20.488359185714287
    ],
    [
        7.5226,
        -20.077184580952387
    ],
    [
        7.533,
        -15.965438547619053
    ],
    [
        7.5433,
        -19.912647014285714
    ],
    [
        7.5537,
        -15.800957419047617
    ],
    [
        7.5641,
        -16.082905719047616
    ],
    [
        7.5744,
        -16.787776466666667
    ],
    [
        7.5848,
        -20.8995225
    ],
    [
        7.5951,
        -27.06722620476191
    ],
    [
        7.6055,
        -25.21694049047619
    ],
    [
        7.6159,
        -18.63814683809524
    ],
    [
        7.6262,
        -21.105194457142858
    ],
    [
        7.6366,
        -20.44731509047619
    ],
    [
        7.6469,
        -20.447371528571427
    ],
    [
        7.6573,
        -20.036112266666667
    ],
    [
        7.6677,
        -13.868493219047618
    ],
    [
        7.678,
        -7.700874171428571
    ],
    [
        7.6884,
        -10.16792179047619
    ],
    [
        7.6988,
        -10.0798069
    ],
    [
        7.7091,
        -7.201584676190477
    ],
    [
        7.7195,
        -10.117186409523807
    ],
    [
        7.7298,
        -3.5380541333333366
    ],
    [
        7.7402,
        -3.702456252380954
    ],
    [
        7.7506,
        -10.281588528571428
    ],
    [
        7.7609,
        -6.581017100000004
    ],
    [
        7.7713,
        -6.992135266666669
    ],
    [
        7.7816,
        -6.992135266666667
    ],
    [
        7.792,
        -5.30044547142857
    ],
    [
        7.8024,
        -1.1886994380952385
    ],
    [
        7.8127,
        -0.6600100952380951
    ],
    [
        7.8231,
        2.423799428571429
    ],
    [
        7.8335,
        0.16233910952381092
    ],
    [
        7.8438,
        2.629386728571429
    ],
    [
        7.8542,
        2.3826819666666665
    ],
    [
        7.8645,
        2.656817180952381
    ],
    [
        7.8749,
        4.595127080952382
    ],
    [
        7.8853,
        0.8943863380952399
    ],
    [
        7.8956,
        -4.039708900000001
    ],
    [
        7.906,
        -2.277532028571428
    ],
    [
        7.9164,
        -2.6475468428571425
    ],
    [
        7.9267,
        -8.815165890476191
    ],
    [
        7.9371,
        -13.300707014285715
    ],
    [
        7.9474,
        -19.05743364285714
    ],
    [
        7.9578,
        -19.715493604761907
    ],
    [
        7.9682,
        -13.13613558095238
    ],
    [
        7.9785,
        -13.547310185714283
    ],
    [
        7.9889,
        -17.247881614285713
    ],
    [
        7.9992,
        -16.261062566666663
    ],
    [
        8.0096,
        -17.864685847619047
    ],
    [
        8.02,
        -17.864685847619047
    ],
    [
        8.0303,
        -22.093861961904757
    ],
    [
        8.0407,
        -25.177713814285706
    ],
    [
        8.0511,
        -24.5609519095238
    ],
    [
        8.0614,
        -29.495047147619044
    ],
    [
        8.0718,
        -30.728570957142853
    ],
    [
        8.0821,
        -28.53571499047619
    ],
    [
        8.0925,
        -29.734029123809528
    ],
    [
        8.1029,
        -28.25363123809524
    ],
    [
        8.1132,
        -30.720509542857144
    ],
    [
        8.1236,
        -31.660450080952376
    ],
    [
        8.1339,
        -28.206583414285713
    ],
    [
        8.1443,
        -18.33839293809524
    ],
    [
        8.1547,
        -13.756757261904768
    ],
    [
        8.165,
        -8.000369257142863
    ],
    [
        8.1754,
        -8.000369257142859
    ],
    [
        8.1858,
        -10.632112466666673
    ],
    [
        8.1961,
        -10.632112466666669
    ],
    [
        8.2065,
        -4.464408761904762
    ],
    [
        8.2168,
        -9.398165376190477
    ],
    [
        8.2272,
        -9.329593947619045
    ],
    [
        8.2376,
        -8.507244742857143
    ],
    [
        8.2479,
        -6.04002780952381
    ],
    [
        8.2583,
        -6.656832042857143
    ],
    [
        8.2687,
        -11.590927280952382
    ],
    [
        8.279,
        -12.413220047619049
    ],
    [
        8.2894,
        -8.301361142857145
    ],
    [
        8.2997,
        -10.932765728571429
    ],
    [
        8.3101,
        -12.084133628571427
    ],
    [
        8.3205,
        -16.031409819047617
    ],
    [
        8.3308,
        -14.269353885714285
    ],
    [
        8.3412,
        -12.6245426
    ],
    [
        8.3515,
        -16.325114028571427
    ],
    [
        8.3619,
        -22.081502033333336
    ],
    [
        8.3723,
        -26.66347633333334
    ],
    [
        8.3826,
        -29.13035463809523
    ],
    [
        8.393,
        -28.718982504761904
    ],
    [
        8.4034,
        -26.745208961904762
    ],
    [
        8.4137,
        -25.71723012380953
    ],
    [
        8.4241,
        -28.184447057142865
    ],
    [
        8.4344,
        -24.895388857142855
    ],
    [
        8.4448,
        -19.413399438095237
    ],
    [
        8.4552,
        -20.400286209523806
    ],
    [
        8.4655,
        -17.933407904761903
    ],
    [
        8.4759,
        -19.166847057142856
    ],
    [
        8.4862,
        -15.877563104761908
    ],
    [
        8.4966,
        -16.28870949047619
    ],
    [
        8.507,
        -18.481791204761905
    ],
    [
        8.5173,
        -16.79010140952381
    ],
    [
        8.5277,
        -16.789875661904762
    ],
    [
        8.5381,
        -12.404050852380951
    ],
    [
        8.5484,
        -10.465620014285712
    ],
    [
        8.5588,
        -7.998572395238093
    ],
    [
        8.5691,
        -8.409746999999998
    ],
    [
        8.5795,
        -8.409803438095237
    ],
    [
        8.5899,
        -4.462256347619046
    ],
    [
        8.6002,
        -6.929473280952381
    ],
    [
        8.6106,
        -7.340845414285713
    ],
    [
        8.621,
        -8.944561814285713
    ],
    [
        8.6313,
        -7.505493033333333
    ],
    [
        8.6417,
        -6.76529409047619
    ],
    [
        8.652,
        -4.133708904761904
    ],
    [
        8.6624,
        -14.001222133333334
    ],
    [
        8.6728,
        -13.462920257142855
    ],
    [
        8.6831,
        -12.476168933333332
    ],
    [
        8.6935,
        -12.476168933333335
    ],
    [
        8.7038,
        -11.653763290476192
    ],
    [
        8.7142,
        -10.42032413809524
    ],
    [
        8.7246,
        -11.105662176190478
    ],
    [
        8.7349,
        -13.044056733333337
    ],
    [
        8.7453,
        -13.866518814285715
    ],
    [
        8.7557,
        -14.14065402857143
    ],
    [
        8.766,
        -13.72956408095238
    ],
    [
        8.7764,
        -21.13036831428571
    ],
    [
        8.7867,
        -20.190589023809522
    ],
    [
        8.7971,
        -21.01282535714286
    ],
    [
        8.8075,
        -20.025938585714286
    ],
    [
        8.8178,
        -21.670749871428573
    ],
    [
        8.8282,
        -17.558890966666667
    ],
    [
        8.8385,
        -18.58682747619048
    ],
    [
        8.8489,
        -20.642785147619048
    ],
    [
        8.8593,
        -22.61625392857143
    ],
    [
        8.8696,
        -24.836833823809528
    ],
    [
        8.88,
        -24.837172447619054
    ],
    [
        8.8904,
        -26.033285966666675
    ],
    [
        8.9007,
        -28.006924061904773
    ],
    [
        8.9111,
        -30.473633057142862
    ],
    [
        8.9214,
        -30.638114185714294
    ],
    [
        8.9318,
        -32.28269972380953
    ],
    [
        8.9422,
        -32.03591030476191
    ],
    [
        8.9525,
        -28.33516956190477
    ],
    [
        8.9629,
        -26.69041470952381
    ],
    [
        8.9732,
        -20.93391382857143
    ],
    [
        8.9836,
        -23.812220709523807
    ],
    [
        8.994,
        -19.7008133
    ],
    [
        9.0043,
        -18.009075128571432
    ],
    [
        9.0147,
        -17.35130863809524
    ],
    [
        9.0251,
        -17.968112871428577
    ],
    [
        9.0354,
        -18.79034920476191
    ],
    [
        9.0458,
        -21.25739682380953
    ],
    [
        9.0561,
        -21.25728394761905
    ],
    [
        9.0665,
        -21.25736860476191
    ],
    [
        9.0769,
        -19.49531267142858
    ],
    [
        9.0872,
        -15.794571928571433
    ],
    [
        9.0976,
        -11.565395814285715
    ],
    [
        9.108,
        -8.68717359047619
    ],
    [
        9.1183,
        -12.634382057142856
    ],
    [
        9.1287,
        -8.317345019047618
    ],
    [
        9.139,
        -9.79767518095238
    ],
    [
        9.1494,
        -6.50839122857143
    ],
    [
        9.1598,
        -7.988789114285716
    ],
    [
        9.1701,
        -11.278355252380951
    ],
    [
        9.1805,
        -15.3898191
    ],
    [
        9.1908,
        -22.791131271428576
    ],
    [
        9.2012,
        -22.17428470952381
    ],
    [
        9.2116,
        -16.417840266666673
    ],
    [
        9.2219,
        -18.391478361904767
    ],
    [
        9.2323,
        -12.470564076190483
    ],
    [
        9.2427,
        -12.840646614285717
    ],
    [
        9.253,
        -8.728900580952384
    ],
    [
        9.2634,
        -9.177454695238099
    ],
    [
        9.2737,
        -5.888283619047622
    ],
    [
        9.2841,
        -5.476883261904762
    ],
    [
        9.2945,
        -9.707172
    ],
    [
        9.3048,
        -12.790939195238098
    ],
    [
        9.3152,
        -11.381149323809527
    ],
    [
        9.3255,
        -13.319543880952386
    ],
    [
        9.3359,
        -9.618972452380952
    ],
    [
        9.3463,
        -9.707075247619045
    ],
    [
        9.3566,
        -9.707244557142857
    ],
    [
        9.367,
        -8.884895352380951
    ],
    [
        9.3774,
        -7.0344826523809525
    ],
    [
        9.3877,
        -8.679011752380953
    ],
    [
        9.3981,
        -1.2780382047619043
    ],
    [
        9.4084,
        -1.2780382047619039
    ],
    [
        9.4188,
        -1.8947154523809515
    ],
    [
        9.4292,
        -8.473621976190477
    ],
    [
        9.4395,
        -5.84221739047619
    ],
    [
        9.4499,
        -11.598605395238096
    ],
    [
        9.4602,
        -13.079274180952382
    ],
    [
        9.4706,
        -16.368558133333334
    ],
    [
        9.481,
        -10.98590878095238
    ],
    [
        9.4913,
        -14.275192733333334
    ],
    [
        9.5017,
        -14.686423776190479
    ],
    [
        9.5121,
        -10.738063990476192
    ],
    [
        9.5224,
        -5.186571928571427
    ],
    [
        9.5328,
        -6.71379188095238
    ],
    [
        9.5431,
        -6.995672457142855
    ],
    [
        9.5535,
        -4.939743004761904
    ],
    [
        9.5639,
        -4.646123457142856
    ],
    [
        9.5742,
        -7.113171076190476
    ],
    [
        9.5846,
        -14.51397530952381
    ],
    [
        9.5949,
        -8.962737214285713
    ],
    [
        9.6053,
        -8.14044444761905
    ],
    [
        9.6157,
        -11.594480428571428
    ],
    [
        9.626,
        -7.893570371428573
    ],
    [
        9.6364,
        -7.482423985714286
    ],
    [
        9.6468,
        -6.454374604761905
    ],
    [
        9.6571,
        -8.921365785714286
    ],
    [
        9.6675,
        -7.482311114285714
    ],
    [
        9.6778,
        -7.4818031761904775
    ],
    [
        9.6882,
        -6.5421367619047635
    ],
    [
        9.6986,
        -12.298581204761904
    ],
    [
        9.7089,
        -12.298581204761904
    ],
    [
        9.7193,
        -6.131300780952381
    ],
    [
        9.7297,
        -6.789360742857142
    ],
    [
        9.74,
        -11.72413322857143
    ],
    [
        9.7504,
        -11.606606395238096
    ],
    [
        9.7607,
        -8.15263813809524
    ],
    [
        9.7711,
        -13.908800395238096
    ],
    [
        9.7815,
        -14.73112138095238
    ],
    [
        9.7918,
        -10.783506566666667
    ],
    [
        9.8022,
        -6.398020380952382
    ],
    [
        9.8125,
        -10.345635195238094
    ],
    [
        9.8229,
        -9.249320085714285
    ],
    [
        9.8333,
        -6.617915499999999
    ],
    [
        9.8436,
        -8.344984285714284
    ],
    [
        9.854,
        -6.28908305238095
    ],
    [
        9.8644,
        -8.13953808095238
    ],
    [
        9.8747,
        -7.9339366714285715
    ],
    [
        9.8851,
        -7.317259423809523
    ],
    [
        9.8954,
        -6.49502309047619
    ],
    [
        9.9058,
        -7.434915252380953
    ],
    [
        9.9162,
        -4.967980509523808
    ],
    [
        9.9265,
        -3.8167254809523787
    ],
    [
        9.9369,
        -9.737233419047616
    ],
    [
        9.9472,
        -8.092422133333333
    ],
    [
        9.9576,
        -10.559639066666666
    ],
    [
        9.968,
        -8.867997647619047
    ],
    [
        9.9783,
        -11.951976485714285
    ],
    [
        9.9887,
        -7.0182198714285695
    ],
    [
        9.9991,
        -6.771582833333333
    ],
    [
        10.0094,
        -7.018202938095236
    ],
    [
        10.0198,
        -6.469932509523808
    ],
    [
        10.0301,
        -8.690360023809525
    ],
    [
        10.0405,
        -5.675211290476189
    ],
    [
        10.0509,
        -7.868029633333335
    ],
    [
        10.0612,
        -9.841701590476191
    ],
    [
        10.0716,
        -12.308918523809526
    ],
    [
        10.082,
        -7.374653971428573
    ],
    [
        10.0923,
        -7.991458204761906
    ],
    [
        10.1027,
        -6.758019052380955
    ],
    [
        10.113,
        -5.7299273428571444
    ],
    [
        10.1234,
        -2.440304771428571
    ],
    [
        10.1338,
        -5.318498776190478
    ],
    [
        10.1441,
        -5.441774966666669
    ],
    [
        10.1545,
        0.47941021904761627
    ],
    [
        10.1648,
        -0.7541982476190503
    ],
    [
        10.1752,
        -3.22073792857143
    ],
    [
        10.1856,
        -9.141990838095237
    ],
    [
        10.1959,
        -9.23014805714286
    ],
    [
        10.2063,
        -7.996708904761905
    ],
    [
        10.2167,
        -7.389367919047619
    ],
    [
        10.227,
        -4.5111174809523815
    ],
    [
        10.2374,
        -5.215988228571429
    ],
    [
        10.2477,
        -1.515332142857143
    ],
    [
        10.2581,
        -4.475653833333333
    ],
    [
        10.2685,
        -3.927421028571428
    ],
    [
        10.2788,
        3.473383204761904
    ],
    [
        10.2892,
        3.7201048999999995
    ],
    [
        10.2995,
        2.897868566666667
    ],
    [
        10.3099,
        3.5832818523809506
    ],
    [
        10.3203,
        2.1032225952380954
    ],
    [
        10.3306,
        2.171709366666665
    ],
    [
        10.341,
        -0.7066257285714295
    ],
    [
        10.3514,
        -4.407451123809524
    ],
    [
        10.3617,
        -1.323514614285715
    ],
    [
        10.3721,
        -7.244699799999999
    ],
    [
        10.3824,
        -10.944847947619047
    ],
    [
        10.3928,
        -6.716010457142856
    ],
    [
        10.4032,
        -1.0766574714285717
    ],
    [
        10.4135,
        -0.7241979095238101
    ],
    [
        10.4239,
        -2.574356638095239
    ],
    [
        10.4342,
        -0.961371700000001
    ],
    [
        10.4446,
        -0.9613717000000012
    ],
    [
        10.455,
        -0.2565009523809536
    ],
    [
        10.4653,
        -3.4285160714285716
    ],
    [
        10.4757,
        -3.7573654523809523
    ],
    [
        10.4861,
        -4.579714657142857
    ],
    [
        10.4964,
        -8.279862804761903
    ],
    [
        10.5068,
        -8.93756157142857
    ],
    [
        10.5171,
        -8.93756157142857
    ],
    [
        10.5275,
        -9.211772033333332
    ],
    [
        10.5379,
        -11.185342404761903
    ],
    [
        10.5482,
        -11.34193197142857
    ],
    [
        10.5586,
        -8.463596876190476
    ],
    [
        10.569,
        -3.5291630142857153
    ],
    [
        10.5793,
        -6.304739738095241
    ],
    [
        10.5897,
        -4.701184180952382
    ],
    [
        10.6,
        -0.7541788904761907
    ],
    [
        10.6104,
        -1.2827835761904767
    ],
    [
        10.6208,
        -1.9877027000000003
    ],
    [
        10.6311,
        -4.102460071428571
    ],
    [
        10.6415,
        -4.190562866666666
    ],
    [
        10.6518,
        -10.357758633333335
    ],
    [
        10.6622,
        -14.469786852380954
    ],
    [
        10.6726,
        -16.93666515714286
    ],
    [
        10.6829,
        -21.166131519047617
    ],
    [
        10.6933,
        -20.343838752380954
    ],
    [
        10.7037,
        -19.932635933333337
    ],
    [
        10.714,
        -20.179425352380957
    ],
    [
        10.7244,
        -18.946070857142853
    ],
    [
        10.7347,
        -20.590882142857144
    ],
    [
        10.7451,
        -14.834719885714286
    ],
    [
        10.7555,
        -14.670374204761902
    ],
    [
        10.7658,
        -18.899647071428568
    ],
    [
        10.7762,
        -23.011336661904764
    ],
    [
        10.7865,
        -22.02458533809524
    ],
    [
        10.7969,
        -22.164872014285713
    ],
    [
        10.8073,
        -24.426261785714285
    ],
    [
        10.8176,
        -28.37326707619048
    ],
    [
        10.828,
        -32.07417712857143
    ],
    [
        10.8384,
        -32.07417712857143
    ],
    [
        10.8487,
        -29.606960195238095
    ],
    [
        10.8591,
        -29.31334064761905
    ],
    [
        10.8694,
        -22.7349984952381
    ],
    [
        10.8798,
        -21.254600609523806
    ],
    [
        10.8902,
        -19.774609076190476
    ],
    [
        10.9005,
        -13.195363928571428
    ],
    [
        10.9109,
        -13.606538533333335
    ],
    [
        10.9212,
        -11.550693733333334
    ],
    [
        10.9316,
        -15.004729714285716
    ],
    [
        10.942,
        -16.23808420952381
    ],
    [
        10.9523,
        -17.882669747619047
    ],
    [
        10.9627,
        -27.751537471428573
    ],
    [
        10.9731,
        -27.751424595238095
    ],
    [
        10.9834,
        -20.350451052380954
    ],
    [
        10.9938,
        -18.50037698095238
    ],
    [
        11.0041,
        -14.553033066666668
    ],
    [
        11.0145,
        -14.926735804761908
    ],
    [
        11.0249,
        -10.814594709523812
    ],
    [
        11.0352,
        -8.3477164
    ],
    [
        11.0456,
        -8.347039152380953
    ],
    [
        11.056,
        -9.3338582
    ],
    [
        11.0663,
        -9.3338582
    ],
    [
        11.0767,
        -8.922655376190477
    ],
    [
        11.087,
        -10.567240914285716
    ],
    [
        11.0974,
        -10.40282750952381
    ],
    [
        11.1078,
        -10.12076310952381
    ],
    [
        11.1181,
        -12.470396766666667
    ],
    [
        11.1285,
        -13.7042592
    ],
    [
        11.1388,
        -14.526664847619045
    ],
    [
        11.1492,
        -12.4705943
    ],
    [
        11.1596,
        -12.470820047619048
    ],
    [
        11.1699,
        -12.059758319047619
    ],
    [
        11.1803,
        -2.1905519714285706
    ],
    [
        11.1907,
        1.098957728571429
    ],
    [
        11.201,
        -2.0727913238095232
    ],
    [
        11.2114,
        -2.160809461904761
    ],
    [
        11.2217,
        -4.62768776666667
    ],
    [
        11.2321,
        -3.805338561904763
    ],
    [
        11.2425,
        -6.8896983523809565
    ],
    [
        11.2528,
        -6.889698352380955
    ],
    [
        11.2632,
        -3.1897195142857138
    ],
    [
        11.2735,
        -0.5583149285714281
    ],
    [
        11.2839,
        -1.106510109523809
    ],
    [
        11.2943,
        1.361045447619048
    ],
    [
        11.3046,
        -1.1058328571428566
    ],
    [
        11.315,
        -2.750305519047618
    ],
    [
        11.3254,
        -6.97991700952381
    ],
    [
        11.3357,
        -6.979965385714285
    ],
    [
        11.3461,
        -4.512522704761905
    ],
    [
        11.3564,
        -5.883048276190477
    ],
    [
        11.3668,
        -0.5378066523809535
    ],
    [
        11.3772,
        0.6136289714285725
    ],
    [
        11.3875,
        1.8471527809523816
    ],
    [
        11.3979,
        -5.554159390476189
    ],
    [
        11.4083,
        -12.955810185714286
    ],
    [
        11.4186,
        -13.23769076190476
    ],
    [
        11.429,
        -11.29925992380952
    ],
    [
        11.4393,
        -8.832381619047615
    ],
    [
        11.4497,
        -9.819132942857138
    ],
    [
        11.4601,
        -11.66971495238095
    ],
    [
        11.4704,
        -10.025185852380949
    ],
    [
        11.4808,
        -8.791408076190475
    ],
    [
        11.4911,
        -11.66960208095238
    ],
    [
        11.5015,
        -12.354930709523806
    ],
    [
        11.5119,
        -15.809373038095234
    ],
    [
        11.5222,
        -14.575933885714283
    ],
    [
        11.5326,
        -7.174621719047617
    ],
    [
        11.543,
        -4.707066161904762
    ],
    [
        11.5533,
        -5.6467809523809525
    ],
    [
        11.5637,
        -4.001969661904762
    ],
    [
        11.574,
        -5.098548142857143
    ],
    [
        11.5844,
        -11.266082533333334
    ],
    [
        11.5948,
        -13.239923804761904
    ],
    [
        11.6051,
        -14.473447614285714
    ],
    [
        11.6155,
        -12.622950257142858
    ],
    [
        11.6258,
        -12.623288880952382
    ],
    [
        11.6362,
        -11.636537557142859
    ],
    [
        11.6466,
        -13.574968395238097
    ],
    [
        11.6569,
        -17.80414450952381
    ],
    [
        11.6673,
        -17.80414450952381
    ],
    [
        11.6777,
        -15.885028695238097
    ],
    [
        11.688,
        -19.997113352380953
    ],
    [
        11.6984,
        -22.46399165714286
    ],
    [
        11.7087,
        -21.847187423809526
    ],
    [
        11.7191,
        -19.79137084761905
    ],
    [
        11.7295,
        -19.253068971428572
    ],
    [
        11.7398,
        -15.552412885714286
    ],
    [
        11.7502,
        -20.980222409523808
    ],
    [
        11.7605,
        -16.86853281904762
    ],
    [
        11.7709,
        -16.45747109047619
    ],
    [
        11.7813,
        -16.457809719047617
    ],
    [
        11.7916,
        -15.517965933333333
    ],
    [
        11.802,
        -19.218622019047615
    ],
    [
        11.8124,
        -17.984844238095235
    ],
    [
        11.8227,
        -19.218537361904765
    ],
    [
        11.8331,
        -16.95711937142857
    ],
    [
        11.8434,
        -12.570617314285714
    ],
    [
        11.8538,
        -12.570617314285714
    ],
    [
        11.8642,
        -12.688200585714284
    ],
    [
        11.8745,
        -10.926241404761905
    ],
    [
        11.8849,
        -11.172929233333335
    ],
    [
        11.8953,
        -11.858267271428574
    ],
    [
        11.9056,
        -11.858267271428572
    ],
    [
        11.916,
        -10.0081932
    ],
    [
        11.9263,
        -9.391388966666666
    ],
    [
        11.9367,
        -10.918504104761904
    ],
    [
        11.9471,
        -9.853199633333334
    ],
    [
        11.9574,
        -13.142935085714285
    ],
    [
        11.9678,
        -7.71580280952381
    ],
    [
        11.9781,
        -9.360614095238097
    ],
    [
        11.9885,
        -8.127090285714283
    ],
    [
        11.9989,
        -11.580855361904762
    ],
    [
        12.0092,
        -11.492607438095238
    ],
    [
        12.0196,
        -9.025729133333332
    ],
    [
        12.03,
        -8.340560409523809
    ],
    [
        12.0403,
        -5.379967814285712
    ],
    [
        12.0507,
        -7.518109614285715
    ],
    [
        12.061,
        -11.903595800000002
    ],
    [
        12.0714,
        -11.903595800000002
    ],
    [
        12.0818,
        -9.436378871428573
    ],
    [
        12.0921,
        -12.45145235714286
    ],
    [
        12.1025,
        -12.20489997619048
    ],
    [
        12.1128,
        -13.438169814285716
    ],
    [
        12.1232,
        -9.73751372857143
    ],
    [
        12.1336,
        -4.186952880952382
    ],
    [
        12.1439,
        -4.8917752523809535
    ],
    [
        12.1543,
        -9.121386742857144
    ],
    [
        12.1647,
        -12.204984623809523
    ],
    [
        12.175,
        -12.616074571428571
    ],
    [
        12.1854,
        -18.783439652380952
    ],
    [
        12.1957,
        -20.42825093809524
    ],
    [
        12.2061,
        -22.072836476190478
    ],
    [
        12.2165,
        -16.152193090476192
    ],
    [
        12.2268,
        -16.152277747619046
    ],
    [
        12.2372,
        -12.698174042857143
    ],
    [
        12.2475,
        -10.916464461904765
    ],
    [
        12.2579,
        -11.762299685714286
    ],
    [
        12.2683,
        -16.202612914285716
    ],
    [
        12.2786,
        -11.81705148095238
    ],
    [
        12.289,
        -13.050829257142855
    ],
    [
        12.2994,
        -13.051055004761906
    ],
    [
        12.3097,
        -14.14767110952381
    ],
    [
        12.3201,
        -8.227027723809524
    ],
    [
        12.3304,
        -6.308419847619045
    ],
    [
        12.3408,
        -10.008398685714283
    ],
    [
        12.3512,
        -16.175763766666666
    ],
    [
        12.3615,
        -13.00406309047619
    ],
    [
        12.3719,
        -8.774548352380952
    ],
    [
        12.3823,
        -1.3739134333333316
    ],
    [
        12.3926,
        -0.8452240904761897
    ],
    [
        12.403,
        -4.546049485714282
    ],
    [
        12.4133,
        -2.9012381999999985
    ],
    [
        12.4237,
        -3.723869595238093
    ],
    [
        12.4341,
        -9.644377533333332
    ],
    [
        12.4444,
        -9.027573299999998
    ],
    [
        12.4548,
        -11.248068538095238
    ],
    [
        12.4651,
        -14.126318976190475
    ],
    [
        12.4755,
        -12.129273876190476
    ],
    [
        12.4859,
        -8.017810028571425
    ],
    [
        12.4962,
        -9.11442613333333
    ],
    [
        12.5066,
        -7.880648357142853
    ],
    [
        12.517,
        -10.512120666666663
    ],
    [
        12.5273,
        -3.9328755190476166
    ],
    [
        12.5377,
        -10.100071285714286
    ],
    [
        12.548,
        -9.962947242857144
    ],
    [
        12.5584,
        -4.324271504761903
    ],
    [
        12.5688,
        -4.735474323809522
    ],
    [
        12.5791,
        -7.202352628571425
    ],
    [
        12.5895,
        -7.731041971428569
    ],
    [
        12.5998,
        -13.898237738095236
    ],
    [
        12.6102,
        -18.127752476190473
    ],
    [
        12.6206,
        -14.015724257142855
    ],
    [
        12.6309,
        -14.015724257142855
    ],
    [
        12.6413,
        -12.781946476190475
    ],
    [
        12.6517,
        -13.028803619047618
    ],
    [
        12.662,
        -17.96323748095238
    ],
    [
        12.6724,
        -17.962898857142857
    ],
    [
        12.6827,
        -17.963124609523806
    ],
    [
        12.6931,
        -14.673276285714284
    ],
    [
        12.7035,
        -15.08442267142857
    ],
    [
        12.7138,
        -15.906489690476192
    ],
    [
        12.7242,
        -16.355074585714288
    ],
    [
        12.7346,
        -12.90097088095238
    ],
    [
        12.7449,
        -12.901648128571427
    ],
    [
        12.7553,
        -12.655095747619047
    ],
    [
        12.7656,
        -13.066242128571426
    ],
    [
        12.776,
        -12.784409928571424
    ],
    [
        12.7864,
        -11.756402876190473
    ],
    [
        12.7967,
        -12.578808519047614
    ],
    [
        12.8071,
        -12.5786392047619
    ],
    [
        12.8174,
        -11.345200052380951
    ],
    [
        12.8278,
        -5.793961957142857
    ],
    [
        12.8382,
        -5.351076052380951
    ],
    [
        12.8485,
        -10.285509914285713
    ],
    [
        12.8589,
        -9.052070761904762
    ],
    [
        12.8693,
        -8.523284666666667
    ],
    [
        12.8796,
        -6.055729109523808
    ],
    [
        12.89,
        -7.289676199999998
    ],
    [
        12.9003,
        -0.710205299999999
    ],
    [
        12.9107,
        -6.093162490476191
    ],
    [
        12.9211,
        -4.154804214285714
    ],
    [
        12.9314,
        -2.3046454857142855
    ],
    [
        12.9418,
        -0.21124930476190482
    ],
    [
        12.9521,
        -3.383385361904761
    ],
    [
        12.9625,
        -9.96195326190476
    ],
    [
        12.9729,
        -11.441944795238097
    ],
    [
        12.9832,
        -12.264237566666663
    ],
    [
        12.9936,
        -15.718205823809523
    ],
    [
        13.004,
        -15.718121166666668
    ],
    [
        13.0143,
        -9.96128166190476
    ],
    [
        13.0247,
        -10.372653795238095
    ],
    [
        13.035,
        -9.139214642857143
    ],
    [
        13.0454,
        -10.460841247619046
    ],
    [
        13.0558,
        -12.548538442857142
    ],
    [
        13.0661,
        -8.23099347142857
    ],
    [
        13.0765,
        -9.87557900952381
    ],
    [
        13.0868,
        -8.348463871428573
    ],
    [
        13.0972,
        -6.8684723380952395
    ],
    [
        13.1076,
        -1.9343771000000016
    ],
    [
        13.1179,
        -7.4170813904761905
    ],
    [
        13.1283,
        -5.734949595238096
    ],
    [
        13.1387,
        -7.673307871428572
    ],
    [
        13.149,
        -9.5234666
    ],
    [
        13.1594,
        -12.81308917142857
    ],
    [
        13.1697,
        -17.04192666190476
    ],
    [
        13.1801,
        -16.01391960952381
    ],
    [
        13.1905,
        -14.533928076190477
    ],
    [
        13.2008,
        -12.889342538095239
    ],
    [
        13.2112,
        -15.35588221904762
    ],
    [
        13.2216,
        -16.38397392857143
    ],
    [
        13.2319,
        -22.963219076190477
    ],
    [
        13.2423,
        -23.7852860952381
    ],
    [
        13.2526,
        -26.663536533333335
    ],
    [
        13.263,
        -27.6032997
    ],
    [
        13.2734,
        -27.603299700000008
    ],
    [
        13.2837,
        -28.219849961904764
    ],
    [
        13.2941,
        -24.10770886666667
    ],
    [
        13.3044,
        -25.752294400000004
    ],
    [
        13.3148,
        -25.998846780952384
    ],
    [
        13.3252,
        -26.820913800000003
    ],
    [
        13.3355,
        -26.82083855238096
    ],
    [
        13.3459,
        -28.876852661904763
    ],
    [
        13.3563,
        -25.176196576190478
    ],
    [
        13.3666,
        -22.70931827142858
    ],
    [
        13.377,
        -21.681226561904765
    ],
    [
        13.3873,
        -17.980909099999998
    ],
    [
        13.3977,
        -17.36410486666666
    ],
    [
        13.4081,
        -18.844096399999994
    ],
    [
        13.4184,
        -13.910339785714282
    ],
    [
        13.4288,
        -9.387785995238096
    ],
    [
        13.4391,
        -9.38756024761905
    ],
    [
        13.4495,
        -8.447668085714287
    ],
    [
        13.4599,
        -4.747181314285715
    ],
    [
        13.4702,
        -5.569248333333333
    ],
    [
        13.4806,
        -5.56947408095238
    ],
    [
        13.491,
        -3.92466279047619
    ],
    [
        13.5013,
        -5.158440571428571
    ],
    [
        13.5117,
        -5.981410590476191
    ],
    [
        13.522,
        -6.803929109523809
    ],
    [
        13.5324,
        -5.981692780952379
    ],
    [
        13.5428,
        -5.981918528571428
    ],
    [
        13.5531,
        -10.36747996190476
    ],
    [
        13.5635,
        -12.012629876190475
    ],
    [
        13.5738,
        -16.947063742857143
    ],
    [
        13.5842,
        -17.76924363809524
    ],
    [
        13.5946,
        -18.79733534761905
    ],
    [
        13.6049,
        -19.208481733333336
    ],
    [
        13.6153,
        -24.142238347619053
    ],
    [
        13.6257,
        -22.168871152380955
    ],
    [
        13.636,
        -28.33606691904762
    ],
    [
        13.6464,
        -25.457985790476197
    ],
    [
        13.6567,
        -24.80028702380953
    ],
    [
        13.6671,
        -29.02912451428572
    ],
    [
        13.6775,
        -31.24961975238096
    ],
    [
        13.6878,
        -30.427552733333332
    ],
    [
        13.6982,
        -27.549132980952376
    ],
    [
        13.7086,
        -27.274922519047614
    ],
    [
        13.7189,
        -19.873948971428565
    ],
    [
        13.7293,
        -22.50555673333333
    ],
    [
        13.7396,
        -18.393867142857136
    ],
    [
        13.75,
        -18.43118764761904
    ],
    [
        13.7604,
        -20.07577318571428
    ],
    [
        13.7707,
        -20.076450433333328
    ],
    [
        13.7811,
        -18.842334028571425
    ],
    [
        13.7914,
        -18.019928380952383
    ],
    [
        13.8018,
        -18.842559771428576
    ],
    [
        13.8122,
        -17.902570857142862
    ],
    [
        13.8225,
        -13.790768385714287
    ],
    [
        13.8329,
        -9.679304538095236
    ],
    [
        13.8433,
        -10.419401895238096
    ],
    [
        13.8536,
        -6.719084438095238
    ],
    [
        13.864,
        -7.540925704761903
    ],
    [
        13.8743,
        -7.787478085714285
    ],
    [
        13.8847,
        -3.4704410476190453
    ],
    [
        13.8951,
        -2.483554276190474
    ],
    [
        13.9054,
        -2.0723514571428567
    ],
    [
        13.9158,
        -3.3059599238095223
    ],
    [
        13.9261,
        -4.402576028571428
    ],
    [
        13.9365,
        -9.336332642857142
    ],
    [
        13.9469,
        -8.966029995238094
    ],
    [
        13.9572,
        -6.498474438095237
    ],
    [
        13.9676,
        -8.517168042857142
    ],
    [
        13.978,
        -10.984046347619048
    ],
    [
        13.9883,
        -6.666332061904761
    ],
    [
        13.9987,
        -3.788081623809523
    ],
    [
        14.009,
        -4.610825895238095
    ],
    [
        14.0194,
        -3.5142097904761904
    ],
    [
        14.0298,
        -1.9869817761904762
    ],
    [
        14.0401,
        -5.276491476190477
    ],
    [
        14.0505,
        -5.687637861904762
    ],
    [
        14.0608,
        -5.687637861904761
    ],
    [
        14.0712,
        -9.388124633333335
    ],
    [
        14.0816,
        -6.0994050571428575
    ],
    [
        14.0919,
        -6.510777190476191
    ],
    [
        14.1023,
        -7.127666080952384
    ],
    [
        14.1127,
        -9.594544385714288
    ],
    [
        14.123,
        -9.347822690476189
    ],
    [
        14.1334,
        -10.581431157142857
    ],
    [
        14.1437,
        -10.170059023809525
    ],
    [
        14.1541,
        -11.814644561904762
    ],
    [
        14.1645,
        -16.132358847619045
    ],
    [
        14.1748,
        -21.68359694285714
    ],
    [
        14.1852,
        -18.511460885714285
    ],
    [
        14.1956,
        -17.031469352380952
    ],
    [
        14.2059,
        -13.94787147142857
    ],
    [
        14.2163,
        -16.003829142857143
    ],
    [
        14.2266,
        -14.15299316190476
    ],
    [
        14.237,
        -13.60479798095238
    ],
    [
        14.2474,
        -11.960325319047618
    ],
    [
        14.2577,
        -12.782618090476191
    ],
    [
        14.2681,
        -16.4836128
    ],
    [
        14.2784,
        -16.89458987142857
    ],
    [
        14.2888,
        -15.660981404761904
    ],
    [
        14.2992,
        -21.043322914285714
    ],
    [
        14.3095,
        -20.103333999999997
    ],
    [
        14.3199,
        -18.253005957142857
    ],
    [
        14.3303,
        -16.773014423809524
    ],
    [
        14.3406,
        -13.318910719047617
    ],
    [
        14.351,
        -7.1515456380952385
    ],
    [
        14.3613,
        -6.466301666666667
    ],
    [
        14.3717,
        -6.055324595238095
    ],
    [
        14.3821,
        -2.1077775047619047
    ],
    [
        14.3924,
        3.442783342857144
    ],
    [
        14.4028,
        1.5042557523809534
    ],
    [
        14.4131,
        0.02392559047619309
    ],
    [
        14.4235,
        2.4914811476190484
    ],
    [
        14.4339,
        2.902627533333334
    ],
    [
        14.4442,
        3.2290862952380954
    ],
    [
        14.4546,
        8.163520157142857
    ],
    [
        14.465,
        5.696641852380952
    ],
    [
        14.4753,
        2.4067935285714284
    ],
    [
        14.4857,
        2.4074707761904737
    ],
    [
        14.496,
        2.8186171619047595
    ],
    [
        14.5064,
        1.8315949380952357
    ],
    [
        14.5168,
        4.747058138095238
    ],
    [
        14.5271,
        4.835160933333333
    ],
    [
        14.5375,
        3.6016371238095237
    ],
    [
        14.5479,
        6.233109433333333
    ],
    [
        14.5582,
        3.2725168380952385
    ],
    [
        14.5686,
        -4.128795333333334
    ],
    [
        14.5789,
        -1.936014614285713
    ],
    [
        14.5893,
        0.5310330047619037
    ],
    [
        14.5997,
        0.901115542857142
    ],
    [
        14.61,
        -4.64936064761905
    ],
    [
        14.6204,
        -8.350186042857143
    ],
    [
        14.6307,
        -6.8698558809523815
    ],
    [
        14.6411,
        -13.448875280952382
    ],
    [
        14.6515,
        -18.383309142857144
    ],
    [
        14.6618,
        -19.73763386666667
    ],
    [
        14.6722,
        -23.438459261904757
    ],
    [
        14.6826,
        -25.08327054761904
    ],
    [
        14.6929,
        -20.64221234761905
    ],
    [
        14.7033,
        -16.325259966666668
    ],
    [
        14.7136,
        -14.104934042857142
    ],
    [
        14.724,
        -13.611287480952383
    ],
    [
        14.7344,
        -15.091279014285718
    ],
    [
        14.7447,
        -15.46144620952381
    ],
    [
        14.7551,
        -16.07808112857143
    ],
    [
        14.7654,
        -18.956275133333335
    ],
    [
        14.7758,
        -18.462899471428575
    ],
    [
        14.7862,
        -20.92977777619048
    ],
    [
        14.7965,
        -23.80797178095238
    ],
    [
        14.8069,
        -26.685996471428574
    ],
    [
        14.8173,
        -27.713777776190476
    ],
    [
        14.8276,
        -28.741869485714286
    ],
    [
        14.838,
        -25.45224690952381
    ],
    [
        14.8483,
        -22.820548852380952
    ],
    [
        14.8587,
        -22.820548852380952
    ],
    [
        14.8691,
        -18.708520633333332
    ],
    [
        14.8794,
        -17.063935095238097
    ],
    [
        14.8898,
        -20.764421866666666
    ],
    [
        14.9002,
        -20.106632804761905
    ],
    [
        14.9105,
        -20.43554990952381
    ],
    [
        14.9209,
        -18.831994352380953
    ],
    [
        14.9312,
        -20.805767895238098
    ],
    [
        14.9416,
        -21.134797876190476
    ],
    [
        14.952,
        -24.588901580952385
    ],
    [
        14.9623,
        -25.24660034761905
    ],
    [
        14.9727,
        -24.50660458095238
    ],
    [
        14.983,
        -23.272996114285718
    ],
    [
        14.9934,
        -24.25988288571429
    ],
    [
        15.0038,
        -19.325449023809526
    ],
    [
        15.0141,
        -16.44702927142857
    ],
    [
        15.0245,
        -15.419247966666665
    ],
    [
        15.0349,
        -8.840228566666665
    ],
    [
        15.0452,
        -7.195417280952383
    ],
    [
        15.0556,
        -7.195191533333333
    ],
    [
        15.0659,
        -9.826889590476194
    ],
    [
        15.0763,
        -9.169190823809524
    ],
    [
        15.0867,
        -8.34678518095238
    ],
    [
        15.097,
        -13.281219042857142
    ],
    [
        15.1074,
        -13.281219042857144
    ],
    [
        15.1177,
        -12.999212690476194
    ],
    [
        15.1281,
        -13.821279714285717
    ],
    [
        15.1385,
        -15.356376719047622
    ],
    [
        15.1488,
        -12.724904409523813
    ],
    [
        15.1592,
        -12.724791533333335
    ],
    [
        15.1696,
        -11.902385885714285
    ],
    [
        15.1799,
        -7.790583414285713
    ],
    [
        15.1903,
        -8.0019624
    ],
    [
        15.2006,
        -10.468840704761904
    ],
    [
        15.211,
        -11.126765219047618
    ],
    [
        15.2214,
        -11.949057985714283
    ],
    [
        15.2317,
        -14.210673504761903
    ],
    [
        15.2421,
        -18.52830313333333
    ],
    [
        15.2525,
        -20.995858690476194
    ],
    [
        15.2628,
        -18.528641757142857
    ],
    [
        15.2732,
        -19.35093452380952
    ],
    [
        15.2835,
        -18.775250576190473
    ],
    [
        15.2939,
        -22.72279766666666
    ],
    [
        15.3043,
        -25.189675971428567
    ],
    [
        15.3146,
        -22.72245904285714
    ],
    [
        15.325,
        -21.90016627619048
    ],
    [
        15.3353,
        -22.83996169047619
    ],
    [
        15.3457,
        -21.195601900000003
    ],
    [
        15.3561,
        -21.88086468571429
    ],
    [
        15.3664,
        -24.512201547619053
    ],
    [
        15.3768,
        -24.512314423809524
    ],
    [
        15.3872,
        -20.40062483333333
    ],
    [
        15.3975,
        -23.85472853809524
    ],
    [
        15.4079,
        -24.794717452380958
    ],
    [
        15.4182,
        -22.32783914761905
    ],
    [
        15.4286,
        -19.038442323809523
    ],
    [
        15.439,
        -18.921165428571427
    ],
    [
        15.4493,
        -23.238117809523807
    ],
    [
        15.4597,
        -20.770900880952382
    ],
    [
        15.47,
        -24.882364723809523
    ],
    [
        15.4804,
        -27.897852085714288
    ],
    [
        15.4908,
        -30.364730395238098
    ],
    [
        15.5011,
        -30.775820338095237
    ],
    [
        15.5115,
        -20.906275366666666
    ],
    [
        15.5219,
        -19.26168982857143
    ],
    [
        15.5322,
        -19.261351200000004
    ],
    [
        15.5426,
        -18.32168478571429
    ],
    [
        15.5529,
        -11.74311688571429
    ],
    [
        15.5633,
        -12.976725352380956
    ],
    [
        15.5737,
        -11.743116885714286
    ],
    [
        15.584,
        -11.743116885714286
    ],
    [
        15.5944,
        -12.15431970952381
    ],
    [
        15.6047,
        -16.2660093
    ],
    [
        15.6151,
        -10.345365914285717
    ],
    [
        15.6255,
        -11.167771561904763
    ],
    [
        15.6358,
        -11.661214947619047
    ],
    [
        15.6462,
        -14.53923963809524
    ],
    [
        15.6566,
        -8.900467152380955
    ],
    [
        15.6669,
        -8.90046715238095
    ],
    [
        15.6773,
        -7.255768738095236
    ],
    [
        15.6876,
        -10.544939814285712
    ],
    [
        15.698,
        -10.61338896190476
    ],
    [
        15.7084,
        -7.324669385714285
    ],
    [
        15.7187,
        -5.515625295238094
    ],
    [
        15.7291,
        -11.273142047619048
    ],
    [
        15.7395,
        -12.095660566666666
    ],
    [
        15.7498,
        -10.451075028571426
    ],
    [
        15.7602,
        -4.81239929047619
    ],
    [
        15.7705,
        -10.979764371428573
    ],
    [
        15.7809,
        -11.390741442857141
    ],
    [
        15.7913,
        -13.035552728571428
    ],
    [
        15.8016,
        -16.983235266666664
    ],
    [
        15.812,
        -18.21684373333333
    ],
    [
        15.8223,
        -21.50578906190476
    ],
    [
        15.8327,
        -31.373302290476193
    ],
    [
        15.8431,
        -29.728603876190476
    ],
    [
        15.8534,
        -24.301403876190474
    ],
    [
        15.8638,
        -24.71255026190476
    ],
    [
        15.8742,
        -30.87991534285715
    ],
    [
        15.8845,
        -28.41303703333333
    ],
    [
        15.8949,
        -28.82412698095238
    ],
    [
        15.9052,
        -18.955936504761905
    ],
    [
        15.9156,
        -19.1615096952381
    ],
    [
        15.926,
        -19.161283942857143
    ],
    [
        15.9363,
        -21.134786590476192
    ],
    [
        15.9467,
        -17.845164014285714
    ],
    [
        15.957,
        -17.433791880952384
    ],
    [
        15.9674,
        -17.316418238095235
    ],
    [
        15.9778,
        -21.427882085714284
    ],
    [
        15.9881,
        -20.6877170047619
    ],
    [
        15.9985,
        -23.97621083333333
    ],
    [
        16.0089,
        -24.799293728571428
    ],
    [
        16.0192,
        -20.687265509523815
    ],
    [
        16.0296,
        -19.86440836666667
    ],
    [
        16.0399,
        -16.16442952857143
    ],
    [
        16.0503,
        -16.16375228095238
    ],
    [
        16.0607,
        -20.27634487142857
    ],
    [
        16.071,
        -30.1438581
    ],
    [
        16.0814,
        -29.204191685714292
    ],
    [
        16.0917,
        -28.51870314761905
    ],
    [
        16.1021,
        -27.038711614285717
    ],
    [
        16.1125,
        -24.160291861904764
    ],
    [
        16.1228,
        -30.0816124952381
    ],
    [
        16.1332,
        -29.259319728571434
    ],
    [
        16.1436,
        -26.627621671428578
    ],
    [
        16.1539,
        -26.345692719047623
    ],
    [
        16.1643,
        -31.280126585714292
    ],
    [
        16.1746,
        -30.66349166666667
    ],
    [
        16.185,
        -29.958572542857144
    ],
    [
        16.1954,
        -31.76761663333334
    ],
    [
        16.2057,
        -26.339739385714292
    ],
    [
        16.2161,
        -22.229065661904762
    ],
    [
        16.2265,
        -20.583464247619048
    ],
    [
        16.2368,
        -21.40586989047619
    ],
    [
        16.2472,
        -20.994949252380955
    ],
    [
        16.2575,
        -20.74805824761905
    ],
    [
        16.2679,
        -14.17061909047619
    ],
    [
        16.2783,
        -10.0580265
    ],
    [
        16.2886,
        -4.507550309523809
    ],
    [
        16.299,
        -5.036239652380952
    ],
    [
        16.3093,
        -5.721558876190476
    ],
    [
        16.3197,
        -6.3794833904761905
    ],
    [
        16.3301,
        -5.557754995238095
    ],
    [
        16.3404,
        -5.1192891523809525
    ],
    [
        16.3508,
        -2.6527494714285718
    ],
    [
        16.3612,
        -4.87324470952381
    ],
    [
        16.3715,
        -4.785141914285715
    ],
    [
        16.3819,
        -3.962736266666666
    ],
    [
        16.3922,
        -4.1682248
    ],
    [
        16.4026,
        -6.635441733333333
    ],
    [
        16.413,
        -3.181473476190477
    ],
    [
        16.4233,
        -9.760492876190474
    ],
    [
        16.4337,
        -10.1716957
    ],
    [
        16.444,
        -10.5830114
    ],
    [
        16.4544,
        -9.554919690476192
    ],
    [
        16.4648,
        -10.788697471428572
    ],
    [
        16.4751,
        -8.568202233333333
    ],
    [
        16.4855,
        -11.857147561904762
    ],
    [
        16.4959,
        -12.67944032857143
    ],
    [
        16.5062,
        -14.118565547619047
    ],
    [
        16.5166,
        -14.118565547619047
    ],
    [
        16.5269,
        -10.418586714285713
    ],
    [
        16.5373,
        -11.24099236190476
    ],
    [
        16.5477,
        -14.324251619047619
    ],
    [
        16.558,
        -18.71026455238095
    ],
    [
        16.5684,
        -19.531992947619045
    ],
    [
        16.5788,
        -19.943195766666662
    ],
    [
        16.5891,
        -20.31309206190476
    ],
    [
        16.5995,
        -18.66828077619048
    ],
    [
        16.6098,
        -19.491025047619047
    ],
    [
        16.6202,
        -17.51718377619048
    ],
    [
        16.6306,
        -17.51718377619048
    ],
    [
        16.6409,
        -16.694891004761907
    ],
    [
        16.6513,
        -16.16620166190476
    ],
    [
        16.6616,
        -16.576952985714282
    ],
    [
        16.672,
        -14.31519637142857
    ],
    [
        16.6824,
        -14.314857742857143
    ],
    [
        16.6927,
        -16.78207467619048
    ],
    [
        16.7031,
        -17.604367442857146
    ],
    [
        16.7135,
        -16.66447528095238
    ],
    [
        16.7238,
        -18.309286571428572
    ],
    [
        16.7342,
        -12.14192149047619
    ],
    [
        16.7445,
        -15.595347942857142
    ],
    [
        16.7549,
        -14.11501778095238
    ],
    [
        16.7653,
        -15.96517650952381
    ],
    [
        16.7756,
        -12.264351114285711
    ],
    [
        16.786,
        -18.843596261904764
    ],
    [
        16.7963,
        -12.26502836190476
    ],
    [
        16.8067,
        -12.265299261904758
    ],
    [
        16.8171,
        -7.3315426476190435
    ],
    [
        16.8274,
        -6.135090504761901
    ],
    [
        16.8378,
        -4.408106376190474
    ],
    [
        16.8482,
        -6.875323309523808
    ],
    [
        16.8585,
        -6.757756166666662
    ],
    [
        16.8689,
        -7.039723814285713
    ],
    [
        16.8792,
        -6.6288031761904715
    ],
    [
        16.8896,
        -8.27384021428571
    ],
    [
        16.9,
        -6.355157090476188
    ],
    [
        16.9103,
        -2.653993066666665
    ],
    [
        16.9207,
        -1.1737306333333326
    ],
    [
        16.931,
        -1.702419976190475
    ],
    [
        16.9414,
        1.9982361095238121
    ],
    [
        16.9518,
        -3.4290316142857127
    ],
    [
        16.9621,
        -2.4421448428571417
    ],
    [
        16.9725,
        -1.903781399999999
    ],
    [
        16.9829,
        3.03031383809524
    ],
    [
        16.9932,
        3.5591966809523834
    ],
    [
        17.0036,
        10.138667580952383
    ],
    [
        17.0139,
        3.560325428571429
    ],
    [
        17.0243,
        9.481646061904762
    ],
    [
        17.0347,
        3.9311698714285703
    ],
    [
        17.045,
        4.379754766666666
    ],
    [
        17.0554,
        1.5015607619047604
    ],
    [
        17.0658,
        -0.14325052380952505
    ],
    [
        17.0761,
        -1.0833361857142862
    ],
    [
        17.0865,
        -0.9188550571428576
    ],
    [
        17.0968,
        -0.5075958000000005
    ],
    [
        17.1072,
        0.19742007142857093
    ],
    [
        17.1176,
        0.12881101904762046
    ],
    [
        17.1279,
        -3.161037304761904
    ],
    [
        17.1383,
        -0.940812966666667
    ],
    [
        17.1486,
        -0.5294972666666654
    ],
    [
        17.159,
        -1.7629364190476196
    ],
    [
        17.1694,
        -2.914010847619048
    ],
    [
        17.1797,
        -0.44645529047619076
    ],
    [
        17.1901,
        -0.614566880952382
    ],
    [
        17.2005,
        -3.081783814285714
    ],
    [
        17.2108,
        -2.3770581904761903
    ],
    [
        17.2212,
        -4.727014352380954
    ],
    [
        17.2315,
        -4.7270143523809525
    ],
    [
        17.2419,
        -10.366367338095237
    ],
    [
        17.2523,
        -12.216526066666667
    ],
    [
        17.2626,
        -7.282092204761905
    ],
    [
        17.273,
        -6.624257990476191
    ],
    [
        17.2833,
        -4.979446704761905
    ],
    [
        17.2937,
        -4.979220957142857
    ],
    [
        17.3041,
        -5.8014008523809535
    ],
    [
        17.3144,
        -5.684140080952382
    ],
    [
        17.3248,
        -1.4547220904761917
    ],
    [
        17.3352,
        -1.8246691809523825
    ],
    [
        17.3455,
        -1.9892631857142877
    ],
    [
        17.3559,
        -1.5782861142857159
    ],
    [
        17.3662,
        -5.69042720952381
    ],
    [
        17.3766,
        -6.138950538095238
    ],
    [
        17.387,
        -3.877560766666667
    ],
    [
        17.3973,
        -11.278872938095239
    ],
    [
        17.4077,
        -11.110884485714287
    ],
    [
        17.418,
        -12.097500357142858
    ],
    [
        17.4284,
        -10.452689071428573
    ],
    [
        17.4388,
        -10.570062714285715
    ],
    [
        17.4491,
        -13.860588285714286
    ],
    [
        17.4595,
        -13.860394785714288
    ],
    [
        17.4699,
        -16.327950342857143
    ],
    [
        17.4802,
        -23.729262509523814
    ],
    [
        17.4906,
        -21.50890271904762
    ],
    [
        17.5009,
        -22.49578949047619
    ],
    [
        17.5113,
        -21.262406776190478
    ],
    [
        17.5217,
        -20.851429704761905
    ],
    [
        17.532,
        -15.21343121428571
    ],
    [
        17.5424,
        -21.1348873
    ],
    [
        17.5528,
        -18.503550438095235
    ],
    [
        17.5631,
        -18.339069309523808
    ],
    [
        17.5735,
        -22.45053315238095
    ],
    [
        17.5838,
        -18.33850493333333
    ],
    [
        17.5942,
        -18.594803976190473
    ],
    [
        17.6046,
        -21.678825142857143
    ],
    [
        17.6149,
        -18.389879814285717
    ],
    [
        17.6253,
        -17.941233347619047
    ],
    [
        17.6356,
        -17.9413688
    ],
    [
        17.646,
        -20.20298431904762
    ],
    [
        17.6564,
        -18.39380478095238
    ],
    [
        17.6667,
        -14.006813599999997
    ],
    [
        17.6771,
        -11.657050938095237
    ],
    [
        17.6875,
        -8.367202614285713
    ],
    [
        17.6978,
        -10.83475817142857
    ],
    [
        17.7082,
        -17.00212325238095
    ],
    [
        17.7185,
        -18.482453414285715
    ],
    [
        17.7289,
        -19.71583612857143
    ],
    [
        17.7393,
        -19.187146785714287
    ],
    [
        17.7496,
        -24.120226152380955
    ],
    [
        17.76,
        -25.60042086190476
    ],
    [
        17.7703,
        -32.17876301428572
    ],
    [
        17.7807,
        -33.82391292857143
    ],
    [
        17.7911,
        -31.973669542857145
    ],
    [
        17.8014,
        -32.38487236666667
    ],
    [
        17.8118,
        -29.213171690476194
    ],
    [
        17.8222,
        -31.680388619047623
    ],
    [
        17.8325,
        -31.02246410476191
    ],
    [
        17.8429,
        -31.727383228571437
    ],
    [
        17.8532,
        -31.97424037142858
    ],
    [
        17.8636,
        -32.17972890476191
    ],
    [
        17.874,
        -33.871534800000006
    ],
    [
        17.8843,
        -28.389658257142866
    ],
    [
        17.8947,
        -25.100261433333344
    ],
    [
        17.905,
        -24.442562666666678
    ],
    [
        17.9154,
        -17.0412505
    ],
    [
        17.9258,
        -13.340933038095235
    ],
    [
        17.9361,
        -11.860602876190478
    ],
    [
        17.9465,
        -10.920936461904764
    ],
    [
        17.9569,
        -11.860828623809525
    ],
    [
        17.9672,
        -10.627558780952382
    ],
    [
        17.9776,
        -9.147364071428575
    ],
    [
        17.9879,
        -4.213607457142856
    ],
    [
        17.9983,
        -2.9796603666666654
    ],
    [
        18.0087,
        -7.2974593095238065
    ],
    [
        18.019,
        -6.475053666666665
    ],
    [
        18.0294,
        -7.708662128571426
    ],
    [
        18.0398,
        -5.241445199999999
    ],
    [
        18.0501,
        -5.2413097523809515
    ],
    [
        18.0605,
        -9.471501738095236
    ],
    [
        18.0708,
        -13.171649885714285
    ],
    [
        18.0812,
        -13.336243890476188
    ],
    [
        18.0916,
        -12.631324766666667
    ],
    [
        18.1019,
        -15.097864447619047
    ],
    [
        18.1123,
        -20.580493490476186
    ],
    [
        18.1226,
        -21.238418004761904
    ],
    [
        18.133,
        -22.71854498571428
    ],
    [
        18.1434,
        -22.101910066666665
    ],
    [
        18.1537,
        -26.049457157142857
    ],
    [
        18.1641,
        -30.278971895238094
    ],
    [
        18.1745,
        -31.10137754285714
    ],
    [
        18.1848,
        -33.32139870952381
    ],
    [
        18.1952,
        -33.567984952380954
    ],
    [
        18.2055,
        -34.55460082380952
    ],
    [
        18.2159,
        -32.33410558571428
    ],
    [
        18.2263,
        -29.044708761904765
    ],
    [
        18.2366,
        -29.25031017142857
    ],
    [
        18.247,
        -28.83922022380952
    ],
    [
        18.2573,
        -27.605611757142853
    ],
    [
        18.2677,
        -27.166980361904752
    ],
    [
        18.2781,
        -23.465647028571432
    ],
    [
        18.2884,
        -19.14869464761905
    ],
    [
        18.2988,
        -16.92819940952381
    ],
    [
        18.3092,
        -19.39473909047619
    ],
    [
        18.3195,
        -20.628347557142856
    ],
    [
        18.3299,
        -21.066903699999997
    ],
    [
        18.3402,
        -19.9155358
    ],
    [
        18.3506,
        -25.836043738095235
    ],
    [
        18.361,
        -30.154350614285715
    ],
    [
        18.3713,
        -26.86450229047619
    ],
    [
        18.3817,
        -26.863825042857144
    ],
    [
        18.3921,
        -25.21901375238095
    ],
    [
        18.4024,
        -19.29782856666667
    ],
    [
        18.4128,
        -18.768945723809527
    ],
    [
        18.4231,
        -18.48734572380953
    ],
    [
        18.4335,
        -18.24079334285715
    ],
    [
        18.4439,
        -17.300772176190485
    ],
    [
        18.4542,
        -11.75088857619048
    ],
    [
        18.4646,
        -15.862465300000007
    ],
    [
        18.4749,
        -17.096073766666674
    ],
    [
        18.4853,
        -18.192313628571437
    ],
    [
        18.4957,
        -17.663648476190488
    ],
    [
        18.506,
        -19.513722547619057
    ],
    [
        18.5164,
        -19.513722547619057
    ],
    [
        18.5268,
        -17.5953027952381
    ],
    [
        18.5371,
        -19.445715495238105
    ],
    [
        18.5475,
        -17.754238547619053
    ],
    [
        18.5578,
        -17.877768704761912
    ],
    [
        18.5682,
        -15.410551771428569
    ],
    [
        18.5786,
        -15.409874523809522
    ],
    [
        18.5889,
        -15.410100271428572
    ],
    [
        18.5993,
        -11.093147890476189
    ],
    [
        18.6096,
        -9.284126376190475
    ],
    [
        18.62,
        -15.452168704761908
    ],
    [
        18.6304,
        -15.734329852380954
    ],
    [
        18.6407,
        -15.02931398095238
    ],
    [
        18.6511,
        -17.0850459
    ],
    [
        18.6615,
        -16.38003002857143
    ],
    [
        18.6718,
        -22.29999616666667
    ],
    [
        18.6822,
        -21.066049076190478
    ],
    [
        18.6925,
        -13.663721033333331
    ],
    [
        18.7029,
        -12.841766885714286
    ],
    [
        18.7133,
        -13.370093414285712
    ],
    [
        18.7236,
        -12.548026395238093
    ],
    [
        18.734,
        -14.486360485714286
    ],
    [
        18.7443,
        -12.70463209047619
    ],
    [
        18.7547,
        -13.732639142857144
    ],
    [
        18.7651,
        -15.670973233333335
    ],
    [
        18.7754,
        -10.11956582857143
    ],
    [
        18.7858,
        -8.475206042857142
    ],
    [
        18.7962,
        -8.475206042857142
    ],
    [
        18.8065,
        -8.064003223809523
    ],
    [
        18.8169,
        -12.381632852380951
    ],
    [
        18.8272,
        -14.60185719047619
    ],
    [
        18.8376,
        -15.835465657142858
    ],
    [
        18.848,
        -16.08184872857143
    ],
    [
        18.8583,
        -11.147414866666667
    ],
    [
        18.8687,
        -11.147414866666665
    ],
    [
        18.8791,
        -6.212981004761905
    ],
    [
        18.8894,
        -5.930916604761904
    ],
    [
        18.8998,
        -5.520052404761903
    ],
    [
        18.9101,
        -9.63287074761905
    ],
    [
        18.9205,
        -9.427353995238098
    ],
    [
        18.9309,
        -9.427523309523808
    ],
    [
        18.9412,
        -9.427297561904762
    ],
    [
        18.9516,
        -6.2553550047619035
    ],
    [
        18.9619,
        -8.311256238095238
    ],
    [
        18.9723,
        -1.7320110904761892
    ],
    [
        18.9827,
        -1.731841776190477
    ],
    [
        18.993,
        -8.311086923809524
    ],
    [
        19.0034,
        -8.311086923809526
    ],
    [
        19.0138,
        -5.021464347619048
    ],
    [
        19.0241,
        -6.255072814285714
    ],
    [
        19.0345,
        -6.255750061904761
    ],
    [
        19.0448,
        -6.008892919047619
    ],
    [
        19.0552,
        -4.364081628571428
    ],
    [
        19.0656,
        1.8022675809523807
    ],
    [
        19.0759,
        -4.365774747619047
    ],
    [
        19.0863,
        -3.543481980952381
    ],
    [
        19.0966,
        -10.945132776190476
    ],
    [
        19.107,
        -15.174744266666668
    ],
    [
        19.1174,
        -14.763428571428573
    ],
    [
        19.1277,
        -14.880802214285714
    ],
    [
        19.1381,
        -19.19834718571429
    ],
    [
        19.1485,
        -18.413323614285716
    ],
    [
        19.1588,
        -15.535129609523809
    ],
    [
        19.1692,
        -17.015459771428567
    ],
    [
        19.1795,
        -16.641628766666663
    ],
    [
        19.1899,
        -24.043279561904757
    ],
    [
        19.2003,
        -27.744274271428566
    ],
    [
        19.2106,
        -23.632584680952377
    ],
    [
        19.221,
        -23.303780447619044
    ],
    [
        19.2313,
        -22.64585593333333
    ],
    [
        19.2417,
        -20.178638999999997
    ],
    [
        19.2521,
        -10.309094028571426
    ],
    [
        19.2624,
        -9.939146938095233
    ],
    [
        19.2728,
        -10.350180447619044
    ],
    [
        19.2832,
        -14.049651347619047
    ],
    [
        19.2935,
        -13.309418542857141
    ],
    [
        19.3039,
        -11.664607257142857
    ],
    [
        19.3142,
        -9.690765985714282
    ],
    [
        19.3246,
        -7.223549057142857
    ],
    [
        19.335,
        -5.16809931904762
    ],
    [
        19.3453,
        -6.859905214285716
    ],
    [
        19.3557,
        -2.6303904761904757
    ],
    [
        19.3661,
        -3.415583357142857
    ],
    [
        19.3764,
        -3.4157526714285718
    ],
    [
        19.3868,
        -6.8698563761904765
    ],
    [
        19.3971,
        -6.9147025523809535
    ],
    [
        19.4075,
        -3.9540422333333334
    ],
    [
        19.4179,
        0.48701596666666636
    ],
    [
        19.4282,
        -2.684878209523811
    ],
    [
        19.4386,
        -3.4247723904761913
    ],
    [
        19.4489,
        2.4965482428571413
    ],
    [
        19.4593,
        0.02966993809523662
    ],
    [
        19.4697,
        -9.839875033333335
    ],
    [
        19.48,
        -10.456679266666669
    ],
    [
        19.4904,
        -14.157673976190479
    ],
    [
        19.5008,
        -16.624890909523813
    ],
    [
        19.5111,
        -17.775875038095236
    ],
    [
        19.5215,
        -21.88790325714286
    ],
    [
        19.5318,
        -19.749942057142857
    ],
    [
        19.5422,
        -17.69392794761905
    ],
    [
        19.5526,
        -14.405208371428571
    ],
    [
        19.5629,
        -8.482533238095238
    ],
    [
        19.5733,
        -12.710693480952381
    ],
    [
        19.5836,
        -12.463937928571427
    ],
    [
        19.594,
        -14.684128404761902
    ],
    [
        19.6044,
        -13.039317114285714
    ],
    [
        19.6147,
        -11.92920494285714
    ],
    [
        19.6251,
        -12.80222016190476
    ],
    [
        19.6355,
        -7.374410638095237
    ],
    [
        19.6458,
        -8.313754557142857
    ],
    [
        19.6562,
        -5.43567342857143
    ],
    [
        19.6665,
        -10.818569052380948
    ],
    [
        19.6769,
        -13.286124609523808
    ],
    [
        19.6873,
        -7.117743657142857
    ],
    [
        19.6976,
        -10.821278047619044
    ],
    [
        19.708,
        -6.239206995238094
    ],
    [
        19.7184,
        -4.183192885714285
    ],
    [
        19.7287,
        -3.2435587190476194
    ],
    [
        19.7391,
        -0.7763417857142852
    ],
    [
        19.7494,
        -3.407814095238095
    ],
    [
        19.7598,
        -1.763002804761906
    ],
    [
        19.7702,
        -7.025044428571429
    ],
    [
        19.7805,
        -7.027753423809525
    ],
    [
        19.7909,
        -4.56189099047619
    ],
    [
        19.8012,
        -4.808477233333333
    ],
    [
        19.8116,
        -6.288942842857143
    ],
    [
        19.822,
        -6.083256776190477
    ],
    [
        19.8323,
        -9.166939314285713
    ],
    [
        19.8427,
        -7.800480709523809
    ],
    [
        19.8531,
        -13.721665895238093
    ],
    [
        19.8634,
        -13.72211739047619
    ],
    [
        19.8738,
        -15.366928676190474
    ],
    [
        19.8841,
        -15.535101833333332
    ],
    [
        19.8945,
        -11.587825642857142
    ],
    [
        19.9049,
        -14.465455276190474
    ],
    [
        19.9152,
        -4.593201309523809
    ],
    [
        19.9256,
        -9.174595114285713
    ],
    [
        19.9359,
        -9.997000757142857
    ],
    [
        19.9463,
        -10.11445502857143
    ],
    [
        19.9567,
        -12.581671961904764
    ],
    [
        19.967,
        -11.59478519047619
    ],
    [
        19.9774,
        -7.893959795238095
    ],
    [
        19.9878,
        -8.181632457142857
    ],
    [
        19.9981,
        -18.04914568571429
    ],
    [
        20.0085,
        -16.56868007619048
    ],
    [
        20.0188,
        -12.867854676190477
    ],
    [
        20.0292,
        -11.387659966666666
    ],
    [
        20.0396,
        -13.238157323809524
    ],
    [
        20.0499,
        -15.704358385714288
    ],
    [
        20.0603,
        -17.308049390476192
    ],
    [
        20.0706,
        -16.321298066666667
    ],
    [
        20.081,
        -15.293291014285717
    ],
    [
        20.0914,
        -14.306404242857147
    ],
    [
        20.1017,
        -14.923377790476192
    ],
    [
        20.1121,
        -16.403098423809528
    ],
    [
        20.1225,
        -15.992177785714286
    ],
    [
        20.1328,
        -21.91417566666667
    ],
    [
        20.1432,
        -15.335607766666666
    ],
    [
        20.1535,
        -16.569216233333332
    ],
    [
        20.1639,
        -20.681470199999996
    ],
    [
        20.1743,
        -23.148687128571428
    ],
    [
        20.1846,
        -24.135303
    ],
    [
        20.195,
        -30.303345328571428
    ],
    [
        20.2054,
        -28.042350619047614
    ],
    [
        20.2157,
        -23.556501652380952
    ],
    [
        20.2261,
        -25.036967261904763
    ],
    [
        20.2364,
        -27.504184195238093
    ],
    [
        20.2468,
        -24.04994503809523
    ],
    [
        20.2572,
        -22.569614876190478
    ],
    [
        20.2675,
        -18.870143976190473
    ],
    [
        20.2779,
        -16.608979952380952
    ],
    [
        20.2882,
        -18.25379123809524
    ],
    [
        20.2986,
        -18.085571899999998
    ],
    [
        20.309,
        -20.306067138095237
    ],
    [
        20.3193,
        -24.005538038095242
    ],
    [
        20.3297,
        -24.00587666666667
    ],
    [
        20.3401,
        -21.785686190476195
    ],
    [
        20.3504,
        -21.503718542857147
    ],
    [
        20.3608,
        -23.148529828571434
    ],
    [
        20.3711,
        -16.980487500000002
    ],
    [
        20.3815,
        -12.750779261904764
    ],
    [
        20.3919,
        -8.638751042857146
    ],
    [
        20.4022,
        -8.638886495238095
    ],
    [
        20.4126,
        -6.8570076
    ],
    [
        20.4229,
        -8.912570214285713
    ],
    [
        20.4333,
        -7.230376852380952
    ],
    [
        20.4437,
        -5.311279847619049
    ],
    [
        20.454,
        -6.2981666190476195
    ],
    [
        20.4644,
        -9.998653395238094
    ],
    [
        20.4748,
        -6.543872442857142
    ],
    [
        20.4851,
        -6.132443871428573
    ],
    [
        20.4955,
        -9.422066447619049
    ],
    [
        20.5058,
        -7.777255161904761
    ],
    [
        20.5162,
        -12.263104128571426
    ],
    [
        20.5266,
        -12.016111533333333
    ],
    [
        20.5369,
        -8.069918938095238
    ],
    [
        20.5473,
        -0.6682681428571429
    ],
    [
        20.5576,
        -0.4214109999999999
    ],
    [
        20.568,
        -1.3613031619047617
    ],
    [
        20.5784,
        -2.1837088047619044
    ],
    [
        20.5887,
        -9.5853596
    ],
    [
        20.5991,
        -10.11390381904762
    ],
    [
        20.6095,
        -11.75871510952381
    ],
    [
        20.6198,
        -11.265339447619047
    ],
    [
        20.6302,
        -11.265264195238094
    ],
    [
        20.6405,
        -11.402256552380951
    ],
    [
        20.6509,
        -10.991279480952382
    ],
    [
        20.6613,
        -12.08774509047619
    ],
    [
        20.6716,
        -12.08774509047619
    ],
    [
        20.682,
        -13.321014928571428
    ],
    [
        20.6924,
        -16.776202233333333
    ],
    [
        20.7027,
        -17.598607876190474
    ],
    [
        20.7131,
        -11.018911228571428
    ],
    [
        20.7234,
        -12.663271019047619
    ],
    [
        20.7338,
        -10.196054090476192
    ],
    [
        20.7442,
        -9.914163842857144
    ],
    [
        20.7545,
        -7.9403903
    ],
    [
        20.7649,
        -13.42324509047619
    ],
    [
        20.7752,
        -15.361579180952381
    ],
    [
        20.7856,
        -15.361579180952385
    ],
    [
        20.796,
        -12.89481374761905
    ],
    [
        20.8063,
        -12.0728596
    ],
    [
        20.8167,
        -8.373050076190477
    ],
    [
        20.8271,
        -7.139780233333333
    ],
    [
        20.8374,
        -7.351130195238097
    ],
    [
        20.8478,
        -10.366692809523808
    ],
    [
        20.8581,
        -4.885418261904762
    ],
    [
        20.8685,
        -8.174815085714286
    ],
    [
        20.8789,
        -7.51698087142857
    ],
    [
        20.8892,
        -7.352499742857142
    ],
    [
        20.8996,
        -6.530771347619047
    ],
    [
        20.9099,
        -4.5567269
    ],
    [
        20.9203,
        -5.379358295238094
    ],
    [
        20.9307,
        -10.76237705238095
    ],
    [
        20.941,
        -8.131265938095236
    ],
    [
        20.9514,
        -8.131265938095238
    ],
    [
        20.9618,
        -9.071577352380952
    ],
    [
        20.9721,
        -7.591585819047618
    ],
    [
        20.9825,
        -8.029916214285715
    ],
    [
        20.9928,
        -8.147483357142855
    ],
    [
        21.0032,
        -8.147483357142855
    ],
    [
        21.0136,
        -4.85876378095238
    ],
    [
        21.0239,
        -4.85876378095238
    ],
    [
        21.0343,
        -7.942107695238095
    ],
    [
        21.0446,
        -6.708160604761903
    ],
    [
        21.055,
        -3.5368952999999976
    ],
    [
        21.0654,
        -2.0565651380952352
    ],
    [
        21.0757,
        -8.634004295238096
    ],
    [
        21.0861,
        -8.222801476190476
    ],
    [
        21.0965,
        -9.703267085714284
    ],
    [
        21.1068,
        -11.348078376190475
    ],
    [
        21.1172,
        -9.703267090476189
    ],
    [
        21.1275,
        -5.755584552380952
    ],
    [
        21.1379,
        -3.64082718095238
    ],
    [
        21.1483,
        -1.9586338190476185
    ],
    [
        21.1586,
        -3.767790785714284
    ],
    [
        21.169,
        -0.06696538571428452
    ],
    [
        21.1794,
        0.5913203285714295
    ],
    [
        21.1897,
        -3.520143514285713
    ],
    [
        21.2001,
        -4.177977728571428
    ],
    [
        21.2104,
        -2.3687981904761894
    ],
    [
        21.2208,
        -1.4289060285714288
    ],
    [
        21.2312,
        -8.00679668095238
    ],
    [
        21.2415,
        -8.00634518095238
    ],
    [
        21.2519,
        -5.744729661904762
    ],
    [
        21.2622,
        -5.744729661904762
    ],
    [
        21.2726,
        -8.659792671428571
    ],
    [
        21.283,
        -12.607339761904763
    ],
    [
        21.2933,
        -12.196588438095237
    ],
    [
        21.3037,
        -6.028546109523812
    ],
    [
        21.3141,
        -4.177964095238097
    ],
    [
        21.3244,
        -0.06593587619047574
    ],
    [
        21.3348,
        -1.0525517476190471
    ],
    [
        21.3451,
        -8.454202542857145
    ],
    [
        21.3555,
        -8.59532181904762
    ],
    [
        21.3659,
        -9.449358452380952
    ],
    [
        21.3762,
        -9.24358772857143
    ],
    [
        21.3866,
        -11.182115319047622
    ],
    [
        21.3969,
        -12.661903676190477
    ],
    [
        21.4073,
        -9.783709671428571
    ],
    [
        21.4177,
        -9.126010904761905
    ],
    [
        21.428,
        -11.757708961904761
    ],
    [
        21.4384,
        -13.520006771428571
    ],
    [
        21.4488,
        -14.341735166666664
    ],
    [
        21.4591,
        -17.631357742857144
    ],
    [
        21.4695,
        -20.920980314285714
    ],
    [
        21.4798,
        -15.986546452380951
    ],
    [
        21.4902,
        -16.242942247619045
    ],
    [
        21.5006,
        -16.242942247619048
    ],
    [
        21.5109,
        -14.187041014285715
    ],
    [
        21.5213,
        -20.1082262
    ],
    [
        21.5317,
        -20.725199747619047
    ],
    [
        21.542,
        -23.192303799999998
    ],
    [
        21.5524,
        -17.271254066666664
    ],
    [
        21.5627,
        -15.508956257142854
    ],
    [
        21.5731,
        -12.548160485714282
    ],
    [
        21.5835,
        -13.632651442857142
    ],
    [
        21.5938,
        -13.01601652380952
    ],
    [
        21.6042,
        -13.95568293809524
    ],
    [
        21.6145,
        -13.133954542857143
    ],
    [
        21.6249,
        -15.354449780952383
    ],
    [
        21.6353,
        -19.301996871428575
    ],
    [
        21.6456,
        -19.30154537619048
    ],
    [
        21.656,
        -17.38259887142857
    ],
    [
        21.6664,
        -13.929443319047618
    ],
    [
        21.6767,
        -9.981896228571427
    ],
    [
        21.6871,
        -7.35019817142857
    ],
    [
        21.6974,
        -12.637188342857144
    ],
    [
        21.7078,
        -10.287909428571428
    ],
    [
        21.7182,
        -4.366588795238096
    ],
    [
        21.7285,
        -5.637466457142856
    ],
    [
        21.7389,
        -9.583794500000002
    ],
    [
        21.7492,
        -8.350186033333333
    ],
    [
        21.7596,
        -8.350298909523811
    ],
    [
        21.77,
        -13.284732771428573
    ],
    [
        21.7803,
        -15.046691952380954
    ],
    [
        21.7907,
        -19.98112581904762
    ],
    [
        21.8011,
        -19.981029066666665
    ],
    [
        21.8114,
        -19.981029066666665
    ],
    [
        21.8218,
        -19.041265900000003
    ],
    [
        21.8321,
        -18.63023239047619
    ],
    [
        21.8425,
        -16.820939976190477
    ],
    [
        21.8529,
        -12.434701295238096
    ],
    [
        21.8632,
        -5.855907642857143
    ],
    [
        21.8736,
        -2.8410975333333326
    ],
    [
        21.8839,
        -5.47252469047619
    ],
    [
        21.8943,
        -5.190557042857142
    ],
    [
        21.9047,
        -4.943835347619047
    ],
    [
        21.915,
        -6.236090323809523
    ],
    [
        21.9254,
        -12.814883976190474
    ],
    [
        21.9358,
        -15.035040590476191
    ],
    [
        21.9461,
        -16.23126698095238
    ],
    [
        21.9565,
        -12.531457457142855
    ],
    [
        21.9668,
        -11.544299785714284
    ],
    [
        21.9772,
        -11.338529061904762
    ],
    [
        21.9876,
        -8.871989380952382
    ],
    [
        21.9979,
        -6.8536959714285715
    ],
    [
        22.0083,
        -2.741667752380953
    ],
    [
        22.0187,
        -3.0237321523809535
    ],
    [
        22.029,
        -3.0237321523809535
    ],
    [
        22.0394,
        -3.9632695714285724
    ],
    [
        22.0497,
        -2.7294917952380953
    ],
    [
        22.0601,
        -4.2566230619047625
    ],
    [
        22.0705,
        -3.003508757142857
    ],
    [
        22.0808,
        -8.4861378
    ],
    [
        22.0912,
        -11.569481714285715
    ],
    [
        22.1015,
        -4.990236566666668
    ],
    [
        22.1119,
        -6.752534376190475
    ],
    [
        22.1223,
        -5.519603161904762
    ],
    [
        22.1326,
        -4.861814100000001
    ],
    [
        22.143,
        -0.47565066666666855
    ],
    [
        22.1534,
        3.2244974809523805
    ],
    [
        22.1637,
        4.3209630857142844
    ],
    [
        22.1741,
        7.1988184666666655
    ],
    [
        22.1844,
        4.978052328571428
    ],
    [
        22.1948,
        3.128147566666666
    ],
    [
        22.2052,
        2.3064191714285713
    ],
    [
        22.2155,
        1.9328241714285714
    ],
    [
        22.2259,
        -0.9455955809523813
    ],
    [
        22.2362,
        1.6861024761904755
    ],
    [
        22.2466,
        1.3335461666666661
    ],
    [
        22.257,
        3.68263158095238
    ],
    [
        22.2673,
        2.8602259380952377
    ],
    [
        22.2777,
        8.498030928571428
    ],
    [
        22.2881,
        7.08819268095238
    ],
    [
        22.2984,
        9.281199147619045
    ],
    [
        22.3088,
        4.963569519047618
    ],
    [
        22.3191,
        -0.5875839190476182
    ],
    [
        22.3295,
        1.8796330142857147
    ],
    [
        22.3399,
        1.8796330142857152
    ],
    [
        22.3502,
        0.39943830476190606
    ],
    [
        22.3606,
        -1.5198468238095226
    ],
    [
        22.371,
        -5.220333595238095
    ],
    [
        22.3813,
        -5.083134299999998
    ],
    [
        22.3917,
        -6.234276452380952
    ],
    [
        22.402,
        -5.493975923809523
    ],
    [
        22.4124,
        -5.494653176190475
    ],
    [
        22.4228,
        -7.632907847619047
    ],
    [
        22.4331,
        -6.262457523809523
    ],
    [
        22.4435,
        -5.851028952380951
    ],
    [
        22.4538,
        -7.495840238095236
    ],
    [
        22.4642,
        -12.07771779047619
    ],
    [
        22.4746,
        -14.016051880952379
    ],
    [
        22.4849,
        -15.660185919047617
    ],
    [
        22.4953,
        -21.827212376190477
    ],
    [
        22.5057,
        -16.187859390476188
    ],
    [
        22.516,
        -19.47703046666667
    ],
    [
        22.5264,
        -15.365002247619046
    ],
    [
        22.5367,
        -15.981552514285713
    ],
    [
        22.5471,
        -16.968439285714286
    ],
    [
        22.5575,
        -20.257384609523807
    ],
    [
        22.5678,
        -20.257384609523807
    ],
    [
        22.5782,
        -16.145017766666665
    ],
    [
        22.5885,
        -17.378626233333332
    ],
    [
        22.5989,
        -15.734210004761906
    ],
    [
        22.6093,
        -16.77592381904762
    ],
    [
        22.6196,
        -16.830811061904765
    ],
    [
        22.63,
        -14.363594128571432
    ],
    [
        22.6404,
        -8.936394128571433
    ],
    [
        22.6507,
        -10.471551328571431
    ],
    [
        22.6611,
        -12.115911114285717
    ],
    [
        22.6714,
        -11.56764068571429
    ],
    [
        22.6818,
        -7.2500110571428555
    ],
    [
        22.6922,
        -8.483958147619047
    ],
    [
        22.7025,
        -7.662004004761904
    ],
    [
        22.7129,
        -6.428056914285714
    ],
    [
        22.7232,
        -12.595760619047619
    ],
    [
        22.7336,
        -9.964514057142855
    ],
    [
        22.744,
        -9.635416352380952
    ],
    [
        22.7543,
        -6.757335223809525
    ],
    [
        22.7647,
        -5.770448452380954
    ],
    [
        22.7751,
        -5.564847042857144
    ],
    [
        22.7854,
        -5.564508414285714
    ],
    [
        22.7958,
        -7.75766538095238
    ],
    [
        22.8061,
        -0.3573690809523804
    ],
    [
        22.8165,
        -1.5906389238095235
    ],
    [
        22.8269,
        -1.0423684952380952
    ],
    [
        22.8372,
        -1.316578957142856
    ],
    [
        22.8476,
        -6.251012819047618
    ],
    [
        22.8579,
        -13.65164773809524
    ],
    [
        22.8683,
        -17.59919482857143
    ],
    [
        22.8787,
        -16.365924990476188
    ],
    [
        22.889,
        -16.914195419047616
    ],
    [
        22.8994,
        -17.002310309523807
    ],
    [
        22.9098,
        -12.889717714285712
    ],
    [
        22.9201,
        -12.478571328571428
    ],
    [
        22.9305,
        -13.4654581
    ],
    [
        22.9408,
        -10.587828466666668
    ],
    [
        22.9512,
        -9.107498304761904
    ],
    [
        22.9616,
        -11.081407300000002
    ],
    [
        22.9719,
        -13.431137709523806
    ],
    [
        22.9823,
        -18.364217076190474
    ],
    [
        22.9927,
        -18.364217076190474
    ],
    [
        23.003,
        -18.363878452380952
    ],
    [
        23.0134,
        -22.749966633333333
    ],
    [
        23.0237,
        -27.683045999999997
    ],
    [
        23.0341,
        -28.916654466666664
    ],
    [
        23.0445,
        -30.561465752380947
    ],
    [
        23.0548,
        -29.739060109523805
    ],
    [
        23.0652,
        -25.509545371428572
    ],
    [
        23.0755,
        -23.042667066666667
    ],
    [
        23.0859,
        -18.656503633333337
    ],
    [
        23.0963,
        -12.488799928571433
    ],
    [
        23.1066,
        -11.940529500000006
    ],
    [
        23.117,
        -11.852329952380956
    ],
    [
        23.1274,
        -14.31988550952381
    ],
    [
        23.1377,
        -13.086446357142862
    ],
    [
        23.1481,
        -13.333168052380953
    ],
    [
        23.1584,
        -16.622339128571433
    ],
    [
        23.1688,
        -16.622339128571433
    ],
    [
        23.1792,
        -11.688243890476194
    ],
    [
        23.1895,
        -12.216933233333334
    ],
    [
        23.1999,
        -8.51712370952381
    ],
    [
        23.2102,
        -8.6052386
    ],
    [
        23.2206,
        -6.13869891904762
    ],
    [
        23.231,
        -3.6714819904761904
    ],
    [
        23.2413,
        -4.49400050952381
    ],
    [
        23.2517,
        -2.273640719047619
    ],
    [
        23.2621,
        -1.8624379000000006
    ],
    [
        23.2724,
        -2.6846177952380956
    ],
    [
        23.2828,
        0.48751826190476133
    ],
    [
        23.2931,
        -0.005925123809524432
    ],
    [
        23.3035,
        3.009562238095237
    ],
    [
        23.3139,
        -0.2805118333333327
    ],
    [
        23.3242,
        -3.2959991952380947
    ],
    [
        23.3346,
        -3.38410199047619
    ],
    [
        23.345,
        -3.383876238095238
    ],
    [
        23.3553,
        -0.9159820571428571
    ],
    [
        23.3657,
        -0.915982057142857
    ],
    [
        23.376,
        0.7288292285714286
    ],
    [
        23.3864,
        -2.443306828571428
    ],
    [
        23.3968,
        -3.676745980952381
    ],
    [
        23.4071,
        -7.377571376190476
    ],
    [
        23.4175,
        -6.966481428571427
    ],
    [
        23.4278,
        -11.196673414285712
    ],
    [
        23.4382,
        -6.2629167999999975
    ],
    [
        23.4486,
        -6.262916799999999
    ],
    [
        23.4589,
        -6.262803923809522
    ],
    [
        23.4693,
        -8.483163714285714
    ],
    [
        23.4797,
        -12.182634614285712
    ],
    [
        23.49,
        -13.005491757142854
    ],
    [
        23.5004,
        -15.472708690476189
    ],
    [
        23.5107,
        -14.979265304761904
    ],
    [
        23.5211,
        -19.091293523809526
    ],
    [
        23.5315,
        -21.55783320952381
    ],
    [
        23.5418,
        -20.077503047619047
    ],
    [
        23.5522,
        -18.87937273809524
    ],
    [
        23.5625,
        -19.044237642857144
    ],
    [
        23.5729,
        -22.745740290476192
    ],
    [
        23.5833,
        -19.04491489047619
    ],
    [
        23.5936,
        -20.68927468095238
    ],
    [
        23.604,
        -20.601159790476192
    ],
    [
        23.6144,
        -19.36805926190476
    ],
    [
        23.6247,
        -16.90151958095238
    ],
    [
        23.6351,
        -18.545879366666668
    ],
    [
        23.6454,
        -16.07798519047619
    ],
    [
        23.6558,
        -21.834034571428578
    ],
    [
        23.6662,
        -19.36681763809524
    ],
    [
        23.6765,
        -19.36659189047619
    ],
    [
        23.6869,
        -19.778020461904767
    ],
    [
        23.6972,
        -14.844941095238095
    ],
    [
        23.7076,
        -14.4331739
    ],
    [
        23.718,
        -19.367607761904758
    ],
    [
        23.7283,
        -21.834486066666663
    ],
    [
        23.7387,
        -22.656891714285717
    ],
    [
        23.7491,
        -26.767791185714287
    ],
    [
        23.7594,
        -28.24845997142857
    ],
    [
        23.7698,
        -29.563947804761906
    ],
    [
        23.7801,
        -26.93197884761905
    ],
    [
        23.7905,
        -26.520776023809525
    ],
    [
        23.8009,
        -28.987992957142858
    ],
    [
        23.8112,
        -28.57690300952381
    ],
    [
        23.8216,
        -29.193707242857148
    ],
    [
        23.832,
        -32.27705115714286
    ],
    [
        23.8423,
        -29.104237852380955
    ],
    [
        23.8527,
        -27.624584942857137
    ],
    [
        23.863,
        -20.22293414761905
    ],
    [
        23.8734,
        -14.467562014285713
    ],
    [
        23.8838,
        -15.289967657142855
    ],
    [
        23.8941,
        -12.82297647619047
    ],
    [
        23.9045,
        -12.000345080952378
    ],
    [
        23.9148,
        -12.82275072380952
    ],
    [
        23.9252,
        -6.655047019047622
    ],
    [
        23.9356,
        -2.707229028571431
    ],
    [
        23.9459,
        -1.8851620095238097
    ],
    [
        23.9563,
        -1.062756361904764
    ],
    [
        23.9667,
        2.225511719047619
    ],
    [
        23.977,
        4.693067276190476
    ],
    [
        23.9874,
        3.048030238095238
    ],
    [
        23.9977,
        2.6364887952380966
    ],
    [
        24.0081,
        3.010309542857142
    ],
    [
        24.0185,
        5.478203723809523
    ],
    [
        24.0288,
        5.477865100000001
    ],
    [
        24.0392,
        11.644552933333333
    ],
    [
        24.0495,
        9.794140233333334
    ],
    [
        24.0599,
        4.156141742857143
    ],
    [
        24.0703,
        4.5262242809523805
    ],
    [
        24.0806,
        -0.4082095809523781
    ],
    [
        24.091,
        -0.4068550857142852
    ],
    [
        24.1014,
        -1.2292607285714288
    ],
    [
        24.1117,
        -4.107341857142857
    ],
    [
        24.1221,
        -4.51854468095238
    ],
    [
        24.1324,
        -6.1633559714285715
    ],
    [
        24.1428,
        -12.331398299999998
    ],
    [
        24.1532,
        -9.699971142857143
    ],
    [
        24.1635,
        -8.671964090476191
    ],
    [
        24.1739,
        -8.671512595238095
    ],
    [
        24.1842,
        -2.0922674476190486
    ],
    [
        24.1946,
        -0.8589976047619058
    ],
    [
        24.205,
        0.37461086190476206
    ],
    [
        24.2153,
        -1.845545752380953
    ],
    [
        24.2257,
        -3.041772142857142
    ],
    [
        24.2361,
        -3.0424493904761922
    ],
    [
        24.2464,
        -3.0424493904761905
    ],
    [
        24.2568,
        -9.620340042857144
    ],
    [
        24.2671,
        -8.386392952380955
    ],
    [
        24.2775,
        -3.453313585714286
    ],
    [
        24.2879,
        -3.453313585714286
    ],
    [
        24.2982,
        -4.440200357142857
    ],
    [
        24.3086,
        -6.90809453809524
    ],
    [
        24.319,
        -6.90809453809524
    ],
    [
        24.3293,
        -7.3196359809523805
    ],
    [
        24.3397,
        -4.44121622857143
    ],
    [
        24.35,
        -6.9084331571428566
    ],
    [
        24.3604,
        -6.661711461904761
    ],
    [
        24.3708,
        -8.754984509523808
    ],
    [
        24.3811,
        -7.151293504761903
    ],
    [
        24.3915,
        -10.441367576190475
    ],
    [
        24.4018,
        -15.992605671428569
    ],
    [
        24.4122,
        -18.459145357142855
    ],
    [
        24.4226,
        -15.9915898
    ],
    [
        24.4329,
        -15.826882923809524
    ],
    [
        24.4433,
        -15.168958409523812
    ],
    [
        24.4537,
        -16.931449719047617
    ],
    [
        24.464,
        -16.19142009047619
    ],
    [
        24.4744,
        -12.08006912380952
    ],
    [
        24.4847,
        -12.080407747619043
    ],
    [
        24.4951,
        -10.846799280952377
    ],
    [
        24.5055,
        -10.582454609523804
    ],
    [
        24.5158,
        -4.662488471428571
    ],
    [
        24.5262,
        -2.1945942904761884
    ],
    [
        24.5365,
        -0.5497830047619048
    ],
    [
        24.5469,
        -0.1382415619047626
    ],
    [
        24.5573,
        -2.6056842428571434
    ],
    [
        24.5676,
        0.6843898285714257
    ],
    [
        24.578,
        2.9052236904761886
    ],
    [
        24.5884,
        0.8871457714285698
    ],
    [
        24.5987,
        -1.7445522857142872
    ],
    [
        24.6091,
        1.545521785714286
    ],
    [
        24.6194,
        1.6688826333333338
    ],
    [
        24.6298,
        5.369369409523811
    ],
    [
        24.6402,
        9.069856180952383
    ],
    [
        24.6505,
        7.425496395238096
    ],
    [
        24.6609,
        5.945166233333334
    ],
    [
        24.6712,
        4.253824738095238
    ],
    [
        24.6816,
        2.279509395238097
    ],
    [
        24.692,
        -0.6356151809523789
    ],
    [
        24.7023,
        1.2147975190476215
    ],
    [
        24.7127,
        -2.485350628571427
    ],
    [
        24.7231,
        -2.7496952999999995
    ],
    [
        24.7334,
        -6.449504823809525
    ],
    [
        24.7438,
        -13.02874997142857
    ],
    [
        24.7541,
        -17.96318383333333
    ],
    [
        24.7645,
        -17.71680076190476
    ],
    [
        24.7749,
        -21.828603228571428
    ],
    [
        24.7852,
        -15.251164071428567
    ],
    [
        24.7956,
        -17.03353209047619
    ],
    [
        24.806,
        -15.800262252380946
    ],
    [
        24.8163,
        -9.221017104761902
    ],
    [
        24.8267,
        -7.576657314285714
    ],
    [
        24.837,
        -8.728025214285712
    ],
    [
        24.8474,
        -12.181858019047617
    ],
    [
        24.8578,
        -18.349900347619045
    ],
    [
        24.8681,
        -18.350577599999998
    ],
    [
        24.8785,
        -15.883360666666663
    ],
    [
        24.8888,
        -9.963665428571428
    ],
    [
        24.8992,
        -8.20069037142857
    ],
    [
        24.9096,
        -7.135301242857141
    ],
    [
        24.9199,
        -8.368909709523807
    ],
    [
        24.9303,
        -7.135978495238094
    ],
    [
        24.9407,
        -7.224093385714285
    ],
    [
        24.951,
        -9.44425
    ],
    [
        24.9614,
        -5.332899033333333
    ],
    [
        24.9717,
        -1.3853519428571428
    ],
    [
        24.9821,
        -5.332899033333333
    ],
    [
        24.9925,
        -2.0432764571428557
    ],
    [
        25.0028,
        -5.331996033333334
    ],
    [
        25.0132,
        -5.400548647619048
    ],
    [
        25.0235,
        -4.167278804761905
    ],
    [
        25.0339,
        -7.457352876190475
    ],
    [
        25.0443,
        -7.457352876190475
    ],
    [
        25.0546,
        -6.799428361904763
    ],
    [
        25.065,
        -7.457172280952381
    ],
    [
        25.0754,
        -8.690780747619046
    ],
    [
        25.0857,
        -5.4011581714285715
    ],
    [
        25.0961,
        -5.9494286
    ],
    [
        25.1064,
        -9.238148176190476
    ],
    [
        25.1168,
        -9.355634695238095
    ],
    [
        25.1272,
        -5.038005066666666
    ],
    [
        25.1375,
        -5.038005066666668
    ],
    [
        25.1479,
        -2.8177807285714276
    ],
    [
        25.1583,
        -2.5615696380952375
    ],
    [
        25.1686,
        -4.0418998
    ],
    [
        25.179,
        -8.152799271428572
    ],
    [
        25.1893,
        -9.633129433333332
    ],
    [
        25.1997,
        -7.1652352523809535
    ],
    [
        25.2101,
        -3.053207033333334
    ],
    [
        25.2204,
        -2.230349890476192
    ],
    [
        25.2308,
        -2.1616467761904774
    ],
    [
        25.2411,
        -3.395255242857144
    ],
    [
        25.2515,
        -0.10518117142857247
    ],
    [
        25.2619,
        4.827898195238094
    ],
    [
        25.2722,
        5.109865842857142
    ],
    [
        25.2826,
        4.2876859523809525
    ],
    [
        25.293,
        4.287008704761904
    ],
    [
        25.3033,
        2.2313332190476194
    ],
    [
        25.3137,
        4.424414933333331
    ],
    [
        25.324,
        1.1356953523809525
    ],
    [
        25.3344,
        1.341296761904761
    ],
    [
        25.3448,
        1.341296761904761
    ],
    [
        25.3551,
        6.893889357142858
    ],
    [
        25.3655,
        5.202547861904761
    ],
    [
        25.3758,
        6.638142666666666
    ],
    [
        25.3862,
        8.656897838095237
    ],
    [
        25.3966,
        10.30125762857143
    ],
    [
        25.4069,
        14.002083028571427
    ],
    [
        25.4173,
        15.975179323809524
    ],
    [
        25.4277,
        8.57352852857143
    ],
    [
        25.438,
        7.339581438095238
    ],
    [
        25.4484,
        7.5082800333333335
    ],
    [
        25.4587,
        13.676322361904763
    ],
    [
        25.4691,
        13.676322361904763
    ],
    [
        25.4795,
        8.038323871428569
    ],
    [
        25.4898,
        11.210459928571428
    ],
    [
        25.5002,
        13.060195376190475
    ],
    [
        25.5105,
        14.294819714285714
    ],
    [
        25.5209,
        20.46252341904762
    ],
    [
        25.5313,
        20.462523419047624
    ],
    [
        25.5416,
        21.490248290476195
    ],
    [
        25.552,
        20.462241238095242
    ],
    [
        25.5624,
        17.17261866190476
    ],
    [
        25.5727,
        11.249672628571428
    ],
    [
        25.5831,
        10.721128409523809
    ],
    [
        25.5934,
        11.379052923809521
    ],
    [
        25.6038,
        11.122789061904761
    ],
    [
        25.6142,
        6.1883552
    ],
    [
        25.6245,
        0.02031287142857049
    ],
    [
        25.6349,
        -0.10304797619047713
    ],
    [
        25.6452,
        6.064655728571431
    ],
    [
        25.6556,
        3.1865746000000015
    ],
    [
        25.666,
        1.0996067523809558
    ],
    [
        25.6763,
        -6.3027212904761925
    ],
    [
        25.6867,
        -6.30272129047619
    ],
    [
        25.6971,
        -7.242613452380952
    ],
    [
        25.7074,
        -10.943438852380954
    ],
    [
        25.7178,
        -11.148814514285714
    ],
    [
        25.7281,
        -12.382761604761905
    ],
    [
        25.7385,
        -18.96155525714286
    ],
    [
        25.7489,
        -20.60636654285715
    ],
    [
        25.7592,
        -19.989731623809526
    ],
    [
        25.7696,
        -18.89319076666667
    ],
    [
        25.78,
        -19.71582215714286
    ],
    [
        25.7903,
        -19.71582215714286
    ],
    [
        25.8007,
        -19.469168185714288
    ],
    [
        25.811,
        -21.113979471428575
    ],
    [
        25.8214,
        -21.642862314285715
    ],
    [
        25.8318,
        -15.063617166666663
    ],
    [
        25.8421,
        -10.622491242857144
    ],
    [
        25.8525,
        -9.594484185714286
    ],
    [
        25.8628,
        -11.156625985714285
    ],
    [
        25.8732,
        -7.044597766666669
    ],
    [
        25.8836,
        0.3563757761904726
    ],
    [
        25.8939,
        2.824269957142858
    ],
    [
        25.9043,
        -3.343772371428573
    ],
    [
        25.9147,
        -1.6989610857142878
    ],
    [
        25.925,
        -2.110163904761908
    ],
    [
        25.9354,
        -2.521253852380953
    ],
    [
        25.9457,
        -1.6988482047619056
    ],
    [
        25.9561,
        2.4127285190476195
    ],
    [
        25.9665,
        0.7679172333333338
    ],
    [
        25.9768,
        -1.698622447619047
    ],
    [
        25.9872,
        -2.1372387904761907
    ],
    [
        25.9975,
        -1.7258102190476186
    ],
    [
        26.0079,
        -1.3554567809523814
    ],
    [
        26.0183,
        -1.1912465523809526
    ],
    [
        26.0286,
        -0.2043597809523811
    ],
    [
        26.039,
        0.6181587380952389
    ],
    [
        26.0494,
        -1.8490581904761907
    ],
    [
        26.0597,
        -0.7389460190476201
    ],
    [
        26.0701,
        -1.1501488428571427
    ],
    [
        26.0804,
        -1.5260025095238103
    ],
    [
        26.0908,
        -4.610192985714285
    ],
    [
        26.1012,
        -8.99650691904762
    ],
    [
        26.1115,
        -10.230115385714287
    ],
    [
        26.1219,
        -9.983393690476191
    ],
    [
        26.1323,
        -5.04895982857143
    ],
    [
        26.1426,
        -3.952418971428572
    ],
    [
        26.153,
        -0.251932195238094
    ],
    [
        26.1633,
        0.1592706238095237
    ],
    [
        26.1737,
        -3.29456218095238
    ],
    [
        26.1841,
        -4.116967828571428
    ],
    [
        26.1944,
        -4.116967828571428
    ],
    [
        26.2048,
        -2.4251619333333334
    ],
    [
        26.2151,
        -4.645386271428572
    ],
    [
        26.2255,
        -4.577002966666667
    ],
    [
        26.2359,
        -2.5209888571428576
    ],
    [
        26.2462,
        -4.212794752380954
    ],
    [
        26.2566,
        -4.6238847000000005
    ],
    [
        26.267,
        -3.8014790571428576
    ],
    [
        26.2773,
        -4.418283290476191
    ],
    [
        26.2877,
        -6.063094576190477
    ],
    [
        26.298,
        -6.345255723809525
    ],
    [
        26.3084,
        -4.905876538095238
    ],
    [
        26.3188,
        -6.158722090476193
    ],
    [
        26.3291,
        -4.102707980952381
    ],
    [
        26.3395,
        -3.820740333333334
    ],
    [
        26.3498,
        -11.222391128571427
    ],
    [
        26.3602,
        -11.907729166666666
    ],
    [
        26.3706,
        -9.440512238095238
    ],
    [
        26.3809,
        -8.82353869047619
    ],
    [
        26.3913,
        -6.192111533333334
    ],
    [
        26.4017,
        -8.65797396666667
    ],
    [
        26.412,
        -6.6848776714285725
    ],
    [
        26.4224,
        -12.32423065714286
    ],
    [
        26.4327,
        -6.403180923809526
    ],
    [
        26.4431,
        -4.62115152857143
    ],
    [
        26.4535,
        -10.789193857142859
    ],
    [
        26.4638,
        -10.632545161904764
    ],
    [
        26.4742,
        -9.810365266666667
    ],
    [
        26.4845,
        -11.043973733333335
    ],
    [
        26.4949,
        -12.030860504761906
    ],
    [
        26.5053,
        -5.451615357142858
    ],
    [
        26.5156,
        -9.397807952380953
    ],
    [
        26.526,
        -15.154647457142861
    ],
    [
        26.5364,
        -11.982704900000002
    ],
    [
        26.5467,
        -13.421914776190476
    ],
    [
        26.5571,
        -7.782561790476189
    ],
    [
        26.5674,
        -6.9601561428571435
    ],
    [
        26.5778,
        -8.19376460952381
    ],
    [
        26.5882,
        -14.114814342857144
    ],
    [
        26.5985,
        -15.14299070952381
    ],
    [
        26.6089,
        -19.255018928571435
    ],
    [
        26.6192,
        -14.938743795238095
    ],
    [
        26.6296,
        -19.37973427142857
    ],
    [
        26.64,
        -15.678908876190478
    ],
    [
        26.6503,
        -22.25770252857143
    ],
    [
        26.6607,
        -25.958527928571428
    ],
    [
        26.6711,
        -22.66845385714286
    ],
    [
        26.6814,
        -21.297552033333336
    ],
    [
        26.6918,
        -26.231985895238093
    ],
    [
        26.7021,
        -25.9852642
    ],
    [
        26.7125,
        -27.464917109523807
    ],
    [
        26.7229,
        -33.38623774285714
    ],
    [
        26.7332,
        -30.919698057142856
    ],
    [
        26.7436,
        -26.97215096666666
    ],
    [
        26.754,
        -28.82256366666667
    ],
    [
        26.7643,
        -28.822563666666664
    ],
    [
        26.7747,
        -35.40135731904762
    ],
    [
        26.785,
        -28.82075767142857
    ],
    [
        26.7954,
        -21.42046137142857
    ],
    [
        26.8058,
        -21.42073227142857
    ],
    [
        26.8161,
        -19.775920985714286
    ],
    [
        26.8265,
        -19.775920985714286
    ],
    [
        26.8368,
        -19.981296647619047
    ],
    [
        26.8472,
        -13.81325431904762
    ],
    [
        26.8576,
        -13.028107614285712
    ],
    [
        26.8679,
        -10.561342180952378
    ],
    [
        26.8783,
        -8.094125247619044
    ],
    [
        26.8887,
        -6.861081152380949
    ],
    [
        26.899,
        -12.618146409523806
    ],
    [
        26.9094,
        -12.618146409523806
    ],
    [
        26.9197,
        -9.164719957142855
    ],
    [
        26.9301,
        -9.165397209523807
    ],
    [
        26.9405,
        -8.178510438095236
    ],
    [
        26.9508,
        -8.179864938095236
    ],
    [
        26.9612,
        -7.686286104761903
    ],
    [
        26.9715,
        -6.658279052380952
    ],
    [
        26.9819,
        -7.685834609523808
    ],
    [
        26.9923,
        -8.50869175238095
    ],
    [
        27.0026,
        -8.510046252380953
    ],
    [
        27.013,
        -13.854328442857144
    ],
    [
        27.0234,
        -12.319171242857143
    ],
    [
        27.0337,
        -13.024090366666666
    ],
    [
        27.0441,
        -15.489952800000001
    ],
    [
        27.0544,
        -17.13498983809524
    ],
    [
        27.0648,
        -15.901381371428572
    ],
    [
        27.0752,
        -16.069723842857144
    ],
    [
        27.0855,
        -16.892129485714285
    ],
    [
        27.0959,
        -18.536940771428572
    ],
    [
        27.1062,
        -18.12562507619048
    ],
    [
        27.1166,
        -14.835551004761905
    ],
    [
        27.127,
        -14.835551004761905
    ],
    [
        27.1373,
        -19.769307619047623
    ],
    [
        27.1477,
        -18.12449632857143
    ],
    [
        27.1581,
        -17.302090685714287
    ],
    [
        27.1684,
        -15.539115628571428
    ],
    [
        27.1788,
        -16.69016748095238
    ],
    [
        27.1891,
        -13.400544904761908
    ],
    [
        27.1995,
        -12.372820033333333
    ],
    [
        27.2099,
        -14.840036961904762
    ],
    [
        27.2202,
        -21.41928210952381
    ],
    [
        27.2306,
        -19.3628165
    ],
    [
        27.241,
        -22.37762660952381
    ],
    [
        27.2513,
        -24.13992441904762
    ],
    [
        27.2617,
        -20.029024947619046
    ],
    [
        27.272,
        -20.029024947619046
    ],
    [
        27.2824,
        -24.963458814285715
    ],
    [
        27.2928,
        -29.281088442857147
    ],
    [
        27.3031,
        -29.774396380952382
    ],
    [
        27.3135,
        -26.48477380952381
    ],
    [
        27.3238,
        -27.47138968095238
    ],
    [
        27.3342,
        -28.294246823809527
    ],
    [
        27.3446,
        -28.29560132380952
    ],
    [
        27.3549,
        -27.47319567619047
    ],
    [
        27.3653,
        -31.583869399999998
    ],
    [
        27.3757,
        -31.701355919047614
    ],
    [
        27.386,
        -31.54455672380952
    ],
    [
        27.3964,
        -24.96576307142857
    ],
    [
        27.4067,
        -30.722376828571424
    ],
    [
        27.4171,
        -30.9283733047619
    ],
    [
        27.4275,
        -21.060860076190473
    ],
    [
        27.4378,
        -21.060860076190476
    ],
    [
        27.4482,
        -27.64055672380952
    ],
    [
        27.4586,
        -27.641233976190474
    ],
    [
        27.4689,
        -25.996196938095242
    ],
    [
        27.4793,
        -23.94006995238096
    ],
    [
        27.4896,
        -22.17777214285715
    ],
    [
        27.5,
        -22.177094890476194
    ],
    [
        27.5104,
        -18.06506667142857
    ],
    [
        27.5207,
        -18.558374604761905
    ],
    [
        27.5311,
        -25.137619752380953
    ],
    [
        27.5414,
        -22.917395414285714
    ],
    [
        27.5518,
        -20.45017848095238
    ],
    [
        27.5622,
        -17.1592014047619
    ],
    [
        27.5725,
        -16.50127689047619
    ],
    [
        27.5829,
        -10.58131075238095
    ],
    [
        27.5933,
        -11.734691638095237
    ],
    [
        27.6036,
        -10.199684938095237
    ],
    [
        27.614,
        -15.83903792380952
    ],
    [
        27.6243,
        -19.95129189047619
    ],
    [
        27.6347,
        -19.128660495238094
    ],
    [
        27.6451,
        -24.76646548571428
    ],
    [
        27.6554,
        -28.05608806190476
    ],
    [
        27.6658,
        -23.121654199999995
    ],
    [
        27.6761,
        -21.888384357142854
    ],
    [
        27.6865,
        -18.598987528571424
    ],
    [
        27.6969,
        -20.381205047619044
    ],
    [
        27.7072,
        -18.442677457142857
    ],
    [
        27.7176,
        -16.592772695238093
    ],
    [
        27.728,
        -13.303150119047617
    ],
    [
        27.7383,
        -13.549804090476187
    ],
    [
        27.7487,
        -9.84931731904762
    ],
    [
        27.759,
        -11.082925785714286
    ],
    [
        27.7694,
        -11.787844909523809
    ],
    [
        27.7798,
        -11.787844909523809
    ],
    [
        27.7901,
        -10.800958138095238
    ],
    [
        27.8005,
        -10.801229038095238
    ],
    [
        27.8109,
        -10.80135217142857
    ],
    [
        27.8212,
        -12.775125714285716
    ],
    [
        27.8316,
        -7.135772728571429
    ],
    [
        27.8419,
        -1.4966132428571437
    ],
    [
        27.8523,
        -2.3190188857142866
    ],
    [
        27.8627,
        3.3187861047619043
    ],
    [
        27.873,
        7.704949538095238
    ],
    [
        27.8834,
        2.7705156761904766
    ],
    [
        27.8937,
        -0.9313255952380961
    ],
    [
        27.9041,
        -5.04290231904762
    ],
    [
        27.9145,
        -6.961848823809524
    ],
    [
        27.9248,
        -9.182073161904762
    ],
    [
        27.9352,
        -9.387843885714286
    ],
    [
        27.9455,
        -11.032655176190476
    ],
    [
        27.9559,
        -11.444196619047618
    ],
    [
        27.9663,
        -10.826884447619047
    ],
    [
        27.9766,
        -10.826884447619047
    ],
    [
        27.987,
        -7.655425642857143
    ],
    [
        27.9974,
        -8.47783129047619
    ],
    [
        28.0077,
        -9.026252219047619
    ],
    [
        28.0181,
        -12.480085023809522
    ],
    [
        28.0284,
        -11.044613357142856
    ],
    [
        28.0388,
        -10.76245220952381
    ],
    [
        28.0492,
        -18.164103004761905
    ],
    [
        28.0595,
        -23.801907995238096
    ],
    [
        28.0699,
        -21.33469106666667
    ],
    [
        28.0803,
        -26.81604086190477
    ],
    [
        28.0906,
        -31.2022042952381
    ],
    [
        28.101,
        -31.2022042952381
    ],
    [
        28.1113,
        -23.799198999999994
    ],
    [
        28.1217,
        -23.14145508095238
    ],
    [
        28.1321,
        -21.661124919047616
    ],
    [
        28.1424,
        -15.740075185714286
    ],
    [
        28.1528,
        -19.852103404761902
    ],
    [
        28.1631,
        -25.608942909523805
    ],
    [
        28.1735,
        -24.668857247619048
    ],
    [
        28.1839,
        -26.51943926190476
    ],
    [
        28.1942,
        -21.586359895238093
    ],
    [
        28.2046,
        -25.69771086190476
    ],
    [
        28.215,
        -24.87575671428571
    ],
    [
        28.2253,
        -29.261769647619047
    ],
    [
        28.2357,
        -29.261769647619047
    ],
    [
        28.246,
        -31.235272290476193
    ],
    [
        28.2564,
        -32.9977636
    ],
    [
        28.2668,
        -31.763816509523817
    ],
    [
        28.2771,
        -26.830737142857146
    ],
    [
        28.2875,
        -27.488932557142864
    ],
    [
        28.2978,
        -28.174270595238102
    ],
    [
        28.3082,
        -24.2264526047619
    ],
    [
        28.3186,
        -20.114424385714283
    ],
    [
        28.3289,
        -20.937281528571425
    ],
    [
        28.3393,
        -20.937823328571426
    ],
    [
        28.3497,
        -18.306125271428566
    ],
    [
        28.36,
        -24.885370419047614
    ],
    [
        28.3704,
        -20.937552428571426
    ],
    [
        28.3807,
        -16.619922799999998
    ],
    [
        28.3911,
        -14.681927333333332
    ],
    [
        28.4015,
        -13.037116042857143
    ],
    [
        28.4118,
        -16.32538412380952
    ],
    [
        28.4222,
        -15.914181304761902
    ],
    [
        28.4325,
        -11.802378838095237
    ],
    [
        28.4429,
        -7.85510264761905
    ],
    [
        28.4533,
        -7.855102647619049
    ],
    [
        28.4636,
        -8.101756619047618
    ],
    [
        28.474,
        -5.6345396857142855
    ],
    [
        28.4844,
        -5.223675485714286
    ],
    [
        28.4947,
        -3.5788641999999995
    ],
    [
        28.5051,
        -5.270205695238096
    ],
    [
        28.5154,
        -5.023483999999999
    ],
    [
        28.5258,
        -5.023484000000001
    ],
    [
        28.5362,
        -9.13551221904762
    ],
    [
        28.5465,
        -10.779872009523809
    ],
    [
        28.5569,
        -9.087601714285714
    ],
    [
        28.5673,
        -11.437138623809522
    ],
    [
        28.5776,
        -14.726761200000002
    ],
    [
        28.588,
        -8.805711466666665
    ],
    [
        28.5983,
        -9.011087128571427
    ],
    [
        28.6087,
        -11.231243742857142
    ],
    [
        28.6191,
        -12.053649385714285
    ],
    [
        28.6294,
        -15.343271957142857
    ],
    [
        28.6398,
        -14.10966349047619
    ],
    [
        28.6501,
        -17.398383066666668
    ],
    [
        28.6605,
        -15.424338623809525
    ],
    [
        28.6709,
        -13.77952733333333
    ],
    [
        28.6812,
        -17.48069135238095
    ],
    [
        28.6916,
        -18.303096995238093
    ],
    [
        28.702,
        -17.48069135238095
    ],
    [
        28.7123,
        -14.191068776190475
    ],
    [
        28.7227,
        -15.95288283333333
    ],
    [
        28.733,
        -15.582800295238092
    ],
    [
        28.7434,
        -12.128967490476189
    ],
    [
        28.7538,
        -12.128967490476189
    ],
    [
        28.7641,
        -13.773778776190476
    ],
    [
        28.7745,
        -16.12325119047619
    ],
    [
        28.7848,
        -16.24115696190476
    ],
    [
        28.7952,
        -11.3067231
    ],
    [
        28.8056,
        -18.708373895238097
    ],
    [
        28.8159,
        -19.11980246666667
    ],
    [
        28.8263,
        -13.199836328571429
    ],
    [
        28.8367,
        -12.788633509523809
    ],
    [
        28.847,
        -16.07690159047619
    ],
    [
        28.8574,
        -14.432090304761903
    ],
    [
        28.8677,
        -14.15818083809524
    ],
    [
        28.8781,
        -12.677850676190477
    ],
    [
        28.8885,
        -14.323339214285717
    ],
    [
        28.8988,
        -11.855445033333332
    ],
    [
        28.9092,
        -11.03303939047619
    ],
    [
        28.9196,
        -11.481624285714284
    ],
    [
        28.9299,
        -21.350492009523805
    ],
    [
        28.9403,
        -17.89706555714286
    ],
    [
        28.9506,
        -18.92507260952381
    ],
    [
        28.961,
        -23.858829223809522
    ],
    [
        28.9714,
        -19.629314485714286
    ],
    [
        28.9817,
        -17.9845032
    ],
    [
        28.9921,
        -17.045062533333333
    ],
    [
        29.0024,
        -16.222431138095235
    ],
    [
        29.0128,
        -18.690325319047616
    ],
    [
        29.0232,
        -17.456378228571428
    ],
    [
        29.0335,
        -17.867581047619048
    ],
    [
        29.0439,
        -20.334120733333336
    ],
    [
        29.0543,
        -21.567729200000002
    ],
    [
        29.0646,
        -17.8682583
    ],
    [
        29.075,
        -14.578635723809525
    ],
    [
        29.0853,
        -14.57863572380953
    ],
    [
        29.0957,
        -16.059236785714287
    ],
    [
        29.1061,
        -14.139613033333339
    ],
    [
        29.1164,
        -6.738639490476192
    ],
    [
        29.1268,
        -8.383450776190477
    ],
    [
        29.1371,
        -3.0017865142857154
    ],
    [
        29.1475,
        0.9454896761904757
    ],
    [
        29.1579,
        2.4251425857142856
    ],
    [
        29.1682,
        9.004387733333331
    ],
    [
        29.1786,
        9.826116128571428
    ],
    [
        29.189,
        15.465469114285714
    ],
    [
        29.1993,
        14.972161176190477
    ],
    [
        29.2097,
        14.032269014285713
    ],
    [
        29.22,
        15.6770803
    ],
    [
        29.2304,
        17.32256883809524
    ],
    [
        29.2408,
        17.322230214285714
    ],
    [
        29.2511,
        17.322230214285714
    ],
    [
        29.2615,
        15.8425773047619
    ],
    [
        29.2718,
        18.30979423809524
    ],
    [
        29.2822,
        14.60896884285714
    ],
    [
        29.2926,
        9.181091595238096
    ],
    [
        29.3029,
        10.716248795238096
    ],
    [
        29.3133,
        8.085273133333335
    ],
    [
        29.3237,
        6.988732276190479
    ],
    [
        29.334,
        -2.8801354476190455
    ],
    [
        29.3444,
        -1.8521283952380905
    ],
    [
        29.3547,
        -5.551599295238097
    ],
    [
        29.3651,
        -7.031658557142857
    ],
    [
        29.3755,
        -11.142558028571429
    ],
    [
        29.3858,
        -18.54420882380952
    ],
    [
        29.3962,
        -17.721803180952378
    ],
    [
        29.4066,
        -21.4229672
    ],
    [
        29.4169,
        -25.865447623809523
    ],
    [
        29.4273,
        -24.83727125714286
    ],
    [
        29.4376,
        -27.71569100952381
    ],
    [
        29.448,
        -27.88017213809524
    ],
    [
        29.4584,
        -25.412955204761904
    ],
    [
        29.4687,
        -26.234683599999997
    ],
    [
        29.4791,
        -27.926489495238094
    ],
    [
        29.4894,
        -29.571300780952377
    ],
    [
        29.4998,
        -19.70243305714285
    ],
    [
        29.5102,
        -19.757109599999996
    ],
    [
        29.5205,
        -20.74399637142857
    ],
    [
        29.5309,
        -14.166557214285714
    ],
    [
        29.5413,
        -17.456179790476188
    ],
    [
        29.5516,
        -12.521745928571429
    ],
    [
        29.562,
        -9.437047514285716
    ],
    [
        29.5723,
        -11.287290900000002
    ],
    [
        29.5827,
        -10.053682433333336
    ],
    [
        29.5931,
        -9.642592485714285
    ],
    [
        29.6034,
        -4.708158619047618
    ],
    [
        29.6138,
        -8.820186838095237
    ],
    [
        29.6241,
        -11.287065147619048
    ],
    [
        29.6345,
        -5.118006942857141
    ],
    [
        29.6449,
        -4.501372023809522
    ],
    [
        29.6552,
        -3.6789663809523794
    ],
    [
        29.6656,
        -5.159296542857142
    ],
    [
        29.676,
        -5.159296542857142
    ],
    [
        29.6863,
        -3.24102729047619
    ],
    [
        29.6967,
        -2.0875232714285694
    ],
    [
        29.707,
        -0.7950747952380953
    ],
    [
        29.7174,
        -5.729508657142856
    ],
    [
        29.7278,
        -6.1682755
    ],
    [
        29.7381,
        -3.7010585666666667
    ],
    [
        29.7485,
        -9.620753804761904
    ],
    [
        29.7588,
        -4.686319942857144
    ],
    [
        29.7692,
        -5.391239066666667
    ],
    [
        29.7796,
        -7.036727604761904
    ],
    [
        29.7899,
        -7.125011809523808
    ],
    [
        29.8003,
        -3.4241864095238093
    ],
    [
        29.8107,
        -3.835727852380952
    ],
    [
        29.821,
        -7.007670409523808
    ],
    [
        29.8314,
        -3.7180478333333333
    ],
    [
        29.8417,
        -3.3068450095238084
    ],
    [
        29.8521,
        -3.55349898095238
    ],
    [
        29.8625,
        -5.198310266666666
    ],
    [
        29.8728,
        -7.254324376190475
    ],
    [
        29.8832,
        -5.609513085714285
    ],
    [
        29.8936,
        -4.375904619047619
    ],
    [
        29.9039,
        -4.238837014285715
    ],
    [
        29.9143,
        -9.62185577142857
    ],
    [
        29.9246,
        -6.802953280952382
    ],
    [
        29.935,
        -11.737387142857143
    ],
    [
        29.9454,
        -9.516891904761902
    ],
    [
        29.9557,
        -12.689027961904761
    ],
    [
        29.9661,
        -13.34677188095238
    ],
    [
        29.9764,
        -15.813988814285713
    ],
    [
        29.9868,
        -10.175990323809524
    ],
    [
        29.9972,
        -8.530501785714284
    ],
    [
        30.0075,
        -8.274167557142858
    ],
    [
        30.0179,
        -10.74138449047619
    ],
    [
        30.0283,
        -14.02965257142857
    ],
    [
        30.0386,
        -13.324926947619046
    ],
    [
        30.049,
        -12.666731533333328
    ],
    [
        30.0593,
        -11.844325890476188
    ],
    [
        30.0697,
        -14.0642116047619
    ],
    [
        30.0801,
        -14.887294500000001
    ],
    [
        30.0904,
        -13.653347409523809
    ],
    [
        30.1008,
        -14.47575305238095
    ],
    [
        30.1111,
        -12.008536123809524
    ],
    [
        30.1215,
        -6.662899438095237
    ],
    [
        30.1319,
        -2.433191200000001
    ],
    [
        30.1422,
        -6.544993666666666
    ],
    [
        30.1526,
        -6.544993666666666
    ],
    [
        30.163,
        -9.422961923809524
    ],
    [
        30.1733,
        -9.70492957142857
    ],
    [
        30.1837,
        -12.993649147619047
    ],
    [
        30.194,
        -11.348837857142858
    ],
    [
        30.2044,
        -15.577191599999997
    ],
    [
        30.2148,
        -19.27903287142857
    ],
    [
        30.2251,
        -20.06405644285714
    ],
    [
        30.2355,
        -20.06405644285714
    ],
    [
        30.2459,
        -16.775336866666663
    ],
    [
        30.2562,
        -14.30811993333333
    ],
    [
        30.2666,
        -15.788720995238096
    ],
    [
        30.2769,
        -17.433080785714285
    ],
    [
        30.2873,
        -16.648518976190477
    ],
    [
        30.2977,
        -14.180624795238097
    ],
    [
        30.308,
        -13.769760595238093
    ],
    [
        30.3184,
        -12.12494930952381
    ],
    [
        30.3287,
        -19.526600104761904
    ],
    [
        30.3391,
        -24.46103396666666
    ],
    [
        30.3495,
        -21.289091409523802
    ],
    [
        30.3598,
        -20.630444495238088
    ],
    [
        30.3702,
        -16.40092975714286
    ],
    [
        30.3805,
        -14.756569966666667
    ],
    [
        30.3909,
        -8.835249333333334
    ],
    [
        30.4013,
        -3.9021699666666674
    ],
    [
        30.4116,
        -3.9021699666666656
    ],
    [
        30.422,
        -5.840504057142859
    ],
    [
        30.4324,
        -7.073773899999999
    ],
    [
        30.4427,
        -5.840165433333333
    ],
    [
        30.4531,
        -6.662571076190476
    ],
    [
        30.4634,
        -7.485428219047619
    ],
    [
        30.4738,
        -8.308285361904762
    ],
    [
        30.4842,
        -10.776856790476192
    ],
    [
        30.4945,
        -11.59903668095238
    ],
    [
        30.5049,
        -12.137215419047621
    ],
    [
        30.5153,
        -13.78157520952381
    ],
    [
        30.5256,
        -14.192778033333333
    ],
    [
        30.536,
        -14.74089796190476
    ],
    [
        30.5463,
        -10.628869742857145
    ],
    [
        30.5567,
        -12.274132528571428
    ],
    [
        30.5671,
        -12.274132528571426
    ],
    [
        30.5774,
        -9.643156866666667
    ],
    [
        30.5878,
        -9.486508171428572
    ],
    [
        30.5981,
        -14.419587538095238
    ],
    [
        30.6085,
        -20.587629866666667
    ],
    [
        30.6189,
        -25.522063728571432
    ],
    [
        30.6292,
        -26.344469371428573
    ],
    [
        30.6396,
        -26.34413074761905
    ],
    [
        30.65,
        -18.942479952380957
    ],
    [
        30.6603,
        -20.176088419047623
    ],
    [
        30.6707,
        -19.802267671428574
    ],
    [
        30.681,
        -16.348163966666668
    ],
    [
        30.6914,
        -22.926957619047624
    ],
    [
        30.7018,
        -19.63552904761905
    ],
    [
        30.7121,
        -15.524178080952378
    ],
    [
        30.7225,
        -9.602857447619046
    ],
    [
        30.7329,
        -8.945113528571428
    ],
    [
        30.7432,
        -6.066693776190476
    ],
    [
        30.7536,
        -6.50546061904762
    ],
    [
        30.7639,
        -10.617488838095237
    ],
    [
        30.7743,
        -10.20583451904762
    ],
    [
        30.7847,
        -13.166494838095238
    ],
    [
        30.795,
        -13.988674733333333
    ],
    [
        30.8054,
        -14.145323428571427
    ],
    [
        30.8157,
        -9.705687447619047
    ],
    [
        30.8261,
        -8.060876157142857
    ],
    [
        30.8365,
        -3.7430772142857154
    ],
    [
        30.8468,
        -3.085152700000001
    ],
    [
        30.8572,
        -1.029477214285715
    ],
    [
        30.8676,
        -8.431128009523812
    ],
    [
        30.8779,
        -7.814323776190476
    ],
    [
        30.8883,
        -2.43130501904762
    ],
    [
        30.8986,
        -2.595786147619049
    ],
    [
        30.909,
        1.6339220904761882
    ],
    [
        30.9194,
        1.6334705952380932
    ],
    [
        30.9297,
        -0.8337463333333353
    ],
    [
        30.9401,
        -0.8337463333333348
    ],
    [
        30.9504,
        -0.5520495857142871
    ],
    [
        30.9608,
        -0.5520495857142885
    ],
    [
        30.9712,
        -0.2698884380952405
    ],
    [
        30.9815,
        4.664545428571428
    ],
    [
        30.9919,
        5.349883466666665
    ],
    [
        31.0023,
        6.830213623809522
    ],
    [
        31.0126,
        5.0211469571428555
    ],
    [
        31.023,
        5.726066080952379
    ],
    [
        31.0333,
        5.602705233333332
    ],
    [
        31.0437,
        6.178118280952379
    ],
    [
        31.0541,
        5.5611447333333315
    ],
    [
        31.0644,
        6.054723566666666
    ],
    [
        31.0748,
        6.877129209523808
    ],
    [
        31.0851,
        8.357459371428568
    ],
    [
        31.0955,
        9.961150376190474
    ],
    [
        31.1059,
        3.3819052285714286
    ],
    [
        31.1162,
        0.9146882999999993
    ],
    [
        31.1266,
        6.553847785714285
    ],
    [
        31.137,
        7.376479176190476
    ],
    [
        31.1473,
        6.554073533333333
    ],
    [
        31.1577,
        1.6196396714285708
    ],
    [
        31.168,
        -0.14265813809523734
    ],
    [
        31.1784,
        -2.404104342857143
    ],
    [
        31.1888,
        -0.8769730761904765
    ],
    [
        31.1991,
        -0.877650328571429
    ],
    [
        31.2095,
        -0.9461841333333338
    ],
    [
        31.2199,
        -3.57833369047619
    ],
    [
        31.2302,
        -1.3578384523809528
    ],
    [
        31.2406,
        -2.0627575761904766
    ],
    [
        31.2509,
        -2.6792231857142856
    ],
    [
        31.2613,
        -1.1986221238095245
    ],
    [
        31.2717,
        4.968065709523809
    ],
    [
        31.282,
        4.967930261904762
    ],
    [
        31.2924,
        4.310005747619047
    ],
    [
        31.3027,
        4.848307623809525
    ],
    [
        31.3131,
        5.0946906952380955
    ],
    [
        31.3235,
        11.673935842857142
    ],
    [
        31.3338,
        11.673935842857142
    ],
    [
        31.3442,
        5.752615209523809
    ],
    [
        31.3546,
        5.587908333333334
    ],
    [
        31.3649,
        5.587908333333334
    ],
    [
        31.3753,
        4.88318270952381
    ],
    [
        31.3856,
        8.584008109523811
    ],
    [
        31.396,
        9.20064302857143
    ],
    [
        31.4064,
        7.3913506142857175
    ],
    [
        31.4167,
        4.219891809523811
    ],
    [
        31.4271,
        6.481507328571429
    ],
    [
        31.4374,
        5.660230433333335
    ],
    [
        31.4478,
        6.894516147619049
    ],
    [
        31.4582,
        7.105991885714287
    ],
    [
        31.4685,
        9.161441623809527
    ],
    [
        31.4789,
        5.989305566666668
    ],
    [
        31.4893,
        5.990660061904762
    ],
    [
        31.4996,
        4.839292161904762
    ],
    [
        31.51,
        2.2080456000000006
    ],
    [
        31.5203,
        -2.277803366666666
    ],
    [
        31.5307,
        -4.4979599809523805
    ],
    [
        31.5411,
        -11.899610776190476
    ],
    [
        31.5514,
        -15.189233352380953
    ],
    [
        31.5618,
        -15.847157866666665
    ],
    [
        31.5722,
        -14.860271095238096
    ],
    [
        31.5825,
        -15.682676742857144
    ],
    [
        31.5929,
        -13.744342652380954
    ],
    [
        31.6032,
        -15.426536014285713
    ],
    [
        31.6136,
        -12.959996333333333
    ],
    [
        31.624,
        -13.618372347619047
    ],
    [
        31.6343,
        -15.379992904761904
    ],
    [
        31.6447,
        -17.024804190476193
    ],
    [
        31.655,
        -15.051572447619042
    ],
    [
        31.6654,
        -12.583678266666666
    ],
    [
        31.6758,
        -17.023314247619048
    ],
    [
        31.6861,
        -17.84549414285714
    ],
    [
        31.6965,
        -18.784934809523808
    ],
    [
        31.7069,
        -25.364179957142856
    ],
    [
        31.7172,
        -18.786289304761905
    ],
    [
        31.7276,
        -18.37553798095238
    ],
    [
        31.7379,
        -14.263509761904759
    ],
    [
        31.7483,
        -12.65998807142857
    ],
    [
        31.7587,
        -11.179657909523806
    ],
    [
        31.769,
        -11.179657909523806
    ],
    [
        31.7794,
        -10.52200429523809
    ],
    [
        31.7898,
        -8.055464614285711
    ],
    [
        31.8001,
        -7.233058966666665
    ],
    [
        31.8105,
        -9.171393057142856
    ],
    [
        31.8208,
        -10.368070947619046
    ],
    [
        31.8312,
        -17.76768999523809
    ],
    [
        31.8416,
        -16.82734633333333
    ],
    [
        31.8519,
        -11.89426697142857
    ],
    [
        31.8623,
        -9.427050038095237
    ],
    [
        31.8726,
        -9.755921995238095
    ],
    [
        31.883,
        -9.755921995238092
    ],
    [
        31.8934,
        -6.467653914285713
    ],
    [
        31.9037,
        -4.822842628571428
    ],
    [
        31.9141,
        -4.822842628571428
    ],
    [
        31.9245,
        -3.8827569666666655
    ],
    [
        31.9348,
        -9.520948957142856
    ],
    [
        31.9452,
        -7.79403255238095
    ],
    [
        31.9555,
        -9.43884384285714
    ],
    [
        31.9659,
        -10.384531319047616
    ],
    [
        31.9763,
        -6.931104866666665
    ],
    [
        31.9866,
        -2.701396628571429
    ],
    [
        31.997,
        -0.8918333142857144
    ],
    [
        32.0073,
        -3.851951833333333
    ],
    [
        32.0177,
        -0.9738707047619044
    ],
    [
        32.0281,
        -5.203578942857143
    ],
    [
        32.0384,
        -3.558316157142856
    ],
    [
        32.0488,
        6.309197071428571
    ],
    [
        32.0592,
        6.465996266666671
    ],
    [
        32.0695,
        3.9994565857142867
    ],
    [
        32.0799,
        0.2986311857142863
    ],
    [
        32.0902,
        0.29840543809523856
    ],
    [
        32.1006,
        -3.9324637999999985
    ],
    [
        32.111,
        -3.2745392857142854
    ],
    [
        32.1213,
        -6.564161857142856
    ],
    [
        32.1317,
        -2.4532623857142846
    ],
    [
        32.142,
        -2.3653409952380944
    ],
    [
        32.1524,
        -2.647115142857142
    ],
    [
        32.1628,
        1.7940107809523806
    ],
    [
        32.1731,
        2.616416428571428
    ],
    [
        32.1835,
        2.945468985714285
    ],
    [
        32.1939,
        -0.7543405380952379
    ],
    [
        32.2042,
        -1.283562004761905
    ],
    [
        32.2146,
        -0.4611563619047626
    ],
    [
        32.2249,
        -0.7899831666666669
    ],
    [
        32.2353,
        2.9111808523809515
    ],
    [
        32.2457,
        5.378397780952381
    ],
    [
        32.256,
        4.1451279380952375
    ],
    [
        32.2664,
        -2.0225757666666677
    ],
    [
        32.2768,
        -1.474455838095239
    ],
    [
        32.2871,
        -6.407535204761906
    ],
    [
        32.2975,
        -3.5291154523809536
    ],
    [
        32.3078,
        -3.5288897047619066
    ],
    [
        32.3182,
        -5.467755919047621
    ],
    [
        32.3286,
        -6.1256804333333355
    ],
    [
        32.3389,
        -2.0143294666666693
    ],
    [
        32.3493,
        -9.41530300952381
    ],
    [
        32.3596,
        -9.785656447619049
    ],
    [
        32.37,
        -10.03203951904762
    ],
    [
        32.3804,
        -16.199743223809524
    ],
    [
        32.3907,
        -17.021471619047617
    ],
    [
        32.4011,
        -15.376208833333331
    ],
    [
        32.4114,
        -16.60947867142857
    ],
    [
        32.4218,
        -16.362418352380956
    ],
    [
        32.4322,
        -18.829409533333333
    ],
    [
        32.4425,
        -13.069861033333334
    ],
    [
        32.4529,
        -19.237564738095234
    ],
    [
        32.4633,
        -18.003617647619045
    ],
    [
        32.4736,
        -14.303808123809521
    ],
    [
        32.484,
        -15.53843246190476
    ],
    [
        32.4943,
        -16.77204092857143
    ],
    [
        32.5047,
        -9.372421880952382
    ],
    [
        32.5151,
        -11.017233166666669
    ],
    [
        32.5254,
        -11.428323114285716
    ],
    [
        32.5358,
        -11.838735814285716
    ],
    [
        32.5462,
        -11.180811300000002
    ],
    [
        32.5565,
        -14.264324523809526
    ],
    [
        32.5669,
        -8.781620233333335
    ],
    [
        32.5772,
        -8.123334519047622
    ],
    [
        32.5876,
        -5.656794833333334
    ],
    [
        32.598,
        -6.068787776190475
    ],
    [
        32.6083,
        -2.8975224714285717
    ],
    [
        32.6187,
        -5.091055685714285
    ],
    [
        32.629,
        -1.9195968809523807
    ],
    [
        32.6394,
        -1.5495143428571432
    ],
    [
        32.6498,
        -2.3716942380952384
    ],
    [
        32.6601,
        -12.243270957142856
    ],
    [
        32.6705,
        -13.477218047619047
    ],
    [
        32.6809,
        -13.477556671428571
    ],
    [
        32.6912,
        -20.878530214285714
    ],
    [
        32.7016,
        -18.95890646190476
    ],
    [
        32.7119,
        -17.020572371428567
    ],
    [
        32.7223,
        -20.103916285714288
    ],
    [
        32.7327,
        -22.57113321904762
    ],
    [
        32.743,
        -21.831171314285715
    ],
    [
        32.7534,
        -19.4818924
    ],
    [
        32.7637,
        -19.728952719047616
    ],
    [
        32.7741,
        -24.04641303809523
    ],
    [
        32.7845,
        -25.581299338095235
    ],
    [
        32.7948,
        -22.7858877
    ],
    [
        32.8052,
        -25.00631521428571
    ],
    [
        32.8156,
        -24.59432227142857
    ],
    [
        32.8259,
        -24.312161123809524
    ],
    [
        32.8363,
        -24.09294325238095
    ],
    [
        32.8466,
        -24.181227457142857
    ],
    [
        32.857,
        -24.551039095238096
    ],
    [
        32.8674,
        -24.55103909523809
    ],
    [
        32.8777,
        -20.6037629047619
    ],
    [
        32.8881,
        -16.902598880952382
    ],
    [
        32.8984,
        -16.65560628571429
    ],
    [
        32.9088,
        -11.721172423809524
    ],
    [
        32.9192,
        -11.666480833333333
    ],
    [
        32.9295,
        -11.549026561904762
    ],
    [
        32.9399,
        -12.57658211904762
    ],
    [
        32.9503,
        -8.464553899999999
    ],
    [
        32.9606,
        -7.971245961904761
    ],
    [
        32.971,
        -9.124626847619048
    ],
    [
        32.9813,
        -7.890679757142857
    ],
    [
        32.9917,
        -5.423462828571429
    ],
    [
        33.0021,
        -9.372635319047621
    ],
    [
        33.0124,
        -12.387294928571428
    ],
    [
        33.0228,
        -11.400137257142857
    ],
    [
        33.0332,
        -8.932920323809523
    ],
    [
        33.0435,
        -10.536442014285713
    ],
    [
        33.0539,
        -10.65585121904762
    ],
    [
        33.0642,
        -10.450306242857142
    ],
    [
        33.0746,
        -4.52790200952381
    ],
    [
        33.085,
        -2.8835422190476176
    ],
    [
        33.0953,
        -2.7187901904761893
    ],
    [
        33.1057,
        -6.419954214285713
    ],
    [
        33.116,
        -5.433338342857142
    ],
    [
        33.1264,
        -7.077698133333332
    ],
    [
        33.1368,
        -6.584119295238093
    ],
    [
        33.1471,
        -8.111411814285713
    ],
    [
        33.1575,
        -7.700660490476189
    ],
    [
        33.1679,
        -8.797351847619046
    ],
    [
        33.1782,
        -9.345622276190474
    ],
    [
        33.1886,
        -3.9627266523809523
    ],
    [
        33.1989,
        -5.607086442857143
    ],
    [
        33.2093,
        -5.607086442857144
    ],
    [
        33.2197,
        -1.2195685095238094
    ],
    [
        33.23,
        0.15118281428571478
    ],
    [
        33.2404,
        -2.3153568666666664
    ],
    [
        33.2508,
        -4.042747342857141
    ],
    [
        33.2611,
        -8.360546285714284
    ],
    [
        33.2715,
        -7.912084523809524
    ],
    [
        33.2818,
        -9.721151190476188
    ],
    [
        33.2922,
        -15.479345195238093
    ],
    [
        33.3026,
        -10.544911333333332
    ],
    [
        33.3129,
        -4.788071828571428
    ],
    [
        33.3233,
        -7.253934261904763
    ],
    [
        33.3336,
        -4.78671732857143
    ],
    [
        33.344,
        -4.3759660047619064
    ],
    [
        33.3544,
        -5.609574471428573
    ],
    [
        33.3647,
        0.029972014285712185
    ],
    [
        33.3751,
        0.7154605523809507
    ],
    [
        33.3855,
        1.812603409523808
    ],
    [
        33.3958,
        7.293953204761904
    ],
    [
        33.4062,
        1.6547937190476183
    ],
    [
        33.4165,
        4.943964795238093
    ],
    [
        33.4269,
        6.7062626047619025
    ],
    [
        33.4373,
        5.609270247619046
    ],
    [
        33.4476,
        9.719718219047618
    ],
    [
        33.458,
        7.2531785333333305
    ],
    [
        33.4683,
        6.101359133333331
    ],
    [
        33.4787,
        8.569253314285712
    ],
    [
        33.4891,
        3.634819452380951
    ],
    [
        33.4994,
        3.916593599999999
    ],
    [
        33.5098,
        7.205538923809521
    ],
    [
        33.5202,
        0.6267452714285712
    ],
    [
        33.5305,
        -2.6633288000000013
    ],
    [
        33.5409,
        1.564637942857142
    ],
    [
        33.5512,
        -3.369795923809523
    ],
    [
        33.5616,
        -2.684457885714287
    ],
    [
        33.572,
        -0.2175795761904768
    ],
    [
        33.5823,
        -5.8571260619047605
    ],
    [
        33.5927,
        -2.8424664523809513
    ],
    [
        33.6031,
        -4.487729238095238
    ],
    [
        33.6134,
        -10.037443523809523
    ],
    [
        33.6238,
        -9.332717899999999
    ],
    [
        33.6341,
        -10.977529185714287
    ],
    [
        33.6445,
        -5.339530695238094
    ],
    [
        33.6549,
        -4.68142558095238
    ],
    [
        33.6652,
        -10.601391719047623
    ],
    [
        33.6756,
        -3.2017726714285706
    ],
    [
        33.6859,
        0.08830140000000124
    ],
    [
        33.6963,
        2.006570652380953
    ],
    [
        33.7067,
        2.006570652380953
    ],
    [
        33.717,
        1.4784134333333334
    ],
    [
        33.7274,
        -0.9881262476190471
    ],
    [
        33.7378,
        -0.9890292428571426
    ],
    [
        33.7481,
        -0.9890292428571424
    ],
    [
        33.7585,
        -0.9007208523809519
    ],
    [
        33.7688,
        -0.9013981000000002
    ],
    [
        33.7792,
        -2.820344604761905
    ],
    [
        33.7896,
        0.87946491904762
    ],
    [
        33.7999,
        0.7623976476190486
    ],
    [
        33.8103,
        -6.639930395238095
    ],
    [
        33.8206,
        -1.7068510285714287
    ],
    [
        33.831,
        -1.0902161095238092
    ],
    [
        33.8414,
        -1.6384865380952376
    ],
    [
        33.8517,
        3.2959473238095236
    ],
    [
        33.8621,
        -2.342051166666668
    ],
    [
        33.8725,
        -3.8214331761904754
    ],
    [
        33.8828,
        -0.3673294714285709
    ],
    [
        33.8932,
        -7.768303014285715
    ],
    [
        33.9035,
        -9.961384728571428
    ],
    [
        33.9139,
        -7.768303014285715
    ],
    [
        33.9243,
        -4.067477619047619
    ],
    [
        33.9346,
        -1.6005993095238107
    ],
    [
        33.945,
        -2.2172342285714297
    ],
    [
        33.9553,
        -1.8055799095238092
    ],
    [
        33.9657,
        1.4844941619047611
    ],
    [
        33.9761,
        -2.8331354666666666
    ],
    [
        33.9864,
        -1.0699669095238096
    ],
    [
        33.9968,
        2.6308584904761894
    ],
    [
        34.0072,
        -2.850641804761905
    ],
    [
        34.0175,
        0.4380777714285718
    ],
    [
        34.0279,
        2.9066491999999995
    ],
    [
        34.0382,
        -3.259700009523809
    ],
    [
        34.0486,
        -4.082331404761904
    ],
    [
        34.059,
        1.399018390476191
    ],
    [
        34.0693,
        -2.713235576190477
    ],
    [
        34.0797,
        -2.337369009523811
    ],
    [
        34.09,
        0.8338962952380944
    ],
    [
        34.1004,
        -2.4557262809523803
    ],
    [
        34.1108,
        4.123518866666666
    ],
    [
        34.1211,
        3.8491579000000007
    ],
    [
        34.1315,
        0.9710767714285711
    ],
    [
        34.1419,
        4.671224919047619
    ],
    [
        34.1522,
        2.4510005809523805
    ],
    [
        34.1626,
        4.054793171428571
    ],
    [
        34.1729,
        4.877198814285714
    ],
    [
        34.1833,
        0.5593998714285714
    ],
    [
        34.1937,
        4.78910810952381
    ],
    [
        34.204,
        4.259886642857143
    ],
    [
        34.2144,
        3.5745486047619046
    ],
    [
        34.2248,
        3.6296615952380953
    ],
    [
        34.2351,
        -0.48168937142857227
    ],
    [
        34.2455,
        -2.9502608
    ],
    [
        34.2558,
        -2.9502608
    ],
    [
        34.2662,
        -2.1276294047619038
    ],
    [
        34.2766,
        -7.060708771428573
    ],
    [
        34.2869,
        -9.528151452380953
    ],
    [
        34.2973,
        -11.66631582857143
    ],
    [
        34.3076,
        -9.904501771428572
    ],
    [
        34.318,
        -9.787015252380954
    ],
    [
        34.3284,
        -13.899720719047624
    ],
    [
        34.3387,
        -18.010168690476196
    ],
    [
        34.3491,
        -19.24343852857143
    ],
    [
        34.3595,
        -22.69686498095238
    ],
    [
        34.3698,
        -19.24235492857143
    ],
    [
        34.3802,
        -22.696052280952383
    ],
    [
        34.3905,
        -24.752066390476188
    ],
    [
        34.4009,
        -26.35558808095238
    ],
    [
        34.4113,
        -26.884470923809523
    ],
    [
        34.4216,
        -25.03388891428571
    ],
    [
        34.432,
        -26.198963576190472
    ],
    [
        34.4423,
        -30.641444
    ],
    [
        34.4527,
        -29.613267633333336
    ],
    [
        34.4631,
        -19.74304540952382
    ],
    [
        34.4734,
        -20.15413535714286
    ],
    [
        34.4838,
        -21.799398142857143
    ],
    [
        34.4942,
        -20.812782271428574
    ],
    [
        34.5045,
        -19.715789914285715
    ],
    [
        34.5149,
        -12.315493614285717
    ],
    [
        34.5252,
        -15.399006838095241
    ],
    [
        34.5356,
        -13.460817871428574
    ],
    [
        34.546,
        -8.52570675714286
    ],
    [
        34.5563,
        -3.972199223809522
    ],
    [
        34.5667,
        -6.440093404761905
    ],
    [
        34.577,
        -7.920423566666666
    ],
    [
        34.5874,
        -9.566137852380947
    ],
    [
        34.5978,
        -7.922229566666666
    ],
    [
        34.6081,
        -10.388091999999999
    ],
    [
        34.6185,
        -9.894513166666666
    ],
    [
        34.6289,
        -7.427296233333335
    ],
    [
        34.6392,
        -3.1081427904761902
    ],
    [
        34.6496,
        -3.4779544285714286
    ],
    [
        34.6599,
        -3.476599928571429
    ],
    [
        34.6703,
        -3.6821449047619046
    ],
    [
        34.6807,
        -7.793947371428571
    ],
    [
        34.691,
        -6.5980493428571405
    ],
    [
        34.7014,
        -9.887220419047615
    ],
    [
        34.7118,
        -10.873836290476188
    ],
    [
        34.7221,
        -5.389777499999998
    ],
    [
        34.7325,
        -12.789396547619045
    ],
    [
        34.7428,
        -13.405692847619047
    ],
    [
        34.7532,
        -13.652075919047618
    ],
    [
        34.7636,
        -17.764104138095234
    ],
    [
        34.7739,
        -17.83324033809523
    ],
    [
        34.7843,
        -11.912190604761904
    ],
    [
        34.7946,
        -9.85606361904762
    ],
    [
        34.805,
        -10.061269971428569
    ],
    [
        34.8154,
        -10.225254447619047
    ],
    [
        34.8257,
        -10.22660894285714
    ],
    [
        34.8361,
        -4.800221638095236
    ],
    [
        34.8465,
        -6.033830104761902
    ],
    [
        34.8568,
        -11.791798361904759
    ],
    [
        34.8672,
        -15.73961635238095
    ],
    [
        34.8775,
        -11.51010161428571
    ],
    [
        34.8879,
        -10.687921719047617
    ],
    [
        34.8983,
        -13.1553644
    ],
    [
        34.9086,
        -15.173442319047616
    ],
    [
        34.919,
        -5.303220095238091
    ],
    [
        34.9293,
        -5.303220095238091
    ],
    [
        34.9397,
        -15.173442319047618
    ],
    [
        34.9501,
        -12.706902638095238
    ],
    [
        34.9604,
        -12.296038438095232
    ],
    [
        34.9708,
        -8.348491347619046
    ],
    [
        34.9812,
        -8.348491347619046
    ],
    [
        34.9915,
        -8.44805374285714
    ],
    [
        35.0019,
        -14.369103476190473
    ],
    [
        35.0122,
        -14.780419171428564
    ],
    [
        35.0226,
        -14.575212819047614
    ],
    [
        35.033,
        -16.055813880952375
    ],
    [
        35.0433,
        -12.7661913047619
    ],
    [
        35.0537,
        -20.166487604761905
    ],
    [
        35.064,
        -21.810847395238095
    ],
    [
        35.0744,
        -21.97528337142857
    ],
    [
        35.0848,
        -15.395586723809524
    ],
    [
        35.0951,
        -14.690667599999998
    ],
    [
        35.1055,
        -15.238938028571427
    ],
    [
        35.1159,
        -8.661047376190476
    ],
    [
        35.1262,
        -11.130296057142854
    ],
    [
        35.1366,
        -17.710444209523807
    ],
    [
        35.1469,
        -19.354803999999998
    ],
    [
        35.1573,
        -16.065181423809523
    ],
    [
        35.1677,
        -18.533075604761905
    ],
    [
        35.178,
        -22.64374932857143
    ],
    [
        35.1884,
        -28.81145303333334
    ],
    [
        35.1988,
        -28.399911590476194
    ],
    [
        35.2091,
        -29.085588252380955
    ],
    [
        35.2195,
        -22.506343104761903
    ],
    [
        35.2298,
        -22.30074169523809
    ],
    [
        35.2402,
        -22.136305719047616
    ],
    [
        35.2506,
        -21.314351571428567
    ],
    [
        35.2609,
        -22.136079966666664
    ],
    [
        35.2713,
        -24.601942399999995
    ],
    [
        35.2816,
        -23.77998825238095
    ],
    [
        35.292,
        -17.85893851904762
    ],
    [
        35.3024,
        -21.14901259047619
    ],
    [
        35.3127,
        -22.13562846190476
    ],
    [
        35.3231,
        -19.1187866047619
    ],
    [
        35.3335,
        -24.599985899999997
    ],
    [
        35.3438,
        -18.84201764285714
    ],
    [
        35.3542,
        -19.664874785714286
    ],
    [
        35.3645,
        -18.84314639047619
    ],
    [
        35.3749,
        -16.211719233333334
    ],
    [
        35.3853,
        -14.360459971428574
    ],
    [
        35.3956,
        -7.78256931904762
    ],
    [
        35.406,
        -9.016516409523812
    ],
    [
        35.4163,
        -9.428057852380954
    ],
    [
        35.4267,
        -9.180726633333332
    ],
    [
        35.4371,
        -12.059146385714287
    ],
    [
        35.4474,
        -12.676289242857143
    ],
    [
        35.4578,
        -14.649520985714286
    ],
    [
        35.4682,
        -12.455987771428571
    ],
    [
        35.4785,
        -10.694367214285716
    ],
    [
        35.4889,
        -8.228504780952381
    ],
    [
        35.4992,
        -12.339178504761906
    ],
    [
        35.5096,
        -17.27361236666667
    ],
    [
        35.52,
        -16.86240954761905
    ],
    [
        35.5303,
        -18.343010609523812
    ],
    [
        35.5407,
        -20.811582038095242
    ],
    [
        35.5511,
        -15.329028247619053
    ],
    [
        35.5614,
        -16.59979303809524
    ],
    [
        35.5718,
        -13.145960233333334
    ],
    [
        35.5821,
        -13.556937304761906
    ],
    [
        35.5925,
        -15.365733071428574
    ],
    [
        35.6029,
        -15.983045242857148
    ],
    [
        35.6132,
        -12.694777161904764
    ],
    [
        35.6236,
        -11.461168695238097
    ],
    [
        35.6339,
        -10.364477338095238
    ],
    [
        35.6443,
        -8.829440538095238
    ],
    [
        35.6547,
        -8.300751195238096
    ],
    [
        35.665,
        -6.0801882333333355
    ],
    [
        35.6754,
        -5.915752257142858
    ],
    [
        35.6858,
        -5.230075595238096
    ],
    [
        35.6961,
        -5.347562114285714
    ],
    [
        35.7065,
        -3.8669610523809514
    ],
    [
        35.7168,
        3.5326579952380945
    ],
    [
        35.7272,
        1.0647638142857137
    ],
    [
        35.7376,
        -2.634707085714286
    ],
    [
        35.7479,
        -1.8118499428571406
    ],
    [
        35.7583,
        -4.279744123809522
    ],
    [
        35.7686,
        -9.831151528571429
    ],
    [
        35.779,
        -14.315646
    ],
    [
        35.7894,
        -12.83572219047619
    ],
    [
        35.7997,
        -12.150534652380951
    ],
    [
        35.8101,
        -11.328354757142856
    ],
    [
        35.8205,
        -12.560947347619049
    ],
    [
        35.8308,
        -16.507139942857144
    ],
    [
        35.8412,
        -15.767110314285715
    ],
    [
        35.8515,
        -17.685530066666665
    ],
    [
        35.8619,
        -23.16703036190476
    ],
    [
        35.8723,
        -24.929328171428573
    ],
    [
        35.8826,
        -25.915944042857145
    ],
    [
        35.893,
        -22.626547214285715
    ],
    [
        35.9034,
        -24.477129223809523
    ],
    [
        35.9137,
        -23.65472358095238
    ],
    [
        35.9241,
        -24.312467499999997
    ],
    [
        35.9344,
        -31.712086547619048
    ],
    [
        35.9448,
        -30.23107913809524
    ],
    [
        35.9552,
        -20.36356590952381
    ],
    [
        35.9655,
        -19.952814585714286
    ],
    [
        35.9759,
        -18.71819024761905
    ],
    [
        35.9862,
        -18.101216704761903
    ],
    [
        35.9966,
        -14.40208442857143
    ],
    [
        36.007,
        -16.86862410952381
    ],
    [
        36.0173,
        -17.30708995238095
    ],
    [
        36.0277,
        -12.374010585714283
    ],
    [
        36.0381,
        -10.356178938095239
    ],
    [
        36.0484,
        -8.876526028571428
    ],
    [
        36.0588,
        -10.850164123809524
    ],
    [
        36.0691,
        -6.739264652380958
    ],
    [
        36.0795,
        -2.4231588285714287
    ],
    [
        36.0899,
        4.977137471428571
    ],
    [
        36.1002,
        0.04270360952380954
    ],
    [
        36.1106,
        -2.4245133238095233
    ],
    [
        36.1209,
        -6.742312266666666
    ],
    [
        36.1313,
        -1.807878404761904
    ],
    [
        36.1417,
        -0.9856985095238092
    ],
    [
        36.152,
        6.41527503809524
    ],
    [
        36.1624,
        5.757350523809526
    ],
    [
        36.1728,
        0.0007367666666680302
    ],
    [
        36.1831,
        3.700884914285715
    ],
    [
        36.1935,
        2.466260576190477
    ],
    [
        36.2038,
        1.2329907333333339
    ],
    [
        36.2142,
        1.7620187000000027
    ],
    [
        36.2246,
        -3.1724151619047585
    ],
    [
        36.2349,
        -7.121316752380949
    ],
    [
        36.2453,
        -14.520935799999998
    ],
    [
        36.2557,
        -15.716587557142857
    ],
    [
        36.266,
        -18.18312723809524
    ],
    [
        36.2764,
        -13.24801612380952
    ],
    [
        36.2867,
        -15.714555809523807
    ],
    [
        36.2971,
        -16.084469038095236
    ],
    [
        36.3075,
        -19.373188614285713
    ],
    [
        36.3178,
        -19.374543114285714
    ],
    [
        36.3282,
        -20.07946223809524
    ],
    [
        36.3385,
        -12.678488695238094
    ],
    [
        36.3489,
        -20.07946223809524
    ],
    [
        36.3593,
        -20.902545128571425
    ],
    [
        36.3696,
        -25.014799099999998
    ],
    [
        36.38,
        -25.014347604761902
    ],
    [
        36.3903,
        -26.660061890476193
    ],
    [
        36.4007,
        -34.062389933333336
    ],
    [
        36.4111,
        -32.58111162380952
    ],
    [
        36.4214,
        -36.28092114761905
    ],
    [
        36.4318,
        -30.638859166666666
    ],
    [
        36.4422,
        -20.77134593809524
    ],
    [
        36.4525,
        -16.657737471428568
    ],
    [
        36.4629,
        -14.191197790476192
    ],
    [
        36.4732,
        -17.482626361904764
    ],
    [
        36.4836,
        -18.30480625714286
    ],
    [
        36.494,
        -18.305483504761906
    ],
    [
        36.5043,
        -18.188029233333335
    ],
    [
        36.5147,
        -22.135576323809524
    ],
    [
        36.5251,
        -18.844147752380955
    ],
    [
        36.5354,
        -15.552719180952382
    ],
    [
        36.5458,
        -15.834686828571432
    ],
    [
        36.5561,
        -19.5351736
    ],
    [
        36.5665,
        -17.06863391904762
    ],
    [
        36.5769,
        -14.190101290476191
    ],
    [
        36.5872,
        -13.250660623809525
    ],
    [
        36.5976,
        -10.901381709523811
    ],
    [
        36.6079,
        -9.050291761904763
    ],
    [
        36.6183,
        -2.4701436095238085
    ],
    [
        36.6287,
        -2.4706854095238087
    ],
    [
        36.639,
        1.6402140619047638
    ],
    [
        36.6494,
        -4.941740080952379
    ],
    [
        36.6598,
        -8.230008161904763
    ],
    [
        36.6701,
        -9.05263955238095
    ],
    [
        36.6805,
        -13.987073414285712
    ],
    [
        36.6908,
        -10.03817182380952
    ],
    [
        36.7012,
        -9.215991928571427
    ],
    [
        36.7116,
        -9.21666918095238
    ],
    [
        36.7219,
        -9.21705618095238
    ],
    [
        36.7323,
        -9.215701685714285
    ],
    [
        36.7426,
        -16.61802972857143
    ],
    [
        36.753,
        -16.207617028571427
    ],
    [
        36.7634,
        -10.286296395238095
    ],
    [
        36.7737,
        -9.8745292
    ],
    [
        36.7841,
        -8.229266414285714
    ],
    [
        36.7945,
        -14.396970119047616
    ],
    [
        36.8048,
        -14.925659461904761
    ],
    [
        36.8152,
        -14.39730874285714
    ],
    [
        36.8255,
        -14.397139428571428
    ],
    [
        36.8359,
        -16.86413061428571
    ],
    [
        36.8463,
        -13.410433261904766
    ],
    [
        36.8566,
        -13.410433261904766
    ],
    [
        36.867,
        -11.765621976190474
    ],
    [
        36.8773,
        -14.64471897619048
    ],
    [
        36.8877,
        -14.233177533333329
    ],
    [
        36.8981,
        -7.654383880952384
    ],
    [
        36.9084,
        -4.20095742857143
    ],
    [
        36.9188,
        -5.023588823809525
    ],
    [
        36.9292,
        -8.477286176190479
    ],
    [
        36.9395,
        -2.8390941857142864
    ],
    [
        36.9499,
        -0.372554500000001
    ],
    [
        36.9602,
        3.7392479666666665
    ],
    [
        36.9706,
        4.620832247619047
    ],
    [
        36.981,
        0.5085782809523824
    ],
    [
        36.9913,
        -1.1364587571428577
    ],
    [
        37.0017,
        -4.426984328571429
    ],
    [
        37.012,
        0.15508672380952376
    ],
    [
        37.0224,
        1.7998980142857137
    ],
    [
        37.0328,
        -0.4199876999999999
    ],
    [
        37.0431,
        2.6638641523809516
    ],
    [
        37.0535,
        2.938074619047618
    ],
    [
        37.0639,
        2.1158947285714285
    ],
    [
        37.0742,
        1.293263338095238
    ],
    [
        37.0846,
        3.7604802714285714
    ],
    [
        37.0949,
        0.06033212380952369
    ],
    [
        37.1053,
        0.4714220714285717
    ],
    [
        37.1157,
        -3.640831900000001
    ],
    [
        37.126,
        -7.093987452380951
    ],
    [
        37.1364,
        -3.8041391238095237
    ],
    [
        37.1468,
        -3.521707076190476
    ],
    [
        37.1571,
        -9.277417838095237
    ],
    [
        37.1675,
        -6.106152533333334
    ],
    [
        37.1778,
        -8.36805023809524
    ],
    [
        37.1882,
        -9.660950214285714
    ],
    [
        37.1986,
        -9.249521642857143
    ],
    [
        37.2089,
        -7.275748095238095
    ],
    [
        37.2193,
        -8.097024990476191
    ],
    [
        37.2296,
        -7.744662180952382
    ],
    [
        37.24,
        -9.389812095238096
    ],
    [
        37.2504,
        -7.169926380952381
    ],
    [
        37.2607,
        -7.991880528571428
    ],
    [
        37.2711,
        -7.8545495476190474
    ],
    [
        37.2815,
        -4.563120976190475
    ],
    [
        37.2918,
        -0.45177000952381025
    ],
    [
        37.3022,
        -3.535621861904761
    ],
    [
        37.3125,
        0.4116543285714278
    ],
    [
        37.3229,
        6.168268085714284
    ],
    [
        37.3333,
        9.869770733333334
    ],
    [
        37.3436,
        10.151660980952382
    ],
    [
        37.354,
        12.617523414285714
    ],
    [
        37.3644,
        12.499617642857142
    ],
    [
        37.3747,
        12.500069142857141
    ],
    [
        37.3851,
        12.500069142857141
    ],
    [
        37.3954,
        12.294524166666665
    ],
    [
        37.4058,
        15.585049738095236
    ],
    [
        37.4162,
        16.817980952380953
    ],
    [
        37.4265,
        16.07781587142857
    ],
    [
        37.4369,
        17.722175657142852
    ],
    [
        37.4472,
        15.254958723809517
    ],
    [
        37.4576,
        21.42469417619047
    ],
    [
        37.468,
        19.4866987095238
    ],
    [
        37.4783,
        22.77722428571428
    ],
    [
        37.4887,
        21.954367142857137
    ],
    [
        37.499,
        12.085499414285712
    ],
    [
        37.5094,
        14.552039099999998
    ],
    [
        37.5198,
        14.181516347619048
    ],
    [
        37.5301,
        13.524314228571427
    ],
    [
        37.5405,
        8.589880366666668
    ],
    [
        37.5509,
        2.42183803809524
    ],
    [
        37.5612,
        3.1265636619047648
    ],
    [
        37.5716,
        -2.511434828571427
    ],
    [
        37.5819,
        -0.45519496666666576
    ],
    [
        37.5923,
        -0.8662849142857125
    ],
    [
        37.6027,
        -2.5581682095238087
    ],
    [
        37.613,
        -3.3803481047619033
    ],
    [
        37.6234,
        -9.96004475238095
    ],
    [
        37.6338,
        -14.89312411428571
    ],
    [
        37.6441,
        -12.01425286190476
    ],
    [
        37.6545,
        -12.836432757142855
    ],
    [
        37.6648,
        -11.355831695238093
    ],
    [
        37.6752,
        -18.758159738095234
    ],
    [
        37.6856,
        -19.2873812047619
    ],
    [
        37.6959,
        -24.223169566666662
    ],
    [
        37.7063,
        -23.976380147619043
    ],
    [
        37.7166,
        -20.030458452380948
    ],
    [
        37.727,
        -20.029103957142855
    ],
    [
        37.7374,
        -21.508485966666665
    ],
    [
        37.7477,
        -21.508937461904758
    ],
    [
        37.7581,
        -23.97615439523809
    ],
    [
        37.7685,
        -23.976154395238094
    ],
    [
        37.7788,
        -24.592789314285717
    ],
    [
        37.7892,
        -22.901254319047617
    ],
    [
        37.7995,
        -24.628441623809522
    ],
    [
        37.8099,
        -25.861711461904765
    ],
    [
        37.8203,
        -24.257918871428576
    ],
    [
        37.8306,
        -24.25791887142857
    ],
    [
        37.841,
        -19.94045855238095
    ],
    [
        37.8514,
        -12.538130509523807
    ],
    [
        37.8617,
        -14.888118919047619
    ],
    [
        37.8721,
        -13.243759128571426
    ],
    [
        37.8824,
        -11.434963361904758
    ],
    [
        37.8928,
        -9.955039552380955
    ],
    [
        37.9032,
        -9.707708333333333
    ],
    [
        37.9135,
        -10.694866004761904
    ],
    [
        37.9239,
        -4.771919971428571
    ],
    [
        37.9342,
        -8.717841666666667
    ],
    [
        37.9446,
        -15.29663531904762
    ],
    [
        37.955,
        -7.894307276190478
    ],
    [
        37.9653,
        -11.182575357142856
    ],
    [
        37.9757,
        -9.701974295238095
    ],
    [
        37.9861,
        -5.590171828571427
    ],
    [
        37.9964,
        -2.505642728571427
    ],
    [
        38.0068,
        -1.025718919047618
    ],
    [
        38.0171,
        -1.518755952380952
    ],
    [
        38.0275,
        -0.03937394285714206
    ],
    [
        38.0379,
        -1.4781887571428567
    ],
    [
        38.0482,
        1.8105308238095237
    ],
    [
        38.0586,
        4.894044047619047
    ],
    [
        38.0689,
        -0.746856938095238
    ],
    [
        38.0793,
        4.890948052380954
    ],
    [
        38.0897,
        4.890948052380954
    ],
    [
        38.1,
        1.6022284761904768
    ],
    [
        38.1104,
        0.12162741428571505
    ],
    [
        38.1208,
        -0.5370194999999985
    ],
    [
        38.1311,
        5.3832175380952405
    ],
    [
        38.1415,
        -0.7861792904761918
    ],
    [
        38.1518,
        4.146900076190477
    ],
    [
        38.1622,
        5.0865342428571445
    ],
    [
        38.1726,
        0.9729257761904762
    ],
    [
        38.1829,
        5.5263291142857165
    ],
    [
        38.1933,
        6.512944985714287
    ],
    [
        38.2036,
        5.691216590476193
    ],
    [
        38.214,
        3.223322409523811
    ],
    [
        38.2244,
        2.4015940142857146
    ],
    [
        38.2347,
        2.15460141904762
    ],
    [
        38.2451,
        1.908489247619048
    ],
    [
        38.2555,
        3.9642776095238115
    ],
    [
        38.2658,
        1.8712097857142864
    ],
    [
        38.2762,
        -5.528409261904762
    ],
    [
        38.2865,
        -3.178485352380952
    ],
    [
        38.2969,
        -10.578781652380954
    ],
    [
        38.3073,
        -13.045998585714287
    ],
    [
        38.3176,
        -12.223818695238098
    ],
    [
        38.328,
        -8.934421866666666
    ],
    [
        38.3384,
        -8.276316752380952
    ],
    [
        38.3487,
        -14.19601199047619
    ],
    [
        38.3591,
        -15.428943204761904
    ],
    [
        38.3694,
        -16.134055828571427
    ],
    [
        38.3798,
        -14.60669880952381
    ],
    [
        38.3902,
        -16.66248717142857
    ],
    [
        38.4005,
        -16.693901090476192
    ],
    [
        38.4109,
        -15.988981966666667
    ],
    [
        38.4212,
        -15.331508947619048
    ],
    [
        38.4316,
        -16.31839571904762
    ],
    [
        38.442,
        -17.963207004761905
    ],
    [
        38.4523,
        -17.96286838095238
    ],
    [
        38.4627,
        -21.6653869
    ],
    [
        38.4731,
        -25.365535047619048
    ],
    [
        38.4834,
        -26.56118680476191
    ],
    [
        38.4938,
        -19.161567757142855
    ],
    [
        38.5041,
        -21.423352585714287
    ],
    [
        38.5145,
        -21.422675333333334
    ],
    [
        38.5249,
        -19.778089795238095
    ],
    [
        38.5352,
        -17.585309076190477
    ],
    [
        38.5456,
        -19.112214595238097
    ],
    [
        38.5559,
        -17.138441052380955
    ],
    [
        38.5663,
        -16.856550804761905
    ],
    [
        38.5767,
        -16.035273909523813
    ],
    [
        38.587,
        -15.33016128571429
    ],
    [
        38.5974,
        -21.088581042857147
    ],
    [
        38.6078,
        -20.677152471428577
    ],
    [
        38.6181,
        -19.032115433333338
    ],
    [
        38.6285,
        -20.185496319047623
    ],
    [
        38.6388,
        -18.21199367619048
    ],
    [
        38.6492,
        -18.459053995238094
    ],
    [
        38.6596,
        -19.446211666666663
    ],
    [
        38.6699,
        -16.979671980952382
    ],
    [
        38.6803,
        -12.660518538095237
    ],
    [
        38.6906,
        -15.127058223809524
    ],
    [
        38.701,
        -11.014804252380953
    ],
    [
        38.7114,
        -11.836984142857142
    ],
    [
        38.7217,
        -11.768281028571428
    ],
    [
        38.7321,
        -8.684937114285715
    ],
    [
        38.7425,
        -8.273395666666667
    ],
    [
        38.7528,
        -7.834929823809524
    ],
    [
        38.7632,
        -8.363474042857144
    ],
    [
        38.7735,
        -7.704827128571429
    ],
    [
        38.7839,
        -7.986717376190478
    ],
    [
        38.7943,
        -7.328612261904763
    ],
    [
        38.8046,
        -12.26169162857143
    ],
    [
        38.815,
        -8.313331838095237
    ],
    [
        38.8254,
        -5.846792152380953
    ],
    [
        38.8357,
        -8.725099033333334
    ],
    [
        38.8461,
        -9.921448557142858
    ],
    [
        38.8564,
        -10.908064428571429
    ],
    [
        38.8668,
        -12.141334271428569
    ],
    [
        38.8772,
        -12.387446442857144
    ],
    [
        38.8875,
        -18.553795652380952
    ],
    [
        38.8979,
        -18.642273357142855
    ],
    [
        38.9082,
        -8.776114623809525
    ],
    [
        38.9186,
        -13.71190298571429
    ],
    [
        38.929,
        -15.356262776190476
    ],
    [
        38.9393,
        -16.041939438095238
    ],
    [
        38.9497,
        -17.892013514285715
    ],
    [
        38.9601,
        -18.467539438095237
    ],
    [
        38.9704,
        -18.467268538095237
    ],
    [
        38.9808,
        -22.167078061904764
    ],
    [
        38.9911,
        -28.745420214285712
    ],
    [
        39.0015,
        -28.99207418571429
    ],
    [
        39.0119,
        -28.827547909523815
    ],
    [
        39.0222,
        -22.90785267142857
    ],
    [
        39.0326,
        -22.7430554952381
    ],
    [
        39.043,
        -24.387415285714287
    ],
    [
        39.0533,
        -17.807267133333337
    ],
    [
        39.0637,
        -16.985087242857144
    ],
    [
        39.074,
        -18.629447033333335
    ],
    [
        39.0844,
        -21.098018461904765
    ],
    [
        39.0948,
        -18.22038882857143
    ],
    [
        39.1051,
        -13.780617400000002
    ],
    [
        39.1155,
        -12.25332488095238
    ],
    [
        39.1258,
        -17.89151687142857
    ],
    [
        39.1362,
        -10.490543323809526
    ],
    [
        39.1466,
        -12.134903114285716
    ],
    [
        39.1569,
        -12.545315814285717
    ],
    [
        39.1673,
        -12.545993061904761
    ],
    [
        39.1777,
        -13.203736980952382
    ],
    [
        39.188,
        -12.95694756190476
    ],
    [
        39.1984,
        -8.846048090476192
    ],
    [
        39.2087,
        -8.848757085714286
    ],
    [
        39.2191,
        -10.082026923809524
    ],
    [
        39.2295,
        -10.2460114
    ],
    [
        39.2398,
        -12.466574361904762
    ],
    [
        39.2502,
        -6.7108636
    ],
    [
        39.2605,
        -11.646651961904762
    ],
    [
        39.2709,
        -18.226800114285712
    ],
    [
        39.2813,
        -22.33995708095238
    ],
    [
        39.2916,
        -19.051237499999996
    ],
    [
        39.302,
        -14.526539085714287
    ],
    [
        39.3124,
        -18.63811580952381
    ],
    [
        39.3227,
        -15.67759093809524
    ],
    [
        39.3331,
        -17.32240222857143
    ],
    [
        39.3434,
        -15.630673733333335
    ],
    [
        39.3538,
        -21.268478723809523
    ],
    [
        39.3642,
        -19.075698004761904
    ],
    [
        39.3745,
        -17.87979997619048
    ],
    [
        39.3849,
        -21.580286747619045
    ],
    [
        39.3952,
        -21.58028674761905
    ],
    [
        39.4056,
        -24.459045123809524
    ],
    [
        39.416,
        -22.40325676190476
    ],
    [
        39.4263,
        -18.015588328571432
    ],
    [
        39.4367,
        -13.90468885714286
    ],
    [
        39.4471,
        -15.38596716666667
    ],
    [
        39.4574,
        -9.218940709523812
    ],
    [
        39.4678,
        -16.61991425714286
    ],
    [
        39.4781,
        -9.216908961904764
    ],
    [
        39.4885,
        -8.559706842857143
    ],
    [
        39.4989,
        -4.6105343523809506
    ],
    [
        39.5092,
        -1.3204602809523789
    ],
    [
        39.5196,
        -3.376587266666665
    ],
    [
        39.5299,
        -1.526174566666667
    ],
    [
        39.5403,
        -3.4452715714285698
    ],
    [
        39.5507,
        -3.002212009523807
    ],
    [
        39.561,
        -4.97571465238095
    ],
    [
        39.5714,
        -4.270989028571426
    ],
    [
        39.5818,
        -9.752489323809522
    ],
    [
        39.5921,
        -10.126658957142855
    ],
    [
        39.6025,
        -4.3695937
    ],
    [
        39.6128,
        -3.547413804761905
    ],
    [
        39.6232,
        -1.9026025190476197
    ],
    [
        39.6336,
        -1.4909482
    ],
    [
        39.6439,
        -1.3912900333333338
    ],
    [
        39.6543,
        -1.8023799809523808
    ],
    [
        39.6647,
        5.598593566666667
    ],
    [
        39.675,
        -4.270274161904764
    ],
    [
        39.6854,
        -2.507105604761905
    ],
    [
        39.6957,
        -2.5077828571428586
    ],
    [
        39.7061,
        -3.1658879714285724
    ],
    [
        39.7165,
        -3.824534885714286
    ],
    [
        39.7268,
        -9.252276685714284
    ],
    [
        39.7372,
        -4.317165571428572
    ],
    [
        39.7475,
        -8.633440704761906
    ],
    [
        39.7579,
        -7.947613542857143
    ],
    [
        39.7683,
        -8.390221604761905
    ],
    [
        39.7786,
        -7.403334833333332
    ],
    [
        39.789,
        -9.048597619047618
    ],
    [
        39.7994,
        -6.582057933333333
    ],
    [
        39.8097,
        -7.403786328571426
    ],
    [
        39.8201,
        -9.459687566666666
    ],
    [
        39.8304,
        -11.10540185238095
    ],
    [
        39.8408,
        -8.638862171428569
    ],
    [
        39.8512,
        -10.98904408095238
    ],
    [
        39.8615,
        -9.718176676190476
    ],
    [
        39.8719,
        -15.88723488095238
    ],
    [
        39.8822,
        -20.82031424761905
    ],
    [
        39.8926,
        -16.501160804761906
    ],
    [
        39.903,
        -17.441246466666666
    ],
    [
        39.9133,
        -21.552597433333332
    ],
    [
        39.9237,
        -22.37522882857143
    ],
    [
        39.9341,
        -15.796886676190477
    ],
    [
        39.9444,
        -15.851849166666668
    ],
    [
        39.9548,
        -20.786960280952385
    ],
    [
        39.9651,
        -17.49824070476191
    ],
    [
        39.9755,
        -23.666621657142862
    ],
    [
        39.9859,
        -17.910007900000004
    ],
    [
        39.9962,
        -18.896894671428576
    ],
    [
        40.0066,
        -18.89644317142858
    ],
    [
        40.017,
        -15.196295023809526
    ],
    [
        40.0273,
        -14.256660857142858
    ],
    [
        40.0377,
        -8.088957152380951
    ],
    [
        40.048,
        -5.621062971428568
    ],
    [
        40.0584,
        -9.73196244285714
    ],
    [
        40.0688,
        -9.02704331904762
    ],
    [
        40.0791,
        -11.083396052380952
    ],
    [
        40.0895,
        -1.2145283238095208
    ],
    [
        40.0998,
        -2.036708219047616
    ],
    [
        40.1102,
        -3.88796748095238
    ],
    [
        40.1206,
        -3.887515985714285
    ],
    [
        40.1309,
        -7.178493061904763
    ],
    [
        40.1413,
        -5.9438687238095245
    ],
    [
        40.1517,
        -15.81409094761905
    ],
    [
        40.162,
        -12.798603585714286
    ],
    [
        40.1724,
        -11.564656495238095
    ],
    [
        40.1827,
        -9.303661785714288
    ],
    [
        40.1931,
        -4.917648852380951
    ],
    [
        40.2035,
        -7.385543033333336
    ],
    [
        40.2138,
        -8.865195942857145
    ],
    [
        40.2242,
        -8.207000528571431
    ],
    [
        40.2345,
        -14.376397357142858
    ],
    [
        40.2449,
        -8.73859236666667
    ],
    [
        40.2553,
        -14.378138852380955
    ],
    [
        40.2656,
        -12.733779061904764
    ],
    [
        40.276,
        -12.73468205714286
    ],
    [
        40.2864,
        -12.73468205714286
    ],
    [
        40.2967,
        -12.48735083809524
    ],
    [
        40.3071,
        -19.88832438571429
    ],
    [
        40.3174,
        -21.532684171428578
    ],
    [
        40.3278,
        -20.709149780952384
    ],
    [
        40.3382,
        -18.07817411904762
    ],
    [
        40.3485,
        -13.759359300000002
    ],
    [
        40.3589,
        -12.526089457142856
    ],
    [
        40.3692,
        -2.653158238095238
    ],
    [
        40.3796,
        -6.7656379571428555
    ],
    [
        40.39,
        -7.176727904761903
    ],
    [
        40.4003,
        -9.027309914285713
    ],
    [
        40.4107,
        -8.478888985714285
    ],
    [
        40.4211,
        -8.477534485714283
    ],
    [
        40.4314,
        -6.833174699999999
    ],
    [
        40.4418,
        -0.9134794619047609
    ],
    [
        40.4521,
        2.377949109523811
    ],
    [
        40.4625,
        -3.789077347619046
    ],
    [
        40.4729,
        -4.728324514285713
    ],
    [
        40.4832,
        -8.017044095238093
    ],
    [
        40.4936,
        -3.9038871285714265
    ],
    [
        40.504,
        -5.5482469190476165
    ],
    [
        40.5143,
        -6.20644233333333
    ],
    [
        40.5247,
        1.1945312142857163
    ],
    [
        40.535,
        2.0158081095238125
    ],
    [
        40.5454,
        2.0153566142857167
    ],
    [
        40.5558,
        0.042124871428575496
    ],
    [
        40.5661,
        5.593532276190478
    ],
    [
        40.5765,
        3.1263153428571453
    ],
    [
        40.5868,
        -2.7963597904761897
    ],
    [
        40.5972,
        -2.795908290476189
    ],
    [
        40.6076,
        -0.6031275714285697
    ],
    [
        40.6179,
        -0.6024503238095217
    ],
    [
        40.6283,
        -3.0689900047619028
    ],
    [
        40.6387,
        -8.004101119047617
    ],
    [
        40.649,
        -7.730191652380951
    ],
    [
        40.6594,
        -15.13048795238095
    ],
    [
        40.6697,
        -18.420562023809524
    ],
    [
        40.6801,
        -18.4202234
    ],
    [
        40.6905,
        -11.841429747619046
    ],
    [
        40.7008,
        -11.841429747619046
    ],
    [
        40.7112,
        -15.954135214285717
    ],
    [
        40.7216,
        -15.296391295238095
    ],
    [
        40.7319,
        -12.41763291904762
    ],
    [
        40.7423,
        -14.884172604761911
    ],
    [
        40.7526,
        -14.225525690476193
    ],
    [
        40.763,
        -13.197349323809528
    ],
    [
        40.7734,
        -17.146521814285716
    ],
    [
        40.7837,
        -20.847685833333337
    ],
    [
        40.7941,
        -19.085194523809527
    ],
    [
        40.8044,
        -16.864360661904765
    ],
    [
        40.8148,
        -20.156692233333338
    ],
    [
        40.8252,
        -21.691999933333335
    ],
    [
        40.8355,
        -20.458730090476198
    ],
    [
        40.8459,
        -19.225798876190478
    ],
    [
        40.8562,
        -11.824148080952384
    ],
    [
        40.8666,
        -12.098057547619048
    ],
    [
        40.877,
        -7.164300933333336
    ],
    [
        40.8873,
        -2.9349796952380967
    ],
    [
        40.8977,
        -1.7013712285714295
    ],
    [
        40.9081,
        -7.869074933333335
    ],
    [
        40.9184,
        -4.991445300000001
    ],
    [
        40.9288,
        -4.333791685714287
    ],
    [
        40.9391,
        -4.16980720952381
    ],
    [
        40.9495,
        -3.75939450952381
    ],
    [
        40.9599,
        -7.048565585714287
    ],
    [
        40.9702,
        -10.995029080952385
    ],
    [
        40.9806,
        -15.311473528571431
    ],
    [
        40.991,
        -11.36230103809524
    ],
    [
        41.0013,
        -10.950308095238096
    ],
    [
        41.0117,
        -15.178661838095241
    ],
    [
        41.022,
        -17.3994957
    ],
    [
        41.0324,
        -7.527918980952383
    ],
    [
        41.0428,
        -1.605243847619047
    ],
    [
        41.0531,
        -4.071783528571428
    ],
    [
        41.0635,
        -3.5429006857142875
    ],
    [
        41.0738,
        -3.541546185714288
    ],
    [
        41.0842,
        -7.655154652380953
    ],
    [
        41.0946,
        -11.108310204761905
    ],
    [
        41.1049,
        -5.470118214285715
    ],
    [
        41.1153,
        -3.002224033333334
    ],
    [
        41.1257,
        -4.235493871428572
    ],
    [
        41.136,
        -6.455379585714287
    ],
    [
        41.1464,
        -6.455108685714288
    ],
    [
        41.1567,
        -3.1652603571428592
    ],
    [
        41.1671,
        -3.164808861904764
    ],
    [
        41.1775,
        -3.328793338095239
    ],
    [
        41.1878,
        0.3710161857142851
    ],
    [
        41.1982,
        5.304095552380951
    ],
    [
        41.2086,
        5.46830578095238
    ],
    [
        41.2189,
        -1.1113908666666674
    ],
    [
        41.2293,
        3.116962876190478
    ],
    [
        41.2396,
        5.749112433333336
    ],
    [
        41.25,
        -1.6518611095238085
    ],
    [
        41.2604,
        -6.587649471428571
    ],
    [
        41.2707,
        -6.589003971428571
    ],
    [
        41.2811,
        -6.432398276190476
    ],
    [
        41.2914,
        -10.663267514285716
    ],
    [
        41.3018,
        -6.27559908095238
    ],
    [
        41.3122,
        -6.934245995238096
    ],
    [
        41.3225,
        -11.867325361904761
    ],
    [
        41.3329,
        -9.402140176190477
    ],
    [
        41.3433,
        -7.484021423809522
    ],
    [
        41.3536,
        -4.8525942666666655
    ],
    [
        41.364,
        -5.09931596190476
    ],
    [
        41.3743,
        -5.099993214285715
    ],
    [
        41.3847,
        -7.731420371428573
    ],
    [
        41.3951,
        -5.099541714285714
    ],
    [
        41.4054,
        -5.0995417142857145
    ],
    [
        41.4158,
        -5.510631661904762
    ],
    [
        41.4261,
        -2.2216863380952394
    ],
    [
        41.4365,
        1.7255898523809512
    ],
    [
        41.4469,
        4.8968551571428565
    ],
    [
        41.4572,
        3.7000541333333326
    ],
    [
        41.4676,
        5.858107042857142
    ],
    [
        41.478,
        7.092054133333333
    ],
    [
        41.4883,
        7.092054133333333
    ],
    [
        41.4987,
        7.146866123809524
    ],
    [
        41.509,
        6.990367928571428
    ],
    [
        41.5194,
        12.471567223809524
    ],
    [
        41.5298,
        13.412104385714283
    ],
    [
        41.5401,
        12.178834542857139
    ],
    [
        41.5505,
        12.181543538095237
    ],
    [
        41.5608,
        11.743348595238094
    ],
    [
        41.5712,
        10.09853730952381
    ],
    [
        41.5816,
        13.798685457142856
    ],
    [
        41.5919,
        6.396357414285714
    ],
    [
        41.6023,
        6.678247661904762
    ],
    [
        41.6127,
        5.032984876190476
    ],
    [
        41.623,
        4.621894928571428
    ],
    [
        41.6334,
        7.500879057142857
    ],
    [
        41.6437,
        4.212159480952381
    ],
    [
        41.6541,
        3.966047309523808
    ],
    [
        41.6645,
        -0.1453036571428573
    ],
    [
        41.6748,
        -2.2385767047619036
    ],
    [
        41.6852,
        -1.9300899333333335
    ],
    [
        41.6956,
        -2.4593113999999994
    ],
    [
        41.7059,
        -0.6082214523809518
    ],
    [
        41.7163,
        -0.11491351428571447
    ],
    [
        41.7266,
        -5.59641380952381
    ],
    [
        41.737,
        -5.593704814285713
    ],
    [
        41.7474,
        -6.122249033333333
    ],
    [
        41.7577,
        -5.382558028571428
    ],
    [
        41.7681,
        -9.331730519047621
    ],
    [
        41.7784,
        -9.167294542857144
    ],
    [
        41.7888,
        -10.812105828571429
    ],
    [
        41.7992,
        -10.811428576190476
    ],
    [
        41.8095,
        -6.423910642857142
    ],
    [
        41.8199,
        -4.897005123809523
    ],
    [
        41.8303,
        -5.884162795238093
    ],
    [
        41.8406,
        -5.884162795238093
    ],
    [
        41.851,
        -5.885517295238094
    ],
    [
        41.8613,
        -5.679972319047618
    ],
    [
        41.8717,
        -9.38249083809524
    ],
    [
        41.8821,
        -10.205573733333333
    ],
    [
        41.8924,
        -9.382942342857142
    ],
    [
        41.9028,
        -9.382942342857142
    ],
    [
        41.9131,
        -9.539247038095239
    ],
    [
        41.9235,
        -10.56725409047619
    ],
    [
        41.9339,
        -13.034471023809523
    ],
    [
        41.9442,
        -6.868121814285713
    ],
    [
        41.9546,
        -12.420883719047616
    ],
    [
        41.965,
        -12.831973666666666
    ],
    [
        41.9753,
        -13.57166467142857
    ],
    [
        41.9857,
        -19.494068904761907
    ],
    [
        41.996,
        -19.494068904761907
    ],
    [
        42.0064,
        -20.317151800000005
    ],
    [
        42.0168,
        -21.140008942857143
    ],
    [
        42.0271,
        -23.059632695238097
    ],
    [
        42.0375,
        -25.526172380952385
    ],
    [
        42.0478,
        -19.605935342857148
    ],
    [
        42.0582,
        -19.194845395238094
    ],
    [
        42.0686,
        -21.661385076190477
    ],
    [
        42.0789,
        -21.044750157142857
    ],
    [
        42.0893,
        -16.725596714285718
    ],
    [
        42.0997,
        -19.192136395238094
    ],
    [
        42.11,
        -19.19236214285714
    ],
    [
        42.1204,
        -17.9590923
    ],
    [
        42.1307,
        -13.573079366666661
    ],
    [
        42.1411,
        -12.545072314285717
    ],
    [
        42.1515,
        -8.431689595238097
    ],
    [
        42.1618,
        -10.651575309523814
    ],
    [
        42.1722,
        -11.021217638095242
    ],
    [
        42.1826,
        -6.910318166666669
    ],
    [
        42.1929,
        -10.612836685714285
    ],
    [
        42.2033,
        -4.443778480952383
    ],
    [
        42.2136,
        -6.088138266666667
    ],
    [
        42.224,
        -4.607130857142857
    ],
    [
        42.2344,
        -3.784273714285715
    ],
    [
        42.2447,
        -2.021298657142857
    ],
    [
        42.2551,
        1.2674209238095229
    ],
    [
        42.2654,
        -8.600092304761906
    ],
    [
        42.2758,
        -8.189453857142858
    ],
    [
        42.2862,
        -9.011633752380954
    ],
    [
        42.2965,
        -11.478850685714287
    ],
    [
        42.3069,
        -12.095824233333333
    ],
    [
        42.3173,
        -5.927781904761907
    ],
    [
        42.3276,
        -5.810101885714287
    ],
    [
        42.338,
        -5.3990119380952395
    ],
    [
        42.3483,
        -11.56739289047619
    ],
    [
        42.3587,
        -13.417128338095237
    ],
    [
        42.3691,
        -19.999082485714286
    ],
    [
        42.3794,
        -23.94554598095238
    ],
    [
        42.3898,
        -23.94608778095238
    ],
    [
        42.4001,
        -31.345706828571423
    ],
    [
        42.4105,
        -31.344352328571425
    ],
    [
        42.4209,
        -35.044839104761905
    ],
    [
        42.4312,
        -31.591412652380953
    ],
    [
        42.4416,
        -31.838134347619054
    ],
    [
        42.452,
        -35.949711071428574
    ],
    [
        42.4623,
        -37.711331628571436
    ],
    [
        42.4727,
        -36.887797238095246
    ],
    [
        42.483,
        -33.597723166666675
    ],
    [
        42.4934,
        -32.77509177142858
    ],
    [
        42.5038,
        -33.59727166666668
    ],
    [
        42.5141,
        -31.952686128571436
    ],
    [
        42.5245,
        -35.65215702857144
    ],
    [
        42.5349,
        -41.82155385714287
    ],
    [
        42.5452,
        -41.116828233333344
    ],
    [
        42.5556,
        -43.11355083333335
    ],
    [
        42.5659,
        -40.64633390000001
    ],
    [
        42.5763,
        -39.824154009523824
    ],
    [
        42.5867,
        -34.18325302380953
    ],
    [
        42.597,
        -29.25017365714286
    ],
    [
        42.6074,
        -28.967741609523813
    ],
    [
        42.6177,
        -25.02127811428571
    ],
    [
        42.6281,
        -22.554738433333334
    ],
    [
        42.6385,
        -25.020600866666665
    ],
    [
        42.6488,
        -28.474027319047618
    ],
    [
        42.6592,
        -24.773879171428568
    ],
    [
        42.6696,
        -24.363466471428573
    ],
    [
        42.6799,
        -16.96113842857143
    ],
    [
        42.6903,
        -19.977152538095236
    ],
    [
        42.7006,
        -18.33279274761905
    ],
    [
        42.711,
        -16.68752996190476
    ],
    [
        42.7214,
        -15.865350066666668
    ],
    [
        42.7317,
        -10.109639304761904
    ],
    [
        42.7421,
        -6.1637176095238075
    ],
    [
        42.7524,
        -2.4611990904761902
    ],
    [
        42.7628,
        -4.928416023809524
    ],
    [
        42.7732,
        0.3570261523809528
    ],
    [
        42.7835,
        -1.5811628142857141
    ],
    [
        42.7939,
        0.06319697619047576
    ],
    [
        42.8043,
        1.8253012857142852
    ],
    [
        42.8146,
        4.293195466666666
    ],
    [
        42.825,
        3.352658304761904
    ],
    [
        42.8353,
        4.339274176190476
    ],
    [
        42.8457,
        6.190533438095238
    ],
    [
        42.8561,
        10.506977885714285
    ],
    [
        42.8664,
        13.5219384952381
    ],
    [
        42.8768,
        10.507278885714285
    ],
    [
        42.8871,
        6.806792114285715
    ],
    [
        42.8975,
        -3.062075614285714
    ],
    [
        42.9079,
        -2.513955685714286
    ],
    [
        42.9182,
        -7.4470350523809525
    ],
    [
        42.9286,
        -9.092297838095238
    ],
    [
        42.939,
        -8.818538871428572
    ],
    [
        42.9493,
        -15.396429523809523
    ],
    [
        42.9597,
        -11.942054919047619
    ],
    [
        42.97,
        -15.641864442857143
    ],
    [
        42.9804,
        -14.40791735238095
    ],
    [
        42.9908,
        -16.873102538095235
    ],
    [
        43.0011,
        -17.8125432047619
    ],
    [
        43.0115,
        -16.57927336190476
    ],
    [
        43.0218,
        -19.75160291904762
    ],
    [
        43.0322,
        -23.453444190476187
    ],
    [
        43.0426,
        -21.808181404761903
    ],
    [
        43.0529,
        -21.808181404761903
    ],
    [
        43.0633,
        -18.723652304761902
    ],
    [
        43.0737,
        -18.811936509523807
    ],
    [
        43.084,
        -14.425923576190474
    ],
    [
        43.0944,
        -15.522163438095236
    ],
    [
        43.1047,
        -15.523517938095237
    ],
    [
        43.1151,
        -13.056978252380954
    ],
    [
        43.1255,
        -10.590438571428573
    ],
    [
        43.1358,
        -10.590438571428573
    ],
    [
        43.1462,
        -11.577596242857144
    ],
    [
        43.1566,
        -15.962254680952382
    ],
    [
        43.1669,
        -19.25052276190476
    ],
    [
        43.1773,
        -24.184956628571427
    ],
    [
        43.1876,
        -20.07428290476191
    ],
    [
        43.198,
        -18.018833166666667
    ],
    [
        43.2084,
        -21.19183997142857
    ],
    [
        43.2187,
        -24.48055954761905
    ],
    [
        43.2291,
        -30.649617752380962
    ],
    [
        43.2394,
        -25.011425761904764
    ],
    [
        43.2498,
        -28.713267033333334
    ],
    [
        43.2602,
        -29.947214123809523
    ],
    [
        43.2705,
        -30.49533405238095
    ],
    [
        43.2809,
        -32.96322823333334
    ],
    [
        43.2913,
        -33.902668900000016
    ],
    [
        43.3016,
        -38.22012921904763
    ],
    [
        43.312,
        -37.280495052380964
    ],
    [
        43.3223,
        -32.89297711904762
    ],
    [
        43.3327,
        -29.192828971428575
    ],
    [
        43.3431,
        -29.191474471428574
    ],
    [
        43.3534,
        -24.963120728571432
    ],
    [
        43.3638,
        -26.442502738095246
    ],
    [
        43.3742,
        -26.44385723333333
    ],
    [
        43.3845,
        -23.9779948
    ],
    [
        43.3949,
        -20.277169400000005
    ],
    [
        43.4052,
        -19.948071695238095
    ],
    [
        43.4156,
        -19.53698174761905
    ],
    [
        43.426,
        -21.29976330476191
    ],
    [
        43.4363,
        -21.299763304761907
    ],
    [
        43.4467,
        -17.597244785714285
    ],
    [
        43.457,
        -24.996863833333332
    ],
    [
        43.4674,
        -21.295022561904755
    ],
    [
        43.4778,
        -18.415361185714282
    ],
    [
        43.4881,
        -20.333780938095234
    ],
    [
        43.4985,
        -21.320667709523804
    ],
    [
        43.5088,
        -14.741874057142851
    ],
    [
        43.5192,
        -15.907118028571425
    ],
    [
        43.5296,
        -13.969122561904758
    ],
    [
        43.5399,
        -15.065211923809525
    ],
    [
        43.5503,
        -17.12145178571429
    ],
    [
        43.5607,
        -19.589345966666666
    ],
    [
        43.571,
        -17.240260552380953
    ],
    [
        43.5814,
        -16.006990714285713
    ],
    [
        43.5917,
        -11.072556852380952
    ],
    [
        43.6021,
        -10.250602704761905
    ],
    [
        43.6125,
        -12.306391066666668
    ],
    [
        43.6228,
        -10.168723338095237
    ],
    [
        43.6332,
        -13.047255966666667
    ],
    [
        43.6436,
        -8.935001995238094
    ],
    [
        43.6539,
        -4.9885385
    ],
    [
        43.6643,
        1.180519704761906
    ],
    [
        43.6746,
        2.942333761904762
    ],
    [
        43.685,
        2.5319210619047623
    ],
    [
        43.6954,
        2.120153866666667
    ],
    [
        43.7057,
        3.5995358761904765
    ],
    [
        43.7161,
        -0.3466567190476176
    ],
    [
        43.7264,
        -5.281090580952379
    ],
    [
        43.7368,
        -6.378082938095236
    ],
    [
        43.7472,
        -8.84529987142857
    ],
    [
        43.7575,
        -9.667479761904762
    ],
    [
        43.7679,
        -10.489208157142855
    ],
    [
        43.7783,
        -5.556128790476188
    ],
    [
        43.7886,
        -4.732594399999998
    ],
    [
        43.799,
        -5.143684347619045
    ],
    [
        43.8093,
        -6.376954190476188
    ],
    [
        43.8197,
        -6.376502690476188
    ],
    [
        43.8301,
        -4.567436023809522
    ],
    [
        43.8404,
        -7.445404280952379
    ],
    [
        43.8508,
        -3.3336018142857116
    ],
    [
        43.8612,
        -6.622772890476188
    ],
    [
        43.8715,
        -8.104051199999997
    ],
    [
        43.8819,
        -10.573299880952382
    ],
    [
        43.8922,
        -9.86857425714286
    ],
    [
        43.9026,
        -8.772334395238094
    ],
    [
        43.913,
        -11.240228576190479
    ],
    [
        43.9233,
        -15.187504766666667
    ],
    [
        43.9337,
        -7.787885719047618
    ],
    [
        43.944,
        -8.404689952380952
    ],
    [
        43.9544,
        -5.937021523809524
    ],
    [
        43.9648,
        -5.936682899999999
    ],
    [
        43.9751,
        -3.963451157142856
    ],
    [
        43.9855,
        -1.4964599714285693
    ],
    [
        43.9958,
        -7.416155209523809
    ],
    [
        44.0062,
        -8.650440923809523
    ],
    [
        44.0166,
        -7.992967904761905
    ],
    [
        44.0269,
        -5.114886776190477
    ],
    [
        44.0373,
        -4.1749946142857155
    ],
    [
        44.0477,
        -6.14849725714286
    ],
    [
        44.058,
        -3.681618947619048
    ],
    [
        44.0684,
        -7.1353162999999995
    ],
    [
        44.0787,
        -3.0239653333333334
    ],
    [
        44.0891,
        4.377008214285715
    ],
    [
        44.0995,
        1.0882886380952386
    ],
    [
        44.1098,
        2.07544630952381
    ],
    [
        44.1202,
        0.9792064476190473
    ],
    [
        44.1306,
        1.66473260952381
    ],
    [
        44.1409,
        7.586324142857142
    ],
    [
        44.1513,
        3.4749731761904767
    ],
    [
        44.1616,
        3.104890638095239
    ],
    [
        44.172,
        0.6381252047619054
    ],
    [
        44.1824,
        3.1050035142857153
    ],
    [
        44.1927,
        3.0498905238095255
    ],
    [
        44.2031,
        2.931984752380953
    ],
    [
        44.2134,
        3.3019657047619053
    ],
    [
        44.2238,
        1.4517223190476194
    ],
    [
        44.2342,
        3.425766761904762
    ],
    [
        44.2445,
        2.767571347619049
    ],
    [
        44.2549,
        2.9242200428571437
    ],
    [
        44.2653,
        3.2940316809523815
    ],
    [
        44.2756,
        4.52865601904762
    ],
    [
        44.286,
        4.282543847619047
    ],
    [
        44.2963,
        2.520729790476191
    ],
    [
        44.3067,
        -3.399507247619047
    ],
    [
        44.3171,
        -5.043867033333333
    ],
    [
        44.3274,
        -10.965458566666666
    ],
    [
        44.3378,
        -10.965458566666666
    ],
    [
        44.3481,
        -15.351471499999997
    ],
    [
        44.3585,
        -15.104682080952381
    ],
    [
        44.3689,
        -14.076505714285712
    ],
    [
        44.3792,
        -12.541348514285712
    ],
    [
        44.3896,
        -11.719394366666664
    ],
    [
        44.4,
        -13.93982188095238
    ],
    [
        44.4103,
        -8.458321585714284
    ],
    [
        44.4207,
        -14.09612657619047
    ],
    [
        44.431,
        -15.94738583809524
    ],
    [
        44.4414,
        -16.035476542857143
    ],
    [
        44.4518,
        -15.377822928571428
    ],
    [
        44.4621,
        -18.83165573333333
    ],
    [
        44.4725,
        -18.2833853047619
    ],
    [
        44.4829,
        -18.653196942857146
    ],
    [
        44.4932,
        -26.05417049047619
    ],
    [
        44.5036,
        -19.885112285714282
    ],
    [
        44.5139,
        -15.65675854285714
    ],
    [
        44.5243,
        -16.31396066190476
    ],
    [
        44.5347,
        -12.86026330952381
    ],
    [
        44.545,
        -8.542802990476188
    ],
    [
        44.5554,
        -7.88505907142857
    ],
    [
        44.5657,
        -7.883704576190476
    ],
    [
        44.5761,
        -4.1818633047619045
    ],
    [
        44.5865,
        -8.499662247619048
    ],
    [
        44.5968,
        -9.752851799999998
    ],
    [
        44.6072,
        -6.463906476190475
    ],
    [
        44.6176,
        -5.476748804761904
    ],
    [
        44.6279,
        -10.858289933333335
    ],
    [
        44.6383,
        -5.103030671428572
    ],
    [
        44.6486,
        -7.568215857142856
    ],
    [
        44.659,
        -8.508753019047617
    ],
    [
        44.6694,
        -15.089352666666665
    ],
    [
        44.6797,
        -15.088675414285712
    ],
    [
        44.6901,
        -16.075291285714282
    ],
    [
        44.7005,
        -13.443412628571428
    ],
    [
        44.7108,
        -9.126968180952383
    ],
    [
        44.7212,
        -11.596216861904761
    ],
    [
        44.7315,
        -16.52929622857143
    ],
    [
        44.7419,
        -16.530199223809525
    ],
    [
        44.7523,
        -16.695176999999997
    ],
    [
        44.7626,
        -17.064819328571428
    ],
    [
        44.773,
        -16.783316080952382
    ],
    [
        44.7833,
        -16.783316080952382
    ],
    [
        44.7937,
        -20.07474465238095
    ],
    [
        44.8041,
        -16.786476576190477
    ],
    [
        44.8144,
        -21.017345814285715
    ],
    [
        44.8248,
        -24.470501366666667
    ],
    [
        44.8351,
        -22.826141576190476
    ],
    [
        44.8455,
        -22.925799742857144
    ],
    [
        44.8559,
        -21.280085457142853
    ],
    [
        44.8662,
        -17.99136588095238
    ],
    [
        44.8766,
        -16.794564857142856
    ],
    [
        44.887,
        -11.0365966
    ],
    [
        44.8973,
        -10.215319704761905
    ],
    [
        44.9077,
        -11.695243514285716
    ],
    [
        44.9181,
        -14.985317585714286
    ],
    [
        44.9284,
        -16.013493952380955
    ],
    [
        44.9388,
        -18.480033633333335
    ],
    [
        44.9491,
        -16.835673842857144
    ],
    [
        44.9595,
        -15.683718995238095
    ],
    [
        44.9699,
        -16.505898890476193
    ],
    [
        44.9802,
        -14.902478785714287
    ],
    [
        44.9906,
        -12.963757695238096
    ],
    [
        45.0009,
        -8.735403952380953
    ],
    [
        45.0113,
        -5.446684376190476
    ],
    [
        45.0217,
        -5.034804304761904
    ],
    [
        45.032,
        -0.5923238809523802
    ],
    [
        45.0424,
        5.328725852380953
    ],
    [
        45.0527,
        4.917974528571429
    ],
    [
        45.0631,
        4.2324859904761905
    ],
    [
        45.0735,
        -0.7026251238095246
    ],
    [
        45.0838,
        0.5306447190476192
    ],
    [
        45.0942,
        1.684219104761905
    ],
    [
        45.1046,
        3.3285788904761904
    ],
    [
        45.1149,
        4.525256780952381
    ],
    [
        45.1253,
        9.459013395238095
    ],
    [
        45.1356,
        10.398905557142857
    ],
    [
        45.146,
        12.865896738095238
    ],
    [
        45.1564,
        11.879280866666667
    ],
    [
        45.1667,
        10.234921076190476
    ],
    [
        45.1771,
        15.66130838095238
    ],
    [
        45.1874,
        15.113188452380953
    ],
    [
        45.1978,
        16.141647004761907
    ],
    [
        45.2082,
        13.262775752380955
    ],
    [
        45.2185,
        12.734231533333334
    ],
    [
        45.2289,
        8.505877790476191
    ],
    [
        45.2393,
        4.807084138095236
    ],
    [
        45.2496,
        4.312963504761904
    ],
    [
        45.26,
        1.8457465761904759
    ],
    [
        45.2703,
        -0.37447776190476306
    ],
    [
        45.2807,
        -0.3741391380952386
    ],
    [
        45.2911,
        2.0930777952380946
    ],
    [
        45.3014,
        2.5041677428571427
    ],
    [
        45.3118,
        1.7990551190476178
    ],
    [
        45.3222,
        -2.3118443523809535
    ],
    [
        45.3325,
        -3.508522242857144
    ],
    [
        45.3429,
        -6.524160104761906
    ],
    [
        45.3532,
        -4.832625109523811
    ],
    [
        45.3636,
        -10.587884371428572
    ],
    [
        45.374,
        -10.588426171428571
    ],
    [
        45.3843,
        -10.588426171428571
    ],
    [
        45.3947,
        -13.054965857142857
    ],
    [
        45.405,
        -17.441279790476194
    ],
    [
        45.4154,
        -20.73135386190476
    ],
    [
        45.4258,
        -20.73045086666667
    ],
    [
        45.4361,
        -17.02996409047619
    ],
    [
        45.4465,
        -18.43960883809524
    ],
    [
        45.4569,
        -18.440963333333332
    ],
    [
        45.4672,
        -14.984963333333331
    ],
    [
        45.4776,
        -17.452180266666666
    ],
    [
        45.4879,
        -18.933458576190475
    ],
    [
        45.4983,
        -18.933458576190475
    ],
    [
        45.5087,
        -23.86653794285714
    ],
    [
        45.519,
        -22.87992207142857
    ],
    [
        45.5294,
        -23.86707974285714
    ],
    [
        45.5397,
        -24.689259633333336
    ],
    [
        45.5501,
        -23.748915971428566
    ],
    [
        45.5605,
        -18.267415676190474
    ],
    [
        45.5708,
        -20.487640014285713
    ],
    [
        45.5812,
        -14.732155004761905
    ],
    [
        45.5916,
        -15.389357123809523
    ],
    [
        45.6019,
        -16.623304214285714
    ],
    [
        45.6123,
        -19.08984389523809
    ],
    [
        45.6226,
        -15.142296804761905
    ],
    [
        45.633,
        -12.675305623809523
    ],
    [
        45.6434,
        -11.85312572857143
    ],
    [
        45.6537,
        -13.33318499047619
    ],
    [
        45.6641,
        -15.094805547619046
    ],
    [
        45.6744,
        -10.608833447619046
    ],
    [
        45.6848,
        -8.141616514285712
    ],
    [
        45.6952,
        -13.077404876190474
    ],
    [
        45.7055,
        -11.596126566666666
    ],
    [
        45.7159,
        -12.007216514285712
    ],
    [
        45.7263,
        -7.896768542857144
    ],
    [
        45.7366,
        -8.178465290476192
    ],
    [
        45.747,
        -7.19130761904762
    ],
    [
        45.7573,
        -5.546947833333332
    ],
    [
        45.7677,
        -9.777817071428572
    ],
    [
        45.7781,
        -17.17743611904762
    ],
    [
        45.7884,
        -20.87690701904762
    ],
    [
        45.7988,
        -24.165852342857143
    ],
    [
        45.8092,
        -20.87622976666667
    ],
    [
        45.8195,
        -20.3470083
    ],
    [
        45.8299,
        -21.33362417142857
    ],
    [
        45.8402,
        -17.88019771904762
    ],
    [
        45.8506,
        -21.170271795238097
    ],
    [
        45.861,
        -19.36147602857143
    ],
    [
        45.8713,
        -21.582580790476193
    ],
    [
        45.8817,
        -19.820766733333336
    ],
    [
        45.892,
        -19.920725900000004
    ],
    [
        45.9024,
        -24.856514261904767
    ],
    [
        45.9128,
        -19.920725900000004
    ],
    [
        45.9231,
        -23.869898390476195
    ],
    [
        45.9335,
        -20.99226875714286
    ],
    [
        45.9438,
        -22.636177042857145
    ],
    [
        45.9542,
        -23.34109616666667
    ],
    [
        45.9646,
        -24.575043257142863
    ],
    [
        45.9749,
        -22.108503571428574
    ],
    [
        45.9853,
        -22.105794576190476
    ],
    [
        45.9957,
        -20.256228438095242
    ],
    [
        46.006,
        -13.677886285714285
    ],
    [
        46.0164,
        -11.916072228571428
    ],
    [
        46.0268,
        -13.561335014285717
    ],
    [
        46.0371,
        -7.923530023809527
    ],
    [
        46.0475,
        -7.6420267761904785
    ],
    [
        46.0578,
        -15.041645823809528
    ],
    [
        46.0682,
        -13.80736010952381
    ],
    [
        46.0786,
        -16.438335771428573
    ],
    [
        46.0889,
        -12.737171747619048
    ],
    [
        46.0993,
        -12.032446123809526
    ],
    [
        46.1096,
        -12.306205090476194
    ],
    [
        46.12,
        -12.306205090476194
    ],
    [
        46.1304,
        -17.240638952380955
    ],
    [
        46.1407,
        -9.836956409523811
    ],
    [
        46.1511,
        -11.070226252380955
    ],
    [
        46.1614,
        -9.837633661904762
    ],
    [
        46.1718,
        -10.454268580952382
    ],
    [
        46.1822,
        -10.453591333333335
    ],
    [
        46.1925,
        -11.275545480952381
    ],
    [
        46.2029,
        -3.8752491809523804
    ],
    [
        46.2133,
        -3.258275638095237
    ],
    [
        46.2236,
        -5.520173342857144
    ],
    [
        46.234,
        0.11763164761904796
    ],
    [
        46.2443,
        -0.8695260238095234
    ],
    [
        46.2547,
        -5.185970471428572
    ],
    [
        46.2651,
        -9.416646209523812
    ],
    [
        46.2754,
        -5.305746738095238
    ],
    [
        46.2858,
        -4.6205592
    ],
    [
        46.2962,
        -0.5096597285714282
    ],
    [
        46.3065,
        -2.976876661904762
    ],
    [
        46.3169,
        -4.210146504761904
    ],
    [
        46.3272,
        -3.3879666095238097
    ],
    [
        46.3376,
        -8.321045976190476
    ],
    [
        46.348,
        -4.208792009523809
    ],
    [
        46.3583,
        -9.144580371428573
    ],
    [
        46.3687,
        -4.20879200952381
    ],
    [
        46.379,
        -5.4413846
    ],
    [
        46.3894,
        -4.208114761904762
    ],
    [
        46.3998,
        -3.96200259047619
    ],
    [
        46.4101,
        -5.607039628571428
    ],
    [
        46.4205,
        -10.540796242857141
    ],
    [
        46.4309,
        -15.473875609523807
    ],
    [
        46.4412,
        -9.924161323809523
    ],
    [
        46.4516,
        -15.679872085714285
    ],
    [
        46.4619,
        -9.759635047619048
    ],
    [
        46.4723,
        -9.143000123809523
    ],
    [
        46.4827,
        -5.852474547619046
    ],
    [
        46.493,
        -5.030294652380952
    ],
    [
        46.5034,
        -6.1262335142857145
    ],
    [
        46.5138,
        -11.059990128571426
    ],
    [
        46.5241,
        -12.046876899999997
    ],
    [
        46.5345,
        -12.45886984285714
    ],
    [
        46.5448,
        -14.925409523809522
    ],
    [
        46.5552,
        -10.814735799999998
    ],
    [
        46.5656,
        -14.926989766666663
    ],
    [
        46.5759,
        -14.926989766666665
    ],
    [
        46.5863,
        -18.876162257142852
    ],
    [
        46.5966,
        -16.858084338095235
    ],
    [
        46.607,
        -16.65208786190476
    ],
    [
        46.6174,
        -18.131469871428568
    ],
    [
        46.6277,
        -15.664027190476185
    ],
    [
        46.6381,
        -13.902406633333328
    ],
    [
        46.6484,
        -6.502787585714282
    ],
    [
        46.6588,
        -11.435866952380948
    ],
    [
        46.6692,
        -11.06182045238095
    ],
    [
        46.6795,
        -11.060465957142855
    ],
    [
        46.6899,
        -11.06012733333333
    ],
    [
        46.7003,
        -9.826067371428568
    ],
    [
        46.7106,
        -8.839451499999996
    ],
    [
        46.721,
        -7.195091709523808
    ],
    [
        46.7313,
        -6.37313756190476
    ],
    [
        46.7417,
        -6.372866661904766
    ],
    [
        46.7521,
        -6.371963666666666
    ],
    [
        46.7624,
        -2.2601611999999998
    ],
    [
        46.7728,
        -2.671025400000001
    ],
    [
        46.7832,
        2.26340846190476
    ],
    [
        46.7935,
        4.731302642857143
    ],
    [
        46.8039,
        8.681829628571428
    ],
    [
        46.8142,
        8.308111495238094
    ],
    [
        46.8246,
        6.498593328571429
    ],
    [
        46.835,
        13.898212376190477
    ],
    [
        46.8453,
        7.317612728571429
    ],
    [
        46.8557,
        5.5559921714285725
    ],
    [
        46.866,
        4.322722333333333
    ],
    [
        46.8764,
        3.6179967095238093
    ],
    [
        46.8868,
        -0.8692067571428569
    ],
    [
        46.8971,
        -10.736719985714286
    ],
    [
        46.9075,
        -14.436868133333334
    ],
    [
        46.9179,
        -13.408691766666667
    ],
    [
        46.9282,
        -14.943427566666665
    ],
    [
        46.9386,
        -19.879215928571426
    ],
    [
        46.9489,
        -19.87831293333333
    ],
    [
        46.9593,
        -17.246434276190474
    ],
    [
        46.9697,
        -16.30680010952381
    ],
    [
        46.98,
        -19.596422685714284
    ],
    [
        46.9904,
        -15.89661316190476
    ],
    [
        47.0007,
        -17.541875947619047
    ],
    [
        47.0111,
        -17.541875947619047
    ],
    [
        47.0215,
        -20.011124623809522
    ],
    [
        47.0318,
        -19.18894472857143
    ],
    [
        47.0422,
        -20.669952138095237
    ],
    [
        47.0526,
        -27.247391295238096
    ],
    [
        47.0629,
        -23.01671555714286
    ],
    [
        47.0733,
        -19.727995980952382
    ],
    [
        47.0836,
        -21.57823936666667
    ],
    [
        47.094,
        -15.940434376190476
    ],
    [
        47.1044,
        -12.649005804761904
    ],
    [
        47.1147,
        -8.420652061904761
    ],
    [
        47.1251,
        -4.308849595238094
    ],
    [
        47.1354,
        -5.912439009523809
    ],
    [
        47.1458,
        -5.364319080952381
    ],
    [
        47.1562,
        2.0386862142857147
    ],
    [
        47.1665,
        8.616125371428572
    ],
    [
        47.1769,
        2.036428723809525
    ],
    [
        47.1873,
        2.1930344190476196
    ],
    [
        47.1976,
        1.3695000285714287
    ],
    [
        47.208,
        -2.7418509380952374
    ],
    [
        47.2183,
        0.5482231333333344
    ],
    [
        47.2287,
        -0.4383927380952371
    ],
    [
        47.2391,
        2.0281469428571435
    ],
    [
        47.2494,
        1.2055155476190482
    ],
    [
        47.2598,
        -1.2596696380952377
    ],
    [
        47.2701,
        1.2070957952380943
    ],
    [
        47.2805,
        1.295404185714286
    ],
    [
        47.2909,
        2.9404412238095237
    ],
    [
        47.3012,
        4.544030638095238
    ],
    [
        47.3116,
        -0.800138676190478
    ],
    [
        47.322,
        -0.8010416714285717
    ],
    [
        47.3323,
        0.397127338095238
    ],
    [
        47.3427,
        -1.6967203428571442
    ],
    [
        47.353,
        1.7571124619047613
    ],
    [
        47.3634,
        0.5235039952380947
    ],
    [
        47.3738,
        -2.765892833333334
    ],
    [
        47.3841,
        -6.054612409523814
    ],
    [
        47.3945,
        0.11376854285714157
    ],
    [
        47.4049,
        0.1137685428571414
    ],
    [
        47.4152,
        3.814932566666666
    ],
    [
        47.4256,
        3.815384061904762
    ],
    [
        47.4359,
        2.1705727761904754
    ],
    [
        47.4463,
        2.170572776190476
    ],
    [
        47.4567,
        -3.9971309285714294
    ],
    [
        47.467,
        -1.5283337476190477
    ],
    [
        47.4774,
        5.871962552380952
    ],
    [
        47.4877,
        0.1151230476190492
    ],
    [
        47.4981,
        -0.9124325095238089
    ],
    [
        47.5085,
        -3.996284361904761
    ],
    [
        47.5188,
        -3.748953142857142
    ],
    [
        47.5292,
        -0.8713235142857143
    ],
    [
        47.5396,
        -0.04824062380952333
    ],
    [
        47.5499,
        -2.1860437999999993
    ],
    [
        47.5603,
        3.198329457142859
    ],
    [
        47.5706,
        -4.201966842857142
    ],
    [
        47.581,
        -4.201628219047619
    ],
    [
        47.5914,
        -5.024711109523809
    ],
    [
        47.6017,
        -1.733282538095238
    ],
    [
        47.6121,
        -1.7339597857142846
    ],
    [
        47.6225,
        -2.830651142857142
    ],
    [
        47.6328,
        -8.999709347619046
    ],
    [
        47.6432,
        -7.903469485714284
    ],
    [
        47.6535,
        -5.4369298047619035
    ],
    [
        47.6639,
        -1.983774252380952
    ],
    [
        47.6743,
        -1.7376620809523804
    ],
    [
        47.6846,
        -5.029090652380951
    ],
    [
        47.695,
        -9.140667376190473
    ],
    [
        47.7053,
        -4.206233514285714
    ],
    [
        47.7157,
        -2.561873723809523
    ],
    [
        47.7261,
        0.5213008761904773
    ],
    [
        47.7364,
        -1.699803885714286
    ],
    [
        47.7468,
        -4.166343566666667
    ],
    [
        47.7572,
        -3.3441636714285714
    ],
    [
        47.7675,
        -4.16634356190476
    ],
    [
        47.7779,
        -6.635592242857141
    ],
    [
        47.7882,
        -2.524692771428571
    ],
    [
        47.7986,
        -1.2914229285714274
    ],
    [
        47.809,
        -0.4683400380952375
    ],
    [
        47.8193,
        -0.4710490333333331
    ],
    [
        47.8297,
        -4.170858557142857
    ],
    [
        47.8401,
        2.409741090476192
    ],
    [
        47.8504,
        5.701169661904763
    ],
    [
        47.8608,
        6.2492895904761925
    ],
    [
        47.8711,
        -1.150329457142855
    ],
    [
        47.8815,
        1.3189192238095255
    ],
    [
        47.8919,
        -0.16046278571428457
    ],
    [
        47.9022,
        0.4981841285714305
    ],
    [
        47.9126,
        -2.795953433333332
    ],
    [
        47.9229,
        -3.7825693047619033
    ],
    [
        47.9333,
        -1.3160296238095226
    ],
    [
        47.9437,
        -4.769185176190474
    ],
    [
        47.954,
        -2.7948698333333315
    ],
    [
        47.9644,
        -3.781485704761903
    ],
    [
        47.9747,
        -6.248025385714284
    ],
    [
        47.9851,
        -4.6036655999999985
    ],
    [
        47.9955,
        -5.424942495238093
    ],
    [
        48.0058,
        0.3330257619047643
    ],
    [
        48.0162,
        5.266105128571431
    ],
    [
        48.0266,
        5.471650104761906
    ],
    [
        48.0369,
        2.183833523809525
    ],
    [
        48.0473,
        5.636989076190477
    ],
    [
        48.0576,
        0.2078927761904766
    ],
    [
        48.068,
        4.320146747619049
    ],
    [
        48.0784,
        3.0868769047619056
    ],
    [
        48.0887,
        10.486495952380952
    ],
    [
        48.0991,
        4.845981966666668
    ],
    [
        48.1095,
        6.325363976190477
    ],
    [
        48.1198,
        6.078710004761904
    ],
    [
        48.1302,
        11.840290247619047
    ],
    [
        48.1405,
        15.293445799999999
    ],
    [
        48.1509,
        11.592959028571428
    ],
    [
        48.1613,
        12.5795749
    ],
    [
        48.1716,
        16.525496595238092
    ],
    [
        48.182,
        16.278842623809524
    ],
    [
        48.1923,
        17.758224633333338
    ],
    [
        48.2027,
        19.813674371428572
    ],
    [
        48.2131,
        16.524051795238094
    ],
    [
        48.2234,
        10.971628514285712
    ],
    [
        48.2338,
        7.683811933333332
    ],
    [
        48.2442,
        5.833907171428572
    ],
    [
        48.2545,
        1.722104704761905
    ],
    [
        48.2649,
        -0.49845825714285735
    ],
    [
        48.2752,
        -1.6504131047619057
    ],
    [
        48.2856,
        -2.885037442857142
    ],
    [
        48.296,
        -2.8850374428571426
    ],
    [
        48.3063,
        -3.7072173333333334
    ],
    [
        48.3167,
        -3.4138074047619047
    ],
    [
        48.327,
        -1.8100148142857138
    ],
    [
        48.3374,
        4.356334395238096
    ],
    [
        48.3478,
        2.710620109523811
    ],
    [
        48.3581,
        -2.223813752380952
    ],
    [
        48.3685,
        -0.003250790476190025
    ],
    [
        48.3789,
        -2.469790471428571
    ],
    [
        48.3892,
        -8.63681692857143
    ],
    [
        48.3996,
        -8.020520628571429
    ],
    [
        48.4099,
        -11.966442323809524
    ],
    [
        48.4203,
        -11.966442323809524
    ],
    [
        48.4307,
        -11.027195157142856
    ],
    [
        48.441,
        -8.766200447619047
    ],
    [
        48.4514,
        -11.233643128571428
    ],
    [
        48.4618,
        -10.00037329047619
    ],
    [
        48.4721,
        -6.546675938095239
    ],
    [
        48.4825,
        -2.8461891666666674
    ],
    [
        48.4928,
        -1.529978938095239
    ],
    [
        48.5032,
        -2.3147870190476194
    ],
    [
        48.5136,
        -6.017305538095239
    ],
    [
        48.5239,
        -9.30602511904762
    ],
    [
        48.5343,
        -9.599435047619048
    ],
    [
        48.5446,
        -8.570807180952382
    ],
    [
        48.555,
        -18.436965914285718
    ],
    [
        48.5654,
        -22.550122880952383
    ],
    [
        48.5757,
        -20.698863619047618
    ],
    [
        48.5861,
        -26.621267852380953
    ],
    [
        48.5965,
        -29.087807538095234
    ],
    [
        48.6068,
        -27.031680552380948
    ],
    [
        48.6172,
        -26.963127938095237
    ],
    [
        48.6275,
        -24.496588252380953
    ],
    [
        48.6379,
        -23.26196391428571
    ],
    [
        48.6483,
        -27.489930657142857
    ],
    [
        48.6586,
        -29.83940307142857
    ],
    [
        48.669,
        -27.78395333333333
    ],
    [
        48.6793,
        -31.48376285714285
    ],
    [
        48.6897,
        -34.116183314285706
    ],
    [
        48.7001,
        -37.56933886666665
    ],
    [
        48.7104,
        -35.595023523809516
    ],
    [
        48.7208,
        -37.68784507619047
    ],
    [
        48.7312,
        -30.285517033333328
    ],
    [
        48.7415,
        -30.286420028571428
    ],
    [
        48.7519,
        -27.11515472380953
    ],
    [
        48.7622,
        -29.747033380952374
    ],
    [
        48.7726,
        -25.800569885714285
    ],
    [
        48.783,
        -18.39688734285715
    ],
    [
        48.7933,
        -18.48536504761905
    ],
    [
        48.8037,
        -8.613788323809526
    ],
    [
        48.8141,
        -3.6807089571428584
    ],
    [
        48.8244,
        -5.736158695238095
    ],
    [
        48.8348,
        -3.542474980952381
    ],
    [
        48.8451,
        -1.780660923809524
    ],
    [
        48.8555,
        -4.248555104761906
    ],
    [
        48.8659,
        1.917794104761904
    ],
    [
        48.8762,
        1.6362908571428574
    ],
    [
        48.8866,
        1.3896368857142842
    ],
    [
        48.8969,
        5.617603628571429
    ],
    [
        48.9073,
        7.262866414285715
    ],
    [
        48.9177,
        8.249482285714286
    ],
    [
        48.928,
        5.781588104761905
    ],
    [
        48.9384,
        6.720835271428572
    ],
    [
        48.9488,
        4.959021214285714
    ],
    [
        48.9591,
        6.193194052380953
    ],
    [
        48.9695,
        4.175116133333335
    ],
    [
        48.9798,
        4.174574333333335
    ],
    [
        48.9902,
        10.094269571428574
    ],
    [
        49.0006,
        5.159835709523811
    ],
    [
        49.0109,
        8.332842514285716
    ],
    [
        49.0213,
        3.3970541523809525
    ],
    [
        49.0316,
        2.1637843095238094
    ],
    [
        49.042,
        5.041413942857143
    ],
    [
        49.0524,
        1.7508883714285723
    ],
    [
        49.0627,
        1.633434100000001
    ],
    [
        49.0731,
        3.2786968857142864
    ],
    [
        49.0835,
        1.4287921238095243
    ],
    [
        49.0938,
        -2.5203803666666666
    ],
    [
        49.1042,
        -1.0404565571428568
    ],
    [
        49.1145,
        -1.3219598047619048
    ],
    [
        49.1249,
        3.611119561904762
    ],
    [
        49.1353,
        2.994146019047619
    ],
    [
        49.1456,
        5.050047257142857
    ],
    [
        49.156,
        4.521890038095239
    ],
    [
        49.1663,
        8.750243780952381
    ],
    [
        49.1767,
        14.09712209047619
    ],
    [
        49.1871,
        11.182120647619048
    ],
    [
        49.1974,
        10.935331228571428
    ],
    [
        49.2078,
        8.470146042857143
    ],
    [
        49.2182,
        9.70341588095238
    ],
    [
        49.2285,
        7.2355217000000005
    ],
    [
        49.2389,
        2.2997333380952383
    ],
    [
        49.2492,
        8.468791542857142
    ],
    [
        49.2596,
        6.824431752380954
    ],
    [
        49.27,
        7.853059619047618
    ],
    [
        49.2803,
        7.441969671428568
    ],
    [
        49.2907,
        8.264601066666666
    ],
    [
        49.301,
        3.9481566190476167
    ],
    [
        49.3114,
        7.650675138095238
    ],
    [
        49.3218,
        3.5397756666666647
    ],
    [
        49.3321,
        4.526391538095236
    ],
    [
        49.3425,
        0.8272592619047626
    ],
    [
        49.3529,
        0.4576169333333316
    ],
    [
        49.3632,
        -2.4200126999999987
    ],
    [
        49.3736,
        -3.65463703809524
    ],
    [
        49.3839,
        -6.1211767238095245
    ],
    [
        49.3943,
        -11.673600004761905
    ],
    [
        49.4047,
        -8.658940395238096
    ],
    [
        49.415,
        -8.658940395238098
    ],
    [
        49.4254,
        -12.113450447619048
    ],
    [
        49.4358,
        -11.866796476190478
    ],
    [
        49.4461,
        -6.933717109523811
    ],
    [
        49.4565,
        2.9405686047619035
    ],
    [
        49.4668,
        -0.7612726666666687
    ],
    [
        49.4772,
        -1.4659982904761926
    ],
    [
        49.4876,
        4.086424990476189
    ],
    [
        49.4979,
        4.333078961904759
    ],
    [
        49.5083,
        1.3170648523809527
    ],
    [
        49.5186,
        6.661234166666667
    ],
    [
        49.529,
        7.346083080952383
    ],
    [
        49.5394,
        4.057363504761906
    ],
    [
        49.5497,
        2.4130037142857157
    ],
    [
        49.5601,
        -0.4662061619047598
    ],
    [
        49.5705,
        1.2251353333333357
    ],
    [
        49.5808,
        7.802574490476194
    ],
    [
        49.5912,
        8.626108880952383
    ],
    [
        49.6015,
        4.925622109523812
    ],
    [
        49.6119,
        4.308309938095242
    ],
    [
        49.6223,
        3.7601900095238117
    ],
    [
        49.6326,
        5.980752971428575
    ],
    [
        49.643,
        6.1447374476190495
    ],
    [
        49.6533,
        7.131353319047621
    ],
    [
        49.6637,
        0.9650041095238121
    ],
    [
        49.6741,
        -2.328230457142858
    ],
    [
        49.6844,
        -5.2074403333333334
    ],
    [
        49.6948,
        -9.435794076190477
    ],
    [
        49.7052,
        -16.83812211904762
    ],
    [
        49.7155,
        -16.399927176190477
    ],
    [
        49.7259,
        -14.207447457142859
    ],
    [
        49.7362,
        -14.618537404761907
    ],
    [
        49.7466,
        -12.425756685714289
    ],
    [
        49.757,
        -2.5568889571428577
    ],
    [
        49.7673,
        -1.5291640857142856
    ],
    [
        49.7777,
        -1.528261090476191
    ],
    [
        49.7881,
        2.7028016476190477
    ],
    [
        49.7984,
        -2.846912638095239
    ],
    [
        49.8088,
        3.7305265190476185
    ],
    [
        49.8191,
        0.028685247619047043
    ],
    [
        49.8295,
        2.49590218095238
    ],
    [
        49.8399,
        0.028007999999998794
    ],
    [
        49.8502,
        -1.945223742857143
    ],
    [
        49.8606,
        0.5217674428571422
    ],
    [
        49.8709,
        -0.4653902285714292
    ],
    [
        49.8813,
        -0.8764801761904766
    ],
    [
        49.8917,
        -2.521742961904762
    ],
    [
        49.902,
        4.05659919047619
    ],
    [
        49.9124,
        8.167047161904762
    ],
    [
        49.9228,
        13.100126523809521
    ],
    [
        49.9331,
        15.29381023809524
    ],
    [
        49.9435,
        12.005090657142857
    ],
    [
        49.9538,
        10.360730866666668
    ],
    [
        49.9642,
        7.071108290476191
    ],
    [
        49.9746,
        -0.33121975238095097
    ],
    [
        49.9849,
        -2.1824790142857142
    ],
    [
        49.9953,
        -0.08965746190475953
    ],
    [
        50.0056,
        -5.519024661904762
    ],
    [
        50.016,
        -6.135659585714285
    ],
    [
        50.0264,
        -12.302008795238097
    ],
    [
        50.0367,
        -9.834114614285713
    ],
    [
        50.0471,
        -16.000463823809522
    ],
    [
        50.0575,
        -12.545953771428572
    ],
    [
        50.0678,
        -12.107758828571429
    ],
    [
        50.0782,
        -15.396478409523809
    ],
    [
        50.0885,
        -13.79268581904762
    ],
    [
        50.0989,
        -14.61486570952381
    ],
    [
        50.1093,
        -13.62770803809524
    ],
    [
        50.1196,
        -19.3829673
    ],
    [
        50.13,
        -19.547493576190476
    ],
    [
        50.1404,
        -21.308920633333337
    ],
    [
        50.1507,
        -23.659296042857143
    ],
    [
        50.1611,
        -19.548396571428572
    ],
    [
        50.1714,
        -19.137306623809526
    ],
    [
        50.1818,
        -12.556255476190477
    ],
    [
        50.1922,
        -11.32298563809524
    ],
    [
        50.2025,
        -10.499451247619048
    ],
    [
        50.2129,
        -13.414452690476192
    ],
    [
        50.2232,
        -11.275611066666666
    ],
    [
        50.2336,
        -10.747066847619047
    ],
    [
        50.244,
        -7.0472573238095215
    ],
    [
        50.2543,
        -2.1128234619047612
    ],
    [
        50.2647,
        1.5863088142857151
    ],
    [
        50.2751,
        1.833640033333334
    ],
    [
        50.2854,
        1.7654260428571429
    ],
    [
        50.2958,
        5.876325514285714
    ],
    [
        50.3061,
        10.195478957142857
    ],
    [
        50.3165,
        11.839838742857143
    ],
    [
        50.3269,
        10.304561142857144
    ],
    [
        50.3372,
        12.36001088095238
    ],
    [
        50.3476,
        15.816417228571424
    ],
    [
        50.3579,
        13.878034761904763
    ],
    [
        50.3683,
        12.116607704761902
    ],
    [
        50.3787,
        4.716988657142856
    ],
    [
        50.389,
        3.4823643190476212
    ],
    [
        50.3994,
        -2.686693885714285
    ],
    [
        50.4098,
        1.0144701380952377
    ],
    [
        50.4201,
        5.126724109523808
    ],
    [
        50.4305,
        2.660184423809523
    ],
    [
        50.4408,
        -0.6294381523809546
    ],
    [
        50.4512,
        0.07528747142857181
    ],
    [
        50.4616,
        -4.859146390476191
    ],
    [
        50.4719,
        -12.258765438095239
    ],
    [
        50.4823,
        -11.846998242857143
    ],
    [
        50.4926,
        -11.436585542857145
    ],
    [
        50.503,
        -12.465213409523813
    ],
    [
        50.5134,
        -19.86483245714286
    ],
    [
        50.5237,
        -27.267160500000003
    ],
    [
        50.5341,
        -27.432138276190482
    ],
    [
        50.5445,
        -26.884018347619055
    ],
    [
        50.5548,
        -27.130672319047623
    ],
    [
        50.5652,
        -30.216555914285717
    ],
    [
        50.5755,
        -28.366312528571434
    ],
    [
        50.5859,
        -26.393351685714293
    ],
    [
        50.5963,
        -26.393351685714283
    ],
    [
        50.6066,
        -23.9254575047619
    ],
    [
        50.617,
        -21.046924876190474
    ],
    [
        50.6273,
        -24.336998952380952
    ],
    [
        50.6377,
        -28.449252923809528
    ],
    [
        50.6481,
        -24.502789428571425
    ],
    [
        50.6584,
        -21.871271966666665
    ],
    [
        50.6688,
        -21.871271966666665
    ],
    [
        50.6792,
        -20.226009180952378
    ],
    [
        50.6895,
        -17.759469495238097
    ],
    [
        50.6999,
        -17.759920995238097
    ],
    [
        50.7102,
        -16.93774110476191
    ],
    [
        50.7206,
        -10.359398952380953
    ],
    [
        50.731,
        -5.4263195857142845
    ],
    [
        50.7413,
        -5.427674085714288
    ],
    [
        50.7517,
        -5.67378625714286
    ],
    [
        50.7621,
        -7.31814604761905
    ],
    [
        50.7724,
        -7.0708148285714305
    ],
    [
        50.7828,
        -8.92071959047619
    ],
    [
        50.7931,
        -10.242805757142857
    ],
    [
        50.8035,
        -8.515279828571428
    ],
    [
        50.8139,
        -3.582200461904761
    ],
    [
        50.8242,
        -4.404380357142856
    ],
    [
        50.8346,
        -1.1165637761904754
    ],
    [
        50.8449,
        -5.227463247619047
    ],
    [
        50.8553,
        -8.518891819047619
    ],
    [
        50.8657,
        -8.76554579047619
    ],
    [
        50.876,
        -8.766087590476191
    ],
    [
        50.8864,
        -11.23398177142857
    ],
    [
        50.8968,
        -15.344429742857145
    ],
    [
        50.9071,
        -12.877890061904763
    ],
    [
        50.9175,
        -12.466574361904764
    ],
    [
        50.9278,
        -13.288754252380954
    ],
    [
        50.9382,
        -13.28604525714286
    ],
    [
        50.9486,
        -14.108225152380957
    ],
    [
        50.9589,
        -11.091910042857144
    ],
    [
        50.9693,
        -10.35194813809524
    ],
    [
        50.9796,
        -7.06322855714286
    ],
    [
        50.99,
        -9.531122738095242
    ],
    [
        51.0004,
        -7.064583057142861
    ],
    [
        51.0107,
        -7.593417523809527
    ],
    [
        51.0211,
        -10.47172440476191
    ],
    [
        51.0315,
        -9.766611780952385
    ],
    [
        51.0418,
        -9.93104775714286
    ],
    [
        51.0522,
        -13.630857280952384
    ],
    [
        51.0625,
        -9.40289053809524
    ],
    [
        51.0729,
        -5.289282071428571
    ],
    [
        51.0833,
        0.8797761333333343
    ],
    [
        51.0936,
        -0.10683973809523668
    ],
    [
        51.104,
        0.7157916571428573
    ],
    [
        51.1144,
        4.415601180952383
    ],
    [
        51.1247,
        1.9490615000000022
    ],
    [
        51.1351,
        -2.5754111666666666
    ],
    [
        51.1454,
        -4.219770957142856
    ],
    [
        51.1558,
        -4.222479952380953
    ],
    [
        51.1662,
        -2.6951874333333334
    ],
    [
        51.1765,
        -4.887968152380951
    ],
    [
        51.1869,
        -3.1613903714285705
    ],
    [
        51.1972,
        -5.216840109523808
    ],
    [
        51.2076,
        -5.628155804761904
    ],
    [
        51.218,
        -4.394208714285713
    ],
    [
        51.2283,
        -5.626801304761903
    ],
    [
        51.2387,
        -2.337178728571427
    ],
    [
        51.2491,
        -3.4909993857142845
    ],
    [
        51.2594,
        -1.682203619047618
    ],
    [
        51.2698,
        -3.9020893333333326
    ],
    [
        51.2801,
        -3.196976709523809
    ],
    [
        51.2905,
        -4.842690995238095
    ],
    [
        51.3009,
        -10.76509522857143
    ],
    [
        51.3112,
        -15.698174595238097
    ],
    [
        51.3216,
        -18.985991176190478
    ],
    [
        51.3319,
        -19.39798411904762
    ],
    [
        51.3423,
        -21.8672328
    ],
    [
        51.3527,
        -21.864523804761905
    ],
    [
        51.363,
        -20.220164014285714
    ],
    [
        51.3734,
        -20.217455019047616
    ],
    [
        51.3838,
        -21.627293266666662
    ],
    [
        51.3941,
        -17.516393795238102
    ],
    [
        51.4045,
        -20.394926423809526
    ],
    [
        51.4148,
        -18.750566633333335
    ],
    [
        51.4252,
        -18.74966363809524
    ],
    [
        51.4356,
        -21.21688057142857
    ],
    [
        51.4459,
        -13.814552528571431
    ],
    [
        51.4563,
        -16.693762404761905
    ],
    [
        51.4666,
        -17.06723426666667
    ],
    [
        51.477,
        -16.902256490476198
    ],
    [
        51.4874,
        -16.90279829047619
    ],
    [
        51.4977,
        -16.198072666666665
    ],
    [
        51.5081,
        -14.178640247619049
    ],
    [
        51.5185,
        -8.256236014285715
    ],
    [
        51.5288,
        -5.78969632857143
    ],
    [
        51.5392,
        4.076462404761905
    ],
    [
        51.5495,
        5.721725190476191
    ],
    [
        51.5599,
        9.012250766666668
    ],
    [
        51.5703,
        9.012250766666666
    ],
    [
        51.5806,
        2.434811609523809
    ],
    [
        51.591,
        -3.1176116714285693
    ],
    [
        51.6014,
        -1.1789389571428561
    ],
    [
        51.6117,
        -8.578558004761906
    ],
    [
        51.6221,
        -7.638407842857141
    ],
    [
        51.6324,
        -8.049046290476191
    ],
    [
        51.6428,
        -1.471607133333334
    ],
    [
        51.6532,
        -1.2249531619047602
    ],
    [
        51.6635,
        -1.2276621571428559
    ],
    [
        51.6739,
        -2.461609247619047
    ],
    [
        51.6842,
        0.8284648285714302
    ],
    [
        51.6946,
        2.3078468380952373
    ],
    [
        51.705,
        2.746884580952382
    ],
    [
        51.7153,
        1.2195275619047625
    ],
    [
        51.7257,
        3.312349114285714
    ],
    [
        51.7361,
        -3.2677990380952386
    ],
    [
        51.7464,
        0.8440034285714277
    ],
    [
        51.7568,
        -6.556970119047621
    ],
    [
        51.7671,
        -6.556970119047621
    ],
    [
        51.7775,
        -6.144977176190477
    ],
    [
        51.7879,
        3.721181557142857
    ],
    [
        51.7982,
        8.037287380952383
    ],
    [
        51.8086,
        9.887192142857144
    ],
    [
        51.819,
        7.948519428571428
    ],
    [
        51.8293,
        15.349492976190474
    ],
    [
        51.8397,
        17.287875442857143
    ],
    [
        51.85,
        16.465244047619045
    ],
    [
        51.8604,
        10.984044752380953
    ],
    [
        51.8708,
        10.325397838095238
    ],
    [
        51.8811,
        10.325397838095242
    ],
    [
        51.8915,
        17.729080380952382
    ],
    [
        51.9018,
        12.793292019047621
    ],
    [
        51.9122,
        11.972015123809523
    ],
    [
        51.9226,
        11.697955157142857
    ],
    [
        51.9329,
        7.587507185714284
    ],
    [
        51.9433,
        10.876226761904762
    ],
    [
        51.9536,
        10.875323766666664
    ],
    [
        51.964,
        7.5857011904761915
    ],
    [
        51.9744,
        7.58705569047619
    ],
    [
        51.9847,
        9.23141548095238
    ],
    [
        51.9951,
        8.819422538095239
    ],
    [
        52.0055,
        3.8863431714285728
    ],
    [
        52.0158,
        -0.43247164761904633
    ],
    [
        52.0262,
        3.2673378761904788
    ],
    [
        52.0365,
        3.149432104761906
    ],
    [
        52.0469,
        -4.251541442857142
    ],
    [
        52.0573,
        -4.251541442857143
    ],
    [
        52.0676,
        -3.428910047619048
    ],
    [
        52.078,
        -3.428910047619046
    ],
    [
        52.0883,
        -4.2515414428571425
    ],
    [
        52.0987,
        -6.718081128571429
    ],
    [
        52.1091,
        -12.358595114285716
    ],
    [
        52.1194,
        -14.826489295238094
    ],
    [
        52.1298,
        -18.938291761904765
    ],
    [
        52.1402,
        -16.470849080952384
    ],
    [
        52.1505,
        -12.242495338095239
    ],
    [
        52.1609,
        -16.470462080952384
    ],
    [
        52.1712,
        -16.469559085714284
    ],
    [
        52.1816,
        -17.291738976190477
    ],
    [
        52.192,
        -15.64783069047619
    ],
    [
        52.2023,
        -18.525460323809522
    ],
    [
        52.2127,
        -18.114144628571427
    ],
    [
        52.2231,
        -20.580684309523807
    ],
    [
        52.2334,
        -14.658280076190476
    ],
    [
        52.2438,
        -20.82530653333333
    ],
    [
        52.2541,
        -24.9357545047619
    ],
    [
        52.2645,
        -19.18049524285714
    ],
    [
        52.2749,
        -21.23639648095238
    ],
    [
        52.2852,
        -20.413765090476186
    ],
    [
        52.2956,
        -20.48228008095238
    ],
    [
        52.3059,
        -16.37002610952381
    ],
    [
        52.3163,
        -21.303105471428566
    ],
    [
        52.3267,
        -21.421011242857144
    ],
    [
        52.337,
        -21.41830224761905
    ],
    [
        52.3474,
        -14.015974204761903
    ],
    [
        52.3578,
        -18.129131171428575
    ],
    [
        52.3681,
        -12.491326180952383
    ],
    [
        52.3785,
        -18.129518171428572
    ],
    [
        52.3888,
        -18.129518171428575
    ],
    [
        52.3992,
        -10.72719012857143
    ],
    [
        52.4096,
        -7.436664552380953
    ],
    [
        52.4199,
        -11.136474076190478
    ],
    [
        52.4303,
        -11.135796828571431
    ],
    [
        52.4406,
        -3.733468785714286
    ],
    [
        52.451,
        -6.364444447619048
    ],
    [
        52.4614,
        -0.19741799047619138
    ],
    [
        52.4717,
        2.2677671952380956
    ],
    [
        52.4821,
        0.6234074047619055
    ],
    [
        52.4925,
        2.268218695238095
    ],
    [
        52.5028,
        -0.0817697142857133
    ],
    [
        52.5132,
        2.18012799047619
    ],
    [
        52.5235,
        -1.9307714809523806
    ],
    [
        52.5339,
        5.4688475666666685
    ],
    [
        52.5443,
        5.057531871428573
    ],
    [
        52.5546,
        7.522717057142859
    ],
    [
        52.565,
        2.093349857142857
    ],
    [
        52.5754,
        9.494323404761907
    ],
    [
        52.5857,
        9.494323404761907
    ],
    [
        52.5961,
        16.895296952380956
    ],
    [
        52.6064,
        23.472736109523815
    ],
    [
        52.6168,
        23.470027114285717
    ],
    [
        52.6272,
        19.768185842857143
    ],
    [
        52.6375,
        24.701265209523815
    ],
    [
        52.6479,
        25.112355157142858
    ],
    [
        52.6582,
        18.943296952380955
    ],
    [
        52.6686,
        21.821603833333334
    ],
    [
        52.679,
        16.888524466666667
    ],
    [
        52.6893,
        21.82431282857143
    ],
    [
        52.6997,
        22.029857804761907
    ],
    [
        52.7101,
        16.27188954761905
    ],
    [
        52.7204,
        14.510075490476193
    ],
    [
        52.7308,
        10.398273023809528
    ],
    [
        52.7411,
        17.797892071428578
    ],
    [
        52.7515,
        15.332706885714291
    ],
    [
        52.7619,
        15.744474080952383
    ],
    [
        52.7722,
        16.568008471428577
    ],
    [
        52.7826,
        12.125798947619055
    ],
    [
        52.793,
        9.660613761904765
    ],
    [
        52.8033,
        9.660613761904763
    ],
    [
        52.8137,
        9.660613761904763
    ],
    [
        52.824,
        3.0831746047619055
    ],
    [
        52.8344,
        -2.5550173857142835
    ],
    [
        52.8448,
        -0.3344544238095224
    ],
    [
        52.8551,
        -2.8009941047619034
    ],
    [
        52.8655,
        -3.623173999999998
    ],
    [
        52.8758,
        0.07799002380952522
    ],
    [
        52.8862,
        -1.97813696190476
    ],
    [
        52.8966,
        -3.62249675238095
    ],
    [
        52.9069,
        -11.026179295238094
    ],
    [
        52.9173,
        -9.422928504761904
    ],
    [
        52.9277,
        -5.7204099857142845
    ],
    [
        52.938,
        -8.189658666666665
    ],
    [
        52.9484,
        -6.7097348571428554
    ],
    [
        52.9587,
        -11.19410619047619
    ],
    [
        52.9691,
        -14.48418026666667
    ],
    [
        52.9795,
        -14.210120300000003
    ],
    [
        52.9898,
        -14.211023295238094
    ],
    [
        53.0002,
        -4.342155566666664
    ],
    [
        53.0105,
        -11.745838109523808
    ],
    [
        53.0209,
        -21.611996842857145
    ],
    [
        53.0313,
        -19.144102661904764
    ],
    [
        53.0416,
        -19.144102661904764
    ],
    [
        53.052,
        -17.616810142857144
    ],
    [
        53.0624,
        -21.069965695238096
    ],
    [
        53.0727,
        -19.42605740952381
    ],
    [
        53.0831,
        -17.781697619047616
    ],
    [
        53.0934,
        -21.482861642857145
    ],
    [
        53.1038,
        -20.660681747619048
    ],
    [
        53.1142,
        -23.94940132380952
    ],
    [
        53.1245,
        -20.65797275238095
    ],
    [
        53.1349,
        -19.178048942857142
    ],
    [
        53.1452,
        -16.710832009523806
    ],
    [
        53.1556,
        -12.268351585714285
    ],
    [
        53.166,
        -10.047788623809524
    ],
    [
        53.1763,
        -15.429576023809524
    ],
    [
        53.1867,
        -9.671607766666666
    ],
    [
        53.1971,
        -9.671607766666666
    ],
    [
        53.2074,
        -3.0932656142857153
    ],
    [
        53.2178,
        -10.49423916190476
    ],
    [
        53.2281,
        -6.791720642857145
    ],
    [
        53.2385,
        -0.21337849047619223
    ],
    [
        53.2489,
        -3.504807061904763
    ],
    [
        53.2592,
        -2.847604942857145
    ],
    [
        53.2696,
        -2.4365149952380953
    ],
    [
        53.2799,
        -0.4632832523809531
    ],
    [
        53.2903,
        1.3465058142857143
    ],
    [
        53.3007,
        -1.1200338666666667
    ],
    [
        53.311,
        -0.29807971904762015
    ],
    [
        53.3214,
        0.40664590476190365
    ],
    [
        53.3318,
        2.87318559047619
    ],
    [
        53.3421,
        6.985439561904761
    ],
    [
        53.3525,
        9.45197924761905
    ],
    [
        53.3628,
        7.769324123809524
    ],
    [
        53.3732,
        9.249247933333333
    ],
    [
        53.3836,
        6.370038057142857
    ],
    [
        53.3939,
        10.316501552380952
    ],
    [
        53.4043,
        5.99734810952381
    ],
    [
        53.4147,
        5.840742414285715
    ],
    [
        53.425,
        -0.7375997380952387
    ],
    [
        53.4354,
        6.663373809523811
    ],
    [
        53.4457,
        6.252283861904764
    ],
    [
        53.4561,
        4.607021076190479
    ],
    [
        53.4665,
        1.728714195238097
    ],
    [
        53.4768,
        -2.2172074999999984
    ],
    [
        53.4872,
        -5.095740128571428
    ],
    [
        53.4975,
        -5.835702033333332
    ],
    [
        53.5079,
        -6.000228309523809
    ],
    [
        53.5183,
        -4.520304499999997
    ],
    [
        53.5286,
        -9.042068171428571
    ],
    [
        53.539,
        -10.980740885714285
    ],
    [
        53.5494,
        -6.869841414285717
    ],
    [
        53.5597,
        -10.57100543809524
    ],
    [
        53.5701,
        -20.43716417142857
    ],
    [
        53.5804,
        -15.052667776190477
    ],
    [
        53.5908,
        -17.27255349047619
    ],
    [
        53.6012,
        -13.982930914285713
    ],
    [
        53.6115,
        -14.641577828571428
    ],
    [
        53.6219,
        -14.024265657142857
    ],
    [
        53.6323,
        -13.936174952380952
    ],
    [
        53.6426,
        -10.64655237619048
    ],
    [
        53.653,
        -15.579631742857142
    ],
    [
        53.6633,
        -11.467377771428572
    ],
    [
        53.6737,
        -12.172490395238096
    ],
    [
        53.6841,
        -8.472003623809526
    ],
    [
        53.6944,
        -8.472003623809528
    ],
    [
        53.7048,
        -9.29463501904762
    ],
    [
        53.7151,
        -8.883545071428573
    ],
    [
        53.7255,
        -12.17271614761905
    ],
    [
        53.7359,
        -13.653994457142861
    ],
    [
        53.7462,
        -7.487645247619048
    ],
    [
        53.7566,
        -6.253698157142858
    ],
    [
        53.767,
        -10.364597628571428
    ],
    [
        53.7773,
        -11.599221966666667
    ],
    [
        53.7877,
        -7.652758471428572
    ],
    [
        53.798,
        -12.588546833333334
    ],
    [
        53.8084,
        -13.821816671428573
    ],
    [
        53.8188,
        -12.999636780952379
    ],
    [
        53.8291,
        -16.290162357142858
    ],
    [
        53.8395,
        -16.290839604761906
    ],
    [
        53.8498,
        -17.318564476190478
    ],
    [
        53.8602,
        -18.96292426666667
    ],
    [
        53.8706,
        -23.898712628571428
    ],
    [
        53.8809,
        -26.983580352380955
    ],
    [
        53.8913,
        -21.345388361904764
    ],
    [
        53.9017,
        -26.281176719047625
    ],
    [
        53.912,
        -23.814637033333334
    ],
    [
        53.9224,
        -22.333358723809525
    ],
    [
        53.9327,
        -23.977718509523807
    ],
    [
        53.9431,
        -20.688547433333333
    ],
    [
        53.9535,
        -15.754113571428572
    ],
    [
        53.9638,
        -17.53550334285714
    ],
    [
        53.9742,
        -16.3022335
    ],
    [
        53.9845,
        -15.480956604761905
    ],
    [
        53.9949,
        -13.717788047619045
    ],
    [
        54.0053,
        -11.908540780952379
    ],
    [
        54.0156,
        -9.442001099999997
    ],
    [
        54.026,
        -7.523882347619049
    ],
    [
        54.0364,
        -9.003806157142854
    ],
    [
        54.0467,
        -1.6014781142857122
    ],
    [
        54.0571,
        -3.539860580952381
    ],
    [
        54.0674,
        -1.0733208952380953
    ],
    [
        54.0778,
        -1.8955007904761902
    ],
    [
        54.0882,
        1.8063404809523826
    ],
    [
        54.0985,
        -0.043225657142856325
    ],
    [
        54.1089,
        -3.743035180952381
    ],
    [
        54.1192,
        1.1941076761904779
    ],
    [
        54.1296,
        3.28097132857143
    ],
    [
        54.14,
        6.734126880952381
    ],
    [
        54.1503,
        10.434613652380953
    ],
    [
        54.1607,
        9.163520495238096
    ],
    [
        54.1711,
        7.519612209523811
    ],
    [
        54.1814,
        7.081116266666667
    ],
    [
        54.1918,
        5.143120800000002
    ],
    [
        54.2021,
        7.610563480952381
    ],
    [
        54.2125,
        9.960551890476191
    ],
    [
        54.2229,
        6.671380814285715
    ],
    [
        54.2332,
        2.5604813428571442
    ],
    [
        54.2436,
        8.044690633333332
    ],
    [
        54.254,
        7.058074761904762
    ],
    [
        54.2643,
        2.947175290476191
    ],
    [
        54.2747,
        2.4183408238095248
    ],
    [
        54.285,
        -3.3369184380952377
    ],
    [
        54.2954,
        -0.8694757571428565
    ],
    [
        54.3058,
        -0.2521635857142859
    ],
    [
        54.3161,
        4.6822702761904775
    ],
    [
        54.3265,
        3.449000433333334
    ],
    [
        54.3368,
        -0.2521635904761911
    ],
    [
        54.3472,
        -2.3403817428571423
    ],
    [
        54.3576,
        -7.273461104761904
    ],
    [
        54.3679,
        -7.273461104761904
    ],
    [
        54.3783,
        -4.358459661904762
    ],
    [
        54.3887,
        -6.415038147619048
    ],
    [
        54.399,
        -6.133147900000001
    ],
    [
        54.4094,
        -0.4953429095238095
    ],
    [
        54.4197,
        1.150371376190476
    ],
    [
        54.4301,
        -5.427970776190476
    ],
    [
        54.4405,
        -2.9614310952380944
    ],
    [
        54.4508,
        -6.250150671428573
    ],
    [
        54.4612,
        -11.891051657142858
    ],
    [
        54.4715,
        -11.891051657142858
    ],
    [
        54.4819,
        -10.246691866666668
    ],
    [
        54.4923,
        -4.076956414285713
    ],
    [
        54.5026,
        3.3226626333333336
    ],
    [
        54.513,
        3.3217596380952377
    ],
    [
        54.5234,
        2.9517786857142854
    ],
    [
        54.5337,
        -4.451903857142858
    ],
    [
        54.5441,
        -5.274083752380953
    ],
    [
        54.5544,
        -2.395551123809524
    ],
    [
        54.5648,
        -0.9148146142857146
    ],
    [
        54.5752,
        -0.09263472380952295
    ],
    [
        54.5855,
        3.609206547619048
    ],
    [
        54.5959,
        6.075746233333334
    ],
    [
        54.6063,
        6.69271978095238
    ],
    [
        54.6166,
        9.041805195238094
    ],
    [
        54.627,
        6.57526550952381
    ],
    [
        54.6373,
        6.573911009523809
    ],
    [
        54.6477,
        11.506990376190476
    ],
    [
        54.6581,
        11.154627566666667
    ],
    [
        54.6684,
        16.536168695238093
    ],
    [
        54.6788,
        16.624307776190477
    ],
    [
        54.6891,
        15.80167638095238
    ],
    [
        54.6995,
        17.44603617142857
    ],
    [
        54.7099,
        10.865888019047619
    ],
    [
        54.7202,
        10.043708128571428
    ],
    [
        54.7306,
        3.4635599761904725
    ],
    [
        54.741,
        1.983636166666666
    ],
    [
        54.7513,
        5.686154685714283
    ],
    [
        54.7617,
        6.508334580952379
    ],
    [
        54.772,
        7.329611476190474
    ],
    [
        54.7824,
        7.699795604761901
    ],
    [
        54.7928,
        10.048881019047615
    ],
    [
        54.8031,
        4.701776961904762
    ],
    [
        54.8135,
        2.235237276190475
    ],
    [
        54.8238,
        7.787999180952381
    ],
    [
        54.8342,
        4.499279600000001
    ],
    [
        54.8446,
        2.0327399190476174
    ],
    [
        54.8549,
        -5.366879128571431
    ],
    [
        54.8653,
        -6.353495000000002
    ],
    [
        54.8757,
        -10.93421155714286
    ],
    [
        54.886,
        -10.395515647619051
    ],
    [
        54.8964,
        -10.48326772857143
    ],
    [
        54.9067,
        -11.304996123809524
    ],
    [
        54.9171,
        -11.962740042857146
    ],
    [
        54.9275,
        -11.552327342857144
    ],
    [
        54.9378,
        -11.552327342857145
    ],
    [
        54.9482,
        -11.552327342857144
    ],
    [
        54.9586,
        -10.072403533333334
    ],
    [
        54.9689,
        -3.9033453285714277
    ],
    [
        54.9793,
        -4.889961199999999
    ],
    [
        54.9896,
        -7.904620809523811
    ],
    [
        55,
        -6.054377423809523
    ],
    [
        55.0104,
        -6.053603423809523
    ],
    [
        55.0207,
        -7.286647519047618
    ],
    [
        55.0311,
        -4.820107833333333
    ],
    [
        55.0414,
        -10.372531114285714
    ],
    [
        55.0518,
        -7.494224233333333
    ],
    [
        55.0622,
        -8.480840104761905
    ],
    [
        55.0725,
        -1.0812210571428575
    ],
    [
        55.0829,
        -1.3285522761904744
    ],
    [
        55.0932,
        -1.3312612714285714
    ],
    [
        55.1036,
        -4.784958623809523
    ],
    [
        55.114,
        -2.435873209523809
    ],
    [
        55.1243,
        0.8528463714285709
    ],
    [
        55.1347,
        -0.6821904285714289
    ],
    [
        55.1451,
        2.196116452380952
    ],
    [
        55.1554,
        -0.6815131809523818
    ],
    [
        55.1658,
        4.25427518095238
    ],
    [
        55.1761,
        4.6239175095238085
    ],
    [
        55.1865,
        4.621208514285714
    ],
    [
        55.1969,
        6.430455780952381
    ],
    [
        55.2072,
        6.978575709523809
    ],
    [
        55.2176,
        4.100043080952381
    ],
    [
        55.2279,
        2.689430833333334
    ],
    [
        55.2383,
        6.800330304761904
    ],
    [
        55.2487,
        3.512513723809523
    ],
    [
        55.259,
        3.142532771428571
    ],
    [
        55.2694,
        -0.557954000000001
    ],
    [
        55.2798,
        5.361741238095238
    ],
    [
        55.2901,
        5.363095738095237
    ],
    [
        55.3005,
        6.103734890476189
    ],
    [
        55.3108,
        12.272793095238093
    ],
    [
        55.3212,
        14.739332776190476
    ],
    [
        55.3316,
        8.161893619047621
    ],
    [
        55.3419,
        5.283586738095237
    ],
    [
        55.3523,
        0.8989282999999991
    ],
    [
        55.3627,
        -5.681219852380953
    ],
    [
        55.373,
        -4.447950009523809
    ],
    [
        55.3834,
        -9.381029376190476
    ],
    [
        55.3937,
        -9.586687228571432
    ],
    [
        55.4041,
        -12.053226914285714
    ],
    [
        55.4145,
        -10.40796412857143
    ],
    [
        55.4248,
        -11.230144023809524
    ],
    [
        55.4352,
        -4.6518018714285745
    ],
    [
        55.4455,
        -4.651801871428573
    ],
    [
        55.4559,
        -6.131725680952382
    ],
    [
        55.4663,
        -8.764146138095239
    ],
    [
        55.4766,
        -6.133170476190478
    ],
    [
        55.487,
        -8.60241915714286
    ],
    [
        55.4974,
        -15.179858314285717
    ],
    [
        55.5077,
        -16.003392704761907
    ],
    [
        55.5181,
        -13.864821980952383
    ],
    [
        55.5284,
        -15.920271719047625
    ],
    [
        55.5388,
        -17.56553450476191
    ],
    [
        55.5492,
        -17.565534504761906
    ],
    [
        55.5595,
        -16.331587414285714
    ],
    [
        55.5699,
        -12.631777890476192
    ],
    [
        55.5803,
        -12.629068895238095
    ],
    [
        55.5906,
        -10.162529214285716
    ],
    [
        55.601,
        -7.697344028571429
    ],
    [
        55.6113,
        -7.861328504761907
    ],
    [
        55.6217,
        -9.093921095238096
    ],
    [
        55.6321,
        -12.109935204761902
    ],
    [
        55.6424,
        -8.819861128571429
    ],
    [
        55.6528,
        -18.686019861904764
    ],
    [
        55.6631,
        -16.33654744761905
    ],
    [
        55.6735,
        -22.258951680952386
    ],
    [
        55.6839,
        -23.739688190476194
    ],
    [
        55.6942,
        -25.932167909523812
    ],
    [
        55.7046,
        -19.35201975714286
    ],
    [
        55.715,
        -18.41277259047619
    ],
    [
        55.7253,
        -18.412772590476195
    ],
    [
        55.7357,
        -18.82476553333334
    ],
    [
        55.746,
        -18.496164476190483
    ],
    [
        55.7564,
        -19.318795871428577
    ],
    [
        55.7668,
        -11.919176823809531
    ],
    [
        55.7771,
        -6.986097457142865
    ],
    [
        55.7875,
        -10.685906980952387
    ],
    [
        55.7979,
        -5.752827614285716
    ],
    [
        55.8082,
        -7.397187400000002
    ],
    [
        55.8186,
        -9.862372585714288
    ],
    [
        55.8289,
        -9.58048233809524
    ],
    [
        55.8393,
        -9.169166642857144
    ],
    [
        55.8497,
        -9.854316557142859
    ],
    [
        55.86,
        -13.55548058095238
    ],
    [
        55.8704,
        -8.622401214285716
    ],
    [
        55.8807,
        -15.199840371428573
    ],
    [
        55.8911,
        -11.497999100000003
    ],
    [
        55.9015,
        -9.030104919047622
    ],
    [
        55.9118,
        -13.417773352380957
    ],
    [
        55.9222,
        -15.062133142857146
    ],
    [
        55.9325,
        -12.712660728571434
    ],
    [
        55.9429,
        -15.590290361904765
    ],
    [
        55.9533,
        -17.52867282857143
    ],
    [
        55.9636,
        -15.390734200000006
    ],
    [
        55.974,
        -12.922840019047623
    ],
    [
        55.9844,
        -16.375995571428575
    ],
    [
        55.9947,
        -18.84118075714286
    ],
    [
        56.0051,
        -11.441561709523812
    ],
    [
        56.0155,
        -11.935140547619051
    ],
    [
        56.0258,
        -11.770704571428572
    ],
    [
        56.0362,
        -5.192362419047624
    ],
    [
        56.0465,
        -5.104271714285719
    ],
    [
        56.0569,
        -9.216074180952385
    ],
    [
        56.0673,
        -5.5162646571428615
    ],
    [
        56.0776,
        -5.51694190476191
    ],
    [
        56.088,
        -4.283672061904767
    ],
    [
        56.0983,
        -4.283672061904765
    ],
    [
        56.1087,
        -3.049724971428572
    ],
    [
        56.1191,
        -4.694987757142858
    ],
    [
        56.1294,
        -0.46392501904761996
    ],
    [
        56.1398,
        -0.46392501904761996
    ],
    [
        56.1501,
        -0.8743377190476186
    ],
    [
        56.1605,
        -1.2863306619047616
    ],
    [
        56.1709,
        -3.047757719047619
    ],
    [
        56.1812,
        -5.925387352380953
    ],
    [
        56.1916,
        -7.158657195238096
    ],
    [
        56.2019,
        -5.184883652380954
    ],
    [
        56.2123,
        -9.297137623809524
    ],
    [
        56.2227,
        -9.297137623809524
    ],
    [
        56.233,
        -9.62573868095238
    ],
    [
        56.2434,
        -8.144460371428574
    ],
    [
        56.2538,
        -9.78972315714286
    ],
    [
        56.2641,
        -14.106167604761906
    ],
    [
        56.2745,
        -9.994365138095239
    ],
    [
        56.2848,
        -13.448062490476191
    ],
    [
        56.2952,
        -13.20073127142857
    ],
    [
        56.3056,
        -15.667270957142856
    ],
    [
        56.3159,
        -9.912011695238096
    ],
    [
        56.3263,
        -10.52898523809524
    ],
    [
        56.3367,
        -8.06109105714286
    ],
    [
        56.347,
        -7.9730003523809545
    ],
    [
        56.3574,
        -9.617360142857144
    ],
    [
        56.3677,
        -10.02867583809524
    ],
    [
        56.3781,
        -10.849952733333334
    ],
    [
        56.3885,
        -10.027772842857143
    ],
    [
        56.3988,
        -3.8587146380952375
    ],
    [
        56.4092,
        2.3076345714285713
    ],
    [
        56.4195,
        0.08652980952380944
    ],
    [
        56.4299,
        0.08652980952380927
    ],
    [
        56.4403,
        -1.1467400285714286
    ],
    [
        56.4506,
        4.611228228571429
    ],
    [
        56.461,
        1.321154152380955
    ],
    [
        56.4714,
        -1.1467400285714262
    ],
    [
        56.4817,
        8.719418704761907
    ],
    [
        56.4921,
        2.1410765523809543
    ],
    [
        56.5024,
        -1.807554138095237
    ],
    [
        56.5128,
        -1.3142462047619032
    ],
    [
        56.5232,
        4.852103004761907
    ],
    [
        56.5335,
        5.017080780952383
    ],
    [
        56.5439,
        6.868001414285717
    ],
    [
        56.5543,
        3.5788303380952393
    ],
    [
        56.5646,
        2.55110546666667
    ],
    [
        56.575,
        5.840728042857146
    ],
    [
        56.5853,
        4.4699767190476205
    ],
    [
        56.5957,
        2.0007280380952404
    ],
    [
        56.6061,
        6.111627509523812
    ],
    [
        56.6164,
        -1.2920550333333334
    ],
    [
        56.6268,
        -6.2251344
    ],
    [
        56.6371,
        -6.635547100000001
    ],
    [
        56.6475,
        -9.924266676190477
    ],
    [
        56.6579,
        -11.980167914285717
    ],
    [
        56.6682,
        -21.84903564285715
    ],
    [
        56.6786,
        -20.204224352380955
    ],
    [
        56.689,
        -18.441055795238096
    ],
    [
        56.6993,
        -23.374135161904764
    ],
    [
        56.7097,
        -16.796696004761905
    ],
    [
        56.72,
        -12.477542561904762
    ],
    [
        56.7304,
        -7.048446261904763
    ],
    [
        56.7408,
        -14.44806530952381
    ],
    [
        56.7511,
        -12.9686833
    ],
    [
        56.7615,
        -9.269551023809523
    ],
    [
        56.7718,
        -9.270905523809525
    ],
    [
        56.7822,
        -8.44917712857143
    ],
    [
        56.7926,
        -8.450080123809524
    ],
    [
        56.8029,
        -9.135907285714287
    ],
    [
        56.8133,
        -4.648826957142857
    ],
    [
        56.8237,
        -6.763003828571431
    ],
    [
        56.834,
        -6.76164932857143
    ],
    [
        56.8444,
        -7.378284247619049
    ],
    [
        56.8547,
        -4.088661671428573
    ],
    [
        56.8651,
        -0.38885214761904846
    ],
    [
        56.8755,
        1.66704909047619
    ],
    [
        56.8858,
        8.244488247619048
    ],
    [
        56.8962,
        6.973148819047617
    ],
    [
        56.9066,
        8.382600066666665
    ],
    [
        56.9169,
        7.677874442857142
    ],
    [
        56.9273,
        6.033514652380954
    ],
    [
        56.9376,
        9.11668925238095
    ],
    [
        56.948,
        2.535638104761905
    ],
    [
        56.9584,
        5.002177785714287
    ],
    [
        56.9687,
        5.002177785714286
    ],
    [
        56.9791,
        -0.47932250952381056
    ],
    [
        56.9894,
        2.810751566666666
    ],
    [
        56.9998,
        2.810751566666666
    ],
    [
        57.0102,
        1.7830266952380944
    ],
    [
        57.0205,
        1.7830266952380938
    ],
    [
        57.0309,
        0.5873749380952379
    ],
    [
        57.0412,
        -2.2315275523809524
    ],
    [
        57.0516,
        0.23501212857142934
    ],
    [
        57.062,
        3.319541228571429
    ],
    [
        57.0723,
        0.851647047619047
    ],
    [
        57.0827,
        3.729276680952382
    ],
    [
        57.0931,
        1.6738269428571424
    ],
    [
        57.1034,
        4.965255514285714
    ],
    [
        57.1138,
        2.9474238666666674
    ],
    [
        57.1241,
        7.17577760952381
    ],
    [
        57.1345,
        2.9447148714285722
    ],
    [
        57.1449,
        2.9447148714285722
    ],
    [
        57.1552,
        5.411254557142858
    ],
    [
        57.1656,
        5.8232475
    ],
    [
        57.176,
        10.759035861904763
    ],
    [
        57.1863,
        7.059226338095238
    ],
    [
        57.1967,
        12.543435628571428
    ],
    [
        57.207,
        11.721255733333335
    ],
    [
        57.2174,
        10.898624342857143
    ],
    [
        57.2278,
        9.459358028571428
    ],
    [
        57.2381,
        11.515485014285716
    ],
    [
        57.2485,
        13.159844804761907
    ],
    [
        57.2588,
        13.158490304761907
    ],
    [
        57.2692,
        15.625029985714288
    ],
    [
        57.2796,
        10.690596123809527
    ],
    [
        57.2899,
        10.27928042857143
    ],
    [
        57.3003,
        7.812740742857145
    ],
    [
        57.3107,
        11.101460319047622
    ],
    [
        57.321,
        8.632211638095237
    ],
    [
        57.3314,
        9.45348853333333
    ],
    [
        57.3417,
        3.286462076190476
    ],
    [
        57.3521,
        8.222250438095235
    ],
    [
        57.3625,
        8.222250438095235
    ],
    [
        57.3728,
        3.289171071428572
    ],
    [
        57.3832,
        3.5358250428571423
    ],
    [
        57.3936,
        3.5331160476190475
    ],
    [
        57.4039,
        7.2342800714285715
    ],
    [
        57.4143,
        1.065221866666666
    ],
    [
        57.4246,
        6.001010228571428
    ],
    [
        57.435,
        8.879542857142857
    ],
    [
        57.4454,
        4.769094885714287
    ],
    [
        57.4557,
        3.9469149904761904
    ],
    [
        57.4661,
        -0.9861643761904764
    ],
    [
        57.4764,
        0.9333088761904772
    ],
    [
        57.4868,
        -0.5466149333333321
    ],
    [
        57.4972,
        0.6859776571428581
    ],
    [
        57.5075,
        1.2151991238095228
    ],
    [
        57.5179,
        3.2706488619047627
    ],
    [
        57.5283,
        5.739897542857142
    ],
    [
        57.5386,
        1.6289980714285714
    ],
    [
        57.549,
        -1.6615275047619051
    ],
    [
        57.5593,
        -1.1323060380952379
    ],
    [
        57.5697,
        -2.7775688238095233
    ],
    [
        57.5801,
        -0.3110291428571423
    ],
    [
        57.5904,
        4.6220502238095245
    ],
    [
        57.6008,
        3.963403309523809
    ],
    [
        57.6111,
        -0.9696760571428565
    ],
    [
        57.6215,
        -2.2036231476190458
    ],
    [
        57.6319,
        -1.7925331999999987
    ],
    [
        57.6422,
        -11.661400928571428
    ],
    [
        57.6526,
        -11.660723680952383
    ],
    [
        57.663,
        -5.083284523809524
    ],
    [
        57.6733,
        -5.631705452380952
    ],
    [
        57.6837,
        -2.3429858761904763
    ],
    [
        57.694,
        -3.4398277333333334
    ],
    [
        57.7044,
        -2.7820838142857136
    ],
    [
        57.7148,
        -0.8434110999999995
    ],
    [
        57.7251,
        -0.8437980999999994
    ],
    [
        57.7355,
        -2.693702861904762
    ],
    [
        57.7458,
        -6.8068598285714295
    ],
    [
        57.7562,
        -2.695960357142858
    ],
    [
        57.7666,
        1.6231930857142873
    ],
    [
        57.7769,
        3.5618658000000014
    ],
    [
        57.7873,
        4.502402961904762
    ],
    [
        57.7977,
        2.035863280952382
    ],
    [
        57.808,
        -2.897216085714285
    ],
    [
        57.8184,
        1.2145863809523807
    ],
    [
        57.8287,
        0.6664664523809523
    ],
    [
        57.8391,
        1.0768791523809516
    ],
    [
        57.8495,
        -0.5688351333333335
    ],
    [
        57.8598,
        -0.5688351333333325
    ],
    [
        57.8702,
        -4.270676404761905
    ],
    [
        57.8806,
        -0.9792478333333334
    ],
    [
        57.8909,
        -1.6647739952380958
    ],
    [
        57.9013,
        2.4461254761904767
    ],
    [
        57.9116,
        1.6248485809523807
    ],
    [
        57.922,
        -0.8416911047619045
    ],
    [
        57.9324,
        -2.7803638190476194
    ],
    [
        57.9427,
        -1.5819432571428569
    ],
    [
        57.9531,
        -1.7874882333333326
    ],
    [
        57.9634,
        -0.14357994761904772
    ],
    [
        57.9738,
        -1.376172538095237
    ],
    [
        57.9842,
        -1.5817175142857127
    ],
    [
        57.9945,
        -3.4322995238095237
    ],
    [
        58.0049,
        -0.2596797190476181
    ],
    [
        58.0153,
        -0.2596797190476181
    ],
    [
        58.0256,
        2.2068599619047626
    ],
    [
        58.036,
        -1.2462955904761899
    ],
    [
        58.0463,
        -5.633964023809526
    ],
    [
        58.0567,
        -2.345244447619047
    ],
    [
        58.0671,
        1.3559195761904763
    ],
    [
        58.0774,
        5.0557291
    ],
    [
        58.0878,
        5.878360495238097
    ],
    [
        58.0981,
        5.8756515
    ],
    [
        58.1085,
        5.8756515
    ],
    [
        58.1189,
        4.642381661904762
    ],
    [
        58.1292,
        12.044709704761905
    ],
    [
        58.1396,
        12.249916057142858
    ],
    [
        58.15,
        11.839503357142856
    ],
    [
        58.1603,
        12.990555209523807
    ],
    [
        58.1707,
        12.167472319047617
    ],
    [
        58.181,
        10.40565826190476
    ],
    [
        58.1914,
        7.938441328571427
    ],
    [
        58.2018,
        7.774005352380952
    ],
    [
        58.2121,
        7.156693180952379
    ],
    [
        58.2225,
        6.333158790476191
    ],
    [
        58.2328,
        5.099888947619048
    ],
    [
        58.2432,
        3.866619109523809
    ],
    [
        58.2536,
        4.304814052380953
    ],
    [
        58.2639,
        9.24060241428571
    ],
    [
        58.2743,
        6.77541722857143
    ],
    [
        58.2847,
        5.5421473857142844
    ],
    [
        58.295,
        5.788801357142859
    ],
    [
        58.3054,
        2.5009847761904767
    ],
    [
        58.3157,
        -2.4320945904761904
    ],
    [
        58.3261,
        3.7376408619047603
    ],
    [
        58.3365,
        4.9722652
    ],
    [
        58.3468,
        -0.785703057142857
    ],
    [
        58.3572,
        -0.16872951428571373
    ],
    [
        58.3675,
        3.1208930619047623
    ],
    [
        58.3779,
        0.6543533761904766
    ],
    [
        58.3883,
        1.066346319047619
    ],
    [
        58.3986,
        0.3616206952380954
    ],
    [
        58.409,
        1.5948905380952385
    ],
    [
        58.4194,
        7.5145857761904775
    ],
    [
        58.4297,
        7.515263023809524
    ],
    [
        58.4401,
        5.870903233333334
    ],
    [
        58.4504,
        4.636278895238095
    ],
    [
        58.4608,
        0.9364693714285725
    ],
    [
        58.4712,
        1.484890300000001
    ],
    [
        58.4815,
        1.484890300000001
    ],
    [
        58.4919,
        0.2502659619047617
    ],
    [
        58.5023,
        -0.45445966190476217
    ],
    [
        58.5126,
        0.5321562095238095
    ],
    [
        58.523,
        7.109595366666667
    ],
    [
        58.5333,
        6.404482742857144
    ],
    [
        58.5437,
        3.9352340619047625
    ],
    [
        58.5541,
        -3.4684484809523815
    ],
    [
        58.5644,
        -3.8802156761904776
    ],
    [
        58.5748,
        -1.4136759952380966
    ],
    [
        58.5851,
        -4.703298571428571
    ],
    [
        58.5955,
        1.0519606904761896
    ],
    [
        58.6059,
        1.051283442857142
    ],
    [
        58.6162,
        -0.5930763476190483
    ],
    [
        58.6266,
        1.8748178333333332
    ],
    [
        58.637,
        -4.291531376190477
    ],
    [
        58.6473,
        -0.5917218523809537
    ],
    [
        58.6577,
        1.8761723285714276
    ],
    [
        58.668,
        1.8775268285714288
    ],
    [
        58.6784,
        -0.5917218523809525
    ],
    [
        58.6888,
        -5.524801219047619
    ],
    [
        58.6991,
        -5.524801219047619
    ],
    [
        58.7095,
        -8.402430852380952
    ],
    [
        58.7198,
        -7.6977052285714285
    ],
    [
        58.7302,
        -8.520336623809524
    ],
    [
        58.7406,
        -9.459970790476191
    ],
    [
        58.7509,
        -9.98812800952381
    ],
    [
        58.7613,
        -13.688614780952381
    ],
    [
        58.7717,
        -7.765668747619049
    ],
    [
        58.782,
        -7.236447280952381
    ],
    [
        58.7924,
        -9.702986961904763
    ],
    [
        58.8027,
        -9.292574261904765
    ],
    [
        58.8131,
        -15.87001341904762
    ],
    [
        58.8235,
        -16.281329114285715
    ],
    [
        58.8338,
        -13.81478942857143
    ],
    [
        58.8442,
        -17.5166307
    ],
    [
        58.8545,
        -17.516630699999997
    ],
    [
        58.8649,
        -19.278444757142854
    ],
    [
        58.8753,
        -23.390698728571422
    ],
    [
        58.8856,
        -25.859947409523805
    ],
    [
        58.896,
        -20.924159047619042
    ],
    [
        58.9064,
        -17.635439471428572
    ],
    [
        58.9167,
        -20.103333652380954
    ],
    [
        58.9271,
        -20.103333652380954
    ],
    [
        58.9374,
        -19.69201795714286
    ],
    [
        58.9478,
        -20.513746352380952
    ],
    [
        58.9582,
        -21.766892904761903
    ],
    [
        58.9685,
        -25.466702428571427
    ],
    [
        58.9789,
        -24.232755338095238
    ],
    [
        58.9893,
        -25.768333938095235
    ],
    [
        58.9996,
        -24.515187385714285
    ],
    [
        59.01,
        -29.44826675238095
    ],
    [
        59.0203,
        -29.858679452380958
    ],
    [
        59.0307,
        -29.03649955714286
    ],
    [
        59.0411,
        -26.842815842857153
    ],
    [
        59.0514,
        -28.213115666666674
    ],
    [
        59.0618,
        -29.44570825714286
    ],
    [
        59.0721,
        -30.680332595238095
    ],
    [
        59.0825,
        -30.39790054761905
    ],
    [
        59.0929,
        -23.82046139047619
    ],
    [
        59.1032,
        -19.871288900000003
    ],
    [
        59.1136,
        -20.6934687952381
    ],
    [
        59.124,
        -19.04910900476191
    ],
    [
        59.1343,
        -17.567830695238097
    ],
    [
        59.1447,
        -15.923470904761908
    ],
    [
        59.155,
        -15.10151675714286
    ],
    [
        59.1654,
        -12.22320987619048
    ],
    [
        59.1758,
        -12.908058790476193
    ],
    [
        59.1861,
        -3.041900057142855
    ],
    [
        59.1965,
        -3.7470126809523783
    ],
    [
        59.2068,
        -3.19859175238095
    ],
    [
        59.2172,
        -5.392275466666664
    ],
    [
        59.2276,
        -0.4591960999999991
    ],
    [
        59.2379,
        2.8304264761904765
    ],
    [
        59.2483,
        2.6659905
    ],
    [
        59.2587,
        1.4128439476190473
    ],
    [
        59.269,
        0.31660408571428583
    ],
    [
        59.2794,
        3.2767226047619045
    ],
    [
        59.2897,
        4.7580009142857165
    ],
    [
        59.3001,
        3.0661176190476205
    ],
    [
        59.3105,
        -1.1622361238095227
    ],
    [
        59.3208,
        -1.1627779238095233
    ],
    [
        59.3312,
        -1.327213899999999
    ],
    [
        59.3416,
        -0.5045825047619036
    ],
    [
        59.3519,
        -0.7519137238095228
    ],
    [
        59.3623,
        -1.9851835666666657
    ],
    [
        59.3726,
        -5.6849930904761905
    ],
    [
        59.383,
        -6.370820252380954
    ],
    [
        59.3934,
        -6.782813195238095
    ],
    [
        59.4037,
        -13.36025235238095
    ],
    [
        59.4141,
        -7.722060361904761
    ],
    [
        59.4244,
        -8.170522123809523
    ],
    [
        59.4348,
        -4.879093552380952
    ],
    [
        59.4452,
        -3.2347337619047614
    ],
    [
        59.4555,
        -5.4281164761904765
    ],
    [
        59.4659,
        -9.377288966666667
    ],
    [
        59.4763,
        -3.7367749809523825
    ],
    [
        59.4866,
        -1.1057993190476185
    ],
    [
        59.497,
        -2.2160130761904773
    ],
    [
        59.5073,
        -1.2293972047619033
    ],
    [
        59.5177,
        -2.9912112619047613
    ],
    [
        59.5281,
        -5.340296676190475
    ],
    [
        59.5384,
        0.579940361904763
    ],
    [
        59.5488,
        2.800503323809525
    ],
    [
        59.5591,
        -0.4886677523809519
    ],
    [
        59.5695,
        -0.48799050476190314
    ],
    [
        59.5799,
        0.04016671428571487
    ],
    [
        59.5902,
        3.493322266666668
    ],
    [
        59.6006,
        7.878281704761907
    ],
    [
        59.611,
        8.2895974
    ],
    [
        59.6213,
        5.000877823809525
    ],
    [
        59.6317,
        -4.867989904761905
    ],
    [
        59.642,
        -5.652797985714288
    ],
    [
        59.6524,
        -6.3118061
    ],
    [
        59.6628,
        -8.661278514285714
    ],
    [
        59.6731,
        -7.126542714285715
    ],
    [
        59.6835,
        -2.895479976190476
    ],
    [
        59.6938,
        -7.214633419047619
    ],
    [
        59.7042,
        -8.74967021904762
    ],
    [
        59.7146,
        -5.666495619047619
    ],
    [
        59.7249,
        -3.1986014380952383
    ],
    [
        59.7353,
        -0.7320617571428575
    ],
    [
        59.7457,
        -0.321649057142857
    ],
    [
        59.756,
        -5.871363342857143
    ],
    [
        59.7664,
        -6.612002495238096
    ],
    [
        59.7767,
        -5.379409904761906
    ],
    [
        59.7871,
        -5.790499852380954
    ],
    [
        59.7975,
        -5.633808157142859
    ],
    [
        59.8078,
        -5.915698404761907
    ],
    [
        59.8182,
        -11.396897700000002
    ],
    [
        59.8286,
        -9.340319214285714
    ],
    [
        59.8389,
        -9.340319214285715
    ],
    [
        59.8493,
        -5.0211657714285725
    ],
    [
        59.8596,
        -4.774511800000001
    ],
    [
        59.87,
        -4.774511800000001
    ],
    [
        59.8804,
        -9.005187538095239
    ],
    [
        59.8907,
        -14.924882776190477
    ],
    [
        59.9011,
        -15.454104242857143
    ],
    [
        59.9114,
        -18.537278842857145
    ],
    [
        59.9218,
        -17.001700242857144
    ],
    [
        59.9322,
        -21.934779604761907
    ],
    [
        59.9425,
        -25.10739940952381
    ],
    [
        59.9529,
        -24.40228678571429
    ],
    [
        59.9632,
        -24.40160953809524
    ],
    [
        59.9736,
        -23.78497461904762
    ],
    [
        59.984,
        -19.342494195238096
    ],
    [
        59.9943,
        -19.095162976190476
    ],
    [
        60.0047,
        -12.515014823809523
    ],
    [
        60.0151,
        -16.899673261904763
    ],
    [
        60.0254,
        -12.671319519047618
    ],
    [
        60.0358,
        -13.35714668095238
    ],
    [
        60.0461,
        -14.179778076190477
    ],
    [
        60.0565,
        -4.313619342857144
    ],
    [
        60.0669,
        -1.2290902428571444
    ],
    [
        60.0772,
        -0.7905943000000013
    ],
    [
        60.0876,
        -3.4221117619047625
    ],
    [
        60.098,
        2.1306501428571427
    ],
    [
        60.1083,
        6.570150671428572
    ],
    [
        60.1187,
        8.508823385714287
    ],
    [
        60.129,
        10.270637442857147
    ],
    [
        60.1394,
        14.219268133333335
    ],
    [
        60.1498,
        19.153701995238098
    ],
    [
        60.1601,
        17.391887938095245
    ],
    [
        60.1705,
        17.67339118571429
    ],
    [
        60.1808,
        16.440121347619055
    ],
    [
        60.1912,
        15.735008723809528
    ],
    [
        60.2016,
        10.799220361904766
    ],
    [
        60.2119,
        14.252375914285718
    ],
    [
        60.2223,
        8.082640461904763
    ],
    [
        60.2327,
        13.015719828571434
    ],
    [
        60.243,
        13.01571982857143
    ],
    [
        60.2534,
        11.783127238095242
    ],
    [
        60.2637,
        10.960495842857146
    ],
    [
        60.2741,
        1.0916281142857163
    ],
    [
        60.2845,
        1.090273614285715
    ],
    [
        60.2948,
        -3.294685823809524
    ],
    [
        60.3052,
        0.8175681476190497
    ],
    [
        60.3156,
        -4.73248476190476
    ],
    [
        60.3259,
        -4.787326852380953
    ],
    [
        60.3363,
        -5.492052476190474
    ],
    [
        60.3466,
        -7.255221033333333
    ],
    [
        60.357,
        -7.2579300285714305
    ],
    [
        60.3674,
        -10.548004104761905
    ],
    [
        60.3777,
        -7.094848552380953
    ],
    [
        60.3881,
        -8.786577047619048
    ],
    [
        60.3984,
        -5.703402447619047
    ],
    [
        60.4088,
        -5.8209212190476185
    ],
    [
        60.4192,
        -5.8209212190476185
    ],
    [
        60.4295,
        -8.287460900000001
    ],
    [
        60.4399,
        -11.986593176190476
    ],
    [
        60.4503,
        -12.479901114285713
    ],
    [
        60.4606,
        -16.17903339047619
    ],
    [
        60.471,
        -8.779414342857143
    ],
    [
        60.4813,
        -12.889862314285713
    ],
    [
        60.4917,
        -6.72080410952381
    ],
    [
        60.5021,
        -9.636051823809524
    ],
    [
        60.5124,
        0.23010690952380924
    ],
    [
        60.5228,
        -0.5934274809523812
    ],
    [
        60.5332,
        3.517020490476191
    ],
    [
        60.5435,
        5.710704204761904
    ],
    [
        60.5539,
        5.162283276190475
    ],
    [
        60.5642,
        8.8634473
    ],
    [
        60.5746,
        4.423946771428571
    ],
    [
        60.585,
        4.797664904761905
    ],
    [
        60.5953,
        2.1666892428571414
    ],
    [
        60.6057,
        0.4052621857142856
    ],
    [
        60.616,
        -2.6779124142857147
    ],
    [
        60.6264,
        -3.5005438047619046
    ],
    [
        60.6368,
        -2.5139279333333326
    ],
    [
        60.6471,
        -3.1305628523809523
    ],
    [
        60.6575,
        -3.133271847619047
    ],
    [
        60.6679,
        -2.0233289904761875
    ],
    [
        60.6782,
        -4.491223171428571
    ],
    [
        60.6886,
        -8.602122642857143
    ],
    [
        60.6989,
        -4.048719304761904
    ],
    [
        60.7093,
        -4.295373276190474
    ],
    [
        60.7197,
        -4.295127004761904
    ],
    [
        60.73,
        -14.161285738095238
    ],
    [
        60.7404,
        -14.160382742857145
    ],
    [
        60.7507,
        -8.404671980952381
    ],
    [
        60.7611,
        -11.695197552380957
    ],
    [
        60.7715,
        -6.213697257142859
    ],
    [
        60.7818,
        -7.4469670999999975
    ],
    [
        60.7922,
        -9.173815780952378
    ],
    [
        60.8026,
        -7.080091233333333
    ],
    [
        60.8129,
        -7.080091233333335
    ],
    [
        60.8233,
        -4.6135515523809545
    ],
    [
        60.8336,
        -2.147011871428573
    ],
    [
        60.844,
        1.9647905952380942
    ],
    [
        60.8544,
        -0.6670880619047628
    ],
    [
        60.8647,
        -4.983532509523809
    ],
    [
        60.8751,
        -1.6921039380952392
    ],
    [
        60.8854,
        2.627049504761904
    ],
    [
        60.8958,
        8.794075961904761
    ],
    [
        60.9062,
        6.326633280952381
    ],
    [
        60.9165,
        6.706309309523809
    ],
    [
        60.9269,
        3.253153757142857
    ],
    [
        60.9373,
        1.2337213380952394
    ],
    [
        60.9476,
        7.153416576190476
    ],
    [
        60.958,
        5.059445757142856
    ],
    [
        60.9683,
        0.12636639047619158
    ],
    [
        60.9787,
        3.416891961904762
    ],
    [
        60.9891,
        -2.5028032761904755
    ],
    [
        60.9994,
        -4.970697457142857
    ],
    [
        61.0098,
        -8.67050698095238
    ],
    [
        61.0201,
        -9.329153895238095
    ],
    [
        61.0305,
        -8.301767647619048
    ],
    [
        61.0409,
        -3.36868828095238
    ],
    [
        61.0512,
        -8.304476642857143
    ],
    [
        61.0616,
        -11.757632195238097
    ],
    [
        61.072,
        -7.64447522857143
    ],
    [
        61.0823,
        -4.355755652380953
    ],
    [
        61.0927,
        -7.644475228571428
    ],
    [
        61.103,
        -11.757632195238097
    ],
    [
        61.1134,
        -17.30734648095238
    ],
    [
        61.1238,
        -14.428813852380953
    ],
    [
        61.1341,
        -15.25144524761905
    ],
    [
        61.1445,
        -17.717984933333334
    ],
    [
        61.1549,
        -13.605730961904763
    ],
    [
        61.1652,
        -15.140767761904764
    ],
    [
        61.1756,
        -13.70544385714286
    ],
    [
        61.1859,
        -12.718827985714288
    ],
    [
        61.1963,
        -14.9122107
    ],
    [
        61.2067,
        -14.748226223809528
    ],
    [
        61.217,
        -14.74958071904762
    ],
    [
        61.2274,
        -8.17214156190476
    ],
    [
        61.2377,
        -7.925487590476189
    ],
    [
        61.2481,
        -8.54246113809524
    ],
    [
        61.2585,
        -18.408619871428574
    ],
    [
        61.2688,
        -13.47283150952381
    ],
    [
        61.2792,
        -14.13057542857143
    ],
    [
        61.2896,
        -15.36452251904762
    ],
    [
        61.2999,
        -14.953432571428571
    ],
    [
        61.3103,
        -11.253623047619048
    ],
    [
        61.3206,
        -7.143175076190475
    ],
    [
        61.331,
        -1.590751795238094
    ],
    [
        61.3414,
        -4.057968728571428
    ],
    [
        61.3517,
        -4.468607176190476
    ],
    [
        61.3621,
        -4.471316171428572
    ],
    [
        61.3725,
        -1.1825965952380941
    ],
    [
        61.3828,
        -1.4563555619047612
    ],
    [
        61.3932,
        -2.442971433333332
    ],
    [
        61.4035,
        1.5062010571428588
    ],
    [
        61.4139,
        1.349595361904763
    ],
    [
        61.4243,
        3.4054966000000024
    ],
    [
        61.4346,
        4.229482490476191
    ],
    [
        61.445,
        0.1172285190476203
    ],
    [
        61.4553,
        1.3504983571428573
    ],
    [
        61.4657,
        1.8797198238095254
    ],
    [
        61.4761,
        7.799956861904762
    ],
    [
        61.4864,
        2.864168499999999
    ],
    [
        61.4968,
        -0.42455107619047505
    ],
    [
        61.5072,
        0.8080415142857134
    ],
    [
        61.5175,
        0.3969515666666676
    ],
    [
        61.5279,
        0.8075900142857144
    ],
    [
        61.5382,
        -2.481129561904762
    ],
    [
        61.5486,
        -2.4838385571428576
    ],
    [
        61.559,
        -2.483838557142858
    ],
    [
        61.5693,
        -6.1836480809523815
    ],
    [
        61.5797,
        3.6852196476190477
    ],
    [
        61.5901,
        -3.7143994
    ],
    [
        61.6004,
        -1.246956719047618
    ],
    [
        61.6108,
        -2.2341143904761895
    ],
    [
        61.6211,
        -12.102982119047619
    ],
    [
        61.6315,
        -9.753896704761905
    ],
    [
        61.6419,
        -10.987166547619049
    ],
    [
        61.6522,
        -9.721371404761905
    ],
    [
        61.6626,
        -6.266319552380954
    ],
    [
        61.6729,
        -3.7997798666666673
    ],
    [
        61.6833,
        -3.712027785714285
    ],
    [
        61.6937,
        0.2338939095238092
    ],
    [
        61.704,
        10.102761638095238
    ],
    [
        61.7144,
        14.048683333333335
    ],
    [
        61.7247,
        12.815413495238094
    ],
    [
        61.7351,
        11.993233604761905
    ],
    [
        61.7455,
        17.75165336190476
    ],
    [
        61.7558,
        15.284662176190475
    ],
    [
        61.7662,
        11.9959426
    ],
    [
        61.7766,
        10.763350009523812
    ],
    [
        61.7869,
        14.463159533333334
    ],
    [
        61.7973,
        4.594291804761906
    ],
    [
        61.8076,
        6.238651590476188
    ],
    [
        61.818,
        5.415568700000001
    ],
    [
        61.8284,
        11.335805738095237
    ],
    [
        61.8387,
        15.034938014285714
    ],
    [
        61.8491,
        8.457498857142857
    ],
    [
        61.8595,
        10.102761642857145
    ],
    [
        61.8698,
        11.186954909523811
    ],
    [
        61.8802,
        9.705947504761905
    ],
    [
        61.8905,
        4.224748209523808
    ],
    [
        61.9009,
        6.074991595238095
    ],
    [
        61.9113,
        0.31973233333333423
    ],
    [
        61.9216,
        -3.379399942857142
    ],
    [
        61.932,
        -7.325321638095238
    ],
    [
        61.9423,
        -10.202951271428573
    ],
    [
        61.9527,
        -7.73641159047619
    ],
    [
        61.9631,
        -11.027840161904763
    ],
    [
        61.9734,
        -11.43847860952381
    ],
    [
        61.9838,
        -11.437575614285711
    ],
    [
        61.9942,
        -8.971035933333335
    ],
    [
        62.0045,
        -9.383028876190478
    ],
    [
        62.0149,
        -5.063875433333335
    ],
    [
        62.0252,
        -4.946808161904762
    ],
    [
        62.0356,
        -5.320526295238096
    ],
    [
        62.046,
        -11.897965452380955
    ],
    [
        62.0563,
        -11.897965452380953
    ],
    [
        62.0667,
        -2.0318067190476192
    ],
    [
        62.077,
        1.2587188571428567
    ],
    [
        62.0874,
        -4.379086133333334
    ],
    [
        62.0978,
        -2.2399736142857143
    ],
    [
        62.1081,
        -2.9251235285714294
    ],
    [
        62.1185,
        -6.624933052380953
    ],
    [
        62.1289,
        -6.789910828571429
    ],
    [
        62.1392,
        -6.790588076190476
    ],
    [
        62.1496,
        -6.7932970714285705
    ],
    [
        62.1599,
        -6.793297071428572
    ],
    [
        62.1703,
        -1.8575087095238099
    ],
    [
        62.1807,
        -4.735138342857142
    ],
    [
        62.191,
        -1.0353288190476202
    ],
    [
        62.2014,
        -5.148485785714285
    ],
    [
        62.2117,
        -7.615025466666666
    ],
    [
        62.2221,
        -3.503223000000002
    ],
    [
        62.2325,
        -4.119857923809524
    ],
    [
        62.2428,
        -4.648692390476192
    ],
    [
        62.2532,
        -3.4527943619047625
    ],
    [
        62.2636,
        -2.3565545
    ],
    [
        62.2739,
        0.11133968095238107
    ],
    [
        62.2843,
        -2.353845504761906
    ],
    [
        62.2946,
        -8.522903709523812
    ],
    [
        62.305,
        -3.9692919857142877
    ],
    [
        62.3154,
        -5.120975933333332
    ],
    [
        62.3257,
        -3.8877060904761915
    ],
    [
        62.3361,
        -6.354245776190477
    ],
    [
        62.3465,
        -10.300167471428573
    ],
    [
        62.3568,
        -12.35606870952381
    ],
    [
        62.3672,
        -6.4336644761904775
    ],
    [
        62.3775,
        0.14377468095238063
    ],
    [
        62.3879,
        -9.72509304761905
    ],
    [
        62.3983,
        -9.196935828571428
    ],
    [
        62.4086,
        -11.66347550952381
    ],
    [
        62.419,
        -7.551221538095239
    ],
    [
        62.4293,
        -5.789407480952383
    ],
    [
        62.4397,
        -13.191735523809525
    ],
    [
        62.4501,
        -10.314105890476194
    ],
    [
        62.4604,
        -9.902338695238095
    ],
    [
        62.4708,
        -16.479777852380955
    ],
    [
        62.4812,
        -17.165605014285717
    ],
    [
        62.4915,
        -19.386167976190478
    ],
    [
        62.5019,
        -20.620792314285715
    ],
    [
        62.5122,
        -24.320601838095243
    ],
    [
        62.5226,
        -28.876922557142866
    ],
    [
        62.533,
        -27.72614160476191
    ],
    [
        62.5433,
        -32.664638957142856
    ],
    [
        62.5537,
        -32.664638957142856
    ],
    [
        62.564,
        -32.66734795238095
    ],
    [
        62.5744,
        -31.022536661904763
    ],
    [
        62.5848,
        -32.62578745238096
    ],
    [
        62.5951,
        -40.02540650000001
    ],
    [
        62.6055,
        -35.09232713333334
    ],
    [
        62.6158,
        -34.387214509523815
    ],
    [
        62.6262,
        -39.32029387619048
    ],
    [
        62.6366,
        -41.78728506190476
    ],
    [
        62.6469,
        -40.260379542857144
    ],
    [
        62.6573,
        -30.391511814285714
    ],
    [
        62.6677,
        -32.858503000000006
    ],
    [
        62.678,
        -30.391060319047625
    ],
    [
        62.6884,
        -27.924520633333334
    ],
    [
        62.6987,
        -25.046213752380947
    ],
    [
        62.7091,
        -24.508059642857148
    ],
    [
        62.7195,
        -25.74132948571428
    ],
    [
        62.7298,
        -20.808250119047614
    ],
    [
        62.7402,
        -16.57718738095238
    ],
    [
        62.7506,
        -17.2349313
    ],
    [
        62.7609,
        -13.001933571428573
    ],
    [
        62.7713,
        -13.001933571428573
    ],
    [
        62.7816,
        -7.243965314285716
    ],
    [
        62.792,
        -6.832875366666666
    ],
    [
        62.8024,
        -6.664948480952383
    ],
    [
        62.8127,
        -5.185566471428571
    ],
    [
        62.8231,
        -6.172182342857143
    ],
    [
        62.8334,
        -3.7056426619047627
    ],
    [
        62.8438,
        2.872699490476189
    ],
    [
        62.8542,
        3.6953308857142853
    ],
    [
        62.8645,
        2.87315099047619
    ],
    [
        62.8749,
        -3.7042881666666667
    ],
    [
        62.8853,
        -1.647709680952381
    ],
    [
        62.8956,
        -4.115152361904761
    ],
    [
        62.906,
        -6.581692047619049
    ],
    [
        62.9163,
        -3.290263476190475
    ],
    [
        62.9267,
        -5.308341395238095
    ],
    [
        62.9371,
        -6.953604180952381
    ],
    [
        62.9474,
        -5.720334338095238
    ],
    [
        62.9578,
        -6.249555804761904
    ],
    [
        62.9681,
        -4.769631995238096
    ],
    [
        62.9785,
        0.8689469952380937
    ],
    [
        62.9889,
        4.979394966666668
    ],
    [
        62.9992,
        6.6251092523809545
    ],
    [
        63.0096,
        4.158569566666668
    ],
    [
        63.02,
        -0.32580176666666494
    ],
    [
        63.0303,
        0.11323597619047765
    ],
    [
        63.0407,
        2.33379893809524
    ],
    [
        63.051,
        1.5116190476190496
    ],
    [
        63.0614,
        -0.6820646666666668
    ],
    [
        63.0718,
        0.9622951238095235
    ],
    [
        63.0821,
        -1.5055990571428577
    ],
    [
        63.0925,
        5.071840100000001
    ],
    [
        63.1029,
        8.770972376190477
    ],
    [
        63.1132,
        14.528940633333335
    ],
    [
        63.1236,
        19.462020000000003
    ],
    [
        63.1339,
        14.14655560952381
    ],
    [
        63.1443,
        17.06155705238095
    ],
    [
        63.1547,
        17.884639942857138
    ],
    [
        63.165,
        15.417423009523807
    ],
    [
        63.1754,
        18.29572989047619
    ],
    [
        63.1857,
        15.007010314285713
    ],
    [
        63.1961,
        10.073930947619047
    ],
    [
        63.2065,
        9.251299557142856
    ],
    [
        63.2168,
        7.605585271428572
    ],
    [
        63.2272,
        7.604908023809522
    ],
    [
        63.2376,
        7.604908023809522
    ],
    [
        63.2479,
        7.448302328571427
    ],
    [
        63.2583,
        7.448302328571427
    ],
    [
        63.2686,
        10.737021904761903
    ],
    [
        63.279,
        12.519389923809522
    ],
    [
        63.2894,
        10.426568371428573
    ],
    [
        63.2997,
        14.538822342857141
    ],
    [
        63.3101,
        8.619127104761905
    ],
    [
        63.3205,
        3.686047738095238
    ],
    [
        63.3308,
        -6.18281999047619
    ],
    [
        63.3412,
        -4.9488729
    ],
    [
        63.3515,
        -6.213556661904763
    ],
    [
        63.3619,
        -6.662018423809524
    ],
    [
        63.3723,
        -6.826454399999999
    ],
    [
        63.3826,
        -6.579123180952381
    ],
    [
        63.393,
        -5.756943290476191
    ],
    [
        63.4033,
        -4.934763395238096
    ],
    [
        63.4137,
        -3.7014935523809527
    ],
    [
        63.4241,
        -2.5049388000000015
    ],
    [
        63.4344,
        -1.6818559095238104
    ],
    [
        63.4448,
        -1.434524690476191
    ],
    [
        63.4552,
        -1.4345246904761924
    ],
    [
        63.4655,
        -0.7297990666666666
    ],
    [
        63.4759,
        -0.976588485714287
    ],
    [
        63.4862,
        -6.289135495238097
    ],
    [
        63.4966,
        -6.906447666666667
    ],
    [
        63.507,
        -5.7528732809523815
    ],
    [
        63.5173,
        -8.101958695238096
    ],
    [
        63.5277,
        -9.581882504761905
    ],
    [
        63.5381,
        -11.226242295238094
    ],
    [
        63.5484,
        -6.293162928571428
    ],
    [
        63.5588,
        -12.460189385714287
    ],
    [
        63.5691,
        -9.582559752380952
    ],
    [
        63.5795,
        -9.582559752380952
    ],
    [
        63.5899,
        -9.144364809523811
    ],
    [
        63.6002,
        -13.090828304761907
    ],
    [
        63.6106,
        -10.621579623809525
    ],
    [
        63.6209,
        -13.088119304761907
    ],
    [
        63.6313,
        -19.257177509523807
    ],
    [
        63.6417,
        -18.808469476190474
    ],
    [
        63.652,
        -17.98583808095238
    ],
    [
        63.6624,
        -18.643581999999995
    ],
    [
        63.6727,
        -14.944449723809523
    ],
    [
        63.6831,
        -14.944449723809523
    ],
    [
        63.6935,
        -10.998528028571425
    ],
    [
        63.7038,
        -12.263420176190472
    ],
    [
        63.7142,
        -13.702686490476188
    ],
    [
        63.7246,
        -16.0521589047619
    ],
    [
        63.7349,
        -15.347433280952377
    ],
    [
        63.7453,
        -11.89427772857143
    ],
    [
        63.7556,
        -11.48386502857143
    ],
    [
        63.766,
        -12.10049994761905
    ],
    [
        63.7764,
        -6.18080470952381
    ],
    [
        63.7867,
        -8.119187176190476
    ],
    [
        63.7971,
        -8.119187176190476
    ],
    [
        63.8074,
        -5.104226566666667
    ],
    [
        63.8178,
        2.2953924809523816
    ],
    [
        63.8282,
        -3.874342971428573
    ],
    [
        63.8385,
        -3.8743429714285713
    ],
    [
        63.8489,
        -0.1718244523809523
    ],
    [
        63.8593,
        0.8147914190476191
    ],
    [
        63.8696,
        -3.2992685476190475
    ],
    [
        63.88,
        -2.642066428571429
    ],
    [
        63.8903,
        -0.4215034666666663
    ],
    [
        63.9007,
        -1.655450557142857
    ],
    [
        63.9111,
        -9.055069604761904
    ],
    [
        63.9214,
        -5.766350023809524
    ],
    [
        63.9318,
        -6.588078419047618
    ],
    [
        63.9421,
        -5.9303345
    ],
    [
        63.9525,
        -5.9303345
    ],
    [
        63.9629,
        -9.384844552380953
    ],
    [
        63.9732,
        -8.150897461904762
    ],
    [
        63.9836,
        -8.520878414285715
    ],
    [
        63.994,
        -7.8631344952380955
    ],
    [
        64.0043,
        -8.803284657142859
    ],
    [
        64.0147,
        -3.870205290476192
    ],
    [
        64.025,
        -1.4036656047619058
    ],
    [
        64.0354,
        -5.515468071428572
    ],
    [
        64.0458,
        -4.281520980952379
    ],
    [
        64.0561,
        -3.048251142857142
    ],
    [
        64.0665,
        -0.5817114571428571
    ],
    [
        64.0769,
        -2.116447257142857
    ],
    [
        64.0872,
        -5.406069833333334
    ],
    [
        64.0976,
        -2.7745523714285727
    ],
    [
        64.1079,
        -4.466280866666668
    ],
    [
        64.1183,
        -5.698873457142857
    ],
    [
        64.1287,
        -4.219491447619047
    ],
    [
        64.139,
        -5.3705433
    ],
    [
        64.1494,
        -3.726183509523809
    ],
    [
        64.1597,
        -3.5617475333333326
    ],
    [
        64.1701,
        -6.028287214285713
    ],
    [
        64.1805,
        -6.028287214285712
    ],
    [
        64.1908,
        -5.04167134285714
    ],
    [
        64.2012,
        -6.522949652380951
    ],
    [
        64.2116,
        -8.872035066666667
    ],
    [
        64.2219,
        -4.7602326
    ],
    [
        64.2323,
        -8.04804918095238
    ],
    [
        64.2426,
        -13.803308442857148
    ],
    [
        64.253,
        -10.51368586666667
    ],
    [
        64.2634,
        -11.74763295714286
    ],
    [
        64.2737,
        -8.870003323809525
    ],
    [
        64.2841,
        -10.925904561904767
    ],
    [
        64.2945,
        -10.377784633333336
    ],
    [
        64.3048,
        -6.429153942857146
    ],
    [
        64.3152,
        -6.429153942857146
    ],
    [
        64.3255,
        -6.711198990476191
    ],
    [
        64.3359,
        -9.177738676190476
    ],
    [
        64.3463,
        -7.4858553809523825
    ],
    [
        64.3566,
        -8.966320990476191
    ],
    [
        64.367,
        -9.788500885714287
    ],
    [
        64.3773,
        -9.953478661904764
    ],
    [
        64.3877,
        -5.842579190476192
    ],
    [
        64.3981,
        -2.758050090476191
    ],
    [
        64.4084,
        -4.449778585714286
    ],
    [
        64.4188,
        -6.91496377142857
    ],
    [
        64.4292,
        -1.27715878095238
    ],
    [
        64.4395,
        -4.360672004761906
    ],
    [
        64.4499,
        -6.005934790476191
    ],
    [
        64.4602,
        -4.197139023809524
    ],
    [
        64.4706,
        -6.546224438095239
    ],
    [
        64.481,
        -4.490097452380952
    ],
    [
        64.4913,
        -11.06753660952381
    ],
    [
        64.5017,
        -10.949630838095239
    ],
    [
        64.5121,
        -13.418879514285715
    ],
    [
        64.5224,
        -11.445105971428571
    ],
    [
        64.5328,
        -13.706439304761906
    ],
    [
        64.5431,
        -11.074018847619048
    ],
    [
        64.5535,
        -6.140939480952381
    ],
    [
        64.5639,
        -1.9125857380952394
    ],
    [
        64.5742,
        -3.3919677476190473
    ],
    [
        64.5846,
        -5.036327533333334
    ],
    [
        64.5949,
        -6.518418538095237
    ],
    [
        64.6053,
        -13.095857695238097
    ],
    [
        64.6157,
        -14.534672509523809
    ],
    [
        64.626,
        -8.896480519047621
    ],
    [
        64.6364,
        -3.415281223809522
    ],
    [
        64.6468,
        -8.60799634761905
    ],
    [
        64.6571,
        -7.286297180952384
    ],
    [
        64.6675,
        -8.273996652380955
    ],
    [
        64.6778,
        -9.260612523809527
    ],
    [
        64.6882,
        -8.555886900000003
    ],
    [
        64.6986,
        -9.378066795238098
    ],
    [
        64.7089,
        -6.089347219047617
    ],
    [
        64.7193,
        -2.9180819142857133
    ],
    [
        64.7296,
        -0.8972949999999992
    ],
    [
        64.74,
        -1.0617309761904747
    ],
    [
        64.7504,
        -1.267840323809522
    ],
    [
        64.7607,
        -2.3646821809523795
    ],
    [
        64.7711,
        -3.0694078047619033
    ],
    [
        64.7815,
        -12.935566538095243
    ],
    [
        64.7918,
        -11.7022967
    ],
    [
        64.8022,
        -8.687336090476188
    ],
    [
        64.8125,
        -6.218087414285714
    ],
    [
        64.8229,
        -1.8337299761904764
    ],
    [
        64.8333,
        -1.5596700095238096
    ],
    [
        64.8436,
        -8.959289057142858
    ],
    [
        64.854,
        -7.040869304761904
    ],
    [
        64.8644,
        -1.848154180952379
    ],
    [
        64.8747,
        -1.730699909523809
    ],
    [
        64.8851,
        -1.5651803333333332
    ],
    [
        64.8954,
        3.367899033333334
    ],
    [
        64.9058,
        -1.5705983190476183
    ],
    [
        64.9162,
        -2.55721419047619
    ],
    [
        64.9265,
        0.07376147142857176
    ],
    [
        64.9369,
        -3.2149581047619025
    ],
    [
        64.9472,
        -2.7664963428571405
    ],
    [
        64.9576,
        -5.2330360285714255
    ],
    [
        64.968,
        -9.343032504761903
    ],
    [
        64.9783,
        -4.955665071428569
    ],
    [
        64.9887,
        -5.238638919047617
    ],
    [
        64.999,
        1.3388002380952393
    ],
    [
        65.0094,
        1.7498901857142872
    ],
    [
        65.0198,
        2.4347391000000016
    ],
    [
        65.0301,
        0.7903793095238113
    ],
    [
        65.0405,
        0.5169213428571455
    ],
    [
        65.0509,
        1.0650412714285735
    ],
    [
        65.0612,
        1.8854151666666688
    ],
    [
        65.0716,
        0.40549135714285917
    ],
    [
        65.0819,
        -5.761535099999999
    ],
    [
        65.0923,
        -7.816984838095237
    ],
    [
        65.1027,
        -7.981420814285713
    ],
    [
        65.113,
        -10.447960499999997
    ],
    [
        65.1234,
        -10.442542514285716
    ],
    [
        65.1338,
        -11.100286433333334
    ],
    [
        65.1441,
        -10.852277966666668
    ],
    [
        65.1545,
        -10.409565714285714
    ],
    [
        65.1648,
        -9.587385819047618
    ],
    [
        65.1752,
        -6.2986662380952385
    ],
    [
        65.1856,
        -3.8321265523809522
    ],
    [
        65.1959,
        -13.703703276190476
    ],
    [
        65.2063,
        -13.703161476190477
    ],
    [
        65.2166,
        -16.170152661904762
    ],
    [
        65.227,
        -10.412184404761907
    ],
    [
        65.2374,
        -16.578533614285714
    ],
    [
        65.2477,
        -13.700903980952384
    ],
    [
        65.2581,
        -13.865339957142861
    ],
    [
        65.2685,
        -8.932260590476195
    ],
    [
        65.2788,
        -4.819555123809524
    ],
    [
        65.2892,
        -8.27406517619048
    ],
    [
        65.2995,
        -4.572223904761906
    ],
    [
        65.3099,
        -5.805493742857145
    ],
    [
        65.3203,
        -6.0521477142857165
    ],
    [
        65.3306,
        -8.518687395238096
    ],
    [
        65.341,
        -4.079186866666668
    ],
    [
        65.3514,
        -0.7904672857142869
    ],
    [
        65.3617,
        -3.2590387142857162
    ],
    [
        65.3721,
        -0.4130313904761903
    ],
    [
        65.3824,
        -6.170999647619048
    ],
    [
        65.3928,
        -9.459719228571428
    ],
    [
        65.4032,
        -2.0601001809523813
    ],
    [
        65.4135,
        0.4077939999999996
    ],
    [
        65.4239,
        1.3949516714285704
    ],
    [
        65.4342,
        3.2047407380952393
    ],
    [
        65.4446,
        -2.2791675523809523
    ],
    [
        65.455,
        -2.281876547619048
    ],
    [
        65.4653,
        1.4179329761904744
    ],
    [
        65.4757,
        0.7601890571428571
    ],
    [
        65.4861,
        -4.17289030952381
    ],
    [
        65.4964,
        -11.572509357142858
    ],
    [
        65.5068,
        -14.040403538095239
    ],
    [
        65.5171,
        -17.213023342857145
    ],
    [
        65.5275,
        -13.102123871428573
    ],
    [
        65.5379,
        -14.336748209523813
    ],
    [
        65.5482,
        -12.692388419047623
    ],
    [
        65.5586,
        -13.021440976190481
    ],
    [
        65.5689,
        -13.6791848952381
    ],
    [
        65.5793,
        -14.911777485714289
    ],
    [
        65.5897,
        -18.20049706190477
    ],
    [
        65.6,
        -11.619445914285714
    ],
    [
        65.6104,
        -9.975086123809525
    ],
    [
        65.6208,
        -15.456586419047623
    ],
    [
        65.6311,
        -11.343429452380954
    ],
    [
        65.6415,
        -11.343429452380954
    ],
    [
        65.6518,
        -12.33058712380953
    ],
    [
        65.6622,
        -12.769083066666672
    ],
    [
        65.6726,
        -8.53802032857143
    ],
    [
        65.6829,
        -12.854464776190483
    ],
    [
        65.6933,
        -8.742210804761905
    ],
    [
        65.7036,
        -10.38747359047619
    ],
    [
        65.714,
        -6.002514152380953
    ],
    [
        65.7244,
        1.3998138904761903
    ],
    [
        65.7347,
        4.571079195238094
    ],
    [
        65.7451,
        2.22160678095238
    ],
    [
        65.7555,
        9.622580328571429
    ],
    [
        65.7658,
        7.978220538095237
    ],
    [
        65.7762,
        7.155589147619047
    ],
    [
        65.7865,
        4.524613485714285
    ],
    [
        65.7969,
        9.457692847619048
    ],
    [
        65.8073,
        9.57514711904762
    ],
    [
        65.8176,
        9.574244123809523
    ],
    [
        65.828,
        8.8695185
    ],
    [
        65.8383,
        10.651209271428572
    ],
    [
        65.8487,
        7.359780700000003
    ],
    [
        65.8591,
        12.292860066666668
    ],
    [
        65.8694,
        14.760754247619051
    ],
    [
        65.8798,
        15.367694090476194
    ],
    [
        65.8902,
        16.424782523809526
    ],
    [
        65.9005,
        15.47927564761905
    ],
    [
        65.9109,
        17.944460833333334
    ],
    [
        65.9212,
        17.12318393809524
    ],
    [
        65.9316,
        18.49438675714286
    ],
    [
        65.942,
        15.20566718095238
    ],
    [
        65.9523,
        13.725743371428573
    ],
    [
        65.9627,
        14.430855995238097
    ],
    [
        65.9731,
        8.793051004761905
    ],
    [
        65.9834,
        3.8599716380952382
    ],
    [
        65.9938,
        3.038694742857144
    ],
    [
        66.0041,
        4.065742361904761
    ],
    [
        66.0145,
        3.2435624714285716
    ],
    [
        66.0249,
        8.884076457142859
    ],
    [
        66.0352,
        4.773176985714289
    ],
    [
        66.0456,
        4.244632766666668
    ],
    [
        66.0559,
        -1.9217164428571405
    ],
    [
        66.0663,
        -2.7420903380952373
    ],
    [
        66.0767,
        -5.208630023809522
    ],
    [
        66.087,
        -8.911148542857141
    ],
    [
        66.0974,
        -3.595684152380951
    ],
    [
        66.1078,
        -2.7147771190476186
    ],
    [
        66.1181,
        -3.0906307857142834
    ],
    [
        66.1285,
        -12.956789519047618
    ],
    [
        66.1388,
        -12.958144019047623
    ],
    [
        66.1492,
        -15.425586700000002
    ],
    [
        66.1596,
        -13.615707333333335
    ],
    [
        66.1699,
        -15.588939076190478
    ],
    [
        66.1803,
        -16.137059004761905
    ],
    [
        66.1906,
        -13.787973590476193
    ],
    [
        66.201,
        -8.361586285714283
    ],
    [
        66.2114,
        -6.30613655238095
    ],
    [
        66.2217,
        -4.866644485714284
    ],
    [
        66.2321,
        -7.059124204761905
    ],
    [
        66.2425,
        -7.056415214285713
    ],
    [
        66.2528,
        -5.823145376190477
    ],
    [
        66.2632,
        -7.056415214285713
    ],
    [
        66.2735,
        -1.3011559523809528
    ],
    [
        66.2839,
        -1.3025104523809539
    ],
    [
        66.2943,
        -5.002319976190477
    ],
    [
        66.3046,
        -2.1233358476190496
    ],
    [
        66.315,
        -8.047365480952381
    ],
    [
        66.3253,
        -4.347555957142858
    ],
    [
        66.3357,
        -1.1762906523809524
    ],
    [
        66.3461,
        1.2902490333333319
    ],
    [
        66.3564,
        2.771527342857143
    ],
    [
        66.3668,
        -0.5171922333333341
    ],
    [
        66.3772,
        -2.3270716
    ],
    [
        66.3875,
        -0.10583139047619045
    ],
    [
        66.3979,
        -0.5443273333333352
    ],
    [
        66.4082,
        -1.202071252380952
    ],
    [
        66.4186,
        -2.929326280952381
    ],
    [
        66.429,
        -4.162596123809523
    ],
    [
        66.4393,
        -4.98477601904762
    ],
    [
        66.4497,
        -4.436656090476189
    ],
    [
        66.4601,
        -11.02041622857143
    ],
    [
        66.4704,
        -4.851358023809525
    ],
    [
        66.4808,
        -7.31789770952381
    ],
    [
        66.4911,
        -9.126693476190477
    ],
    [
        66.5015,
        -8.303159085714285
    ],
    [
        66.5119,
        -9.537783423809524
    ],
    [
        66.5222,
        -13.648682895238093
    ],
    [
        66.5326,
        -10.192547442857142
    ],
    [
        66.5429,
        -17.593520990476193
    ],
    [
        66.5533,
        -15.126981304761907
    ],
    [
        66.5637,
        -17.596229985714288
    ],
    [
        66.574,
        -11.67653474761905
    ],
    [
        66.5844,
        -11.676534747619046
    ],
    [
        66.5948,
        -13.320894533333334
    ],
    [
        66.6051,
        -14.555518871428571
    ],
    [
        66.6155,
        -14.117022928571426
    ],
    [
        66.6258,
        -15.103638799999999
    ],
    [
        66.6362,
        -11.403152028571428
    ],
    [
        66.6466,
        -14.691871604761904
    ],
    [
        66.6569,
        -9.756083242857141
    ],
    [
        66.6673,
        -14.689162609523809
    ],
    [
        66.6777,
        -13.043899823809525
    ],
    [
        66.688,
        -20.446227866666668
    ],
    [
        66.6984,
        -16.335328395238097
    ],
    [
        66.7087,
        -14.114765433333336
    ],
    [
        66.7191,
        -13.703675485714287
    ],
    [
        66.7295,
        -12.058412699999998
    ],
    [
        66.7398,
        -14.524952385714286
    ],
    [
        66.7502,
        -12.0597672
    ],
    [
        66.7605,
        -7.946610233333334
    ],
    [
        66.7709,
        -7.946610233333334
    ],
    [
        66.7813,
        -4.244091714285712
    ],
    [
        66.7916,
        -10.822433866666668
    ],
    [
        66.802,
        -8.35724868095238
    ],
    [
        66.8124,
        -8.35724868095238
    ],
    [
        66.8227,
        -13.293037042857142
    ],
    [
        66.8331,
        -17.67799648095238
    ],
    [
        66.8434,
        -18.382722104761903
    ],
    [
        66.8538,
        -20.35595384761905
    ],
    [
        66.8642,
        -16.409490352380956
    ],
    [
        66.8745,
        -21.345278714285715
    ],
    [
        66.8849,
        -17.116924971428574
    ],
    [
        66.8952,
        -16.29474507619048
    ],
    [
        66.9056,
        -14.650385290476194
    ],
    [
        66.916,
        -18.76128476190476
    ],
    [
        66.9263,
        -21.639591642857145
    ],
    [
        66.9367,
        -21.64026889047619
    ],
    [
        66.9471,
        -20.817637495238095
    ],
    [
        66.9574,
        -15.3361372
    ],
    [
        66.9678,
        -21.09184796190476
    ],
    [
        66.9781,
        -23.442223371428575
    ],
    [
        66.9885,
        -26.730942947619052
    ],
    [
        66.9989,
        -26.319853000000005
    ],
    [
        67.0092,
        -23.852410319047618
    ],
    [
        67.0196,
        -22.617785980952384
    ],
    [
        67.0299,
        -21.797412085714285
    ],
    [
        67.0403,
        -17.478258642857142
    ],
    [
        67.0507,
        -17.478258642857142
    ],
    [
        67.061,
        -19.2400727
    ],
    [
        67.0714,
        -18.500110795238097
    ],
    [
        67.0818,
        -17.513494923809528
    ],
    [
        67.0921,
        -18.74676476666667
    ],
    [
        67.1025,
        -18.042039142857146
    ],
    [
        67.1128,
        -20.09884337619048
    ],
    [
        67.1232,
        -17.632303690476192
    ],
    [
        67.1336,
        -13.685840195238097
    ],
    [
        67.1439,
        -9.574940723809526
    ],
    [
        67.1543,
        -10.807533314285715
    ],
    [
        67.1646,
        -9.98535341904762
    ],
    [
        67.175,
        -11.238499971428572
    ],
    [
        67.1854,
        -15.348947942857146
    ],
    [
        67.1957,
        -14.64383531904762
    ],
    [
        67.2061,
        -17.27535278095238
    ],
    [
        67.2165,
        -21.388961247619047
    ],
    [
        67.2268,
        -27.14422050952381
    ],
    [
        67.2372,
        -20.977871299999997
    ],
    [
        67.2475,
        -20.564975357142856
    ],
    [
        67.2579,
        -17.481800757142857
    ],
    [
        67.2683,
        -7.610224038095238
    ],
    [
        67.2786,
        -6.3769542
    ],
    [
        67.289,
        -5.143684361904762
    ],
    [
        67.2994,
        -5.143684361904762
    ],
    [
        67.3097,
        -8.843493885714286
    ],
    [
        67.3201,
        -10.07676372857143
    ],
    [
        67.3304,
        -13.775218757142861
    ],
    [
        67.3408,
        -17.88747272857143
    ],
    [
        67.3512,
        -17.888014528571432
    ],
    [
        67.3615,
        -18.709291423809525
    ],
    [
        67.3719,
        -21.175831109523813
    ],
    [
        67.3822,
        -19.53147132380953
    ],
    [
        67.3926,
        -19.53147132380953
    ],
    [
        67.403,
        -17.064931638095242
    ],
    [
        67.4133,
        -18.051547509523814
    ],
    [
        67.4237,
        -15.830442747619053
    ],
    [
        67.434,
        -11.717737276190478
    ],
    [
        67.4444,
        -5.140298119047621
    ],
    [
        67.4548,
        -5.140298119047621
    ],
    [
        67.4651,
        -6.373567957142858
    ],
    [
        67.4755,
        -9.457081180952384
    ],
    [
        67.4859,
        -11.929038852380957
    ],
    [
        67.4962,
        -10.695769009523811
    ],
    [
        67.5066,
        -8.22381133809524
    ],
    [
        67.5169,
        -10.690351019047622
    ],
    [
        67.5273,
        -0.8241922857142876
    ],
    [
        67.5377,
        -0.5775383142857171
    ],
    [
        67.548,
        9.288620419047621
    ],
    [
        67.5584,
        9.289974919047618
    ],
    [
        67.5688,
        5.341344228571428
    ],
    [
        67.5791,
        5.340441233333332
    ],
    [
        67.5895,
        8.628257814285714
    ],
    [
        67.5998,
        3.6951784523809525
    ],
    [
        67.6102,
        6.867798257142859
    ],
    [
        67.6206,
        4.401258571428572
    ],
    [
        67.6309,
        4.68314881904762
    ],
    [
        67.6413,
        4.154314352380954
    ],
    [
        67.6516,
        4.155217347619049
    ],
    [
        67.662,
        3.49855702857143
    ],
    [
        67.6724,
        -3.0788821285714274
    ],
    [
        67.6827,
        -0.6123424476190468
    ],
    [
        67.6931,
        -0.6120038238095251
    ],
    [
        67.7035,
        -8.011622871428571
    ],
    [
        67.7138,
        -12.947411233333334
    ],
    [
        67.7242,
        -22.818987952380954
    ],
    [
        67.7345,
        -15.416659909523808
    ],
    [
        67.7449,
        -20.349739276190476
    ],
    [
        67.7553,
        -19.980096947619046
    ],
    [
        67.7656,
        -19.980096947619046
    ],
    [
        67.776,
        -22.446636633333338
    ],
    [
        67.7864,
        -22.446636633333334
    ],
    [
        67.7967,
        -25.324266266666672
    ],
    [
        67.8071,
        -23.67900348095238
    ],
    [
        67.8174,
        -21.212463800000002
    ],
    [
        67.8278,
        -26.14825216190476
    ],
    [
        67.8382,
        -26.14825216190476
    ],
    [
        67.8485,
        -30.37660590476191
    ],
    [
        67.8589,
        -29.84844868571429
    ],
    [
        67.8692,
        -29.437584485714286
    ],
    [
        67.8796,
        -29.19133686666667
    ],
    [
        67.89,
        -28.533592947619052
    ],
    [
        67.9003,
        -26.06569876666667
    ],
    [
        67.9107,
        -20.515984480952383
    ],
    [
        67.9211,
        -17.227264904761906
    ],
    [
        67.9314,
        -13.93673932857143
    ],
    [
        67.9418,
        -8.01704409047619
    ],
    [
        67.9521,
        -13.77501234761905
    ],
    [
        67.9625,
        -8.841932980952384
    ],
    [
        67.9729,
        -9.212658909523812
    ],
    [
        67.9832,
        -12.50137848571429
    ],
    [
        67.9936,
        -8.801568961904765
    ],
    [
        68.004,
        -5.510140390476189
    ],
    [
        68.0143,
        -5.510817638095235
    ],
    [
        68.0247,
        -3.044277957142854
    ],
    [
        68.035,
        -2.057662085714283
    ],
    [
        68.0454,
        -0.8230377476190469
    ],
    [
        68.0558,
        2.875417280952383
    ],
    [
        68.0661,
        12.741576014285712
    ],
    [
        68.0765,
        9.863946380952385
    ],
    [
        68.0868,
        9.452630680952385
    ],
    [
        68.0972,
        13.151762957142859
    ],
    [
        68.1076,
        12.49401903809524
    ],
    [
        68.1179,
        11.259394700000005
    ],
    [
        68.1283,
        6.326315333333334
    ],
    [
        68.1386,
        6.324509338095239
    ],
    [
        68.149,
        6.325412333333335
    ],
    [
        68.1594,
        3.6935336761904782
    ],
    [
        68.1697,
        9.448792938095242
    ],
    [
        68.1801,
        3.6935336761904773
    ],
    [
        68.1905,
        3.036873357142859
    ],
    [
        68.2008,
        2.214693461904764
    ],
    [
        68.2112,
        3.4479633047619065
    ],
    [
        68.2215,
        4.270143200000002
    ],
    [
        68.2319,
        6.32627018571429
    ],
    [
        68.2423,
        1.393190823809526
    ],
    [
        68.2526,
        2.8731146333333353
    ],
    [
        68.263,
        3.2842045809523834
    ],
    [
        68.2734,
        2.873566133333337
    ],
    [
        68.2837,
        -2.0595132333333317
    ],
    [
        68.2941,
        -2.0604162285714267
    ],
    [
        68.3044,
        -2.471731923809521
    ],
    [
        68.3148,
        -2.4690229285714276
    ],
    [
        68.3252,
        -1.8112790095238078
    ],
    [
        68.3355,
        -2.2223689571428573
    ],
    [
        68.3459,
        -2.2223689571428573
    ],
    [
        68.3562,
        -1.5628190428571416
    ],
    [
        68.3666,
        -0.3669210142857123
    ],
    [
        68.377,
        6.216839123809525
    ],
    [
        68.3873,
        1.9003946761904746
    ],
    [
        68.3977,
        1.4893047285714285
    ],
    [
        68.4081,
        -1.7994148476190475
    ],
    [
        68.4184,
        -0.1537005619047613
    ],
    [
        68.4288,
        -2.6202402428571423
    ],
    [
        68.4391,
        3.1350190190476197
    ],
    [
        68.4495,
        1.0782147857142867
    ],
    [
        68.4599,
        1.9003946761904766
    ],
    [
        68.4702,
        -5.4992243714285705
    ],
    [
        68.4806,
        -9.610123842857142
    ],
    [
        68.491,
        -9.198130899999999
    ],
    [
        68.5013,
        -8.375951004761905
    ],
    [
        68.5117,
        -9.1972279
    ],
    [
        68.522,
        -12.899069171428572
    ],
    [
        68.5324,
        -18.657940423809524
    ],
    [
        68.5428,
        -17.671324552380952
    ],
    [
        68.5531,
        -23.426583814285713
    ],
    [
        68.5635,
        -23.426583814285713
    ],
    [
        68.5738,
        -24.084327733333332
    ],
    [
        68.5842,
        -23.63586597142857
    ],
    [
        68.5946,
        -26.10782364285714
    ],
    [
        68.6049,
        -27.711074433333334
    ],
    [
        68.6153,
        -26.47780459047619
    ],
    [
        68.6256,
        -22.777995066666666
    ],
    [
        68.636,
        -22.77934956666667
    ],
    [
        68.6464,
        -21.546079728571424
    ],
    [
        68.6567,
        -28.12351888571429
    ],
    [
        68.6671,
        -25.901736876190483
    ],
    [
        68.6775,
        -25.49064692857143
    ],
    [
        68.6878,
        -21.380198957142856
    ],
    [
        68.6982,
        -11.511331228571427
    ],
    [
        68.7085,
        -11.922421176190474
    ],
    [
        68.7189,
        -15.21114075238095
    ],
    [
        68.7293,
        -11.100241280952384
    ],
    [
        68.7396,
        -11.100241280952384
    ],
    [
        68.75,
        -12.743698071428572
    ],
    [
        68.7603,
        -7.80790970952381
    ],
    [
        68.7707,
        -4.519190133333333
    ],
    [
        68.7811,
        -6.987084314285714
    ],
    [
        68.7914,
        -6.575994366666667
    ],
    [
        68.8018,
        -7.8092642095238105
    ],
    [
        68.8122,
        -9.570691266666667
    ],
    [
        68.8225,
        -9.28880101904762
    ],
    [
        68.8329,
        -6.8209068380952385
    ],
    [
        68.8432,
        -10.520716361904762
    ],
    [
        68.8536,
        -8.051467680952381
    ],
    [
        68.864,
        -7.366317766666666
    ],
    [
        68.8743,
        -8.188497657142857
    ],
    [
        68.8847,
        -10.40960241904762
    ],
    [
        68.8951,
        -6.709792895238096
    ],
    [
        68.9054,
        -7.531521290476191
    ],
    [
        68.9158,
        -7.534230285714283
    ],
    [
        68.9261,
        -7.534230285714288
    ],
    [
        68.9365,
        -4.362190980952381
    ],
    [
        68.9469,
        -6.993166642857142
    ],
    [
        68.9572,
        -0.8234311904761885
    ],
    [
        68.9676,
        -0.0012512999999991674
    ],
    [
        68.9779,
        -4.937039661904762
    ],
    [
        68.9883,
        -4.525949714285715
    ],
    [
        68.9987,
        -3.702415323809523
    ],
    [
        69.009,
        -0.8238826952380949
    ],
    [
        69.0194,
        -1.234972642857142
    ],
    [
        69.0298,
        -2.927242938095238
    ],
    [
        69.0401,
        -0.5781575238095229
    ],
    [
        69.0505,
        -1.401691914285713
    ],
    [
        69.0608,
        2.9147525333333326
    ],
    [
        69.0712,
        -1.0338781571428572
    ],
    [
        69.0816,
        -1.4723741000000001
    ],
    [
        69.0919,
        0.9941655809523805
    ],
    [
        69.1023,
        0.9941655809523808
    ],
    [
        69.1127,
        -3.938913780952381
    ],
    [
        69.123,
        0.1719856904761905
    ],
    [
        69.1334,
        -5.465819300000001
    ],
    [
        69.1437,
        -2.5881896666666675
    ],
    [
        69.1541,
        -4.938049080952381
    ],
    [
        69.1645,
        -6.420681885714286
    ],
    [
        69.1748,
        -10.121168657142857
    ],
    [
        69.1852,
        -10.121168657142857
    ],
    [
        69.1955,
        -8.475905871428571
    ],
    [
        69.2059,
        -9.709175709523809
    ],
    [
        69.2163,
        -3.129027557142857
    ],
    [
        69.2266,
        -3.952110447619048
    ],
    [
        69.237,
        -3.294366528571428
    ],
    [
        69.2474,
        -3.5418331952380946
    ],
    [
        69.2577,
        -6.419462828571428
    ],
    [
        69.2681,
        -6.41946282857143
    ],
    [
        69.2784,
        -6.507553533333333
    ],
    [
        69.2888,
        -5.028171523809523
    ],
    [
        69.2992,
        -6.5080953333333325
    ],
    [
        69.3095,
        -11.4411747
    ],
    [
        69.3199,
        -15.145724961904762
    ],
    [
        69.3302,
        -15.145724961904762
    ],
    [
        69.3406,
        -16.3789948
    ],
    [
        69.351,
        -16.661426847619047
    ],
    [
        69.3613,
        -15.42815700952381
    ],
    [
        69.3717,
        -13.78379721904762
    ],
    [
        69.3821,
        -12.01927416666667
    ],
    [
        69.3924,
        -9.552734485714284
    ],
    [
        69.4028,
        -8.613874319047618
    ],
    [
        69.4131,
        -8.614777314285712
    ],
    [
        69.4235,
        -8.616131814285714
    ],
    [
        69.4339,
        -12.318650333333338
    ],
    [
        69.4442,
        -14.127446100000002
    ],
    [
        69.4546,
        -8.207750861904763
    ],
    [
        69.465,
        -9.439666204761906
    ],
    [
        69.4753,
        -10.674290542857142
    ],
    [
        69.4857,
        -14.786544514285717
    ],
    [
        69.496,
        -19.01760725238095
    ],
    [
        69.5064,
        -26.417226300000006
    ],
    [
        69.5168,
        -19.01760725238095
    ],
    [
        69.5271,
        -15.317797728571426
    ],
    [
        69.5375,
        -10.379300376190477
    ],
    [
        69.5478,
        -8.73494059047619
    ],
    [
        69.5582,
        -7.49896175714286
    ],
    [
        69.5686,
        -8.978343766666667
    ],
    [
        69.5789,
        -9.80052365714286
    ],
    [
        69.5893,
        -10.622703552380955
    ],
    [
        69.5997,
        -9.095798033333335
    ],
    [
        69.61,
        -9.424850590476192
    ],
    [
        69.6204,
        -7.897171071428572
    ],
    [
        69.6307,
        -9.541530861904764
    ],
    [
        69.6411,
        -12.012134033333334
    ],
    [
        69.6515,
        -15.71329805714286
    ],
    [
        69.6618,
        -15.959952028571431
    ],
    [
        69.6722,
        -19.248671604761903
    ],
    [
        69.6825,
        -11.849052557142862
    ],
    [
        69.6929,
        -10.085884000000002
    ],
    [
        69.7033,
        -6.631373947619047
    ],
    [
        69.7136,
        3.2429117666666665
    ],
    [
        69.724,
        7.353811238095238
    ],
    [
        69.7343,
        -2.5123474952380955
    ],
    [
        69.7447,
        -0.04580780952380944
    ],
    [
        69.7551,
        -0.8684392047619047
    ],
    [
        69.7654,
        1.1870105333333332
    ],
    [
        69.7758,
        -2.926597933333334
    ],
    [
        69.7862,
        -2.9265979333333343
    ],
    [
        69.7965,
        -9.509455076190475
    ],
    [
        69.8069,
        -9.509455076190475
    ],
    [
        69.8173,
        -9.098365128571427
    ],
    [
        69.8276,
        -11.235852252380951
    ],
    [
        69.838,
        -12.058032147619045
    ],
    [
        69.8483,
        -10.413672357142856
    ],
    [
        69.8587,
        -7.119534795238094
    ],
    [
        69.8691,
        -5.356753238095236
    ],
    [
        69.8794,
        -2.4782206095238086
    ],
    [
        69.8898,
        -9.055659766666665
    ],
    [
        69.9001,
        -13.988739133333333
    ],
    [
        69.9105,
        -11.639653719047617
    ],
    [
        69.9209,
        -13.174389519047617
    ],
    [
        69.9312,
        -16.87961702857143
    ],
    [
        69.9416,
        -11.121648771428577
    ],
    [
        69.9519,
        -6.893682028571424
    ],
    [
        69.9623,
        -3.193872504761905
    ],
    [
        69.9727,
        -3.357856980952378
    ],
    [
        69.983,
        -9.526915185714287
    ],
    [
        69.9934,
        -8.704735290476188
    ],
    [
        70.0038,
        -4.593835819047617
    ],
    [
        70.0141,
        0.34466153333333305
    ],
    [
        70.0245,
        -1.2996982523809528
    ],
    [
        70.0348,
        -0.06507391428571467
    ],
    [
        70.0452,
        2.401465766666667
    ],
    [
        70.0556,
        -2.5343225952380957
    ],
    [
        70.0659,
        -9.111761752380954
    ],
    [
        70.0763,
        -5.823042171428573
    ],
    [
        70.0867,
        -5.611508385714286
    ],
    [
        70.097,
        -9.723310852380953
    ],
    [
        70.1074,
        -6.434591276190476
    ],
    [
        70.1177,
        -5.61195988095238
    ],
    [
        70.1281,
        -7.961432295238095
    ],
    [
        70.1385,
        -8.64658220952381
    ],
    [
        70.1488,
        -9.87985205238095
    ],
    [
        70.1592,
        -9.879852052380954
    ],
    [
        70.1695,
        -6.708199747619049
    ],
    [
        70.1799,
        -11.641279114285716
    ],
    [
        70.1903,
        -11.888610333333332
    ],
    [
        70.2006,
        -8.598084757142859
    ],
    [
        70.211,
        -7.227784933333334
    ],
    [
        70.2214,
        -9.420264652380954
    ],
    [
        70.2317,
        -10.653534495238098
    ],
    [
        70.2421,
        -8.186994814285715
    ],
    [
        70.2524,
        -11.477068890476191
    ],
    [
        70.2628,
        -10.653986000000002
    ],
    [
        70.2732,
        -6.166659400000001
    ],
    [
        70.2835,
        -0.7402720952380953
    ],
    [
        70.2939,
        -3.089744509523809
    ],
    [
        70.3043,
        -1.6090080000000002
    ],
    [
        70.3146,
        0.8575316809523812
    ],
    [
        70.325,
        1.5152755999999996
    ],
    [
        70.3353,
        5.625723571428572
    ],
    [
        70.3457,
        1.3923388428571435
    ],
    [
        70.3561,
        5.092148366666668
    ],
    [
        70.3664,
        4.105532495238096
    ],
    [
        70.3768,
        1.6362838142857146
    ],
    [
        70.3871,
        -1.2413458142857139
    ],
    [
        70.3975,
        0.40301397619047646
    ],
    [
        70.4079,
        -0.8309331142857143
    ],
    [
        70.4182,
        2.458689461904762
    ],
    [
        70.4286,
        1.2935959904761898
    ],
    [
        70.439,
        4.3096101
    ],
    [
        70.4493,
        0.609800576190476
    ],
    [
        70.4597,
        2.2541603666666665
    ],
    [
        70.47,
        1.8430704190476193
    ],
    [
        70.4804,
        -1.4465521523809524
    ],
    [
        70.4908,
        0.6462694000000001
    ],
    [
        70.5011,
        -0.34088827142857253
    ],
    [
        70.5115,
        0.364224352380952
    ],
    [
        70.5218,
        -5.556012685714287
    ],
    [
        70.5322,
        -4.568855014285716
    ],
    [
        70.5426,
        -6.048778823809523
    ],
    [
        70.5529,
        -6.046069828571429
    ],
    [
        70.5633,
        -6.040651842857143
    ],
    [
        70.5737,
        -9.32937141904762
    ],
    [
        70.584,
        -13.2812529
    ],
    [
        70.5944,
        -15.747792580952384
    ],
    [
        70.6047,
        -10.403623266666667
    ],
    [
        70.6151,
        -12.047983057142858
    ],
    [
        70.6255,
        -10.566704747619047
    ],
    [
        70.6358,
        -13.444334380952379
    ],
    [
        70.6462,
        -11.594090995238096
    ],
    [
        70.6566,
        -14.767484800000002
    ],
    [
        70.6669,
        -4.901326066666666
    ],
    [
        70.6773,
        -1.6126064904761894
    ],
    [
        70.6876,
        2.0872030333333336
    ],
    [
        70.698,
        3.319795623809523
    ],
    [
        70.7084,
        0.44216599047619015
    ],
    [
        70.7187,
        5.869095090476191
    ],
    [
        70.7291,
        3.4025554095238095
    ],
    [
        70.7394,
        5.866386100000001
    ],
    [
        70.7498,
        7.347122609523811
    ],
    [
        70.7602,
        9.813662290476193
    ],
    [
        70.7705,
        4.055694033333336
    ],
    [
        70.7809,
        7.755503557142857
    ],
    [
        70.7912,
        8.577683452380953
    ],
    [
        70.8016,
        13.51618080476191
    ],
    [
        70.812,
        11.046932128571436
    ],
    [
        70.8223,
        8.580392442857143
    ],
    [
        70.8327,
        13.516180804761905
    ],
    [
        70.8431,
        13.798071052380951
    ],
    [
        70.8534,
        17.497880576190475
    ],
    [
        70.8638,
        21.1976901
    ],
    [
        70.8741,
        16.970110357142858
    ],
    [
        70.8845,
        12.037030990476191
    ],
    [
        70.8949,
        6.280868733333335
    ],
    [
        70.9052,
        -1.1187503142857143
    ],
    [
        70.9156,
        2.5817364571428576
    ],
    [
        70.926,
        2.581736457142858
    ],
    [
        70.9363,
        2.5844454523809537
    ],
    [
        70.9467,
        6.037601004761906
    ],
    [
        70.957,
        8.013541742857143
    ],
    [
        70.9674,
        4.558489890476191
    ],
    [
        70.9778,
        6.204655676190477
    ],
    [
        70.9881,
        6.3221099476190465
    ],
    [
        70.9985,
        8.003841552380951
    ],
    [
        71.0088,
        7.6243739095238094
    ],
    [
        71.0192,
        10.09091359047619
    ],
    [
        71.0296,
        14.204522057142858
    ],
    [
        71.0399,
        10.750824704761905
    ],
    [
        71.0503,
        5.815036342857143
    ],
    [
        71.0607,
        4.875402176190477
    ],
    [
        71.071,
        7.341941861904763
    ],
    [
        71.0814,
        1.1749154047619055
    ],
    [
        71.0917,
        7.341264614285715
    ],
    [
        71.1021,
        6.107994771428572
    ],
    [
        71.1125,
        5.943919990476192
    ],
    [
        71.1228,
        9.232639566666666
    ],
    [
        71.1332,
        8.000046976190479
    ],
    [
        71.1435,
        8.411136923809526
    ],
    [
        71.1539,
        4.297979957142858
    ],
    [
        71.1643,
        2.078094242857144
    ],
    [
        71.1746,
        1.5847863095238102
    ],
    [
        71.185,
        7.507732342857144
    ],
    [
        71.1954,
        0.928487190476192
    ],
    [
        71.2057,
        0.6460551428571429
    ],
    [
        71.2161,
        1.1842092523809538
    ],
    [
        71.2264,
        -3.3721114666666665
    ],
    [
        71.2368,
        -6.825267019047617
    ],
    [
        71.2472,
        -7.2363569666666665
    ],
    [
        71.2575,
        -7.483146385714284
    ],
    [
        71.2679,
        -8.716416228571427
    ],
    [
        71.2783,
        -2.1389770714285703
    ],
    [
        71.2886,
        -7.072056438095238
    ],
    [
        71.299,
        -3.3702151666666667
    ],
    [
        71.3093,
        -2.136945328571429
    ],
    [
        71.3197,
        -2.1369453285714286
    ],
    [
        71.3301,
        -1.7664225761904764
    ],
    [
        71.3404,
        0.7001171095238101
    ],
    [
        71.3508,
        -0.533829980952381
    ],
    [
        71.3611,
        -0.32828500476190636
    ],
    [
        71.3715,
        -2.797082185714287
    ],
    [
        71.3819,
        -1.0705044047619054
    ],
    [
        71.3922,
        -0.5771964714285706
    ],
    [
        71.4026,
        -10.446064200000002
    ],
    [
        71.413,
        -5.512984833333333
    ],
    [
        71.4233,
        -2.0592874809523813
    ],
    [
        71.4337,
        -4.690263142857144
    ],
    [
        71.444,
        -0.5775576714285722
    ],
    [
        71.4544,
        -0.8242116428571435
    ],
    [
        71.4648,
        -3.701841276190477
    ],
    [
        71.4751,
        -1.7631685619047637
    ],
    [
        71.4855,
        -1.5165145904761919
    ],
    [
        71.4959,
        -3.981699776190479
    ],
    [
        71.5062,
        -5.626059566666669
    ],
    [
        71.5166,
        -8.093953747619047
    ],
    [
        71.5269,
        -11.5481929
    ],
    [
        71.5373,
        -8.670563266666667
    ],
    [
        71.5477,
        -8.758653971428572
    ],
    [
        71.558,
        -8.594217995238093
    ],
    [
        71.5684,
        -13.52729736190476
    ],
    [
        71.5787,
        -13.897278314285716
    ],
    [
        71.5891,
        -13.895472319047618
    ],
    [
        71.5995,
        -13.402164385714283
    ],
    [
        71.6098,
        -13.614356071428569
    ],
    [
        71.6202,
        -8.064641785714285
    ],
    [
        71.6306,
        -9.298588876190475
    ],
    [
        71.6409,
        -14.233022738095238
    ],
    [
        71.6513,
        -14.233022738095238
    ],
    [
        71.6616,
        -15.05429963809524
    ],
    [
        71.672,
        -12.587759952380953
    ],
    [
        71.6824,
        -12.178024499999998
    ],
    [
        71.6927,
        -11.649480280952382
    ],
    [
        71.7031,
        -10.662864409523808
    ],
    [
        71.7134,
        -14.775118380952382
    ],
    [
        71.7238,
        -14.775118380952382
    ],
    [
        71.7342,
        -19.70819774761905
    ],
    [
        71.7445,
        -19.953768119047616
    ],
    [
        71.7549,
        -20.36418081904762
    ],
    [
        71.7653,
        -20.207876123809523
    ],
    [
        71.7756,
        -21.194491995238096
    ],
    [
        71.786,
        -21.194491995238092
    ],
    [
        71.7963,
        -19.503150499999997
    ],
    [
        71.8067,
        -18.352098647619044
    ],
    [
        71.8171,
        -18.63398889523809
    ],
    [
        71.8274,
        -19.572849061904755
    ],
    [
        71.8378,
        -21.01166387619047
    ],
    [
        71.8481,
        -18.543092447619046
    ],
    [
        71.8585,
        -17.720461052380948
    ],
    [
        71.8689,
        -21.00918062857143
    ],
    [
        71.8792,
        -20.18700073333334
    ],
    [
        71.8896,
        -21.420270576190475
    ],
    [
        71.9,
        -25.11872560476191
    ],
    [
        71.9103,
        -23.885455766666663
    ],
    [
        71.9207,
        -28.823953119047616
    ],
    [
        71.931,
        -22.246513961904764
    ],
    [
        71.9414,
        -15.669074804761905
    ],
    [
        71.9518,
        -12.380355228571432
    ],
    [
        71.9621,
        -11.7637203047619
    ],
    [
        71.9725,
        -14.230937238095237
    ],
    [
        71.9828,
        -13.68251630952381
    ],
    [
        71.9932,
        -18.61830467142857
    ],
    [
        72.0036,
        -14.672382976190477
    ],
    [
        72.0139,
        -14.143838757142854
    ],
    [
        72.0243,
        -18.583610185714285
    ],
    [
        72.0347,
        -19.288335809523808
    ],
    [
        72.045,
        -20.1105157
    ],
    [
        72.0554,
        -17.643976014285712
    ],
    [
        72.0657,
        -19.701231747619047
    ],
    [
        72.0761,
        -22.991757323809523
    ],
    [
        72.0865,
        -17.23604656190476
    ],
    [
        72.0968,
        -18.88040634761905
    ],
    [
        72.1072,
        -19.585131971428574
    ],
    [
        72.1175,
        -13.418782761904762
    ],
    [
        72.1279,
        -16.871938314285714
    ],
    [
        72.1383,
        -13.578703747619048
    ],
    [
        72.1486,
        -17.278513271428572
    ],
    [
        72.159,
        -20.97764554761905
    ],
    [
        72.1694,
        -20.038398380952387
    ],
    [
        72.1797,
        -21.888303142857147
    ],
    [
        72.1901,
        -21.888303142857147
    ],
    [
        72.2004,
        -16.95522377619048
    ],
    [
        72.2108,
        -7.083647052380955
    ],
    [
        72.2212,
        -6.7131243
    ],
    [
        72.2315,
        -5.479854461904766
    ],
    [
        72.2419,
        -3.014669276190474
    ],
    [
        72.2523,
        -7.947748642857141
    ],
    [
        72.2626,
        -3.0133147809523804
    ],
    [
        72.273,
        -5.890944414285713
    ],
    [
        72.2833,
        -7.534852699999997
    ],
    [
        72.2937,
        -7.534852699999998
    ],
    [
        72.3041,
        -3.4244047285714276
    ],
    [
        72.3144,
        -1.5065869761904749
    ],
    [
        72.3248,
        -0.8018613523809519
    ],
    [
        72.3351,
        -3.268401038095237
    ],
    [
        72.3455,
        -0.6374253761904776
    ],
    [
        72.3559,
        1.0078374095238085
    ],
    [
        72.3662,
        -0.22543243333333457
    ],
    [
        72.3766,
        1.0071601571428563
    ],
    [
        72.387,
        -3.220806585714287
    ],
    [
        72.3973,
        0.8909958809523791
    ],
    [
        72.4077,
        1.7445896952380937
    ],
    [
        72.418,
        -2.6951817333333348
    ],
    [
        72.4284,
        -8.45360149047619
    ],
    [
        72.4388,
        -7.8369665714285714
    ],
    [
        72.4491,
        -11.125686147619048
    ],
    [
        72.4595,
        -7.013432176190475
    ],
    [
        72.4699,
        -7.016141171428573
    ],
    [
        72.4802,
        -4.548246990476194
    ],
    [
        72.4906,
        -4.019702771428573
    ],
    [
        72.5009,
        -6.4862424571428585
    ],
    [
        72.5113,
        -3.194813885714286
    ],
    [
        72.5217,
        -7.1407355809523825
    ],
    [
        72.532,
        -9.058553333333336
    ],
    [
        72.5424,
        -6.590659152380955
    ],
    [
        72.5527,
        -5.974362852380953
    ],
    [
        72.5631,
        -6.632106771428573
    ],
    [
        72.5735,
        -7.337219395238097
    ],
    [
        72.5838,
        -8.981579185714287
    ],
    [
        72.5942,
        -10.21484902380952
    ],
    [
        72.6046,
        -6.103949552380952
    ],
    [
        72.6149,
        -7.044486714285713
    ],
    [
        72.6253,
        -9.134059361904761
    ],
    [
        72.6356,
        -7.98300750952381
    ],
    [
        72.646,
        -9.626915795238094
    ],
    [
        72.6564,
        -10.613531666666667
    ],
    [
        72.6667,
        -10.613531666666665
    ],
    [
        72.6771,
        -10.613531666666667
    ],
    [
        72.6875,
        -0.7419549428571411
    ],
    [
        72.6978,
        -7.322103095238095
    ],
    [
        72.7082,
        -7.440460361904761
    ],
    [
        72.7185,
        -0.8630212047619038
    ],
    [
        72.7289,
        0.07699996190476362
    ],
    [
        72.7393,
        0.735105076190478
    ],
    [
        72.7496,
        3.2016447571428577
    ],
    [
        72.76,
        0.028637952380953922
    ],
    [
        72.7703,
        1.4682428904761913
    ],
    [
        72.7807,
        0.4816270190476196
    ],
    [
        72.7911,
        -1.2811545380952378
    ],
    [
        72.8014,
        -0.8693873380952372
    ],
    [
        72.8118,
        -3.3366042714285706
    ],
    [
        72.8222,
        2.4159459952380953
    ],
    [
        72.8325,
        2.6983780428571436
    ],
    [
        72.8429,
        4.5513304238095245
    ],
    [
        72.8532,
        5.374864814285714
    ],
    [
        72.8636,
        6.607457404761905
    ],
    [
        72.874,
        7.594073276190476
    ],
    [
        72.8843,
        10.059709961904762
    ],
    [
        72.8947,
        7.18140308095238
    ],
    [
        72.905,
        4.713508900000001
    ],
    [
        72.9154,
        11.288239061904763
    ],
    [
        72.9258,
        10.466059166666666
    ],
    [
        72.9361,
        7.999970980952381
    ],
    [
        72.9465,
        6.233706428571428
    ],
    [
        72.9568,
        5.204739938095239
    ],
    [
        72.9672,
        3.5585741523809524
    ],
    [
        72.9776,
        3.7706497380952366
    ],
    [
        72.9879,
        5.415912523809525
    ],
    [
        72.9983,
        5.415912523809525
    ],
    [
        73.0087,
        8.870964376190477
    ],
    [
        73.019,
        9.280925571428574
    ],
    [
        73.0294,
        9.692241266666667
    ],
    [
        73.0397,
        3.5279238047619055
    ],
    [
        73.0501,
        3.3624945285714296
    ],
    [
        73.0605,
        2.991633152380951
    ],
    [
        73.0708,
        0.5237389714285722
    ],
    [
        73.0812,
        6.693474423809524
    ],
    [
        73.0916,
        11.62655379047619
    ],
    [
        73.1019,
        9.366462080952381
    ],
    [
        73.1123,
        11.587566842857143
    ],
    [
        73.1226,
        8.414560038095239
    ],
    [
        73.133,
        -1.4461807047619037
    ],
    [
        73.1434,
        1.8443448666666664
    ],
    [
        73.1537,
        1.0208104761904764
    ],
    [
        73.1641,
        -1.441665714285715
    ],
    [
        73.1744,
        -0.8246921666666673
    ],
    [
        73.1848,
        -0.8228861714285708
    ],
    [
        73.1952,
        -1.9769179142857145
    ],
    [
        73.2055,
        -9.375182466666667
    ],
    [
        73.2159,
        -9.99215600952381
    ],
    [
        73.2263,
        -9.746721090476191
    ],
    [
        73.2366,
        -9.629202319047618
    ],
    [
        73.247,
        -6.340031242857143
    ],
    [
        73.2573,
        -2.6395444714285707
    ],
    [
        73.2677,
        -4.44843053809524
    ],
    [
        73.2781,
        -3.461272866666668
    ],
    [
        73.2884,
        -4.6945427095238115
    ],
    [
        73.2988,
        -8.80815117619048
    ],
    [
        73.3091,
        -18.677018904761905
    ],
    [
        73.3195,
        -18.6770189047619
    ],
    [
        73.3299,
        -18.677560704761902
    ],
    [
        73.3402,
        -19.205717923809523
    ],
    [
        73.3506,
        -15.263047023809523
    ],
    [
        73.361,
        -16.086129914285713
    ],
    [
        73.3713,
        -16.085226919047617
    ],
    [
        73.3817,
        -11.768782471428572
    ],
    [
        73.392,
        -13.002052314285717
    ],
    [
        73.4024,
        -6.424613157142858
    ],
    [
        73.4128,
        -5.600175771428572
    ],
    [
        73.4231,
        0.5634644428571424
    ],
    [
        73.4335,
        3.6483321666666653
    ],
    [
        73.4438,
        1.7106269523809507
    ],
    [
        73.4542,
        4.060099366666667
    ],
    [
        73.4646,
        0.36028984285714166
    ],
    [
        73.4749,
        -7.039329204761905
    ],
    [
        73.4853,
        -6.757284157142857
    ],
    [
        73.4957,
        -5.1138273666666665
    ],
    [
        73.506,
        -2.2343917380952387
    ],
    [
        73.5164,
        -7.989651000000001
    ],
    [
        73.5267,
        -4.28780972857143
    ],
    [
        73.5371,
        -3.6711748095238104
    ],
    [
        73.5475,
        -5.644948352380953
    ],
    [
        73.5578,
        0.5241098523809523
    ],
    [
        73.5682,
        2.744401914285715
    ],
    [
        73.5786,
        1.3052484761904768
    ],
    [
        73.5889,
        -1.986180095238095
    ],
    [
        73.5993,
        -1.369545176190476
    ],
    [
        73.6096,
        -3.0130019666666668
    ],
    [
        73.62,
        -6.958923661904763
    ],
    [
        73.6304,
        -8.602831947619048
    ],
    [
        73.6407,
        -10.658733185714286
    ],
    [
        73.6511,
        -10.661442180952383
    ],
    [
        73.6615,
        -10.661829180952381
    ],
    [
        73.6718,
        -11.319573100000003
    ],
    [
        73.6822,
        -10.086303257142859
    ],
    [
        73.6925,
        -6.139839761904763
    ],
    [
        73.7029,
        -3.790367347619048
    ],
    [
        73.7133,
        -6.42098180952381
    ],
    [
        73.7236,
        -10.532332780952382
    ],
    [
        73.734,
        -6.833200504761907
    ],
    [
        73.7443,
        -3.1313592333333338
    ],
    [
        73.7547,
        -1.4860964476190481
    ],
    [
        73.7651,
        -2.9641239619047623
    ],
    [
        73.7754,
        -9.133859414285716
    ],
    [
        73.7858,
        -5.4360816333333375
    ],
    [
        73.7962,
        -9.754896452380954
    ],
    [
        73.8065,
        -6.051700680952381
    ],
    [
        73.8169,
        -6.051700680952381
    ],
    [
        73.8272,
        -9.344032252380952
    ],
    [
        73.8376,
        -11.976452709523812
    ],
    [
        73.848,
        -9.509913028571429
    ],
    [
        73.8583,
        -3.754202266666666
    ],
    [
        73.8687,
        -4.985440361904763
    ],
    [
        73.879,
        -4.896237033333334
    ],
    [
        73.8894,
        -4.895695233333334
    ],
    [
        73.8998,
        -9.831483595238096
    ],
    [
        73.9101,
        -8.844867723809525
    ],
    [
        73.9205,
        -11.037046442857147
    ],
    [
        73.9308,
        -10.049888771428575
    ],
    [
        73.9412,
        -8.570777661904764
    ],
    [
        73.9516,
        -9.804724752380954
    ],
    [
        73.9619,
        -10.627356147619052
    ],
    [
        73.9723,
        -13.916075723809527
    ],
    [
        73.9827,
        -12.274424928571431
    ],
    [
        73.993,
        -10.218749442857145
    ],
    [
        74.0034,
        -16.7943826
    ],
    [
        74.0138,
        -10.215137447619052
    ],
    [
        74.0241,
        -10.627807642857144
    ],
    [
        74.0345,
        -12.271264433333334
    ],
    [
        74.0448,
        -9.803370252380953
    ],
    [
        74.0552,
        -8.706227395238095
    ],
    [
        74.0656,
        -7.4716030571428576
    ],
    [
        74.0759,
        -11.699956800000002
    ],
    [
        74.0863,
        -13.639016514285718
    ],
    [
        74.0966,
        -15.490614400000005
    ],
    [
        74.107,
        -18.94295725714286
    ],
    [
        74.1174,
        -12.361906109523808
    ],
    [
        74.1277,
        -13.066244733333333
    ],
    [
        74.1381,
        -11.69579440952381
    ],
    [
        74.1484,
        -12.682952080952381
    ],
    [
        74.1588,
        -12.684035676190476
    ],
    [
        74.1692,
        -5.285771123809523
    ],
    [
        74.1795,
        -6.9310339095238085
    ],
    [
        74.1899,
        -4.465848723809523
    ],
    [
        74.2003,
        -6.1102085142857145
    ],
    [
        74.2106,
        -9.399831090476189
    ],
    [
        74.221,
        -8.988515395238098
    ],
    [
        74.2313,
        -11.619129857142859
    ],
    [
        74.2417,
        -9.973867071428574
    ],
    [
        74.2521,
        -8.330410280952382
    ],
    [
        74.2624,
        -7.09510869047619
    ],
    [
        74.2728,
        -1.6112003999999993
    ],
    [
        74.2832,
        -7.778904104761905
    ],
    [
        74.2935,
        -9.4707874
    ],
    [
        74.3039,
        -7.778517104761905
    ],
    [
        74.3142,
        -6.544570014285715
    ],
    [
        74.3246,
        -4.6272037571428575
    ],
    [
        74.335,
        -5.449835147619047
    ],
    [
        74.3453,
        -4.745496523809523
    ],
    [
        74.3557,
        -6.801397761904762
    ],
    [
        74.366,
        -3.34634590952381
    ],
    [
        74.3764,
        -4.82572791904762
    ],
    [
        74.3868,
        -10.461597914285715
    ],
    [
        74.3971,
        -12.105054704761907
    ],
    [
        74.4075,
        -11.939625428571427
    ],
    [
        74.4179,
        -11.11609103809524
    ],
    [
        74.4282,
        -8.648196857142857
    ],
    [
        74.4386,
        -9.058609557142855
    ],
    [
        74.4489,
        -8.071451885714286
    ],
    [
        74.4593,
        -9.058067757142856
    ],
    [
        74.4697,
        -8.23543636190476
    ],
    [
        74.48,
        -8.647203561904762
    ],
    [
        74.4904,
        -14.570149595238092
    ],
    [
        74.5007,
        -10.870340071428572
    ],
    [
        74.5111,
        -7.41664271904762
    ],
    [
        74.5215,
        -7.1698533
    ],
    [
        74.5318,
        -6.483725138095237
    ],
    [
        74.5422,
        -3.4666575333333336
    ],
    [
        74.5526,
        -5.111468823809524
    ],
    [
        74.5629,
        -0.1756804619047614
    ],
    [
        74.5733,
        0.23586098571428576
    ],
    [
        74.5836,
        -1.4094017999999997
    ],
    [
        74.594,
        -3.8732324904761906
    ],
    [
        74.6044,
        -5.635627047619049
    ],
    [
        74.6147,
        -4.485749095238096
    ],
    [
        74.6251,
        -4.485749095238096
    ],
    [
        74.6354,
        -7.779886657142857
    ],
    [
        74.6458,
        -12.710257028571428
    ],
    [
        74.6562,
        -12.299844328571426
    ],
    [
        74.6665,
        -11.313228457142854
    ],
    [
        74.6769,
        -11.55988242857143
    ],
    [
        74.6873,
        -12.382513823809523
    ],
    [
        74.6976,
        -11.972778371428571
    ],
    [
        74.708,
        -12.62998049047619
    ],
    [
        74.7183,
        -8.930170966666667
    ],
    [
        74.7287,
        -6.463631280952381
    ],
    [
        74.7391,
        -8.146286404761904
    ],
    [
        74.7494,
        -5.954107685714287
    ],
    [
        74.7598,
        -13.356435728571428
    ],
    [
        74.7702,
        -11.711624438095235
    ],
    [
        74.7805,
        -21.58320116190476
    ],
    [
        74.7909,
        -23.228012447619044
    ],
    [
        74.8012,
        -26.517183523809518
    ],
    [
        74.8116,
        -21.966489180952376
    ],
    [
        74.822,
        -18.882298704761904
    ],
    [
        74.8323,
        -18.388719866666666
    ],
    [
        74.8427,
        -16.910692352380952
    ],
    [
        74.853,
        -16.913401347619043
    ],
    [
        74.8634,
        -16.913401347619047
    ],
    [
        74.8738,
        -18.147348438095236
    ],
    [
        74.8841,
        -20.7774211
    ],
    [
        74.8945,
        -20.778098347619046
    ],
    [
        74.9049,
        -20.778098347619046
    ],
    [
        74.9152,
        -22.008659195238096
    ],
    [
        74.9256,
        -20.364299404761905
    ],
    [
        74.9359,
        -23.653470480952382
    ],
    [
        74.9463,
        -26.941287061904767
    ],
    [
        74.9567,
        -28.95776421428572
    ],
    [
        74.967,
        -25.666335642857142
    ],
    [
        74.9774,
        -20.733256276190474
    ],
    [
        74.9877,
        -22.2610002952381
    ],
    [
        74.9981,
        -18.14694032857143
    ],
    [
        75.0085,
        -14.693242976190472
    ],
    [
        75.0188,
        -14.694597476190479
    ],
    [
        75.0292,
        -12.669658661904762
    ],
    [
        75.0396,
        -14.51990204761905
    ],
    [
        75.0499,
        -14.51990204761905
    ],
    [
        75.0603,
        -19.452981414285716
    ],
    [
        75.0706,
        -15.49893273809524
    ],
    [
        75.081,
        -9.334615276190476
    ],
    [
        75.0914,
        -6.866721095238094
    ],
    [
        75.1017,
        -0.2820579571428579
    ],
    [
        75.1121,
        -0.28070345714285727
    ],
    [
        75.1224,
        -0.8976770000000003
    ],
    [
        75.1328,
        2.8001007761904755
    ],
    [
        75.1432,
        2.251679847619047
    ],
    [
        75.1535,
        3.0743112428571417
    ],
    [
        75.1639,
        2.6616410476190473
    ],
    [
        75.1743,
        3.481563447619047
    ],
    [
        75.1846,
        -2.441382585714286
    ],
    [
        75.195,
        -3.675329676190476
    ],
    [
        75.2054,
        -2.148488657142857
    ],
    [
        75.2157,
        -2.9720230476190483
    ],
    [
        75.2261,
        -5.60263750952381
    ],
    [
        75.2364,
        -3.134743328571429
    ],
    [
        75.2468,
        -4.780006114285715
    ],
    [
        75.2572,
        -6.0153077
    ],
    [
        75.2675,
        -6.0153077
    ],
    [
        75.2779,
        -4.252526142857143
    ],
    [
        75.2882,
        -2.442737076190476
    ],
    [
        75.2986,
        -4.381022790476189
    ],
    [
        75.309,
        -3.8326018619047604
    ],
    [
        75.3193,
        -8.773808209523807
    ],
    [
        75.3297,
        -7.952305566666668
    ],
    [
        75.34,
        -9.803226204761906
    ],
    [
        75.3504,
        -11.858450190476193
    ],
    [
        75.3608,
        -9.666572471428573
    ],
    [
        75.3711,
        -10.489203866666669
    ],
    [
        75.3815,
        -9.255256776190476
    ],
    [
        75.3919,
        -6.99302044761905
    ],
    [
        75.4022,
        -6.827591171428575
    ],
    [
        75.4126,
        -7.239809866666668
    ],
    [
        75.4229,
        -9.048154133333336
    ],
    [
        75.4333,
        -9.04725113809524
    ],
    [
        75.4437,
        -6.698681723809524
    ],
    [
        75.454,
        -7.6852975952380955
    ],
    [
        75.4644,
        -9.330560380952383
    ],
    [
        75.4747,
        -7.565650328571428
    ],
    [
        75.4851,
        -5.919484542857142
    ],
    [
        75.4955,
        -6.447641761904763
    ],
    [
        75.5058,
        -5.6259133666666665
    ],
    [
        75.5162,
        -9.857363104761905
    ],
    [
        75.5266,
        -11.22841542857143
    ],
    [
        75.5369,
        -13.69495510952381
    ],
    [
        75.5473,
        -11.639505371428573
    ],
    [
        75.5576,
        -9.876723814285715
    ],
    [
        75.568,
        -6.586198238095237
    ],
    [
        75.5784,
        -9.216812700000002
    ],
    [
        75.5887,
        -7.161588714285715
    ],
    [
        75.5991,
        -9.628128395238097
    ],
    [
        75.6094,
        -8.60074214761905
    ],
    [
        75.6198,
        -8.395648671428571
    ],
    [
        75.6302,
        -9.216022566666666
    ],
    [
        75.6405,
        -8.934519319047618
    ],
    [
        75.6509,
        -8.934519319047618
    ],
    [
        75.6613,
        -4.703843580952379
    ],
    [
        75.6716,
        1.2158516571428568
    ],
    [
        75.682,
        0.3932202619047617
    ],
    [
        75.6923,
        3.564098566666666
    ],
    [
        75.7027,
        -0.5486069000000002
    ],
    [
        75.7131,
        -1.7832312380952382
    ],
    [
        75.7234,
        -4.1319296523809514
    ],
    [
        75.7338,
        1.7915581809523806
    ],
    [
        75.7442,
        2.6141895761904768
    ],
    [
        75.7545,
        4.463755714285715
    ],
    [
        75.7649,
        5.28458110952381
    ],
    [
        75.7752,
        1.0539053714285727
    ],
    [
        75.7856,
        1.0511963761904763
    ],
    [
        75.796,
        -0.42954013333333374
    ],
    [
        75.8063,
        -6.597921085714288
    ],
    [
        75.8167,
        -8.243183871428574
    ],
    [
        75.827,
        -12.352728847619046
    ],
    [
        75.8374,
        -16.67188229047619
    ],
    [
        75.8478,
        -15.850605395238098
    ],
    [
        75.8581,
        -13.502035980952382
    ],
    [
        75.8685,
        -13.091623280952382
    ],
    [
        75.8789,
        -16.38214885714286
    ],
    [
        75.8892,
        -21.667784533333332
    ],
    [
        75.8996,
        -18.378161957142858
    ],
    [
        75.9099,
        -22.078648728571427
    ],
    [
        75.9203,
        -21.255565838095237
    ],
    [
        75.9307,
        -18.78767165714286
    ],
    [
        75.941,
        -17.097258957142863
    ],
    [
        75.9514,
        -18.633981357142858
    ],
    [
        75.9617,
        -13.698192995238097
    ],
    [
        75.9721,
        -18.017007814285716
    ],
    [
        75.9825,
        -20.482193000000002
    ],
    [
        75.9928,
        -18.012944319047616
    ],
    [
        76.0032,
        -25.41391786666667
    ],
    [
        76.0136,
        -21.302566895238094
    ],
    [
        76.0239,
        -16.983752076190477
    ],
    [
        76.0343,
        -16.042827914285716
    ],
    [
        76.0446,
        -12.589401457142861
    ],
    [
        76.055,
        -9.297069885714285
    ],
    [
        76.0654,
        -2.7160187380952374
    ],
    [
        76.0757,
        -9.289845900000001
    ],
    [
        76.0861,
        -8.549477647619048
    ],
    [
        76.0965,
        -11.84000321904762
    ],
    [
        76.1068,
        -10.783108285714286
    ],
    [
        76.1172,
        -10.61659540952381
    ],
    [
        76.1275,
        -9.930467247619047
    ],
    [
        76.1379,
        -13.22279881904762
    ],
    [
        76.1483,
        -18.15316919047619
    ],
    [
        76.1586,
        -20.784506052380955
    ],
    [
        76.169,
        -15.305112752380953
    ],
    [
        76.1793,
        -21.06353250952381
    ],
    [
        76.1897,
        -17.77120093809524
    ],
    [
        76.2001,
        -16.12413215714286
    ],
    [
        76.2104,
        -13.657592476190477
    ],
    [
        76.2208,
        -8.721804114285714
    ],
    [
        76.2312,
        -9.378103233333334
    ],
    [
        76.2415,
        -9.25416672857143
    ],
    [
        76.2519,
        -8.549828104761906
    ],
    [
        76.2622,
        -9.538069376190476
    ],
    [
        76.2726,
        -12.830400947619047
    ],
    [
        76.283,
        -18.588820704761908
    ],
    [
        76.2933,
        -12.424503242857142
    ],
    [
        76.3037,
        -12.379139895238094
    ],
    [
        76.314,
        -8.265982928571427
    ],
    [
        76.3244,
        -8.148593157142857
    ],
    [
        76.3348,
        -10.123992099999999
    ],
    [
        76.3451,
        -9.576173171428572
    ],
    [
        76.3555,
        -5.133963647619048
    ],
    [
        76.3658,
        2.2615919095238093
    ],
    [
        76.3762,
        2.919697023809524
    ],
    [
        76.3866,
        -2.0106733476190484
    ],
    [
        76.3969,
        -2.174116028571429
    ],
    [
        76.4073,
        1.1164095428571426
    ],
    [
        76.4177,
        -1.516733314285715
    ],
    [
        76.428,
        -6.452521676190478
    ],
    [
        76.4384,
        -8.097784461904764
    ],
    [
        76.4487,
        -14.020730495238094
    ],
    [
        76.4591,
        -12.823354833333331
    ],
    [
        76.4695,
        -13.645986228571427
    ],
    [
        76.4798,
        -14.350324852380952
    ],
    [
        76.4902,
        -4.478748128571427
    ],
    [
        76.5005,
        -2.4221696428571406
    ],
    [
        76.5109,
        -5.301379519047619
    ],
    [
        76.5213,
        -5.6757954238095225
    ],
    [
        76.5316,
        -7.1576155285714265
    ],
    [
        76.542,
        -6.334081138095239
    ],
    [
        76.5524,
        -5.8947423952380955
    ],
    [
        76.5627,
        -8.362636576190477
    ],
    [
        76.5731,
        -8.573783361904761
    ],
    [
        76.5834,
        -13.50550822857143
    ],
    [
        76.5938,
        -11.813237933333335
    ],
    [
        76.6042,
        -12.22455362857143
    ],
    [
        76.6145,
        -10.252405476190475
    ],
    [
        76.6249,
        -12.60458688095238
    ],
    [
        76.6353,
        -10.91340018095238
    ],
    [
        76.6456,
        -5.977611819047618
    ],
    [
        76.656,
        -6.798888714285714
    ],
    [
        76.6663,
        -2.568212976190477
    ],
    [
        76.6767,
        -2.2853939285714295
    ],
    [
        76.6871,
        -6.393132904761904
    ],
    [
        76.6974,
        -5.688794280952381
    ],
    [
        76.7078,
        -11.44676253809524
    ],
    [
        76.7181,
        -7.744921266666668
    ],
    [
        76.7285,
        -6.803997104761903
    ],
    [
        76.7389,
        -7.2153127999999995
    ],
    [
        76.7492,
        -5.733492695238095
    ],
    [
        76.7596,
        -12.311834847619046
    ],
    [
        76.77,
        -14.231910100000002
    ],
    [
        76.7803,
        -6.830936552380952
    ],
    [
        76.7907,
        -6.831323552380951
    ],
    [
        76.801,
        -6.008240661904761
    ],
    [
        76.8114,
        -11.649141647619047
    ],
    [
        76.8218,
        -16.173614314285714
    ],
    [
        76.8321,
        -14.69314870952381
    ],
    [
        76.8425,
        -13.988036085714286
    ],
    [
        76.8529,
        -15.68030638095238
    ],
    [
        76.8632,
        -10.747227014285713
    ],
    [
        76.8736,
        -8.280687333333333
    ],
    [
        76.8839,
        -7.575574709523811
    ],
    [
        76.8943,
        -9.796002223809525
    ],
    [
        76.9047,
        -3.879015980952381
    ],
    [
        76.915,
        -6.346910161904762
    ],
    [
        76.9254,
        -6.75867735714286
    ],
    [
        76.9357,
        -12.681081590476191
    ],
    [
        76.9461,
        -16.909822333333334
    ],
    [
        76.9565,
        -16.909145085714286
    ],
    [
        76.9668,
        -21.02230205238095
    ],
    [
        76.9772,
        -13.622683004761907
    ],
    [
        76.9876,
        -10.332157428571431
    ],
    [
        76.9979,
        -15.758544733333332
    ],
    [
        77.0083,
        -15.05343210952381
    ],
    [
        77.0186,
        -12.177382728571429
    ],
    [
        77.029,
        -7.7900152952380965
    ],
    [
        77.0394,
        -0.38633275238095266
    ],
    [
        77.0497,
        -0.3876872476190488
    ],
    [
        77.0601,
        1.258478538095237
    ],
    [
        77.0705,
        1.7971744476190463
    ],
    [
        77.0808,
        -1.90195782857143
    ],
    [
        77.0912,
        3.0284125428571413
    ],
    [
        77.1015,
        2.205781147619046
    ],
    [
        77.1119,
        2.891232061904762
    ],
    [
        77.1223,
        2.5215897333333332
    ],
    [
        77.1326,
        4.002326242857143
    ],
    [
        77.143,
        3.5910105476190473
    ],
    [
        77.1533,
        7.042811604761904
    ],
    [
        77.1637,
        7.040102609523809
    ],
    [
        77.1741,
        7.286214780952383
    ],
    [
        77.1844,
        8.931477566666667
    ],
    [
        77.1948,
        1.529149523809521
    ],
    [
        77.2052,
        3.171703314285713
    ],
    [
        77.2155,
        3.215810676190474
    ],
    [
        77.2259,
        -1.7145596952380975
    ],
    [
        77.2362,
        -5.415046466666665
    ],
    [
        77.2466,
        -3.880551461904761
    ],
    [
        77.257,
        -7.171077038095237
    ],
    [
        77.2673,
        -11.279719014285714
    ],
    [
        77.2777,
        -12.220772176190476
    ],
    [
        77.288,
        -12.59494180952381
    ],
    [
        77.2984,
        -18.770095247619047
    ],
    [
        77.3088,
        -25.344825409523814
    ],
    [
        77.3191,
        -25.75546385714286
    ],
    [
        77.3295,
        -25.207644928571433
    ],
    [
        77.3399,
        -25.276799542857145
    ],
    [
        77.3502,
        -24.700957566666673
    ],
    [
        77.3606,
        -18.125324409523813
    ],
    [
        77.3709,
        -21.578750866666667
    ],
    [
        77.3813,
        -15.65688842857143
    ],
    [
        77.3917,
        -15.022287066666669
    ],
    [
        77.402,
        -13.378830276190477
    ],
    [
        77.4124,
        -5.9751477333333325
    ],
    [
        77.4228,
        -9.262964314285714
    ],
    [
        77.4331,
        -6.348578552380952
    ],
    [
        77.4435,
        -3.063470966666667
    ],
    [
        77.4538,
        -0.5955767857142846
    ],
    [
        77.4642,
        -4.049545042857142
    ],
    [
        77.4746,
        -4.459506238095237
    ],
    [
        77.4849,
        -1.1707866619047604
    ],
    [
        77.4953,
        -2.580237909523808
    ],
    [
        77.5056,
        0.7102876666666676
    ],
    [
        77.516,
        3.186308828571429
    ],
    [
        77.5264,
        2.362774438095239
    ],
    [
        77.5367,
        3.59604428095238
    ],
    [
        77.5471,
        2.7734128857142846
    ],
    [
        77.5575,
        4.690779142857143
    ],
    [
        77.5678,
        6.08925250952381
    ],
    [
        77.5782,
        0.7101741047619052
    ],
    [
        77.5885,
        0.4658227857142852
    ],
    [
        77.5989,
        0.30238010476190536
    ],
    [
        77.6093,
        -1.8116032666666673
    ],
    [
        77.6196,
        -5.101225842857143
    ],
    [
        77.63,
        -8.801712614285714
    ],
    [
        77.6403,
        -3.043292857142857
    ],
    [
        77.6507,
        -0.5753986761904748
    ],
    [
        77.6611,
        -0.5744956809523803
    ],
    [
        77.6714,
        -1.122615609523809
    ],
    [
        77.6818,
        1.83885740952381
    ],
    [
        77.6921,
        2.5230290714285712
    ],
    [
        77.7025,
        -1.5887733952380947
    ],
    [
        77.7129,
        0.760828014285716
    ],
    [
        77.7232,
        -1.7070661666666664
    ],
    [
        77.7336,
        0.20939709047619137
    ],
    [
        77.744,
        3.9098838619047607
    ],
    [
        77.7543,
        6.377778042857141
    ],
    [
        77.7647,
        2.2673300714285705
    ],
    [
        77.775,
        -0.9033547380952368
    ],
    [
        77.7854,
        -1.2742161142857134
    ],
    [
        77.7958,
        -1.205184109523809
    ],
    [
        77.8061,
        1.6708652714285714
    ],
    [
        77.8165,
        0.848233876190478
    ],
    [
        77.8269,
        0.846879376190475
    ],
    [
        77.8372,
        0.02650548095238375
    ],
    [
        77.8476,
        6.192177438095241
    ],
    [
        77.8579,
        0.022441985714285823
    ],
    [
        77.8683,
        -5.897795052380951
    ],
    [
        77.8787,
        0.6769351095238108
    ],
    [
        77.889,
        3.6929492190476196
    ],
    [
        77.8994,
        2.5827354619047633
    ],
    [
        77.9097,
        4.776419176190477
    ],
    [
        77.9201,
        4.7777736714285695
    ],
    [
        77.9305,
        1.8985637952380958
    ],
    [
        77.9408,
        1.0759324000000006
    ],
    [
        77.9512,
        2.329379952380951
    ],
    [
        77.9616,
        -1.3697523190476197
    ],
    [
        77.9719,
        -4.331225338095236
    ],
    [
        77.9823,
        1.3069666523809524
    ],
    [
        77.9926,
        -0.452718909523811
    ],
    [
        78.003,
        -1.7741278285714301
    ],
    [
        78.0134,
        -6.330240161904764
    ],
    [
        78.0237,
        -10.440236633333333
    ],
    [
        78.0341,
        -7.973696952380953
    ],
    [
        78.0445,
        -10.441591133333336
    ],
    [
        78.0548,
        -8.961125528571431
    ],
    [
        78.0652,
        -13.894204895238095
    ],
    [
        78.0755,
        -15.125442990476188
    ],
    [
        78.0859,
        -9.202496957142857
    ],
    [
        78.0963,
        -11.664973147619046
    ],
    [
        78.1066,
        -14.681288257142853
    ],
    [
        78.117,
        -16.53254751904762
    ],
    [
        78.1273,
        -20.64299549047619
    ],
    [
        78.1377,
        -16.94386321428571
    ],
    [
        78.1481,
        -17.35337291428571
    ],
    [
        78.1584,
        -17.079162447619048
    ],
    [
        78.1688,
        -16.138625285714284
    ],
    [
        78.1791,
        -15.31734839047619
    ],
    [
        78.1895,
        -9.887981190476191
    ],
    [
        78.1999,
        -9.88798119047619
    ],
    [
        78.2102,
        -7.712817790476193
    ],
    [
        78.2206,
        -5.362442380952386
    ],
    [
        78.231,
        -2.0719168047619054
    ],
    [
        78.2413,
        -0.8379697142857145
    ],
    [
        78.2517,
        -0.836615214285715
    ],
    [
        78.262,
        2.453910361904762
    ],
    [
        78.2724,
        4.145097061904762
    ],
    [
        78.2828,
        3.1590229857142864
    ],
    [
        78.2931,
        5.624208171428571
    ],
    [
        78.3035,
        -0.9550369809523807
    ],
    [
        78.3139,
        -3.4229311619047618
    ],
    [
        78.3242,
        -4.517967023809524
    ],
    [
        78.3346,
        -6.99127919047619
    ],
    [
        78.3449,
        -3.293501414285714
    ],
    [
        78.3553,
        -5.144422047619047
    ],
    [
        78.3657,
        -3.9945440952380937
    ],
    [
        78.376,
        -2.2135305761904744
    ],
    [
        78.3864,
        1.076995000000002
    ],
    [
        78.3968,
        1.9005293904761924
    ],
    [
        78.4071,
        -2.211724580952381
    ],
    [
        78.4175,
        -5.382022390476189
    ],
    [
        78.4278,
        -6.325947695238096
    ],
    [
        78.4382,
        -6.985136404761905
    ],
    [
        78.4486,
        -10.275661980952382
    ],
    [
        78.4589,
        -9.747408014285716
    ],
    [
        78.4693,
        -12.83227573809524
    ],
    [
        78.4796,
        -6.251224590476191
    ],
    [
        78.49,
        -5.311074428571429
    ],
    [
        78.5004,
        -4.325000352380954
    ],
    [
        78.5107,
        -4.941973895238097
    ],
    [
        78.5211,
        -0.8306229238095233
    ],
    [
        78.5315,
        4.099747447619047
    ],
    [
        78.5418,
        5.194783309523809
    ],
    [
        78.5522,
        11.779446447619048
    ],
    [
        78.5625,
        9.559696185714287
    ],
    [
        78.5729,
        11.821932514285715
    ],
    [
        78.5833,
        12.315511352380952
    ],
    [
        78.5936,
        10.589881723809526
    ],
    [
        78.604,
        0.7183049999999989
    ],
    [
        78.6143,
        -3.395754966666666
    ],
    [
        78.6247,
        -4.219289357142859
    ],
    [
        78.6351,
        -2.741261842857142
    ],
    [
        78.6454,
        -1.5073147523809525
    ],
    [
        78.6558,
        2.442399533333335
    ],
    [
        78.6661,
        6.392113819047621
    ],
    [
        78.6765,
        7.096452442857146
    ],
    [
        78.6869,
        2.7830556142857175
    ],
    [
        78.6972,
        -4.620626928571428
    ],
    [
        78.7076,
        -6.265889714285715
    ],
    [
        78.718,
        -6.971002338095238
    ],
    [
        78.7283,
        -11.289817157142858
    ],
    [
        78.7387,
        -12.11244855238095
    ],
    [
        78.7491,
        -15.399362133333335
    ],
    [
        78.7594,
        -15.556053828571432
    ],
    [
        78.7698,
        -18.02214201428572
    ],
    [
        78.7801,
        -16.418688047619053
    ],
    [
        78.7905,
        -22.9997391952381
    ],
    [
        78.8009,
        -24.23368628571429
    ],
    [
        78.8112,
        -24.234228085714285
    ],
    [
        78.8216,
        -18.850224233333332
    ],
    [
        78.8319,
        -14.266992180952379
    ],
    [
        78.8423,
        -19.19736255238095
    ],
    [
        78.8527,
        -13.274416519047618
    ],
    [
        78.863,
        -13.891051438095236
    ],
    [
        78.8734,
        -18.826839799999995
    ],
    [
        78.8837,
        -22.773845090476186
    ],
    [
        78.8941,
        -27.70963345238095
    ],
    [
        78.9045,
        -22.779263080952383
    ],
    [
        78.9148,
        -19.07877630952381
    ],
    [
        78.9252,
        -19.695749852380953
    ],
    [
        78.9356,
        -21.457176909523813
    ],
    [
        78.9459,
        -18.166651333333334
    ],
    [
        78.9563,
        -22.2770993047619
    ],
    [
        78.9666,
        -25.155180433333335
    ],
    [
        78.977,
        -26.916607490476196
    ],
    [
        78.9874,
        -31.02705546190476
    ],
    [
        78.9977,
        -26.710272390476185
    ],
    [
        79.0081,
        -22.322904957142857
    ],
    [
        79.0185,
        -18.623772680952378
    ],
    [
        79.0288,
        -19.36414093333333
    ],
    [
        79.0392,
        -24.748144785714285
    ],
    [
        79.0495,
        -29.33137683809524
    ],
    [
        79.0599,
        -29.334085833333337
    ],
    [
        79.0703,
        -35.503821285714295
    ],
    [
        79.0806,
        -33.653239276190476
    ],
    [
        79.091,
        -34.269874195238096
    ],
    [
        79.1013,
        -31.80333450952381
    ],
    [
        79.1117,
        -28.511905938095246
    ],
    [
        79.1221,
        -30.98115461428572
    ],
    [
        79.1324,
        -29.748562019047622
    ],
    [
        79.1428,
        -31.599482657142865
    ],
    [
        79.1532,
        -34.06602234285714
    ],
    [
        79.1635,
        -33.40791722857143
    ],
    [
        79.1739,
        -30.238006419047615
    ],
    [
        79.1842,
        -29.496690019047616
    ],
    [
        79.1946,
        -27.030150338095236
    ],
    [
        79.205,
        -23.330340814285712
    ],
    [
        79.2153,
        -30.731314361904758
    ],
    [
        79.2257,
        -31.827253223809528
    ],
    [
        79.2361,
        -34.29243840952381
    ],
    [
        79.2464,
        -34.29108390952381
    ],
    [
        79.2568,
        -29.355295547619047
    ],
    [
        79.2671,
        -25.405581261904757
    ],
    [
        79.2775,
        -18.004607714285708
    ],
    [
        79.2879,
        -14.30276644285714
    ],
    [
        79.2982,
        -16.085134461904754
    ],
    [
        79.3086,
        -14.234552452380951
    ],
    [
        79.3189,
        -13.000605361904759
    ],
    [
        79.3293,
        -12.59019266190476
    ],
    [
        79.3397,
        -11.107018061904762
    ],
    [
        79.35,
        -16.04009742857143
    ],
    [
        79.3604,
        -8.636414885714284
    ],
    [
        79.3707,
        -8.639123880952381
    ],
    [
        79.3811,
        -8.638040285714286
    ],
    [
        79.3915,
        -10.576713000000002
    ],
    [
        79.4018,
        -15.013775433333334
    ],
    [
        79.4122,
        -16.247722523809525
    ],
    [
        79.4226,
        -15.837084076190475
    ],
    [
        79.4329,
        -11.725733104761906
    ],
    [
        79.4433,
        -10.787259933333335
    ],
    [
        79.4536,
        -8.32207474761905
    ],
    [
        79.464,
        -7.9121135523809505
    ],
    [
        79.4744,
        -12.842483923809523
    ],
    [
        79.4847,
        -13.090356938095237
    ],
    [
        79.4951,
        -13.914794328571428
    ],
    [
        79.5054,
        -17.617990095238092
    ],
    [
        79.5158,
        -18.055643242857144
    ],
    [
        79.5262,
        -24.23079668095238
    ],
    [
        79.5365,
        -22.99820409047619
    ],
    [
        79.5469,
        -24.232151180952382
    ],
    [
        79.5573,
        -28.176447476190475
    ],
    [
        79.5676,
        -27.353816080952377
    ],
    [
        79.578,
        -37.2253928047619
    ],
    [
        79.5883,
        -37.21997481428571
    ],
    [
        79.5987,
        -37.88096951904762
    ],
    [
        79.6091,
        -37.87961501904762
    ],
    [
        79.6194,
        -28.013456285714284
    ],
    [
        79.6298,
        -27.190373390476186
    ],
    [
        79.6402,
        -31.30353035714285
    ],
    [
        79.6505,
        -32.94698714761904
    ],
    [
        79.6609,
        -33.06373192380952
    ],
    [
        79.6712,
        -37.1723739
    ],
    [
        79.6816,
        -33.879139328571426
    ],
    [
        79.692,
        -31.413954142857143
    ],
    [
        79.7023,
        -31.413954142857136
    ],
    [
        79.7127,
        -33.605831861904754
    ],
    [
        79.7231,
        -31.754233980952378
    ],
    [
        79.7334,
        -31.26173874285714
    ],
    [
        79.7438,
        -24.677075604761903
    ],
    [
        79.7541,
        -25.911022695238092
    ],
    [
        79.7645,
        -24.677075604761903
    ],
    [
        79.7749,
        -22.214599414285715
    ],
    [
        79.7852,
        -15.638966257142856
    ],
    [
        79.7956,
        -11.525809290476193
    ],
    [
        79.8059,
        -7.143558847619049
    ],
    [
        79.8163,
        -4.509874195238094
    ],
    [
        79.8267,
        -8.213069966666668
    ],
    [
        79.837,
        -13.597566361904764
    ],
    [
        79.8474,
        -12.199544495238095
    ],
    [
        79.8577,
        -12.194126504761906
    ],
    [
        79.8681,
        -13.016757900000002
    ],
    [
        79.8785,
        -9.727586823809526
    ],
    [
        79.8888,
        -10.550669714285716
    ],
    [
        79.8992,
        -14.007888761904765
    ],
    [
        79.9096,
        -12.247816200000003
    ],
    [
        79.9199,
        -13.481763290476191
    ],
    [
        79.9303,
        -8.000262995238096
    ],
    [
        79.9406,
        -9.164679219047619
    ],
    [
        79.951,
        -7.436476042857144
    ],
    [
        79.9614,
        -9.628353761904767
    ],
    [
        79.9717,
        -7.5726782761904765
    ],
    [
        79.9821,
        -5.104784095238096
    ],
    [
        79.9925,
        -4.281249704761905
    ],
    [
        80.0028,
        -9.520276161904764
    ],
    [
        80.0132,
        -3.767274395238094
    ],
    [
        80.0235,
        -4.4531015571428565
    ],
    [
        80.0339,
        -5.439717428571428
    ],
    [
        80.0443,
        -2.9718232476190476
    ],
    [
        80.0546,
        -3.34529510952381
    ],
    [
        80.065,
        -5.566399871428572
    ],
    [
        80.0753,
        -5.569108866666667
    ],
    [
        80.0857,
        0.18931089047619107
    ],
    [
        80.0961,
        -3.263844661904762
    ],
    [
        80.1064,
        -5.730384347619047
    ],
    [
        80.1168,
        -5.977715566666666
    ],
    [
        80.1272,
        -6.9169627333333334
    ],
    [
        80.1375,
        -6.9169627333333334
    ],
    [
        80.1479,
        -12.397861028571429
    ],
    [
        80.1582,
        -11.8497411
    ],
    [
        80.1686,
        -14.31763528095238
    ],
    [
        80.179,
        -11.302674671428571
    ],
    [
        80.1893,
        -12.946582961904761
    ],
    [
        80.1997,
        -11.71263587142857
    ],
    [
        80.2101,
        -14.998646452380951
    ],
    [
        80.2204,
        -12.222096185714284
    ],
    [
        80.2308,
        -17.975097952380953
    ],
    [
        80.2411,
        -17.975097952380953
    ],
    [
        80.2515,
        -19.619457742857144
    ],
    [
        80.2619,
        -17.856289185714285
    ],
    [
        80.2722,
        -12.098320928571427
    ],
    [
        80.2826,
        -5.934003466666666
    ],
    [
        80.2929,
        0.644338685714287
    ],
    [
        80.3033,
        -5.277523752380954
    ],
    [
        80.3137,
        -4.908558676190478
    ],
    [
        80.324,
        -1.2080719000000006
    ],
    [
        80.3344,
        1.6711379761904774
    ],
    [
        80.3448,
        2.6111591428571437
    ],
    [
        80.3551,
        2.611159142857143
    ],
    [
        80.3655,
        4.804240857142858
    ],
    [
        80.3758,
        3.8181667809523825
    ],
    [
        80.3862,
        4.228805228571429
    ],
    [
        80.3966,
        4.2315142238095245
    ],
    [
        80.4069,
        3.408431333333335
    ],
    [
        80.4173,
        -0.7038226380952385
    ],
    [
        80.4276,
        2.5812849476190487
    ],
    [
        80.438,
        0.113390766666667
    ],
    [
        80.4484,
        0.38760123333333446
    ],
    [
        80.4587,
        1.6215483238095236
    ],
    [
        80.4691,
        2.2782086428571424
    ],
    [
        80.4794,
        7.916013633333334
    ],
    [
        80.4898,
        1.3349624857142863
    ],
    [
        80.5002,
        -5.24067067142857
    ],
    [
        80.5105,
        -8.118526047619048
    ],
    [
        80.5209,
        -8.777714757142855
    ],
    [
        80.5313,
        -8.981792357142858
    ],
    [
        80.5416,
        -10.217093947619048
    ],
    [
        80.552,
        -13.09630382380952
    ],
    [
        80.5623,
        -13.008938742857143
    ],
    [
        80.5727,
        -11.088863490476191
    ],
    [
        80.5831,
        -12.185705347619049
    ],
    [
        80.5934,
        -11.199631271428572
    ],
    [
        80.6038,
        -11.364157547619048
    ],
    [
        80.6142,
        -14.656037619047616
    ],
    [
        80.6245,
        -13.832503228571428
    ],
    [
        80.6349,
        -11.365512042857144
    ],
    [
        80.6452,
        -10.95419634761905
    ],
    [
        80.6556,
        -11.402904380952382
    ],
    [
        80.666,
        -10.854483452380954
    ],
    [
        80.6763,
        -15.790271814285715
    ],
    [
        80.6867,
        -15.624300738095242
    ],
    [
        80.697,
        -22.202642890476188
    ],
    [
        80.7074,
        -15.621591742857142
    ],
    [
        80.7178,
        -14.681441580952383
    ],
    [
        80.7281,
        -8.518478619047626
    ],
    [
        80.7385,
        -7.421636761904765
    ],
    [
        80.7489,
        -9.065093552380953
    ],
    [
        80.7592,
        -8.242462157142857
    ],
    [
        80.7696,
        -5.363252280952382
    ],
    [
        80.7799,
        -5.239315776190477
    ],
    [
        80.7903,
        -5.515031238095239
    ],
    [
        80.8007,
        -4.419995376190475
    ],
    [
        80.811,
        -4.913574214285713
    ],
    [
        80.8214,
        -8.856786914285715
    ],
    [
        80.8317,
        -9.26810260952381
    ],
    [
        80.8421,
        -10.091637
    ],
    [
        80.8525,
        -11.61938101904762
    ],
    [
        80.8628,
        -7.917539747619049
    ],
    [
        80.8732,
        -12.404620076190476
    ],
    [
        80.8836,
        -13.1089587
    ],
    [
        80.8939,
        -9.407117428571429
    ],
    [
        80.9043,
        -9.572546704761903
    ],
    [
        80.9146,
        -9.57074070952381
    ],
    [
        80.925,
        -14.506529071428572
    ],
    [
        80.9354,
        -14.294453485714287
    ],
    [
        80.9457,
        -17.577755076190474
    ],
    [
        80.9561,
        -18.015408223809523
    ],
    [
        80.9665,
        -16.255335661904763
    ],
    [
        80.9768,
        -15.431801271428569
    ],
    [
        80.9872,
        -18.06422172857143
    ],
    [
        80.9975,
        -16.3385921
    ],
    [
        81.0079,
        -16.13203125238095
    ],
    [
        81.0183,
        -20.243382223809522
    ],
    [
        81.0286,
        -14.814015023809523
    ],
    [
        81.039,
        -10.432065580952383
    ],
    [
        81.0494,
        -11.663303676190477
    ],
    [
        81.0597,
        -10.635014433333334
    ],
    [
        81.0701,
        -14.860272180952379
    ],
    [
        81.0804,
        -17.32816636190476
    ],
    [
        81.0908,
        -12.940497928571428
    ],
    [
        81.1012,
        -11.001534966666668
    ],
    [
        81.1115,
        -11.000180466666666
    ],
    [
        81.1219,
        -11.000180466666666
    ],
    [
        81.1322,
        -10.17890357142857
    ],
    [
        81.1426,
        -10.17890357142857
    ],
    [
        81.153,
        -14.618945899999998
    ],
    [
        81.1633,
        -17.088646076190475
    ],
    [
        81.1737,
        -16.807684623809525
    ],
    [
        81.1841,
        -17.74693179047619
    ],
    [
        81.1944,
        -17.912361066666666
    ],
    [
        81.2048,
        -19.391743076190476
    ],
    [
        81.2151,
        -25.55741503333333
    ],
    [
        81.2255,
        -25.319909133333333
    ],
    [
        81.2359,
        -22.029383557142857
    ],
    [
        81.2462,
        -27.669510542857147
    ],
    [
        81.2566,
        -27.94372100952381
    ],
    [
        81.2669,
        -27.123798609523803
    ],
    [
        81.2773,
        -27.740772157142857
    ],
    [
        81.2877,
        -23.303709723809522
    ],
    [
        81.298,
        -24.290867395238095
    ],
    [
        81.3084,
        -23.742747466666668
    ],
    [
        81.3188,
        -29.910451171428576
    ],
    [
        81.3291,
        -30.322218371428573
    ],
    [
        81.3395,
        -29.714528342857143
    ],
    [
        81.3498,
        -30.53851423333334
    ],
    [
        81.3602,
        -35.46888460476191
    ],
    [
        81.3706,
        -31.08422616666666
    ],
    [
        81.3809,
        -30.261594771428566
    ],
    [
        81.3913,
        -30.37911354285714
    ],
    [
        81.4017,
        -29.967346342857137
    ],
    [
        81.412,
        -30.214135761904757
    ],
    [
        81.4224,
        -30.215490261904762
    ],
    [
        81.4327,
        -26.51568073809524
    ],
    [
        81.4431,
        -23.668318914285717
    ],
    [
        81.4535,
        -24.984258247619053
    ],
    [
        81.4638,
        -25.102551019047617
    ],
    [
        81.4742,
        -25.2659937
    ],
    [
        81.4845,
        -24.854678004761904
    ],
    [
        81.4949,
        -21.97727412380952
    ],
    [
        81.5053,
        -21.932920490476185
    ],
    [
        81.5156,
        -21.932378690476188
    ],
    [
        81.526,
        -26.868167052380947
    ],
    [
        81.5364,
        -22.42812472380952
    ],
    [
        81.5467,
        -18.31587075238095
    ],
    [
        81.5571,
        -18.92356078095238
    ],
    [
        81.5674,
        -17.277394995238094
    ],
    [
        81.5778,
        -12.84087435714286
    ],
    [
        81.5882,
        -11.059183585714285
    ],
    [
        81.5985,
        -11.367670357142858
    ],
    [
        81.6089,
        -10.545038961904762
    ],
    [
        81.6192,
        -4.37665800952381
    ],
    [
        81.6296,
        -3.553575114285714
    ],
    [
        81.64,
        -3.553575114285714
    ],
    [
        81.6503,
        -3.1420336666666655
    ],
    [
        81.6607,
        -0.6687215000000004
    ],
    [
        81.671,
        -0.9971419571428584
    ],
    [
        81.6814,
        -0.8796231857142854
    ],
    [
        81.6918,
        -0.5098454047619049
    ],
    [
        81.7021,
        5.659890047619048
    ],
    [
        81.7125,
        3.52285441904762
    ],
    [
        81.7229,
        2.7378616333333334
    ],
    [
        81.7332,
        3.1075039619047624
    ],
    [
        81.7436,
        8.748404947619047
    ],
    [
        81.7539,
        8.747863147619046
    ],
    [
        81.7643,
        5.293894890476189
    ],
    [
        81.7747,
        5.047105471428568
    ],
    [
        81.785,
        3.4036486809523776
    ],
    [
        81.7954,
        3.4039195761904737
    ],
    [
        81.8058,
        1.1845756571428554
    ],
    [
        81.8161,
        1.493062428571427
    ],
    [
        81.8265,
        0.6713340333333316
    ],
    [
        81.8368,
        -4.758033166666667
    ],
    [
        81.8472,
        -6.815063152380953
    ],
    [
        81.8576,
        -4.8949879
    ],
    [
        81.8679,
        -1.605365323809524
    ],
    [
        81.8783,
        -7.779164261904763
    ],
    [
        81.8886,
        -7.662548490476191
    ],
    [
        81.899,
        -7.780067261904763
    ],
    [
        81.9094,
        -7.986402361904763
    ],
    [
        81.9197,
        -14.565647514285718
    ],
    [
        81.9301,
        -14.620489604761907
    ],
    [
        81.9405,
        -15.851727700000001
    ],
    [
        81.9508,
        -16.880016942857146
    ],
    [
        81.9612,
        -20.05302374761905
    ],
    [
        81.9715,
        -21.2047076952381
    ],
    [
        81.9819,
        -20.83560716190477
    ],
    [
        81.9923,
        -20.149479000000003
    ],
    [
        82.0026,
        -19.122995752380955
    ],
    [
        82.013,
        -20.272873704761906
    ],
    [
        82.0234,
        -24.222587990476192
    ],
    [
        82.0337,
        -24.633677938095236
    ],
    [
        82.0441,
        -21.345409861904766
    ],
    [
        82.0544,
        -21.55694364761905
    ],
    [
        82.0648,
        -19.500365161904764
    ],
    [
        82.0752,
        -16.486006552380953
    ],
    [
        82.0855,
        -19.658626357142865
    ],
    [
        82.0959,
        -19.248665161904768
    ],
    [
        82.1062,
        -23.47663190476191
    ],
    [
        82.1166,
        -23.147308447619054
    ],
    [
        82.127,
        -23.31129292380953
    ],
    [
        82.1373,
        -22.21655806190477
    ],
    [
        82.1477,
        -21.66813713333334
    ],
    [
        82.1581,
        -19.651413709523815
    ],
    [
        82.1684,
        -16.360888138095238
    ],
    [
        82.1788,
        -14.341701990476189
    ],
    [
        82.1891,
        -13.682513280952378
    ],
    [
        82.1995,
        -13.888171133333335
    ],
    [
        82.2099,
        -14.327509876190478
    ],
    [
        82.2202,
        -14.533167728571433
    ],
    [
        82.2306,
        -17.820081309523815
    ],
    [
        82.241,
        -13.263968976190478
    ],
    [
        82.2513,
        -13.8400367
    ],
    [
        82.2617,
        -16.922872676190476
    ],
    [
        82.272,
        -14.572497266666668
    ],
    [
        82.2824,
        -14.51354438095238
    ],
    [
        82.2928,
        -15.992384590476192
    ],
    [
        82.3031,
        -15.735874457142856
    ],
    [
        82.3135,
        -16.14719015238095
    ],
    [
        82.3238,
        -11.625200733333335
    ],
    [
        82.3342,
        -6.198813428571428
    ],
    [
        82.3446,
        -5.8288324761904775
    ],
    [
        82.3549,
        -5.828531476190477
    ],
    [
        82.3653,
        -6.651162871428572
    ],
    [
        82.3757,
        -7.18931698095238
    ],
    [
        82.386,
        -9.657211161904762
    ],
    [
        82.3964,
        -9.701811066666668
    ],
    [
        82.4067,
        -9.20904492857143
    ],
    [
        82.4171,
        -5.919873852380955
    ],
    [
        82.4275,
        -5.481137109523812
    ],
    [
        82.4378,
        -5.2069266428571455
    ],
    [
        82.4482,
        -5.2096356380952376
    ],
    [
        82.4585,
        -5.241049557142857
    ],
    [
        82.4689,
        -9.18968024761905
    ],
    [
        82.4793,
        -9.395112347619047
    ],
    [
        82.4896,
        -11.45169082857143
    ],
    [
        82.5,
        -11.921959414285718
    ],
    [
        82.5104,
        -13.895732961904761
    ],
    [
        82.5207,
        -14.153017095238093
    ],
    [
        82.5311,
        -13.412377942857141
    ],
    [
        82.5414,
        -15.469182176190477
    ],
    [
        82.5518,
        -21.10660016666667
    ],
    [
        82.5622,
        -21.03838617619048
    ],
    [
        82.5725,
        -21.72255783809524
    ],
    [
        82.5829,
        -21.887084114285717
    ],
    [
        82.5932,
        -21.51676453809524
    ],
    [
        82.6036,
        -21.18825377619048
    ],
    [
        82.614,
        -21.682103509523813
    ],
    [
        82.6243,
        -22.01097546666667
    ],
    [
        82.6347,
        -25.094488690476197
    ],
    [
        82.6451,
        -24.994529523809526
    ],
    [
        82.6554,
        -26.09137138095238
    ],
    [
        82.6658,
        -22.390884604761908
    ],
    [
        82.6761,
        -22.96749412857143
    ],
    [
        82.6865,
        -18.41117340952381
    ],
    [
        82.6969,
        -18.29417063809524
    ],
    [
        82.7072,
        -18.869470814285712
    ],
    [
        82.7176,
        -18.540418257142857
    ],
    [
        82.728,
        -18.170640476190478
    ],
    [
        82.7383,
        -18.082114395238097
    ],
    [
        82.7487,
        -18.574880533333335
    ],
    [
        82.759,
        -16.812872971428572
    ],
    [
        82.7694,
        -16.60157138571428
    ],
    [
        82.7798,
        -17.69841324285714
    ],
    [
        82.7901,
        -17.01424158095238
    ],
    [
        82.8005,
        -16.4752994
    ],
    [
        82.8108,
        -20.794791466666666
    ],
    [
        82.8212,
        -21.122399228571425
    ],
    [
        82.8316,
        -21.123482828571426
    ],
    [
        82.8419,
        -20.749518423809523
    ],
    [
        82.8523,
        -20.749518423809523
    ],
    [
        82.8627,
        -21.94451345238095
    ],
    [
        82.873,
        -21.12188206190476
    ],
    [
        82.8834,
        -20.505585766666666
    ],
    [
        82.8937,
        -20.751833385714285
    ],
    [
        82.9041,
        -20.821073776190474
    ],
    [
        82.9145,
        -21.10350582380952
    ],
    [
        82.9248,
        -21.10404762380952
    ],
    [
        82.9352,
        -21.103505823809524
    ],
    [
        82.9455,
        -21.309840923809524
    ],
    [
        82.9559,
        -21.397206004761905
    ],
    [
        82.9663,
        -22.056394714285716
    ],
    [
        82.9766,
        -21.350508095238098
    ],
    [
        82.987,
        -21.13198251904762
    ],
    [
        82.9974,
        -20.10301602857143
    ],
    [
        83.0077,
        -19.5545951
    ],
    [
        83.0181,
        -19.810718233333336
    ],
    [
        83.0284,
        -15.323637904761906
    ],
    [
        83.0388,
        -9.566121147619047
    ],
    [
        83.0492,
        -9.071458714285713
    ],
    [
        83.0595,
        -8.623243223809524
    ],
    [
        83.0699,
        -8.554690609523808
    ],
    [
        83.0802,
        -7.459654747619045
    ],
    [
        83.0906,
        -7.253319647619048
    ],
    [
        83.101,
        -7.870970442857144
    ],
    [
        83.1113,
        -7.869615942857143
    ],
    [
        83.1217,
        -8.654855000000001
    ],
    [
        83.1321,
        -8.115912819047619
    ],
    [
        83.1424,
        -7.952289538095238
    ],
    [
        83.1528,
        -8.445326576190476
    ],
    [
        83.1631,
        -8.445326576190475
    ],
    [
        83.1735,
        -8.289408880952381
    ],
    [
        83.1839,
        -7.137183133333332
    ],
    [
        83.1942,
        -8.371130223809523
    ],
    [
        83.2046,
        -8.544834252380952
    ],
    [
        83.215,
        -8.545511500000002
    ],
    [
        83.2253,
        -9.532669171428573
    ],
    [
        83.2357,
        -9.180886857142857
    ],
    [
        83.246,
        -9.55390721904762
    ],
    [
        83.2564,
        -14.86937160952381
    ],
    [
        83.2668,
        -15.361866847619048
    ],
    [
        83.2771,
        -16.184498242857142
    ],
    [
        83.2875,
        -16.25271223333333
    ],
    [
        83.2978,
        -16.03352446190476
    ],
    [
        83.3082,
        -15.828092361904762
    ],
    [
        83.3186,
        -15.828092361904758
    ],
    [
        83.3289,
        -14.948056080952378
    ],
    [
        83.3393,
        -14.700183066666668
    ],
    [
        83.3497,
        -14.800388504761905
    ],
    [
        83.36,
        -14.285171576190479
    ],
    [
        83.3704,
        -13.916409671428571
    ],
    [
        83.3807,
        -13.383800061904761
    ],
    [
        83.3911,
        -13.452352676190479
    ],
    [
        83.4015,
        -13.370405585714288
    ],
    [
        83.4118,
        -12.684578423809528
    ],
    [
        83.4222,
        -12.729670871428574
    ],
    [
        83.4326,
        -12.561405361904766
    ],
    [
        83.4429,
        -11.864428828571432
    ],
    [
        83.4533,
        -12.21698514285715
    ],
    [
        83.4636,
        -11.88883558571429
    ],
    [
        83.474,
        -12.331339452380957
    ],
    [
        83.4844,
        -11.635446514285718
    ],
    [
        83.4947,
        -11.799430990476193
    ],
    [
        83.5051,
        -11.512029228571432
    ],
    [
        83.5154,
        -11.490944219047622
    ],
    [
        83.5258,
        -11.69705356666667
    ],
    [
        83.5362,
        -10.956685314285716
    ],
    [
        83.5465,
        -10.894469495238097
    ],
    [
        83.5569,
        -10.612734042857145
    ],
    [
        83.5673,
        -10.51302114761905
    ],
    [
        83.5776,
        -10.65341171904762
    ],
    [
        83.588,
        -10.583918476190478
    ],
    [
        83.5983,
        -10.742604723809526
    ],
    [
        83.6087,
        -10.674052109523812
    ],
    [
        83.6191,
        -11.496909252380956
    ],
    [
        83.6294,
        -11.770517719047623
    ],
    [
        83.6398,
        -11.688525476190478
    ],
    [
        83.6502,
        -12.226433314285718
    ],
    [
        83.6605,
        -12.640977800000003
    ],
    [
        83.6709,
        -12.346955123809524
    ],
    [
        83.6812,
        -13.087323376190476
    ],
    [
        83.6916,
        -12.882117023809526
    ],
    [
        83.702,
        -13.003025833333336
    ],
    [
        83.7123,
        -12.4651179952381
    ],
    [
        83.7227,
        -7.202805471428573
    ],
    [
        83.733,
        -7.387965261904766
    ],
    [
        83.7434,
        -7.264299657142861
    ],
    [
        83.7538,
        -7.475833442857146
    ],
    [
        83.7641,
        -9.65067813809524
    ],
    [
        83.7745,
        -9.44018924761905
    ],
    [
        83.7848,
        -9.696206838095241
    ],
    [
        83.7952,
        -9.627174833333337
    ],
    [
        83.8056,
        -9.572392942857144
    ],
    [
        83.8159,
        -9.572392942857144
    ],
    [
        83.8263,
        -9.84570040952381
    ],
    [
        83.8367,
        -9.84570040952381
    ],
    [
        83.847,
        -9.283750347619046
    ],
    [
        83.8574,
        -9.36601349047619
    ],
    [
        83.8677,
        -8.927818547619049
    ],
    [
        83.8781,
        -8.671308414285713
    ],
    [
        83.8885,
        -8.589271023809525
    ],
    [
        83.8988,
        -8.34139800952381
    ],
    [
        83.9092,
        -8.135740161904762
    ],
    [
        83.9195,
        -8.272469142857142
    ],
    [
        83.9299,
        -8.372784038095237
    ],
    [
        83.9403,
        -13.658419714285715
    ],
    [
        83.9506,
        -13.98729167142857
    ],
    [
        83.961,
        -14.110618652380952
    ],
    [
        83.9714,
        -13.816821723809525
    ],
    [
        83.9817,
        -11.731312566666666
    ],
    [
        83.9921,
        -11.685678323809523
    ],
    [
        84.0024,
        -11.429660733333334
    ],
    [
        84.0128,
        -11.498692738095238
    ],
    [
        84.0232,
        -11.622290619047622
    ],
    [
        84.0335,
        -11.584672533333336
    ],
    [
        84.0439,
        -11.379917680952383
    ],
    [
        84.0543,
        -11.132044666666669
    ],
    [
        84.0646,
        -11.224822642857145
    ],
    [
        84.075,
        -11.552972200000003
    ],
    [
        84.0853,
        -11.553574200000003
    ],
    [
        84.0957,
        -11.927087104761906
    ],
    [
        84.1061,
        -11.927087104761906
    ],
    [
        84.1164,
        -11.434591866666668
    ],
    [
        84.1268,
        -11.471491661904762
    ],
    [
        84.1371,
        -11.303348761904763
    ],
    [
        84.1475,
        -11.37062212857143
    ],
    [
        84.1579,
        -11.840697214285715
    ],
    [
        84.1682,
        -11.32700409047619
    ],
    [
        84.1786,
        -10.98457963809524
    ],
    [
        84.189,
        -10.9525232
    ],
    [
        84.1993,
        -10.984162866666667
    ],
    [
        84.2097,
        -11.083766304761907
    ],
    [
        84.22,
        -10.894836604761904
    ],
    [
        84.2304,
        -11.680075661904763
    ],
    [
        84.2408,
        -11.474756438095238
    ],
    [
        84.2511,
        -12.297613580952381
    ],
    [
        84.2615,
        -11.904747780952382
    ],
    [
        84.2718,
        -11.535647247619048
    ],
    [
        84.2822,
        -11.73090986666667
    ],
    [
        84.2926,
        -11.357889504761907
    ],
    [
        84.3029,
        -11.25732833809524
    ],
    [
        84.3133,
        -10.983719871428573
    ],
    [
        84.3237,
        -11.10337534761905
    ],
    [
        84.334,
        -11.103375347619048
    ],
    [
        84.3444,
        -11.007329171428571
    ],
    [
        84.3547,
        -10.936139914285715
    ],
    [
        84.3651,
        -10.86796354761905
    ],
    [
        84.3755,
        -10.42500818095238
    ],
    [
        84.3858,
        -10.664641338095239
    ],
    [
        84.3962,
        -11.095543495238095
    ],
    [
        84.4065,
        -11.165484814285712
    ],
    [
        84.4169,
        -11.20238460952381
    ],
    [
        84.4273,
        -11.14814451904762
    ],
    [
        84.4376,
        -11.29923038095238
    ],
    [
        84.448,
        -10.394335847619047
    ],
    [
        84.4584,
        -10.393884347619046
    ],
    [
        84.4687,
        -9.571252952380954
    ],
    [
        84.4791,
        -9.795853242857143
    ],
    [
        84.4894,
        -9.590195395238098
    ],
    [
        84.4998,
        -9.864104861904764
    ],
    [
        84.5102,
        -9.79507285714286
    ],
    [
        84.5205,
        -9.620821561904762
    ],
    [
        84.5309,
        -9.777040257142856
    ],
    [
        84.5413,
        -9.739647919047618
    ],
    [
        84.5516,
        -9.657159028571428
    ],
    [
        84.562,
        -9.684874123809525
    ],
    [
        84.5723,
        -9.924206280952381
    ],
    [
        84.5827,
        -9.755762380952381
    ],
    [
        84.5931,
        -9.666267490476192
    ],
    [
        84.6034,
        -9.611455500000002
    ],
    [
        84.6138,
        -9.317432823809526
    ],
    [
        84.6242,
        -9.220788557142859
    ],
    [
        84.6345,
        -9.08089052857143
    ],
    [
        84.6449,
        -13.523100052380952
    ],
    [
        84.6552,
        -13.56094389047619
    ],
    [
        84.6656,
        -13.725199271428572
    ],
    [
        84.676,
        -13.762796838095236
    ],
    [
        84.6863,
        -13.730948785714283
    ],
    [
        84.6967,
        -13.987353376190478
    ],
    [
        84.707,
        -14.069074719047618
    ],
    [
        84.7174,
        -13.696300628571429
    ],
    [
        84.7278,
        -13.668987409523808
    ],
    [
        84.7381,
        -13.843484976190474
    ],
    [
        84.7485,
        -13.843484976190476
    ],
    [
        84.7589,
        -13.761221838095237
    ],
    [
        84.7692,
        -13.843710728571429
    ],
    [
        84.7796,
        -13.723319495238096
    ],
    [
        84.7899,
        -13.483987338095238
    ],
    [
        84.8003,
        -13.433363866666665
    ],
    [
        84.8106,
        -13.402961061904762
    ],
    [
        84.821,
        -13.28891238095238
    ],
    [
        84.8314,
        -13.124747300000003
    ],
    [
        84.8417,
        -13.18328093809524
    ],
    [
        84.8521,
        -13.286053423809527
    ],
    [
        84.8625,
        -8.585173604761904
    ],
    [
        84.8728,
        -8.396386485714284
    ],
    [
        84.8832,
        -8.255441357142857
    ],
    [
        84.8935,
        -8.081039561904763
    ],
    [
        84.9039,
        -8.194879857142858
    ],
    [
        84.9143,
        -7.724804776190478
    ],
    [
        84.9246,
        -7.584130547619049
    ],
    [
        84.935,
        -7.40923620952381
    ],
    [
        84.9454,
        -7.436549428571429
    ],
    [
        84.9557,
        -7.536262323809524
    ],
    [
        84.9661,
        -7.160795657142858
    ],
    [
        84.9765,
        -7.211210742857143
    ],
    [
        84.9868,
        -7.334605447619047
    ],
    [
        84.9972,
        -7.427281585714287
    ],
    [
        85.0075,
        -7.8239074380952385
    ],
    [
        85.0179,
        -7.847024195238095
    ],
    [
        85.0283,
        -8.035953895238096
    ],
    [
        85.0386,
        -8.205115566666667
    ],
    [
        85.049,
        -8.287243257142856
    ],
    [
        85.0593,
        -8.42457423809524
    ],
    [
        85.0697,
        -8.717822914285714
    ],
    [
        85.0801,
        -8.83547068095238
    ],
    [
        85.0904,
        -8.986413961904763
    ],
    [
        85.1008,
        -9.456682547619048
    ],
    [
        85.1111,
        -9.593486776190478
    ],
    [
        85.1215,
        -10.335480423809523
    ],
    [
        85.1319,
        -10.3469614
    ],
    [
        85.1422,
        -10.54301951904762
    ],
    [
        85.1526,
        -10.621128866666666
    ],
    [
        85.163,
        -10.735031676190475
    ],
    [
        85.1733,
        -10.63531878095238
    ],
    [
        85.1837,
        -10.799483861904761
    ],
    [
        85.194,
        -10.72850299047619
    ],
    [
        85.2044,
        -10.491205476190476
    ],
    [
        85.2148,
        -10.51871219047619
    ],
    [
        85.2251,
        -10.103621352380953
    ],
    [
        85.2355,
        -10.738609709523809
    ],
    [
        85.2458,
        -10.618593033333335
    ],
    [
        85.2562,
        -10.4814878
    ],
    [
        85.2666,
        -10.460922014285714
    ],
    [
        85.2769,
        -10.127100580952382
    ],
    [
        85.2873,
        -9.775318266666666
    ],
    [
        85.2977,
        -9.696972423809525
    ],
    [
        85.308,
        -9.521145771428573
    ],
    [
        85.3184,
        -9.027205733333336
    ],
    [
        85.3287,
        -9.065049571428574
    ],
    [
        85.3391,
        -8.103657452380954
    ],
    [
        85.3495,
        -8.236814961904763
    ],
    [
        85.3598,
        -7.978734523809526
    ],
    [
        85.3702,
        -8.075273242857143
    ],
    [
        85.3806,
        -7.8058021476190484
    ],
    [
        85.3909,
        -7.647434604761907
    ],
    [
        85.4013,
        -7.506566880952382
    ],
    [
        85.4116,
        -7.609395804761904
    ],
    [
        85.422,
        -7.846016071428571
    ],
    [
        85.4324,
        -7.713400361904762
    ],
    [
        85.4427,
        -7.802637833333333
    ],
    [
        85.4531,
        -7.168036471428569
    ],
    [
        85.4634,
        -7.074382657142856
    ],
    [
        85.4738,
        -6.953088747619048
    ],
    [
        85.4842,
        -6.973654533333334
    ],
    [
        85.4945,
        -7.169919233333334
    ],
    [
        85.5049,
        -7.0641523428571436
    ],
    [
        85.5153,
        -7.201257571428572
    ],
    [
        85.5256,
        -7.274706800000001
    ],
    [
        85.536,
        -7.170123185714287
    ],
    [
        85.5463,
        -7.214993990476192
    ],
    [
        85.5567,
        -7.293447333333335
    ],
    [
        85.5671,
        -7.222088766666667
    ],
    [
        85.5774,
        -7.343063971428571
    ],
    [
        85.5878,
        -7.387934776190476
    ],
    [
        85.5981,
        -7.516383342857143
    ],
    [
        85.6085,
        -7.51606463809524
    ],
    [
        85.6189,
        -7.6126033571428575
    ],
    [
        85.6292,
        -7.47534762857143
    ],
    [
        85.6396,
        -7.211414142857144
    ],
    [
        85.65,
        -7.23841379047619
    ],
    [
        85.6603,
        -7.1189328666666665
    ],
    [
        85.6707,
        -7.074694019047619
    ],
    [
        85.681,
        -7.25097217142857
    ],
    [
        85.6914,
        -7.207604352380953
    ],
    [
        85.7018,
        -7.2582278238095235
    ],
    [
        85.7121,
        -7.137544766666668
    ],
    [
        85.7225,
        -7.150533680952381
    ],
    [
        85.7328,
        -7.232796823809524
    ],
    [
        85.7432,
        -7.171099471428572
    ],
    [
        85.7536,
        -7.3266677571428565
    ],
    [
        85.7639,
        -7.1231107047619036
    ],
    [
        85.7743,
        -7.061088385714284
    ],
    [
        85.7847,
        -6.976878666666667
    ],
    [
        85.795,
        -7.014271004761905
    ],
    [
        85.8054,
        -6.794752133333333
    ],
    [
        85.8157,
        -6.750513285714285
    ],
    [
        85.8261,
        -6.675090995238094
    ],
    [
        85.8365,
        -6.637698657142856
    ],
    [
        85.8468,
        -6.7749543857142855
    ],
    [
        85.8572,
        -6.801850833333333
    ],
    [
        85.8676,
        -6.752144880952381
    ],
    [
        85.8779,
        -6.94082830952381
    ],
    [
        85.8883,
        -6.892146604761905
    ],
    [
        85.8986,
        -6.705061790476189
    ],
    [
        85.909,
        -6.748907666666666
    ],
    [
        85.9194,
        -6.628867395238095
    ],
    [
        85.9297,
        -6.886806185714285
    ],
    [
        85.9401,
        -6.886663609523809
    ],
    [
        85.9505,
        -6.722137328571429
    ],
    [
        85.9608,
        -6.765369695238097
    ],
    [
        85.9712,
        -6.62073120952381
    ],
    [
        85.9815,
        -6.779417457142857
    ],
    [
        85.9919,
        -6.6789149809523805
    ],
    [
        86.0022,
        -6.7631247
    ],
    [
        86.0126,
        -6.643288623809523
    ],
    [
        86.023,
        -6.721784966666666
    ],
    [
        86.0333,
        -6.603097133333333
    ],
    [
        86.0437,
        -6.696984409523809
    ],
    [
        86.0541,
        -6.614360071428572
    ],
    [
        86.0644,
        -6.463107866666666
    ],
    [
        86.0748,
        -6.463107866666668
    ],
    [
        86.0851,
        -6.453353347619047
    ],
    [
        86.0955,
        -6.3570609000000005
    ],
    [
        86.1059,
        -6.303082990476192
    ],
    [
        86.1162,
        -6.901483504761906
    ],
    [
        86.1266,
        -6.870476838095239
    ],
    [
        86.137,
        -6.940254838095239
    ],
    [
        86.1473,
        -6.666044376190476
    ],
    [
        86.1577,
        -6.6661869523809525
    ],
    [
        86.168,
        -6.627474885714285
    ],
    [
        86.1784,
        -6.584107066666666
    ],
    [
        86.1888,
        -6.546392699999999
    ],
    [
        86.1991,
        -6.388025157142856
    ],
    [
        86.2095,
        -6.472096609523809
    ],
    [
        86.2198,
        -6.438140109523808
    ],
    [
        86.2302,
        -6.602576090476191
    ],
    [
        86.2406,
        -6.4328267571428555
    ],
    [
        86.2509,
        -6.517257095238095
    ],
    [
        86.2613,
        -6.45542429047619
    ],
    [
        86.2717,
        -6.479289242857143
    ],
    [
        86.282,
        -6.5479171095238105
    ],
    [
        86.2924,
        -6.548333880952382
    ],
    [
        86.3027,
        -6.607944852380954
    ],
    [
        86.3131,
        -6.432053695238096
    ],
    [
        86.3235,
        -6.5550872047619055
    ],
    [
        86.3338,
        -6.112131838095238
    ],
    [
        86.3442,
        -6.09915718095238
    ],
    [
        86.3546,
        -6.016532842857141
    ],
    [
        86.3649,
        -5.977660919047618
    ],
    [
        86.3753,
        -6.0704388952380945
    ],
    [
        86.3856,
        -6.070120190476191
    ],
    [
        86.396,
        -6.0133042380952375
    ],
    [
        86.4064,
        -6.005819076190475
    ],
    [
        86.4167,
        -5.989388052380953
    ],
    [
        86.4271,
        -5.989689052380951
    ],
    [
        86.4374,
        -5.974821266666666
    ],
    [
        86.4478,
        -5.705867342857142
    ],
    [
        86.4582,
        -5.769849785714285
    ],
    [
        86.4685,
        -5.678596795238095
    ],
    [
        86.4789,
        -5.7400909761904755
    ],
    [
        86.4893,
        -5.61188868095238
    ],
    [
        86.4996,
        -5.497561252380952
    ],
    [
        86.51,
        -5.3529227666666666
    ],
    [
        86.5203,
        -5.387199071428572
    ],
    [
        86.5307,
        -5.393727890476191
    ],
    [
        86.5411,
        -5.289390547619048
    ],
    [
        86.5514,
        -5.288973776190477
    ],
    [
        86.5618,
        -5.196698642857143
    ],
    [
        86.5722,
        -5.36077341904762
    ],
    [
        86.5825,
        -5.399946342857144
    ],
    [
        86.5929,
        -5.261871742857143
    ],
    [
        86.6032,
        -5.231770871428572
    ],
    [
        86.6136,
        -5.332273347619048
    ],
    [
        86.624,
        -5.4022136666666665
    ],
    [
        86.6343,
        -5.342668128571428
    ],
    [
        86.6447,
        -5.283057157142856
    ],
    [
        86.655,
        -5.238163476190475
    ],
    [
        86.6654,
        -5.393068719047618
    ],
    [
        86.6758,
        -5.322557457142857
    ],
    [
        86.6861,
        -5.413960947619047
    ],
    [
        86.6965,
        -5.414299571428571
    ],
    [
        86.7069,
        -5.387160371428571
    ],
    [
        86.7172,
        -5.432574776190476
    ],
    [
        86.7276,
        -5.394752047619048
    ],
    [
        86.7379,
        -5.32130281904762
    ],
    [
        86.7483,
        -5.398131600000001
    ],
    [
        86.7587,
        -5.398008466666668
    ],
    [
        86.769,
        -5.233348704761905
    ],
    [
        86.7794,
        -5.293704814285714
    ],
    [
        86.7898,
        -5.107194633333333
    ],
    [
        86.8001,
        -5.047348095238094
    ],
    [
        86.8105,
        -5.067903928571429
    ],
    [
        86.8208,
        -5.022464723809524
    ],
    [
        86.8312,
        -4.902230419047619
    ],
    [
        86.8416,
        -4.889106052380952
    ],
    [
        86.8519,
        -4.8894594
    ],
    [
        86.8623,
        -4.921378423809523
    ],
    [
        86.8726,
        -4.889585904761905
    ],
    [
        86.883,
        -4.734557528571429
    ],
    [
        86.8934,
        -4.782633385714286
    ],
    [
        86.9037,
        -4.722904628571429
    ],
    [
        86.9141,
        -4.6199628285714285
    ],
    [
        86.9244,
        -4.6825263476190475
    ],
    [
        86.9348,
        -4.6054546571428565
    ],
    [
        86.9452,
        -4.591046819047619
    ],
    [
        86.9555,
        -4.630069242857143
    ],
    [
        86.9659,
        -4.630211819047618
    ],
    [
        86.9763,
        -4.603195752380953
    ],
    [
        86.9866,
        -4.603195752380953
    ],
    [
        86.997,
        -4.571202566666666
    ],
    [
        87.0073,
        -4.529755861904762
    ],
    [
        87.0177,
        -4.57502769047619
    ],
    [
        87.0281,
        -4.510189747619047
    ],
    [
        87.0384,
        -4.624399395238095
    ],
    [
        87.0488,
        -4.6135995761904764
    ],
    [
        87.0591,
        -4.549752585714287
    ],
    [
        87.0695,
        -4.581689276190477
    ],
    [
        87.0799,
        -4.5407157285714295
    ],
    [
        87.0902,
        -4.582262766666667
    ],
    [
        87.1006,
        -4.522558166666666
    ],
    [
        87.111,
        -4.533112695238096
    ],
    [
        87.1213,
        -4.565284952380951
    ],
    [
        87.1317,
        -4.535921385714285
    ],
    [
        87.142,
        -4.465981066666666
    ],
    [
        87.1524,
        -4.488706523809523
    ],
    [
        87.1628,
        -4.4888068571428565
    ],
    [
        87.1731,
        -4.397453533333333
    ],
    [
        87.1835,
        -4.29689418095238
    ],
    [
        87.1939,
        -4.346333333333332
    ],
    [
        87.2042,
        -4.28122109047619
    ],
    [
        87.2146,
        -4.2960463142857135
    ],
    [
        87.2249,
        -4.2831517761904765
    ],
    [
        87.2353,
        -4.238115514285714
    ],
    [
        87.2457,
        -4.273573761904761
    ],
    [
        87.256,
        -4.1211605619047615
    ],
    [
        87.2664,
        -4.126199876190476
    ],
    [
        87.2767,
        -4.1135925
    ],
    [
        87.2871,
        -4.140848000000001
    ],
    [
        87.2975,
        -4.10550262857143
    ],
    [
        87.3078,
        -4.0868064619047635
    ],
    [
        87.3182,
        -4.0763542571428575
    ],
    [
        87.3286,
        -4.017917371428572
    ],
    [
        87.3389,
        -4.061285190476191
    ],
    [
        87.3493,
        -4.026126309523809
    ],
    [
        87.3596,
        -4.018894604761904
    ],
    [
        87.37,
        -3.9836621047619047
    ],
    [
        87.3804,
        -4.047273314285714
    ],
    [
        87.3907,
        -4.029092952380951
    ],
    [
        87.4011,
        -4.059711985714285
    ],
    [
        87.4115,
        -3.9582282666666666
    ],
    [
        87.4218,
        -3.9913473238095234
    ],
    [
        87.4322,
        -3.9837538047619043
    ],
    [
        87.4425,
        -3.983940633333333
    ],
    [
        87.4529,
        -3.914352033333334
    ],
    [
        87.4633,
        -3.8788937857142862
    ],
    [
        87.4736,
        -3.9080477285714297
    ],
    [
        87.484,
        -3.9030958000000004
    ],
    [
        87.4943,
        -3.9030958000000004
    ],
    [
        87.5047,
        -3.788405314285714
    ],
    [
        87.5151,
        -3.7942903714285707
    ],
    [
        87.5254,
        -3.7428759095238098
    ],
    [
        87.5358,
        -3.8129095761904765
    ],
    [
        87.5462,
        -3.790681304761905
    ],
    [
        87.5565,
        -3.650228861904762
    ],
    [
        87.5669,
        -3.673732619047619
    ],
    [
        87.5772,
        -3.6554719904761908
    ],
    [
        87.5876,
        -3.668079366666668
    ],
    [
        87.598,
        -3.5449555571428575
    ],
    [
        87.6083,
        -3.534503352380953
    ],
    [
        87.6187,
        -3.591454757142857
    ],
    [
        87.6291,
        -3.5760311
    ],
    [
        87.6394,
        -3.5764324333333337
    ],
    [
        87.6498,
        -3.513014171428571
    ],
    [
        87.6601,
        -3.513014171428572
    ],
    [
        87.6705,
        -3.5220773523809523
    ],
    [
        87.6809,
        -3.497048
    ],
    [
        87.6912,
        -3.4366267952380953
    ],
    [
        87.7016,
        -3.4535111999999994
    ],
    [
        87.7119,
        -3.428481847619047
    ],
    [
        87.7223,
        -3.4188322095238095
    ],
    [
        87.7327,
        -3.3761472380952378
    ],
    [
        87.743,
        -3.375977928571428
    ],
    [
        87.7534,
        -3.3417081999999994
    ],
    [
        87.7638,
        -3.317211404761905
    ],
    [
        87.7741,
        -3.3128123095238093
    ],
    [
        87.7845,
        -3.2816124999999996
    ],
    [
        87.7948,
        -3.2765731857142852
    ],
    [
        87.8052,
        -3.2431713714285713
    ],
    [
        87.8156,
        -3.269346004761905
    ],
    [
        87.8259,
        -3.2355248666666667
    ],
    [
        87.8363,
        -3.118624614285715
    ],
    [
        87.8466,
        -3.1154367
    ],
    [
        87.857,
        -3.073374180952381
    ],
    [
        87.8674,
        -3.096441680952381
    ],
    [
        87.8777,
        -3.056131247619048
    ],
    [
        87.8881,
        -3.051626514285714
    ],
    [
        87.8985,
        -3.021116042857143
    ],
    [
        87.9088,
        -3.0406314285714284
    ],
    [
        87.9192,
        -2.9715941285714287
    ],
    [
        87.9295,
        -2.959823566666667
    ],
    [
        87.9399,
        -2.9435806952380954
    ],
    [
        87.9503,
        -2.933711166666667
    ],
    [
        87.9606,
        -2.9093836809523816
    ],
    [
        87.971,
        -2.837064438095238
    ],
    [
        87.9813,
        -2.8248366190476193
    ],
    [
        87.9917,
        -2.7917202428571435
    ],
    [
        88.0021,
        -2.773179547619048
    ],
    [
        88.0124,
        -2.7210551476190483
    ],
    [
        88.0228,
        -2.695044695238096
    ],
    [
        88.0331,
        -2.6527751000000004
    ],
    [
        88.0435,
        -2.659080838095239
    ],
    [
        88.0539,
        -2.643851447619048
    ],
    [
        88.0642,
        -2.6245925190476194
    ],
    [
        88.0746,
        -2.5864198809523806
    ],
    [
        88.085,
        -2.5634218428571423
    ],
    [
        88.0953,
        -2.5266096476190474
    ],
    [
        88.1057,
        -2.4843400523809516
    ],
    [
        88.116,
        -2.4703553666666656
    ],
    [
        88.1264,
        -2.402609247619047
    ],
    [
        88.1368,
        -2.4101541666666657
    ],
    [
        88.1471,
        -2.3681078047619044
    ],
    [
        88.1575,
        -2.3278583047619046
    ],
    [
        88.1679,
        -2.2924188523809526
    ],
    [
        88.1782,
        -2.2774580380952383
    ],
    [
        88.1886,
        -2.2449456523809523
    ],
    [
        88.1989,
        -2.228212542857143
    ],
    [
        88.2093,
        -2.2058510238095237
    ],
    [
        88.2197,
        -2.1792043238095236
    ],
    [
        88.23,
        -2.1600646857142856
    ],
    [
        88.2404,
        -2.133450138095238
    ],
    [
        88.2507,
        -2.1032720857142855
    ],
    [
        88.2611,
        -2.061719795238095
    ],
    [
        88.2715,
        -2.0385136714285714
    ],
    [
        88.2818,
        -2.0107797190476195
    ],
    [
        88.2922,
        -1.9977488380952377
    ],
    [
        88.3026,
        -1.9495194714285713
    ],
    [
        88.3129,
        -1.9387056380952385
    ],
    [
        88.3233,
        -1.9179036142857142
    ],
    [
        88.3336,
        -1.8943502190476191
    ],
    [
        88.344,
        -1.879742895238095
    ],
    [
        88.3544,
        -1.8421634666666664
    ],
    [
        88.3647,
        -1.829069990476191
    ],
    [
        88.3751,
        -1.808578142857143
    ],
    [
        88.3855,
        -1.8041907809523814
    ],
    [
        88.3958,
        -1.7730165190476195
    ],
    [
        88.4062,
        -1.7688802857142858
    ],
    [
        88.4165,
        -1.7428382523809522
    ],
    [
        88.4269,
        -1.7302675952380953
    ],
    [
        88.4373,
        -1.7272283190476192
    ],
    [
        88.4476,
        -1.7150795190476191
    ],
    [
        88.458,
        -1.6897932619047622
    ],
    [
        88.4683,
        -1.6897932619047615
    ],
    [
        88.4787,
        -1.6806387285714284
    ],
    [
        88.4891,
        -1.6687945666666661
    ],
    [
        88.4994,
        -1.6640134428571427
    ],
    [
        88.5098,
        -1.6478594571428569
    ],
    [
        88.5202,
        -1.653257038095238
    ],
    [
        88.5305,
        -1.6376937428571425
    ],
    [
        88.5409,
        -1.6259806904761906
    ],
    [
        88.5512,
        -1.6235615761904765
    ],
    [
        88.5616,
        -1.6223033
    ],
    [
        88.572,
        -1.6197995333333333
    ],
    [
        88.5823,
        -1.6211491761904764
    ],
    [
        88.5927,
        -1.6222325333333334
    ],
    [
        88.6031,
        -1.618138680952381
    ],
    [
        88.6134,
        -1.608114785714286
    ],
    [
        88.6238,
        -1.6055904809523809
    ],
    [
        88.6341,
        -1.611672595238095
    ],
    [
        88.6445,
        -1.6071360238095243
    ],
    [
        88.6549,
        -1.6003820714285715
    ],
    [
        88.6652,
        -1.6004237476190475
    ],
    [
        88.6756,
        -1.6085393333333333
    ],
    [
        88.6859,
        -1.6096774857142866
    ],
    [
        88.6963,
        -1.6109473285714286
    ],
    [
        88.7067,
        -1.6049254571428575
    ],
    [
        88.717,
        -1.6072624809523812
    ],
    [
        88.7274,
        -1.6201725857142861
    ],
    [
        88.7377,
        -1.6070264190476191
    ],
    [
        88.7481,
        -1.6254263761904764
    ],
    [
        88.7585,
        -1.631556266666667
    ],
    [
        88.7688,
        -1.6338907238095237
    ],
    [
        88.7792,
        -1.6313220095238097
    ],
    [
        88.7896,
        -1.6337411238095239
    ],
    [
        88.7999,
        -1.6323914809523812
    ],
    [
        88.8103,
        -1.6370688476190474
    ],
    [
        88.8206,
        -1.631127838095238
    ],
    [
        88.831,
        -1.6473803333333334
    ],
    [
        88.8414,
        -1.6540408714285715
    ],
    [
        88.8517,
        -1.6458002523809525
    ],
    [
        88.8621,
        -1.666240985714286
    ],
    [
        88.8724,
        -1.666240985714286
    ],
    [
        88.8828,
        -1.675312166666667
    ],
    [
        88.8932,
        -1.6779658142857148
    ],
    [
        88.9035,
        -1.6819691047619052
    ],
    [
        88.9139,
        -1.7016275714285716
    ],
    [
        88.9243,
        -1.7103030904761907
    ],
    [
        88.9346,
        -1.714007138095238
    ],
    [
        88.945,
        -1.7172510190476191
    ],
    [
        88.9554,
        -1.741554571428571
    ],
    [
        88.9657,
        -1.7532349333333332
    ],
    [
        88.9761,
        -1.747064
    ],
    [
        88.9864,
        -1.759817823809524
    ],
    [
        88.9968,
        -1.7781459047619048
    ],
    [
        89.0072,
        -1.7861240095238098
    ],
    [
        89.0175,
        -1.8225239380952385
    ],
    [
        89.0279,
        -1.8189627619047621
    ],
    [
        89.0382,
        -1.8460246571428574
    ],
    [
        89.0486,
        -1.8442752476190476
    ],
    [
        89.059,
        -1.8523458333333334
    ],
    [
        89.0693,
        -1.8825896333333332
    ],
    [
        89.0797,
        -1.8793053809523805
    ],
    [
        89.0901,
        -1.8957743476190472
    ],
    [
        89.1004,
        -1.9094837285714286
    ],
    [
        89.1108,
        -1.9278956809523806
    ],
    [
        89.1211,
        -1.9463629190476188
    ],
    [
        89.1315,
        -1.952298804761905
    ],
    [
        89.1419,
        -1.9688056142857142
    ],
    [
        89.1522,
        -1.9866414571428574
    ],
    [
        89.1626,
        -1.9955614380952382
    ],
    [
        89.1729,
        -2.0154694
    ],
    [
        89.1833,
        -2.017405004761905
    ],
    [
        89.1937,
        -2.0454903000000004
    ],
    [
        89.204,
        -2.0652632238095245
    ],
    [
        89.2144,
        -2.0714746571428577
    ],
    [
        89.2247,
        -2.0891473428571428
    ],
    [
        89.2351,
        -2.0948065761904764
    ],
    [
        89.2455,
        -2.1130988761904765
    ],
    [
        89.2558,
        -2.1575387476190477
    ],
    [
        89.2662,
        -2.1744648142857144
    ],
    [
        89.2766,
        -2.1961106190476194
    ],
    [
        89.2869,
        -2.223099571428572
    ],
    [
        89.2973,
        -2.253809123809524
    ],
    [
        89.3076,
        -2.2685580952380953
    ],
    [
        89.318,
        -2.282019338095238
    ],
    [
        89.3284,
        -2.307902923809524
    ],
    [
        89.3387,
        -2.321895323809524
    ],
    [
        89.3491,
        -2.336633547619048
    ],
    [
        89.3595,
        -2.371190071428571
    ],
    [
        89.3698,
        -2.3959231952380953
    ],
    [
        89.3802,
        -2.420588595238095
    ],
    [
        89.3905,
        -2.4155500047619047
    ],
    [
        89.4009,
        -2.4401612238095236
    ],
    [
        89.4113,
        -2.457717380952381
    ],
    [
        89.4216,
        -2.4851083285714286
    ],
    [
        89.432,
        -2.497008742857143
    ],
    [
        89.4423,
        -2.5352587809523808
    ],
    [
        89.4527,
        -2.5413595238095237
    ],
    [
        89.4631,
        -2.607588919047619
    ],
    [
        89.4734,
        -2.5610802571428573
    ],
    [
        89.4838,
        -2.661721333333333
    ],
    [
        89.4942,
        -2.6702578142857143
    ],
    [
        89.5045,
        -2.669058295238095
    ],
    [
        89.5149,
        -2.548607138095238
    ],
    [
        89.5252,
        -2.4390433523809523
    ],
    [
        89.5356,
        -2.326866342857143
    ],
    [
        89.546,
        -2.2003076666666663
    ],
    [
        89.5563,
        -2.0855848904761904
    ],
    [
        89.5667,
        -1.9681306190476193
    ],
    [
        89.5771,
        -1.8348041476190478
    ],
    [
        89.5874,
        -1.711409438095238
    ],
    [
        89.5978,
        -1.5880824523809522
    ],
    [
        89.604,
        -1.4783982666666666
    ],
    [
        0,
        -1.3550712809523813
    ],
    [
        0,
        -1.2346862
    ],
    [
        0,
        -1.0977314666666669
    ],
    [
        0,
        -0.9829456904761903
    ],
    [
        0,
        -0.8419231666666667
    ],
    [
        0,
        -0.7155034142857143
    ],
    [
        0,
        -0.5561970476190476
    ],
    [
        0,
        -0.4575354619047619
    ],
    [
        0,
        -0.2518776142857143
    ],
    [
        0,
        -0.12861835714285713
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0.020995228571428572
    ],
    [
        0,
        0.05623798095238095
    ],
    [
        0,
        0.09840992857142858
    ],
    [
        0,
        0.1432642238095238
    ],
    [
        0,
        0.1907068476190476
    ],
    [
        0,
        0.24433599047619045
    ],
    [
        0,
        0.2936751952380952
    ],
    [
        0,
        0.3461643333333333
    ],
    [
        0,
        0.40560865238095234
    ],
    [
        0,
        0.46886655714285713
    ],
    [
        0.0104,
        0.5269119952380953
    ],
    [
        0.0207,
        0.5893682952380952
    ],
    [
        0.0311,
        0.6454362380952381
    ],
    [
        0.0414,
        0.7095131285714286
    ],
    [
        0.0518,
        0.7735915333333333
    ],
    [
        0.0622,
        0.8440778523809523
    ],
    [
        0.0725,
        0.9098645904761905
    ],
    [
        0.0829,
        0.9835067761904762
    ],
    [
        0.0933,
        1.0539920380952381
    ],
    [
        0.1036,
        1.1323081523809524
    ],
    [
        0.114,
        1.2048674428571429
    ],
    [
        0.1243,
        1.263453542857143
    ],
    [
        0.1347,
        1.3029691666666667
    ],
    [
        0.1451,
        1.3430321380952381
    ],
    [
        0.1554,
        1.3878869333333332
    ],
    [
        0.1658,
        1.4213297285714286
    ],
    [
        0.1761,
        1.441341742857143
    ],
    [
        0.1865,
        1.4947948666666666
    ],
    [
        0.1969,
        1.537189938095238
    ],
    [
        0.2072,
        1.5643077666666667
    ],
    [
        0.2176,
        1.6017433095238094
    ],
    [
        0.228,
        1.6334060000000001
    ],
    [
        0.2383,
        1.6623187000000001
    ],
    [
        0.2487,
        1.7090437476190476
    ],
    [
        0.259,
        1.7456605190476193
    ],
    [
        0.2694,
        1.799056542857143
    ],
    [
        0.2798,
        1.8253176047619046
    ],
    [
        0.2901,
        1.8667915047619048
    ],
    [
        0.3005,
        1.8938421190476191
    ],
    [
        0.3108,
        1.9182420809523812
    ],
    [
        0.3212,
        1.9427174142857142
    ],
    [
        0.3316,
        1.975134819047619
    ],
    [
        0.3419,
        1.9942349714285714
    ],
    [
        0.3523,
        2.039818319047619
    ],
    [
        0.3626,
        2.060374404761905
    ],
    [
        0.373,
        2.094017695238095
    ],
    [
        0.3834,
        2.1227731952380955
    ],
    [
        0.3937,
        2.1824842476190476
    ],
    [
        0.4041,
        2.1869511761904765
    ],
    [
        0.4145,
        2.225418604761905
    ],
    [
        0.4248,
        2.265371176190476
    ],
    [
        0.4352,
        2.279421176190476
    ],
    [
        0.4455,
        2.326766547619047
    ],
    [
        0.4559,
        2.358747814285714
    ],
    [
        0.4663,
        2.376297576190476
    ],
    [
        0.4766,
        2.4021164619047615
    ],
    [
        0.487,
        2.434154938095238
    ],
    [
        0.4973,
        2.470762628571429
    ],
    [
        0.5077,
        2.473149961904762
    ],
    [
        0.5181,
        2.5023001047619045
    ],
    [
        0.5284,
        2.527757633333333
    ],
    [
        0.5388,
        2.5548091285714283
    ],
    [
        0.5492,
        2.5831812095238096
    ],
    [
        0.5595,
        2.643660676190476
    ],
    [
        0.5699,
        2.6498331285714287
    ],
    [
        0.5802,
        2.692158838095238
    ],
    [
        0.5906,
        2.6986494
    ],
    [
        0.601,
        2.7260637428571424
    ],
    [
        0.6113,
        2.7422263619047618
    ],
    [
        0.6217,
        2.784485142857142
    ],
    [
        0.632,
        2.7714741952380955
    ],
    [
        0.6424,
        2.804123157142857
    ],
    [
        0.6528,
        2.8655928619047617
    ],
    [
        0.6631,
        2.865592861904762
    ],
    [
        0.6735,
        2.849503695238095
    ],
    [
        0.6839,
        2.8742813904761904
    ],
    [
        0.6942,
        2.923981995238095
    ],
    [
        0.7046,
        2.923983280952381
    ],
    [
        0.7149,
        2.960769423809524
    ],
    [
        0.7253,
        2.9962394095238096
    ],
    [
        0.7357,
        3.0491376
    ],
    [
        0.746,
        3.0491386333333335
    ],
    [
        0.7564,
        3.056349190476191
    ],
    [
        0.7667,
        3.0639721428571427
    ],
    [
        0.7771,
        3.102171619047619
    ],
    [
        0.7875,
        3.105499828571429
    ],
    [
        0.7978,
        3.13052140952381
    ],
    [
        0.8082,
        3.127190471428572
    ],
    [
        0.8186,
        3.195715457142857
    ],
    [
        0.8289,
        3.191316095238095
    ],
    [
        0.8393,
        3.1621408095238093
    ],
    [
        0.8496,
        3.178858319047619
    ],
    [
        0.86,
        3.164813842857143
    ],
    [
        0.8704,
        3.138118728571429
    ],
    [
        0.8807,
        3.1655308380952385
    ],
    [
        0.8911,
        3.282541023809524
    ],
    [
        0.9014,
        3.3430078761904762
    ],
    [
        0.9118,
        3.372374971428571
    ],
    [
        0.9222,
        3.377045966666667
    ],
    [
        0.9325,
        3.4124814714285723
    ],
    [
        0.9429,
        3.5023143857142855
    ],
    [
        0.9532,
        3.543844538095238
    ],
    [
        0.9636,
        3.613265504761905
    ],
    [
        0.974,
        3.652431204761905
    ],
    [
        0.9843,
        3.7357365333333337
    ],
    [
        0.9947,
        3.735734838095238
    ],
    [
        1.0051,
        3.7510104095238095
    ],
    [
        1.0154,
        3.7400323809523806
    ],
    [
        1.0258,
        3.750573876190476
    ],
    [
        1.0361,
        3.7041552619047615
    ],
    [
        1.0465,
        3.7000103190476183
    ],
    [
        1.0569,
        3.7167222380952385
    ],
    [
        1.0672,
        3.733850547619048
    ],
    [
        1.0776,
        3.742919171428572
    ],
    [
        1.0879,
        3.742914038095239
    ],
    [
        1.0983,
        3.727966466666667
    ],
    [
        1.1087,
        3.75039570952381
    ],
    [
        1.119,
        3.853178780952381
    ],
    [
        1.1294,
        3.8718678952380956
    ],
    [
        1.1398,
        3.9150380523809525
    ],
    [
        1.1501,
        3.9444101857142857
    ],
    [
        1.1605,
        3.8795961523809517
    ],
    [
        1.1708,
        3.8254602428571434
    ],
    [
        1.1812,
        3.8330508333333335
    ],
    [
        1.1916,
        3.8395743333333328
    ],
    [
        1.2019,
        3.7980384095238087
    ],
    [
        1.2123,
        3.8473717428571437
    ],
    [
        1.2226,
        3.8849964857142862
    ],
    [
        1.233,
        3.9501055809523806
    ],
    [
        1.2434,
        4.018639380952381
    ],
    [
        1.2537,
        4.042217314285715
    ],
    [
        1.2641,
        4.0986122952380954
    ],
    [
        1.2745,
        4.111070528571428
    ],
    [
        1.2848,
        4.2471224238095235
    ],
    [
        1.2952,
        4.307453114285714
    ],
    [
        1.3055,
        4.303060657142857
    ],
    [
        1.3159,
        4.329754257142857
    ],
    [
        1.3263,
        4.411990590476189
    ],
    [
        1.3366,
        4.309200466666666
    ],
    [
        1.347,
        4.299453304761904
    ],
    [
        1.3573,
        4.266570623809523
    ],
    [
        1.3677,
        4.291304309523809
    ],
    [
        1.3781,
        4.285637314285715
    ],
    [
        1.3884,
        4.3397693761904765
    ],
    [
        1.3988,
        4.356936233333333
    ],
    [
        1.4091,
        4.363966428571429
    ],
    [
        1.4195,
        4.416178180952381
    ],
    [
        1.4299,
        4.366846542857142
    ],
    [
        1.4402,
        4.492474214285714
    ],
    [
        1.4506,
        4.457967976190476
    ],
    [
        1.461,
        4.422521890476189
    ],
    [
        1.4713,
        4.592217699999999
    ],
    [
        1.4817,
        4.600432442857143
    ],
    [
        1.492,
        4.665445419047619
    ],
    [
        1.5024,
        4.580794314285713
    ],
    [
        1.5128,
        4.549016928571429
    ],
    [
        1.5231,
        4.650601242857142
    ],
    [
        1.5335,
        4.748501304761904
    ],
    [
        1.5438,
        4.654521357142857
    ],
    [
        1.5542,
        4.72305280952381
    ],
    [
        1.5646,
        4.816900980952381
    ],
    [
        1.5749,
        4.926535314285714
    ],
    [
        1.5853,
        4.881378657142856
    ],
    [
        1.5957,
        5.128076361904762
    ],
    [
        1.606,
        5.138761809523809
    ],
    [
        1.6164,
        5.094372066666665
    ],
    [
        1.6267,
        5.178715571428571
    ],
    [
        1.6371,
        5.168035895238095
    ],
    [
        1.6475,
        5.129839833333333
    ],
    [
        1.6578,
        5.04574267142857
    ],
    [
        1.6682,
        5.061563323809524
    ],
    [
        1.6785,
        5.006166542857143
    ],
    [
        1.6889,
        4.913415933333333
    ],
    [
        1.6993,
        4.981952085714286
    ],
    [
        1.7096,
        4.90839551904762
    ],
    [
        1.72,
        5.082340980952381
    ],
    [
        1.7304,
        5.0758114857142855
    ],
    [
        1.7407,
        5.011854128571428
    ],
    [
        1.7511,
        4.984442957142856
    ],
    [
        1.7614,
        4.984434895238095
    ],
    [
        1.7718,
        4.9570279571428575
    ],
    [
        1.7822,
        4.8729346428571425
    ],
    [
        1.7925,
        5.092228704761904
    ],
    [
        1.8029,
        5.067465947619048
    ],
    [
        1.8132,
        5.035837676190477
    ],
    [
        1.8236,
        5.109260452380953
    ],
    [
        1.834,
        5.268067676190477
    ],
    [
        1.8443,
        5.240651804761906
    ],
    [
        1.8547,
        5.427546142857143
    ],
    [
        1.8651,
        5.492654990476191
    ],
    [
        1.8754,
        5.716928195238095
    ],
    [
        1.8858,
        5.863785838095238
    ],
    [
        1.8961,
        5.9388077857142845
    ],
    [
        1.9065,
        6.090290566666665
    ],
    [
        1.9169,
        6.090281161904761
    ],
    [
        1.9272,
        6.163841409523808
    ],
    [
        1.9376,
        6.1132370428571425
    ],
    [
        1.9479,
        6.1426091809523795
    ],
    [
        1.9583,
        6.233968771428572
    ],
    [
        1.9687,
        6.398428738095238
    ],
    [
        1.979,
        6.3462210190476185
    ],
    [
        1.9894,
        6.346216785714285
    ],
    [
        1.9998,
        6.327527028571429
    ],
    [
        2.0101,
        6.04864449047619
    ],
    [
        2.0205,
        6.118561676190475
    ],
    [
        2.0308,
        6.287226985714286
    ],
    [
        2.0412,
        6.176215133333334
    ],
    [
        2.0516,
        6.044635876190476
    ],
    [
        2.0619,
        6.177474623809522
    ],
    [
        2.0723,
        5.980821914285714
    ],
    [
        2.0826,
        6.016233471428571
    ],
    [
        2.093,
        5.765044095238094
    ],
    [
        2.1034,
        5.720978338095239
    ],
    [
        2.1137,
        5.883635238095238
    ],
    [
        2.1241,
        5.74657939047619
    ],
    [
        2.1344,
        5.780842766666666
    ],
    [
        2.1448,
        5.9458524809523805
    ],
    [
        2.1552,
        5.925299747619047
    ],
    [
        2.1655,
        5.943989180952381
    ],
    [
        2.1759,
        5.904827009523809
    ],
    [
        2.1863,
        5.783912390476191
    ],
    [
        2.1966,
        5.891402195238095
    ],
    [
        2.207,
        5.918813366666667
    ],
    [
        2.2173,
        5.987337766666665
    ],
    [
        2.2277,
        6.12523648095238
    ],
    [
        2.2381,
        6.139681980952381
    ],
    [
        2.2484,
        5.899842476190476
    ],
    [
        2.2588,
        5.8852198
    ],
    [
        2.2691,
        6.0403068428571425
    ],
    [
        2.2795,
        5.969122061904763
    ],
    [
        2.2899,
        6.028728328571428
    ],
    [
        2.3002,
        6.043150557142857
    ],
    [
        2.3106,
        6.15416918095238
    ],
    [
        2.321,
        6.070066728571428
    ],
    [
        2.3313,
        6.046577485714285
    ],
    [
        2.3417,
        6.080841447619047
    ],
    [
        2.352,
        6.019171604761904
    ],
    [
        2.3624,
        5.929863266666666
    ],
    [
        2.3728,
        5.8955993047619035
    ],
    [
        2.3831,
        6.288077419047617
    ],
    [
        2.3935,
        6.382072276190475
    ],
    [
        2.4038,
        6.382057338095238
    ],
    [
        2.4142,
        6.400196771428571
    ],
    [
        2.4246,
        6.385769838095238
    ],
    [
        2.4349,
        6.560219947619048
    ],
    [
        2.4453,
        6.498033652380952
    ],
    [
        2.4557,
        6.4481772047619055
    ],
    [
        2.466,
        6.41391911904762
    ],
    [
        2.4764,
        6.560123314285715
    ],
    [
        2.4867,
        6.536609885714286
    ],
    [
        2.4971,
        6.518486328571428
    ],
    [
        2.5075,
        6.491061519047618
    ],
    [
        2.5178,
        6.596487285714285
    ],
    [
        2.5282,
        6.523072571428571
    ],
    [
        2.5385,
        6.747330385714285
    ],
    [
        2.5489,
        6.829549785714286
    ],
    [
        2.5593,
        6.7952858238095235
    ],
    [
        2.5696,
        6.8227064
    ],
    [
        2.58,
        7.080689452380953
    ],
    [
        2.5903,
        7.003938176190475
    ],
    [
        2.6007,
        6.766713104761903
    ],
    [
        2.6111,
        6.766707461904763
    ],
    [
        2.6214,
        6.856028823809523
    ],
    [
        2.6318,
        7.041051042857142
    ],
    [
        2.6422,
        6.995884795238094
    ],
    [
        2.6525,
        6.95849759047619
    ],
    [
        2.6629,
        7.116797676190476
    ],
    [
        2.6732,
        7.152208623809524
    ],
    [
        2.6836,
        7.137781938095237
    ],
    [
        2.694,
        7.795621795238095
    ],
    [
        2.7043,
        7.642899395238095
    ],
    [
        2.7147,
        8.057551300000002
    ],
    [
        2.725,
        8.119220085714288
    ],
    [
        2.7354,
        7.963964380952383
    ],
    [
        2.7458,
        8.08141461904762
    ],
    [
        2.7561,
        8.012422871428571
    ],
    [
        2.7665,
        7.798633242857143
    ],
    [
        2.7769,
        7.9040608190476185
    ],
    [
        2.7872,
        8.12334077142857
    ],
    [
        2.7976,
        7.849238480952382
    ],
    [
        2.8079,
        7.898570119047618
    ],
    [
        2.8183,
        7.93023258095238
    ],
    [
        2.8287,
        8.306157195238095
    ],
    [
        2.839,
        8.186289661904762
    ],
    [
        2.8494,
        8.104057566666667
    ],
    [
        2.8597,
        8.382957038095238
    ],
    [
        2.8701,
        8.231471657142857
    ],
    [
        2.8805,
        8.111869028571428
    ],
    [
        2.8908,
        8.142409695238094
    ],
    [
        2.9012,
        8.376138909523808
    ],
    [
        2.9116,
        7.800514219047619
    ],
    [
        2.9219,
        7.914533519047619
    ],
    [
        2.9323,
        7.757864666666666
    ],
    [
        2.9426,
        7.709188809523808
    ],
    [
        2.953,
        7.837347128571428
    ],
    [
        2.9634,
        8.101676685714285
    ],
    [
        2.9737,
        8.133312014285714
    ],
    [
        2.9841,
        8.182644499999999
    ],
    [
        2.9944,
        8.351338028571428
    ],
    [
        3.0048,
        8.132043966666666
    ],
    [
        3.0152,
        8.474651842857142
    ],
    [
        3.0255,
        8.50207618095238
    ],
    [
        3.0359,
        8.419804576190476
    ],
    [
        3.0462,
        8.043885604761904
    ],
    [
        3.0566,
        8.489023209523808
    ],
    [
        3.067,
        8.406802395238092
    ],
    [
        3.0773,
        8.242312799999997
    ],
    [
        3.0877,
        8.43117769047619
    ],
    [
        3.0981,
        8.39247027142857
    ],
    [
        3.1084,
        8.481808104761905
    ],
    [
        3.1188,
        8.24807889047619
    ],
    [
        3.1291,
        8.285457114285714
    ],
    [
        3.1395,
        8.2549154
    ],
    [
        3.1499,
        7.996932347619049
    ],
    [
        3.1602,
        8.11678890952381
    ],
    [
        3.1706,
        8.116776814285714
    ],
    [
        3.1809,
        7.879568833333335
    ],
    [
        3.1913,
        7.797345200000002
    ],
    [
        3.2017,
        7.9301686428571445
    ],
    [
        3.212,
        7.761481623809526
    ],
    [
        3.2224,
        7.734070923809523
    ],
    [
        3.2328,
        8.104136533333334
    ],
    [
        3.2431,
        8.120270990476191
    ],
    [
        3.2535,
        8.099713671428571
    ],
    [
        3.2638,
        8.181948595238095
    ],
    [
        3.2742,
        8.02535902857143
    ],
    [
        3.2846,
        8.048830533333334
    ],
    [
        3.2949,
        8.268134942857143
    ],
    [
        3.3053,
        8.109821723809524
    ],
    [
        3.3156,
        8.199146533333334
    ],
    [
        3.326,
        8.093704123809525
    ],
    [
        3.3364,
        8.538846185714286
    ],
    [
        3.3467,
        8.912656671428572
    ],
    [
        3.3571,
        8.87723680952381
    ],
    [
        3.3675,
        9.035559985714286
    ],
    [
        3.3778,
        9.008449576190477
    ],
    [
        3.3882,
        9.104534233333332
    ],
    [
        3.3985,
        9.341784538095236
    ],
    [
        3.4089,
        9.303064504761902
    ],
    [
        3.4193,
        9.372083485714285
    ],
    [
        3.4296,
        9.321477380952379
    ],
    [
        3.44,
        9.42719227142857
    ],
    [
        3.4503,
        8.98863989047619
    ],
    [
        3.4607,
        8.988619971428571
    ],
    [
        3.4711,
        9.173686638095237
    ],
    [
        3.4814,
        9.379252776190476
    ],
    [
        3.4918,
        9.242180466666666
    ],
    [
        3.5022,
        9.269315066666666
    ],
    [
        3.5125,
        9.132228647619046
    ],
    [
        3.5229,
        9.664336957142856
    ],
    [
        3.5332,
        9.558888761904761
    ],
    [
        3.5436,
        9.637219828571428
    ],
    [
        3.554,
        9.261325852380951
    ],
    [
        3.5643,
        8.932369238095236
    ],
    [
        3.5747,
        8.998310895238095
    ],
    [
        3.585,
        9.166539847619045
    ],
    [
        3.5954,
        9.060800766666663
    ],
    [
        3.6058,
        9.317139390476186
    ],
    [
        3.6161,
        9.522733747619046
    ],
    [
        3.6265,
        9.522733747619046
    ],
    [
        3.6368,
        9.403123423809522
    ],
    [
        3.6472,
        9.522753247619047
    ],
    [
        3.6576,
        9.718537242857142
    ],
    [
        3.6679,
        9.52272771904762
    ],
    [
        3.6783,
        9.612029542857144
    ],
    [
        3.6887,
        9.73536499047619
    ],
    [
        3.699,
        9.392740647619048
    ],
    [
        3.7094,
        9.474972742857142
    ],
    [
        3.7197,
        9.474972742857144
    ],
    [
        3.7301,
        9.76864872857143
    ],
    [
        3.7405,
        9.170576580952382
    ],
    [
        3.7508,
        9.601349738095237
    ],
    [
        3.7612,
        9.742294861904762
    ],
    [
        3.7715,
        9.82452696190476
    ],
    [
        3.7819,
        9.947875114285713
    ],
    [
        3.7923,
        10.010111561904761
    ],
    [
        3.8026,
        9.701698866666666
    ],
    [
        3.813,
        9.90354129047619
    ],
    [
        3.8234,
        10.021015714285715
    ],
    [
        3.8337,
        9.609798785714284
    ],
    [
        3.8441,
        9.672003223809522
    ],
    [
        3.8544,
        9.836467419047619
    ],
    [
        3.8648,
        9.740333290476192
    ],
    [
        3.8752,
        10.178923295238095
    ],
    [
        3.8855,
        10.086180323809524
    ],
    [
        3.8959,
        10.015039095238095
    ],
    [
        3.9062,
        11.042862728571427
    ],
    [
        3.9166,
        11.121181704761904
    ],
    [
        3.927,
        11.076312442857143
    ],
    [
        3.9373,
        11.10795537142857
    ],
    [
        3.9477,
        10.782652738095237
    ],
    [
        3.9581,
        11.175116423809522
    ],
    [
        3.9684,
        10.744371485714286
    ],
    [
        3.9788,
        10.74438841904762
    ],
    [
        3.9891,
        10.744388419047619
    ],
    [
        3.9995,
        10.6210572
    ],
    [
        4.0099,
        10.816804914285715
    ],
    [
        4.0202,
        10.956988657142857
    ],
    [
        4.0306,
        11.001859461904761
    ],
    [
        4.0409,
        10.79627921904762
    ],
    [
        4.0513,
        10.644807947619048
    ],
    [
        4.0617,
        11.114665338095238
    ],
    [
        4.072,
        10.95021242857143
    ],
    [
        4.0824,
        11.04631577142857
    ],
    [
        4.0927,
        10.43907637142857
    ],
    [
        4.1031,
        11.166147385714286
    ],
    [
        4.1135,
        11.306309966666666
    ],
    [
        4.1238,
        10.014159804761904
    ],
    [
        4.1342,
        10.366570990476191
    ],
    [
        4.1446,
        10.297593447619048
    ],
    [
        4.1549,
        10.297551119047618
    ],
    [
        4.1653,
        10.534814447619047
    ],
    [
        4.1756,
        10.226433495238094
    ],
    [
        4.186,
        10.500540490476189
    ],
    [
        4.1964,
        10.45568508095238
    ],
    [
        4.2067,
        10.304213809523807
    ],
    [
        4.2171,
        10.797589471428571
    ],
    [
        4.2274,
        10.557754080952382
    ],
    [
        4.2378,
        10.814070714285714
    ],
    [
        4.2482,
        10.731835795238094
    ],
    [
        4.2585,
        10.819917423809523
    ],
    [
        4.2689,
        11.265098947619046
    ],
    [
        4.2793,
        10.689502476190475
    ],
    [
        4.2896,
        11.594044276190475
    ],
    [
        4.3,
        11.55669427142857
    ],
    [
        4.3103,
        11.79386806190476
    ],
    [
        4.3207,
        11.097357633333333
    ],
    [
        4.3311,
        15.583068071428572
    ],
    [
        4.3414,
        15.465573485714284
    ],
    [
        4.3518,
        19.694991476190474
    ],
    [
        4.3621,
        19.726592076190475
    ],
    [
        4.3725,
        19.66788106666667
    ],
    [
        4.3829,
        19.75596874761905
    ],
    [
        4.3932,
        19.776537352380956
    ],
    [
        4.4036,
        19.607837309523813
    ],
    [
        4.414,
        19.864129757142862
    ],
    [
        4.4243,
        20.30931128095239
    ],
    [
        4.4347,
        19.596623685714295
    ],
    [
        4.445,
        19.288263895238103
    ],
    [
        4.4554,
        18.962912885714296
    ],
    [
        4.4658,
        18.962926995238107
    ],
    [
        4.4761,
        18.87482420000001
    ],
    [
        4.4865,
        18.87480001428572
    ],
    [
        4.4968,
        19.07665783333334
    ],
    [
        4.5072,
        18.459895928571434
    ],
    [
        4.5176,
        18.542116738095245
    ],
    [
        4.5279,
        18.418789752380956
    ],
    [
        4.5383,
        18.950869842857145
    ],
    [
        4.5486,
        14.721451852380953
    ],
    [
        4.559,
        14.935065176190475
    ],
    [
        4.5694,
        10.617541366666666
    ],
    [
        4.5797,
        10.585940766666667
    ],
    [
        4.5901,
        10.726897980952382
    ],
    [
        4.6005,
        10.570222414285714
    ],
    [
        4.6108,
        10.946213142857143
    ],
    [
        4.6212,
        11.015219100000001
    ],
    [
        4.6315,
        10.8037482
    ],
    [
        4.6419,
        10.647110257142858
    ],
    [
        4.6523,
        10.866439123809524
    ],
    [
        4.6626,
        11.277585509523808
    ],
    [
        4.673,
        11.720386757142856
    ],
    [
        4.6833,
        11.661633419047618
    ],
    [
        4.6937,
        11.373853523809522
    ],
    [
        4.7041,
        11.902488442857143
    ],
    [
        4.7144,
        12.070675071428571
    ],
    [
        4.7248,
        11.806390871428569
    ],
    [
        4.7352,
        11.665392533333332
    ],
    [
        4.7455,
        16.106264495238094
    ],
    [
        4.7559,
        15.592324461904761
    ],
    [
        4.7662,
        15.874195361904762
    ],
    [
        4.7766,
        16.04244548095238
    ],
    [
        4.787,
        15.80522447619048
    ],
    [
        4.7973,
        16.130525252380956
    ],
    [
        4.8077,
        16.08565599047619
    ],
    [
        4.818,
        15.889908276190479
    ],
    [
        4.8284,
        15.537424528571432
    ],
    [
        4.8388,
        15.37910678571429
    ],
    [
        4.8491,
        19.81999567619048
    ],
    [
        4.8595,
        19.62417405714286
    ],
    [
        4.8699,
        19.953068585714288
    ],
    [
        4.8802,
        20.77544600952381
    ],
    [
        4.8906,
        20.261495395238097
    ],
    [
        4.9009,
        20.525779595238095
    ],
    [
        4.9113,
        20.60802862857143
    ],
    [
        4.9217,
        21.019132685714286
    ],
    [
        4.932,
        20.75482731904762
    ],
    [
        4.9424,
        20.895784533333334
    ],
    [
        4.9527,
        20.991912066666668
    ],
    [
        4.9631,
        16.55105703809524
    ],
    [
        4.9735,
        16.790871266666667
    ],
    [
        4.9838,
        18.271286080952382
    ],
    [
        4.9942,
        17.928579438095237
    ],
    [
        5.0045,
        18.535844885714287
    ],
    [
        5.0149,
        18.242172928571428
    ],
    [
        5.0253,
        18.287042190476193
    ],
    [
        5.0356,
        18.34578343809524
    ],
    [
        5.046,
        18.98013324761905
    ],
    [
        5.0564,
        18.980123290476197
    ],
    [
        5.0667,
        14.750729485714286
    ],
    [
        5.0771,
        15.10318904761905
    ],
    [
        5.0874,
        15.514307214285717
    ],
    [
        5.0978,
        15.103132609523808
    ],
    [
        5.1082,
        15.243285566666668
    ],
    [
        5.1185,
        15.119975514285713
    ],
    [
        5.1289,
        15.257000790476193
    ],
    [
        5.1392,
        15.25705722857143
    ],
    [
        5.1496,
        15.315824676190479
    ],
    [
        5.16,
        15.174843271428571
    ],
    [
        5.1703,
        15.548625538095239
    ],
    [
        5.1807,
        16.042035061904762
    ],
    [
        5.1911,
        15.98723247142857
    ],
    [
        5.2014,
        15.164685733333334
    ],
    [
        5.2118,
        15.438820947619046
    ],
    [
        5.2221,
        14.945462219047618
    ],
    [
        5.2325,
        14.886698799999998
    ],
    [
        5.2429,
        16.037948185714285
    ],
    [
        5.2532,
        15.873464233333332
    ],
    [
        5.2636,
        12.419716085714285
    ],
    [
        5.2739,
        12.622880728571431
    ],
    [
        5.2843,
        12.4114195047619
    ],
    [
        5.2947,
        12.254800376190477
    ],
    [
        5.305,
        11.726183595238094
    ],
    [
        5.3154,
        11.890619576190474
    ],
    [
        5.3258,
        12.428881433333334
    ],
    [
        5.3361,
        12.483723528571431
    ],
    [
        5.3465,
        12.640307723809526
    ],
    [
        5.3568,
        11.612469980952383
    ],
    [
        5.3672,
        11.50963752857143
    ],
    [
        5.3776,
        12.143977666666668
    ],
    [
        5.3879,
        11.938439747619048
    ],
    [
        5.3983,
        11.400174814285714
    ],
    [
        5.4086,
        11.400191747619049
    ],
    [
        5.419,
        11.400191747619049
    ],
    [
        5.4294,
        11.300497661904764
    ],
    [
        5.4397,
        11.300514590476192
    ],
    [
        5.4501,
        11.359235680952382
    ],
    [
        5.4604,
        10.207986295238097
    ],
    [
        5.4708,
        10.783658957142858
    ],
    [
        5.4812,
        13.867320333333334
    ],
    [
        5.4915,
        13.702895642857143
    ],
    [
        5.5019,
        8.275390880952381
    ],
    [
        5.5123,
        8.220514923809526
    ],
    [
        5.5226,
        8.33801353809524
    ],
    [
        5.533,
        8.584752161904763
    ],
    [
        5.5433,
        8.009141580952381
    ],
    [
        5.5537,
        7.909428680952382
    ],
    [
        5.5641,
        7.584163257142858
    ],
    [
        5.5744,
        7.789708228571429
    ],
    [
        5.5848,
        8.098057433333334
    ],
    [
        5.5951,
        7.728089176190475
    ],
    [
        5.6055,
        7.6595012904761886
    ],
    [
        5.6159,
        7.210947176190477
    ],
    [
        5.6262,
        7.097070414285715
    ],
    [
        5.6366,
        5.804908157142857
    ],
    [
        5.647,
        7.823417057142858
    ],
    [
        5.6573,
        7.878206476190477
    ],
    [
        5.6677,
        8.171872385714286
    ],
    [
        5.678,
        8.17188931904762
    ],
    [
        5.6884,
        8.336297080952383
    ],
    [
        5.6988,
        8.424445228571429
    ],
    [
        5.7091,
        13.029589495238096
    ],
    [
        5.7195,
        18.25392562857143
    ],
    [
        5.7298,
        18.465420714285717
    ],
    [
        5.7402,
        19.287741704761906
    ],
    [
        5.7506,
        18.60241307619048
    ],
    [
        5.7609,
        19.178125242857146
    ],
    [
        5.7713,
        19.34628493809524
    ],
    [
        5.7817,
        19.415337071428574
    ],
    [
        5.792,
        19.29783442857143
    ],
    [
        5.8024,
        19.060621566666672
    ],
    [
        5.8127,
        19.677362309523815
    ],
    [
        5.8231,
        19.83403787619048
    ],
    [
        5.8335,
        20.32743046666667
    ],
    [
        5.8438,
        19.947897704761903
    ],
    [
        5.8542,
        19.97502020952381
    ],
    [
        5.8645,
        18.124734495238094
    ],
    [
        5.8749,
        18.069877352380953
    ],
    [
        5.8853,
        19.831957471428574
    ],
    [
        5.8956,
        24.27298180952381
    ],
    [
        5.906,
        23.594556414285712
    ],
    [
        5.9163,
        24.123149004761906
    ],
    [
        5.9267,
        19.54151332857143
    ],
    [
        5.9371,
        20.238023757142855
    ],
    [
        5.9474,
        20.51997205714286
    ],
    [
        5.9578,
        19.286476466666667
    ],
    [
        5.9682,
        13.80418604761905
    ],
    [
        5.9785,
        13.265853390476192
    ],
    [
        5.9889,
        14.293853390476194
    ],
    [
        5.9992,
        14.293822609523813
    ],
    [
        6.0096,
        14.205695623809525
    ],
    [
        6.02,
        14.64848167619048
    ],
    [
        6.0303,
        14.401844638095243
    ],
    [
        6.0407,
        14.51926665714286
    ],
    [
        6.051,
        14.73069643809524
    ],
    [
        6.0614,
        15.717515485714289
    ],
    [
        6.0718,
        15.690405076190482
    ],
    [
        6.0821,
        16.30716698095238
    ],
    [
        6.0925,
        16.3619865
    ],
    [
        6.1029,
        14.881927242857143
    ],
    [
        6.1132,
        10.769786147619046
    ],
    [
        6.1236,
        10.840972142857142
    ],
    [
        6.1339,
        10.429882195238095
    ],
    [
        6.1443,
        11.310861785714286
    ],
    [
        6.1547,
        10.32411046190476
    ],
    [
        6.165,
        9.748437800000001
    ],
    [
        6.1754,
        9.95401099047619
    ],
    [
        6.1857,
        17.35532316190476
    ],
    [
        6.1961,
        17.23566358095238
    ],
    [
        6.2065,
        15.590965166666669
    ],
    [
        6.2168,
        15.494846428571432
    ],
    [
        6.2272,
        19.81222209047619
    ],
    [
        6.2376,
        19.976680647619048
    ],
    [
        6.2479,
        19.606513452380952
    ],
    [
        6.2583,
        20.017744495238095
    ],
    [
        6.2686,
        19.761476238095238
    ],
    [
        6.279,
        20.419242728571426
    ],
    [
        6.2894,
        21.711505766666665
    ],
    [
        6.2997,
        21.464801004761906
    ],
    [
        6.3101,
        21.2068268
    ],
    [
        6.3204,
        21.042413395238096
    ],
    [
        6.3308,
        21.206871952380954
    ],
    [
        6.3412,
        23.294373785714285
    ],
    [
        6.3515,
        23.705463733333335
    ],
    [
        6.3619,
        22.780405533333333
    ],
    [
        6.3722,
        23.76722458095238
    ],
    [
        6.3826,
        24.34282951904762
    ],
    [
        6.393,
        26.193157561904762
    ],
    [
        6.4033,
        24.21935015238095
    ],
    [
        6.4137,
        28.824550857142857
    ],
    [
        6.4241,
        29.153490538095237
    ],
    [
        6.4344,
        23.86729854761905
    ],
    [
        6.4448,
        19.63802568095238
    ],
    [
        6.4551,
        19.356145104761907
    ],
    [
        6.4655,
        19.356187433333336
    ],
    [
        6.4759,
        19.76747490952381
    ],
    [
        6.4862,
        19.671307795238096
    ],
    [
        6.4966,
        22.960817495238096
    ],
    [
        6.5069,
        21.764673195238096
    ],
    [
        6.5173,
        21.60020335238095
    ],
    [
        6.5277,
        19.665283961904763
    ],
    [
        6.538,
        19.222424104761906
    ],
    [
        6.5484,
        16.59120011904762
    ],
    [
        6.5588,
        14.535327104761908
    ],
    [
        6.5691,
        14.535496419047618
    ],
    [
        6.5795,
        14.675638795238093
    ],
    [
        6.5898,
        14.675571071428564
    ],
    [
        6.6002,
        18.622914985714285
    ],
    [
        6.6106,
        16.60442148095238
    ],
    [
        6.6209,
        18.578059576190476
    ],
    [
        6.6313,
        14.877403490476189
    ],
    [
        6.6416,
        15.041873333333331
    ],
    [
        6.652,
        15.041873333333331
    ],
    [
        6.6624,
        14.830460485714283
    ],
    [
        6.6727,
        15.112408785714287
    ],
    [
        6.6831,
        15.20046925238095
    ],
    [
        6.6935,
        11.088610347619047
    ],
    [
        6.7038,
        11.147397952380953
    ],
    [
        6.7142,
        7.446826523809524
    ],
    [
        6.7245,
        7.703143157142857
    ],
    [
        6.7349,
        5.647439457142858
    ],
    [
        6.7453,
        7.671709704761906
    ],
    [
        6.7556,
        12.226259157142858
    ],
    [
        6.766,
        15.10417097142857
    ],
    [
        6.7763,
        15.186405890476191
    ],
    [
        6.7867,
        14.401351538095238
    ],
    [
        6.7971,
        16.419706514285718
    ],
    [
        6.8074,
        16.419774238095243
    ],
    [
        6.8178,
        12.307971766666666
    ],
    [
        6.8282,
        12.564336776190476
    ],
    [
        6.8385,
        10.919525490476188
    ],
    [
        6.8489,
        10.234356766666664
    ],
    [
        6.8592,
        8.096361704761902
    ],
    [
        6.8696,
        13.523527842857144
    ],
    [
        6.88,
        13.73498906666667
    ],
    [
        6.8903,
        11.514730866666667
    ],
    [
        6.9007,
        11.189454280952381
    ],
    [
        6.911,
        13.656501900000002
    ],
    [
        6.9214,
        13.950198042857147
    ],
    [
        6.9318,
        13.264907038095238
    ],
    [
        6.9421,
        12.560036290476189
    ],
    [
        6.9525,
        15.026829938095242
    ],
    [
        6.9628,
        15.634144876190476
    ],
    [
        6.9732,
        11.148572966666666
    ],
    [
        6.9836,
        11.148657623809527
    ],
    [
        6.9939,
        12.299946514285718
    ],
    [
        7.0043,
        13.084831552380953
    ],
    [
        7.0147,
        12.262651661904762
    ],
    [
        7.025,
        12.920531028571428
    ],
    [
        7.0354,
        12.715042495238094
    ],
    [
        7.0457,
        12.503499033333334
    ],
    [
        7.0561,
        12.668014023809524
    ],
    [
        7.0665,
        12.119743595238095
    ],
    [
        7.0768,
        14.312524314285714
    ],
    [
        7.0872,
        12.174416376190475
    ],
    [
        7.0975,
        11.821981004761906
    ],
    [
        7.1079,
        14.04217148095238
    ],
    [
        7.1183,
        14.896149504761903
    ],
    [
        7.1286,
        16.540847919047618
    ],
    [
        7.139,
        16.822796219047618
    ],
    [
        7.1494,
        21.208320028571432
    ],
    [
        7.1597,
        21.619466414285718
    ],
    [
        7.1701,
        20.934297690476193
    ],
    [
        7.1804,
        15.013654304761907
    ],
    [
        7.1908,
        14.56513097619048
    ],
    [
        7.2012,
        13.879839971428572
    ],
    [
        7.2115,
        7.301384942857142
    ],
    [
        7.2219,
        6.77281653809524
    ],
    [
        7.2322,
        5.95046732857143
    ],
    [
        7.2426,
        -0.6283263238095248
    ],
    [
        7.253,
        -0.9162134476190475
    ],
    [
        7.2633,
        0.23510930476190392
    ],
    [
        7.2737,
        0.07065074761904742
    ],
    [
        7.2841,
        1.304174557142857
    ],
    [
        7.2944,
        0.7559793761904752
    ],
    [
        7.3048,
        -2.533417447619049
    ],
    [
        7.3151,
        -2.4372987095238092
    ],
    [
        7.3255,
        -2.4372987095238097
    ],
    [
        7.3359,
        -3.2222684047619063
    ],
    [
        7.3462,
        -7.3338451238095255
    ],
    [
        7.3566,
        -3.3865689333333355
    ],
    [
        7.3669,
        -7.498032780952387
    ],
    [
        7.3773,
        -7.4606250523809585
    ],
    [
        7.3877,
        -5.541847861904764
    ],
    [
        7.398,
        -0.11471558571428732
    ],
    [
        7.4084,
        -0.11471558571428732
    ],
    [
        7.4188,
        -2.3077596761904777
    ],
    [
        7.4291,
        3.2427165142857137
    ],
    [
        7.4395,
        3.524664814285714
    ],
    [
        7.4498,
        3.3190351857142857
    ],
    [
        7.4602,
        5.786252119047617
    ],
    [
        7.4706,
        7.102033423809522
    ],
    [
        7.4809,
        5.809770385714286
    ],
    [
        7.4913,
        7.454694552380953
    ],
    [
        7.5016,
        11.154927357142858
    ],
    [
        7.512,
        12.388366509523811
    ],
    [
        7.5224,
        17.77104664285714
    ],
    [
        7.5327,
        16.335741204761902
    ],
    [
        7.5431,
        16.053860628571428
    ],
    [
        7.5534,
        13.13842820952381
    ],
    [
        7.5638,
        15.984852299999996
    ],
    [
        7.5742,
        11.050757061904765
    ],
    [
        7.5845,
        11.46198810476191
    ],
    [
        7.5949,
        10.026614942857146
    ],
    [
        7.6053,
        7.970713709523809
    ],
    [
        7.6156,
        9.122036461904763
    ],
    [
        7.626,
        9.670269266666669
    ],
    [
        7.6363,
        8.848032933333334
    ],
    [
        7.6467,
        8.93613572857143
    ],
    [
        7.6571,
        8.936135728571427
    ],
    [
        7.6674,
        9.964114566666666
    ],
    [
        7.6778,
        7.497236257142858
    ],
    [
        7.6881,
        7.086061652380951
    ],
    [
        7.6985,
        7.555975480952381
    ],
    [
        7.7089,
        10.022515161904764
    ],
    [
        7.7192,
        6.322282357142858
    ],
    [
        7.7296,
        10.022938442857143
    ],
    [
        7.74,
        4.640258309523809
    ],
    [
        7.7503,
        5.935369423809522
    ],
    [
        7.7607,
        10.164932538095238
    ],
    [
        7.771,
        7.697715604761903
    ],
    [
        7.7814,
        9.784878814285715
    ],
    [
        7.7918,
        14.719312676190476
    ],
    [
        7.8021,
        13.896963471428572
    ],
    [
        7.8125,
        17.350830138095237
    ],
    [
        7.8228,
        17.644506123809524
    ],
    [
        7.8332,
        13.532929404761903
    ],
    [
        7.8436,
        13.601458504761903
    ],
    [
        7.8539,
        21.002262738095236
    ],
    [
        7.8643,
        22.764318671428573
    ],
    [
        7.8747,
        23.42219803809524
    ],
    [
        7.885,
        26.711594861904764
    ],
    [
        7.8954,
        32.46764424285715
    ],
    [
        7.9057,
        32.0564132
    ],
    [
        7.9161,
        29.589365580952375
    ],
    [
        7.9265,
        27.122656585714285
    ],
    [
        7.9368,
        26.875748647619048
    ],
    [
        7.9472,
        23.586464695238096
    ],
    [
        7.9575,
        29.507311257142856
    ],
    [
        7.9679,
        29.903870590476192
    ],
    [
        7.9783,
        23.982685404761906
    ],
    [
        7.9886,
        29.464975823809525
    ],
    [
        7.999,
        24.53121920952381
    ],
    [
        8.0093,
        20.090194871428572
    ],
    [
        8.0197,
        18.034321852380952
    ],
    [
        8.0301,
        16.18399380952381
    ],
    [
        8.0404,
        16.712695247619052
    ],
    [
        8.0508,
        20.001922757142854
    ],
    [
        8.0612,
        17.740462438095236
    ],
    [
        8.0715,
        15.273753442857144
    ],
    [
        8.0819,
        14.451686423809525
    ],
    [
        8.0922,
        14.451686423809525
    ],
    [
        8.1026,
        10.751030338095237
    ],
    [
        8.113,
        8.284152033333331
    ],
    [
        8.1233,
        8.695383076190476
    ],
    [
        8.1337,
        12.807298423809522
    ],
    [
        8.144,
        10.957012709523807
    ],
    [
        8.1544,
        9.422239285714285
    ],
    [
        8.1648,
        8.394232233333332
    ],
    [
        8.1751,
        8.11230328095238
    ],
    [
        8.1855,
        7.4073841571428565
    ],
    [
        8.1959,
        10.861013785714285
    ],
    [
        8.2062,
        10.92953818095238
    ],
    [
        8.2166,
        12.409699028571428
    ],
    [
        8.2269,
        12.903040828571426
    ],
    [
        8.2373,
        9.613644004761904
    ],
    [
        8.2477,
        10.641622842857142
    ],
    [
        8.258,
        9.408099033333333
    ],
    [
        8.2684,
        8.585749828571426
    ],
    [
        8.2787,
        10.582859428571428
    ],
    [
        8.2891,
        7.128992761904762
    ],
    [
        8.2995,
        0.550086238095236
    ],
    [
        8.3098,
        -0.646088847619048
    ],
    [
        8.3202,
        -3.1129671523809517
    ],
    [
        8.3306,
        -0.6459759714285709
    ],
    [
        8.3409,
        -0.6459759714285708
    ],
    [
        8.3513,
        -5.580240523809524
    ],
    [
        8.3616,
        -4.552304014285715
    ],
    [
        8.372,
        -8.937903076190477
    ],
    [
        8.3824,
        -8.567955985714288
    ],
    [
        8.3927,
        -10.917589642857145
    ],
    [
        8.4031,
        -10.09524043809524
    ],
    [
        8.4134,
        -11.328764247619054
    ],
    [
        8.4238,
        -9.478266890476194
    ],
    [
        8.4342,
        -3.557284880952384
    ],
    [
        8.4445,
        -2.8994506666666715
    ],
    [
        8.4549,
        1.0478255238095238
    ],
    [
        8.4653,
        -0.04864008571428681
    ],
    [
        8.4756,
        0.9380435142857131
    ],
    [
        8.486,
        3.4050911333333325
    ],
    [
        8.4963,
        5.51989688095238
    ],
    [
        8.5067,
        5.5198968809523805
    ],
    [
        8.5171,
        11.68743127142857
    ],
    [
        8.5274,
        11.787103123809523
    ],
    [
        8.5378,
        12.52709889047619
    ],
    [
        8.5481,
        13.349278785714286
    ],
    [
        8.5585,
        12.609164499999999
    ],
    [
        8.5689,
        13.84268830952381
    ],
    [
        8.5792,
        13.184808942857144
    ],
    [
        8.5896,
        18.11856555714286
    ],
    [
        8.5999,
        19.598692538095243
    ],
    [
        8.6103,
        26.177599061904765
    ],
    [
        8.6207,
        26.177599061904765
    ],
    [
        8.631,
        25.7664809
    ],
    [
        8.6414,
        22.312377195238092
    ],
    [
        8.6518,
        17.92651476190476
    ],
    [
        8.6621,
        15.459580019047616
    ],
    [
        8.6725,
        17.679838219047618
    ],
    [
        8.6828,
        17.131605414285712
    ],
    [
        8.6932,
        17.78950735238095
    ],
    [
        8.7036,
        16.96715814761905
    ],
    [
        8.7139,
        16.144639628571433
    ],
    [
        8.7243,
        16.63808301428572
    ],
    [
        8.7346,
        15.952839042857144
    ],
    [
        8.745,
        17.04945514761905
    ],
    [
        8.7554,
        22.47665514761905
    ],
    [
        8.7657,
        25.765826223809523
    ],
    [
        8.7761,
        25.820687128571432
    ],
    [
        8.7865,
        27.054210938095242
    ],
    [
        8.7968,
        26.0673918904762
    ],
    [
        8.8072,
        26.0673918904762
    ],
    [
        8.8175,
        25.24498624761905
    ],
    [
        8.8279,
        21.955702295238094
    ],
    [
        8.8383,
        19.89991393809524
    ],
    [
        8.8486,
        14.143920990476195
    ],
    [
        8.859,
        15.130807761904768
    ],
    [
        8.8693,
        15.130770138095242
    ],
    [
        8.8797,
        13.485902409523812
    ],
    [
        8.8901,
        12.95723725714286
    ],
    [
        8.9004,
        15.424454190476194
    ],
    [
        8.9108,
        12.546344842857147
    ],
    [
        8.9211,
        11.60651717619048
    ],
    [
        8.9315,
        11.606404300000001
    ],
    [
        8.9419,
        11.11302863809524
    ],
    [
        8.9522,
        11.55158478095238
    ],
    [
        8.9626,
        7.439556561904763
    ],
    [
        8.973,
        1.2722761380952385
    ],
    [
        8.9833,
        -2.9567064761904764
    ],
    [
        8.9937,
        -2.5180901333333336
    ],
    [
        9.004,
        -0.05121182857142886
    ],
    [
        9.0144,
        -4.9853070666666675
    ],
    [
        9.0248,
        -5.690129438095239
    ],
    [
        9.0351,
        -6.841192576190475
    ],
    [
        9.0455,
        -6.0189562428571435
    ],
    [
        9.0558,
        -6.018956242857143
    ],
    [
        9.0662,
        -0.2628504238095231
    ],
    [
        9.0766,
        -1.9076617095238084
    ],
    [
        9.0869,
        -1.4691732904761894
    ],
    [
        9.0973,
        3.465260576190478
    ],
    [
        9.1077,
        1.773667528571428
    ],
    [
        9.118,
        0.29326964285714247
    ],
    [
        9.1284,
        3.1713789904761915
    ],
    [
        9.1387,
        3.699947395238096
    ],
    [
        9.1491,
        0.821838047619049
    ],
    [
        9.1595,
        -3.1255058666666664
    ],
    [
        9.1698,
        -3.4073864428571414
    ],
    [
        9.1802,
        -3.4070478142857135
    ],
    [
        9.1905,
        -1.5568044285714284
    ],
    [
        9.2009,
        0.205638504761905
    ],
    [
        9.2113,
        -5.71500488095238
    ],
    [
        9.2216,
        -3.2471107
    ],
    [
        9.232,
        2.920254380952382
    ],
    [
        9.2424,
        2.3915529428571434
    ],
    [
        9.2527,
        6.83257728095238
    ],
    [
        9.2631,
        -0.5682269523809531
    ],
    [
        9.2734,
        1.4876178428571418
    ],
    [
        9.2838,
        -0.9793169000000004
    ],
    [
        9.2942,
        1.370316757142856
    ],
    [
        9.3045,
        -0.4387160476190495
    ],
    [
        9.3149,
        -0.4390546761904764
    ],
    [
        9.3252,
        1.5346511428571419
    ],
    [
        9.3356,
        1.370237738095238
    ],
    [
        9.346,
        -5.207427166666667
    ],
    [
        9.3563,
        -8.90791393809524
    ],
    [
        9.3667,
        -9.319201414285715
    ],
    [
        9.3771,
        -2.7405206380952403
    ],
    [
        9.3874,
        -2.458640061904763
    ],
    [
        9.3978,
        -4.926195619047619
    ],
    [
        9.4081,
        -6.529666519047619
    ],
    [
        9.4185,
        -7.352410790476191
    ],
    [
        9.4289,
        -0.7736171380952379
    ],
    [
        9.4392,
        -10.642146238095238
    ],
    [
        9.4496,
        -6.941659466666668
    ],
    [
        9.4599,
        -5.214726133333335
    ],
    [
        9.4703,
        -10.149159995238094
    ],
    [
        9.4807,
        -5.21540338095238
    ],
    [
        9.491,
        -7.271248176190475
    ],
    [
        9.5014,
        -5.247142899999998
    ],
    [
        9.5117,
        -6.938852042857142
    ],
    [
        9.5221,
        -5.568345285714285
    ],
    [
        9.5325,
        -8.583494019047617
    ],
    [
        9.5428,
        -8.747975147619048
    ],
    [
        9.5532,
        -8.865558419047618
    ],
    [
        9.5636,
        -4.7547153857142845
    ],
    [
        9.5739,
        -7.222101628571427
    ],
    [
        9.5843,
        -4.590539019047617
    ],
    [
        9.5946,
        -3.7679076238095233
    ],
    [
        9.605,
        -4.137939371428573
    ],
    [
        9.6154,
        -1.6703838142857141
    ],
    [
        9.6257,
        -5.617727728571428
    ],
    [
        9.6361,
        -5.617389104761905
    ],
    [
        9.6464,
        -12.196860004761906
    ],
    [
        9.6568,
        -6.029664238095238
    ],
    [
        9.6672,
        -13.430299157142857
    ],
    [
        9.6775,
        -16.3907563
    ],
    [
        9.6879,
        -15.404004976190476
    ],
    [
        9.6983,
        -14.417118204761906
    ],
    [
        9.7086,
        -14.417118204761906
    ],
    [
        9.719,
        -13.56319879047619
    ],
    [
        9.7293,
        -11.34270355238095
    ],
    [
        9.7397,
        -9.4239828
    ],
    [
        9.7501,
        -11.342929304761904
    ],
    [
        9.7604,
        -12.165334947619048
    ],
    [
        9.7708,
        -14.10359647142857
    ],
    [
        9.7811,
        -12.576537766666664
    ],
    [
        9.7915,
        -5.1750562857142866
    ],
    [
        9.8019,
        -4.517222071428571
    ],
    [
        9.8122,
        -6.984439004761904
    ],
    [
        9.8226,
        -6.896336209523808
    ],
    [
        9.833,
        -9.363214519047618
    ],
    [
        9.8433,
        0.5049759571428568
    ],
    [
        9.8537,
        0.5049759571428558
    ],
    [
        9.864,
        7.9062881238095235
    ],
    [
        9.8744,
        5.850556204761904
    ],
    [
        9.8848,
        8.865667314285714
    ],
    [
        9.8951,
        11.332714933333332
    ],
    [
        9.9055,
        15.280397471428572
    ],
    [
        9.9158,
        19.22794456190476
    ],
    [
        9.9262,
        17.994505409523804
    ],
    [
        9.9366,
        17.37778583333333
    ],
    [
        9.9469,
        17.788650028571425
    ],
    [
        9.9573,
        14.704967490476188
    ],
    [
        9.9677,
        18.81699570952381
    ],
    [
        9.978,
        19.639232042857138
    ],
    [
        9.9884,
        18.40562357619047
    ],
    [
        9.9987,
        22.634896442857137
    ],
    [
        10.0091,
        20.990028719047615
    ],
    [
        10.0195,
        18.7970975
    ],
    [
        10.0298,
        19.50191987142857
    ],
    [
        10.0402,
        19.5018715
    ],
    [
        10.0505,
        23.61322247142857
    ],
    [
        10.0609,
        23.61288384761905
    ],
    [
        10.0713,
        17.0336387
    ],
    [
        10.0816,
        15.183479971428572
    ],
    [
        10.092,
        14.772248928571427
    ],
    [
        10.1024,
        15.04630889047619
    ],
    [
        10.1127,
        12.579430585714285
    ],
    [
        10.1231,
        8.87860519047619
    ],
    [
        10.1334,
        8.878605190476192
    ],
    [
        10.1438,
        13.81219249047619
    ],
    [
        10.1542,
        10.72859460952381
    ],
    [
        10.1645,
        9.700926180952381
    ],
    [
        10.1749,
        9.495324771428571
    ],
    [
        10.1852,
        9.494986147619048
    ],
    [
        10.1956,
        9.28951171904762
    ],
    [
        10.206,
        10.934210133333334
    ],
    [
        10.2163,
        7.233384738095238
    ],
    [
        10.2267,
        6.411204842857145
    ],
    [
        10.237,
        11.893307138095238
    ],
    [
        10.2474,
        12.422008576190478
    ],
    [
        10.2578,
        10.072536161904765
    ],
    [
        10.2681,
        9.908258204761907
    ],
    [
        10.2785,
        5.264742485714287
    ],
    [
        10.2889,
        8.965483223809526
    ],
    [
        10.2992,
        7.526527314285715
    ],
    [
        10.3096,
        9.253494509523811
    ],
    [
        10.3199,
        5.9643234380952395
    ],
    [
        10.3303,
        9.41795306666667
    ],
    [
        10.3407,
        9.41795306666667
    ],
    [
        10.351,
        9.417275819047621
    ],
    [
        10.3614,
        5.717043014285717
    ],
    [
        10.3717,
        5.716704385714288
    ],
    [
        10.3821,
        6.086516023809526
    ],
    [
        10.3925,
        7.3575065619047635
    ],
    [
        10.4028,
        4.068222614285717
    ],
    [
        10.4132,
        2.2180215571428596
    ],
    [
        10.4236,
        1.3958981000000026
    ],
    [
        10.4339,
        0.9850339000000016
    ],
    [
        10.4443,
        2.218642366666668
    ],
    [
        10.4546,
        2.2183037428571426
    ],
    [
        10.465,
        5.918875171428571
    ],
    [
        10.4754,
        10.030621204761903
    ],
    [
        10.4857,
        13.48418310952381
    ],
    [
        10.4961,
        11.54913092857143
    ],
    [
        10.5064,
        13.60511681904762
    ],
    [
        10.5168,
        16.07205156666667
    ],
    [
        10.5272,
        20.513042042857144
    ],
    [
        10.5375,
        22.157740452380956
    ],
    [
        10.5479,
        22.1578759
    ],
    [
        10.5583,
        25.858024047619047
    ],
    [
        10.5686,
        21.911086480952378
    ],
    [
        10.579,
        24.378218757142857
    ],
    [
        10.5893,
        28.489795480952385
    ],
    [
        10.5997,
        29.97022722857143
    ],
    [
        10.6101,
        27.054256090476194
    ],
    [
        10.6204,
        23.76564938571429
    ],
    [
        10.6308,
        24.012337214285715
    ],
    [
        10.6411,
        26.890418342857146
    ],
    [
        10.6515,
        26.61620788095238
    ],
    [
        10.6619,
        25.382599414285718
    ],
    [
        10.6722,
        17.982133804761908
    ],
    [
        10.6826,
        17.981795180952382
    ],
    [
        10.6929,
        16.337040328571433
    ],
    [
        10.7033,
        8.936066785714289
    ],
    [
        10.7137,
        15.514634685714292
    ],
    [
        10.724,
        15.92595038571429
    ],
    [
        10.7344,
        13.576526347619048
    ],
    [
        10.7448,
        9.346963233333335
    ],
    [
        10.7551,
        11.813954414285716
    ],
    [
        10.7655,
        10.827067642857145
    ],
    [
        10.7758,
        10.827067642857143
    ],
    [
        10.7862,
        14.774682457142857
    ],
    [
        10.7966,
        13.54107399047619
    ],
    [
        10.8069,
        11.896714200000002
    ],
    [
        10.8173,
        11.074477866666667
    ],
    [
        10.8276,
        14.775133952380955
    ],
    [
        10.838,
        21.35336322857143
    ],
    [
        10.8484,
        22.340114552380953
    ],
    [
        10.8587,
        16.17274947142857
    ],
    [
        10.8691,
        17.26959132857143
    ],
    [
        10.8795,
        17.886310904761906
    ],
    [
        10.8898,
        19.973864833333334
    ],
    [
        10.9002,
        15.86251386190476
    ],
    [
        10.9105,
        16.02697241904762
    ],
    [
        10.9209,
        23.428284590476192
    ],
    [
        10.9313,
        23.428284590476192
    ],
    [
        10.9416,
        17.26091950952381
    ],
    [
        10.952,
        22.89974037142857
    ],
    [
        10.9623,
        27.128626238095237
    ],
    [
        10.9727,
        27.951031885714286
    ],
    [
        10.9831,
        32.88546574761905
    ],
    [
        10.9934,
        26.718269980952375
    ],
    [
        11.0038,
        26.717254109523815
    ],
    [
        11.0142,
        27.334058342857144
    ],
    [
        11.0245,
        25.689472804761905
    ],
    [
        11.0349,
        24.044661519047615
    ],
    [
        11.0452,
        23.516310800000003
    ],
    [
        11.0556,
        20.227026852380952
    ],
    [
        11.066,
        20.84374642857143
    ],
    [
        11.0763,
        24.54389457619048
    ],
    [
        11.0867,
        22.89885753809524
    ],
    [
        11.097,
        23.268889285714287
    ],
    [
        11.1074,
        28.582478214285715
    ],
    [
        11.1178,
        26.115487033333334
    ],
    [
        11.1281,
        25.67693089047619
    ],
    [
        11.1385,
        24.196736180952378
    ],
    [
        11.1488,
        16.796270571428565
    ],
    [
        11.1592,
        19.26297956666666
    ],
    [
        11.1696,
        13.095360519047615
    ],
    [
        11.1799,
        9.394958399999997
    ],
    [
        11.1903,
        7.750203547619047
    ],
    [
        11.2007,
        0.3488913809523805
    ],
    [
        11.211,
        2.075672333333334
    ],
    [
        11.2214,
        -3.844632428571429
    ],
    [
        11.2317,
        -3.639143895238096
    ],
    [
        11.2421,
        2.9398755047619036
    ],
    [
        11.2525,
        2.9398755047619036
    ],
    [
        11.2628,
        2.8517182857142847
    ],
    [
        11.2732,
        2.8516054095238093
    ],
    [
        11.2835,
        0.5900745476190479
    ],
    [
        11.2939,
        4.290561319047619
    ],
    [
        11.3043,
        5.277380366666667
    ],
    [
        11.3146,
        -0.6434661952380955
    ],
    [
        11.325,
        -4.590877833333333
    ],
    [
        11.3354,
        -5.413509228571429
    ],
    [
        11.3457,
        -4.317118871428572
    ],
    [
        11.3561,
        -10.238236333333335
    ],
    [
        11.3664,
        -9.416225752380953
    ],
    [
        11.3768,
        -6.244307385714286
    ],
    [
        11.3872,
        -2.5440745809523815
    ],
    [
        11.3975,
        -1.310381457142858
    ],
    [
        11.4079,
        -3.7776548238095238
    ],
    [
        11.4182,
        -0.606002519047619
    ],
    [
        11.4286,
        -3.5665612523809527
    ],
    [
        11.439,
        -1.34623532857143
    ],
    [
        11.4493,
        -1.1817090476190475
    ],
    [
        11.4597,
        -6.9384921142857126
    ],
    [
        11.4701,
        -6.9384921142857126
    ],
    [
        11.4804,
        -5.910668480952379
    ],
    [
        11.4908,
        -3.854936557142856
    ],
    [
        11.5011,
        -2.9149960190476185
    ],
    [
        11.5115,
        -2.914488080952379
    ],
    [
        11.5219,
        -2.2568344666666658
    ],
    [
        11.5322,
        3.664012095238096
    ],
    [
        11.5426,
        7.611762357142858
    ],
    [
        11.5529,
        11.72345195238095
    ],
    [
        11.5633,
        8.434055128571426
    ],
    [
        11.5737,
        18.30258422857143
    ],
    [
        11.584,
        24.880362009523814
    ],
    [
        11.5944,
        25.820189676190473
    ],
    [
        11.6048,
        24.99829196666667
    ],
    [
        11.6151,
        21.70895158095238
    ],
    [
        11.6255,
        23.901958047619047
    ],
    [
        11.6358,
        23.19718404761905
    ],
    [
        11.6462,
        30.597818971428573
    ],
    [
        11.6566,
        30.351266590476193
    ],
    [
        11.6669,
        28.131008390476193
    ],
    [
        11.6773,
        28.953357595238096
    ],
    [
        11.6876,
        30.597943133333334
    ],
    [
        11.698,
        24.01960098095238
    ],
    [
        11.7084,
        23.608454595238094
    ],
    [
        11.7187,
        25.135730985714286
    ],
    [
        11.7291,
        27.602101357142857
    ],
    [
        11.7394,
        26.574348271428573
    ],
    [
        11.7498,
        24.765191304761906
    ],
    [
        11.7602,
        24.764514057142854
    ],
    [
        11.7705,
        24.353395890476193
    ],
    [
        11.7809,
        30.933318285714282
    ],
    [
        11.7913,
        28.46576272857143
    ],
    [
        11.8016,
        22.299328866666666
    ],
    [
        11.812,
        21.359549576190474
    ],
    [
        11.8223,
        18.72798696666667
    ],
    [
        11.8327,
        21.19509101904762
    ],
    [
        11.8431,
        25.580652452380956
    ],
    [
        11.8534,
        25.580652452380956
    ],
    [
        11.8638,
        22.29182
    ],
    [
        11.8741,
        22.291684552380953
    ],
    [
        11.8845,
        18.59068984285714
    ],
    [
        11.8949,
        19.413095485714287
    ],
    [
        11.9052,
        20.235388252380954
    ],
    [
        11.9156,
        23.52455932857143
    ],
    [
        11.926,
        25.169370614285715
    ],
    [
        11.9363,
        26.813843276190482
    ],
    [
        11.9467,
        26.813843276190482
    ],
    [
        11.957,
        27.01952934285715
    ],
    [
        11.9674,
        27.019755090476195
    ],
    [
        11.9778,
        23.07288524761905
    ],
    [
        11.9881,
        19.37231381904762
    ],
    [
        11.9985,
        15.259495476190477
    ],
    [
        12.0088,
        11.5590087047619
    ],
    [
        12.0192,
        14.43694873809524
    ],
    [
        12.0296,
        14.965299457142853
    ],
    [
        12.0399,
        14.307239495238093
    ],
    [
        12.0503,
        13.210698638095234
    ],
    [
        12.0607,
        7.290326152380954
    ],
    [
        12.071,
        8.52393461904762
    ],
    [
        12.0814,
        9.346566014285717
    ],
    [
        12.0917,
        8.359814690476192
    ],
    [
        12.1021,
        18.228682414285714
    ],
    [
        12.1125,
        19.87315507619048
    ],
    [
        12.1228,
        17.406276771428573
    ],
    [
        12.1332,
        21.517740614285717
    ],
    [
        12.1435,
        19.872929328571434
    ],
    [
        12.1539,
        16.419164252380956
    ],
    [
        12.1643,
        12.19023000952381
    ],
    [
        12.1746,
        13.012635652380952
    ],
    [
        12.185,
        15.479401085714287
    ],
    [
        12.1953,
        13.505627542857141
    ],
    [
        12.2057,
        16.9595958
    ],
    [
        12.2161,
        16.842222157142857
    ],
    [
        12.2264,
        13.14207400952381
    ],
    [
        12.2368,
        10.5108726
    ],
    [
        12.2472,
        10.100008399999998
    ],
    [
        12.2575,
        11.128015452380952
    ],
    [
        12.2679,
        11.028268352380952
    ],
    [
        12.2782,
        13.001635547619045
    ],
    [
        12.2886,
        14.235244014285714
    ],
    [
        12.299,
        10.945734314285712
    ],
    [
        12.3093,
        11.932621085714283
    ],
    [
        12.3197,
        8.231626376190476
    ],
    [
        12.33,
        0.8309914571428558
    ],
    [
        12.3404,
        3.297869761904761
    ],
    [
        12.3508,
        -1.6358868476190493
    ],
    [
        12.3611,
        -4.103103780952383
    ],
    [
        12.3715,
        -1.8826085428571444
    ],
    [
        12.3819,
        -1.9707113380952397
    ],
    [
        12.3922,
        -3.6155226238095253
    ],
    [
        12.4026,
        -0.3256743000000015
    ],
    [
        12.4129,
        -1.8056658333333349
    ],
    [
        12.4233,
        -1.5588086904761926
    ],
    [
        12.4337,
        0.20315049047618858
    ],
    [
        12.444,
        10.07066371904762
    ],
    [
        12.4544,
        12.044166361904761
    ],
    [
        12.4647,
        8.75522103809524
    ],
    [
        12.4751,
        10.358912042857142
    ],
    [
        12.4855,
        8.26557742857143
    ],
    [
        12.4958,
        7.983648476190477
    ],
    [
        12.5062,
        3.871846004761904
    ],
    [
        12.5166,
        6.338950057142857
    ],
    [
        12.5269,
        6.99687457142857
    ],
    [
        12.5373,
        6.468161042857143
    ],
    [
        12.5476,
        10.415030885714284
    ],
    [
        12.558,
        7.94815258095238
    ],
    [
        12.5684,
        11.237323652380951
    ],
    [
        12.5787,
        8.77010672380953
    ],
    [
        12.5891,
        12.470254871428573
    ],
    [
        12.5994,
        16.787969157142857
    ],
    [
        12.6098,
        16.787969157142857
    ],
    [
        12.6202,
        16.78729190952381
    ],
    [
        12.6305,
        24.189281323809524
    ],
    [
        12.6409,
        22.955672857142854
    ],
    [
        12.6513,
        21.31108731904762
    ],
    [
        12.6616,
        13.910113771428572
    ],
    [
        12.672,
        11.27886720952381
    ],
    [
        12.6823,
        14.238850276190476
    ],
    [
        12.6927,
        10.785085200000001
    ],
    [
        12.7031,
        13.416783257142859
    ],
    [
        12.7134,
        11.067375347619047
    ],
    [
        12.7238,
        17.64639474761905
    ],
    [
        12.7341,
        14.356997923809526
    ],
    [
        12.7445,
        17.6466205
    ],
    [
        12.7549,
        17.646717247619048
    ],
    [
        12.7652,
        23.567360633333333
    ],
    [
        12.7756,
        28.50111724761905
    ],
    [
        12.7859,
        26.034238942857147
    ],
    [
        12.7963,
        31.673591928571426
    ],
    [
        12.8067,
        25.752948542857148
    ],
    [
        12.817,
        22.051953833333332
    ],
    [
        12.8274,
        23.285392985714285
    ],
    [
        12.8378,
        13.417879757142854
    ],
    [
        12.8481,
        10.949985580952383
    ],
    [
        12.8585,
        11.772278347619048
    ],
    [
        12.8688,
        10.949985580952378
    ],
    [
        12.8792,
        12.18376336190476
    ],
    [
        12.8896,
        15.06173161904762
    ],
    [
        12.8999,
        19.502044847619047
    ],
    [
        12.9103,
        23.61373443809524
    ],
    [
        12.9206,
        17.693091052380954
    ],
    [
        12.931,
        20.98226212857143
    ],
    [
        12.9414,
        15.061076942857138
    ],
    [
        12.9517,
        17.199173590476192
    ],
    [
        12.9621,
        12.264739728571431
    ],
    [
        12.9725,
        10.326478204761909
    ],
    [
        12.9828,
        6.626330057142855
    ],
    [
        12.9932,
        4.1594517476190465
    ],
    [
        13.0035,
        5.804037285714284
    ],
    [
        13.0139,
        6.086004933333332
    ],
    [
        13.0243,
        9.539770009523808
    ],
    [
        13.0346,
        9.12851075238095
    ],
    [
        13.045,
        10.362288533333333
    ],
    [
        13.0553,
        14.309293823809526
    ],
    [
        13.0657,
        12.66448253333333
    ],
    [
        13.0761,
        10.197604228571427
    ],
    [
        13.0864,
        15.13136084285714
    ],
    [
        13.0968,
        18.00961128095238
    ],
    [
        13.1072,
        14.30912450952381
    ],
    [
        13.1175,
        10.080287019047617
    ],
    [
        13.1279,
        5.968258799999999
    ],
    [
        13.1382,
        11.606837785714289
    ],
    [
        13.1486,
        8.31789245714286
    ],
    [
        13.159,
        10.291530552380955
    ],
    [
        13.1693,
        14.73245330476191
    ],
    [
        13.1797,
        13.745701980952386
    ],
    [
        13.19,
        19.912897747619052
    ],
    [
        13.2004,
        17.692402509523816
    ],
    [
        13.2108,
        16.047704100000004
    ],
    [
        13.2211,
        16.047704100000004
    ],
    [
        13.2315,
        15.677536904761908
    ],
    [
        13.2419,
        11.566073061904763
    ],
    [
        13.2522,
        11.730644490476193
    ],
    [
        13.2626,
        11.73030586190476
    ],
    [
        13.2729,
        15.184274119047622
    ],
    [
        13.2833,
        14.97858805238095
    ],
    [
        13.2937,
        14.156295285714288
    ],
    [
        13.304,
        7.577727385714287
    ],
    [
        13.3144,
        8.399794404761906
    ],
    [
        13.3247,
        10.044267066666666
    ],
    [
        13.3351,
        9.956152176190475
    ],
    [
        13.3455,
        13.128094733333333
    ],
    [
        13.3558,
        11.189833209523808
    ],
    [
        13.3662,
        13.327636385714285
    ],
    [
        13.3765,
        17.275183476190477
    ],
    [
        13.3869,
        11.518569719047617
    ],
    [
        13.3973,
        14.972537976190475
    ],
    [
        13.4076,
        14.973215223809522
    ],
    [
        13.418,
        17.193541147619044
    ],
    [
        13.4284,
        16.37136125238095
    ],
    [
        13.4387,
        17.604969719047617
    ],
    [
        13.4491,
        16.98825014285714
    ],
    [
        13.4594,
        18.63283568095238
    ],
    [
        13.4698,
        12.712327742857141
    ],
    [
        13.4802,
        11.232336209523808
    ],
    [
        13.4905,
        10.409704814285714
    ],
    [
        13.5009,
        11.437570776190475
    ],
    [
        13.5112,
        12.670840614285712
    ],
    [
        13.5216,
        15.549260366666664
    ],
    [
        13.532,
        11.849112219047617
    ],
    [
        13.5423,
        10.203962304761907
    ],
    [
        13.5527,
        10.135278
    ],
    [
        13.5631,
        11.075137914285714
    ],
    [
        13.5734,
        10.66376578095238
    ],
    [
        13.5838,
        11.403761547619046
    ],
    [
        13.5941,
        11.403084300000003
    ],
    [
        13.6045,
        12.225264195238097
    ],
    [
        13.6149,
        4.823613400000001
    ],
    [
        13.6252,
        2.356057842857144
    ],
    [
        13.6356,
        6.056375304761907
    ],
    [
        13.6459,
        7.701186590476192
    ],
    [
        13.6563,
        6.467578123809525
    ],
    [
        13.6667,
        7.289870890476191
    ],
    [
        13.677,
        4.822992585714287
    ],
    [
        13.6874,
        9.7567492
    ],
    [
        13.6977,
        9.263305814285715
    ],
    [
        13.7081,
        5.973908990476192
    ],
    [
        13.7185,
        6.796201757142859
    ],
    [
        13.7288,
        10.49668852857143
    ],
    [
        13.7392,
        10.907665600000001
    ],
    [
        13.7496,
        17.075369304761903
    ],
    [
        13.7599,
        22.009803166666668
    ],
    [
        13.7703,
        20.639390471428573
    ],
    [
        13.7806,
        19.611609166666668
    ],
    [
        13.791,
        22.24317177619048
    ],
    [
        13.8014,
        20.18732698095238
    ],
    [
        13.8117,
        16.898155904761907
    ],
    [
        13.8221,
        15.664547438095239
    ],
    [
        13.8324,
        21.303803671428568
    ],
    [
        13.8428,
        13.90316875238095
    ],
    [
        13.8532,
        9.791704904761904
    ],
    [
        13.8635,
        10.614449176190474
    ],
    [
        13.8739,
        10.614449176190474
    ],
    [
        13.8843,
        8.14757087142857
    ],
    [
        13.8946,
        5.6806925619047615
    ],
    [
        13.905,
        10.614449176190476
    ],
    [
        13.9153,
        11.765817076190476
    ],
    [
        13.9257,
        15.055213899999998
    ],
    [
        13.9361,
        10.943524309523806
    ],
    [
        13.9464,
        9.09336558095238
    ],
    [
        13.9568,
        8.435531366666664
    ],
    [
        13.9671,
        1.8562862190476193
    ],
    [
        13.9775,
        -0.6105920857142864
    ],
    [
        13.9879,
        -0.6107049619047615
    ],
    [
        13.9982,
        -0.4053292999999999
    ],
    [
        14.0086,
        -0.1584721571428575
    ],
    [
        14.019,
        0.6639334857142856
    ],
    [
        14.0293,
        -5.9144086666666675
    ],
    [
        14.0397,
        -3.6939134285714297
    ],
    [
        14.05,
        -3.1652966476190496
    ],
    [
        14.0604,
        0.535020814285715
    ],
    [
        14.0708,
        0.12393087142856993
    ],
    [
        14.0811,
        -3.048447057142858
    ],
    [
        14.0915,
        -2.061695733333334
    ],
    [
        14.1018,
        0.2877121761904756
    ],
    [
        14.1122,
        3.576883252380953
    ],
    [
        14.1226,
        -2.5904818285714293
    ],
    [
        14.1329,
        -3.5303739904761904
    ],
    [
        14.1433,
        -5.879781900000001
    ],
    [
        14.1537,
        -3.7864472857142863
    ],
    [
        14.164,
        -4.403166861904762
    ],
    [
        14.1744,
        -6.006637761904763
    ],
    [
        14.1847,
        -3.657004104761906
    ],
    [
        14.1951,
        -1.1897871714285726
    ],
    [
        14.2055,
        1.2772040095238089
    ],
    [
        14.2158,
        -4.478732500000001
    ],
    [
        14.2262,
        -3.2451240333333353
    ],
    [
        14.2365,
        -2.4228312666666687
    ],
    [
        14.2469,
        4.155736633333332
    ],
    [
        14.2573,
        -1.764906752380954
    ],
    [
        14.2676,
        -5.465224209523812
    ],
    [
        14.278,
        0.7026488047619027
    ],
    [
        14.2883,
        2.758662914285712
    ],
    [
        14.2987,
        -1.4695940809523838
    ],
    [
        14.3091,
        -1.4694586333333355
    ],
    [
        14.3194,
        -1.1876651333333352
    ],
    [
        14.3298,
        -0.24799871904762075
    ],
    [
        14.3402,
        1.5338801761904737
    ],
    [
        14.3505,
        1.5337834238095214
    ],
    [
        14.3609,
        0.5940202571428544
    ],
    [
        14.3712,
        -4.788936933333335
    ],
    [
        14.3816,
        -9.722016300000002
    ],
    [
        14.392,
        -8.118274500000002
    ],
    [
        14.4023,
        -8.823096871428573
    ],
    [
        14.4127,
        -11.290313804761908
    ],
    [
        14.423,
        -11.290088057142862
    ],
    [
        14.4334,
        -5.739527209523811
    ],
    [
        14.4438,
        -9.19349546666667
    ],
    [
        14.4541,
        -14.950222095238095
    ],
    [
        14.4645,
        -16.595033380952383
    ],
    [
        14.4749,
        -11.661276766666667
    ],
    [
        14.4852,
        -7.549812923809524
    ],
    [
        14.4956,
        -7.550490171428573
    ],
    [
        14.5059,
        -5.083273242857145
    ],
    [
        14.5163,
        0.8365574476190493
    ],
    [
        14.5267,
        2.316548980952381
    ],
    [
        14.537,
        0.09632464285714312
    ],
    [
        14.5474,
        -1.665634538095235
    ],
    [
        14.5577,
        -1.3914993238095221
    ],
    [
        14.5681,
        2.837434919047621
    ],
    [
        14.5785,
        5.1868428285714305
    ],
    [
        14.5888,
        5.187520076190475
    ],
    [
        14.5992,
        5.1868428285714305
    ],
    [
        14.6096,
        5.186707376190476
    ],
    [
        14.6199,
        4.199956052380952
    ],
    [
        14.6303,
        5.022361695238095
    ],
    [
        14.6406,
        8.311307023809526
    ],
    [
        14.651,
        12.628259404761907
    ],
    [
        14.6614,
        16.082227661904763
    ],
    [
        14.6717,
        19.37207598571429
    ],
    [
        14.6821,
        21.838954290476195
    ],
    [
        14.6924,
        24.3058325952381
    ],
    [
        14.7028,
        24.3058325952381
    ],
    [
        14.7132,
        18.138298204761906
    ],
    [
        14.7235,
        13.908686714285714
    ],
    [
        14.7339,
        14.566520928571427
    ],
    [
        14.7443,
        12.099642623809524
    ],
    [
        14.7546,
        15.800129395238097
    ],
    [
        14.765,
        13.333251085714286
    ],
    [
        14.7753,
        14.978062376190477
    ],
    [
        14.7857,
        10.661025338095238
    ],
    [
        14.7961,
        9.33948339047619
    ],
    [
        14.8064,
        14.889959580952382
    ],
    [
        14.8168,
        14.88928233333334
    ],
    [
        14.8271,
        13.902395561904763
    ],
    [
        14.8375,
        9.955390271428573
    ],
    [
        14.8479,
        9.544131014285718
    ],
    [
        14.8582,
        3.376935247619046
    ],
    [
        14.8686,
        -6.4905779809523825
    ],
    [
        14.8789,
        -7.970908142857145
    ],
    [
        14.8893,
        -5.503916961904765
    ],
    [
        14.8997,
        -8.793088033333333
    ],
    [
        14.91,
        -16.193722952380956
    ],
    [
        14.9204,
        -12.904551876190478
    ],
    [
        14.9308,
        -10.848368457142858
    ],
    [
        14.9411,
        -10.31958236190476
    ],
    [
        14.9515,
        -12.786460666666672
    ],
    [
        14.9618,
        -12.406940928571434
    ],
    [
        14.9722,
        -15.253338971428578
    ],
    [
        14.9826,
        -9.702778123809523
    ],
    [
        14.9929,
        -11.183243733333338
    ],
    [
        15.0033,
        -16.73371992380953
    ],
    [
        15.0136,
        -11.183243733333338
    ],
    [
        15.024,
        -9.332746376190483
    ],
    [
        15.0344,
        -3.5759068714285727
    ],
    [
        15.0447,
        -4.39819963809524
    ],
    [
        15.0551,
        1.1522765523809513
    ],
    [
        15.0655,
        0.7411301666666658
    ],
    [
        15.0758,
        0.3297580333333319
    ],
    [
        15.0862,
        10.197271261904763
    ],
    [
        15.0965,
        10.197271261904763
    ],
    [
        15.1069,
        7.730280080952381
    ],
    [
        15.1173,
        9.374865614285714
    ],
    [
        15.1276,
        15.95320776666667
    ],
    [
        15.138,
        11.019451152380952
    ],
    [
        15.1483,
        15.13147937142857
    ],
    [
        15.1587,
        13.897870904761904
    ],
    [
        15.1691,
        12.253623990476187
    ],
    [
        15.1794,
        12.32262758095238
    ],
    [
        15.1898,
        7.767713457142854
    ],
    [
        15.2002,
        2.2171526095238088
    ],
    [
        15.2105,
        -3.7033553285714294
    ],
    [
        15.2209,
        -3.7040325761904787
    ],
    [
        15.2312,
        -3.704032576190476
    ],
    [
        15.2416,
        -4.526663971428569
    ],
    [
        15.252,
        -4.526551095238095
    ],
    [
        15.2623,
        1.2288210380952396
    ],
    [
        15.2727,
        -0.6215070047619028
    ],
    [
        15.283,
        1.8453713000000018
    ],
    [
        15.2934,
        5.956722266666667
    ],
    [
        15.3038,
        3.490521209523812
    ],
    [
        15.3141,
        4.970512742857146
    ],
    [
        15.3245,
        1.6813416666666683
    ],
    [
        15.3348,
        1.4758531333333313
    ],
    [
        15.3452,
        4.765024209523809
    ],
    [
        15.3556,
        -0.1694096523809519
    ],
    [
        15.3659,
        -0.1700868999999998
    ],
    [
        15.3763,
        0.6522058666666668
    ],
    [
        15.3867,
        1.8853063952380953
    ],
    [
        15.397,
        -3.497650795238091
    ],
    [
        15.4074,
        2.1416054380952403
    ],
    [
        15.4177,
        4.608483747619049
    ],
    [
        15.4281,
        10.247159485714288
    ],
    [
        15.4385,
        12.714715042857145
    ],
    [
        15.4488,
        10.248175361904767
    ],
    [
        15.4592,
        6.136372890476197
    ],
    [
        15.4695,
        7.7803940523809585
    ],
    [
        15.4799,
        2.847314685714286
    ],
    [
        15.4903,
        5.067539023809525
    ],
    [
        15.5006,
        0.9560751809523822
    ],
    [
        15.511,
        -0.6880588571428554
    ],
    [
        15.5214,
        -8.090048276190476
    ],
    [
        15.5317,
        -9.734633814285713
    ],
    [
        15.5421,
        0.13355666190476323
    ],
    [
        15.5524,
        1.9836307333333345
    ],
    [
        15.5628,
        -7.883882495238096
    ],
    [
        15.5732,
        -2.9494486333333336
    ],
    [
        15.5835,
        -8.705498014285714
    ],
    [
        15.5939,
        -9.21943099047619
    ],
    [
        15.6042,
        -8.53418701904762
    ],
    [
        15.6146,
        1.334003457142856
    ],
    [
        15.625,
        -1.8380358476190473
    ],
    [
        15.6353,
        0.9813020190476184
    ],
    [
        15.6457,
        -4.657373719047619
    ],
    [
        15.6561,
        -2.7387658428571444
    ],
    [
        15.6664,
        -4.7575210142857145
    ],
    [
        15.6768,
        -7.224737942857143
    ],
    [
        15.6871,
        -12.158494557142856
    ],
    [
        15.6975,
        -10.925055404761903
    ],
    [
        15.7079,
        -14.378549580952381
    ],
    [
        15.7182,
        -11.089378504761905
    ],
    [
        15.7286,
        -10.924897376190476
    ],
    [
        15.7389,
        -5.990463514285714
    ],
    [
        15.7493,
        -6.81275628095238
    ],
    [
        15.7597,
        -6.813433528571427
    ],
    [
        15.77,
        -8.046872680952383
    ],
    [
        15.7804,
        -2.290710423809521
    ],
    [
        15.7908,
        2.6430461904761935
    ],
    [
        15.8011,
        2.642933314285717
    ],
    [
        15.8115,
        2.7830352857142886
    ],
    [
        15.8218,
        2.097791314285717
    ],
    [
        15.8322,
        -5.303182233333331
    ],
    [
        15.8426,
        -2.835965299999999
    ],
    [
        15.8529,
        -2.4835057380952357
    ],
    [
        15.8633,
        2.4502508761904798
    ],
    [
        15.8736,
        4.643106842857145
    ],
    [
        15.884,
        9.1284016952381
    ],
    [
        15.8944,
        14.06283555714286
    ],
    [
        15.9047,
        18.174412280952385
    ],
    [
        15.9151,
        19.408190061904765
    ],
    [
        15.9254,
        24.341946671428577
    ],
    [
        15.9358,
        21.710609809523813
    ],
    [
        15.9462,
        21.710609809523813
    ],
    [
        15.9565,
        21.710609809523813
    ],
    [
        15.9669,
        22.533015452380955
    ],
    [
        15.9773,
        15.954899047619051
    ],
    [
        15.9876,
        12.254581590476194
    ],
    [
        15.998,
        12.137191819047622
    ],
    [
        16.0083,
        8.025727971428571
    ],
    [
        16.0187,
        3.915054247619047
    ],
    [
        16.0291,
        0.999375552380953
    ],
    [
        16.0394,
        3.0551074714285695
    ],
    [
        16.0498,
        4.699918761904762
    ],
    [
        16.0601,
        9.634352623809523
    ],
    [
        16.0705,
        9.751726266666665
    ],
    [
        16.0809,
        9.751726266666665
    ],
    [
        16.0912,
        10.574357661904761
    ],
    [
        16.1016,
        5.6406010476190485
    ],
    [
        16.112,
        6.089062809523811
    ],
    [
        16.1223,
        3.6222973761904753
    ],
    [
        16.1327,
        3.621958747619046
    ],
    [
        16.143,
        -0.07835870952381101
    ],
    [
        16.1534,
        1.895279385714284
    ],
    [
        16.1638,
        3.3756095476190455
    ],
    [
        16.1741,
        5.842149228571427
    ],
    [
        16.1845,
        2.5528652809523797
    ],
    [
        16.1948,
        5.184111842857142
    ],
    [
        16.2052,
        8.8844293
    ],
    [
        16.2156,
        9.166435652380951
    ],
    [
        16.2259,
        6.699783095238096
    ],
    [
        16.2363,
        14.099740766666669
    ],
    [
        16.2467,
        17.55370902380952
    ],
    [
        16.257,
        15.744529485714285
    ],
    [
        16.2674,
        21.501368990476188
    ],
    [
        16.2777,
        15.333326661904763
    ],
    [
        16.2881,
        14.511033895238095
    ],
    [
        16.2985,
        15.215856266666666
    ],
    [
        16.3088,
        12.748639333333331
    ],
    [
        16.3192,
        12.748639333333331
    ],
    [
        16.3295,
        13.533616723809521
    ],
    [
        16.3399,
        13.369135595238092
    ],
    [
        16.3503,
        11.607079661904761
    ],
    [
        16.3606,
        13.663037333333332
    ],
    [
        16.371,
        10.209272257142858
    ],
    [
        16.3814,
        10.208595004761904
    ],
    [
        16.3917,
        6.755033099999999
    ],
    [
        16.4021,
        9.926637028571427
    ],
    [
        16.4124,
        4.005993642857142
    ],
    [
        16.4228,
        4.417591528571428
    ],
    [
        16.4332,
        8.363648671428571
    ],
    [
        16.4435,
        10.994895233333333
    ],
    [
        16.4539,
        7.705611285714284
    ],
    [
        16.4642,
        7.952129804761903
    ],
    [
        16.4746,
        13.873450438095237
    ],
    [
        16.485,
        10.172625042857144
    ],
    [
        16.4953,
        12.39284938095238
    ],
    [
        16.5057,
        11.40609805714286
    ],
    [
        16.516,
        11.40619480952381
    ],
    [
        16.5264,
        12.111017180952382
    ],
    [
        16.5368,
        13.344625647619049
    ],
    [
        16.5471,
        11.562991314285718
    ],
    [
        16.5575,
        17.483634700000003
    ],
    [
        16.5679,
        14.311934023809526
    ],
    [
        16.5782,
        14.31182114761905
    ],
    [
        16.5886,
        18.012138604761905
    ],
    [
        16.5989,
        13.901013385714284
    ],
    [
        16.6093,
        16.532621147619047
    ],
    [
        16.6197,
        16.53281464761905
    ],
    [
        16.63,
        20.64427849523809
    ],
    [
        16.6404,
        16.53202452380952
    ],
    [
        16.6507,
        11.598945157142857
    ],
    [
        16.6611,
        11.160178314285714
    ],
    [
        16.6715,
        10.749314114285715
    ],
    [
        16.6818,
        10.749822047619048
    ],
    [
        16.6922,
        4.170802647619049
    ],
    [
        16.7026,
        3.485464609523809
    ],
    [
        16.7129,
        4.143434276190477
    ],
    [
        16.7233,
        3.4856000619047633
    ],
    [
        16.7336,
        -2.153752923809523
    ],
    [
        16.744,
        -2.2417710619047617
    ],
    [
        16.7544,
        -8.408458895238095
    ],
    [
        16.7647,
        -6.6266552476190475
    ],
    [
        16.7751,
        -14.027290166666669
    ],
    [
        16.7854,
        -10.327142019047617
    ],
    [
        16.7958,
        -12.794810447619046
    ],
    [
        16.8062,
        -9.095170233333336
    ],
    [
        16.8165,
        -6.217145542857139
    ],
    [
        16.8269,
        -7.861731080952383
    ],
    [
        16.8372,
        -7.579898880952382
    ],
    [
        16.8476,
        -6.757606114285714
    ],
    [
        16.858,
        -3.3035024095238077
    ],
    [
        16.8683,
        -3.3035024095238077
    ],
    [
        16.8787,
        -4.468426571428572
    ],
    [
        16.8891,
        -2.001717576190475
    ],
    [
        16.8994,
        -1.5909098142857137
    ],
    [
        16.9098,
        1.0405624952380967
    ],
    [
        16.9201,
        2.1368776047619074
    ],
    [
        16.9305,
        1.7259005333333364
    ],
    [
        16.9409,
        2.6656637000000023
    ],
    [
        16.9512,
        8.304919933333334
    ],
    [
        16.9616,
        2.753004590476192
    ],
    [
        16.9719,
        8.134607285714289
    ],
    [
        16.9823,
        9.367538500000002
    ],
    [
        16.9927,
        6.901337438095238
    ],
    [
        17.003,
        4.43428981904762
    ],
    [
        17.0134,
        7.06630392857143
    ],
    [
        17.0238,
        1.4284021904761914
    ],
    [
        17.0341,
        2.662179971428573
    ],
    [
        17.0445,
        3.3670023428571443
    ],
    [
        17.0548,
        -2.5535055952380947
    ],
    [
        17.0652,
        -0.9084685571428563
    ],
    [
        17.0756,
        -0.662085485714285
    ],
    [
        17.0859,
        -5.5958421000000005
    ],
    [
        17.0963,
        -0.04460400476190581
    ],
    [
        17.1066,
        1.1884965238095215
    ],
    [
        17.117,
        2.0111279190476163
    ],
    [
        17.1274,
        -0.6205701380952404
    ],
    [
        17.1377,
        -1.7717800142857172
    ],
    [
        17.1481,
        -4.6501997666666695
    ],
    [
        17.1585,
        -8.879134009523813
    ],
    [
        17.1688,
        -8.350517228571432
    ],
    [
        17.1792,
        -2.7103902476190505
    ],
    [
        17.1895,
        1.7741657904761887
    ],
    [
        17.1999,
        -1.9256437333333356
    ],
    [
        17.2103,
        -1.9276754761904773
    ],
    [
        17.2206,
        -5.627654314285716
    ],
    [
        17.231,
        -4.969820100000001
    ],
    [
        17.2413,
        -3.5600786047619075
    ],
    [
        17.2517,
        -6.849588304761906
    ],
    [
        17.2621,
        -6.320971523809525
    ],
    [
        17.2724,
        -1.3872149095238107
    ],
    [
        17.2828,
        -5.498791628571431
    ],
    [
        17.2931,
        -6.183866290476193
    ],
    [
        17.3035,
        1.2171072571428552
    ],
    [
        17.3139,
        -4.539619371428572
    ],
    [
        17.3242,
        -6.019407728571427
    ],
    [
        17.3346,
        -6.8418133761904745
    ],
    [
        17.345,
        -2.730123785714286
    ],
    [
        17.3553,
        -8.157933309523811
    ],
    [
        17.3657,
        -6.513122023809522
    ],
    [
        17.376,
        -4.0459050904761895
    ],
    [
        17.3864,
        -3.6340814571428575
    ],
    [
        17.3968,
        0.5939820380952382
    ],
    [
        17.4071,
        -4.339097328571429
    ],
    [
        17.4175,
        -2.4890232523809517
    ],
    [
        17.4278,
        2.446087857142857
    ],
    [
        17.4382,
        8.61345293809524
    ],
    [
        17.4486,
        2.035110785714287
    ],
    [
        17.4589,
        -1.1366866428571432
    ],
    [
        17.4693,
        0.9192145904761911
    ],
    [
        17.4797,
        -5.247981176190477
    ],
    [
        17.49,
        -3.6033956380952388
    ],
    [
        17.5004,
        1.3303609761904756
    ],
    [
        17.5107,
        -1.6847125095238096
    ],
    [
        17.5211,
        -3.329636676190476
    ],
    [
        17.5315,
        -3.3297495523809526
    ],
    [
        17.5418,
        -3.8679775476190477
    ],
    [
        17.5522,
        -4.2793496809523806
    ],
    [
        17.5625,
        -7.2397390952380976
    ],
    [
        17.5729,
        -1.856843471428573
    ],
    [
        17.5833,
        -2.8434593428571446
    ],
    [
        17.5936,
        -2.8448138428571426
    ],
    [
        17.604,
        -3.996768690476191
    ],
    [
        17.6144,
        -7.943096733333334
    ],
    [
        17.6247,
        -8.929983504761905
    ],
    [
        17.6351,
        -8.560036414285715
    ],
    [
        17.6454,
        -8.560036414285715
    ],
    [
        17.6558,
        -14.728078742857141
    ],
    [
        17.6662,
        -4.861242761904761
    ],
    [
        17.6765,
        -2.3943644571428586
    ],
    [
        17.6869,
        -3.0111686904761923
    ],
    [
        17.6972,
        0.2782281333333328
    ],
    [
        17.7076,
        -0.8179364761904756
    ],
    [
        17.718,
        -5.7516930904761905
    ],
    [
        17.7283,
        1.6489418285714297
    ],
    [
        17.7387,
        0.8266490619047628
    ],
    [
        17.7491,
        4.11593300952381
    ],
    [
        17.7594,
        2.022824142857143
    ],
    [
        17.7698,
        1.6119035095238112
    ],
    [
        17.7801,
        3.0920304904761915
    ],
    [
        17.7905,
        -2.290187885714284
    ],
    [
        17.8009,
        -0.31668524285714317
    ],
    [
        17.8112,
        0.9177697857142865
    ],
    [
        17.8216,
        2.069047385714286
    ],
    [
        17.8319,
        2.727107347619049
    ],
    [
        17.8423,
        1.2471158142857155
    ],
    [
        17.8527,
        1.9049500285714291
    ],
    [
        17.863,
        4.372505585714286
    ],
    [
        17.8734,
        8.484759552380954
    ],
    [
        17.8837,
        4.099875366666668
    ],
    [
        17.8941,
        -0.8345584952380941
    ],
    [
        17.9045,
        -2.6846325714285695
    ],
    [
        17.9148,
        1.4269441523809538
    ],
    [
        17.9252,
        -1.5886937095238083
    ],
    [
        17.9356,
        1.4260411523809529
    ],
    [
        17.9459,
        1.4260411523809533
    ],
    [
        17.9563,
        3.0708524380952387
    ],
    [
        17.9666,
        2.248785419047619
    ],
    [
        17.977,
        5.126979423809524
    ],
    [
        17.9874,
        2.6601011142857147
    ],
    [
        17.9977,
        3.318070780952381
    ],
    [
        18.0081,
        9.07423303809524
    ],
    [
        18.0184,
        3.1542669000000023
    ],
    [
        18.0288,
        5.374592823809525
    ],
    [
        18.0392,
        3.1817368571428584
    ],
    [
        18.0495,
        4.003803876190476
    ],
    [
        18.0599,
        7.457433504761907
    ],
    [
        18.0703,
        7.046456433333333
    ],
    [
        18.0806,
        3.3454617238095232
    ],
    [
        18.091,
        4.8724639904761915
    ],
    [
        18.1013,
        -0.6101650523809523
    ],
    [
        18.1117,
        3.33765293809524
    ],
    [
        18.1221,
        5.1877270142857155
    ],
    [
        18.1324,
        3.5430286000000004
    ],
    [
        18.1428,
        8.476785214285716
    ],
    [
        18.1531,
        6.941703261904763
    ],
    [
        18.1635,
        2.4190930333333336
    ],
    [
        18.1739,
        0.39481410476190537
    ],
    [
        18.1842,
        -0.7016514999999993
    ],
    [
        18.1946,
        -0.7016515000000002
    ],
    [
        18.205,
        -0.0439075809523806
    ],
    [
        18.2153,
        -0.04368183333333436
    ],
    [
        18.2257,
        4.06699189047619
    ],
    [
        18.236,
        10.644882542857143
    ],
    [
        18.2464,
        7.190778838095238
    ],
    [
        18.2568,
        7.739049266666667
    ],
    [
        18.2671,
        6.916756500000001
    ],
    [
        18.2775,
        6.752297942857142
    ],
    [
        18.2878,
        6.7521286285714295
    ],
    [
        18.2982,
        3.05113391904762
    ],
    [
        18.3086,
        3.9911228333333333
    ],
    [
        18.3189,
        3.9924773285714266
    ],
    [
        18.3293,
        2.5118762666666647
    ],
    [
        18.3396,
        4.3619503380952365
    ],
    [
        18.35,
        8.47386568095238
    ],
    [
        18.3604,
        7.651911538095238
    ],
    [
        18.3707,
        3.7043644476190476
    ],
    [
        18.3811,
        4.526657214285715
    ],
    [
        18.3915,
        7.373003161904762
    ],
    [
        18.4018,
        7.529479852380953
    ],
    [
        18.4122,
        8.76308831904762
    ],
    [
        18.4225,
        7.28316450952381
    ],
    [
        18.4329,
        3.1711362904761917
    ],
    [
        18.4433,
        -1.2136726476190474
    ],
    [
        18.4536,
        2.075272680952382
    ],
    [
        18.464,
        3.994219185714287
    ],
    [
        18.4743,
        4.610938761904763
    ],
    [
        18.4847,
        1.321541938095239
    ],
    [
        18.4951,
        5.4323285380952395
    ],
    [
        18.5054,
        5.18591160952381
    ],
    [
        18.5158,
        10.120345471428573
    ],
    [
        18.5262,
        13.409968047619047
    ],
    [
        18.5365,
        20.809587095238097
    ],
    [
        18.5469,
        23.981819899999998
    ],
    [
        18.5572,
        26.449375457142857
    ],
    [
        18.5676,
        26.448020961904767
    ],
    [
        18.578,
        25.790186747619053
    ],
    [
        18.5883,
        30.27603571428572
    ],
    [
        18.5987,
        30.029212433333335
    ],
    [
        18.609,
        32.49676799047619
    ],
    [
        18.6194,
        33.02555408571428
    ],
    [
        18.6298,
        35.49141651904762
    ],
    [
        18.6401,
        36.313144914285715
    ],
    [
        18.6505,
        39.22894674285714
    ],
    [
        18.6609,
        37.97575719047619
    ],
    [
        18.6712,
        33.042000576190475
    ],
    [
        18.6816,
        34.412676647619044
    ],
    [
        18.6919,
        33.79595707142857
    ],
    [
        18.7023,
        33.79595707142857
    ],
    [
        18.7127,
        29.685057599999997
    ],
    [
        18.723,
        33.63246923809523
    ],
    [
        18.7334,
        38.5655486047619
    ],
    [
        18.7437,
        35.27637752857144
    ],
    [
        18.7541,
        34.45419763809524
    ],
    [
        18.7645,
        32.76229499523809
    ],
    [
        18.7748,
        28.814612457142857
    ],
    [
        18.7852,
        28.815966952380947
    ],
    [
        18.7956,
        29.474026914285712
    ],
    [
        18.8059,
        28.278252019047613
    ],
    [
        18.8163,
        32.224715514285705
    ],
    [
        18.8266,
        28.277168423809528
    ],
    [
        18.837,
        25.39897441904762
    ],
    [
        18.8474,
        22.933450609523806
    ],
    [
        18.8577,
        25.026846790476196
    ],
    [
        18.8681,
        23.756082000000003
    ],
    [
        18.8784,
        24.461001123809524
    ],
    [
        18.8888,
        25.283293890476187
    ],
    [
        18.8992,
        19.52645438571429
    ],
    [
        18.9095,
        17.8816431
    ],
    [
        18.9199,
        19.878817195238092
    ],
    [
        18.9302,
        16.590097619047615
    ],
    [
        18.9406,
        16.590097619047615
    ],
    [
        18.951,
        10.95219588095238
    ],
    [
        18.9613,
        10.011948966666669
    ],
    [
        18.9717,
        7.380702404761905
    ],
    [
        18.9821,
        9.072314800000001
    ],
    [
        18.9924,
        9.0723148
    ],
    [
        19.0028,
        9.0723148
    ],
    [
        19.0131,
        2.4944241476190467
    ],
    [
        19.0235,
        4.138783938095237
    ],
    [
        19.0339,
        1.6722442523809529
    ],
    [
        19.0442,
        -4.247721885714286
    ],
    [
        19.0546,
        -4.247947633333334
    ],
    [
        19.0649,
        -6.098698957142856
    ],
    [
        19.0753,
        -6.547283852380952
    ],
    [
        19.0857,
        -4.902698314285714
    ],
    [
        19.096,
        -7.369576619047619
    ],
    [
        19.1064,
        -3.2587900190476184
    ],
    [
        19.1168,
        4.142860776190474
    ],
    [
        19.1271,
        6.404306980952379
    ],
    [
        19.1375,
        4.818335704761902
    ],
    [
        19.1478,
        9.752092319047616
    ],
    [
        19.1582,
        3.5843886142857135
    ],
    [
        19.1686,
        5.5228194523809515
    ],
    [
        19.1789,
        6.462614866666666
    ],
    [
        19.1893,
        4.982623333333332
    ],
    [
        19.1996,
        9.212331571428571
    ],
    [
        19.21,
        9.459188714285713
    ],
    [
        19.2204,
        5.758024695238095
    ],
    [
        19.2307,
        5.758024695238095
    ],
    [
        19.2411,
        6.580430338095239
    ],
    [
        19.2515,
        3.1270038857142857
    ],
    [
        19.2618,
        9.046834576190477
    ],
    [
        19.2722,
        15.626079723809523
    ],
    [
        19.2825,
        14.461005061904762
    ],
    [
        19.2929,
        14.954312999999999
    ],
    [
        19.3033,
        15.776718642857144
    ],
    [
        19.3136,
        13.30916308571428
    ],
    [
        19.324,
        13.31051758095238
    ],
    [
        19.3343,
        9.856684776190475
    ],
    [
        19.3447,
        9.788169785714283
    ],
    [
        19.3551,
        12.255386719047616
    ],
    [
        19.3654,
        8.55473063333333
    ],
    [
        19.3758,
        10.774887247619047
    ],
    [
        19.3862,
        10.24629465238095
    ],
    [
        19.3965,
        7.61473204285714
    ],
    [
        19.4069,
        12.548488652380952
    ],
    [
        19.4172,
        8.600806114285714
    ],
    [
        19.4276,
        2.432763785714286
    ],
    [
        19.438,
        2.843853733333332
    ],
    [
        19.4483,
        12.71136696190476
    ],
    [
        19.4587,
        12.875712642857142
    ],
    [
        19.469,
        12.218239623809522
    ],
    [
        19.4794,
        12.465096766666667
    ],
    [
        19.4898,
        2.595551795238097
    ],
    [
        19.5001,
        8.077578838095238
    ],
    [
        19.5105,
        8.817879366666668
    ],
    [
        19.5208,
        5.939798238095237
    ],
    [
        19.5312,
        11.490867019047622
    ],
    [
        19.5416,
        1.6219992952380957
    ],
    [
        19.5519,
        0.14139823333333537
    ],
    [
        19.5623,
        0.29810067619047875
    ],
    [
        19.5727,
        -0.9355077904761897
    ],
    [
        19.583,
        1.2845472333333372
    ],
    [
        19.5934,
        1.1200661047619047
    ],
    [
        19.6037,
        -0.4074682857142846
    ],
    [
        19.6141,
        1.401575804761905
    ],
    [
        19.6245,
        -5.998381866666666
    ],
    [
        19.6348,
        -8.218741657142857
    ],
    [
        19.6452,
        -2.297691923809522
    ],
    [
        19.6555,
        -2.708443247619048
    ],
    [
        19.6659,
        -6.409268642857141
    ],
    [
        19.6763,
        -9.862898271428568
    ],
    [
        19.6866,
        -10.68507816190476
    ],
    [
        19.697,
        -11.213767504761902
    ],
    [
        19.7074,
        -6.278656395238094
    ],
    [
        19.7177,
        -9.97880454285714
    ],
    [
        19.7281,
        -6.277979147619047
    ],
    [
        19.7384,
        -6.689069095238092
    ],
    [
        19.7488,
        -7.3057040142857135
    ],
    [
        19.7592,
        -1.5490902571428573
    ],
    [
        19.7695,
        -4.015629938095239
    ],
    [
        19.7799,
        -3.4866745333333347
    ],
    [
        19.7902,
        -3.4866745333333347
    ],
    [
        19.8006,
        -4.473290404761906
    ],
    [
        19.811,
        -4.8021172095238125
    ],
    [
        19.8213,
        -5.212868533333336
    ],
    [
        19.8317,
        -4.390350014285716
    ],
    [
        19.8421,
        -1.1020819333333347
    ],
    [
        19.8524,
        1.3647963761904747
    ],
    [
        19.8628,
        -4.556253357142857
    ],
    [
        19.8731,
        -4.803110500000001
    ],
    [
        19.8835,
        -6.653353885714285
    ],
    [
        19.8939,
        -3.1995888095238105
    ],
    [
        19.9042,
        -0.18477869999999946
    ],
    [
        19.9146,
        -2.12330629047619
    ],
    [
        19.9249,
        2.811127571428571
    ],
    [
        19.9353,
        2.564134976190475
    ],
    [
        19.9457,
        -3.603568728571431
    ],
    [
        19.956,
        -0.9720512666666697
    ],
    [
        19.9664,
        0.6723085238095207
    ],
    [
        19.9767,
        2.3170069333333316
    ],
    [
        19.9871,
        8.895574833333333
    ],
    [
        19.9975,
        2.7287176904761887
    ],
    [
        20.0078,
        4.373528976190474
    ],
    [
        20.0182,
        3.66860985238095
    ],
    [
        20.0286,
        4.161917785714285
    ],
    [
        20.0389,
        7.862404561904759
    ],
    [
        20.0493,
        4.573233485714284
    ],
    [
        20.0596,
        6.834849004761902
    ],
    [
        20.07,
        10.534658528571427
    ],
    [
        20.0804,
        10.534658528571427
    ],
    [
        20.0907,
        8.842988080952377
    ],
    [
        20.1011,
        10.076342576190475
    ],
    [
        20.1114,
        10.322725647619047
    ],
    [
        20.1218,
        10.761341990476188
    ],
    [
        20.1322,
        10.761003366666664
    ],
    [
        20.1425,
        7.059839347619048
    ],
    [
        20.1529,
        11.007657338095239
    ],
    [
        20.1633,
        17.174006547619047
    ],
    [
        20.1736,
        16.186848876190478
    ],
    [
        20.184,
        15.364781852380954
    ],
    [
        20.1943,
        11.66429508095238
    ],
    [
        20.2047,
        11.664069333333334
    ],
    [
        20.2151,
        18.24195998571429
    ],
    [
        20.2254,
        17.30187432380953
    ],
    [
        20.2358,
        18.00679344761905
    ],
    [
        20.2461,
        21.954340538095238
    ],
    [
        20.2565,
        17.019906671428572
    ],
    [
        20.2669,
        17.019906671428572
    ],
    [
        20.2772,
        17.389989209523808
    ],
    [
        20.2876,
        14.10149538095238
    ],
    [
        20.298,
        23.970363104761905
    ],
    [
        20.3083,
        29.60971609047619
    ],
    [
        20.3187,
        29.815176409523808
    ],
    [
        20.329,
        27.34863672380952
    ],
    [
        20.3394,
        27.595358419047617
    ],
    [
        20.3498,
        28.829305509523813
    ],
    [
        20.3601,
        25.129495985714286
    ],
    [
        20.3705,
        21.429009214285713
    ],
    [
        20.3808,
        17.72919969047619
    ],
    [
        20.3912,
        17.72919969047619
    ],
    [
        20.4016,
        15.67329845714286
    ],
    [
        20.4119,
        17.611632547619045
    ],
    [
        20.4223,
        14.733438542857145
    ],
    [
        20.4326,
        13.636897685714287
    ],
    [
        20.443,
        13.918787933333332
    ],
    [
        20.4534,
        13.096156538095236
    ],
    [
        20.4637,
        9.148338547619046
    ],
    [
        20.4741,
        9.970969942857142
    ],
    [
        20.4845,
        14.082546666666666
    ],
    [
        20.4948,
        18.02941650952381
    ],
    [
        20.5052,
        17.371582295238095
    ],
    [
        20.5155,
        17.371582295238092
    ],
    [
        20.5259,
        11.203878590476188
    ],
    [
        20.5363,
        10.929818623809524
    ],
    [
        20.5466,
        17.098876828571427
    ],
    [
        20.557,
        10.9308345
    ],
    [
        20.5673,
        5.9964006380952375
    ],
    [
        20.5777,
        9.167665942857143
    ],
    [
        20.5881,
        7.933718852380952
    ],
    [
        20.5984,
        6.70044900952381
    ],
    [
        20.6088,
        7.405271380952382
    ],
    [
        20.6192,
        6.172001542857141
    ],
    [
        20.6295,
        10.40170978095238
    ],
    [
        20.6399,
        12.621934119047623
    ],
    [
        20.6502,
        14.540880623809525
    ],
    [
        20.6606,
        8.620508138095236
    ],
    [
        20.671,
        11.088176571428573
    ],
    [
        20.6813,
        11.746371985714283
    ],
    [
        20.6917,
        13.39095752380953
    ],
    [
        20.702,
        15.857497204761904
    ],
    [
        20.7124,
        12.157349057142858
    ],
    [
        20.7228,
        12.4042062
    ],
    [
        20.7331,
        2.5353384761904776
    ],
    [
        20.7435,
        4.473333942857145
    ],
    [
        20.7539,
        3.431906076190477
    ],
    [
        20.7642,
        -0.6815895190476194
    ],
    [
        20.7746,
        6.7203999
    ],
    [
        20.7849,
        13.299645047619046
    ],
    [
        20.7953,
        11.8900003
    ],
    [
        20.8057,
        12.506635219047618
    ],
    [
        20.816,
        11.519883895238095
    ],
    [
        20.8264,
        11.801948295238095
    ],
    [
        20.8367,
        9.335747233333331
    ],
    [
        20.8471,
        4.949583799999999
    ],
    [
        20.8575,
        2.976081157142859
    ],
    [
        20.8678,
        1.1254991428571417
    ],
    [
        20.8782,
        1.1248218952380975
    ],
    [
        20.8886,
        -0.5202151428571404
    ],
    [
        20.8989,
        -7.0994602904761885
    ],
    [
        20.9093,
        -4.632581985714284
    ],
    [
        20.9196,
        -9.566338599999998
    ],
    [
        20.93,
        -10.351423738095237
    ],
    [
        20.9404,
        -16.51811157142857
    ],
    [
        20.9507,
        -11.583677709523808
    ],
    [
        20.9611,
        -7.353969471428572
    ],
    [
        20.9714,
        -7.5653871571428555
    ],
    [
        20.9818,
        -7.939095028571428
    ],
    [
        20.9922,
        -10.406650585714287
    ],
    [
        21.0025,
        -13.696047409523809
    ],
    [
        21.0129,
        -12.004241514285711
    ],
    [
        21.0233,
        -10.1536595
    ],
    [
        21.0336,
        -9.166908176190477
    ],
    [
        21.044,
        -11.387403414285714
    ],
    [
        21.0543,
        -6.005862285714286
    ],
    [
        21.0647,
        -4.909547176190477
    ],
    [
        21.0751,
        -5.156438180952382
    ],
    [
        21.0854,
        -4.5396339476190475
    ],
    [
        21.0958,
        -1.2497856238095242
    ],
    [
        21.1061,
        3.6846482380952374
    ],
    [
        21.1165,
        9.32390447142857
    ],
    [
        21.1269,
        5.870139395238095
    ],
    [
        21.1372,
        6.31872429047619
    ],
    [
        21.1476,
        3.40366128095238
    ],
    [
        21.1579,
        9.04165977142857
    ],
    [
        21.1683,
        7.397299980952379
    ],
    [
        21.1787,
        1.2294269666666666
    ],
    [
        21.189,
        3.167761057142857
    ],
    [
        21.1994,
        7.654287271428571
    ],
    [
        21.2098,
        8.641174042857143
    ],
    [
        21.2201,
        9.052489738095238
    ],
    [
        21.2305,
        9.710188504761904
    ],
    [
        21.2408,
        8.887782857142856
    ],
    [
        21.2512,
        10.121391323809522
    ],
    [
        21.2616,
        10.650274166666666
    ],
    [
        21.2719,
        10.530774661904761
    ],
    [
        21.2823,
        7.2418293333333335
    ],
    [
        21.2926,
        7.242167957142856
    ],
    [
        21.303,
        6.7136479285714294
    ],
    [
        21.3134,
        10.003270504761904
    ],
    [
        21.3237,
        4.081949871428572
    ],
    [
        21.3341,
        4.1994202666666665
    ],
    [
        21.3445,
        2.719767357142857
    ],
    [
        21.3548,
        4.738399395238095
    ],
    [
        21.3652,
        8.191554947619048
    ],
    [
        21.3755,
        6.253027357142858
    ],
    [
        21.3859,
        10.36460408095238
    ],
    [
        21.3963,
        13.242403023809524
    ],
    [
        21.4066,
        16.942551171428573
    ],
    [
        21.417,
        14.474656995238096
    ],
    [
        21.4273,
        14.104659114285715
    ],
    [
        21.4377,
        14.633106585714287
    ],
    [
        21.4481,
        13.605155966666668
    ],
    [
        21.4584,
        10.315533395238097
    ],
    [
        21.4688,
        4.148845561904761
    ],
    [
        21.4792,
        6.087179652380952
    ],
    [
        21.4895,
        0.8237835333333333
    ],
    [
        21.4999,
        3.084862900000003
    ],
    [
        21.5102,
        4.318302052380953
    ],
    [
        21.5206,
        5.25796846666667
    ],
    [
        21.531,
        8.546688042857145
    ],
    [
        21.5413,
        7.888763528571429
    ],
    [
        21.5517,
        12.000904623809525
    ],
    [
        21.562,
        15.454060176190477
    ],
    [
        21.5724,
        15.454060176190477
    ],
    [
        21.5828,
        19.400523671428573
    ],
    [
        21.5931,
        19.40069298571429
    ],
    [
        21.6035,
        18.167253828571432
    ],
    [
        21.6138,
        17.139613614285718
    ],
    [
        21.6242,
        14.673073933333333
    ],
    [
        21.6346,
        17.139613614285718
    ],
    [
        21.6449,
        17.1394443
    ],
    [
        21.6553,
        17.350823285714288
    ],
    [
        21.6657,
        21.668622228571426
    ],
    [
        21.676,
        21.669073723809525
    ],
    [
        21.6864,
        27.424445857142857
    ],
    [
        21.6967,
        31.124594004761907
    ],
    [
        21.7071,
        37.29246701904761
    ],
    [
        21.7175,
        37.90935590952381
    ],
    [
        21.7278,
        36.26454462380953
    ],
    [
        21.7382,
        33.91503996190475
    ],
    [
        21.7485,
        33.91436271428571
    ],
    [
        21.7589,
        36.00775889523809
    ],
    [
        21.7693,
        32.71768482380952
    ],
    [
        21.7796,
        26.796635090476197
    ],
    [
        21.79,
        25.316304928571434
    ],
    [
        21.8004,
        19.678403190476192
    ],
    [
        21.8107,
        22.556202133333336
    ],
    [
        21.8211,
        22.027343480952386
    ],
    [
        21.8314,
        18.944084223809526
    ],
    [
        21.8418,
        21.412655652380952
    ],
    [
        21.8522,
        17.465785809523812
    ],
    [
        21.8625,
        15.204706442857146
    ],
    [
        21.8729,
        16.684765700000003
    ],
    [
        21.8832,
        14.217548771428575
    ],
    [
        21.8936,
        16.68431420476191
    ],
    [
        21.904,
        15.413498109523815
    ],
    [
        21.9143,
        11.18466061904762
    ],
    [
        21.9247,
        10.655947090476193
    ],
    [
        21.935,
        7.777978833333335
    ],
    [
        21.9454,
        9.916368957142858
    ],
    [
        21.9558,
        10.127844695238096
    ],
    [
        21.9661,
        7.661305009523811
    ],
    [
        21.9765,
        8.035125757142858
    ],
    [
        21.9869,
        3.924226285714287
    ],
    [
        21.9972,
        11.325538452380954
    ],
    [
        22.0076,
        7.87143474761905
    ],
    [
        22.0179,
        9.12452754761905
    ],
    [
        22.0283,
        5.013628076190479
    ],
    [
        22.0387,
        -0.6257249095238075
    ],
    [
        22.049,
        2.545733895238098
    ],
    [
        22.0594,
        -7.323133833333332
    ],
    [
        22.0697,
        -3.374232242857142
    ],
    [
        22.0801,
        -2.963142295238094
    ],
    [
        22.0905,
        -3.1274654
    ],
    [
        22.1008,
        -4.361073866666667
    ],
    [
        22.1112,
        -4.196863638095237
    ],
    [
        22.1216,
        -3.748340309523809
    ],
    [
        22.1319,
        -6.9204763666666675
    ],
    [
        22.1423,
        -9.269561780952378
    ],
    [
        22.1526,
        -7.176617095238095
    ],
    [
        22.163,
        -8.218466361904762
    ],
    [
        22.1734,
        -7.313933028571428
    ],
    [
        22.1837,
        -10.602878352380952
    ],
    [
        22.1941,
        -6.490850133333332
    ],
    [
        22.2044,
        -3.476115271428572
    ],
    [
        22.2148,
        -6.929880347619045
    ],
    [
        22.2252,
        -3.6404835190476197
    ],
    [
        22.2355,
        -2.5443941571428565
    ],
    [
        22.2459,
        -1.7231172619047617
    ],
    [
        22.2563,
        4.1979324714285715
    ],
    [
        22.2666,
        8.42880170952381
    ],
    [
        22.277,
        14.184060971428572
    ],
    [
        22.2873,
        8.261521285714284
    ],
    [
        22.2977,
        10.481542452380953
    ],
    [
        22.3081,
        12.126466619047617
    ],
    [
        22.3184,
        9.248272614285712
    ],
    [
        22.3288,
        9.248543514285712
    ],
    [
        22.3391,
        11.715083195238092
    ],
    [
        22.3495,
        19.115379495238095
    ],
    [
        22.3599,
        22.404550571428572
    ],
    [
        22.3702,
        23.189574142857143
    ],
    [
        22.3806,
        18.80341070952381
    ],
    [
        22.391,
        20.859537695238096
    ],
    [
        22.4013,
        20.448504185714288
    ],
    [
        22.4117,
        16.336250219047617
    ],
    [
        22.422,
        17.02183281428572
    ],
    [
        22.4324,
        18.71354195714286
    ],
    [
        22.4428,
        16.904475290476196
    ],
    [
        22.4531,
        20.19319486666667
    ],
    [
        22.4635,
        22.455177228571433
    ],
    [
        22.4738,
        20.76373898571429
    ],
    [
        22.4842,
        16.533063247619047
    ],
    [
        22.4946,
        18.177423033333334
    ],
    [
        22.5049,
        24.098608219047616
    ],
    [
        22.5153,
        23.934149661904762
    ],
    [
        22.5257,
        20.23366288571429
    ],
    [
        22.536,
        23.111856890476194
    ],
    [
        22.5464,
        23.358578585714287
    ],
    [
        22.5567,
        20.187313280952385
    ],
    [
        22.5671,
        17.721450847619046
    ],
    [
        22.5775,
        21.010396176190476
    ],
    [
        22.5878,
        20.599080476190476
    ],
    [
        22.5982,
        25.533514338095237
    ],
    [
        22.6085,
        23.06629740476191
    ],
    [
        22.6189,
        25.53317570952381
    ],
    [
        22.6293,
        25.944604280952387
    ],
    [
        22.6396,
        22.244286823809528
    ],
    [
        22.65,
        22.15609937142857
    ],
    [
        22.6603,
        21.49772335714286
    ],
    [
        22.6707,
        17.797913833333332
    ],
    [
        22.6811,
        18.414802723809522
    ],
    [
        22.6914,
        19.119625095238092
    ],
    [
        22.7018,
        16.769926933333334
    ],
    [
        22.7122,
        15.125792895238092
    ],
    [
        22.7225,
        7.724480728571432
    ],
    [
        22.7329,
        8.135627114285718
    ],
    [
        22.7432,
        14.303330819047622
    ],
    [
        22.7536,
        15.536262033333335
    ],
    [
        22.764,
        13.06938372380953
    ],
    [
        22.7743,
        14.596740742857147
    ],
    [
        22.7847,
        7.196444442857144
    ],
    [
        22.795,
        3.9072733666666646
    ],
    [
        22.8054,
        5.552536157142857
    ],
    [
        22.8158,
        6.53928748095238
    ],
    [
        22.8261,
        6.53928748095238
    ],
    [
        22.8365,
        5.305848328571427
    ],
    [
        22.8469,
        -0.861516752380953
    ],
    [
        22.8572,
        2.8386313952380933
    ],
    [
        22.8676,
        2.9267220999999983
    ],
    [
        22.8779,
        3.585233561904759
    ],
    [
        22.8883,
        7.286397580952378
    ],
    [
        22.8987,
        7.697374652380951
    ],
    [
        22.909,
        6.052563366666665
    ],
    [
        22.9194,
        8.93075737142857
    ],
    [
        22.9297,
        8.813174100000001
    ],
    [
        22.9401,
        16.213131771428568
    ],
    [
        22.9505,
        17.4475868
    ],
    [
        22.9608,
        11.690973042857145
    ],
    [
        22.9712,
        9.772553290476187
    ],
    [
        22.9815,
        10.300904009523808
    ],
    [
        22.9919,
        11.122858152380951
    ],
    [
        23.0023,
        18.523154452380954
    ],
    [
        23.0126,
        19.34544721904762
    ],
    [
        23.023,
        18.11150012857143
    ],
    [
        23.0334,
        14.657531871428573
    ],
    [
        23.0437,
        15.362354242857144
    ],
    [
        23.0541,
        15.362354242857144
    ],
    [
        23.0644,
        21.529719323809527
    ],
    [
        23.0748,
        18.651299571428574
    ],
    [
        23.0852,
        15.480034266666667
    ],
    [
        23.0955,
        17.70039405714286
    ],
    [
        23.1059,
        11.121374657142859
    ],
    [
        23.1162,
        10.181708242857146
    ],
    [
        23.1266,
        11.826519528571431
    ],
    [
        23.137,
        15.526667676190476
    ],
    [
        23.1473,
        16.05511514761905
    ],
    [
        23.1577,
        12.355644247619049
    ],
    [
        23.1681,
        11.532109857142862
    ],
    [
        23.1784,
        11.367538428571432
    ],
    [
        23.1888,
        12.46445553809524
    ],
    [
        23.1991,
        14.813734452380956
    ],
    [
        23.2095,
        13.169148914285714
    ],
    [
        23.2199,
        15.635688595238097
    ],
    [
        23.2302,
        14.813621576190476
    ],
    [
        23.2406,
        14.028598004761905
    ],
    [
        23.2509,
        16.49581493809524
    ],
    [
        23.2613,
        14.968812671428571
    ],
    [
        23.2717,
        15.955699442857142
    ],
    [
        23.282,
        19.655170342857144
    ],
    [
        23.2924,
        22.944567166666666
    ],
    [
        23.3028,
        27.87832378095238
    ],
    [
        23.3131,
        31.579149176190473
    ],
    [
        23.3235,
        34.868094504761906
    ],
    [
        23.3338,
        36.63073093809524
    ],
    [
        23.3442,
        34.9861454
    ],
    [
        23.3546,
        30.600508714285713
    ],
    [
        23.3649,
        24.43145050952381
    ],
    [
        23.3753,
        24.18445791428572
    ],
    [
        23.3856,
        21.8353725
    ],
    [
        23.396,
        27.756557685714288
    ],
    [
        23.4064,
        26.111520647619052
    ],
    [
        23.4167,
        29.40159471904762
    ],
    [
        23.4271,
        31.046406004761902
    ],
    [
        23.4375,
        27.345919233333326
    ],
    [
        23.4478,
        28.168324880952376
    ],
    [
        23.4582,
        30.186956919047617
    ],
    [
        23.4685,
        27.720417238095234
    ],
    [
        23.4789,
        29.365002776190472
    ],
    [
        23.4893,
        23.443682142857142
    ],
    [
        23.4996,
        23.443682142857146
    ],
    [
        23.51,
        21.7990966047619
    ],
    [
        23.5203,
        19.331879671428574
    ],
    [
        23.5307,
        15.219625704761906
    ],
    [
        23.5411,
        14.809213004761903
    ],
    [
        23.5514,
        12.341657447619045
    ],
    [
        23.5618,
        14.808422880952383
    ],
    [
        23.5721,
        15.247189723809527
    ],
    [
        23.5825,
        19.478252461904763
    ],
    [
        23.5929,
        18.49163659047619
    ],
    [
        23.6032,
        20.841173500000004
    ],
    [
        23.6136,
        15.90673963809524
    ],
    [
        23.624,
        15.90673963809524
    ],
    [
        23.6343,
        12.205744928571429
    ],
    [
        23.6447,
        13.027473323809524
    ],
    [
        23.655,
        12.287782319047619
    ],
    [
        23.6654,
        9.272294957142858
    ],
    [
        23.6758,
        7.791964795238097
    ],
    [
        23.6861,
        11.49177431904762
    ],
    [
        23.6965,
        9.847188780952383
    ],
    [
        23.7068,
        15.60402828571428
    ],
    [
        23.7172,
        15.605382780952382
    ],
    [
        23.7276,
        16.592134104761904
    ],
    [
        23.7379,
        15.358525638095237
    ],
    [
        23.7483,
        14.536345742857144
    ],
    [
        23.7587,
        13.85066908095238
    ],
    [
        23.769,
        8.91623521904762
    ],
    [
        23.7794,
        8.094055323809526
    ],
    [
        23.7897,
        12.040247919047617
    ],
    [
        23.8001,
        11.100162257142857
    ],
    [
        23.8105,
        11.511252204761906
    ],
    [
        23.8208,
        4.933361552380952
    ],
    [
        23.8312,
        3.2885502666666673
    ],
    [
        23.8415,
        5.7557672
    ],
    [
        23.8519,
        5.50894391904762
    ],
    [
        23.8623,
        1.397592952380954
    ],
    [
        23.8726,
        0.9040141142857135
    ],
    [
        23.883,
        2.6858930095238107
    ],
    [
        23.8934,
        2.5214118809523827
    ],
    [
        23.9037,
        -0.3566692476190476
    ],
    [
        23.9141,
        2.1098704380952378
    ],
    [
        23.9244,
        0.3005780238095243
    ],
    [
        23.9348,
        -5.620471709523809
    ],
    [
        23.9452,
        -6.058952604761903
    ],
    [
        23.9555,
        -4.002938495238095
    ],
    [
        23.9659,
        -3.0163226238095238
    ],
    [
        23.9762,
        1.3711953095238103
    ],
    [
        23.9866,
        8.772168852380952
    ],
    [
        23.997,
        10.005438695238094
    ],
    [
        24.0073,
        4.5240889
    ],
    [
        24.0177,
        6.521359747619047
    ],
    [
        24.0281,
        5.288089904761906
    ],
    [
        24.0384,
        10.221169271428572
    ],
    [
        24.0488,
        12.570706180952381
    ],
    [
        24.0591,
        12.570706180952381
    ],
    [
        24.0695,
        13.228901595238096
    ],
    [
        24.0799,
        14.05130723809524
    ],
    [
        24.0902,
        14.59957766666667
    ],
    [
        24.1006,
        8.431535338095241
    ],
    [
        24.1109,
        10.075669376190476
    ],
    [
        24.1213,
        11.720480661904764
    ],
    [
        24.1317,
        11.309729338095238
    ],
    [
        24.142,
        11.31000023809524
    ],
    [
        24.1524,
        13.941427395238097
    ],
    [
        24.1627,
        14.215336861904767
    ],
    [
        24.1731,
        11.748571428571433
    ],
    [
        24.1835,
        11.466874680952381
    ],
    [
        24.1938,
        5.708454923809522
    ],
    [
        24.2042,
        4.475185085714286
    ],
    [
        24.2146,
        3.241915242857143
    ],
    [
        24.2249,
        5.023455514285715
    ],
    [
        24.2353,
        5.119457714285716
    ],
    [
        24.2456,
        5.324664066666669
    ],
    [
        24.256,
        7.791203747619049
    ],
    [
        24.2664,
        7.086478123809524
    ],
    [
        24.2767,
        5.323986814285715
    ],
    [
        24.2871,
        8.61360939047619
    ],
    [
        24.2974,
        11.080826319047619
    ],
    [
        24.3078,
        9.827636766666666
    ],
    [
        24.3182,
        15.255514014285714
    ],
    [
        24.3285,
        17.723408195238097
    ],
    [
        24.3389,
        12.790328828571429
    ],
    [
        24.3493,
        10.323450519047617
    ],
    [
        24.3596,
        11.858336819047619
    ],
    [
        24.37,
        8.568714247619045
    ],
    [
        24.3803,
        2.8134549857142845
    ],
    [
        24.3907,
        5.691423242857141
    ],
    [
        24.4011,
        9.92093798095238
    ],
    [
        24.4114,
        11.448294999999996
    ],
    [
        24.4218,
        9.227867485714283
    ],
    [
        24.4321,
        10.872227276190474
    ],
    [
        24.4425,
        4.705878066666664
    ],
    [
        24.4529,
        4.257416304761901
    ],
    [
        24.4632,
        5.4225097761904735
    ],
    [
        24.4736,
        1.3111588095238071
    ],
    [
        24.484,
        6.245592671428568
    ],
    [
        24.4943,
        3.0736501142857113
    ],
    [
        24.5047,
        -1.244148828571431
    ],
    [
        24.515,
        -0.4210659333333348
    ],
    [
        24.5254,
        0.28385319047618857
    ],
    [
        24.5358,
        -0.2095901952380968
    ],
    [
        24.5461,
        -0.20959019523809547
    ],
    [
        24.5565,
        5.171950933333332
    ],
    [
        24.5668,
        5.171612309523809
    ],
    [
        24.5772,
        2.1568021999999996
    ],
    [
        24.5876,
        4.62379338095238
    ],
    [
        24.5979,
        10.544030419047616
    ],
    [
        24.6083,
        7.66606216190476
    ],
    [
        24.6187,
        5.198845233333333
    ],
    [
        24.629,
        3.6717139666666663
    ],
    [
        24.6394,
        5.645487509523809
    ],
    [
        24.6497,
        4.001127719047619
    ],
    [
        24.6601,
        10.168154176190475
    ],
    [
        24.6705,
        11.154770047619047
    ],
    [
        24.6808,
        8.961838833333335
    ],
    [
        24.6912,
        10.60665011904762
    ],
    [
        24.7015,
        6.905486100000003
    ],
    [
        24.7119,
        11.839919966666667
    ],
    [
        24.7223,
        13.690501980952382
    ],
    [
        24.7326,
        9.988999333333332
    ],
    [
        24.743,
        10.975886104761903
    ],
    [
        24.7533,
        15.908965471428571
    ],
    [
        24.7637,
        15.907610976190478
    ],
    [
        24.7741,
        20.394937576190475
    ],
    [
        24.7844,
        24.095085723809525
    ],
    [
        24.7948,
        28.2059851952381
    ],
    [
        24.8052,
        28.08872442380953
    ],
    [
        24.8155,
        25.868296909523814
    ],
    [
        24.8259,
        28.746265166666667
    ],
    [
        24.8362,
        26.279048233333334
    ],
    [
        24.8466,
        27.718088795238103
    ],
    [
        24.857,
        26.183352995238096
    ],
    [
        24.8673,
        26.731623423809527
    ],
    [
        24.8777,
        24.26406786666667
    ],
    [
        24.888,
        23.65691963809524
    ],
    [
        24.8984,
        24.853146028571434
    ],
    [
        24.9088,
        24.853146028571434
    ],
    [
        24.9191,
        24.606492057142862
    ],
    [
        24.9295,
        23.784086409523812
    ],
    [
        24.9399,
        16.38243561428572
    ],
    [
        24.9502,
        16.629157309523812
    ],
    [
        24.9606,
        16.629157309523812
    ],
    [
        24.9709,
        12.244197871428574
    ],
    [
        24.9813,
        6.077510038095238
    ],
    [
        24.9917,
        2.3749915190476187
    ],
    [
        25.002,
        -1.7359079523809522
    ],
    [
        25.0124,
        -2.8318468142857127
    ],
    [
        25.0227,
        -2.550150066666665
    ],
    [
        25.0331,
        -1.8650001523809512
    ],
    [
        25.0435,
        -4.332555709523808
    ],
    [
        25.0538,
        -3.510375814285713
    ],
    [
        25.0642,
        -3.1401239619047607
    ],
    [
        25.0746,
        -1.6051172619047602
    ],
    [
        25.0849,
        -1.448662066666666
    ],
    [
        25.0953,
        -1.8598648857142843
    ],
    [
        25.1056,
        2.6951013333333345
    ],
    [
        25.116,
        3.760321147619049
    ],
    [
        25.1264,
        2.5267126809523823
    ],
    [
        25.1367,
        0.3064883428571436
    ],
    [
        25.1471,
        -0.7216880238095223
    ],
    [
        25.1574,
        3.2255881666666673
    ],
    [
        25.1678,
        1.745935257142858
    ],
    [
        25.1782,
        1.3756834047619055
    ],
    [
        25.1885,
        0.8275634761904775
    ],
    [
        25.1989,
        4.527711623809526
    ],
    [
        25.2093,
        4.281328552380954
    ],
    [
        25.2196,
        5.477226580952382
    ],
    [
        25.23,
        9.86188501904762
    ],
    [
        25.2403,
        9.580188271428572
    ],
    [
        25.2507,
        11.36191666666667
    ],
    [
        25.2611,
        7.661429895238096
    ],
    [
        25.2714,
        5.605641533333336
    ],
    [
        25.2818,
        7.085294442857146
    ],
    [
        25.2921,
        6.920813314285717
    ],
    [
        25.3025,
        3.7488707571428597
    ],
    [
        25.3129,
        6.6269518857142895
    ],
    [
        25.3232,
        0.7056312523809529
    ],
    [
        25.3336,
        1.0758831047619057
    ],
    [
        25.3439,
        3.5427614142857156
    ],
    [
        25.3543,
        7.242909561904764
    ],
    [
        25.3647,
        9.094846071428574
    ],
    [
        25.375,
        11.068619614285716
    ],
    [
        25.3854,
        11.890799504761906
    ],
    [
        25.3958,
        11.274164585714288
    ],
    [
        25.4061,
        13.740704266666668
    ],
    [
        25.4165,
        16.20859844761905
    ],
    [
        25.4268,
        16.20859844761905
    ],
    [
        25.4372,
        21.590139576190477
    ],
    [
        25.4476,
        14.19052052857143
    ],
    [
        25.4579,
        18.420035266666666
    ],
    [
        25.4683,
        12.25199293809524
    ],
    [
        25.4786,
        22.12086066190476
    ],
    [
        25.489,
        23.73404096190476
    ],
    [
        25.4994,
        18.8009616
    ],
    [
        25.5097,
        20.445772890476192
    ],
    [
        25.5201,
        22.912989823809525
    ],
    [
        25.5305,
        16.74528611904762
    ],
    [
        25.5408,
        17.73217289047619
    ],
    [
        25.5512,
        17.361921038095236
    ],
    [
        25.5615,
        16.128651195238092
    ],
    [
        25.5719,
        8.729032147619044
    ],
    [
        25.5823,
        11.19489458095238
    ],
    [
        25.5926,
        12.67617289047619
    ],
    [
        25.603,
        13.333916809523808
    ],
    [
        25.6133,
        14.156096704761906
    ],
    [
        25.6237,
        12.138141919047618
    ],
    [
        25.6341,
        6.4989824333333335
    ],
    [
        25.6444,
        5.512095661904762
    ],
    [
        25.6548,
        1.2839354190476184
    ],
    [
        25.6651,
        8.683554466666665
    ],
    [
        25.6755,
        5.393931890476192
    ],
    [
        25.6859,
        10.32836575238095
    ],
    [
        25.6962,
        2.9267149571428583
    ],
    [
        25.7066,
        -2.387290738095237
    ],
    [
        25.717,
        1.0661357142857153
    ],
    [
        25.7273,
        0.24327857142857245
    ],
    [
        25.7377,
        0.24327857142857245
    ],
    [
        25.748,
        4.561077514285713
    ],
    [
        25.7584,
        4.940545157142855
    ],
    [
        25.7688,
        4.323910238095236
    ],
    [
        25.7791,
        1.8566933047619028
    ],
    [
        25.7895,
        8.434583957142857
    ],
    [
        25.7998,
        8.433229461904762
    ],
    [
        25.8102,
        5.965335280952383
    ],
    [
        25.8206,
        8.18555961904762
    ],
    [
        25.8309,
        9.830596657142857
    ],
    [
        25.8413,
        9.382011761904764
    ],
    [
        25.8517,
        12.553954319047621
    ],
    [
        25.862,
        10.909143033333336
    ],
    [
        25.8724,
        11.190839780952382
    ],
    [
        25.8827,
        11.193548776190477
    ],
    [
        25.8931,
        7.903926204761905
    ],
    [
        25.9035,
        8.232798161904762
    ],
    [
        25.9138,
        9.995095971428574
    ],
    [
        25.9242,
        15.75170972857143
    ],
    [
        25.9345,
        9.830389095238097
    ],
    [
        25.9449,
        13.120463166666665
    ],
    [
        25.9553,
        15.587002847619047
    ],
    [
        25.9656,
        14.970367923809524
    ],
    [
        25.976,
        15.82450874761905
    ],
    [
        25.9864,
        17.057778590476193
    ],
    [
        25.9967,
        18.29138705714286
    ],
    [
        26.0071,
        17.633733442857142
    ],
    [
        26.0174,
        14.345013866666667
    ],
    [
        26.0278,
        15.989825152380954
    ],
    [
        26.0382,
        17.22275636666667
    ],
    [
        26.0485,
        13.521930966666664
    ],
    [
        26.0589,
        13.521930966666664
    ],
    [
        26.0692,
        12.041600804761904
    ],
    [
        26.0796,
        14.67302796190476
    ],
    [
        26.09,
        8.753332723809521
    ],
    [
        26.1003,
        8.751978223809521
    ],
    [
        26.1107,
        7.928895328571426
    ],
    [
        26.121,
        8.833293214285712
    ],
    [
        26.1314,
        9.538212338095235
    ],
    [
        26.1418,
        13.64911180952381
    ],
    [
        26.1521,
        19.405725566666664
    ],
    [
        26.1625,
        9.536857842857145
    ],
    [
        26.1729,
        9.537535095238095
    ],
    [
        26.1832,
        11.757420809523808
    ],
    [
        26.1936,
        10.523812342857143
    ],
    [
        26.2039,
        11.757759433333332
    ],
    [
        26.2143,
        13.813773542857142
    ],
    [
        26.2247,
        11.593007404761904
    ],
    [
        26.235,
        5.014213752380951
    ],
    [
        26.2454,
        3.3694024666666658
    ],
    [
        26.2557,
        5.835942152380953
    ],
    [
        26.2661,
        8.05643739047619
    ],
    [
        26.2765,
        7.234257495238095
    ],
    [
        26.2868,
        6.658573547619049
    ],
    [
        26.2972,
        4.438349209523811
    ],
    [
        26.3075,
        14.304507942857143
    ],
    [
        26.3179,
        14.305862442857146
    ],
    [
        26.3283,
        17.595936519047623
    ],
    [
        26.3386,
        15.376050804761906
    ],
    [
        26.349,
        15.924471733333334
    ],
    [
        26.3594,
        6.058313000000003
    ],
    [
        26.3697,
        7.702672785714286
    ],
    [
        26.3801,
        10.169889714285718
    ],
    [
        26.3904,
        9.347032571428574
    ],
    [
        26.4008,
        10.005227985714285
    ],
    [
        26.4112,
        9.300308861904762
    ],
    [
        26.4215,
        6.833091928571427
    ],
    [
        26.4319,
        8.477903219047619
    ],
    [
        26.4422,
        11.355871476190476
    ],
    [
        26.4526,
        17.935116623809524
    ],
    [
        26.463,
        18.483387052380955
    ],
    [
        26.4733,
        11.9054964
    ],
    [
        26.4837,
        12.562969419047617
    ],
    [
        26.4941,
        11.740789523809527
    ],
    [
        26.5044,
        12.151992342857145
    ],
    [
        26.5148,
        10.919061128571427
    ],
    [
        26.5251,
        6.690900885714285
    ],
    [
        26.5355,
        0.7679548523809514
    ],
    [
        26.5459,
        0.9323908285714276
    ],
    [
        26.5562,
        -3.015427161904762
    ],
    [
        26.5666,
        -2.947213171428572
    ],
    [
        26.577,
        1.2809470714285724
    ],
    [
        26.5873,
        -0.4806734857142854
    ],
    [
        26.5977,
        0.7532736047619056
    ],
    [
        26.608,
        -5.825520047619046
    ],
    [
        26.6184,
        -6.648151438095239
    ],
    [
        26.6288,
        -4.709623847619046
    ],
    [
        26.6391,
        -6.3544351333333315
    ],
    [
        26.6495,
        -7.834765295238093
    ],
    [
        26.6598,
        -8.930854657142854
    ],
    [
        26.6702,
        -11.56255271428571
    ],
    [
        26.6806,
        -11.288643247619044
    ],
    [
        26.6909,
        -12.11037164285714
    ],
    [
        26.7013,
        -14.459844057142856
    ],
    [
        26.7116,
        -12.11075864285714
    ],
    [
        26.722,
        -11.69989444285714
    ],
    [
        26.7324,
        -10.878166047619045
    ],
    [
        26.7427,
        -10.596469299999997
    ],
    [
        26.7531,
        -10.431943023809522
    ],
    [
        26.7635,
        -10.59615325238095
    ],
    [
        26.7738,
        -4.0173596
    ],
    [
        26.7842,
        -7.1005341999999985
    ],
    [
        26.7945,
        -5.778810842857141
    ],
    [
        26.8049,
        -6.483729966666665
    ],
    [
        26.8153,
        -7.717677057142856
    ],
    [
        26.8256,
        -1.1384319095238093
    ],
    [
        26.836,
        -1.467168419047619
    ],
    [
        26.8463,
        -3.3174118047619054
    ],
    [
        26.8567,
        0.7946164142857145
    ],
    [
        26.8671,
        -2.6594872904761906
    ],
    [
        26.8774,
        -8.142191580952382
    ],
    [
        26.8878,
        -6.6619968714285696
    ],
    [
        26.8982,
        -5.016959833333333
    ],
    [
        26.9085,
        -3.7836899952380945
    ],
    [
        26.9189,
        -2.091961499999999
    ],
    [
        26.9292,
        -7.732475485714285
    ],
    [
        26.9396,
        -4.032665961904762
    ],
    [
        26.95,
        -4.032214466666666
    ],
    [
        26.9603,
        -4.031672666666666
    ],
    [
        26.9707,
        -0.7425015904761891
    ],
    [
        26.981,
        0.07945255714285657
    ],
    [
        26.9914,
        -0.85979460952381
    ],
    [
        27.0018,
        0.7845651761904756
    ],
    [
        27.0121,
        1.8125722285714274
    ],
    [
        27.0225,
        2.63497787142857
    ],
    [
        27.0328,
        5.102194804761905
    ],
    [
        27.0432,
        5.102194804761903
    ],
    [
        27.0536,
        3.9038322952380935
    ],
    [
        27.0639,
        5.754075680952381
    ],
    [
        27.0743,
        3.9915843714285706
    ],
    [
        27.0847,
        5.636395661904762
    ],
    [
        27.095,
        15.50390889047619
    ],
    [
        27.1054,
        15.627405185714286
    ],
    [
        27.1157,
        13.160188252380951
    ],
    [
        27.1261,
        13.406842223809525
    ],
    [
        27.1365,
        13.242631995238096
    ],
    [
        27.1468,
        17.473694733333335
    ],
    [
        27.1572,
        13.088735295238093
    ],
    [
        27.1675,
        12.265200904761901
    ],
    [
        27.1779,
        11.894847466666665
    ],
    [
        27.1883,
        8.441421014285716
    ],
    [
        27.1986,
        7.783677095238096
    ],
    [
        27.209,
        7.078564471428572
    ],
    [
        27.2194,
        12.833823733333334
    ],
    [
        27.2297,
        13.656229380952382
    ],
    [
        27.2401,
        15.301040671428574
    ],
    [
        27.2504,
        10.366606809523812
    ],
    [
        27.2608,
        9.95506536666667
    ],
    [
        27.2712,
        9.015624700000004
    ],
    [
        27.2815,
        12.719159090476193
    ],
    [
        27.2919,
        12.719159090476193
    ],
    [
        27.3022,
        11.897204942857146
    ],
    [
        27.3126,
        8.608936861904763
    ],
    [
        27.323,
        9.225402471428573
    ],
    [
        27.3333,
        9.225402471428573
    ],
    [
        27.3437,
        11.445965433333335
    ],
    [
        27.3541,
        9.918834166666668
    ],
    [
        27.3644,
        11.328285414285716
    ],
    [
        27.3748,
        9.54655701904762
    ],
    [
        27.3851,
        13.248059666666668
    ],
    [
        27.3955,
        17.565689295238094
    ],
    [
        27.4059,
        19.53946283809524
    ],
    [
        27.4162,
        16.908487176190476
    ],
    [
        27.4266,
        17.89537394761905
    ],
    [
        27.4369,
        14.60710586666667
    ],
    [
        27.4473,
        9.673349252380953
    ],
    [
        27.4577,
        12.140566180952382
    ],
    [
        27.468,
        18.061886814285714
    ],
    [
        27.4784,
        17.815232842857146
    ],
    [
        27.4887,
        20.446479404761902
    ],
    [
        27.4991,
        14.522449776190475
    ],
    [
        27.5095,
        12.58445430952381
    ],
    [
        27.5198,
        15.46276119047619
    ],
    [
        27.5302,
        13.817949904761903
    ],
    [
        27.5406,
        17.51775942857143
    ],
    [
        27.5509,
        19.984976361904764
    ],
    [
        27.5613,
        13.817949904761907
    ],
    [
        27.5716,
        15.345081171428571
    ],
    [
        27.582,
        12.173138614285715
    ],
    [
        27.5924,
        14.228926976190476
    ],
    [
        27.6027,
        8.06088464761905
    ],
    [
        27.6131,
        3.126450785714288
    ],
    [
        27.6234,
        2.84448313809524
    ],
    [
        27.6338,
        6.133654214285715
    ],
    [
        27.6442,
        0.2136880761904771
    ],
    [
        27.6545,
        -0.4442364380952392
    ],
    [
        27.6649,
        3.6671145285714277
    ],
    [
        27.6753,
        -1.7606272714285713
    ],
    [
        27.6856,
        -1.1031542523809523
    ],
    [
        27.696,
        -0.44450733809523885
    ],
    [
        27.7063,
        -1.4313941095238099
    ],
    [
        27.7167,
        0.3776725571428563
    ],
    [
        27.7271,
        1.6109423999999997
    ],
    [
        27.7374,
        5.310074676190475
    ],
    [
        27.7478,
        0.3756408142857141
    ],
    [
        27.7581,
        -4.557438552380954
    ],
    [
        27.7685,
        -9.491195166666667
    ],
    [
        27.7789,
        -4.178543966666668
    ],
    [
        27.7892,
        -6.645309400000002
    ],
    [
        27.7996,
        -5.411700933333333
    ],
    [
        27.81,
        -1.3008014619047632
    ],
    [
        27.8203,
        -1.302155961904762
    ],
    [
        27.8307,
        -0.3152691904761905
    ],
    [
        27.841,
        -0.1980084190476204
    ],
    [
        27.8514,
        -0.19800841904761904
    ],
    [
        27.8618,
        3.0902596619047635
    ],
    [
        27.8721,
        3.090259661904763
    ],
    [
        27.8825,
        1.4454483761904764
    ],
    [
        27.8928,
        -2.9956775476190463
    ],
    [
        27.9032,
        -3.653421466666666
    ],
    [
        27.9136,
        -5.298684252380951
    ],
    [
        27.9239,
        -0.36425039047619007
    ],
    [
        27.9343,
        1.2814638952380961
    ],
    [
        27.9446,
        -0.36289589523809423
    ],
    [
        27.955,
        -4.062705419047616
    ],
    [
        27.9654,
        -0.7730828428571422
    ],
    [
        27.9757,
        0.04909705238095289
    ],
    [
        27.9861,
        -2.4174426333333323
    ],
    [
        27.9965,
        -2.797014466666666
    ],
    [
        28.0068,
        1.314562257142859
    ],
    [
        28.0172,
        3.252896347619049
    ],
    [
        28.0275,
        -1.6801830190476181
    ],
    [
        28.0379,
        -1.6788285190476164
    ],
    [
        28.0483,
        2.2687185714285736
    ],
    [
        28.0586,
        3.0913499619047617
    ],
    [
        28.069,
        3.9130783571428585
    ],
    [
        28.0793,
        0.6248102761904752
    ],
    [
        28.0897,
        -0.36207649523809543
    ],
    [
        28.1001,
        0.6248102761904761
    ],
    [
        28.1104,
        8.025783819047618
    ],
    [
        28.1208,
        6.0522811761904745
    ],
    [
        28.1312,
        7.697092461904759
    ],
    [
        28.1415,
        1.9402529571428575
    ],
    [
        28.1519,
        -0.03433328571428626
    ],
    [
        28.1622,
        2.8436349714285702
    ],
    [
        28.1726,
        0.9935608952380941
    ],
    [
        28.183,
        3.255007099999999
    ],
    [
        28.1933,
        0.788015919047619
    ],
    [
        28.2037,
        8.187634966666666
    ],
    [
        28.214,
        6.542823680952381
    ],
    [
        28.2244,
        5.062493519047618
    ],
    [
        28.2348,
        -0.5753114714285724
    ],
    [
        28.2451,
        1.8919054619047608
    ],
    [
        28.2555,
        5.592730857142856
    ],
    [
        28.2659,
        5.592730857142856
    ],
    [
        28.2762,
        4.3965044666666655
    ],
    [
        28.2866,
        3.5743245761904747
    ],
    [
        28.2969,
        8.507403942857142
    ],
    [
        28.3073,
        9.494019814285714
    ],
    [
        28.3177,
        7.8024074190476185
    ],
    [
        28.328,
        5.8841381666666654
    ],
    [
        28.3384,
        9.33824187142857
    ],
    [
        28.3487,
        6.0486193
    ],
    [
        28.3591,
        5.226213657142857
    ],
    [
        28.3695,
        5.966175561904762
    ],
    [
        28.3798,
        4.732567095238096
    ],
    [
        28.3902,
        5.349371328571429
    ],
    [
        28.4006,
        5.437655533333334
    ],
    [
        28.4109,
        12.01690068095238
    ],
    [
        28.4213,
        4.617281633333332
    ],
    [
        28.4316,
        1.3276590571428568
    ],
    [
        28.442,
        1.5743807523809519
    ],
    [
        28.4524,
        8.152271404761905
    ],
    [
        28.4627,
        0.7506206095238118
    ],
    [
        28.4731,
        1.9842290761904762
    ],
    [
        28.4834,
        -0.48366510476190294
    ],
    [
        28.4938,
        1.5342896809523803
    ],
    [
        28.5042,
        1.5347411761904757
    ],
    [
        28.5145,
        0.8298220523809519
    ],
    [
        28.5249,
        -5.09122768095238
    ],
    [
        28.5352,
        -2.742142266666666
    ],
    [
        28.5456,
        -2.673777776190475
    ],
    [
        28.556,
        -0.20656084761904886
    ],
    [
        28.5663,
        1.438250438095239
    ],
    [
        28.5767,
        2.260656080952381
    ],
    [
        28.5871,
        1.6441904714285716
    ],
    [
        28.5974,
        -0.00016931904761869582
    ],
    [
        28.6078,
        0.4931386190476194
    ],
    [
        28.6181,
        -1.4453889714285708
    ],
    [
        28.6285,
        -3.9126059000000004
    ],
    [
        28.6389,
        2.00736023809524
    ],
    [
        28.6492,
        4.474577166666667
    ],
    [
        28.6596,
        0.7744290190476208
    ],
    [
        28.6699,
        -0.16546314285714286
    ],
    [
        28.6803,
        6.002579185714287
    ],
    [
        28.6907,
        1.0681453238095262
    ],
    [
        28.701,
        -3.864934038095237
    ],
    [
        28.7114,
        -6.331473719047619
    ],
    [
        28.7218,
        -6.7430151619047605
    ],
    [
        28.7321,
        -10.971175404761905
    ],
    [
        28.7425,
        -3.5695246095238096
    ],
    [
        28.7528,
        -2.7466674666666666
    ],
    [
        28.7632,
        -2.8151824571428574
    ],
    [
        28.7736,
        -8.572247714285718
    ],
    [
        28.7839,
        -7.955612795238098
    ],
    [
        28.7943,
        -8.777566942857144
    ],
    [
        28.8046,
        -7.749559890476191
    ],
    [
        28.815,
        -5.400280976190475
    ],
    [
        28.8254,
        -6.598508038095239
    ],
    [
        28.8357,
        -7.8321165047619035
    ],
    [
        28.8461,
        -9.594607814285714
    ],
    [
        28.8565,
        -10.581494585714287
    ],
    [
        28.8668,
        -5.647060719047619
    ],
    [
        28.8772,
        -5.647060719047618
    ],
    [
        28.8875,
        -4.707168557142857
    ],
    [
        28.8979,
        -4.295965738095238
    ],
    [
        28.9083,
        5.57154749047619
    ],
    [
        28.9186,
        7.544643785714286
    ],
    [
        28.929,
        8.531530557142858
    ],
    [
        28.9393,
        9.764800395238094
    ],
    [
        28.9497,
        16.34269104761905
    ],
    [
        28.9601,
        15.108743957142858
    ],
    [
        28.9704,
        11.65491115238095
    ],
    [
        28.9808,
        12.093677995238094
    ],
    [
        28.9911,
        15.38420357142857
    ],
    [
        29.0015,
        13.780952780952381
    ],
    [
        29.0119,
        16.042116804761903
    ],
    [
        29.0222,
        13.575125623809523
    ],
    [
        29.0326,
        13.363649885714283
    ],
    [
        29.043,
        11.601352076190476
    ],
    [
        29.0533,
        15.302177476190474
    ],
    [
        29.0637,
        9.664372485714285
    ],
    [
        29.074,
        9.664372485714285
    ],
    [
        29.0844,
        5.552795761904762
    ],
    [
        29.0948,
        9.006493114285712
    ],
    [
        29.1051,
        9.828898761904759
    ],
    [
        29.1155,
        9.417357319047621
    ],
    [
        29.1258,
        5.471164723809525
    ],
    [
        29.1362,
        4.977992238095239
    ],
    [
        29.1466,
        5.635465257142858
    ],
    [
        29.1569,
        3.991331219047617
    ],
    [
        29.1673,
        1.729715700000001
    ],
    [
        29.1777,
        2.962985538095239
    ],
    [
        29.188,
        4.936488180952383
    ],
    [
        29.1984,
        2.304790123809524
    ],
    [
        29.2087,
        -0.16310405714285772
    ],
    [
        29.2191,
        1.440485357142857
    ],
    [
        29.2295,
        -1.642858557142856
    ],
    [
        29.2398,
        -0.8206786619047625
    ],
    [
        29.2502,
        3.6203118142857136
    ],
    [
        29.2605,
        1.1544493809523797
    ],
    [
        29.2709,
        4.855274776190475
    ],
    [
        29.2813,
        11.433165428571428
    ],
    [
        29.2916,
        11.433165428571428
    ],
    [
        29.302,
        13.077525219047619
    ],
    [
        29.3123,
        14.557584480952379
    ],
    [
        29.3227,
        12.912773190476187
    ],
    [
        29.3331,
        10.85732345238095
    ],
    [
        29.3434,
        7.403219747619047
    ],
    [
        29.3538,
        9.21224126190476
    ],
    [
        29.3642,
        10.03442115238095
    ],
    [
        29.3745,
        9.828650428571429
    ],
    [
        29.3849,
        9.211846195238095
    ],
    [
        29.3952,
        7.567712157142857
    ],
    [
        29.4056,
        5.594209514285713
    ],
    [
        29.416,
        9.70691498095238
    ],
    [
        29.4263,
        12.173454661904762
    ],
    [
        29.4367,
        14.0230208
    ],
    [
        29.4471,
        11.555126619047618
    ],
    [
        29.4574,
        12.103397047619048
    ],
    [
        29.4678,
        6.675519799999999
    ],
    [
        29.4781,
        12.313518290476189
    ],
    [
        29.4885,
        6.392468557142856
    ],
    [
        29.4989,
        5.2959277
    ],
    [
        29.5092,
        2.829388019047619
    ],
    [
        29.5196,
        7.765176380952381
    ],
    [
        29.5299,
        6.1203650904761915
    ],
    [
        29.5403,
        4.064689604761905
    ],
    [
        29.5507,
        7.35340918095238
    ],
    [
        29.561,
        11.465437399999999
    ],
    [
        29.5714,
        10.643031757142856
    ],
    [
        29.5818,
        5.709952395238095
    ],
    [
        29.5921,
        5.5045203
    ],
    [
        29.6025,
        5.504858923809523
    ],
    [
        29.6128,
        6.327264566666666
    ],
    [
        29.6232,
        5.669520647619048
    ],
    [
        29.6336,
        4.435573557142857
    ],
    [
        29.6439,
        2.791439519047619
    ],
    [
        29.6543,
        -2.141639842857143
    ],
    [
        29.6646,
        2.792794019047619
    ],
    [
        29.675,
        -2.6899102714285723
    ],
    [
        29.6854,
        0.27007279523809435
    ],
    [
        29.6957,
        1.2099649571428561
    ],
    [
        29.7061,
        2.196580828571428
    ],
    [
        29.7164,
        4.1148500809523805
    ],
    [
        29.7268,
        4.11417282857143
    ],
    [
        29.7372,
        0.16527123809523778
    ],
    [
        29.7475,
        -0.6571344047619059
    ],
    [
        29.7579,
        5.5105692999999984
    ],
    [
        29.7683,
        3.866209514285713
    ],
    [
        29.7786,
        3.8657580190476173
    ],
    [
        29.789,
        2.632149552380951
    ],
    [
        29.7993,
        3.8654193904761898
    ],
    [
        29.8097,
        4.687825033333333
    ],
    [
        29.8201,
        6.907981647619047
    ],
    [
        29.8304,
        3.618810571428572
    ],
    [
        29.8408,
        6.085576004761904
    ],
    [
        29.8511,
        3.618020447619048
    ],
    [
        29.8615,
        4.028884647619047
    ],
    [
        29.8719,
        8.96264126190476
    ],
    [
        29.8822,
        11.429858195238094
    ],
    [
        29.8926,
        16.364292057142862
    ],
    [
        29.903,
        15.542563661904762
    ],
    [
        29.9133,
        18.830831742857143
    ],
    [
        29.9237,
        23.7652656047619
    ],
    [
        29.934,
        20.47632028095238
    ],
    [
        29.9444,
        24.587897004761906
    ],
    [
        29.9548,
        23.601010233333334
    ],
    [
        29.9651,
        24.42341587619048
    ],
    [
        29.9755,
        20.105616933333334
    ],
    [
        29.9858,
        19.283437038095236
    ],
    [
        29.9962,
        16.40546878095238
    ],
    [
        30.0066,
        18.187197176190473
    ],
    [
        30.0169,
        18.872196590476186
    ],
    [
        30.0273,
        18.186708052380947
    ],
    [
        30.0377,
        16.377415638095233
    ],
    [
        30.048,
        19.666586714285707
    ],
    [
        30.0584,
        19.66658671428571
    ],
    [
        30.0687,
        20.900195180952373
    ],
    [
        30.0791,
        17.1997084047619
    ],
    [
        30.0895,
        15.3492957047619
    ],
    [
        30.0998,
        12.470875947619044
    ],
    [
        30.1102,
        13.457762719047615
    ],
    [
        30.1205,
        12.517677057142857
    ],
    [
        30.1309,
        5.1180580095238115
    ],
    [
        30.1413,
        2.65084108095238
    ],
    [
        30.1516,
        4.706516566666667
    ],
    [
        30.162,
        1.416893990476191
    ],
    [
        30.1723,
        3.8841109238095237
    ],
    [
        30.1827,
        4.294975123809524
    ],
    [
        30.1931,
        6.144879885714286
    ],
    [
        30.2034,
        6.144879885714286
    ],
    [
        30.2138,
        12.311567719047622
    ],
    [
        30.2242,
        16.69923615238095
    ],
    [
        30.2345,
        18.069686476190476
    ],
    [
        30.2449,
        15.05502686666667
    ],
    [
        30.2552,
        14.890545738095241
    ],
    [
        30.2656,
        14.068140095238096
    ],
    [
        30.276,
        13.245734452380955
    ],
    [
        30.2863,
        10.778517519047622
    ],
    [
        30.2967,
        15.712274133333334
    ],
    [
        30.307,
        16.329078366666668
    ],
    [
        30.3174,
        17.562686833333334
    ],
    [
        30.3278,
        14.931440271428574
    ],
    [
        30.3381,
        15.871525933333338
    ],
    [
        30.3485,
        19.324952385714283
    ],
    [
        30.3589,
        16.857735452380957
    ],
    [
        30.3692,
        13.979654323809527
    ],
    [
        30.3796,
        14.63803033809524
    ],
    [
        30.3899,
        14.637353085714288
    ],
    [
        30.4003,
        15.049346028571433
    ],
    [
        30.4107,
        15.04934602857143
    ],
    [
        30.421,
        16.694157314285718
    ],
    [
        30.4314,
        12.747693819047615
    ],
    [
        30.4417,
        5.345365776190475
    ],
    [
        30.4521,
        5.228105004761904
    ],
    [
        30.4625,
        2.7602108238095244
    ],
    [
        30.4728,
        8.680176961904763
    ],
    [
        30.4832,
        2.923337457142858
    ],
    [
        30.4936,
        2.923337457142858
    ],
    [
        30.5039,
        5.390554390476191
    ],
    [
        30.5143,
        3.910901480952382
    ],
    [
        30.5246,
        1.443684547619048
    ],
    [
        30.535,
        5.5543582714285735
    ],
    [
        30.5454,
        8.021123704761907
    ],
    [
        30.5557,
        1.4432330523809536
    ],
    [
        30.5661,
        5.389425647619049
    ],
    [
        30.5764,
        10.325214009523812
    ],
    [
        30.5868,
        12.956641166666667
    ],
    [
        30.5972,
        13.943527938095242
    ],
    [
        30.6075,
        9.832176971428574
    ],
    [
        30.6179,
        8.891639809523813
    ],
    [
        30.6283,
        5.191830285714287
    ],
    [
        30.6386,
        4.1639925428571445
    ],
    [
        30.649,
        3.177376671428573
    ],
    [
        30.6593,
        4.410307885714288
    ],
    [
        30.6697,
        5.349748552380953
    ],
    [
        30.6801,
        9.050573947619048
    ],
    [
        30.6904,
        -0.8169392809523801
    ],
    [
        30.7008,
        4.939900223809524
    ],
    [
        30.7111,
        4.235174600000001
    ],
    [
        30.7215,
        4.543576714285716
    ],
    [
        30.7319,
        1.9118786571428585
    ],
    [
        30.7422,
        4.132102995238096
    ],
    [
        30.7526,
        -0.8009763714285708
    ],
    [
        30.7629,
        -2.6099978857142854
    ],
    [
        30.7733,
        2.3230814809523808
    ],
    [
        30.7837,
        -2.061727457142857
    ],
    [
        30.794,
        -7.8196957142857135
    ],
    [
        30.8044,
        -8.806311585714285
    ],
    [
        30.8148,
        -8.3128682
    ],
    [
        30.8251,
        -1.7349775476190463
    ],
    [
        30.8355,
        -2.439703171428571
    ],
    [
        30.8458,
        -1.9111589523809518
    ],
    [
        30.8562,
        -1.7059525999999987
    ],
    [
        30.8666,
        -0.47234413333333203
    ],
    [
        30.8769,
        -1.7059525999999994
    ],
    [
        30.8873,
        -2.363425619047618
    ],
    [
        30.8976,
        -1.9519970476190458
    ],
    [
        30.908,
        3.5992410476190484
    ],
    [
        30.9184,
        5.244052338095239
    ],
    [
        30.9287,
        3.4815610285714302
    ],
    [
        30.9391,
        5.640375847619049
    ],
    [
        30.9495,
        12.219620995238095
    ],
    [
        30.9598,
        8.766465442857143
    ],
    [
        30.9702,
        8.766465442857143
    ],
    [
        30.9805,
        12.22029824761905
    ],
    [
        30.9909,
        12.837102480952383
    ],
    [
        31.0013,
        12.28883205238095
    ],
    [
        31.0116,
        13.11101194761905
    ],
    [
        31.022,
        13.11101194761905
    ],
    [
        31.0323,
        10.151028880952381
    ],
    [
        31.0427,
        2.750732580952382
    ],
    [
        31.0531,
        3.984341047619049
    ],
    [
        31.0634,
        7.1556063523809526
    ],
    [
        31.0738,
        7.038345580952383
    ],
    [
        31.0842,
        7.449096904761905
    ],
    [
        31.0945,
        14.8507477
    ],
    [
        31.1049,
        12.219049642857142
    ],
    [
        31.1152,
        11.39641825238095
    ],
    [
        31.1256,
        12.013222485714286
    ],
    [
        31.136,
        11.190365342857143
    ],
    [
        31.1463,
        11.190365342857145
    ],
    [
        31.1567,
        9.339952642857144
    ],
    [
        31.167,
        3.857248352380953
    ],
    [
        31.1774,
        6.940422952380954
    ],
    [
        31.1878,
        6.235503828571431
    ],
    [
        31.1981,
        4.590466790476191
    ],
    [
        31.2085,
        5.206932400000001
    ],
    [
        31.2189,
        4.220045628571429
    ],
    [
        31.2292,
        5.864856914285714
    ],
    [
        31.2396,
        10.799290776190476
    ],
    [
        31.2499,
        13.970749580952383
    ],
    [
        31.2603,
        11.504887147619048
    ],
    [
        31.2707,
        11.504548523809527
    ],
    [
        31.281,
        7.804400376190479
    ],
    [
        31.2914,
        8.744292538095237
    ],
    [
        31.3017,
        7.0999327476190475
    ],
    [
        31.3121,
        4.632715819047617
    ],
    [
        31.3225,
        7.921886895238094
    ],
    [
        31.3328,
        8.333428338095237
    ],
    [
        31.3432,
        8.08697754285714
    ],
    [
        31.3535,
        6.44261775238095
    ],
    [
        31.3639,
        3.9767553190476175
    ],
    [
        31.3743,
        -1.5744827761904774
    ],
    [
        31.3846,
        -0.5777505857142871
    ],
    [
        31.395,
        -3.66160243809524
    ],
    [
        31.4054,
        -2.956683314285716
    ],
    [
        31.4157,
        -5.423223000000002
    ],
    [
        31.4261,
        -5.012133052380953
    ],
    [
        31.4364,
        -2.791637814285716
    ],
    [
        31.4468,
        -3.7315299761904774
    ],
    [
        31.4572,
        -9.899572304761906
    ],
    [
        31.4675,
        -10.604491428571428
    ],
    [
        31.4779,
        -3.2048723809523816
    ],
    [
        31.4882,
        -3.2045337571428583
    ],
    [
        31.4986,
        -2.9575411619047625
    ],
    [
        31.509,
        -2.135135514285716
    ],
    [
        31.5193,
        0.33208141904761745
    ],
    [
        31.5297,
        0.3320814190476181
    ],
    [
        31.5401,
        -0.4900984761904771
    ],
    [
        31.5504,
        -0.9016399190476204
    ],
    [
        31.5608,
        0.5784193428571411
    ],
    [
        31.5711,
        -4.356014519047621
    ],
    [
        31.5815,
        -1.0677464380952377
    ],
    [
        31.5919,
        5.099957266666667
    ],
    [
        31.6022,
        6.296306790476192
    ],
    [
        31.6126,
        9.996793566666666
    ],
    [
        31.6229,
        9.17461367142857
    ],
    [
        31.6333,
        5.884539600000003
    ],
    [
        31.6437,
        5.884991095238095
    ],
    [
        31.654,
        9.58581649047619
    ],
    [
        31.6644,
        9.703303009523811
    ],
    [
        31.6748,
        6.003832109523809
    ],
    [
        31.6851,
        10.93826597142857
    ],
    [
        31.6955,
        9.176645414285714
    ],
    [
        31.7058,
        7.2383113238095245
    ],
    [
        31.7162,
        8.224927195238095
    ],
    [
        31.7266,
        7.402521547619047
    ],
    [
        31.7369,
        5.757484509523809
    ],
    [
        31.7473,
        0.8230506428571419
    ],
    [
        31.7576,
        -0.9860160238095239
    ],
    [
        31.768,
        -4.275638600000001
    ],
    [
        31.7784,
        -6.294270638095237
    ],
    [
        31.7887,
        -1.3598367761904757
    ],
    [
        31.7991,
        1.5182443523809521
    ],
    [
        31.8094,
        -2.182242423809524
    ],
    [
        31.8198,
        -5.060662176190476
    ],
    [
        31.8302,
        -5.800624080952383
    ],
    [
        31.8405,
        -5.965330957142858
    ],
    [
        31.8509,
        0.6134626952380939
    ],
    [
        31.8613,
        -0.48307816190476266
    ],
    [
        31.8716,
        -6.239917666666669
    ],
    [
        31.882,
        -8.295931776190477
    ],
    [
        31.8923,
        -2.1292439428571432
    ],
    [
        31.9027,
        -7.063677804761904
    ],
    [
        31.9131,
        -5.302057247619048
    ],
    [
        31.9234,
        -2.952971833333334
    ],
    [
        31.9338,
        1.9814620285714273
    ],
    [
        31.9441,
        1.9814620285714273
    ],
    [
        31.9545,
        1.9814620285714277
    ],
    [
        31.9649,
        -0.4871094000000017
    ],
    [
        31.9752,
        1.7330472142857143
    ],
    [
        31.9856,
        4.20026414285714
    ],
    [
        31.996,
        1.2844623142857117
    ],
    [
        32.0063,
        -1.1820773666666693
    ],
    [
        32.0167,
        -1.5927158142857154
    ],
    [
        32.027,
        0.8745011190476172
    ],
    [
        32.0374,
        2.930289480952383
    ],
    [
        32.0478,
        2.4369815428571426
    ],
    [
        32.0581,
        -1.5092110523809528
    ],
    [
        32.0685,
        -5.620110523809524
    ],
    [
        32.0788,
        -11.104169314285716
    ],
    [
        32.0892,
        -8.636952385714286
    ],
    [
        32.0996,
        -9.8698836
    ],
    [
        32.1099,
        -6.169058204761906
    ],
    [
        32.1203,
        -5.346878309523811
    ],
    [
        32.1307,
        -7.31997460476191
    ],
    [
        32.141,
        -9.375537219047619
    ],
    [
        32.1514,
        -9.375537219047615
    ],
    [
        32.1617,
        -10.403713585714286
    ],
    [
        32.1721,
        -12.459501947619044
    ],
    [
        32.1825,
        -5.879353795238098
    ],
    [
        32.1928,
        -9.58051781904762
    ],
    [
        32.2032,
        -5.4684896000000025
    ],
    [
        32.2135,
        -4.64563245714286
    ],
    [
        32.2239,
        -1.5622885428571451
    ],
    [
        32.2343,
        -2.384919938095243
    ],
    [
        32.2446,
        2.548159428571424
    ],
    [
        32.255,
        -0.7419146476190503
    ],
    [
        32.2653,
        0.08026524761904479
    ],
    [
        32.2757,
        4.026728742857141
    ],
    [
        32.2861,
        7.198187547619047
    ],
    [
        32.2964,
        13.36724575238095
    ],
    [
        32.3068,
        10.076720180952382
    ],
    [
        32.3172,
        13.365439757142857
    ],
    [
        32.3275,
        5.964466214285714
    ],
    [
        32.3379,
        0.20920695238095277
    ],
    [
        32.3482,
        -0.2842364333333329
    ],
    [
        32.3586,
        -4.807467471428571
    ],
    [
        32.369,
        -12.209118266666668
    ],
    [
        32.3793,
        -7.891319323809524
    ],
    [
        32.3897,
        -4.191171176190476
    ],
    [
        32.4,
        -0.9010971047619077
    ],
    [
        32.4104,
        2.8004055428571424
    ],
    [
        32.4208,
        6.088673623809521
    ],
    [
        32.4311,
        8.966980504761906
    ],
    [
        32.4415,
        13.283255638095241
    ],
    [
        32.4519,
        15.749795319047621
    ],
    [
        32.4622,
        5.882282090476187
    ],
    [
        32.4726,
        9.054901895238093
    ],
    [
        32.4829,
        3.2996426333333324
    ],
    [
        32.4933,
        -0.6495298571428582
    ],
    [
        32.5037,
        1.112090699999999
    ],
    [
        32.514,
        -0.1211791428571455
    ],
    [
        32.5244,
        -0.12027614285714519
    ],
    [
        32.5347,
        -2.586815828571432
    ],
    [
        32.5451,
        0.7021294952380944
    ],
    [
        32.5555,
        5.635208861904762
    ],
    [
        32.5658,
        5.635208861904762
    ],
    [
        32.5762,
        10.569642723809528
    ],
    [
        32.5866,
        14.023746428571432
    ],
    [
        32.5969,
        14.022391933333335
    ],
    [
        32.6073,
        6.620063890476193
    ],
    [
        32.6176,
        6.618709395238095
    ],
    [
        32.628,
        4.562695285714288
    ],
    [
        32.6384,
        0.17788634761904748
    ],
    [
        32.6487,
        3.8770186238095254
    ],
    [
        32.6591,
        1.4104789380952365
    ],
    [
        32.6694,
        0.5892020428571415
    ],
    [
        32.6798,
        4.818910280952382
    ],
    [
        32.6902,
        4.9363645523809545
    ],
    [
        32.7005,
        8.882557147619046
    ],
    [
        32.7109,
        14.804961380952383
    ],
    [
        32.7213,
        11.104474609523809
    ],
    [
        32.7316,
        11.652745038095237
    ],
    [
        32.742,
        14.530826166666667
    ],
    [
        32.7523,
        15.188299185714289
    ],
    [
        32.7627,
        13.132623700000002
    ],
    [
        32.7731,
        13.680894128571433
    ],
    [
        32.7834,
        18.615327990476192
    ],
    [
        32.7938,
        17.381719523809526
    ],
    [
        32.8041,
        16.88814069047619
    ],
    [
        32.8145,
        10.310250038095237
    ],
    [
        32.8249,
        16.89039819047619
    ],
    [
        32.8352,
        13.191265914285712
    ],
    [
        32.8456,
        15.247280023809521
    ],
    [
        32.856,
        12.232469914285714
    ],
    [
        32.8663,
        4.832850866666668
    ],
    [
        32.8767,
        -0.10090574761904932
    ],
    [
        32.887,
        -1.252860595238096
    ],
    [
        32.8974,
        -1.2532475952380948
    ],
    [
        32.9078,
        -0.43106770000000083
    ],
    [
        32.9181,
        5.488898438095238
    ],
    [
        32.9285,
        5.2072016904761895
    ],
    [
        32.9388,
        4.796337495238095
    ],
    [
        32.9492,
        5.48133690952381
    ],
    [
        32.9596,
        2.6028042809523804
    ],
    [
        32.9699,
        -1.3433883142857144
    ],
    [
        32.9803,
        1.5346928142857144
    ],
    [
        32.9906,
        2.6312336714285713
    ],
    [
        33.001,
        -7.236279557142857
    ],
    [
        33.0114,
        -4.769401247619047
    ],
    [
        33.0217,
        -3.617446400000001
    ],
    [
        33.0321,
        -2.589270033333333
    ],
    [
        33.0425,
        -1.7670901428571444
    ],
    [
        33.0528,
        -2.2959246095238117
    ],
    [
        33.0632,
        -5.996072757142858
    ],
    [
        33.0735,
        -1.8851732857142873
    ],
    [
        33.0839,
        -1.8865277857142864
    ],
    [
        33.0943,
        1.8139589904761908
    ],
    [
        33.1046,
        2.307266923809524
    ],
    [
        33.115,
        7.946813409523808
    ],
    [
        33.1253,
        11.235532985714284
    ],
    [
        33.1357,
        5.685480076190475
    ],
    [
        33.1461,
        4.9805609523809515
    ],
    [
        33.1564,
        7.612259009523809
    ],
    [
        33.1668,
        6.927259595238094
    ],
    [
        33.1772,
        7.867603257142855
    ],
    [
        33.1875,
        10.334142942857143
    ],
    [
        33.1979,
        9.306135890476188
    ],
    [
        33.2082,
        7.661324604761905
    ],
    [
        33.2186,
        13.41658386666667
    ],
    [
        33.229,
        11.689870638095238
    ],
    [
        33.2393,
        10.59272778095238
    ],
    [
        33.2497,
        16.142442066666668
    ],
    [
        33.26,
        13.675902385714286
    ],
    [
        33.2704,
        14.204398228571428
    ],
    [
        33.2808,
        15.437668066666665
    ],
    [
        33.2911,
        15.438119561904763
    ],
    [
        33.3015,
        15.439474061904765
    ],
    [
        33.3119,
        19.141992580952383
    ],
    [
        33.3222,
        15.68883702857143
    ],
    [
        33.3326,
        12.398762957142857
    ],
    [
        33.3429,
        8.452570361904762
    ],
    [
        33.3533,
        7.424732619047619
    ],
    [
        33.3637,
        4.958192938095239
    ],
    [
        33.374,
        4.676418790476191
    ],
    [
        33.3844,
        4.744783280952382
    ],
    [
        33.3947,
        4.462815633333336
    ],
    [
        33.4051,
        9.39657224761905
    ],
    [
        33.4155,
        7.1354082238095256
    ],
    [
        33.4258,
        8.369355314285716
    ],
    [
        33.4362,
        8.369355314285716
    ],
    [
        33.4465,
        11.329338380952382
    ],
    [
        33.4569,
        9.794602580952382
    ],
    [
        33.4673,
        5.409793642857142
    ],
    [
        33.4776,
        4.587388
    ],
    [
        33.488,
        4.058892157142857
    ],
    [
        33.4984,
        4.305546128571429
    ],
    [
        33.5087,
        0.194195161904763
    ],
    [
        33.5191,
        4.305546128571429
    ],
    [
        33.5294,
        -0.08212230476190505
    ],
    [
        33.5398,
        1.3978015047619052
    ],
    [
        33.5502,
        1.3987044999999994
    ],
    [
        33.5605,
        2.0561775190476186
    ],
    [
        33.5709,
        2.467380338095237
    ],
    [
        33.5812,
        2.4667030857142858
    ],
    [
        33.5916,
        2.583996104761905
    ],
    [
        33.602,
        0.32254990000000017
    ],
    [
        33.6123,
        6.242516038095239
    ],
    [
        33.6227,
        1.308082171428572
    ],
    [
        33.6331,
        3.939057833333334
    ],
    [
        33.6434,
        5.172327676190475
    ],
    [
        33.6538,
        1.8836081000000005
    ],
    [
        33.6641,
        0.40422609047618996
    ],
    [
        33.6745,
        -1.0756977190476187
    ],
    [
        33.6849,
        -4.090507828571429
    ],
    [
        33.6952,
        -1.6228393999999997
    ],
    [
        33.7056,
        0.13926490952380988
    ],
    [
        33.7159,
        2.770692066666667
    ],
    [
        33.7263,
        0.30415238095237995
    ],
    [
        33.7367,
        1.125880776190475
    ],
    [
        33.747,
        1.8110306904761904
    ],
    [
        33.7574,
        4.442457847619047
    ],
    [
        33.7678,
        1.151932276190477
    ],
    [
        33.7781,
        4.4406518523809515
    ],
    [
        33.7885,
        0.7398264571428582
    ],
    [
        33.7988,
        3.9117690142857153
    ],
    [
        33.8092,
        4.734174657142859
    ],
    [
        33.8196,
        4.734174657142857
    ],
    [
        33.8299,
        4.735529152380952
    ],
    [
        33.8403,
        7.751016514285714
    ],
    [
        33.8506,
        5.777784771428571
    ],
    [
        33.861,
        -1.62251152857143
    ],
    [
        33.8714,
        1.8309149238095235
    ],
    [
        33.8817,
        -0.38985121428571423
    ],
    [
        33.8921,
        2.8990941095238076
    ],
    [
        33.9024,
        4.379017919047617
    ],
    [
        33.9128,
        7.668188995238094
    ],
    [
        33.9232,
        7.66751174285714
    ],
    [
        33.9335,
        8.489917390476188
    ],
    [
        33.9439,
        10.955102576190475
    ],
    [
        33.9543,
        9.105197814285711
    ],
    [
        33.9646,
        10.33846765238095
    ],
    [
        33.975,
        3.7605769999999987
    ],
    [
        33.9853,
        7.050651071428568
    ],
    [
        33.9957,
        7.052005566666663
    ],
    [
        34.0061,
        11.986439428571424
    ],
    [
        34.0164,
        12.926525090476186
    ],
    [
        34.0268,
        10.048105338095235
    ],
    [
        34.0371,
        10.459646780952376
    ],
    [
        34.0475,
        6.512099690476186
    ],
    [
        34.0579,
        10.896908628571424
    ],
    [
        34.0682,
        10.238261714285711
    ],
    [
        34.0786,
        16.40461092380952
    ],
    [
        34.089,
        16.40434002380952
    ],
    [
        34.0993,
        22.571027857142855
    ],
    [
        34.1097,
        21.74862221428571
    ],
    [
        34.12,
        20.26802115238095
    ],
    [
        34.1304,
        16.97885007619047
    ],
    [
        34.1408,
        15.06043032380952
    ],
    [
        34.1511,
        12.045093461904761
    ],
    [
        34.1615,
        15.33516753809524
    ],
    [
        34.1718,
        9.784098757142857
    ],
    [
        34.1822,
        2.381770714285714
    ],
    [
        34.1926,
        2.381770714285711
    ],
    [
        34.2029,
        1.970567895238094
    ],
    [
        34.2133,
        1.9692133999999997
    ],
    [
        34.2237,
        4.436430333333332
    ],
    [
        34.234,
        3.4084232809523822
    ],
    [
        34.2444,
        2.1748148142857135
    ],
    [
        34.2547,
        4.39497142857143
    ],
    [
        34.2651,
        2.9147767190476204
    ],
    [
        34.2755,
        -1.3135770238095241
    ],
    [
        34.2858,
        1.0366048857142882
    ],
    [
        34.2962,
        -1.1832808285714276
    ],
    [
        34.3065,
        -1.1827390285714277
    ],
    [
        34.3169,
        -7.7601781857142855
    ],
    [
        34.3273,
        -7.7601781857142855
    ],
    [
        34.3376,
        -2.8257443190476184
    ],
    [
        34.348,
        -2.0037901714285717
    ],
    [
        34.3584,
        -7.4876984619047615
    ],
    [
        34.3687,
        -5.70630869047619
    ],
    [
        34.3791,
        -7.515104457142859
    ],
    [
        34.3894,
        -0.11413091428571405
    ],
    [
        34.3998,
        4.821657447619048
    ],
    [
        34.4102,
        10.371541047619047
    ],
    [
        34.4205,
        8.433013457142856
    ],
    [
        34.4309,
        4.4868208619047625
    ],
    [
        34.4412,
        2.0196039285714287
    ],
    [
        34.4516,
        -1.063739985714286
    ],
    [
        34.462,
        1.9517473761904764
    ],
    [
        34.4723,
        5.899294466666666
    ],
    [
        34.4827,
        8.037413690476189
    ],
    [
        34.493,
        6.346072195238095
    ],
    [
        34.5034,
        10.574232438095237
    ],
    [
        34.5138,
        6.627768942857141
    ],
    [
        34.5241,
        7.285422557142856
    ],
    [
        34.5345,
        9.478203276190474
    ],
    [
        34.5449,
        11.944742957142855
    ],
    [
        34.5552,
        7.832714738095237
    ],
    [
        34.5656,
        2.898958123809523
    ],
    [
        34.5759,
        9.068016328571428
    ],
    [
        34.5863,
        6.1899352
    ],
    [
        34.5967,
        8.410498161904762
    ],
    [
        34.607,
        7.177228323809524
    ],
    [
        34.6174,
        6.4723092
    ],
    [
        34.6277,
        7.08877480952381
    ],
    [
        34.6381,
        10.260910866666666
    ],
    [
        34.6485,
        10.260910866666666
    ],
    [
        34.6588,
        11.247526738095237
    ],
    [
        34.6692,
        12.72690874761905
    ],
    [
        34.6796,
        10.945368476190477
    ],
    [
        34.6899,
        7.2442044571428585
    ],
    [
        34.7003,
        0.6649593095238109
    ],
    [
        34.7106,
        2.638191052380954
    ],
    [
        34.721,
        2.6381910523809555
    ],
    [
        34.7314,
        5.926007633333335
    ],
    [
        34.7417,
        4.985921971428573
    ],
    [
        34.7521,
        2.7931412523809547
    ],
    [
        34.7624,
        1.3137592428571456
    ],
    [
        34.7728,
        2.958570528571431
    ],
    [
        34.7832,
        2.9578932761904775
    ],
    [
        34.7935,
        2.9582319000000012
    ],
    [
        34.8039,
        7.070260119047623
    ],
    [
        34.8143,
        5.131587404761906
    ],
    [
        34.8246,
        5.13192602857143
    ],
    [
        34.835,
        7.481204942857144
    ],
    [
        34.8453,
        8.715829280952383
    ],
    [
        34.8557,
        6.248612347619049
    ],
    [
        34.8661,
        6.248612347619049
    ],
    [
        34.8764,
        4.275109704761906
    ],
    [
        34.8868,
        6.906627166666667
    ],
    [
        34.8971,
        13.072976376190478
    ],
    [
        34.9075,
        12.544819157142859
    ],
    [
        34.9179,
        16.93113309047619
    ],
    [
        34.9282,
        16.492637147619046
    ],
    [
        34.9386,
        12.546444552380953
    ],
    [
        34.949,
        11.725167657142858
    ],
    [
        34.9593,
        6.084653671428571
    ],
    [
        34.9697,
        9.373373252380954
    ],
    [
        34.98,
        8.386215580952383
    ],
    [
        34.9904,
        3.4517817190476214
    ],
    [
        35.0008,
        10.852078019047623
    ],
    [
        35.0111,
        12.085686485714287
    ],
    [
        35.0215,
        14.552903414285717
    ],
    [
        35.0318,
        16.491237504761905
    ],
    [
        35.0422,
        14.0243591952381
    ],
    [
        35.0526,
        13.202405047619049
    ],
    [
        35.0629,
        12.378870657142857
    ],
    [
        35.0733,
        11.144923566666666
    ],
    [
        35.0836,
        10.158036795238095
    ],
    [
        35.094,
        12.131539438095237
    ],
    [
        35.1044,
        8.020639966666668
    ],
    [
        35.1147,
        8.02199446190476
    ],
    [
        35.1251,
        8.02160746190476
    ],
    [
        35.1355,
        7.5825697190476165
    ],
    [
        35.1458,
        7.856629685714283
    ],
    [
        35.1562,
        9.336282595238092
    ],
    [
        35.1665,
        12.625227919047616
    ],
    [
        35.1769,
        16.856290657142857
    ],
    [
        35.1873,
        16.85764515238095
    ],
    [
        35.1976,
        16.241010233333334
    ],
    [
        35.208,
        23.64198377619048
    ],
    [
        35.2183,
        19.25717483809524
    ],
    [
        35.2287,
        14.32274097142857
    ],
    [
        35.2391,
        10.37492298095238
    ],
    [
        35.2494,
        11.608531447619047
    ],
    [
        35.2598,
        12.293530861904761
    ],
    [
        35.2702,
        12.293305114285712
    ],
    [
        35.2805,
        12.293305114285712
    ],
    [
        35.2909,
        12.82213958095238
    ],
    [
        35.3012,
        13.438774499999997
    ],
    [
        35.3116,
        13.68610571904762
    ],
    [
        35.322,
        13.686105719047616
    ],
    [
        35.3323,
        10.396031647619047
    ],
    [
        35.3427,
        14.625739885714287
    ],
    [
        35.353,
        13.146357876190477
    ],
    [
        35.3634,
        11.337562109523809
    ],
    [
        35.3738,
        9.364601266666668
    ],
    [
        35.3841,
        3.609116257142857
    ],
    [
        35.3945,
        -0.6205919809523808
    ],
    [
        35.4049,
        0.20091066666666807
    ],
    [
        35.4152,
        2.051154052380953
    ],
    [
        35.4256,
        -2.881925309523811
    ],
    [
        35.4359,
        -2.813391504761905
    ],
    [
        35.4463,
        -1.5797830380952376
    ],
    [
        35.4567,
        -0.09985922857143
    ],
    [
        35.467,
        -5.034293095238095
    ],
    [
        35.4774,
        -3.9379027380952376
    ],
    [
        35.4877,
        -6.404442423809523
    ],
    [
        35.4981,
        -10.516696395238096
    ],
    [
        35.5085,
        -9.106664647619048
    ],
    [
        35.5188,
        -10.956908033333335
    ],
    [
        35.5292,
        -11.368675228571432
    ],
    [
        35.5395,
        -10.546495338095239
    ],
    [
        35.5499,
        -10.956908038095241
    ],
    [
        35.5603,
        -15.8913419
    ],
    [
        35.5706,
        -18.357881585714285
    ],
    [
        35.581,
        -12.436831852380957
    ],
    [
        35.5914,
        -17.863490057142855
    ],
    [
        35.6017,
        -17.864844552380948
    ],
    [
        35.6121,
        -12.313606457142859
    ],
    [
        35.6224,
        -19.71525725238095
    ],
    [
        35.6328,
        -19.304054433333334
    ],
    [
        35.6432,
        -14.370297819047618
    ],
    [
        35.6535,
        -10.054022685714282
    ],
    [
        35.6639,
        -10.464774009523804
    ],
    [
        35.6742,
        -7.995525328571424
    ],
    [
        35.6846,
        -6.761578238095237
    ],
    [
        35.695,
        -6.692893933333331
    ],
    [
        35.7053,
        -4.226354247619044
    ],
    [
        35.7157,
        -2.580865709523809
    ],
    [
        35.7261,
        -2.463604938095235
    ],
    [
        35.7364,
        3.7057918904761933
    ],
    [
        35.7468,
        2.0614321000000033
    ],
    [
        35.7571,
        4.528197533333332
    ],
    [
        35.7675,
        3.2942504428571433
    ],
    [
        35.7779,
        5.760790123809524
    ],
    [
        35.7882,
        12.339132276190478
    ],
    [
        35.7986,
        12.337777780952385
    ],
    [
        35.8089,
        16.03724868095238
    ],
    [
        35.8193,
        18.504465609523805
    ],
    [
        35.8297,
        18.5925805
    ],
    [
        35.84,
        28.46144822380952
    ],
    [
        35.8504,
        25.583367095238092
    ],
    [
        35.8608,
        23.9385558047619
    ],
    [
        35.8711,
        20.649836228571427
    ],
    [
        35.8815,
        24.761412952380955
    ],
    [
        35.8918,
        19.82562459047619
    ],
    [
        35.9022,
        21.058894433333336
    ],
    [
        35.9126,
        18.797730409523812
    ],
    [
        35.9229,
        19.208820357142855
    ],
    [
        35.9333,
        24.96701436190476
    ],
    [
        35.9436,
        24.84956009047619
    ],
    [
        35.954,
        17.445877547619048
    ],
    [
        35.9644,
        15.80151776190476
    ],
    [
        35.9747,
        16.623471909523808
    ],
    [
        35.9851,
        17.61008778095238
    ],
    [
        35.9954,
        14.32114245714286
    ],
    [
        36.0058,
        13.498285314285713
    ],
    [
        36.0162,
        3.6307720857142853
    ],
    [
        36.0265,
        6.097650395238095
    ],
    [
        36.0369,
        11.032084261904762
    ],
    [
        36.0473,
        9.34027836666667
    ],
    [
        36.0576,
        5.392731276190476
    ],
    [
        36.068,
        6.626001119047621
    ],
    [
        36.0783,
        0.867807114285714
    ],
    [
        36.0887,
        -0.776552676190476
    ],
    [
        36.0991,
        -2.4215897142857146
    ],
    [
        36.1094,
        -7.356023576190476
    ],
    [
        36.1198,
        -6.122753733333333
    ],
    [
        36.1301,
        -6.123656728571428
    ],
    [
        36.1405,
        -5.712566780952381
    ],
    [
        36.1509,
        -8.181138209523809
    ],
    [
        36.1612,
        -7.241504042857142
    ],
    [
        36.1716,
        -6.418421147619047
    ],
    [
        36.182,
        -3.951881466666667
    ],
    [
        36.1923,
        -3.9509784714285714
    ],
    [
        36.2027,
        -0.004514976190476107
    ],
    [
        36.213,
        5.750970033333333
    ],
    [
        36.2234,
        6.5733756761904765
    ],
    [
        36.2338,
        10.802890414285715
    ],
    [
        36.2441,
        10.391800466666666
    ],
    [
        36.2545,
        2.9887951714285723
    ],
    [
        36.2648,
        4.962568714285715
    ],
    [
        36.2752,
        2.989066071428572
    ],
    [
        36.2856,
        4.222674538095238
    ],
    [
        36.2959,
        4.225383533333337
    ],
    [
        36.3063,
        4.8423570761904795
    ],
    [
        36.3167,
        4.0201771809523805
    ],
    [
        36.327,
        9.941226914285718
    ],
    [
        36.3374,
        7.721341200000002
    ],
    [
        36.3477,
        10.188783880952384
    ],
    [
        36.3581,
        7.3108156238095265
    ],
    [
        36.3685,
        6.077545780952382
    ],
    [
        36.3788,
        4.43318599047619
    ],
    [
        36.3892,
        5.091381404761907
    ],
    [
        36.3995,
        5.539843166666669
    ],
    [
        36.4099,
        1.4280407
    ],
    [
        36.4203,
        1.4293951952380932
    ],
    [
        36.4306,
        -5.970223852380952
    ],
    [
        36.441,
        -6.998230904761905
    ],
    [
        36.4513,
        -7.526920247619048
    ],
    [
        36.4617,
        -7.64437451904762
    ],
    [
        36.4721,
        -2.708586157142858
    ],
    [
        36.4824,
        1.237606438095238
    ],
    [
        36.4928,
        4.69211649047619
    ],
    [
        36.5032,
        1.8132452380952384
    ],
    [
        36.5135,
        11.679403971428572
    ],
    [
        36.5239,
        12.706790219047619
    ],
    [
        36.5342,
        10.240250538095237
    ],
    [
        36.5446,
        10.897994457142856
    ],
    [
        36.555,
        14.351150009523808
    ],
    [
        36.5653,
        14.516127785714286
    ],
    [
        36.5757,
        16.572141895238094
    ],
    [
        36.586,
        14.844886866666664
    ],
    [
        36.5964,
        15.4616911
    ],
    [
        36.6068,
        18.915523904761905
    ],
    [
        36.6171,
        19.172174766666668
    ],
    [
        36.6275,
        21.2276245
    ],
    [
        36.6379,
        16.7416524
    ],
    [
        36.6482,
        17.974583614285716
    ],
    [
        36.6586,
        22.290858747619048
    ],
    [
        36.6689,
        25.168827004761905
    ],
    [
        36.6793,
        26.109364166666666
    ],
    [
        36.6897,
        31.043798028571423
    ],
    [
        36.7,
        31.04379802857143
    ],
    [
        36.7104,
        29.809173690476193
    ],
    [
        36.7208,
        29.809173690476193
    ],
    [
        36.7311,
        26.10936416666667
    ],
    [
        36.7415,
        23.231057285714282
    ],
    [
        36.7518,
        20.7645176
    ],
    [
        36.7622,
        19.824883433333337
    ],
    [
        36.7726,
        15.713983961904763
    ],
    [
        36.7829,
        15.343630523809525
    ],
    [
        36.7933,
        13.699044985714286
    ],
    [
        36.8036,
        16.660247104761908
    ],
    [
        36.814,
        17.27671271428571
    ],
    [
        36.8244,
        16.454307066666665
    ],
    [
        36.8347,
        20.68227380952381
    ],
    [
        36.8451,
        22.327310847619046
    ],
    [
        36.8554,
        21.26204485238095
    ],
    [
        36.8658,
        23.48226919047619
    ],
    [
        36.8762,
        18.549189823809524
    ],
    [
        36.8865,
        17.609555657142852
    ],
    [
        36.8969,
        11.029859009523811
    ],
    [
        36.9073,
        4.862155304761907
    ],
    [
        36.9176,
        1.574338723809524
    ],
    [
        36.928,
        0.7895306428571445
    ],
    [
        36.9383,
        1.6126135333333342
    ],
    [
        36.9487,
        1.201523585714286
    ],
    [
        36.9591,
        -2.5003176857142853
    ],
    [
        36.9694,
        -0.03377799999999978
    ],
    [
        36.9798,
        0.24811224761904935
    ],
    [
        36.9901,
        3.12641912857143
    ],
    [
        37.0005,
        2.5096148952380966
    ],
    [
        37.0109,
        3.3317947904761924
    ],
    [
        37.0212,
        1.6863062523809529
    ],
    [
        37.0316,
        -0.04027152857142885
    ],
    [
        37.0419,
        0.7814568666666672
    ],
    [
        37.0523,
        -3.164464828571428
    ],
    [
        37.0627,
        -5.631681761904763
    ],
    [
        37.073,
        -5.014877528571429
    ],
    [
        37.0834,
        -5.296767776190476
    ],
    [
        37.0938,
        -7.763307457142857
    ],
    [
        37.1041,
        -9.701980171428572
    ],
    [
        37.1145,
        -3.7806595380952386
    ],
    [
        37.1248,
        -1.8426640714285718
    ],
    [
        37.1352,
        -3.487926857142858
    ],
    [
        37.1456,
        -2.29202882857143
    ],
    [
        37.1559,
        -3.9363886142857156
    ],
    [
        37.1663,
        -7.225785442857144
    ],
    [
        37.1766,
        -1.0563886142857153
    ],
    [
        37.187,
        -3.524282795238096
    ],
    [
        37.1974,
        -3.8057860428571426
    ],
    [
        37.2077,
        -5.861913028571429
    ],
    [
        37.2181,
        -4.628643185714286
    ],
    [
        37.2285,
        -4.628643185714286
    ],
    [
        37.2388,
        -10.384128195238095
    ],
    [
        37.2492,
        -7.423603323809524
    ],
    [
        37.2595,
        -8.903527133333332
    ],
    [
        37.2699,
        -7.424145123809522
    ],
    [
        37.2803,
        -7.835235071428572
    ],
    [
        37.2906,
        -8.821850942857143
    ],
    [
        37.301,
        -9.032997728571425
    ],
    [
        37.3113,
        -1.6306696857142844
    ],
    [
        37.3217,
        2.069817085714287
    ],
    [
        37.3321,
        1.082930314285716
    ],
    [
        37.3424,
        1.0829303142857163
    ],
    [
        37.3528,
        1.0829303142857163
    ],
    [
        37.3632,
        -2.2057892666666654
    ],
    [
        37.3735,
        5.1965387761904775
    ],
    [
        37.3839,
        6.841575814285715
    ],
    [
        37.3942,
        5.987539180952383
    ],
    [
        37.4046,
        10.099793147619051
    ],
    [
        37.415,
        9.80588334761905
    ],
    [
        37.4253,
        8.98280045714286
    ],
    [
        37.4357,
        8.982800457142858
    ],
    [
        37.446,
        7.995913685714287
    ],
    [
        37.4564,
        15.395532733333328
    ],
    [
        37.4668,
        14.161585642857144
    ],
    [
        37.4771,
        18.110758133333338
    ],
    [
        37.4875,
        10.711139085714287
    ],
    [
        37.4979,
        13.588768719047621
    ],
    [
        37.5082,
        16.21974438095238
    ],
    [
        37.5186,
        14.739549671428572
    ],
    [
        37.5289,
        10.508680433333334
    ],
    [
        37.5393,
        6.397329466666669
    ],
    [
        37.5497,
        7.63127655714286
    ],
    [
        37.56,
        1.9934715666666678
    ],
    [
        37.5704,
        -0.4730681142857131
    ],
    [
        37.5807,
        2.4049001428571466
    ],
    [
        37.5911,
        -0.06231679047619058
    ],
    [
        37.6015,
        2.404222895238097
    ],
    [
        37.6118,
        3.257920904761904
    ],
    [
        37.6222,
        3.5318303714285726
    ],
    [
        37.6325,
        3.737262466666668
    ],
    [
        37.6429,
        6.3694120238095255
    ],
    [
        37.6533,
        3.902533714285716
    ],
    [
        37.6636,
        9.823854347619049
    ],
    [
        37.674,
        7.357314666666666
    ],
    [
        37.6844,
        8.590584504761905
    ],
    [
        37.6947,
        4.888065985714286
    ],
    [
        37.7051,
        14.756933714285713
    ],
    [
        37.7154,
        14.756933714285713
    ],
    [
        37.7258,
        10.646034242857143
    ],
    [
        37.7362,
        11.084530185714286
    ],
    [
        37.7465,
        10.828195957142858
    ],
    [
        37.7569,
        14.939546923809521
    ],
    [
        37.7672,
        12.471991366666666
    ],
    [
        37.7776,
        17.89851411904762
    ],
    [
        37.788,
        17.897836866666665
    ],
    [
        37.7983,
        11.728440038095234
    ],
    [
        37.8087,
        9.259868609523808
    ],
    [
        37.8191,
        10.083402999999999
    ],
    [
        37.8294,
        3.9170537904761873
    ],
    [
        37.8398,
        5.081959138095236
    ],
    [
        37.8501,
        2.8205129333333328
    ],
    [
        37.8605,
        1.833626161904763
    ],
    [
        37.8709,
        8.000313995238093
    ],
    [
        37.8812,
        5.533774314285717
    ],
    [
        37.8916,
        0.6006949476190497
    ],
    [
        37.9019,
        -1.160732109523807
    ],
    [
        37.9123,
        -7.327081319047618
    ],
    [
        37.9227,
        -17.19594904761905
    ],
    [
        37.933,
        -15.962679209523813
    ],
    [
        37.9434,
        -18.431927890476192
    ],
    [
        37.9538,
        -16.897192090476192
    ],
    [
        37.9641,
        -18.990136776190475
    ],
    [
        37.9745,
        -20.223067990476192
    ],
    [
        37.9848,
        -16.522919842857142
    ],
    [
        37.9952,
        -16.4682433
    ],
    [
        38.0056,
        -14.549447295238096
    ],
    [
        38.0159,
        -8.790801790476191
    ],
    [
        38.0263,
        -8.790801790476191
    ],
    [
        38.0366,
        -8.792156290476191
    ],
    [
        38.047,
        -2.6254684571428575
    ],
    [
        38.0574,
        -3.2421033761904785
    ],
    [
        38.0677,
        -2.8309005571428565
    ],
    [
        38.0781,
        -1.1860892714285707
    ],
    [
        38.0884,
        -7.352438480952383
    ],
    [
        38.0988,
        -9.409242714285714
    ],
    [
        38.1092,
        -6.120523138095241
    ],
    [
        38.1195,
        -4.3590960809523835
    ],
    [
        38.1299,
        1.2790959095238095
    ],
    [
        38.1403,
        5.225017604761904
    ],
    [
        38.1506,
        -2.1746014428571447
    ],
    [
        38.161,
        7.694266285714285
    ],
    [
        38.1713,
        7.324454647619048
    ],
    [
        38.1817,
        8.352010204761905
    ],
    [
        38.1921,
        8.105017609523811
    ],
    [
        38.2024,
        10.57291179047619
    ],
    [
        38.2128,
        10.729517485714286
    ],
    [
        38.2231,
        8.81004423333333
    ],
    [
        38.2335,
        12.92162095238095
    ],
    [
        38.2439,
        15.388837885714285
    ],
    [
        38.2542,
        10.45508127142857
    ],
    [
        38.2646,
        10.208088676190474
    ],
    [
        38.275,
        11.85289996190476
    ],
    [
        38.2853,
        13.086508428571427
    ],
    [
        38.2957,
        10.208088676190474
    ],
    [
        38.306,
        13.085718309523807
    ],
    [
        38.3164,
        17.607707728571427
    ],
    [
        38.3268,
        20.074247414285715
    ],
    [
        38.3371,
        16.620550061904762
    ],
    [
        38.3475,
        20.848516804761903
    ],
    [
        38.3578,
        16.90259510952381
    ],
    [
        38.3682,
        21.83567447619048
    ],
    [
        38.3786,
        18.13586495238095
    ],
    [
        38.3889,
        17.51906071904762
    ],
    [
        38.3993,
        19.78022474285714
    ],
    [
        38.4097,
        19.615788766666665
    ],
    [
        38.42,
        13.694739033333333
    ],
    [
        38.4304,
        13.976900180952384
    ],
    [
        38.4407,
        17.43168113333333
    ],
    [
        38.4511,
        11.263300180952381
    ],
    [
        38.4615,
        10.440443038095237
    ],
    [
        38.4718,
        17.842093833333333
    ],
    [
        38.4822,
        21.78855732857143
    ],
    [
        38.4925,
        21.37735450952381
    ],
    [
        38.5029,
        26.31178837142857
    ],
    [
        38.5133,
        27.545396838095236
    ],
    [
        38.5236,
        26.88792381904762
    ],
    [
        38.534,
        20.31048466190476
    ],
    [
        38.5443,
        20.721574609523806
    ],
    [
        38.5547,
        24.17527196190476
    ],
    [
        38.5651,
        14.309113228571427
    ],
    [
        38.5754,
        14.306404233333328
    ],
    [
        38.5858,
        15.011129857142853
    ],
    [
        38.5962,
        15.011468480952383
    ],
    [
        38.6065,
        10.07703461904762
    ],
    [
        38.6169,
        5.9661351476190445
    ],
    [
        38.6272,
        5.692225680952377
    ],
    [
        38.6376,
        5.4452330857142845
    ],
    [
        38.648,
        5.075150547619044
    ],
    [
        38.6583,
        3.3833446523809503
    ],
    [
        38.6687,
        5.60417851428571
    ],
    [
        38.679,
        4.946434595238095
    ],
    [
        38.6894,
        -0.4814426523809522
    ],
    [
        38.6998,
        -4.180913552380953
    ],
    [
        38.7101,
        -4.7978871000000005
    ],
    [
        38.7205,
        -8.745163290476189
    ],
    [
        38.7309,
        -9.978433133333331
    ],
    [
        38.7412,
        -6.032240538095236
    ],
    [
        38.7516,
        -3.1539336571428556
    ],
    [
        38.7619,
        0.5458758666666693
    ],
    [
        38.7723,
        -3.153933657142854
    ],
    [
        38.7827,
        2.3272656380952386
    ],
    [
        38.793,
        7.263054
    ],
    [
        38.8034,
        9.025545309523809
    ],
    [
        38.8137,
        6.805050071428572
    ],
    [
        38.8241,
        12.287754361904764
    ],
    [
        38.8345,
        13.109934252380954
    ],
    [
        38.8448,
        10.916852538095242
    ],
    [
        38.8552,
        12.855380128571435
    ],
    [
        38.8656,
        14.705115576190481
    ],
    [
        38.8759,
        15.410034700000004
    ],
    [
        38.8863,
        19.356227295238103
    ],
    [
        38.8966,
        20.014422709523814
    ],
    [
        38.907,
        15.57478672857143
    ],
    [
        38.9174,
        9.4080988952381
    ],
    [
        38.9277,
        10.436726761904765
    ],
    [
        38.9381,
        10.271748985714286
    ],
    [
        38.9484,
        11.505018828571433
    ],
    [
        38.9588,
        9.039833642857145
    ],
    [
        38.9692,
        2.8728071857142856
    ],
    [
        38.9795,
        -1.2380922857142862
    ],
    [
        38.9899,
        -1.6500852285714287
    ],
    [
        39.0003,
        -3.842564947619049
    ],
    [
        39.0106,
        -2.1982051571428585
    ],
    [
        39.021,
        -3.843016447619049
    ],
    [
        39.0313,
        -4.500489466666669
    ],
    [
        39.0417,
        -0.11432603333333548
    ],
    [
        39.0521,
        2.146837990476189
    ],
    [
        39.0624,
        2.5583794333333314
    ],
    [
        39.0728,
        3.4987230952380926
    ],
    [
        39.0831,
        -0.6126278714285741
    ],
    [
        39.0935,
        0.0922912523809497
    ],
    [
        39.1039,
        -3.195976828571431
    ],
    [
        39.1142,
        -7.307327795238096
    ],
    [
        39.1246,
        -2.3742484285714305
    ],
    [
        39.1349,
        1.5722150666666668
    ],
    [
        39.1453,
        0.7491321761904769
    ],
    [
        39.1557,
        1.160222123809523
    ],
    [
        39.166,
        2.8054849095238086
    ],
    [
        39.1764,
        -0.6490251428571453
    ],
    [
        39.1868,
        5.517324066666667
    ],
    [
        39.1971,
        7.163038352380952
    ],
    [
        39.2075,
        7.575031295238094
    ],
    [
        39.2178,
        11.687285266666667
    ],
    [
        39.2282,
        11.687285266666665
    ],
    [
        39.2386,
        5.931800257142857
    ],
    [
        39.2489,
        8.398565690476188
    ],
    [
        39.2593,
        -1.46894753809524
    ],
    [
        39.2696,
        2.8473275952380934
    ],
    [
        39.28,
        5.314544528571427
    ],
    [
        39.2904,
        4.491461638095237
    ],
    [
        39.3007,
        5.51929938095238
    ],
    [
        39.3111,
        5.362650685714284
    ],
    [
        39.3215,
        1.2506224666666659
    ],
    [
        39.3318,
        2.0728023571428573
    ],
    [
        39.3422,
        -0.39441457619047615
    ],
    [
        39.3525,
        1.5790880666666662
    ],
    [
        39.3629,
        2.4012679619047623
    ],
    [
        39.3733,
        0.18138224761904673
    ],
    [
        39.3836,
        -0.8472456190476187
    ],
    [
        39.394,
        5.075158614285714
    ],
    [
        39.4043,
        -1.0911905952380945
    ],
    [
        39.4147,
        -2.3258149333333327
    ],
    [
        39.4251,
        -2.7373563761904762
    ],
    [
        39.4354,
        -2.7380336238095246
    ],
    [
        39.4458,
        -2.7375821285714292
    ],
    [
        39.4562,
        3.017902880952381
    ],
    [
        39.4665,
        -0.27104244285714296
    ],
    [
        39.4769,
        3.0172256380952396
    ],
    [
        39.4872,
        -0.6825838857142854
    ],
    [
        39.4976,
        3.016548390476191
    ],
    [
        39.508,
        3.181526166666668
    ],
    [
        39.5183,
        3.1815261666666688
    ],
    [
        39.5287,
        3.8665255809523815
    ],
    [
        39.539,
        6.950377433333334
    ],
    [
        39.5494,
        6.9494744380952405
    ],
    [
        39.5598,
        9.416691371428572
    ],
    [
        39.5701,
        8.92311253809524
    ],
    [
        39.5805,
        12.214541109523813
    ],
    [
        39.5908,
        11.556797190476194
    ],
    [
        39.6012,
        11.76234216666667
    ],
    [
        39.6116,
        1.8934744380952395
    ],
    [
        39.6219,
        8.05982364761905
    ],
    [
        39.6323,
        1.8907654428571454
    ],
    [
        39.6427,
        5.17993651904762
    ],
    [
        39.653,
        5.179936519047621
    ],
    [
        39.6634,
        1.0679083000000016
    ],
    [
        39.6737,
        0.24550265714285824
    ],
    [
        39.6841,
        4.35640212857143
    ],
    [
        39.6945,
        6.706132538095241
    ],
    [
        39.7048,
        4.650457052380953
    ],
    [
        39.7152,
        -1.10480220952381
    ],
    [
        39.7255,
        -1.474986338095238
    ],
    [
        39.7359,
        0.3752570476190481
    ],
    [
        39.7463,
        -2.5032755809523803
    ],
    [
        39.7566,
        -2.671448738095237
    ],
    [
        39.767,
        -1.8483658476190465
    ],
    [
        39.7774,
        -6.781445214285714
    ],
    [
        39.7877,
        -2.3389647904761905
    ],
    [
        39.7981,
        -6.041483309523808
    ],
    [
        39.8084,
        -3.5744921238095224
    ],
    [
        39.8188,
        -9.329751385714287
    ],
    [
        39.8292,
        -6.863211699999999
    ],
    [
        39.8395,
        -6.452121752380951
    ],
    [
        39.8499,
        -0.5294466190476174
    ],
    [
        39.8602,
        2.759272957142859
    ],
    [
        39.8706,
        0.2920560238095247
    ],
    [
        39.881,
        2.14263803809524
    ],
    [
        39.8913,
        3.375907880952383
    ],
    [
        39.9017,
        2.9654951809523817
    ],
    [
        39.9121,
        2.965301680952382
    ],
    [
        39.9224,
        3.2392863952380964
    ],
    [
        39.9328,
        4.766191914285715
    ],
    [
        39.9431,
        9.085006733333334
    ],
    [
        39.9535,
        10.318276571428571
    ],
    [
        39.9639,
        10.730269514285714
    ],
    [
        39.9742,
        10.898442671428572
    ],
    [
        39.9846,
        9.252728385714287
    ],
    [
        39.9949,
        12.541447961904762
    ],
    [
        40.0053,
        2.672580233333334
    ],
    [
        40.0157,
        2.67325748095238
    ],
    [
        40.026,
        3.0838959285714287
    ],
    [
        40.0364,
        12.95276365714286
    ],
    [
        40.0468,
        17.063663128571427
    ],
    [
        40.0571,
        15.419303338095238
    ],
    [
        40.0675,
        16.076776357142858
    ],
    [
        40.0778,
        11.966102633333335
    ],
    [
        40.0882,
        7.033023266666667
    ],
    [
        40.0986,
        6.0043954
    ],
    [
        40.1089,
        9.704204923809524
    ],
    [
        40.1193,
        9.29243772857143
    ],
    [
        40.1296,
        7.971077185714286
    ],
    [
        40.14,
        9.013076952380953
    ],
    [
        40.1504,
        9.130982723809526
    ],
    [
        40.1607,
        3.2083075904761906
    ],
    [
        40.1711,
        1.9753763761904761
    ],
    [
        40.1814,
        5.6751859
    ],
    [
        40.1918,
        6.70291077142857
    ],
    [
        40.2022,
        9.992984847619047
    ],
    [
        40.2125,
        9.169450457142856
    ],
    [
        40.2229,
        14.102529823809524
    ],
    [
        40.2333,
        11.88196686190476
    ],
    [
        40.2436,
        15.581776385714285
    ],
    [
        40.254,
        15.581776385714285
    ],
    [
        40.2643,
        14.386124628571427
    ],
    [
        40.2747,
        13.89281669047619
    ],
    [
        40.2851,
        17.181536266666665
    ],
    [
        40.2954,
        18.826347557142856
    ],
    [
        40.3058,
        24.992696766666665
    ],
    [
        40.3162,
        21.704880185714288
    ],
    [
        40.3265,
        21.704880185714284
    ],
    [
        40.3369,
        25.818488652380953
    ],
    [
        40.3472,
        26.9832435
    ],
    [
        40.3576,
        26.489664661904765
    ],
    [
        40.368,
        30.60553061904762
    ],
    [
        40.3783,
        32.82568723333333
    ],
    [
        40.3887,
        30.76989887142857
    ],
    [
        40.399,
        29.125990585714288
    ],
    [
        40.4094,
        29.948170476190473
    ],
    [
        40.4198,
        24.192911214285715
    ],
    [
        40.4301,
        26.6608053952381
    ],
    [
        40.4405,
        24.194265714285716
    ],
    [
        40.4508,
        25.729152014285713
    ],
    [
        40.4612,
        19.151261361904762
    ],
    [
        40.4716,
        16.68201268095238
    ],
    [
        40.4819,
        18.70052158095238
    ],
    [
        40.4923,
        20.01623516190476
    ],
    [
        40.5027,
        13.849547328571424
    ],
    [
        40.513,
        12.615600238095237
    ],
    [
        40.5234,
        13.027593180952382
    ],
    [
        40.5337,
        17.960672547619044
    ],
    [
        40.5441,
        13.027593180952382
    ],
    [
        40.5545,
        9.736164609523806
    ],
    [
        40.5648,
        10.010224576190474
    ],
    [
        40.5752,
        7.542330395238093
    ],
    [
        40.5855,
        5.070372723809522
    ],
    [
        40.5959,
        -1.0986854809523827
    ],
    [
        40.6063,
        -0.27650558571428635
    ],
    [
        40.6166,
        3.8339423857142845
    ],
    [
        40.627,
        1.367402704761904
    ],
    [
        40.6374,
        8.76702175238095
    ],
    [
        40.6477,
        11.234238685714283
    ],
    [
        40.6581,
        15.345589652380951
    ],
    [
        40.6684,
        15.413954142857143
    ],
    [
        40.6788,
        16.353846304761902
    ],
    [
        40.6892,
        14.435426552380951
    ],
    [
        40.6995,
        9.501669938095237
    ],
    [
        40.7099,
        3.7434759333333316
    ],
    [
        40.7202,
        4.977084399999999
    ],
    [
        40.7306,
        4.360449476190475
    ],
    [
        40.741,
        1.8930067952380931
    ],
    [
        40.7513,
        2.7151866904761883
    ],
    [
        40.7617,
        1.0708268999999977
    ],
    [
        40.772,
        0.6604141999999987
    ],
    [
        40.7824,
        0.8248501761904751
    ],
    [
        40.7928,
        0.8248501761904747
    ],
    [
        40.8031,
        -4.108229185714286
    ],
    [
        40.8135,
        -0.40571066666666833
    ],
    [
        40.8239,
        0.21126287619047454
    ],
    [
        40.8342,
        -5.708432361904762
    ],
    [
        40.8446,
        -5.3287563333333345
    ],
    [
        40.8549,
        -9.439655804761903
    ],
    [
        40.8653,
        -10.262512947619047
    ],
    [
        40.8757,
        -10.262512947619047
    ],
    [
        40.886,
        -15.813581728571426
    ],
    [
        40.8964,
        -20.04174197142857
    ],
    [
        40.9067,
        -20.5898619
    ],
    [
        40.9171,
        -16.89005237619048
    ],
    [
        40.9275,
        -7.021184647619048
    ],
    [
        40.9378,
        -6.404549728571429
    ],
    [
        40.9482,
        -9.489078828571431
    ],
    [
        40.9586,
        -9.900846023809525
    ],
    [
        40.9689,
        -9.900846023809528
    ],
    [
        40.9793,
        -3.323406866666668
    ],
    [
        40.9896,
        -9.490433323809523
    ],
    [
        41,
        -8.009155014285716
    ],
    [
        41.0104,
        -3.895998047619049
    ],
    [
        41.0207,
        -0.19618852380952304
    ],
    [
        41.0311,
        5.970160685714286
    ],
    [
        41.0414,
        6.586456985714285
    ],
    [
        41.0518,
        10.04028979047619
    ],
    [
        41.0622,
        8.838208119047618
    ],
    [
        41.0725,
        12.127379195238094
    ],
    [
        41.0829,
        12.127830690476188
    ],
    [
        41.0932,
        12.949559085714286
    ],
    [
        41.1036,
        17.88399294761905
    ],
    [
        41.114,
        17.88399294761905
    ],
    [
        41.1243,
        18.87115061904762
    ],
    [
        41.1347,
        18.186000704761902
    ],
    [
        41.1451,
        10.786381657142856
    ],
    [
        41.1554,
        9.465021114285712
    ],
    [
        41.1658,
        13.577275085714287
    ],
    [
        41.1761,
        16.044492019047617
    ],
    [
        41.1865,
        10.286523761904762
    ],
    [
        41.1969,
        6.998707180952377
    ],
    [
        41.2072,
        11.931786547619048
    ],
    [
        41.2176,
        10.697839457142857
    ],
    [
        41.228,
        13.98565603809524
    ],
    [
        41.2383,
        17.68546556190476
    ],
    [
        41.2487,
        11.765228523809522
    ],
    [
        41.259,
        11.51857455238095
    ],
    [
        41.2694,
        11.5178973
    ],
    [
        41.2797,
        17.274736804761904
    ],
    [
        41.2901,
        13.163385838095238
    ],
    [
        41.3005,
        12.341205947619047
    ],
    [
        41.3108,
        10.422936695238096
    ],
    [
        41.3212,
        8.778576904761907
    ],
    [
        41.3316,
        11.244439338095242
    ],
    [
        41.3419,
        10.962007290476194
    ],
    [
        41.3523,
        8.769527571428574
    ],
    [
        41.3626,
        12.881330038095243
    ],
    [
        41.373,
        18.51913502857143
    ],
    [
        41.3834,
        17.57950086190477
    ],
    [
        41.3937,
        14.701871228571433
    ],
    [
        41.4041,
        19.637659590476197
    ],
    [
        41.4145,
        20.458936485714293
    ],
    [
        41.4248,
        21.07591002857144
    ],
    [
        41.4352,
        19.13665681428572
    ],
    [
        41.4455,
        14.203577447619052
    ],
    [
        41.4559,
        10.257113952380953
    ],
    [
        41.4663,
        8.777731942857143
    ],
    [
        41.4766,
        9.43637885714286
    ],
    [
        41.487,
        7.792019071428571
    ],
    [
        41.4973,
        1.2127739238095248
    ],
    [
        41.5077,
        4.384039228571429
    ],
    [
        41.5181,
        -1.3716715333333345
    ],
    [
        41.5284,
        0.5465977190476181
    ],
    [
        41.5388,
        2.8079310523809524
    ],
    [
        41.5492,
        0.33935962380952506
    ],
    [
        41.5595,
        1.2789937904761903
    ],
    [
        41.5699,
        2.923353580952381
    ],
    [
        41.5802,
        -0.3662689952380961
    ],
    [
        41.5906,
        -10.232427728571428
    ],
    [
        41.601,
        -10.114521957142856
    ],
    [
        41.6113,
        -7.483546295238095
    ],
    [
        41.6217,
        -9.127906085714285
    ],
    [
        41.632,
        -11.594445766666665
    ],
    [
        41.6424,
        -11.506500185714284
    ],
    [
        41.6528,
        -11.506306685714284
    ],
    [
        41.6631,
        -10.957885757142856
    ],
    [
        41.6735,
        -9.477961947619049
    ],
    [
        41.6838,
        -11.944501633333333
    ],
    [
        41.6942,
        -13.58976441904762
    ],
    [
        41.7046,
        -14.411944314285714
    ],
    [
        41.7149,
        -12.27382509047619
    ],
    [
        41.7253,
        -13.389866409523812
    ],
    [
        41.7357,
        -7.963479104761904
    ],
    [
        41.746,
        -5.496939419047619
    ],
    [
        41.7564,
        -4.880643119047616
    ],
    [
        41.7667,
        0.671780161904763
    ],
    [
        41.7771,
        -2.6178424142857124
    ],
    [
        41.7875,
        -5.085736595238093
    ],
    [
        41.7978,
        -1.7970170142857131
    ],
    [
        41.8082,
        2.642754414285715
    ],
    [
        41.8185,
        2.642302914285714
    ],
    [
        41.8289,
        -3.278746819047618
    ],
    [
        41.8393,
        -1.0861165999999993
    ],
    [
        41.8496,
        -6.020550461904761
    ],
    [
        41.86,
        -8.37028087142857
    ],
    [
        41.8704,
        -6.020808457142856
    ],
    [
        41.8807,
        -4.102012452380952
    ],
    [
        41.8911,
        -8.213363419047617
    ],
    [
        41.9014,
        -1.6350212666666666
    ],
    [
        41.9118,
        -0.6484053952380952
    ],
    [
        41.9222,
        4.286028466666666
    ],
    [
        41.9325,
        5.0259903714285725
    ],
    [
        41.9429,
        10.370385433333333
    ],
    [
        41.9532,
        12.344971676190479
    ],
    [
        41.9636,
        5.767532519047622
    ],
    [
        41.974,
        5.3564425714285715
    ],
    [
        41.9843,
        -0.19327171428571296
    ],
    [
        41.9947,
        3.0963508619047606
    ],
    [
        42.0051,
        0.6284566809523804
    ],
    [
        42.0154,
        -0.4674821809523811
    ],
    [
        42.0258,
        -0.9607901142857138
    ],
    [
        42.0361,
        0.6835696714285721
    ],
    [
        42.0465,
        8.084543214285715
    ],
    [
        42.0569,
        12.470706647619046
    ],
    [
        42.0672,
        18.638748976190477
    ],
    [
        42.0776,
        19.460928866666666
    ],
    [
        42.0879,
        18.30987701428571
    ],
    [
        42.0983,
        13.376120400000001
    ],
    [
        42.1087,
        17.48814861904762
    ],
    [
        42.119,
        16.54761145714286
    ],
    [
        42.1294,
        14.327048495238099
    ],
    [
        42.1398,
        10.215020276190476
    ],
    [
        42.1501,
        13.916184295238095
    ],
    [
        42.1605,
        7.338745138095236
    ],
    [
        42.1708,
        7.337390638095234
    ],
    [
        42.1812,
        7.337390638095236
    ],
    [
        42.1916,
        7.749157833333331
    ],
    [
        42.2019,
        17.615316566666667
    ],
    [
        42.2123,
        11.037877409523809
    ],
    [
        42.2226,
        16.5217857
    ],
    [
        42.233,
        20.907798633333332
    ],
    [
        42.2434,
        23.12802297142857
    ],
    [
        42.2537,
        18.19426635714286
    ],
    [
        42.2641,
        16.961335142857145
    ],
    [
        42.2744,
        14.494118214285715
    ],
    [
        42.2848,
        10.793292814285715
    ],
    [
        42.2952,
        12.602540080952378
    ],
    [
        42.3055,
        9.642015209523809
    ],
    [
        42.3159,
        14.575771823809527
    ],
    [
        42.3263,
        13.095441661904761
    ],
    [
        42.3366,
        9.92417635714286
    ],
    [
        42.347,
        13.624663128571425
    ],
    [
        42.3573,
        14.447520271428576
    ],
    [
        42.3677,
        10.499702280952382
    ],
    [
        42.3781,
        10.499702280952382
    ],
    [
        42.3884,
        9.267109690476193
    ],
    [
        42.3988,
        13.378009161904764
    ],
    [
        42.4091,
        13.377331914285715
    ],
    [
        42.4195,
        5.977712866666666
    ],
    [
        42.4299,
        11.32199505714286
    ],
    [
        42.4402,
        8.307335447619051
    ],
    [
        42.4506,
        2.1392931190476205
    ],
    [
        42.461,
        1.4539550809523814
    ],
    [
        42.4713,
        -1.0119073523809508
    ],
    [
        42.4817,
        -7.18130418095238
    ],
    [
        42.492,
        -14.58160048095238
    ],
    [
        42.5024,
        -10.880097833333334
    ],
    [
        42.5128,
        -13.511073495238096
    ],
    [
        42.5231,
        -15.976935928571427
    ],
    [
        42.5335,
        -18.03295003809524
    ],
    [
        42.5438,
        -19.513144747619048
    ],
    [
        42.5542,
        -16.429631523809523
    ],
    [
        42.5646,
        -17.251811414285715
    ],
    [
        42.5749,
        -20.130344042857143
    ],
    [
        42.5853,
        -20.500324995238095
    ],
    [
        42.5956,
        -20.089912295238094
    ],
    [
        42.606,
        -20.090589542857145
    ],
    [
        42.6164,
        -22.557129223809525
    ],
    [
        42.6267,
        -28.72347843333333
    ],
    [
        42.6371,
        -25.02366890952381
    ],
    [
        42.6475,
        -27.079231523809522
    ],
    [
        42.6578,
        -19.678257980952388
    ],
    [
        42.6682,
        -13.510215652380955
    ],
    [
        42.6785,
        -14.058486080952386
    ],
    [
        42.6889,
        -8.577286785714287
    ],
    [
        42.6993,
        -4.628114295238097
    ],
    [
        42.7096,
        1.1275964666666662
    ],
    [
        42.72,
        0.715716395238094
    ],
    [
        42.7304,
        1.1261290952380947
    ],
    [
        42.7407,
        7.7035682523809506
    ],
    [
        42.7511,
        4.8251484952380945
    ],
    [
        42.7614,
        5.93509135238095
    ],
    [
        42.7718,
        6.962477599999998
    ],
    [
        42.7822,
        2.850223628571427
    ],
    [
        42.7925,
        5.727853261904762
    ],
    [
        42.8029,
        5.933398238095236
    ],
    [
        42.8132,
        6.462619704761905
    ],
    [
        42.8236,
        6.0515297571428555
    ],
    [
        42.834,
        4.40626697142857
    ],
    [
        42.8443,
        10.573293428571427
    ],
    [
        42.8547,
        7.695663795238095
    ],
    [
        42.865,
        6.873483904761904
    ],
    [
        42.8754,
        2.5556849619047624
    ],
    [
        42.8858,
        0.0884680333333326
    ],
    [
        42.8961,
        -4.845965828571429
    ],
    [
        42.9065,
        -4.945500861904762
    ],
    [
        42.9169,
        -4.945500861904763
    ],
    [
        42.9272,
        -3.3002380761904764
    ],
    [
        42.9376,
        0.8104356476190473
    ],
    [
        42.9479,
        6.9788166
    ],
    [
        42.9583,
        6.979719595238096
    ],
    [
        42.9687,
        14.380015895238094
    ],
    [
        42.979,
        14.996989442857144
    ],
    [
        42.9894,
        13.352629652380953
    ],
    [
        42.9997,
        17.053793676190473
    ],
    [
        43.0101,
        15.409433885714284
    ],
    [
        43.0205,
        14.58725399047619
    ],
    [
        43.0308,
        15.291979614285713
    ],
    [
        43.0412,
        15.291979614285717
    ],
    [
        43.0516,
        12.002808538095238
    ],
    [
        43.0619,
        11.385665680952382
    ],
    [
        43.0723,
        11.38566568095238
    ],
    [
        43.0826,
        14.557317985714292
    ],
    [
        43.093,
        12.296323276190481
    ],
    [
        43.1034,
        12.296323276190476
    ],
    [
        43.1137,
        18.87511692857143
    ],
    [
        43.1241,
        17.192584942857145
    ],
    [
        43.1344,
        17.192584942857145
    ],
    [
        43.1448,
        15.42960988571429
    ],
    [
        43.1552,
        11.113334752380952
    ],
    [
        43.1655,
        7.412170733333335
    ],
    [
        43.1759,
        5.318199914285716
    ],
    [
        43.1863,
        1.3717364190476198
    ],
    [
        43.1966,
        0.9607593476190478
    ],
    [
        43.207,
        -3.972320019047619
    ],
    [
        43.2173,
        -4.383409966666667
    ],
    [
        43.2277,
        0.5523783952380952
    ],
    [
        43.2381,
        -4.380700971428571
    ],
    [
        43.2484,
        -3.3940851000000003
    ],
    [
        43.2588,
        -6.682804676190476
    ],
    [
        43.2691,
        -4.2149104952380965
    ],
    [
        43.2795,
        -4.420511904761905
    ],
    [
        43.2899,
        -2.776152114285714
    ],
    [
        43.3002,
        -2.659084842857143
    ],
    [
        43.3106,
        -1.0147250523809523
    ],
    [
        43.321,
        -5.948481666666667
    ],
    [
        43.3313,
        -10.06005839047619
    ],
    [
        43.3417,
        -8.277840871428571
    ],
    [
        43.352,
        -5.646413714285715
    ],
    [
        43.3624,
        -7.056251961904762
    ],
    [
        43.3728,
        -6.8508763
    ],
    [
        43.3831,
        -8.789065266666666
    ],
    [
        43.3935,
        -7.723722314285714
    ],
    [
        43.4038,
        -7.476391095238093
    ],
    [
        43.4142,
        -9.94338228095238
    ],
    [
        43.4246,
        -4.188123019047619
    ],
    [
        43.4349,
        -5.010302914285714
    ],
    [
        43.4453,
        -9.397971347619048
    ],
    [
        43.4557,
        -5.169617604761905
    ],
    [
        43.466,
        -4.922286385714287
    ],
    [
        43.4764,
        -4.922286385714286
    ],
    [
        43.4867,
        -0.811386914285715
    ],
    [
        43.4971,
        -6.155669104761905
    ],
    [
        43.5075,
        -8.622886038095238
    ],
    [
        43.5178,
        -8.458179161904761
    ],
    [
        43.5282,
        -6.813367876190476
    ],
    [
        43.5385,
        -2.7024684047619054
    ],
    [
        43.5489,
        -1.8805142571428566
    ],
    [
        43.5593,
        -1.6066047904761913
    ],
    [
        43.5696,
        1.683017785714285
    ],
    [
        43.58,
        4.855831090476189
    ],
    [
        43.5903,
        1.5671115142857137
    ],
    [
        43.6007,
        2.765474023809523
    ],
    [
        43.6111,
        0.9155692619047606
    ],
    [
        43.6214,
        0.38634779523809437
    ],
    [
        43.6318,
        -0.4358320952380962
    ],
    [
        43.6422,
        0.38725079523809436
    ],
    [
        43.6525,
        2.0316105857142848
    ],
    [
        43.6629,
        -0.16177212857143022
    ],
    [
        43.6732,
        2.187313285714284
    ],
    [
        43.6836,
        0.9533661952380937
    ],
    [
        43.694,
        4.036540795238094
    ],
    [
        43.7043,
        1.158233914285713
    ],
    [
        43.7147,
        11.024392647619049
    ],
    [
        43.725,
        14.108244499999996
    ],
    [
        43.7354,
        12.4164386047619
    ],
    [
        43.7458,
        11.758243190476193
    ],
    [
        43.7561,
        15.049671761904763
    ],
    [
        43.7665,
        11.761855180952383
    ],
    [
        43.7769,
        11.76185518095238
    ],
    [
        43.7872,
        5.839992747619048
    ],
    [
        43.7976,
        5.838638247619048
    ],
    [
        43.8079,
        9.010097052380953
    ],
    [
        43.8183,
        10.9838706
    ],
    [
        43.8287,
        13.861951728571428
    ],
    [
        43.839,
        13.157226104761905
    ],
    [
        43.8494,
        14.390495942857147
    ],
    [
        43.8597,
        14.389592947619049
    ],
    [
        43.8701,
        13.979180247619048
    ],
    [
        43.8805,
        16.611058904761904
    ],
    [
        43.8908,
        13.322339323809526
    ],
    [
        43.9012,
        18.25812768571429
    ],
    [
        43.9116,
        16.654876895238097
    ],
    [
        43.9219,
        19.12209382857143
    ],
    [
        43.9323,
        19.12480282380952
    ],
    [
        43.9426,
        20.152527695238092
    ],
    [
        43.953,
        20.857446819047617
    ],
    [
        43.9634,
        22.337370628571428
    ],
    [
        43.9737,
        18.388198138095238
    ],
    [
        43.9841,
        21.676917714285715
    ],
    [
        43.9944,
        21.47171136190476
    ],
    [
        44.0048,
        21.84216639047619
    ],
    [
        44.0152,
        20.080545833333336
    ],
    [
        44.0255,
        20.36251348095238
    ],
    [
        44.0359,
        15.428756866666665
    ],
    [
        44.0462,
        15.429208366666668
    ],
    [
        44.0566,
        17.07356815714286
    ],
    [
        44.067,
        19.29345387142857
    ],
    [
        44.0773,
        22.58217344761905
    ],
    [
        44.0877,
        20.114956514285716
    ],
    [
        44.0981,
        19.507808285714287
    ],
    [
        44.1084,
        20.447055452380955
    ],
    [
        44.1188,
        16.744536933333332
    ],
    [
        44.1291,
        12.79671894285714
    ],
    [
        44.1395,
        12.797057566666664
    ],
    [
        44.1499,
        9.505628995238094
    ],
    [
        44.1602,
        2.9268353428571445
    ],
    [
        44.1706,
        1.282024057142859
    ],
    [
        44.1809,
        -0.6910722380952358
    ],
    [
        44.1913,
        -3.3220478999999976
    ],
    [
        44.2017,
        3.255391257142859
    ],
    [
        44.212,
        4.283680500000001
    ],
    [
        44.2224,
        7.3671937238095255
    ],
    [
        44.2328,
        6.662274600000002
    ],
    [
        44.2431,
        6.29202274761905
    ],
    [
        44.2535,
        11.226456609523812
    ],
    [
        44.2638,
        14.514273190476192
    ],
    [
        44.2742,
        11.22555360952381
    ],
    [
        44.2846,
        12.706831919047623
    ],
    [
        44.2949,
        10.240292233333335
    ],
    [
        44.3053,
        9.829202285714288
    ],
    [
        44.3156,
        10.683004485714289
    ],
    [
        44.326,
        11.388117109523813
    ],
    [
        44.3364,
        11.79920705714286
    ],
    [
        44.3467,
        17.555820814285717
    ],
    [
        44.3571,
        13.854318166666669
    ],
    [
        44.3675,
        13.032138271428575
    ],
    [
        44.3778,
        16.732625042857144
    ],
    [
        44.3882,
        20.84397600952381
    ],
    [
        44.3985,
        21.17271251904762
    ],
    [
        44.4089,
        21.583125219047623
    ],
    [
        44.4193,
        17.8833156952381
    ],
    [
        44.4296,
        18.705947090476194
    ],
    [
        44.44,
        16.732715347619052
    ],
    [
        44.4503,
        15.499445504761908
    ],
    [
        44.4607,
        19.815889952380957
    ],
    [
        44.4711,
        18.993258557142855
    ],
    [
        44.4814,
        14.060179190476193
    ],
    [
        44.4918,
        16.93848607142857
    ],
    [
        44.5021,
        19.403671257142857
    ],
    [
        44.5125,
        12.004052209523811
    ],
    [
        44.5229,
        12.003149214285713
    ],
    [
        44.5332,
        11.474604995238096
    ],
    [
        44.5436,
        16.407684361904764
    ],
    [
        44.554,
        14.763324571428571
    ],
    [
        44.5643,
        14.927760547619046
    ],
    [
        44.5747,
        19.040466014285716
    ],
    [
        44.585,
        17.395880476190477
    ],
    [
        44.5954,
        21.09636724761905
    ],
    [
        44.6058,
        19.246801109523812
    ],
    [
        44.6161,
        17.60244131904762
    ],
    [
        44.6265,
        19.453023328571426
    ],
    [
        44.6368,
        20.687647666666663
    ],
    [
        44.6472,
        13.285319623809528
    ],
    [
        44.6576,
        15.259228619047619
    ],
    [
        44.6679,
        14.848138671428574
    ],
    [
        44.6783,
        10.61978492857143
    ],
    [
        44.6887,
        10.618881933333334
    ],
    [
        44.699,
        15.554670295238097
    ],
    [
        44.7094,
        15.307339076190479
    ],
    [
        44.7197,
        15.307339076190477
    ],
    [
        44.7301,
        22.70831262380953
    ],
    [
        44.7405,
        21.88703572857143
    ],
    [
        44.7508,
        21.88722922857143
    ],
    [
        44.7612,
        18.187758328571434
    ],
    [
        44.7715,
        17.483032704761907
    ],
    [
        44.7819,
        17.20133595714286
    ],
    [
        44.7923,
        16.789794514285717
    ],
    [
        44.8026,
        16.625584285714286
    ],
    [
        44.813,
        9.225965238095238
    ],
    [
        44.8234,
        10.254254480952381
    ],
    [
        44.8337,
        11.781160000000002
    ],
    [
        44.8441,
        13.632419261904761
    ],
    [
        44.8544,
        9.519262295238097
    ],
    [
        44.8648,
        13.467080285714287
    ],
    [
        44.8752,
        11.704975976190473
    ],
    [
        44.8855,
        13.34933576666667
    ],
    [
        44.8959,
        10.178070461904765
    ],
    [
        44.9062,
        9.355890566666668
    ],
    [
        44.9166,
        5.4067180761904785
    ],
    [
        44.927,
        6.064461995238097
    ],
    [
        44.9373,
        1.1313826285714288
    ],
    [
        44.9477,
        3.5965678142857147
    ],
    [
        44.9581,
        8.531001676190476
    ],
    [
        44.9684,
        9.470442342857142
    ],
    [
        44.9788,
        6.59202259047619
    ],
    [
        44.9891,
        8.530018057142858
    ],
    [
        44.9995,
        5.357882
    ],
    [
        45.0099,
        4.947017800000001
    ],
    [
        45.0202,
        5.933633671428571
    ],
    [
        45.0306,
        12.099982880952384
    ],
    [
        45.0409,
        5.52164072857143
    ],
    [
        45.0513,
        6.05018494761905
    ],
    [
        45.0617,
        6.051539442857142
    ],
    [
        45.072,
        7.69680222857143
    ],
    [
        45.0824,
        8.683418100000003
    ],
    [
        45.0927,
        9.21157531904762
    ],
    [
        45.1031,
        4.278495952380953
    ],
    [
        45.1135,
        11.680823995238097
    ],
    [
        45.1238,
        11.563369723809526
    ],
    [
        45.1342,
        8.110214171428575
    ],
    [
        45.1446,
        6.959162319047623
    ],
    [
        45.1549,
        4.49194538571429
    ],
    [
        45.1653,
        1.2032258095238104
    ],
    [
        45.1756,
        -1.2646683714285711
    ],
    [
        45.186,
        -5.375567842857142
    ],
    [
        45.1964,
        -2.4971480904761902
    ],
    [
        45.2067,
        -3.730417933333332
    ],
    [
        45.2171,
        -6.196280366666667
    ],
    [
        45.2274,
        -5.784738919047618
    ],
    [
        45.2378,
        -4.798123047619047
    ],
    [
        45.2482,
        -7.018008761904762
    ],
    [
        45.2585,
        -1.4682944761904755
    ],
    [
        45.2689,
        -2.7015643190476193
    ],
    [
        45.2793,
        -5.170812995238095
    ],
    [
        45.2896,
        -0.2350246333333327
    ],
    [
        45.3,
        -1.879384423809523
    ],
    [
        45.3103,
        -2.4075416428571423
    ],
    [
        45.3207,
        1.8208121000000004
    ],
    [
        45.3311,
        -2.6665144999999986
    ],
    [
        45.3414,
        -4.604509966666667
    ],
    [
        45.3518,
        -1.1513544142857137
    ],
    [
        45.3621,
        -4.1125565333333345
    ],
    [
        45.3725,
        -1.0287046809523814
    ],
    [
        45.3829,
        2.26136939047619
    ],
    [
        45.3932,
        1.0280995476190469
    ],
    [
        45.4036,
        4.3168191238095215
    ],
    [
        45.414,
        3.9058420523809514
    ],
    [
        45.4243,
        3.0836621571428564
    ],
    [
        45.4347,
        12.952529885714288
    ],
    [
        45.445,
        12.211890733333334
    ],
    [
        45.4554,
        9.580915071428574
    ],
    [
        45.4658,
        8.923171152380954
    ],
    [
        45.4761,
        9.293693904761906
    ],
    [
        45.4865,
        8.471514009523812
    ],
    [
        45.4968,
        8.85119003809524
    ],
    [
        45.5072,
        3.9154016761904766
    ],
    [
        45.5176,
        7.204121257142857
    ],
    [
        45.5279,
        8.966902814285714
    ],
    [
        45.5383,
        8.438358595238094
    ],
    [
        45.5486,
        8.606870376190475
    ],
    [
        45.559,
        9.84014021904762
    ],
    [
        45.5694,
        7.209164557142858
    ],
    [
        45.5797,
        10.499464380952382
    ],
    [
        45.5901,
        14.817263323809524
    ],
    [
        45.6005,
        12.350046395238095
    ],
    [
        45.6108,
        8.650236871428573
    ],
    [
        45.6212,
        10.294596657142858
    ],
    [
        45.6315,
        14.405044628571426
    ],
    [
        45.6419,
        15.638540219047622
    ],
    [
        45.6523,
        13.169291538095237
    ],
    [
        45.6626,
        11.44203650952381
    ],
    [
        45.673,
        14.730756090476188
    ],
    [
        45.6833,
        11.442036514285714
    ],
    [
        45.6937,
        11.441494714285712
    ],
    [
        45.7041,
        17.19946297142857
    ],
    [
        45.7144,
        15.997155547619048
    ],
    [
        45.7248,
        16.819335442857145
    ],
    [
        45.7352,
        16.81933544285714
    ],
    [
        45.7455,
        14.351441261904759
    ],
    [
        45.7559,
        20.519144966666666
    ],
    [
        45.7662,
        21.546982709523807
    ],
    [
        45.7766,
        26.480062076190475
    ],
    [
        45.787,
        33.06021022857143
    ],
    [
        45.7973,
        37.170883952380954
    ],
    [
        45.8077,
        32.784720519047625
    ],
    [
        45.818,
        30.317503585714288
    ],
    [
        45.8284,
        36.484530042857145
    ],
    [
        45.8388,
        29.084910995238086
    ],
    [
        45.8491,
        25.385101471428573
    ],
    [
        45.8595,
        22.507246095238095
    ],
    [
        45.8699,
        21.027322285714284
    ],
    [
        45.8802,
        17.326835514285715
    ],
    [
        45.8906,
        18.14969265714286
    ],
    [
        45.9009,
        24.069929695238102
    ],
    [
        45.9113,
        20.616774142857146
    ],
    [
        45.9217,
        20.616774142857146
    ],
    [
        45.932,
        22.67267538095238
    ],
    [
        45.9424,
        24.318389666666672
    ],
    [
        45.9527,
        23.66064574761905
    ],
    [
        45.9631,
        23.04401082857143
    ],
    [
        45.9735,
        19.09619283809524
    ],
    [
        45.9838,
        12.516947690476194
    ],
    [
        45.9942,
        8.200503242857145
    ],
    [
        46.0046,
        4.909977666666669
    ],
    [
        46.0149,
        -0.8452815952380929
    ],
    [
        46.0253,
        -0.5709206285714272
    ],
    [
        46.0356,
        0.6623492142857158
    ],
    [
        46.046,
        0.4150179952380986
    ],
    [
        46.0564,
        4.361481490476193
    ],
    [
        46.0667,
        5.596105828571431
    ],
    [
        46.0771,
        6.829375666666668
    ],
    [
        46.0874,
        6.336067733333336
    ],
    [
        46.0978,
        6.3374222285714295
    ],
    [
        46.1082,
        4.4870095285714315
    ],
    [
        46.1185,
        2.88321693809524
    ],
    [
        46.1289,
        7.816973552380952
    ],
    [
        46.1392,
        4.115809528571429
    ],
    [
        46.1496,
        1.2381798952380956
    ],
    [
        46.16,
        0.0035555571428570517
    ],
    [
        46.1703,
        -0.5345985523809512
    ],
    [
        46.1807,
        -0.4114070190476177
    ],
    [
        46.1911,
        -0.16441442380952256
    ],
    [
        46.2014,
        4.065293814285714
    ],
    [
        46.2118,
        3.828230728571428
    ],
    [
        46.2221,
        -2.7501114238095243
    ],
    [
        46.2325,
        -0.6946616857142855
    ],
    [
        46.2429,
        -0.5301354095238102
    ],
    [
        46.2532,
        3.1723831095238095
    ],
    [
        46.2636,
        4.65230691904762
    ],
    [
        46.2739,
        5.638922790476191
    ],
    [
        46.2843,
        4.157644480952381
    ],
    [
        46.2947,
        10.32399369047619
    ],
    [
        46.305,
        14.765119614285714
    ],
    [
        46.3154,
        18.711312209523808
    ],
    [
        46.3258,
        18.642627904761905
    ],
    [
        46.3361,
        20.49287129047619
    ],
    [
        46.3465,
        16.380843071428572
    ],
    [
        46.3568,
        12.678324552380948
    ],
    [
        46.3672,
        15.309300214285713
    ],
    [
        46.3776,
        14.89843601428571
    ],
    [
        46.3879,
        13.627568609523808
    ],
    [
        46.3983,
        15.107627866666665
    ],
    [
        46.4086,
        18.80845326190476
    ],
    [
        46.419,
        17.0466392047619
    ],
    [
        46.4294,
        18.31142716190476
    ],
    [
        46.4397,
        23.244506528571424
    ],
    [
        46.4501,
        24.47913086666666
    ],
    [
        46.4605,
        24.725243038095236
    ],
    [
        46.4708,
        18.96727478095238
    ],
    [
        46.4812,
        16.912050795238095
    ],
    [
        46.4915,
        14.445511114285715
    ],
    [
        46.5019,
        13.907357004761904
    ],
    [
        46.5123,
        7.740330547619048
    ],
    [
        46.5226,
        7.738976052380955
    ],
    [
        46.533,
        10.370674109523808
    ],
    [
        46.5433,
        12.289093861904762
    ],
    [
        46.5537,
        11.877891038095239
    ],
    [
        46.5641,
        12.700296680952382
    ],
    [
        46.5744,
        15.716461290476191
    ],
    [
        46.5848,
        13.496236952380954
    ],
    [
        46.5951,
        14.318642595238098
    ],
    [
        46.6055,
        20.075482100000002
    ],
    [
        46.6159,
        19.911046123809527
    ],
    [
        46.6262,
        13.743342419047618
    ],
    [
        46.6366,
        17.855144885714285
    ],
    [
        46.647,
        16.621875042857145
    ],
    [
        46.6573,
        15.799695147619047
    ],
    [
        46.6677,
        18.26488033333333
    ],
    [
        46.678,
        19.49815017142857
    ],
    [
        46.6884,
        25.25476392857143
    ],
    [
        46.6988,
        27.310665166666666
    ],
    [
        46.7091,
        27.309987914285713
    ],
    [
        46.7195,
        27.848412923809526
    ],
    [
        46.7298,
        29.78727913809524
    ],
    [
        46.7402,
        23.20983998095238
    ],
    [
        46.7506,
        26.49810806190476
    ],
    [
        46.7609,
        25.676831166666666
    ],
    [
        46.7713,
        25.841312295238097
    ],
    [
        46.7816,
        21.729284076190478
    ],
    [
        46.792,
        19.947743804761906
    ],
    [
        46.8024,
        21.729472199999996
    ],
    [
        46.8127,
        22.5512005952381
    ],
    [
        46.8231,
        12.680978371428571
    ],
    [
        46.8335,
        13.504061261904761
    ],
    [
        46.8438,
        15.970600947619047
    ],
    [
        46.8542,
        16.791877842857144
    ],
    [
        46.8645,
        21.313867261904765
    ],
    [
        46.8749,
        19.668152976190477
    ],
    [
        46.8853,
        15.968343452380953
    ],
    [
        46.8956,
        15.968343452380948
    ],
    [
        46.906,
        8.56736990952381
    ],
    [
        46.9163,
        6.716957209523811
    ],
    [
        46.9267,
        10.82898542857143
    ],
    [
        46.9371,
        7.3748817238095254
    ],
    [
        46.9474,
        7.903571066666667
    ],
    [
        46.9578,
        11.192742142857144
    ],
    [
        46.9682,
        5.026392933333334
    ],
    [
        46.9785,
        3.381130147619048
    ],
    [
        46.9889,
        7.331386233333334
    ],
    [
        46.9992,
        13.499089938095237
    ],
    [
        47.0096,
        9.797925919047618
    ],
    [
        47.02,
        9.698267752380952
    ],
    [
        47.0303,
        5.587368280952383
    ],
    [
        47.0407,
        8.878796852380953
    ],
    [
        47.051,
        8.878796852380955
    ],
    [
        47.0614,
        8.878796852380955
    ],
    [
        47.0718,
        11.348045533333337
    ],
    [
        47.0821,
        8.059325957142857
    ],
    [
        47.0925,
        11.760489980952382
    ],
    [
        47.1029,
        10.52722013809524
    ],
    [
        47.1132,
        9.705491742857143
    ],
    [
        47.1236,
        11.555227190476192
    ],
    [
        47.1339,
        11.643342080952383
    ],
    [
        47.1443,
        10.546650723809526
    ],
    [
        47.1547,
        12.027251785714286
    ],
    [
        47.165,
        11.341763247619047
    ],
    [
        47.1754,
        8.709794290476191
    ],
    [
        47.1857,
        10.765695528571428
    ],
    [
        47.1961,
        8.298478595238095
    ],
    [
        47.2065,
        5.006147023809525
    ],
    [
        47.2168,
        4.477602804761908
    ],
    [
        47.2272,
        8.178766823809523
    ],
    [
        47.2376,
        10.198199242857141
    ],
    [
        47.2479,
        13.075828876190474
    ],
    [
        47.2583,
        17.18672834761905
    ],
    [
        47.2686,
        14.308195719047617
    ],
    [
        47.279,
        13.7796515
    ],
    [
        47.2894,
        10.489125923809521
    ],
    [
        47.2997,
        13.780554495238093
    ],
    [
        47.3101,
        14.191644442857141
    ],
    [
        47.3204,
        13.643524514285714
    ],
    [
        47.3308,
        12.44717499047619
    ],
    [
        47.3412,
        13.063979223809522
    ],
    [
        47.3515,
        13.345675971428571
    ],
    [
        47.3619,
        12.304112657142856
    ],
    [
        47.3722,
        13.838848457142856
    ],
    [
        47.3826,
        15.757268209523808
    ],
    [
        47.393,
        17.73104175238095
    ],
    [
        47.4033,
        15.921252685714286
    ],
    [
        47.4137,
        19.37562729047619
    ],
    [
        47.4241,
        16.086004719047615
    ],
    [
        47.4344,
        17.02609038095238
    ],
    [
        47.4448,
        18.87599514285714
    ],
    [
        47.4551,
        15.421485090476189
    ],
    [
        47.4655,
        16.65610942857143
    ],
    [
        47.4759,
        15.011749642857145
    ],
    [
        47.4862,
        17.889379276190475
    ],
    [
        47.4966,
        16.362473757142855
    ],
    [
        47.5069,
        15.422130095238096
    ],
    [
        47.5173,
        12.953558666666666
    ],
    [
        47.5277,
        13.775738557142855
    ],
    [
        47.538,
        15.96912127142857
    ],
    [
        47.5484,
        17.1650193
    ],
    [
        47.5588,
        14.697802366666664
    ],
    [
        47.5691,
        11.244646814285712
    ],
    [
        47.5795,
        12.443202823809523
    ],
    [
        47.5898,
        10.250723104761905
    ],
    [
        47.6002,
        8.488909047619046
    ],
    [
        47.6106,
        8.2070188
    ],
    [
        47.6209,
        8.645755542857144
    ],
    [
        47.6313,
        8.275232790476192
    ],
    [
        47.6416,
        8.686096985714284
    ],
    [
        47.652,
        8.02844337142857
    ],
    [
        47.6624,
        9.05616824285714
    ],
    [
        47.6727,
        12.509323795238092
    ],
    [
        47.6831,
        12.507969295238096
    ],
    [
        47.6935,
        12.67240527142857
    ],
    [
        47.7038,
        11.02804548095238
    ],
    [
        47.7142,
        9.383685695238096
    ],
    [
        47.7245,
        9.500946466666667
    ],
    [
        47.7349,
        4.567189852380953
    ],
    [
        47.7453,
        4.567189852380953
    ],
    [
        47.7556,
        -2.0138612952380957
    ],
    [
        47.766,
        -3.0415861666666677
    ],
    [
        47.7763,
        1.893524947619046
    ],
    [
        47.7867,
        6.826604309523809
    ],
    [
        47.7971,
        7.354761528571428
    ],
    [
        47.8074,
        7.353858533333334
    ],
    [
        47.8178,
        7.097594671428571
    ],
    [
        47.8282,
        5.776234128571428
    ],
    [
        47.8385,
        7.969917842857145
    ],
    [
        47.8489,
        7.801744685714286
    ],
    [
        47.8592,
        7.801744685714287
    ],
    [
        47.8696,
        4.348047333333335
    ],
    [
        47.88,
        0.23714786190476206
    ],
    [
        47.8903,
        -0.9954447285714279
    ],
    [
        47.9007,
        -1.8176246190476195
    ],
    [
        47.911,
        -7.740028852380952
    ],
    [
        47.9214,
        -7.246720914285714
    ],
    [
        47.9318,
        -2.3122870523809516
    ],
    [
        47.9421,
        1.7986124190476194
    ],
    [
        47.9525,
        5.498421942857143
    ],
    [
        47.9629,
        2.327156638095239
    ],
    [
        47.9732,
        8.085124895238096
    ],
    [
        47.9836,
        8.70175981904762
    ],
    [
        47.9939,
        8.70175981904762
    ],
    [
        48.0043,
        8.70175981904762
    ],
    [
        48.0147,
        12.404278338095239
    ],
    [
        48.025,
        9.116461757142856
    ],
    [
        48.0354,
        9.901269838095239
    ],
    [
        48.0457,
        5.5848253904761895
    ],
    [
        48.0561,
        2.29339681904762
    ],
    [
        48.0665,
        2.6671149523809548
    ],
    [
        48.0768,
        -1.0320173238095225
    ],
    [
        48.0872,
        1.7868851666666676
    ],
    [
        48.0975,
        3.431244952380952
    ],
    [
        48.1079,
        3.430567704761905
    ],
    [
        48.1183,
        6.721996276190476
    ],
    [
        48.1286,
        12.64494230952381
    ],
    [
        48.139,
        17.084713738095235
    ],
    [
        48.1494,
        17.083359238095237
    ],
    [
        48.1597,
        9.683740190476193
    ],
    [
        48.1701,
        9.067443890476191
    ],
    [
        48.1804,
        9.772169514285716
    ],
    [
        48.1908,
        8.456681680952382
    ],
    [
        48.2012,
        7.84004675714286
    ],
    [
        48.2115,
        2.9042583952380974
    ],
    [
        48.2219,
        1.25989860952381
    ],
    [
        48.2322,
        -3.6758897523809564
    ],
    [
        48.2426,
        2.490459457142858
    ],
    [
        48.253,
        2.490459457142858
    ],
    [
        48.2633,
        8.410696495238097
    ],
    [
        48.2737,
        8.823592438095238
    ],
    [
        48.2841,
        3.065624180952382
    ],
    [
        48.2944,
        9.64306333809524
    ],
    [
        48.3048,
        14.226488890476192
    ],
    [
        48.3151,
        15.665303704761904
    ],
    [
        48.3255,
        19.365113228571424
    ],
    [
        48.3359,
        15.41594073809524
    ],
    [
        48.3462,
        11.960888885714287
    ],
    [
        48.3566,
        7.0278095190476195
    ],
    [
        48.3669,
        9.4970582
    ],
    [
        48.3773,
        13.607957671428572
    ],
    [
        48.3877,
        17.924063495238094
    ],
    [
        48.398,
        22.857142861904762
    ],
    [
        48.4084,
        18.417371433333333
    ],
    [
        48.4188,
        18.41771005714286
    ],
    [
        48.4291,
        22.529964028571428
    ],
    [
        48.4395,
        21.707784133333334
    ],
    [
        48.4498,
        22.694400004761906
    ],
    [
        48.4602,
        18.994590480952382
    ],
    [
        48.4706,
        22.697109
    ],
    [
        48.4809,
        24.179200004761906
    ],
    [
        48.4913,
        23.768110057142856
    ],
    [
        48.5016,
        27.05953862857143
    ],
    [
        48.512,
        25.908486776190475
    ],
    [
        48.5224,
        22.617058204761904
    ],
    [
        48.5327,
        19.532529104761903
    ],
    [
        48.5431,
        15.421629633333334
    ],
    [
        48.5534,
        15.421629633333334
    ],
    [
        48.5638,
        15.422984133333333
    ],
    [
        48.5742,
        15.422984133333333
    ],
    [
        48.5845,
        12.13245855714286
    ],
    [
        48.5949,
        12.955992947619048
    ],
    [
        48.6053,
        8.845093476190478
    ],
    [
        48.6156,
        4.616739733333332
    ],
    [
        48.626,
        8.845093476190478
    ],
    [
        48.6363,
        9.215277604761905
    ],
    [
        48.6467,
        8.275643438095237
    ],
    [
        48.6571,
        7.570917814285714
    ],
    [
        48.6674,
        11.520090304761906
    ],
    [
        48.6778,
        14.691355609523812
    ],
    [
        48.6881,
        7.287673066666666
    ],
    [
        48.6985,
        6.46278418095238
    ],
    [
        48.7089,
        8.929775366666666
    ],
    [
        48.7192,
        12.218494947619046
    ],
    [
        48.7296,
        11.72518700952381
    ],
    [
        48.74,
        12.548721400000002
    ],
    [
        48.7503,
        17.483155261904763
    ],
    [
        48.7607,
        17.483606761904763
    ],
    [
        48.771,
        15.880355971428575
    ],
    [
        48.7814,
        18.840203590476193
    ],
    [
        48.7918,
        17.60693374761905
    ],
    [
        48.8021,
        13.495131280952384
    ],
    [
        48.8125,
        11.520815938095243
    ],
    [
        48.8228,
        10.698636042857146
    ],
    [
        48.8332,
        12.461804600000002
    ],
    [
        48.8436,
        13.166530223809527
    ],
    [
        48.8539,
        14.645912233333336
    ],
    [
        48.8643,
        15.585546400000002
    ],
    [
        48.8747,
        13.822377842857144
    ],
    [
        48.885,
        9.503224399999999
    ],
    [
        48.8954,
        8.798498776190476
    ],
    [
        48.9057,
        11.971505580952382
    ],
    [
        48.9161,
        5.394066423809523
    ],
    [
        48.9265,
        7.0379747095238105
    ],
    [
        48.9368,
        6.216246314285714
    ],
    [
        48.9472,
        6.216246314285714
    ],
    [
        48.9575,
        2.926172238095239
    ],
    [
        48.9679,
        2.103992347619047
    ],
    [
        48.9783,
        2.1035408476190467
    ],
    [
        48.9886,
        2.720175766666666
    ],
    [
        48.999,
        2.2268678285714274
    ],
    [
        49.0094,
        4.008257599999999
    ],
    [
        49.0197,
        5.488181409523809
    ],
    [
        49.0301,
        4.994602571428572
    ],
    [
        49.0404,
        5.981760242857143
    ],
    [
        49.0508,
        4.500481933333332
    ],
    [
        49.0612,
        5.734429023809523
    ],
    [
        49.0715,
        -1.6651900238095247
    ],
    [
        49.0819,
        -1.664287028571429
    ],
    [
        49.0922,
        -0.8407526380952387
    ],
    [
        49.1026,
        -0.6352076619047629
    ],
    [
        49.113,
        0.5980621809523802
    ],
    [
        49.1233,
        4.828737919047619
    ],
    [
        49.1337,
        10.995764376190477
    ],
    [
        49.144,
        6.062685014285715
    ],
    [
        49.1544,
        6.2266694904761914
    ],
    [
        49.1648,
        3.760129809523811
    ],
    [
        49.1751,
        4.582309704761906
    ],
    [
        49.1855,
        2.9379499142857157
    ],
    [
        49.1959,
        2.8204956428571433
    ],
    [
        49.2062,
        0.3539559619047625
    ],
    [
        49.2166,
        1.0586815857142864
    ],
    [
        49.2269,
        -0.19416396666666627
    ],
    [
        49.2373,
        1.4975645285714296
    ],
    [
        49.2477,
        1.4975645285714296
    ],
    [
        49.258,
        5.443486223809525
    ],
    [
        49.2684,
        5.004990280952382
    ],
    [
        49.2787,
        8.704122557142858
    ],
    [
        49.2891,
        16.105096104761905
    ],
    [
        49.2995,
        19.39562168095238
    ],
    [
        49.3098,
        25.973060838095243
    ],
    [
        49.3202,
        25.973060838095243
    ],
    [
        49.3306,
        25.973738085714288
    ],
    [
        49.3409,
        20.051333852380953
    ],
    [
        49.3513,
        17.583439671428575
    ],
    [
        49.3616,
        20.666614271428575
    ],
    [
        49.372,
        21.324358190476193
    ],
    [
        49.3824,
        28.723977238095237
    ],
    [
        49.3927,
        29.957247080952385
    ],
    [
        49.4031,
        25.02416771428572
    ],
    [
        49.4134,
        25.142073485714292
    ],
    [
        49.4238,
        32.54440152857144
    ],
    [
        49.4342,
        26.906596538095247
    ],
    [
        49.4445,
        22.67824279523811
    ],
    [
        49.4549,
        23.20640001428573
    ],
    [
        49.4653,
        28.142188376190493
    ],
    [
        49.4756,
        25.675648690476194
    ],
    [
        49.486,
        27.59542294285714
    ],
    [
        49.4963,
        22.662343576190477
    ],
    [
        49.5067,
        21.016629290476192
    ],
    [
        49.5171,
        17.725200719047624
    ],
    [
        49.5274,
        11.804963680952383
    ],
    [
        49.5378,
        11.531204714285716
    ],
    [
        49.5481,
        7.830717942857143
    ],
    [
        49.5585,
        9.63996520952381
    ],
    [
        49.5689,
        8.40737261904762
    ],
    [
        49.5792,
        8.777353571428575
    ],
    [
        49.5896,
        2.1999144142857157
    ],
    [
        49.5999,
        -2.0280523285714285
    ],
    [
        49.6103,
        -4.905681961904761
    ],
    [
        49.6207,
        0.027397404761907006
    ],
    [
        49.631,
        0.19138188095238243
    ],
    [
        49.6414,
        -3.5104593904761896
    ],
    [
        49.6518,
        0.18935013333333522
    ],
    [
        49.6621,
        5.9450608952380986
    ],
    [
        49.6725,
        3.88961115714286
    ],
    [
        49.6828,
        -0.597715442857142
    ],
    [
        49.6932,
        -4.708614914285712
    ],
    [
        49.7036,
        -6.3543292
    ],
    [
        49.7139,
        -7.340945071428571
    ],
    [
        49.7243,
        -7.751809271428571
    ],
    [
        49.7346,
        -10.218348957142855
    ],
    [
        49.745,
        -7.99792144285714
    ],
    [
        49.7554,
        -6.078448190476188
    ],
    [
        49.7657,
        -5.257171295238094
    ],
    [
        49.7761,
        -6.079802690476189
    ],
    [
        49.7865,
        -2.791083114285713
    ],
    [
        49.7968,
        1.1553803809523826
    ],
    [
        49.8072,
        7.732819538095238
    ],
    [
        49.8175,
        5.383347123809526
    ],
    [
        49.8279,
        7.575826842857144
    ],
    [
        49.8383,
        5.930564057142858
    ],
    [
        49.8486,
        7.410487866666668
    ],
    [
        49.859,
        7.163698447619048
    ],
    [
        49.8693,
        7.163698447619048
    ],
    [
        49.8797,
        7.574336895238097
    ],
    [
        49.8901,
        8.396516790476191
    ],
    [
        49.9004,
        9.181324871428572
    ],
    [
        49.9108,
        15.761473023809526
    ],
    [
        49.9211,
        15.761473023809526
    ],
    [
        49.9315,
        16.748088895238098
    ],
    [
        49.9419,
        18.803312880952383
    ],
    [
        49.9522,
        19.625492776190477
    ],
    [
        49.9626,
        23.3280112952381
    ],
    [
        49.973,
        21.56522973809524
    ],
    [
        49.9833,
        20.743952842857148
    ],
    [
        49.9937,
        15.808164480952385
    ],
    [
        50.004,
        16.63034437619048
    ],
    [
        50.0144,
        16.633053371428574
    ],
    [
        50.0248,
        19.92177294761905
    ],
    [
        50.0351,
        22.182767657142858
    ],
    [
        50.0455,
        20.40137788571429
    ],
    [
        50.0558,
        22.86882056666667
    ],
    [
        50.0662,
        25.33536025238096
    ],
    [
        50.0766,
        22.7029397952381
    ],
    [
        50.0869,
        24.922825509523808
    ],
    [
        50.0973,
        26.15609534761905
    ],
    [
        50.1077,
        27.389365190476195
    ],
    [
        50.118,
        23.688201166666673
    ],
    [
        50.1284,
        19.5745927
    ],
    [
        50.1387,
        19.575044199999997
    ],
    [
        50.1491,
        21.219403990476188
    ],
    [
        50.1595,
        21.219403990476188
    ],
    [
        50.1698,
        21.219403990476188
    ],
    [
        50.1802,
        18.75015530952381
    ],
    [
        50.1905,
        18.045042685714282
    ],
    [
        50.2009,
        21.333762261904763
    ],
    [
        50.2113,
        24.62519083333333
    ],
    [
        50.2216,
        23.967446914285713
    ],
    [
        50.232,
        21.499552733333335
    ],
    [
        50.2424,
        14.922113576190478
    ],
    [
        50.2527,
        15.292636328571431
    ],
    [
        50.2631,
        14.059366490476194
    ],
    [
        50.2734,
        13.941912219047625
    ],
    [
        50.2838,
        13.94191221904762
    ],
    [
        50.2942,
        18.055069185714288
    ],
    [
        50.3045,
        18.301723157142856
    ],
    [
        50.3149,
        10.899395114285715
    ],
    [
        50.3252,
        8.02176548095238
    ],
    [
        50.3356,
        11.194385285714286
    ],
    [
        50.346,
        11.358821261904765
    ],
    [
        50.3563,
        10.53618986666667
    ],
    [
        50.3667,
        13.82761843809524
    ],
    [
        50.3771,
        13.005438547619049
    ],
    [
        50.3874,
        14.649798338095238
    ],
    [
        50.3978,
        13.827618447619049
    ],
    [
        50.4081,
        16.29551262857143
    ],
    [
        50.4185,
        14.240062890476194
    ],
    [
        50.4289,
        18.350962361904763
    ],
    [
        50.4392,
        17.912767419047622
    ],
    [
        50.4496,
        14.622693342857144
    ],
    [
        50.4599,
        21.200132500000002
    ],
    [
        50.4703,
        21.035606223809527
    ],
    [
        50.4807,
        21.857786114285716
    ],
    [
        50.491,
        21.97569188571429
    ],
    [
        50.5014,
        15.80934267619048
    ],
    [
        50.5118,
        14.163628390476193
    ],
    [
        50.5221,
        14.16362839047619
    ],
    [
        50.5325,
        20.743776542857145
    ],
    [
        50.5428,
        24.85467601428572
    ],
    [
        50.5532,
        24.855063014285715
    ],
    [
        50.5636,
        18.93536777619048
    ],
    [
        50.5739,
        14.002288409523812
    ],
    [
        50.5843,
        10.300447138095238
    ],
    [
        50.5946,
        9.478267242857143
    ],
    [
        50.605,
        5.366013271428575
    ],
    [
        50.6154,
        2.487706390476195
    ],
    [
        50.6257,
        0.7245378333333315
    ],
    [
        50.6361,
        1.9578076761904748
    ],
    [
        50.6464,
        -1.4953478761904773
    ],
    [
        50.6568,
        -1.3390431809523815
    ],
    [
        50.6672,
        -5.452651647619049
    ],
    [
        50.6775,
        -9.152461171428573
    ],
    [
        50.6879,
        -7.507198385714289
    ],
    [
        50.6983,
        -3.396298914285716
    ],
    [
        50.7086,
        -2.9849832190476215
    ],
    [
        50.719,
        0.714826304761902
    ],
    [
        50.7293,
        1.1259162523809494
    ],
    [
        50.7397,
        1.5379091952380914
    ],
    [
        50.7501,
        -0.10645059523809848
    ],
    [
        50.7604,
        -0.10645059523809881
    ],
    [
        50.7708,
        -0.10683759523809602
    ],
    [
        50.7811,
        3.181881980952377
    ],
    [
        50.7915,
        10.582855528571429
    ],
    [
        50.8019,
        4.415829071428571
    ],
    [
        50.8122,
        4.415829071428571
    ],
    [
        50.8226,
        4.415829071428569
    ],
    [
        50.833,
        6.472407557142858
    ],
    [
        50.8433,
        10.7007613
    ],
    [
        50.8537,
        10.7007613
    ],
    [
        50.864,
        14.153916852380952
    ],
    [
        50.8744,
        15.09355101904762
    ],
    [
        50.8848,
        21.673699171428574
    ],
    [
        50.8951,
        15.507349961904762
    ],
    [
        50.9055,
        8.106376414285716
    ],
    [
        50.9158,
        6.2568102761904765
    ],
    [
        50.9262,
        0.0897838190476162
    ],
    [
        50.9366,
        -1.1428087714285735
    ],
    [
        50.9469,
        -4.020438404761907
    ],
    [
        50.9573,
        -10.59878055714286
    ],
    [
        50.9677,
        -9.612164685714287
    ],
    [
        50.978,
        -13.72396715238095
    ],
    [
        50.9884,
        -13.72396715238095
    ],
    [
        50.9987,
        -11.09299149047619
    ],
    [
        51.0091,
        -13.560885671428572
    ],
    [
        51.0195,
        -8.627806304761906
    ],
    [
        51.0298,
        -6.9834465190476225
    ],
    [
        51.0402,
        -3.2816052476190496
    ],
    [
        51.0505,
        -1.6376969619047645
    ],
    [
        51.0609,
        -7.3934077238095215
    ],
    [
        51.0713,
        -6.776772804761903
    ],
    [
        51.0816,
        -11.709852166666664
    ],
    [
        51.092,
        -8.4184235952381
    ],
    [
        51.1023,
        -9.076167514285718
    ],
    [
        51.1127,
        -4.1430881476190455
    ],
    [
        51.1231,
        0.7899912190476179
    ],
    [
        51.1334,
        1.8173774666666656
    ],
    [
        51.1438,
        11.6835362
    ],
    [
        51.1542,
        12.094851895238094
    ],
    [
        51.1645,
        14.443937309523811
    ],
    [
        51.1749,
        17.73175389047619
    ],
    [
        51.1852,
        17.449863642857146
    ],
    [
        51.1956,
        16.62858674761905
    ],
    [
        51.206,
        10.990781757142862
    ],
    [
        51.2163,
        12.47206006666667
    ],
    [
        51.2267,
        13.458675938095242
    ],
    [
        51.237,
        15.103035728571431
    ],
    [
        51.2474,
        12.636496047619053
    ],
    [
        51.2578,
        12.019522500000003
    ],
    [
        51.2681,
        8.847870195238098
    ],
    [
        51.2785,
        10.081591538095239
    ],
    [
        51.2889,
        4.529168257142858
    ],
    [
        51.2992,
        8.228977780952384
    ],
    [
        51.3096,
        4.115369314285716
    ],
    [
        51.3199,
        8.06183280952381
    ],
    [
        51.3303,
        6.416570023809524
    ],
    [
        51.3407,
        8.883109704761905
    ],
    [
        51.351,
        9.705289595238096
    ],
    [
        51.3614,
        6.005480071428572
    ],
    [
        51.3717,
        3.126947442857142
    ],
    [
        51.3821,
        3.126947442857142
    ],
    [
        51.3925,
        4.772210228571429
    ],
    [
        51.4028,
        4.067484604761905
    ],
    [
        51.4132,
        9.001918466666666
    ],
    [
        51.4236,
        15.168267676190474
    ],
    [
        51.4339,
        17.63345286190476
    ],
    [
        51.4443,
        14.178942809523809
    ],
    [
        51.4546,
        13.52119889047619
    ],
    [
        51.465,
        18.45698725238095
    ],
    [
        51.4754,
        12.906934342857141
    ],
    [
        51.4857,
        14.43467836190476
    ],
    [
        51.4961,
        14.023588414285712
    ],
    [
        51.5064,
        20.603736566666665
    ],
    [
        51.5168,
        20.357624395238094
    ],
    [
        51.5272,
        21.179804290476188
    ],
    [
        51.5375,
        21.182513285714286
    ],
    [
        51.5479,
        27.76085543809523
    ],
    [
        51.5582,
        23.649955966666667
    ],
    [
        51.5686,
        21.888528909523814
    ],
    [
        51.579,
        22.29961885714286
    ],
    [
        51.5893,
        22.29961885714286
    ],
    [
        51.5997,
        22.828163076190478
    ],
    [
        51.6101,
        24.472522866666672
    ],
    [
        51.6204,
        26.117785652380956
    ],
    [
        51.6308,
        25.294251261904762
    ],
    [
        51.6411,
        22.827034328571433
    ],
    [
        51.6515,
        17.893954961904765
    ],
    [
        51.6619,
        20.978484061904762
    ],
    [
        51.6722,
        20.978484061904766
    ],
    [
        51.6826,
        17.688861485714284
    ],
    [
        51.6929,
        20.155401171428572
    ],
    [
        51.7033,
        21.799309457142858
    ],
    [
        51.7137,
        23.44366924761905
    ],
    [
        51.724,
        20.81269358571429
    ],
    [
        51.7344,
        21.058805757142856
    ],
    [
        51.7448,
        19.414445966666666
    ],
    [
        51.7551,
        15.465273476190472
    ],
    [
        51.7655,
        11.766141200000002
    ],
    [
        51.7758,
        14.39711686190476
    ],
    [
        51.7862,
        12.987278614285712
    ],
    [
        51.7966,
        12.329534695238097
    ],
    [
        51.8069,
        15.208067323809521
    ],
    [
        51.8173,
        15.208067323809525
    ],
    [
        51.8276,
        15.208067323809521
    ],
    [
        51.838,
        18.495883904761907
    ],
    [
        51.8484,
        21.787312476190476
    ],
    [
        51.8587,
        25.48779924761905
    ],
    [
        51.8691,
        26.036220176190476
    ],
    [
        51.8795,
        24.186315414285712
    ],
    [
        51.8898,
        24.186857214285713
    ],
    [
        51.9002,
        21.55407555714286
    ],
    [
        51.9105,
        25.007772909523812
    ],
    [
        51.9209,
        22.54123322857143
    ],
    [
        51.9313,
        22.130143280952378
    ],
    [
        51.9416,
        18.180970790476188
    ],
    [
        51.952,
        21.880780314285715
    ],
    [
        51.9623,
        24.347319995238095
    ],
    [
        51.9727,
        23.90912505238095
    ],
    [
        51.9831,
        25.14171764285714
    ],
    [
        51.9934,
        24.15510177142857
    ],
    [
        52.0038,
        22.391933214285714
    ],
    [
        52.0142,
        22.391933214285714
    ],
    [
        52.0245,
        19.759512757142858
    ],
    [
        52.0349,
        19.759512757142854
    ],
    [
        52.0452,
        19.348422809523804
    ],
    [
        52.0556,
        15.237523338095235
    ],
    [
        52.066,
        9.753615047619048
    ],
    [
        52.0763,
        7.59539282857143
    ],
    [
        52.0867,
        9.514866080952382
    ],
    [
        52.097,
        10.748135923809524
    ],
    [
        52.1074,
        8.527031161904763
    ],
    [
        52.1178,
        10.747594123809526
    ],
    [
        52.1281,
        12.226976133333334
    ],
    [
        52.1385,
        12.226976133333334
    ],
    [
        52.1488,
        12.638969076190476
    ],
    [
        52.1592,
        18.561373309523812
    ],
    [
        52.1696,
        16.094833623809524
    ],
    [
        52.1799,
        11.983934152380952
    ],
    [
        52.1903,
        12.140238847619047
    ],
    [
        52.2007,
        10.660315038095238
    ],
    [
        52.211,
        11.89426212857143
    ],
    [
        52.2214,
        15.348772180952377
    ],
    [
        52.2317,
        12.716893523809526
    ],
    [
        52.2421,
        12.059691404761903
    ],
    [
        52.2525,
        11.37454149047619
    ],
    [
        52.2628,
        12.609165828571431
    ],
    [
        52.2732,
        9.320446252380952
    ],
    [
        52.2835,
        9.868566180952381
    ],
    [
        52.2939,
        14.496037080952382
    ],
    [
        52.3043,
        14.494682580952382
    ],
    [
        52.3146,
        9.561603214285716
    ],
    [
        52.325,
        11.782707976190476
    ],
    [
        52.3354,
        13.016655066666669
    ],
    [
        52.3457,
        11.372295280952383
    ],
    [
        52.3561,
        13.838834961904762
    ],
    [
        52.3664,
        13.427519266666666
    ],
    [
        52.3768,
        11.207633552380951
    ],
    [
        52.3872,
        9.974363714285714
    ],
    [
        52.3975,
        13.26398629047619
    ],
    [
        52.4079,
        15.025800347619048
    ],
    [
        52.4182,
        13.052568604761905
    ],
    [
        52.4286,
        12.523347138095238
    ],
    [
        52.439,
        12.358911161904762
    ],
    [
        52.4493,
        18.937253314285716
    ],
    [
        52.4597,
        22.22687589047619
    ],
    [
        52.4701,
        24.146650142857148
    ],
    [
        52.4804,
        20.692140090476194
    ],
    [
        52.4908,
        24.803039561904765
    ],
    [
        52.5011,
        26.03630940476191
    ],
    [
        52.5115,
        16.167441676190474
    ],
    [
        52.5219,
        16.168796176190476
    ],
    [
        52.5322,
        16.168796176190476
    ],
    [
        52.5426,
        16.826901290476194
    ],
    [
        52.5529,
        15.345622980952381
    ],
    [
        52.5633,
        9.590363719047621
    ],
    [
        52.5737,
        7.572532071428572
    ],
    [
        52.584,
        8.806479161904763
    ],
    [
        52.5944,
        11.273018847619047
    ],
    [
        52.6047,
        9.053133133333334
    ],
    [
        52.6151,
        9.052230138095236
    ],
    [
        52.6255,
        7.818960299999999
    ],
    [
        52.6358,
        11.27347035238095
    ],
    [
        52.6462,
        11.116864657142854
    ],
    [
        52.6566,
        9.472504866666664
    ],
    [
        52.6669,
        5.773372590476191
    ],
    [
        52.6773,
        5.361379647619048
    ],
    [
        52.6876,
        0.42694578571428615
    ],
    [
        52.698,
        3.8814558380952398
    ],
    [
        52.7084,
        2.853730966666668
    ],
    [
        52.7187,
        0.9153484999999997
    ],
    [
        52.7291,
        6.55315349047619
    ],
    [
        52.7394,
        2.440899519047621
    ],
    [
        52.7498,
        7.922399814285717
    ],
    [
        52.7602,
        5.043867185714287
    ],
    [
        52.7705,
        1.5893571333333345
    ],
    [
        52.7809,
        7.139071419047621
    ],
    [
        52.7913,
        6.690363385714288
    ],
    [
        52.8016,
        5.4557390476190495
    ],
    [
        52.812,
        2.5772064190476205
    ],
    [
        52.8223,
        4.112243219047621
    ],
    [
        52.8327,
        7.400962795238097
    ],
    [
        52.8431,
        6.872418576190478
    ],
    [
        52.8534,
        -0.528554971428571
    ],
    [
        52.8638,
        0.15659494285714393
    ],
    [
        52.8741,
        -0.0083828333333324
    ],
    [
        52.8845,
        -0.6256950047619038
    ],
    [
        52.8949,
        -6.792044214285714
    ],
    [
        52.9052,
        -3.0922346904761904
    ],
    [
        52.9156,
        -3.915769080952381
    ],
    [
        52.926,
        -5.51956167142857
    ],
    [
        52.9363,
        -3.8272913761904754
    ],
    [
        52.9467,
        -4.038709061904761
    ],
    [
        52.957,
        -2.8876572095238098
    ],
    [
        52.9674,
        -0.9681839571428564
    ],
    [
        52.9778,
        1.498355728571429
    ],
    [
        52.9881,
        4.953407580952382
    ],
    [
        52.9985,
        -0.596306704761904
    ],
    [
        53.0088,
        1.8702329761904768
    ],
    [
        53.0192,
        1.4591430285714282
    ],
    [
        53.0296,
        1.460046023809524
    ],
    [
        53.0399,
        1.8987827666666677
    ],
    [
        53.0503,
        -7.967375966666667
    ],
    [
        53.0606,
        -3.736313228571429
    ],
    [
        53.071,
        1.9014917619047624
    ],
    [
        53.0814,
        -4.264857447619049
    ],
    [
        53.0917,
        -2.0437526857142854
    ],
    [
        53.1021,
        -1.0160278142857158
    ],
    [
        53.1125,
        5.562314338095236
    ],
    [
        53.1228,
        3.5068646
    ],
    [
        53.1332,
        6.795584176190475
    ],
    [
        53.1435,
        9.01601169047619
    ],
    [
        53.1539,
        12.961933385714284
    ],
    [
        53.1643,
        13.291256842857143
    ],
    [
        53.1746,
        18.720353142857142
    ],
    [
        53.185,
        16.869432504761907
    ],
    [
        53.1953,
        10.703083295238095
    ],
    [
        53.2057,
        11.360285414285713
    ],
    [
        53.2161,
        17.93772457142857
    ],
    [
        53.2264,
        20.40697325238095
    ],
    [
        53.2368,
        18.598177485714285
    ],
    [
        53.2472,
        20.9472629
    ],
    [
        53.2575,
        19.96010522857143
    ],
    [
        53.2679,
        25.441304523809528
    ],
    [
        53.2782,
        18.860253376190474
    ],
    [
        53.2886,
        23.091316114285714
    ],
    [
        53.299,
        29.668755271428576
    ],
    [
        53.3093,
        27.61262828571429
    ],
    [
        53.3197,
        26.672994119047623
    ],
    [
        53.3301,
        27.494271014285715
    ],
    [
        53.3404,
        29.139082304761907
    ],
    [
        53.3508,
        25.028182833333332
    ],
    [
        53.3611,
        28.72799235714286
    ],
    [
        53.3715,
        22.561643147619048
    ],
    [
        53.3819,
        23.383371542857144
    ],
    [
        53.3922,
        18.896537485714287
    ],
    [
        53.4026,
        17.046294100000004
    ],
    [
        53.4129,
        19.511479285714284
    ],
    [
        53.4233,
        19.10038933809524
    ],
    [
        53.4337,
        17.45602954761905
    ],
    [
        53.444,
        14.164600976190478
    ],
    [
        53.4544,
        17.619111028571428
    ],
    [
        53.4647,
        21.847464771428573
    ],
    [
        53.4751,
        23.080734614285717
    ],
    [
        53.4855,
        24.176974476190477
    ],
    [
        53.4958,
        20.886448899999998
    ],
    [
        53.5062,
        16.937276409523808
    ],
    [
        53.5166,
        13.647653833333331
    ],
    [
        53.5269,
        14.880923676190475
    ],
    [
        53.5373,
        9.243118685714283
    ],
    [
        53.5476,
        7.763736676190474
    ],
    [
        53.558,
        5.707835438095235
    ],
    [
        53.5684,
        5.708286938095234
    ],
    [
        53.5787,
        -0.8691522190476206
    ],
    [
        53.5891,
        2.008477414285714
    ],
    [
        53.5994,
        1.5980647142857143
    ],
    [
        53.6098,
        1.971290304761904
    ],
    [
        53.6202,
        1.560200357142856
    ],
    [
        53.6305,
        5.673357323809524
    ],
    [
        53.6409,
        6.084447271428569
    ],
    [
        53.6513,
        11.020235633333332
    ],
    [
        53.6616,
        10.609145685714285
    ],
    [
        53.672,
        6.907981661904762
    ],
    [
        53.6823,
        3.2088493857142826
    ],
    [
        53.6927,
        6.90865890952381
    ],
    [
        53.7031,
        5.675389066666667
    ],
    [
        53.7134,
        10.162715666666665
    ],
    [
        53.7238,
        10.162715666666665
    ],
    [
        53.7341,
        12.356399380952379
    ],
    [
        53.7445,
        11.123129538095238
    ],
    [
        53.7549,
        16.878388800000003
    ],
    [
        53.7652,
        15.068599733333336
    ],
    [
        53.7756,
        16.712959523809527
    ],
    [
        53.786,
        14.245968338095244
    ],
    [
        53.7963,
        16.712959523809527
    ],
    [
        53.8067,
        15.06859973333334
    ],
    [
        53.817,
        13.834652642857147
    ],
    [
        53.8274,
        14.656832538095243
    ],
    [
        53.8378,
        16.30119232857143
    ],
    [
        53.8481,
        14.655929542857146
    ],
    [
        53.8585,
        13.628543295238098
    ],
    [
        53.8688,
        7.048395142857146
    ],
    [
        53.8792,
        6.637756695238098
    ],
    [
        53.8896,
        5.404486857142861
    ],
    [
        53.8999,
        5.8148995571428586
    ],
    [
        53.9103,
        2.527082976190476
    ],
    [
        53.9206,
        2.115993028571429
    ],
    [
        53.931,
        3.7977246333333334
    ],
    [
        53.9414,
        7.746897123809525
    ],
    [
        53.9517,
        9.665015876190477
    ],
    [
        53.9621,
        15.420275138095239
    ],
    [
        53.9725,
        19.533883604761904
    ],
    [
        53.9828,
        19.651789376190475
    ],
    [
        53.9932,
        19.689161190476188
    ],
    [
        54.0035,
        22.038633604761905
    ],
    [
        54.0139,
        19.571642419047617
    ],
    [
        54.0243,
        24.507430780952383
    ],
    [
        54.0346,
        29.443219142857146
    ],
    [
        54.045,
        28.503584976190478
    ],
    [
        54.0553,
        26.037045295238098
    ],
    [
        54.0657,
        30.970124661904762
    ],
    [
        54.0761,
        27.88661143809524
    ],
    [
        54.0864,
        30.02455006666667
    ],
    [
        54.0968,
        24.268839304761904
    ],
    [
        54.1072,
        29.201918666666664
    ],
    [
        54.1175,
        28.262671499999993
    ],
    [
        54.1279,
        25.384138871428572
    ],
    [
        54.1382,
        23.73977908095238
    ],
    [
        54.1486,
        23.12348278095239
    ],
    [
        54.159,
        20.654234100000004
    ],
    [
        54.1693,
        19.420964261904764
    ],
    [
        54.1797,
        12.021345214285718
    ],
    [
        54.19,
        7.633676780952383
    ],
    [
        54.2004,
        5.871862723809524
    ],
    [
        54.2108,
        0.4876126000000022
    ],
    [
        54.2211,
        0.19358992380952472
    ],
    [
        54.2315,
        6.773738076190479
    ],
    [
        54.2418,
        4.304489395238097
    ],
    [
        54.2522,
        -1.3364115904761893
    ],
    [
        54.2626,
        -1.3364115904761904
    ],
    [
        54.2729,
        -0.5151346952380942
    ],
    [
        54.2833,
        -4.461598190476192
    ],
    [
        54.2937,
        2.9380208571428597
    ],
    [
        54.304,
        7.377521385714287
    ],
    [
        54.3144,
        12.7216907
    ],
    [
        54.3247,
        9.706730090476192
    ],
    [
        54.3351,
        9.001617466666668
    ],
    [
        54.3455,
        10.78330823809524
    ],
    [
        54.3558,
        10.78330823809524
    ],
    [
        54.3662,
        11.153492366666669
    ],
    [
        54.3766,
        8.686952685714289
    ],
    [
        54.3869,
        9.098042633333337
    ],
    [
        54.3973,
        14.032476495238097
    ],
    [
        54.4076,
        15.128716357142858
    ],
    [
        54.418,
        19.23961582857143
    ],
    [
        54.4284,
        24.17540419047619
    ],
    [
        54.4387,
        23.05958861904762
    ],
    [
        54.4491,
        19.35774734761905
    ],
    [
        54.4594,
        17.50818120952381
    ],
    [
        54.4698,
        19.200451504761904
    ],
    [
        54.4802,
        17.94760595238095
    ],
    [
        54.4905,
        20.140988666666665
    ],
    [
        54.5009,
        16.687833114285713
    ],
    [
        54.5112,
        12.988700838095237
    ],
    [
        54.5216,
        9.288891314285715
    ],
    [
        54.532,
        10.52216115714286
    ],
    [
        54.5423,
        10.083966214285715
    ],
    [
        54.5527,
        11.317236057142859
    ],
    [
        54.5631,
        12.002762219047622
    ],
    [
        54.5734,
        11.180582328571429
    ],
    [
        54.5838,
        15.129213019047622
    ],
    [
        54.5941,
        15.74584793809524
    ],
    [
        54.6045,
        12.45712835714286
    ],
    [
        54.6149,
        10.694346800000002
    ],
    [
        54.6252,
        9.872166904761908
    ],
    [
        54.6356,
        3.2947277476190493
    ],
    [
        54.6459,
        5.76126742857143
    ],
    [
        54.6563,
        1.5329136857142867
    ],
    [
        54.6667,
        -1.34629619047619
    ],
    [
        54.677,
        -1.1410898380952381
    ],
    [
        54.6874,
        -1.1410898380952381
    ],
    [
        54.6978,
        4.340109457142858
    ],
    [
        54.7081,
        4.7791472
    ],
    [
        54.7185,
        12.178766247619048
    ],
    [
        54.7288,
        12.589178947619049
    ],
    [
        54.7392,
        8.888014923809523
    ],
    [
        54.7496,
        7.243655133333334
    ],
    [
        54.7599,
        8.93499662857143
    ],
    [
        54.7703,
        8.935673876190478
    ],
    [
        54.7806,
        5.235187104761904
    ],
    [
        54.791,
        12.634806152380953
    ],
    [
        54.8014,
        8.315991333333333
    ],
    [
        54.8117,
        7.699356414285712
    ],
    [
        54.8221,
        6.875822023809524
    ],
    [
        54.8324,
        6.1707094
    ],
    [
        54.8428,
        6.581799347619047
    ],
    [
        54.8532,
        12.748148557142857
    ],
    [
        54.8635,
        11.515555966666668
    ],
    [
        54.8739,
        18.915175014285712
    ],
    [
        54.8843,
        22.205700585714283
    ],
    [
        54.8946,
        20.150250847619045
    ],
    [
        54.905,
        19.985273071428573
    ],
    [
        54.9153,
        16.6965534952381
    ],
    [
        54.9257,
        20.64518418571429
    ],
    [
        54.9361,
        16.41721744285714
    ],
    [
        54.9464,
        13.128497861904764
    ],
    [
        54.9568,
        20.52947140952381
    ],
    [
        54.9671,
        22.99601109047619
    ],
    [
        54.9775,
        27.224364833333333
    ],
    [
        54.9879,
        27.224364833333333
    ],
    [
        54.9982,
        28.45763467142857
    ],
    [
        55.0086,
        24.511171176190476
    ],
    [
        55.019,
        24.598923257142857
    ],
    [
        55.0293,
        22.132383576190477
    ],
    [
        55.0397,
        24.600277757142855
    ],
    [
        55.05,
        25.834224847619048
    ],
    [
        55.0604,
        29.536743366666673
    ],
    [
        55.0708,
        29.008199147619052
    ],
    [
        55.0811,
        26.12989226666667
    ],
    [
        55.0915,
        24.64996845714286
    ],
    [
        55.1018,
        21.770081333333337
    ],
    [
        55.1122,
        23.989967047619054
    ],
    [
        55.1226,
        24.15440302380953
    ],
    [
        55.1329,
        22.510043233333334
    ],
    [
        55.1433,
        20.04214905238096
    ],
    [
        55.1536,
        20.981396219047628
    ],
    [
        55.164,
        24.270115800000003
    ],
    [
        55.1744,
        21.80493061428572
    ],
    [
        55.1847,
        14.405311566666668
    ],
    [
        55.1951,
        9.965811038095241
    ],
    [
        55.2055,
        7.498594104761905
    ],
    [
        55.2158,
        9.279983876190478
    ],
    [
        55.2262,
        5.825473823809525
    ],
    [
        55.2365,
        7.587287880952381
    ],
    [
        55.2469,
        12.520367242857146
    ],
    [
        55.2573,
        13.753637085714288
    ],
    [
        55.2676,
        11.286420152380952
    ],
    [
        55.278,
        6.730307819047617
    ],
    [
        55.2883,
        4.381222404761904
    ],
    [
        55.2987,
        10.961370557142857
    ],
    [
        55.3091,
        12.441294366666666
    ],
    [
        55.3194,
        14.663076376190475
    ],
    [
        55.3298,
        8.743381138095236
    ],
    [
        55.3402,
        8.578945161904759
    ],
    [
        55.3505,
        7.344998071428568
    ],
    [
        55.3609,
        9.810183257142857
    ],
    [
        55.3712,
        8.988003361904761
    ],
    [
        55.3816,
        6.521463676190476
    ],
    [
        55.392,
        4.464659442857142
    ],
    [
        55.4023,
        6.929844628571428
    ],
    [
        55.4127,
        6.436265790476191
    ],
    [
        55.423,
        8.286170552380952
    ],
    [
        55.4334,
        8.442776247619047
    ],
    [
        55.4438,
        9.266310638095238
    ],
    [
        55.4541,
        7.621950852380952
    ],
    [
        55.4645,
        0.21962280952381127
    ],
    [
        55.4749,
        -3.4801867142857144
    ],
    [
        55.4852,
        -3.89127666190476
    ],
    [
        55.4956,
        -3.7773738523809532
    ],
    [
        55.5059,
        2.800065304761907
    ],
    [
        55.5163,
        -1.4306104333333334
    ],
    [
        55.5267,
        -5.541509904761904
    ],
    [
        55.537,
        -7.077088504761904
    ],
    [
        55.5474,
        -1.8356916761904758
    ],
    [
        55.5577,
        -4.30223136190476
    ],
    [
        55.5681,
        -2.363171647619048
    ],
    [
        55.5785,
        -8.282866885714284
    ],
    [
        55.5888,
        -7.4597839952380935
    ],
    [
        55.5992,
        -1.7045247333333333
    ],
    [
        55.6095,
        -1.410889057142855
    ],
    [
        55.6199,
        2.7013649142857155
    ],
    [
        55.6303,
        0.23347073333333446
    ],
    [
        55.6406,
        4.549915180952382
    ],
    [
        55.651,
        5.079136647619048
    ],
    [
        55.6614,
        7.340131357142859
    ],
    [
        55.6717,
        5.695771566666668
    ],
    [
        55.6821,
        8.987200138095242
    ],
    [
        55.6924,
        13.920279499999998
    ],
    [
        55.7028,
        16.386819185714284
    ],
    [
        55.7132,
        18.36113452857143
    ],
    [
        55.7235,
        8.494975795238096
    ],
    [
        55.7339,
        8.238571204761907
    ],
    [
        55.7443,
        8.238571204761907
    ],
    [
        55.7546,
        10.432254919047622
    ],
    [
        55.765,
        12.59183163809524
    ],
    [
        55.7753,
        19.1692707952381
    ],
    [
        55.7857,
        15.997618490476198
    ],
    [
        55.7961,
        17.688959985714288
    ],
    [
        55.8064,
        16.660332119047624
    ],
    [
        55.8168,
        11.11061783333333
    ],
    [
        55.8271,
        11.022865752380952
    ],
    [
        55.8375,
        11.845045642857144
    ],
    [
        55.8479,
        11.846400142857146
    ],
    [
        55.8582,
        7.735500671428572
    ],
    [
        55.8686,
        7.735500671428572
    ],
    [
        55.879,
        6.131708080952382
    ],
    [
        55.8893,
        7.940503847619048
    ],
    [
        55.8997,
        11.22922342857143
    ],
    [
        55.91,
        9.995953590476192
    ],
    [
        55.9204,
        8.468661071428574
    ],
    [
        55.9308,
        7.234036733333335
    ],
    [
        55.9411,
        12.167116100000001
    ],
    [
        55.9515,
        12.335381609523811
    ],
    [
        55.9618,
        13.363106480952384
    ],
    [
        55.9722,
        12.129159390476191
    ],
    [
        55.9826,
        8.427995366666668
    ],
    [
        55.9929,
        4.200028623809524
    ],
    [
        56.0033,
        4.2000286238095255
    ],
    [
        56.0136,
        4.317482895238096
    ],
    [
        56.024,
        8.636636338095238
    ],
    [
        56.0344,
        11.71981093809524
    ],
    [
        56.0447,
        6.169758028571429
    ],
    [
        56.0551,
        4.407943971428572
    ],
    [
        56.0655,
        8.519746438095236
    ],
    [
        56.0758,
        6.464296699999998
    ],
    [
        56.0862,
        2.763809928571427
    ],
    [
        56.0965,
        4.299087528571427
    ],
    [
        56.1069,
        2.0785245666666645
    ],
    [
        56.1173,
        0.9274727142857144
    ],
    [
        56.1276,
        -1.9510599142857141
    ],
    [
        56.138,
        -0.6293123714285714
    ],
    [
        56.1483,
        -2.4792171333333335
    ],
    [
        56.1587,
        -1.77449150952381
    ],
    [
        56.1691,
        -1.568833657142858
    ],
    [
        56.1794,
        -0.24747311428571483
    ],
    [
        56.1898,
        0.9864739761904756
    ],
    [
        56.2001,
        1.2331279476190475
    ],
    [
        56.2105,
        2.172375114285714
    ],
    [
        56.2209,
        -0.29687356666666853
    ],
    [
        56.2312,
        -1.1190534619047636
    ],
    [
        56.2416,
        -5.561533885714287
    ],
    [
        56.252,
        -6.382810780952385
    ],
    [
        56.2623,
        -3.9162710952380966
    ],
    [
        56.2727,
        -4.62099671904762
    ],
    [
        56.283,
        -5.279643633333333
    ],
    [
        56.2934,
        -5.280320880952382
    ],
    [
        56.3038,
        2.119298166666666
    ],
    [
        56.3141,
        2.0196399999999994
    ],
    [
        56.3245,
        -1.6822012714285717
    ],
    [
        56.3348,
        -3.162125080952382
    ],
    [
        56.3452,
        -1.0681542619047637
    ],
    [
        56.3556,
        -1.6847891809523818
    ],
    [
        56.3659,
        3.8649251047619035
    ],
    [
        56.3763,
        1.5149366952380943
    ],
    [
        56.3867,
        1.1856132380952373
    ],
    [
        56.397,
        0.48088761428571347
    ],
    [
        56.4074,
        0.06889467142857156
    ],
    [
        56.4177,
        2.2887803857142863
    ],
    [
        56.4281,
        2.2896833809523804
    ],
    [
        56.4385,
        5.581111952380954
    ],
    [
        56.4488,
        6.567727823809525
    ],
    [
        56.4592,
        11.010208247619047
    ],
    [
        56.4695,
        11.831485142857142
    ],
    [
        56.4799,
        14.298024823809525
    ],
    [
        56.4903,
        19.23110419047619
    ],
    [
        56.5006,
        19.477758161904763
    ],
    [
        56.511,
        19.06734546190476
    ],
    [
        56.5214,
        14.956445990476192
    ],
    [
        56.5317,
        15.330164123809524
    ],
    [
        56.5421,
        22.733846666666672
    ],
    [
        56.5524,
        28.65354190476191
    ],
    [
        56.5628,
        28.909559495238103
    ],
    [
        56.5732,
        28.20444687142858
    ],
    [
        56.5835,
        23.764675442857147
    ],
    [
        56.5939,
        24.17599113809524
    ],
    [
        56.6042,
        25.327945985714287
    ],
    [
        56.6146,
        26.97230577619048
    ],
    [
        56.625,
        26.44376155714286
    ],
    [
        56.6353,
        24.717183776190485
    ],
    [
        56.6457,
        23.68855590952381
    ],
    [
        56.656,
        25.332915700000008
    ],
    [
        56.6664,
        24.96293474761905
    ],
    [
        56.6768,
        21.260416228571426
    ],
    [
        56.6871,
        18.793876547619046
    ],
    [
        56.6975,
        16.325982366666665
    ],
    [
        56.7079,
        12.37951887142857
    ],
    [
        56.7182,
        12.133406699999998
    ],
    [
        56.7286,
        12.133406699999998
    ],
    [
        56.7389,
        8.844687123809525
    ],
    [
        56.7493,
        8.72761985238095
    ],
    [
        56.7597,
        8.726265352380953
    ],
    [
        56.77,
        5.438448771428572
    ],
    [
        56.7804,
        5.43844877142857
    ],
    [
        56.7907,
        4.499201604761906
    ],
    [
        56.8011,
        2.3615338761904745
    ],
    [
        56.8115,
        4.829428057142858
    ],
    [
        56.8218,
        4.418112361904762
    ],
    [
        56.8322,
        0.3072128904761902
    ],
    [
        56.8426,
        1.2477500523809524
    ],
    [
        56.8529,
        2.5632378857142855
    ],
    [
        56.8633,
        -0.5199367142857141
    ],
    [
        56.8736,
        0.028183214285714457
    ],
    [
        56.884,
        -1.2050866238095237
    ],
    [
        56.8944,
        -2.058680438095238
    ],
    [
        56.9047,
        -0.8254105952380949
    ],
    [
        56.9151,
        0.40921374285714307
    ],
    [
        56.9255,
        4.358386233333333
    ],
    [
        56.9358,
        4.357844433333335
    ],
    [
        56.9462,
        10.93528359047619
    ],
    [
        56.9565,
        8.468743904761906
    ],
    [
        56.9669,
        8.380604823809525
    ],
    [
        56.9773,
        8.380604823809525
    ],
    [
        56.9876,
        8.379701828571429
    ],
    [
        56.998,
        6.441706361904762
    ],
    [
        57.0083,
        5.619526471428571
    ],
    [
        57.0187,
        8.910052047619047
    ],
    [
        57.0291,
        8.224224885714285
    ],
    [
        57.0394,
        8.634637585714284
    ],
    [
        57.0498,
        6.165388904761902
    ],
    [
        57.0601,
        -0.4129532476190495
    ],
    [
        57.0705,
        -0.5304075190476206
    ],
    [
        57.0809,
        0.7021850714285685
    ],
    [
        57.0912,
        0.6473730809523793
    ],
    [
        57.1016,
        -0.3803517904761938
    ],
    [
        57.1119,
        -5.693107185714289
    ],
    [
        57.1223,
        -6.92637702857143
    ],
    [
        57.1327,
        -10.626186552380956
    ],
    [
        57.143,
        -14.739343519047619
    ],
    [
        57.1534,
        -13.259419709523812
    ],
    [
        57.1638,
        -18.19249907619048
    ],
    [
        57.1741,
        -12.025472619047619
    ],
    [
        57.1845,
        -11.93772053809524
    ],
    [
        57.1948,
        -19.337339585714282
    ],
    [
        57.2052,
        -16.045911014285714
    ],
    [
        57.2156,
        -14.107915547619045
    ],
    [
        57.2259,
        -13.285735657142858
    ],
    [
        57.2363,
        -14.383480514285715
    ],
    [
        57.2467,
        -16.164870285714283
    ],
    [
        57.257,
        -22.745018438095233
    ],
    [
        57.2674,
        -17.261110147619046
    ],
    [
        57.2777,
        -13.972390571428573
    ],
    [
        57.2881,
        -13.689958523809523
    ],
    [
        57.2985,
        -11.633831538095238
    ],
    [
        57.3088,
        -17.060218842857143
    ],
    [
        57.3192,
        -20.351647414285715
    ],
    [
        57.3295,
        -12.952028366666669
    ],
    [
        57.3399,
        -11.965412495238096
    ],
    [
        57.3503,
        -6.3276075047619065
    ],
    [
        57.3606,
        -8.136854771428574
    ],
    [
        57.371,
        -15.53647381904762
    ],
    [
        57.3813,
        -15.53647381904762
    ],
    [
        57.3917,
        -16.065695285714284
    ],
    [
        57.4021,
        -16.221999980952383
    ],
    [
        57.4124,
        -9.644560823809528
    ],
    [
        57.4228,
        -14.580349185714287
    ],
    [
        57.4332,
        -16.9294346
    ],
    [
        57.4435,
        -15.285074809523808
    ],
    [
        57.4539,
        -20.7692841
    ],
    [
        57.4642,
        -18.71383436190476
    ],
    [
        57.4746,
        -8.844966633333334
    ],
    [
        57.485,
        -8.40647069047619
    ],
    [
        57.4953,
        -1.829031533333334
    ],
    [
        57.5057,
        2.119599157142857
    ],
    [
        57.516,
        1.9143928047619065
    ],
    [
        57.5264,
        6.230837252380954
    ],
    [
        57.5368,
        11.546093257142855
    ],
    [
        57.5471,
        8.462580033333333
    ],
    [
        57.5575,
        8.709234004761905
    ],
    [
        57.5679,
        12.937587747619048
    ],
    [
        57.5782,
        15.569466404761906
    ],
    [
        57.5886,
        21.32472566666667
    ],
    [
        57.5989,
        22.96908545714286
    ],
    [
        57.6093,
        27.1974392
    ],
    [
        57.6197,
        21.71322990952381
    ],
    [
        57.63,
        20.891050014285717
    ],
    [
        57.6404,
        23.3589441952381
    ],
    [
        57.6507,
        26.647663776190484
    ],
    [
        57.6611,
        21.71187541428572
    ],
    [
        57.6715,
        25.00330398571428
    ],
    [
        57.6818,
        24.674702928571428
    ],
    [
        57.6922,
        18.09726377142858
    ],
    [
        57.7025,
        18.09726377142858
    ],
    [
        57.7129,
        10.697644723809528
    ],
    [
        57.7233,
        10.694935728571432
    ],
    [
        57.7336,
        11.722321976190479
    ],
    [
        57.744,
        13.572903985714287
    ],
    [
        57.7544,
        10.72554216190476
    ],
    [
        57.7647,
        12.329131576190475
    ],
    [
        57.7751,
        12.32913157619048
    ],
    [
        57.7854,
        2.4629728428571425
    ],
    [
        57.7958,
        1.4346836000000007
    ],
    [
        57.8062,
        -4.320575661904763
    ],
    [
        57.8165,
        -5.964935452380954
    ],
    [
        57.8269,
        -10.075834923809524
    ],
    [
        57.8372,
        -5.140046561904763
    ],
    [
        57.8476,
        -8.430120638095238
    ],
    [
        57.858,
        -10.40470688095238
    ],
    [
        57.8683,
        -10.815119580952379
    ],
    [
        57.8787,
        -7.524594004761904
    ],
    [
        57.8891,
        -10.813313580952384
    ],
    [
        57.8994,
        -12.95125220952381
    ],
    [
        57.9098,
        -10.601779795238095
    ],
    [
        57.9201,
        -10.883670042857142
    ],
    [
        57.9305,
        -10.061490152380951
    ],
    [
        57.9409,
        -13.350209728571425
    ],
    [
        57.9512,
        -14.994569519047616
    ],
    [
        57.9616,
        -16.228516609523805
    ],
    [
        57.9719,
        -13.143648885714283
    ],
    [
        57.9823,
        -12.896994914285713
    ],
    [
        57.9927,
        -14.95289615238095
    ],
    [
        58.003,
        -9.033200914285715
    ],
    [
        58.0134,
        -7.183634776190475
    ],
    [
        58.0238,
        -0.6061956190476178
    ],
    [
        58.0341,
        -5.54198398095238
    ],
    [
        58.0445,
        -5.130894033333332
    ],
    [
        58.0548,
        -3.4865342428571426
    ],
    [
        58.0652,
        0.2146297809523816
    ],
    [
        58.0756,
        2.187861523809524
    ],
    [
        58.0859,
        -0.03378503809523807
    ],
    [
        58.0963,
        2.1595976761904763
    ],
    [
        58.1066,
        8.079834714285713
    ],
    [
        58.117,
        7.257654823809524
    ],
    [
        58.1274,
        7.539158071428571
    ],
    [
        58.1377,
        4.367892766666666
    ],
    [
        58.1481,
        7.6575153428571445
    ],
    [
        58.1584,
        7.657515342857142
    ],
    [
        58.1688,
        8.362240966666665
    ],
    [
        58.1792,
        7.375625095238094
    ],
    [
        58.1895,
        8.403011342857143
    ],
    [
        58.1999,
        8.815004285714284
    ],
    [
        58.2103,
        11.28199547142857
    ],
    [
        58.2206,
        10.295379599999999
    ],
    [
        58.231,
        8.533565542857142
    ],
    [
        58.2413,
        5.6559359095238095
    ],
    [
        58.2517,
        11.824994114285712
    ],
    [
        58.2621,
        11.085303109523807
    ],
    [
        58.2724,
        8.207673476190473
    ],
    [
        58.2828,
        7.961019504761903
    ],
    [
        58.2931,
        4.26120998095238
    ],
    [
        58.3035,
        7.716803633333332
    ],
    [
        58.3139,
        7.168683704761903
    ],
    [
        58.3242,
        6.886251657142855
    ],
    [
        58.3346,
        10.174971233333332
    ],
    [
        58.345,
        7.543092576190475
    ],
    [
        58.3553,
        8.776362414285712
    ],
    [
        58.3657,
        7.747734547619048
    ],
    [
        58.376,
        7.090532428571426
    ],
    [
        58.3864,
        7.619076647619047
    ],
    [
        58.3968,
        6.137798338095238
    ],
    [
        58.4071,
        -0.43964081904762037
    ],
    [
        58.4175,
        -1.468268685714286
    ],
    [
        58.4278,
        1.8231598857142852
    ],
    [
        58.4382,
        0.5898900428571421
    ],
    [
        58.4486,
        1.1184342619047616
    ],
    [
        58.4589,
        3.99696689047619
    ],
    [
        58.4693,
        -2.16938231904762
    ],
    [
        58.4797,
        -1.840781261904762
    ],
    [
        58.49,
        -2.2527742047619044
    ],
    [
        58.5004,
        -1.5950302857142855
    ],
    [
        58.5107,
        -2.828300123809523
    ],
    [
        58.5211,
        -4.591081680952381
    ],
    [
        58.5315,
        -2.9467218904761907
    ],
    [
        58.5418,
        -1.1849078333333338
    ],
    [
        58.5522,
        -1.3901141857142858
    ],
    [
        58.5625,
        -0.7314672714285715
    ],
    [
        58.5729,
        5.437590933333334
    ],
    [
        58.5833,
        5.200423657142858
    ],
    [
        58.5936,
        2.9799961428571438
    ],
    [
        58.604,
        4.214620480952381
    ],
    [
        58.6144,
        7.669130533333334
    ],
    [
        58.6247,
        10.95785010952381
    ],
    [
        58.6351,
        11.574485033333334
    ],
    [
        58.6454,
        7.625312542857143
    ],
    [
        58.6558,
        11.325122066666667
    ],
    [
        58.6662,
        9.104694552380952
    ],
    [
        58.6765,
        8.446047638095239
    ],
    [
        58.6869,
        15.845666685714285
    ],
    [
        58.6972,
        15.023486790476191
    ],
    [
        58.7076,
        17.3734752
    ],
    [
        58.718,
        20.66219477619048
    ],
    [
        58.7283,
        18.190237104761906
    ],
    [
        58.7387,
        18.30730437619048
    ],
    [
        58.749,
        15.429674742857149
    ],
    [
        58.7594,
        13.511254990476196
    ],
    [
        58.7698,
        7.9584930857142915
    ],
    [
        58.7801,
        9.931724828571435
    ],
    [
        58.7905,
        4.379301547619046
    ],
    [
        58.8009,
        5.233780995238094
    ],
    [
        58.8112,
        7.083685757142858
    ],
    [
        58.8216,
        3.6286339047619056
    ],
    [
        58.8319,
        3.464197928571429
    ],
    [
        58.8423,
        5.9307376142857136
    ],
    [
        58.8527,
        3.0522049857142854
    ],
    [
        58.863,
        2.5588970523809524
    ],
    [
        58.8734,
        -1.5520024190476192
    ],
    [
        58.8837,
        4.368234619047619
    ],
    [
        58.8941,
        3.3816187476190485
    ],
    [
        58.9045,
        0.9150790666666664
    ],
    [
        58.9148,
        1.7377104619047619
    ],
    [
        58.9252,
        -0.6122779476190474
    ],
    [
        58.9356,
        -4.840631690476189
    ],
    [
        58.9459,
        5.0336540238095235
    ],
    [
        58.9563,
        5.1980900000000005
    ],
    [
        58.9666,
        5.444743971428571
    ],
    [
        58.977,
        5.883781714285713
    ],
    [
        58.9874,
        12.052839919047619
    ],
    [
        58.9977,
        11.066224047619048
    ],
    [
        59.0081,
        11.99117642857143
    ],
    [
        59.0184,
        10.40486652857143
    ],
    [
        59.0288,
        10.493344233333334
    ],
    [
        59.0392,
        11.480501904761905
    ],
    [
        59.0495,
        13.126216190476192
    ],
    [
        59.0599,
        9.837496614285715
    ],
    [
        59.0703,
        12.03087932857143
    ],
    [
        59.0806,
        16.47065075714286
    ],
    [
        59.091,
        19.760273333333334
    ],
    [
        59.1013,
        14.827193966666666
    ],
    [
        59.1117,
        16.471553757142857
    ],
    [
        59.1221,
        18.115913547619044
    ],
    [
        59.1324,
        14.82538797142857
    ],
    [
        59.1428,
        18.937190438095236
    ],
    [
        59.1531,
        18.937577438095236
    ],
    [
        59.1635,
        11.533894895238097
    ],
    [
        59.1739,
        11.36945891904762
    ],
    [
        59.1842,
        14.000434580952382
    ],
    [
        59.1946,
        14.657636700000001
    ],
    [
        59.2049,
        18.36015521904762
    ],
    [
        59.2153,
        18.36015521904762
    ],
    [
        59.2257,
        15.583943576190475
    ],
    [
        59.236,
        16.38476814761904
    ],
    [
        59.2464,
        14.035295733333331
    ],
    [
        59.2568,
        16.50183541428571
    ],
    [
        59.2671,
        15.0210989047619
    ],
    [
        59.2775,
        16.25436874285714
    ],
    [
        59.2878,
        16.323222357142853
    ],
    [
        59.2982,
        11.390142990476189
    ],
    [
        59.3086,
        10.5670601
    ],
    [
        59.3189,
        15.500139466666667
    ],
    [
        59.3293,
        11.387885495238097
    ],
    [
        59.3397,
        8.099165914285713
    ],
    [
        59.35,
        10.895932052380951
    ],
    [
        59.3604,
        5.9628526904761925
    ],
    [
        59.3707,
        10.193528428571431
    ],
    [
        59.3811,
        17.597210971428574
    ],
    [
        59.3915,
        18.419390866666667
    ],
    [
        59.4018,
        17.76218874761905
    ],
    [
        59.4122,
        17.762188747619046
    ],
    [
        59.4225,
        12.826400385714285
    ],
    [
        59.4329,
        14.059670228571427
    ],
    [
        59.4433,
        14.882301623809523
    ],
    [
        59.4536,
        11.96730018095238
    ],
    [
        59.464,
        14.598275842857142
    ],
    [
        59.4743,
        12.953916057142857
    ],
    [
        59.4847,
        12.78893828095238
    ],
    [
        59.4951,
        13.4740881952381
    ],
    [
        59.5054,
        15.325008833333337
    ],
    [
        59.5158,
        15.818587671428574
    ],
    [
        59.5262,
        12.529868095238095
    ],
    [
        59.5365,
        8.301514352380954
    ],
    [
        59.5469,
        9.12414574761905
    ],
    [
        59.5572,
        15.701584904761907
    ],
    [
        59.5676,
        15.920832876190477
    ],
    [
        59.578,
        20.855266738095242
    ],
    [
        59.5883,
        14.686208533333337
    ],
    [
        59.5987,
        10.573051566666669
    ],
    [
        59.609,
        7.9420759047619045
    ],
    [
        59.6194,
        7.9420759047619045
    ],
    [
        59.6298,
        7.530985957142858
    ],
    [
        59.6401,
        6.708806061904762
    ],
    [
        59.6505,
        6.298167614285714
    ],
    [
        59.6609,
        8.765610295238098
    ],
    [
        59.6712,
        9.587790185714287
    ],
    [
        59.6816,
        9.049882347619048
    ],
    [
        59.6919,
        6.994432609523811
    ],
    [
        59.7023,
        6.9944326095238125
    ],
    [
        59.7127,
        12.475631904761908
    ],
    [
        59.723,
        14.940817090476193
    ],
    [
        59.7334,
        16.913777933333332
    ],
    [
        59.7437,
        21.848211795238097
    ],
    [
        59.7541,
        16.20769780952381
    ],
    [
        59.7645,
        19.49732038571429
    ],
    [
        59.7748,
        13.577625147619049
    ],
    [
        59.7852,
        14.262775061904764
    ],
    [
        59.7956,
        12.411854423809524
    ],
    [
        59.8059,
        18.58091262857143
    ],
    [
        59.8163,
        16.52501139047619
    ],
    [
        59.8266,
        18.498243133333336
    ],
    [
        59.837,
        17.959547223809526
    ],
    [
        59.8474,
        11.793198014285716
    ],
    [
        59.8577,
        13.602535580952381
    ],
    [
        59.8681,
        10.312010004761907
    ],
    [
        59.8784,
        11.1341899
    ],
    [
        59.8888,
        15.245089371428573
    ],
    [
        59.8992,
        19.72946070476191
    ],
    [
        59.9095,
        20.140550652380952
    ],
    [
        59.9199,
        19.698046785714286
    ],
    [
        59.9302,
        13.531697576190476
    ],
    [
        59.9406,
        9.585234080952379
    ],
    [
        59.951,
        7.1186944
    ],
    [
        59.9613,
        4.6508002190476185
    ],
    [
        59.9717,
        10.079896519047619
    ],
    [
        59.9821,
        9.669483819047619
    ],
    [
        59.9924,
        15.589179057142857
    ],
    [
        60.0028,
        19.291697576190476
    ],
    [
        60.0131,
        18.674724033333334
    ],
    [
        60.0235,
        14.97288276190476
    ],
    [
        60.0339,
        15.219536733333333
    ],
    [
        60.0442,
        14.849555780952379
    ],
    [
        60.0546,
        12.75583123333333
    ],
    [
        60.0649,
        18.393636223809523
    ],
    [
        60.0753,
        15.762118761904762
    ],
    [
        60.0857,
        18.64110289047619
    ],
    [
        60.096,
        14.528848919047618
    ],
    [
        60.1064,
        16.9980976
    ],
    [
        60.1168,
        11.078402361904764
    ],
    [
        60.1271,
        12.887198128571427
    ],
    [
        60.1375,
        14.974061780952379
    ],
    [
        60.1478,
        17.440601466666667
    ],
    [
        60.1582,
        17.441143266666664
    ],
    [
        60.1686,
        17.441143266666664
    ],
    [
        60.1789,
        18.674413109523805
    ],
    [
        60.1893,
        23.114184538095238
    ],
    [
        60.1996,
        21.880237447619045
    ],
    [
        60.21,
        19.415052261904766
    ],
    [
        60.2204,
        16.947158080952384
    ],
    [
        60.2307,
        18.59242086666667
    ],
    [
        60.2411,
        15.714113985714292
    ],
    [
        60.2514,
        17.317364776190477
    ],
    [
        60.2618,
        15.878549961904763
    ],
    [
        60.2722,
        18.228538371428574
    ],
    [
        60.2825,
        15.055918566666668
    ],
    [
        60.2929,
        16.700278357142857
    ],
    [
        60.3033,
        16.73765017142857
    ],
    [
        60.3136,
        17.561184561904764
    ],
    [
        60.324,
        17.561184561904764
    ],
    [
        60.3343,
        19.25252605714286
    ],
    [
        60.3447,
        20.733804366666668
    ],
    [
        60.3551,
        20.733804366666668
    ],
    [
        60.3654,
        21.144894314285715
    ],
    [
        60.3758,
        15.221948280952384
    ],
    [
        60.3862,
        16.866308071428573
    ],
    [
        60.3965,
        16.619654100000005
    ],
    [
        60.4069,
        12.673732404761907
    ],
    [
        60.4172,
        10.207192723809525
    ],
    [
        60.4276,
        8.972568385714286
    ],
    [
        60.438,
        4.859411419047619
    ],
    [
        60.4483,
        4.03678002857143
    ],
    [
        60.4587,
        6.385865442857143
    ],
    [
        60.469,
        4.782072852380953
    ],
    [
        60.4794,
        5.604252747619047
    ],
    [
        60.4898,
        7.366066804761904
    ],
    [
        60.5001,
        10.327268923809523
    ],
    [
        60.5105,
        11.97253170952381
    ],
    [
        60.5208,
        10.537454076190478
    ],
    [
        60.5312,
        14.64835354761905
    ],
    [
        60.5416,
        11.356924976190477
    ],
    [
        60.5519,
        11.26883427142857
    ],
    [
        60.5623,
        11.26883427142857
    ],
    [
        60.5727,
        7.569024747619047
    ],
    [
        60.583,
        7.569927742857144
    ],
    [
        60.5934,
        12.505716104761902
    ],
    [
        60.6037,
        12.094626157142855
    ],
    [
        60.6141,
        10.285830390476189
    ],
    [
        60.6245,
        9.915307638095237
    ],
    [
        60.6348,
        17.317635680952378
    ],
    [
        60.6452,
        18.55090551904762
    ],
    [
        60.6555,
        21.84143109047619
    ],
    [
        60.6659,
        16.08572032857143
    ],
    [
        60.6763,
        12.914455023809523
    ],
    [
        60.6866,
        14.254169004761902
    ],
    [
        60.697,
        13.26701133333333
    ],
    [
        60.7074,
        12.444831442857145
    ],
    [
        60.7177,
        12.938139376190477
    ],
    [
        60.7281,
        16.22595595714286
    ],
    [
        60.7384,
        18.199729500000004
    ],
    [
        60.7488,
        14.746573947619048
    ],
    [
        60.7592,
        18.035293523809525
    ],
    [
        60.7695,
        18.240838500000002
    ],
    [
        60.7799,
        16.478056942857148
    ],
    [
        60.7902,
        17.711326785714288
    ],
    [
        60.8006,
        17.711326785714288
    ],
    [
        60.811,
        18.416052409523815
    ],
    [
        60.8213,
        16.196166695238098
    ],
    [
        60.8317,
        17.511925428571434
    ],
    [
        60.8421,
        16.073110614285717
    ],
    [
        60.8524,
        11.630630190476193
    ],
    [
        60.8628,
        8.547116966666666
    ],
    [
        60.8731,
        8.547116966666668
    ],
    [
        60.8835,
        13.891286280952382
    ],
    [
        60.8939,
        15.535646066666667
    ],
    [
        60.9042,
        16.005269652380953
    ],
    [
        60.9146,
        16.16979592857143
    ],
    [
        60.9249,
        16.169795928571425
    ],
    [
        60.9353,
        15.88829268095238
    ],
    [
        60.9457,
        11.659938938095237
    ],
    [
        60.956,
        10.672781266666666
    ],
    [
        60.9664,
        6.726317771428572
    ],
    [
        60.9768,
        3.437598195238093
    ],
    [
        60.9871,
        4.260681085714284
    ],
    [
        60.9975,
        2.8508428380952373
    ],
    [
        61.0078,
        4.08411268095238
    ],
    [
        61.0182,
        3.143575519047618
    ],
    [
        61.0286,
        3.4254657666666657
    ],
    [
        61.0389,
        3.424923966666666
    ],
    [
        61.0493,
        3.918502799999999
    ],
    [
        61.0596,
        7.207222376190476
    ],
    [
        61.07,
        5.480644595238094
    ],
    [
        61.0804,
        6.646039066666666
    ],
    [
        61.0907,
        5.987392152380951
    ],
    [
        61.1011,
        10.512090566666668
    ],
    [
        61.1114,
        13.800810142857141
    ],
    [
        61.1218,
        12.978630247619048
    ],
    [
        61.1322,
        14.787877514285713
    ],
    [
        61.1425,
        11.91024788095238
    ],
    [
        61.1529,
        12.439082347619046
    ],
    [
        61.1633,
        12.351330266666666
    ],
    [
        61.1736,
        9.884790585714287
    ],
    [
        61.184,
        17.284409633333333
    ],
    [
        61.1943,
        18.106589523809525
    ],
    [
        61.2047,
        17.448484409523807
    ],
    [
        61.2151,
        13.220130666666668
    ],
    [
        61.2254,
        12.974018495238093
    ],
    [
        61.2358,
        9.80275319047619
    ],
    [
        61.2461,
        10.049407161904762
    ],
    [
        61.2565,
        15.969644200000001
    ],
    [
        61.2669,
        15.59912144761905
    ],
    [
        61.2772,
        15.600475947619051
    ],
    [
        61.2876,
        14.365851609523807
    ],
    [
        61.2979,
        13.81743068095238
    ],
    [
        61.3083,
        7.897735442857143
    ],
    [
        61.3187,
        5.42848676190476
    ],
    [
        61.329,
        4.6072098666666665
    ],
    [
        61.3394,
        5.84047970952381
    ],
    [
        61.3498,
        6.498223628571429
    ],
    [
        61.3601,
        10.198033152380951
    ],
    [
        61.3705,
        8.964086061904762
    ],
    [
        61.3808,
        3.4113241571428574
    ],
    [
        61.3912,
        6.326325599999997
    ],
    [
        61.4016,
        3.8597859190476194
    ],
    [
        61.4119,
        3.0376060285714277
    ],
    [
        61.4223,
        6.983527723809524
    ],
    [
        61.4327,
        6.983527723809524
    ],
    [
        61.443,
        4.351107266666667
    ],
    [
        61.4534,
        7.310954885714286
    ],
    [
        61.4637,
        4.844415199999999
    ],
    [
        61.4741,
        4.8444151999999985
    ],
    [
        61.4845,
        4.676242042857142
    ],
    [
        61.4948,
        0.9750780190476185
    ],
    [
        61.5052,
        5.912220876190475
    ],
    [
        61.5155,
        6.73485227142857
    ],
    [
        61.5259,
        6.73485227142857
    ],
    [
        61.5363,
        5.254928461904761
    ],
    [
        61.5466,
        -1.3234136904761915
    ],
    [
        61.557,
        -0.0887893523809536
    ],
    [
        61.5673,
        -0.9109692476190484
    ],
    [
        61.5777,
        -1.7331491380952404
    ],
    [
        61.5881,
        -0.9109692428571453
    ],
    [
        61.5984,
        2.3804593285714297
    ],
    [
        61.6088,
        3.5772603523809514
    ],
    [
        61.6192,
        4.193895271428571
    ],
    [
        61.6295,
        4.194798266666665
    ],
    [
        61.6399,
        -1.7248969714285731
    ],
    [
        61.6502,
        0.7402882142857127
    ],
    [
        61.6606,
        3.207730895238095
    ],
    [
        61.671,
        5.180962638095237
    ],
    [
        61.6813,
        11.350020842857143
    ],
    [
        61.6917,
        1.4838621095238071
    ],
    [
        61.702,
        1.6520352666666647
    ],
    [
        61.7124,
        0.4187654285714276
    ],
    [
        61.7228,
        -0.4052204619047635
    ],
    [
        61.7331,
        -1.227851857142858
    ],
    [
        61.7435,
        4.2536484380952375
    ],
    [
        61.7539,
        1.6217697809523803
    ],
    [
        61.7642,
        7.048157085714285
    ],
    [
        61.7746,
        3.7580830095238116
    ],
    [
        61.7849,
        3.4292110523809525
    ],
    [
        61.7953,
        0.139588476190477
    ],
    [
        61.8057,
        0.5506784238095237
    ],
    [
        61.816,
        3.1816540857142854
    ],
    [
        61.8264,
        1.5363913000000002
    ],
    [
        61.8367,
        0.9197563809523809
    ],
    [
        61.8471,
        4.207572961904764
    ],
    [
        61.8575,
        5.687767671428572
    ],
    [
        61.8678,
        13.091450214285718
    ],
    [
        61.8782,
        11.447090423809525
    ],
    [
        61.8886,
        9.473858680952384
    ],
    [
        61.8989,
        4.538070319047621
    ],
    [
        61.9093,
        11.93768936666667
    ],
    [
        61.9196,
        16.25684280952381
    ],
    [
        61.93,
        20.36774228095238
    ],
    [
        61.9404,
        16.254585314285713
    ],
    [
        61.9507,
        16.95931093809524
    ],
    [
        61.9611,
        14.766530219047622
    ],
    [
        61.9714,
        17.645062847619048
    ],
    [
        61.9818,
        19.61964909047619
    ],
    [
        61.9922,
        21.264460380952386
    ],
    [
        62.0025,
        18.304612761904764
    ],
    [
        62.0129,
        19.94987554761905
    ],
    [
        62.0232,
        20.361868490476194
    ],
    [
        62.0336,
        20.361868490476194
    ],
    [
        62.044,
        19.539688595238097
    ],
    [
        62.0543,
        20.362319990476195
    ],
    [
        62.0647,
        17.895780304761907
    ],
    [
        62.0751,
        17.895509404761906
    ],
    [
        62.0854,
        8.026641676190476
    ],
    [
        62.0958,
        8.84882157142857
    ],
    [
        62.1061,
        9.342671304761904
    ],
    [
        62.1165,
        9.342671304761904
    ],
    [
        62.1269,
        8.520491414285715
    ],
    [
        62.1372,
        4.817972895238095
    ],
    [
        62.1476,
        4.407560195238096
    ],
    [
        62.1579,
        5.888296704761906
    ],
    [
        62.1683,
        6.170186952380954
    ],
    [
        62.1787,
        9.458906533333336
    ],
    [
        62.189,
        13.15871605714286
    ],
    [
        62.1994,
        13.157361557142858
    ],
    [
        62.2098,
        13.15736155714286
    ],
    [
        62.2201,
        20.559689600000002
    ],
    [
        62.2305,
        22.204049390476193
    ],
    [
        62.2408,
        19.572170733333333
    ],
    [
        62.2512,
        19.078862799999996
    ],
    [
        62.2616,
        19.901042695238097
    ],
    [
        62.2719,
        21.54495098095238
    ],
    [
        62.2823,
        20.312358390476188
    ],
    [
        62.2926,
        18.832434580952377
    ],
    [
        62.303,
        24.313633876190476
    ],
    [
        62.3134,
        24.313633876190476
    ],
    [
        62.3237,
        23.326476204761903
    ],
    [
        62.3341,
        23.87489713333333
    ],
    [
        62.3445,
        21.24337967142857
    ],
    [
        62.3548,
        20.83228972380952
    ],
    [
        62.3652,
        18.98136909047619
    ],
    [
        62.3755,
        21.61288655238095
    ],
    [
        62.3859,
        21.330996304761904
    ],
    [
        62.3963,
        18.041373728571426
    ],
    [
        62.4066,
        14.341564204761905
    ],
    [
        62.417,
        12.697204419047617
    ],
    [
        62.4273,
        12.57968564761905
    ],
    [
        62.4377,
        12.576976652380953
    ],
    [
        62.4481,
        12.576976652380953
    ],
    [
        62.4584,
        14.797539614285716
    ],
    [
        62.4688,
        11.837691995238096
    ],
    [
        62.4791,
        10.192429209523809
    ],
    [
        62.4895,
        12.658968895238095
    ],
    [
        62.4999,
        12.129747428571429
    ],
    [
        62.5102,
        13.116363300000003
    ],
    [
        62.5206,
        13.390423266666671
    ],
    [
        62.531,
        15.856962947619051
    ],
    [
        62.5413,
        15.856962947619051
    ],
    [
        62.5517,
        17.7750817
    ],
    [
        62.562,
        19.996186461904763
    ],
    [
        62.5724,
        18.351375171428568
    ],
    [
        62.5828,
        19.79098010952381
    ],
    [
        62.5931,
        23.079699685714285
    ],
    [
        62.6035,
        24.841513742857142
    ],
    [
        62.6139,
        26.486776528571426
    ],
    [
        62.6242,
        26.24012255714286
    ],
    [
        62.6346,
        27.88583684285714
    ],
    [
        62.6449,
        29.647263899999995
    ],
    [
        62.6553,
        24.714184533333338
    ],
    [
        62.6657,
        25.536364423809523
    ],
    [
        62.676,
        25.94677712380953
    ],
    [
        62.6864,
        29.400474476190478
    ],
    [
        62.6967,
        33.51363144285714
    ],
    [
        62.7071,
        26.1140123952381
    ],
    [
        62.7175,
        26.23146666666667
    ],
    [
        62.7278,
        21.2983873
    ],
    [
        62.7382,
        22.120567195238095
    ],
    [
        62.7485,
        20.35914013809524
    ],
    [
        62.7589,
        22.00349992857143
    ],
    [
        62.7693,
        18.30369040476191
    ],
    [
        62.7796,
        19.5376374952381
    ],
    [
        62.79,
        20.976903809523815
    ],
    [
        62.8004,
        15.218484052380957
    ],
    [
        62.8107,
        7.817510504761906
    ],
    [
        62.8211,
        6.337586695238092
    ],
    [
        62.8314,
        7.981946485714282
    ],
    [
        62.8418,
        11.92840998095238
    ],
    [
        62.8522,
        14.39630416190476
    ],
    [
        62.8625,
        16.862843847619047
    ],
    [
        62.8729,
        17.685475242857144
    ],
    [
        62.8832,
        15.923661185714288
    ],
    [
        62.8936,
        13.899833752380955
    ],
    [
        62.904,
        13.617401704761908
    ],
    [
        62.9143,
        11.598215557142858
    ],
    [
        62.9247,
        13.242575342857142
    ],
    [
        62.9351,
        7.484607085714288
    ],
    [
        62.9454,
        13.404302323809526
    ],
    [
        62.9558,
        14.226482214285717
    ],
    [
        62.9661,
        14.138343133333334
    ],
    [
        62.9765,
        13.480599214285716
    ],
    [
        62.9869,
        17.180408738095238
    ],
    [
        62.9972,
        13.06725177142857
    ],
    [
        63.0076,
        12.45061685238095
    ],
    [
        63.0179,
        19.030765004761907
    ],
    [
        63.0283,
        23.143921971428572
    ],
    [
        63.0387,
        24.623845780952383
    ],
    [
        63.049,
        23.96664366190476
    ],
    [
        63.0594,
        19.033564295238097
    ],
    [
        63.0698,
        9.164696566666667
    ],
    [
        63.0801,
        4.848252119047618
    ],
    [
        63.0905,
        4.025620723809523
    ],
    [
        63.1008,
        4.554164942857142
    ],
    [
        63.1112,
        5.755812480952379
    ],
    [
        63.1216,
        4.22890696190476
    ],
    [
        63.1319,
        5.01346877142857
    ],
    [
        63.1423,
        5.835648666666667
    ],
    [
        63.1526,
        11.476549652380951
    ],
    [
        63.163,
        9.50277610952381
    ],
    [
        63.1734,
        11.969315795238098
    ],
    [
        63.1837,
        12.174522147619049
    ],
    [
        63.1941,
        9.543546485714288
    ],
    [
        63.2044,
        8.064164476190475
    ],
    [
        63.2148,
        12.177321442857144
    ],
    [
        63.2252,
        12.177321442857144
    ],
    [
        63.2355,
        12.999501333333333
    ],
    [
        63.2459,
        12.58750839047619
    ],
    [
        63.2563,
        10.12096870952381
    ],
    [
        63.2666,
        9.838536661904763
    ],
    [
        63.277,
        12.306430842857145
    ],
    [
        63.2873,
        16.25289433809524
    ],
    [
        63.2977,
        16.869529261904763
    ],
    [
        63.3081,
        17.57464188571429
    ],
    [
        63.3184,
        17.574641885714286
    ],
    [
        63.3288,
        17.98640908095238
    ],
    [
        63.3391,
        17.98640908095238
    ],
    [
        63.3495,
        17.986409080952377
    ],
    [
        63.3599,
        20.452948761904757
    ],
    [
        63.3702,
        19.747836138095234
    ],
    [
        63.3806,
        22.379353599999995
    ],
    [
        63.391,
        16.21232714285714
    ],
    [
        63.4013,
        15.883726085714287
    ],
    [
        63.4117,
        17.528085876190474
    ],
    [
        63.422,
        17.527544076190477
    ],
    [
        63.4324,
        16.293596985714288
    ],
    [
        63.4428,
        15.306439314285715
    ],
    [
        63.4531,
        13.456534552380953
    ],
    [
        63.4635,
        7.290185342857141
    ],
    [
        63.4738,
        8.934545133333332
    ],
    [
        63.4842,
        6.585459719047621
    ],
    [
        63.4946,
        4.610873476190478
    ],
    [
        63.5049,
        10.533277709523812
    ],
    [
        63.5153,
        9.300007866666668
    ],
    [
        63.5256,
        8.594895242857145
    ],
    [
        63.536,
        8.183805295238097
    ],
    [
        63.5464,
        11.88293757142857
    ],
    [
        63.5567,
        13.93838730952381
    ],
    [
        63.5671,
        11.88248607142857
    ],
    [
        63.5775,
        10.120672014285713
    ],
    [
        63.5878,
        5.187592647619046
    ],
    [
        63.5982,
        4.776502699999999
    ],
    [
        63.6085,
        6.8330811857142875
    ],
    [
        63.6189,
        5.352886476190476
    ],
    [
        63.6293,
        6.9981492619047625
    ],
    [
        63.6396,
        8.47807307142857
    ],
    [
        63.65,
        7.949238604761904
    ],
    [
        63.6603,
        8.936396276190475
    ],
    [
        63.6707,
        9.30637722857143
    ],
    [
        63.6811,
        15.226614266666667
    ],
    [
        63.6914,
        12.76007458095238
    ],
    [
        63.7018,
        14.898013209523807
    ],
    [
        63.7122,
        19.33778463809524
    ],
    [
        63.7225,
        9.46891690952381
    ],
    [
        63.7329,
        11.113276699999998
    ],
    [
        63.7432,
        11.935908095238094
    ],
    [
        63.7536,
        6.17793983809524
    ],
    [
        63.764,
        2.2314763428571407
    ],
    [
        63.7743,
        0.2934808761904743
    ],
    [
        63.7847,
        1.664232199999999
    ],
    [
        63.795,
        1.9461224476190466
    ],
    [
        63.8054,
        1.9461224476190466
    ],
    [
        63.8158,
        -1.7550415761904763
    ],
    [
        63.8261,
        -1.5910571000000011
    ],
    [
        63.8365,
        -3.07098090952381
    ],
    [
        63.8469,
        -5.949513538095239
    ],
    [
        63.8572,
        -7.967098914285714
    ],
    [
        63.8676,
        -7.0278517476190485
    ],
    [
        63.8779,
        -8.672211538095238
    ],
    [
        63.8883,
        -8.836196014285715
    ],
    [
        63.8987,
        -9.823353685714286
    ],
    [
        63.909,
        -7.356813999999999
    ],
    [
        63.9194,
        -7.028212942857144
    ],
    [
        63.9297,
        -11.344657390476192
    ],
    [
        63.9401,
        -6.904885961904763
    ],
    [
        63.9505,
        -7.5626298809523815
    ],
    [
        63.9608,
        -8.385261276190475
    ],
    [
        63.9712,
        -2.83249937142857
    ],
    [
        63.9816,
        -5.4634750333333315
    ],
    [
        63.9919,
        0.17703895238095277
    ],
    [
        64.0023,
        0.8621888666666677
    ],
    [
        64.0126,
        1.1088428380952378
    ],
    [
        64.023,
        7.275192047619049
    ],
    [
        64.0334,
        11.388349014285714
    ],
    [
        64.0437,
        9.69700751904762
    ],
    [
        64.0541,
        12.780182119047621
    ],
    [
        64.0644,
        18.949240323809523
    ],
    [
        64.0748,
        19.205011642857144
    ],
    [
        64.0852,
        12.624863490476192
    ],
    [
        64.0955,
        13.282607409523811
    ],
    [
        64.1059,
        13.165088638095241
    ],
    [
        64.1162,
        10.698548957142862
    ],
    [
        64.1266,
        9.602610095238099
    ],
    [
        64.137,
        7.136070409523812
    ],
    [
        64.1473,
        8.985975171428574
    ],
    [
        64.1577,
        11.123642900000002
    ],
    [
        64.1681,
        10.959206923809527
    ],
    [
        64.1784,
        13.425746604761907
    ],
    [
        64.1888,
        17.74185242857143
    ],
    [
        64.1991,
        21.032378004761906
    ],
    [
        64.2095,
        21.029669009523808
    ],
    [
        64.2199,
        22.262938847619047
    ],
    [
        64.2302,
        23.497563185714288
    ],
    [
        64.2406,
        19.797753661904764
    ],
    [
        64.2509,
        18.645798814285715
    ],
    [
        64.2613,
        16.883984757142862
    ],
    [
        64.2717,
        16.26734983809524
    ],
    [
        64.282,
        11.948535019047618
    ],
    [
        64.2924,
        12.888169185714286
    ],
    [
        64.3028,
        16.590687704761905
    ],
    [
        64.3131,
        20.046552252380952
    ],
    [
        64.3235,
        20.98579941904762
    ],
    [
        64.3338,
        25.918878780952383
    ],
    [
        64.3442,
        25.819165885714288
    ],
    [
        64.3546,
        26.970217738095236
    ],
    [
        64.3649,
        25.051797985714284
    ],
    [
        64.3753,
        25.87397787619048
    ],
    [
        64.3856,
        25.87397787619048
    ],
    [
        64.396,
        18.47435882857143
    ],
    [
        64.4064,
        14.246392085714287
    ],
    [
        64.4167,
        12.600226300000001
    ],
    [
        64.4271,
        6.433877090476187
    ],
    [
        64.4375,
        2.7333903190476163
    ],
    [
        64.4478,
        0.594368095238094
    ],
    [
        64.4582,
        5.5288019571428535
    ],
    [
        64.4685,
        5.7402196428571415
    ],
    [
        64.4789,
        6.97281223333333
    ],
    [
        64.4893,
        7.677537857142854
    ],
    [
        64.4996,
        6.566985476190472
    ],
    [
        64.51,
        5.539260604761903
    ],
    [
        64.5203,
        7.320650376190473
    ],
    [
        64.5307,
        3.6181318571428553
    ],
    [
        64.5411,
        4.440311747619047
    ],
    [
        64.5514,
        6.912269419047618
    ],
    [
        64.5618,
        7.450177257142856
    ],
    [
        64.5721,
        12.876564561904761
    ],
    [
        64.5825,
        13.973707419047619
    ],
    [
        64.5929,
        12.740437580952378
    ],
    [
        64.6032,
        13.562617476190477
    ],
    [
        64.6136,
        17.673516947619046
    ],
    [
        64.624,
        17.95502019523809
    ],
    [
        64.6343,
        18.44832813333333
    ],
    [
        64.6447,
        18.694982104761902
    ],
    [
        64.655,
        14.990431842857138
    ],
    [
        64.6654,
        15.36628550952381
    ],
    [
        64.6758,
        10.431851647619045
    ],
    [
        64.6861,
        10.34376094285714
    ],
    [
        64.6965,
        11.577708033333334
    ],
    [
        64.7069,
        12.5173422
    ],
    [
        64.7172,
        15.47881521904762
    ],
    [
        64.7276,
        19.795259666666666
    ],
    [
        64.7379,
        17.602779947619045
    ],
    [
        64.7483,
        20.480409580952383
    ],
    [
        64.7587,
        19.247139742857136
    ],
    [
        64.769,
        15.954808176190475
    ],
    [
        64.7794,
        16.201462147619047
    ],
    [
        64.7897,
        12.254998652380953
    ],
    [
        64.8001,
        9.623119995238095
    ],
    [
        64.8105,
        8.389850152380953
    ],
    [
        64.8208,
        7.732106233333334
    ],
    [
        64.8312,
        9.787555971428572
    ],
    [
        64.8415,
        6.33440041904762
    ],
    [
        64.8519,
        6.663272376190478
    ],
    [
        64.8623,
        8.266861790476193
    ],
    [
        64.8726,
        12.218743271428572
    ],
    [
        64.883,
        13.980557328571427
    ],
    [
        64.8934,
        14.801834223809525
    ],
    [
        64.9037,
        14.890311928571432
    ],
    [
        64.9141,
        12.42377224761905
    ],
    [
        64.9244,
        11.396047376190475
    ],
    [
        64.9348,
        3.992364833333334
    ],
    [
        64.9452,
        -0.11853463809523745
    ],
    [
        64.9555,
        -3.40996320952381
    ],
    [
        64.9659,
        -4.349597376190476
    ],
    [
        64.9762,
        -6.199502138095239
    ],
    [
        64.9866,
        -7.297547995238098
    ],
    [
        64.997,
        -6.886458047619049
    ],
    [
        65.0073,
        -7.873073919047621
    ],
    [
        65.0177,
        -6.393150109523811
    ],
    [
        65.0281,
        -3.92661042857143
    ],
    [
        65.0384,
        -5.899842171428573
    ],
    [
        65.0488,
        -5.899164923809525
    ],
    [
        65.0591,
        1.5004541238095213
    ],
    [
        65.0695,
        -0.14390566666666887
    ],
    [
        65.0799,
        0.06130068571428299
    ],
    [
        65.0902,
        3.514456238095236
    ],
    [
        65.1006,
        1.5960364857142844
    ],
    [
        65.1109,
        8.174378638095236
    ],
    [
        65.1213,
        6.764540390476188
    ],
    [
        65.1317,
        9.724929804761905
    ],
    [
        65.142,
        14.044083247619048
    ],
    [
        65.1524,
        21.446411290476185
    ],
    [
        65.1628,
        23.092125576190476
    ],
    [
        65.1731,
        29.261183780952383
    ],
    [
        65.1835,
        33.48953752380952
    ],
    [
        65.1938,
        31.63963276190476
    ],
    [
        65.2042,
        31.091512833333333
    ],
    [
        65.2145,
        34.380232409523806
    ],
    [
        65.2249,
        34.99686732857143
    ],
    [
        65.2353,
        32.85829660476191
    ],
    [
        65.2456,
        30.885335761904766
    ],
    [
        65.256,
        29.405411952380963
    ],
    [
        65.2664,
        28.788099780952386
    ],
    [
        65.2767,
        22.621073323809526
    ],
    [
        65.2871,
        25.909792900000006
    ],
    [
        65.2974,
        25.792338628571436
    ],
    [
        65.3078,
        18.390010585714286
    ],
    [
        65.3182,
        18.664070552380952
    ],
    [
        65.3285,
        8.797911819047618
    ],
    [
        65.3389,
        10.324817338095238
    ],
    [
        65.3493,
        4.895179242857142
    ],
    [
        65.3596,
        -1.6858719047619064
    ],
    [
        65.37,
        -2.5080517952380936
    ],
    [
        65.3803,
        -4.2708333523809525
    ],
    [
        65.3907,
        -10.437182561904761
    ],
    [
        65.4011,
        -15.370261928571429
    ],
    [
        65.4114,
        -12.198996623809524
    ],
    [
        65.4218,
        -9.732456942857144
    ],
    [
        65.4321,
        -15.652152180952381
    ],
    [
        65.4425,
        -15.282171228571428
    ],
    [
        65.4529,
        -12.81472854761905
    ],
    [
        65.4632,
        -12.60358176190476
    ],
    [
        65.4736,
        -10.960124971428574
    ],
    [
        65.484,
        -10.590144019047623
    ],
    [
        65.4943,
        -8.369581057142861
    ],
    [
        65.5047,
        -13.302660419047621
    ],
    [
        65.515,
        -13.184754647619044
    ],
    [
        65.5254,
        -3.315886919047622
    ],
    [
        65.5358,
        -3.6447588761904774
    ],
    [
        65.5461,
        0.055050647619045566
    ],
    [
        65.5565,
        -2.000399090476192
    ],
    [
        65.5668,
        4.169336361904762
    ],
    [
        65.5772,
        7.458958938095239
    ],
    [
        65.5876,
        7.047868990476189
    ],
    [
        65.5979,
        6.342756366666667
    ],
    [
        65.6083,
        10.04256589047619
    ],
    [
        65.6187,
        10.864745785714286
    ],
    [
        65.629,
        11.804379952380952
    ],
    [
        65.6394,
        10.571787361904763
    ],
    [
        65.6497,
        9.091863552380953
    ],
    [
        65.6601,
        8.809973304761906
    ],
    [
        65.6705,
        6.754523566666666
    ],
    [
        65.6808,
        6.543376780952381
    ],
    [
        65.6912,
        6.133189828571427
    ],
    [
        65.7015,
        5.639881895238094
    ],
    [
        65.7119,
        2.185371842857142
    ],
    [
        65.7223,
        9.584990890476192
    ],
    [
        65.7326,
        11.230253676190475
    ],
    [
        65.743,
        7.530444152380952
    ],
    [
        65.7533,
        11.972924576190476
    ],
    [
        65.7637,
        14.85055420952381
    ],
    [
        65.7741,
        21.01690341904762
    ],
    [
        65.7844,
        20.276535166666665
    ],
    [
        65.7948,
        17.398905533333334
    ],
    [
        65.8052,
        16.987815585714287
    ],
    [
        65.8155,
        14.521275904761906
    ],
    [
        65.8259,
        17.400711533333336
    ],
    [
        65.8362,
        15.591915766666668
    ],
    [
        65.8466,
        14.769735871428574
    ],
    [
        65.857,
        15.180148571428575
    ],
    [
        65.8673,
        15.180148571428575
    ],
    [
        65.8777,
        15.709370038095239
    ],
    [
        65.8881,
        15.956024009523812
    ],
    [
        65.8984,
        15.955753109523812
    ],
    [
        65.9088,
        12.255943585714286
    ],
    [
        65.9191,
        6.829556280952382
    ],
    [
        65.9295,
        10.002176085714286
    ],
    [
        65.9399,
        6.05571259047619
    ],
    [
        65.9502,
        2.6001189380952354
    ],
    [
        65.9606,
        3.833388776190479
    ],
    [
        65.9709,
        3.83067978095238
    ],
    [
        65.9813,
        2.8910456142857135
    ],
    [
        65.9917,
        -3.276658090476191
    ],
    [
        66.002,
        -3.321775166666666
    ],
    [
        66.0124,
        -7.021584690476191
    ],
    [
        66.0227,
        -12.776843952380954
    ],
    [
        66.0331,
        -9.077034428571428
    ],
    [
        66.0435,
        -9.078840423809526
    ],
    [
        66.0538,
        -5.624330371428574
    ],
    [
        66.0642,
        -5.459894395238096
    ],
    [
        66.0746,
        -8.338201276190478
    ],
    [
        66.0849,
        -5.0494817
    ],
    [
        66.0953,
        -4.639069000000004
    ],
    [
        66.1056,
        -3.652453128571432
    ],
    [
        66.116,
        -9.078840433333337
    ],
    [
        66.1264,
        -3.7660850380952398
    ],
    [
        66.1367,
        0.34481443333333367
    ],
    [
        66.1471,
        1.2844486000000004
    ],
    [
        66.1574,
        0.914467647619048
    ],
    [
        66.1678,
        1.197441495238095
    ],
    [
        66.1782,
        -0.6524632666666668
    ],
    [
        66.1885,
        -10.518622
    ],
    [
        66.1989,
        -10.235648152380953
    ],
    [
        66.2093,
        -7.355761028571429
    ],
    [
        66.2196,
        -12.737302157142857
    ],
    [
        66.23,
        -6.158960004761907
    ],
    [
        66.2403,
        -0.6092457190476199
    ],
    [
        66.2507,
        -0.6092457190476195
    ],
    [
        66.2611,
        -2.9583311333333335
    ],
    [
        66.2714,
        -5.426225314285714
    ],
    [
        66.2818,
        -6.033373542857144
    ],
    [
        66.2921,
        -5.621606347619046
    ],
    [
        66.3025,
        -8.910325923809523
    ],
    [
        66.3129,
        -9.566986242857142
    ],
    [
        66.3232,
        -4.633906876190475
    ],
    [
        66.3336,
        5.232251857142858
    ],
    [
        66.344,
        5.400996757142858
    ],
    [
        66.3543,
        1.2900972857142852
    ],
    [
        66.3647,
        2.1122771761904766
    ],
    [
        66.375,
        3.962181938095239
    ],
    [
        66.3854,
        9.59998692857143
    ],
    [
        66.3958,
        10.216621852380953
    ],
    [
        66.4061,
        15.971881114285715
    ],
    [
        66.4165,
        16.62944442857143
    ],
    [
        66.4268,
        13.749557304761906
    ],
    [
        66.4372,
        19.915906514285716
    ],
    [
        66.4476,
        13.337564361904763
    ],
    [
        66.4579,
        7.785141080952382
    ],
    [
        66.4683,
        7.0999911666666655
    ],
    [
        66.4786,
        6.571446947619047
    ],
    [
        66.489,
        7.188081866666667
    ],
    [
        66.4994,
        6.808614223809524
    ],
    [
        66.5097,
        8.042109814285713
    ],
    [
        66.5201,
        9.27537965238095
    ],
    [
        66.5305,
        7.582954557142856
    ],
    [
        66.5408,
        3.8831450333333324
    ],
    [
        66.5512,
        -0.5015134047619048
    ],
    [
        66.5615,
        -5.9857226952380955
    ],
    [
        66.5719,
        -5.985722695238097
    ],
    [
        66.5823,
        -8.003554342857143
    ],
    [
        66.5926,
        -11.018213952380952
    ],
    [
        66.603,
        -15.59893050952381
    ],
    [
        66.6133,
        -15.187840561904766
    ],
    [
        66.6237,
        -14.776750614285717
    ],
    [
        66.6341,
        -17.409171071428574
    ],
    [
        66.6444,
        -13.708007052380957
    ],
    [
        66.6548,
        -13.954661023809527
    ],
    [
        66.6652,
        -11.490830333333335
    ],
    [
        66.6755,
        -4.088502290476191
    ],
    [
        66.6859,
        -3.4033523761904756
    ],
    [
        66.6962,
        -1.1829248619047612
    ],
    [
        66.7066,
        -1.7995597809523807
    ],
    [
        66.717,
        0.6669799000000002
    ],
    [
        66.7273,
        1.242280076190475
    ],
    [
        66.7377,
        2.47554991904762
    ],
    [
        66.748,
        4.413545385714286
    ],
    [
        66.7584,
        3.180275542857143
    ],
    [
        66.7688,
        1.3985847714285715
    ],
    [
        66.7791,
        1.395875780952381
    ],
    [
        66.7895,
        6.822263085714287
    ],
    [
        66.7999,
        7.6068248952380975
    ],
    [
        66.8102,
        6.920997733333334
    ],
    [
        66.8206,
        7.061942861904762
    ],
    [
        66.8309,
        6.4041989428571435
    ],
    [
        66.8413,
        3.937659257142858
    ],
    [
        66.8517,
        3.279554142857143
    ],
    [
        66.862,
        1.1418864190476203
    ],
    [
        66.8724,
        -0.4613643714285703
    ],
    [
        66.8827,
        2.8305157000000003
    ],
    [
        66.8931,
        1.5979231095238102
    ],
    [
        66.9035,
        5.297732633333335
    ],
    [
        66.9138,
        3.3239590904761913
    ],
    [
        66.9242,
        3.703426733333334
    ],
    [
        66.9345,
        1.8538605952380958
    ],
    [
        66.9449,
        0.4561547761904774
    ],
    [
        66.9553,
        1.004575704761906
    ],
    [
        66.9656,
        0.5934857571428573
    ],
    [
        66.976,
        -1.8730539238095236
    ],
    [
        66.9864,
        1.8267556000000011
    ],
    [
        66.9967,
        11.69833231904762
    ],
    [
        67.0071,
        16.13810374761905
    ],
    [
        67.0174,
        15.892533376190478
    ],
    [
        67.0278,
        17.619788404761906
    ],
    [
        67.0382,
        15.482120676190478
    ],
    [
        67.0485,
        19.431293166666666
    ],
    [
        67.0589,
        25.597642376190475
    ],
    [
        67.0692,
        28.23006283333333
    ],
    [
        67.0796,
        25.434651195238093
    ],
    [
        67.09,
        30.984365480952377
    ],
    [
        67.1003,
        25.22865471904761
    ],
    [
        67.1107,
        24.611342547619046
    ],
    [
        67.1211,
        20.66487905238095
    ],
    [
        67.1314,
        20.00532913809524
    ],
    [
        67.1418,
        20.85913133809524
    ],
    [
        67.1521,
        21.228773666666665
    ],
    [
        67.1625,
        20.817683719047615
    ],
    [
        67.1729,
        21.50320988095238
    ],
    [
        67.1832,
        21.667645857142855
    ],
    [
        67.1936,
        24.134185538095235
    ],
    [
        67.2039,
        22.9009157
    ],
    [
        67.2143,
        20.43437601428571
    ],
    [
        67.2247,
        16.73456649047619
    ],
    [
        67.235,
        18.214761200000005
    ],
    [
        67.2454,
        22.65724162380953
    ],
    [
        67.2558,
        24.301601414285717
    ],
    [
        67.2661,
        19.365813052380954
    ],
    [
        67.2765,
        15.137846309523812
    ],
    [
        67.2868,
        19.086477000000002
    ],
    [
        67.2972,
        22.00147844285714
    ],
    [
        67.3076,
        15.835129233333337
    ],
    [
        67.3179,
        25.701287966666673
    ],
    [
        67.3283,
        27.55119272857143
    ],
    [
        67.3386,
        21.628788495238098
    ],
    [
        67.349,
        24.26157015238096
    ],
    [
        67.3594,
        21.79435321904762
    ],
    [
        67.3697,
        21.301045285714288
    ],
    [
        67.3801,
        21.41811255714286
    ],
    [
        67.3905,
        20.536528276190477
    ],
    [
        67.4008,
        17.083372723809525
    ],
    [
        67.4112,
        17.70034626666667
    ],
    [
        67.4215,
        21.40015579047619
    ],
    [
        67.4319,
        18.52252616190476
    ],
    [
        67.4423,
        17.73771808095238
    ],
    [
        67.4526,
        16.25643977142857
    ],
    [
        67.463,
        12.025377033333335
    ],
    [
        67.4733,
        13.258646876190477
    ],
    [
        67.4837,
        11.61248109047619
    ],
    [
        67.4941,
        12.141315557142857
    ],
    [
        67.5044,
        4.738987514285717
    ],
    [
        67.5148,
        5.277141623809525
    ],
    [
        67.5251,
        1.574623104761906
    ],
    [
        67.5355,
        -2.653730638095239
    ],
    [
        67.5459,
        -10.053349685714286
    ],
    [
        67.5562,
        -5.117561323809524
    ],
    [
        67.5666,
        -4.870907352380952
    ],
    [
        67.5769,
        -1.9926004714285714
    ],
    [
        67.5873,
        2.4525889428571417
    ],
    [
        67.5977,
        4.144317438095238
    ],
    [
        67.608,
        3.7919546285714283
    ],
    [
        67.6184,
        5.4363144142857145
    ],
    [
        67.6288,
        5.198808514285717
    ],
    [
        67.6391,
        -4.670059214285716
    ],
    [
        67.6495,
        -6.725508947619049
    ],
    [
        67.6598,
        -8.818330500000002
    ],
    [
        67.6702,
        -9.311638433333334
    ],
    [
        67.6806,
        -10.016364057142857
    ],
    [
        67.6909,
        -11.2496339
    ],
    [
        67.7013,
        -4.6703887476190475
    ],
    [
        67.7117,
        -3.435087161904762
    ],
    [
        67.722,
        -0.35191256190476333
    ],
    [
        67.7324,
        -6.271607800000003
    ],
    [
        67.7427,
        -0.6310938142857154
    ],
    [
        67.7531,
        3.599968923809523
    ],
    [
        67.7635,
        13.466127657142858
    ],
    [
        67.7738,
        11.821767866666667
    ],
    [
        67.7842,
        11.822445114285713
    ],
    [
        67.7945,
        10.882810947619047
    ],
    [
        67.8049,
        10.877392961904764
    ],
    [
        67.8153,
        12.358671271428571
    ],
    [
        67.8256,
        10.713408485714286
    ],
    [
        67.836,
        12.768858223809527
    ],
    [
        67.8463,
        13.376006452380953
    ],
    [
        67.8567,
        19.545064657142856
    ],
    [
        67.8671,
        21.024988466666667
    ],
    [
        67.8774,
        27.605136619047624
    ],
    [
        67.8878,
        27.816554304761905
    ],
    [
        67.8982,
        29.04982414761905
    ],
    [
        67.9085,
        26.58328446666667
    ],
    [
        67.9189,
        22.19862602857143
    ],
    [
        67.9292,
        22.19862602857143
    ],
    [
        67.9396,
        21.581991109523806
    ],
    [
        67.95,
        27.219796100000003
    ],
    [
        67.9603,
        27.50168634761905
    ],
    [
        67.9707,
        21.74371809047619
    ],
    [
        67.9811,
        18.454998514285716
    ],
    [
        67.9914,
        20.099358304761907
    ],
    [
        68.0018,
        19.852027085714287
    ],
    [
        68.0121,
        19.96948135714286
    ],
    [
        68.0225,
        12.569862309523812
    ],
    [
        68.0329,
        10.101968128571432
    ],
    [
        68.0432,
        11.747230914285716
    ],
    [
        68.0536,
        11.062081000000003
    ],
    [
        68.0639,
        8.840840790476191
    ],
    [
        68.0743,
        9.251930738095236
    ],
    [
        68.0847,
        12.70508629047619
    ],
    [
        68.095,
        8.262605866666666
    ],
    [
        68.1054,
        7.557880242857144
    ],
    [
        68.1157,
        6.324610399999999
    ],
    [
        68.1261,
        10.436412866666666
    ],
    [
        68.1365,
        14.821071304761904
    ],
    [
        68.1468,
        11.529642733333331
    ],
    [
        68.1572,
        12.234755357142856
    ],
    [
        68.1676,
        13.996569414285712
    ],
    [
        68.1779,
        14.65431333333333
    ],
    [
        68.1883,
        17.121756014285715
    ],
    [
        68.1986,
        14.655216328571427
    ],
    [
        68.209,
        15.272189871428571
    ],
    [
        68.2194,
        15.272189871428571
    ],
    [
        68.2297,
        19.385798338095235
    ],
    [
        68.2401,
        26.785417385714286
    ],
    [
        68.2504,
        27.607597280952383
    ],
    [
        68.2608,
        26.62098140952381
    ],
    [
        68.2712,
        31.005940847619048
    ],
    [
        68.2815,
        33.22718105714286
    ],
    [
        68.2919,
        33.22718105714286
    ],
    [
        68.3023,
        28.291392700000003
    ],
    [
        68.3126,
        28.414719680952388
    ],
    [
        68.323,
        29.64798952380953
    ],
    [
        68.3333,
        32.114529204761915
    ],
    [
        68.3437,
        32.9385150952381
    ],
    [
        68.3541,
        27.183255833333334
    ],
    [
        68.3644,
        28.710161352380954
    ],
    [
        68.3748,
        28.82722862380952
    ],
    [
        68.3851,
        23.89414926190476
    ],
    [
        68.3955,
        23.894149261904758
    ],
    [
        68.4059,
        21.54416085238095
    ],
    [
        68.4162,
        22.62626166190476
    ],
    [
        68.4266,
        22.009288119047614
    ],
    [
        68.4369,
        16.089592880952377
    ],
    [
        68.4473,
        11.15380451904762
    ],
    [
        68.4577,
        7.207882823809523
    ],
    [
        68.468,
        8.85224260952381
    ],
    [
        68.4784,
        11.072128323809526
    ],
    [
        68.4888,
        11.072128323809524
    ],
    [
        68.4991,
        11.318782295238098
    ],
    [
        68.5095,
        8.687264833333334
    ],
    [
        68.5198,
        12.38978335238095
    ],
    [
        68.5302,
        16.7089367952381
    ],
    [
        68.5406,
        16.09230187142857
    ],
    [
        68.5509,
        16.09230187142857
    ],
    [
        68.5613,
        15.268315980952385
    ],
    [
        68.5716,
        11.157416509523811
    ],
    [
        68.582,
        12.097953671428575
    ],
    [
        68.5924,
        12.097953671428574
    ],
    [
        68.6027,
        14.564493352380953
    ],
    [
        68.6131,
        11.686863719047622
    ],
    [
        68.6235,
        13.213769238095242
    ],
    [
        68.6338,
        14.18711816666667
    ],
    [
        68.6442,
        19.12290652857143
    ],
    [
        68.6545,
        23.2333545
    ],
    [
        68.6649,
        23.64444444761905
    ],
    [
        68.6753,
        17.724207409523814
    ],
    [
        68.6856,
        16.490937571428574
    ],
    [
        68.696,
        12.789773547619049
    ],
    [
        68.7063,
        9.089964023809523
    ],
    [
        68.7167,
        10.323233861904763
    ],
    [
        68.7271,
        8.843851852380952
    ],
    [
        68.7374,
        8.15870193809524
    ],
    [
        68.7478,
        -1.7101657904761924
    ],
    [
        68.7582,
        -1.0921763714285724
    ],
    [
        68.7685,
        -4.7919858952380965
    ],
    [
        68.7789,
        -5.732136057142859
    ],
    [
        68.7892,
        -0.7990566904761913
    ],
    [
        68.7996,
        -4.910859157142857
    ],
    [
        68.81,
        -5.11606550952381
    ],
    [
        68.8203,
        -5.11606550952381
    ],
    [
        68.8307,
        -2.2384358761904775
    ],
    [
        68.841,
        -5.527155452380953
    ],
    [
        68.8514,
        -5.116065504761906
    ],
    [
        68.8618,
        -11.285123709523809
    ],
    [
        68.8721,
        -10.154174852380955
    ],
    [
        68.8825,
        -11.798534642857144
    ],
    [
        68.8929,
        -5.219289490476192
    ],
    [
        68.9032,
        -5.63037943809524
    ],
    [
        68.9136,
        -1.5181254666666688
    ],
    [
        68.9239,
        2.1816840571428564
    ],
    [
        68.9343,
        0.5377757714285694
    ],
    [
        68.9447,
        1.7710456095238087
    ],
    [
        68.955,
        1.9276513047619026
    ],
    [
        68.9654,
        8.505993457142857
    ],
    [
        68.9757,
        6.038099276190477
    ],
    [
        68.9861,
        7.271369119047618
    ],
    [
        68.9965,
        7.799526338095236
    ],
    [
        69.0068,
        9.033473428571428
    ],
    [
        69.0172,
        12.733282952380954
    ],
    [
        69.0275,
        9.64976972857143
    ],
    [
        69.0379,
        4.713981366666668
    ],
    [
        69.0483,
        3.617741504761904
    ],
    [
        69.0586,
        11.020069547619048
    ],
    [
        69.069,
        11.020069547619048
    ],
    [
        69.0794,
        9.78679970952381
    ],
    [
        69.0897,
        10.026602176190476
    ],
    [
        69.1001,
        10.273256147619046
    ],
    [
        69.1104,
        9.243725280952383
    ],
    [
        69.1208,
        3.4884660190476198
    ],
    [
        69.1312,
        -3.094391123809522
    ],
    [
        69.1415,
        -7.322744866666667
    ],
    [
        69.1519,
        -13.07845562857143
    ],
    [
        69.1623,
        -10.858569914285715
    ],
    [
        69.1726,
        -9.095401357142858
    ],
    [
        69.183,
        -10.035938519047617
    ],
    [
        69.1933,
        -3.8695893095238088
    ],
    [
        69.2037,
        -4.85674698095238
    ],
    [
        69.214,
        -4.856746980952378
    ],
    [
        69.2244,
        -5.542574142857142
    ],
    [
        69.2348,
        -7.480569609523806
    ],
    [
        69.2451,
        -4.396717757142857
    ],
    [
        69.2555,
        -0.44754526666666633
    ],
    [
        69.2659,
        -0.17348529999999907
    ],
    [
        69.2762,
        -4.12265779047619
    ],
    [
        69.2866,
        -4.779859909523808
    ],
    [
        69.2969,
        2.6197591380952368
    ],
    [
        69.3073,
        2.0716392095238083
    ],
    [
        69.3177,
        7.991334447619047
    ],
    [
        69.328,
        9.84123920952381
    ],
    [
        69.3384,
        15.322739504761907
    ],
    [
        69.3488,
        17.79469717619048
    ],
    [
        69.3591,
        15.445611761904765
    ],
    [
        69.3695,
        21.201322523809523
    ],
    [
        69.3798,
        22.682600833333336
    ],
    [
        69.3902,
        21.03688654761905
    ],
    [
        69.4006,
        20.33216092380953
    ],
    [
        69.4109,
        20.334869919047623
    ],
    [
        69.4213,
        22.308643461904765
    ],
    [
        69.4316,
        21.07537361904762
    ],
    [
        69.442,
        21.07537361904762
    ],
    [
        69.4524,
        19.313559561904764
    ],
    [
        69.4627,
        17.051887600000004
    ],
    [
        69.4731,
        19.02511934285715
    ],
    [
        69.4835,
        13.264442095238099
    ],
    [
        69.4938,
        12.771134161904765
    ],
    [
        69.5042,
        12.60615638571429
    ],
    [
        69.5145,
        6.9683513952380975
    ],
    [
        69.5249,
        11.90413975714286
    ],
    [
        69.5353,
        7.957676261904764
    ],
    [
        69.5456,
        10.424215947619048
    ],
    [
        69.556,
        11.520154809523811
    ],
    [
        69.5663,
        16.453234171428573
    ],
    [
        69.5767,
        16.453234171428573
    ],
    [
        69.5871,
        17.274962566666666
    ],
    [
        69.5974,
        16.45142817619048
    ],
    [
        69.6078,
        18.098496957142856
    ],
    [
        69.6181,
        23.031576323809524
    ],
    [
        69.6285,
        23.031576323809524
    ],
    [
        69.6389,
        22.75007307619048
    ],
    [
        69.6492,
        23.73668894761905
    ],
    [
        69.6596,
        21.95529917619048
    ],
    [
        69.67,
        24.915146795238094
    ],
    [
        69.6803,
        24.0929669047619
    ],
    [
        69.6907,
        28.039430399999997
    ],
    [
        69.701,
        33.526047680952374
    ],
    [
        69.7114,
        32.04558207142857
    ],
    [
        69.7218,
        32.210559847619045
    ],
    [
        69.7321,
        33.62001109523809
    ],
    [
        69.7425,
        27.979497109523805
    ],
    [
        69.7528,
        29.460775419047618
    ],
    [
        69.7632,
        25.144330971428573
    ],
    [
        69.7736,
        24.20469680476191
    ],
    [
        69.7839,
        20.50421003333334
    ],
    [
        69.7943,
        23.38183966666667
    ],
    [
        69.8046,
        23.38183966666667
    ],
    [
        69.815,
        22.354114795238097
    ],
    [
        69.8254,
        17.41832643809524
    ],
    [
        69.8357,
        13.719871409523808
    ],
    [
        69.8461,
        8.784083047619047
    ],
    [
        69.8565,
        3.1431820619047617
    ],
    [
        69.8668,
        3.3898360333333346
    ],
    [
        69.8772,
        6.267465666666667
    ],
    [
        69.8875,
        7.419420514285713
    ],
    [
        69.8979,
        11.119230038095237
    ],
    [
        69.9083,
        1.2530713047619044
    ],
    [
        69.9186,
        5.6380307428571435
    ],
    [
        69.929,
        7.858458257142858
    ],
    [
        69.9394,
        8.10457042857143
    ],
    [
        69.9497,
        2.461347452380954
    ],
    [
        69.9601,
        5.632612757142859
    ],
    [
        69.9704,
        4.809078366666667
    ],
    [
        69.9808,
        4.192443447619048
    ],
    [
        69.9912,
        4.474875495238096
    ],
    [
        70.0015,
        7.3531823761904755
    ],
    [
        70.0119,
        1.1868331666666667
    ],
    [
        70.0222,
        0.5290892476190466
    ],
    [
        70.0326,
        -0.08754567142857132
    ],
    [
        70.043,
        1.5568141142857144
    ],
    [
        70.0533,
        0.8157686142857146
    ],
    [
        70.0637,
        1.5204942380952384
    ],
    [
        70.074,
        6.456282599999999
    ],
    [
        70.0844,
        0.28993339047618977
    ],
    [
        70.0948,
        -0.12115655714285654
    ],
    [
        70.1051,
        -6.702207704761904
    ],
    [
        70.1155,
        -7.113297652380952
    ],
    [
        70.1259,
        2.75286108095238
    ],
    [
        70.1362,
        -1.6869103476190475
    ],
    [
        70.1466,
        -3.53681510952381
    ],
    [
        70.1569,
        -4.390408923809524
    ],
    [
        70.1673,
        -1.0962713619047622
    ],
    [
        70.1777,
        -5.207170833333333
    ],
    [
        70.188,
        -11.784609990476191
    ],
    [
        70.1984,
        -11.079497366666667
    ],
    [
        70.2088,
        -10.421392252380954
    ],
    [
        70.2191,
        -10.832482199999998
    ],
    [
        70.2295,
        -4.254140047619048
    ],
    [
        70.2398,
        -2.7742162380952387
    ],
    [
        70.2502,
        -5.240755919047619
    ],
    [
        70.2606,
        -3.4314183523809523
    ],
    [
        70.2709,
        1.0080821761904777
    ],
    [
        70.2813,
        -1.341003238095237
    ],
    [
        70.2916,
        -0.5188233428571419
    ],
    [
        70.302,
        6.880795704761904
    ],
    [
        70.3124,
        6.353025485714285
    ],
    [
        70.3227,
        8.822274166666668
    ],
    [
        70.3331,
        9.233364114285715
    ],
    [
        70.3434,
        4.300284747619049
    ],
    [
        70.3538,
        5.451336600000001
    ],
    [
        70.3642,
        7.712331309523809
    ],
    [
        70.3745,
        6.099385438095238
    ],
    [
        70.3849,
        8.154835176190476
    ],
    [
        70.3952,
        8.154835176190476
    ],
    [
        70.4056,
        14.074530414285713
    ],
    [
        70.416,
        15.835957471428575
    ],
    [
        70.4263,
        14.190694685714286
    ],
    [
        70.4367,
        13.505544771428571
    ],
    [
        70.4471,
        16.793361352380952
    ],
    [
        70.4574,
        12.682461880952381
    ],
    [
        70.4678,
        20.084789923809524
    ],
    [
        70.4781,
        20.742895038095238
    ],
    [
        70.4885,
        10.876736304761906
    ],
    [
        70.4989,
        13.13773101428571
    ],
    [
        70.5092,
        14.782090799999997
    ],
    [
        70.5196,
        13.020276742857147
    ],
    [
        70.53,
        12.31477711904762
    ],
    [
        70.5403,
        15.486816423809527
    ],
    [
        70.5507,
        13.26693070952381
    ],
    [
        70.561,
        11.622570919047622
    ],
    [
        70.5714,
        10.964827
    ],
    [
        70.5818,
        11.788361390476192
    ],
    [
        70.5921,
        15.488170914285714
    ],
    [
        70.6025,
        15.077080966666667
    ],
    [
        70.6128,
        17.132756452380956
    ],
    [
        70.6232,
        16.146140580952384
    ],
    [
        70.6336,
        16.14614058095238
    ],
    [
        70.6439,
        13.6796009
    ],
    [
        70.6543,
        14.365428061904762
    ],
    [
        70.6646,
        10.25498009047619
    ],
    [
        70.675,
        10.666070038095238
    ],
    [
        70.6854,
        8.196821357142857
    ],
    [
        70.6957,
        6.551558571428571
    ],
    [
        70.7061,
        12.101611480952382
    ],
    [
        70.7165,
        11.48497656190476
    ],
    [
        70.7268,
        9.01843688095238
    ],
    [
        70.7372,
        9.01843688095238
    ],
    [
        70.7475,
        7.785167038095237
    ],
    [
        70.7579,
        7.901847309523809
    ],
    [
        70.7683,
        9.437185109523808
    ],
    [
        70.7786,
        10.376819276190474
    ],
    [
        70.789,
        11.856743085714285
    ],
    [
        70.7993,
        4.455769538095237
    ],
    [
        70.8097,
        3.2224996999999993
    ],
    [
        70.8201,
        3.8394732428571423
    ],
    [
        70.8304,
        -1.50492181904762
    ],
    [
        70.8408,
        3.428157547619047
    ],
    [
        70.8512,
        1.9482337380952381
    ],
    [
        70.8615,
        -0.5183059476190482
    ],
    [
        70.8719,
        -0.9300731428571428
    ],
    [
        70.8822,
        3.180374828571428
    ],
    [
        70.8926,
        6.8828933476190475
    ],
    [
        70.903,
        4.416353666666666
    ],
    [
        70.9133,
        6.884247847619047
    ],
    [
        70.9237,
        1.3341949380952371
    ],
    [
        70.934,
        6.2699833
    ],
    [
        70.9444,
        7.256599171428571
    ],
    [
        70.9548,
        7.78514339047619
    ],
    [
        70.9651,
        9.84059312857143
    ],
    [
        70.9755,
        7.78514339047619
    ],
    [
        70.9858,
        7.853395004761905
    ],
    [
        70.9962,
        3.625041261904762
    ],
    [
        71.0066,
        1.1585015809523806
    ],
    [
        71.0169,
        7.736843733333333
    ],
    [
        71.0273,
        11.436653257142856
    ],
    [
        71.0377,
        5.883891352380952
    ],
    [
        71.048,
        11.36509064761905
    ],
    [
        71.0584,
        8.899905461904762
    ],
    [
        71.0687,
        9.146559433333334
    ],
    [
        71.0791,
        11.613099119047623
    ],
    [
        71.0895,
        9.146559433333334
    ],
    [
        71.0998,
        4.761599995238096
    ],
    [
        71.1102,
        -0.17418836190476128
    ],
    [
        71.1205,
        0.6484430333333341
    ],
    [
        71.1309,
        -6.752530514285714
    ],
    [
        71.1413,
        -1.2707292190476185
    ],
    [
        71.1516,
        -7.0286974761904775
    ],
    [
        71.162,
        -7.566851585714285
    ],
    [
        71.1724,
        -13.735909790476192
    ],
    [
        71.1827,
        -14.558089685714286
    ],
    [
        71.1931,
        -8.391740476190478
    ],
    [
        71.2034,
        -8.186534123809526
    ],
    [
        71.2138,
        -1.609094966666666
    ],
    [
        71.2242,
        -2.8423648095238088
    ],
    [
        71.2345,
        -3.939507666666667
    ],
    [
        71.2449,
        -10.105856876190478
    ],
    [
        71.2553,
        -4.621346585714287
    ],
    [
        71.2656,
        -10.102545880952382
    ],
    [
        71.276,
        -11.337170219047621
    ],
    [
        71.2863,
        -12.570440061904765
    ],
    [
        71.2967,
        -15.036979747619048
    ],
    [
        71.3071,
        -12.944158195238098
    ],
    [
        71.3174,
        -14.725547966666667
    ],
    [
        71.3278,
        -11.272392414285713
    ],
    [
        71.3381,
        -13.328970900000002
    ],
    [
        71.3485,
        -7.1626216904761915
    ],
    [
        71.3589,
        -6.889163723809524
    ],
    [
        71.3692,
        -4.420818042857144
    ],
    [
        71.3796,
        -7.335819485714286
    ],
    [
        71.3899,
        0.06650855714285579
    ],
    [
        71.4003,
        1.7117713428571413
    ],
    [
        71.4107,
        -2.2346921523809544
    ],
    [
        71.421,
        1.8762073190476176
    ],
    [
        71.4314,
        -1.4125122619047628
    ],
    [
        71.4417,
        1.4660203666666658
    ],
    [
        71.4521,
        1.7400803333333332
    ],
    [
        71.4625,
        0.5068104952380941
    ],
    [
        71.4728,
        1.191358409523809
    ],
    [
        71.4832,
        4.478271990476192
    ],
    [
        71.4936,
        4.2316180190476205
    ],
    [
        71.5039,
        4.724925957142859
    ],
    [
        71.5143,
        9.658005323809524
    ],
    [
        71.5246,
        10.85480634761905
    ],
    [
        71.535,
        10.443716400000001
    ],
    [
        71.5454,
        10.074074071428573
    ],
    [
        71.5557,
        8.841481480952382
    ],
    [
        71.5661,
        9.25257142857143
    ],
    [
        71.5764,
        9.663661376190479
    ],
    [
        71.5868,
        9.661855380952383
    ],
    [
        71.5972,
        9.659146385714289
    ],
    [
        71.6075,
        7.897332328571431
    ],
    [
        71.6179,
        3.7855298619047644
    ],
    [
        71.6283,
        3.2922219285714287
    ],
    [
        71.6386,
        -3.2861202238095237
    ],
    [
        71.649,
        -6.574839800000001
    ],
    [
        71.6593,
        -6.986832742857142
    ],
    [
        71.6697,
        -6.822396766666665
    ],
    [
        71.6801,
        0.5772222809523813
    ],
    [
        71.6904,
        -0.1627396238095225
    ],
    [
        71.7008,
        0.25015631904761987
    ],
    [
        71.7112,
        0.0861718428571437
    ],
    [
        71.7215,
        -2.8750302761904747
    ],
    [
        71.7319,
        -1.2306704857142843
    ],
    [
        71.7422,
        -1.2315734809523802
    ],
    [
        71.7526,
        -4.523002052380953
    ],
    [
        71.763,
        -3.4947128095238105
    ],
    [
        71.7733,
        0.6161866619047606
    ],
    [
        71.7837,
        -1.0281731285714295
    ],
    [
        71.794,
        -0.6170831809523809
    ],
    [
        71.8044,
        -4.727982652380953
    ],
    [
        71.8148,
        -1.4361025809523813
    ],
    [
        71.8251,
        -0.9075583619047618
    ],
    [
        71.8355,
        4.026875499999999
    ],
    [
        71.8458,
        2.300297719047618
    ],
    [
        71.8562,
        4.439139342857142
    ],
    [
        71.8666,
        9.372218709523809
    ],
    [
        71.8769,
        9.372218709523807
    ],
    [
        71.8873,
        7.398445166666666
    ],
    [
        71.8977,
        3.170091423809523
    ],
    [
        71.908,
        3.49896338095238
    ],
    [
        71.9184,
        9.665312590476189
    ],
    [
        71.9287,
        8.137955571428572
    ],
    [
        71.9391,
        11.15396968095238
    ],
    [
        71.9495,
        9.50960989047619
    ],
    [
        71.9598,
        8.852949571428573
    ],
    [
        71.9702,
        8.855658566666664
    ],
    [
        71.9805,
        8.44434287142857
    ],
    [
        71.9909,
        11.735771442857144
    ],
    [
        72.0013,
        10.091411652380955
    ],
    [
        72.0116,
        9.151777485714288
    ],
    [
        72.022,
        12.851587009523811
    ],
    [
        72.0323,
        13.673315404761906
    ],
    [
        72.0427,
        12.440045561904764
    ],
    [
        72.0531,
        5.039072014285715
    ],
    [
        72.0634,
        6.9778414809523825
    ],
    [
        72.0738,
        1.5511832761904754
    ],
    [
        72.0842,
        2.537799147619047
    ],
    [
        72.0945,
        2.2911451761904766
    ],
    [
        72.1049,
        8.21409120952381
    ],
    [
        72.1152,
        7.509365585714288
    ],
    [
        72.1256,
        7.920455533333334
    ],
    [
        72.136,
        3.604011085714287
    ],
    [
        72.1463,
        3.6921017904761917
    ],
    [
        72.1567,
        3.8487074857142867
    ],
    [
        72.1671,
        5.493970271428572
    ],
    [
        72.1774,
        5.492886671428572
    ],
    [
        72.1878,
        8.780703252380953
    ],
    [
        72.1981,
        8.78070325238095
    ],
    [
        72.2085,
        8.777994257142858
    ],
    [
        72.2189,
        5.489274680952382
    ],
    [
        72.2292,
        5.771706728571429
    ],
    [
        72.2396,
        4.538436885714286
    ],
    [
        72.2499,
        3.880692966666667
    ],
    [
        72.2603,
        1.4127987857142859
    ],
    [
        72.2707,
        6.962513071428573
    ],
    [
        72.281,
        7.079193342857144
    ],
    [
        72.2914,
        13.656632500000004
    ],
    [
        72.3018,
        11.436746785714288
    ],
    [
        72.3121,
        11.437830385714287
    ],
    [
        72.3225,
        6.502042023809522
    ],
    [
        72.3328,
        8.968581704761903
    ],
    [
        72.3432,
        8.228890700000001
    ],
    [
        72.3536,
        7.612255780952379
    ],
    [
        72.3639,
        13.161970066666667
    ],
    [
        72.3743,
        14.923784123809522
    ],
    [
        72.3846,
        18.211600704761903
    ],
    [
        72.395,
        19.692879014285715
    ],
    [
        72.4054,
        21.88626172857143
    ],
    [
        72.4157,
        19.947589014285715
    ],
    [
        72.4261,
        16.249133985714284
    ],
    [
        72.4364,
        16.249133985714284
    ],
    [
        72.4468,
        15.614339123809522
    ],
    [
        72.4572,
        14.62772325238095
    ],
    [
        72.4675,
        13.641107380952379
    ],
    [
        72.4779,
        21.042080928571426
    ],
    [
        72.4882,
        21.042080928571426
    ],
    [
        72.4986,
        22.68644071428571
    ],
    [
        72.509,
        19.80881108095238
    ],
    [
        72.5193,
        21.49054268571428
    ],
    [
        72.5297,
        25.435922580952376
    ],
    [
        72.5401,
        25.435922580952376
    ],
    [
        72.5504,
        24.614645685714283
    ],
    [
        72.5608,
        24.943246742857145
    ],
    [
        72.5711,
        22.47670706190476
    ],
    [
        72.5815,
        19.18889048095238
    ],
    [
        72.5919,
        16.017625176190478
    ],
    [
        72.6022,
        11.533007571428573
    ],
    [
        72.6126,
        14.000901752380953
    ],
    [
        72.6229,
        14.439397695238094
    ],
    [
        72.6333,
        15.966754714285715
    ],
    [
        72.6437,
        19.665209742857144
    ],
    [
        72.654,
        22.95392931904762
    ],
    [
        72.6644,
        25.068106190476193
    ],
    [
        72.6748,
        26.60314299047619
    ],
    [
        72.6851,
        25.61598531904762
    ],
    [
        72.6955,
        23.149445633333332
    ],
    [
        72.7058,
        22.98151874761905
    ],
    [
        72.7162,
        18.870619276190478
    ],
    [
        72.7266,
        20.925617514285715
    ],
    [
        72.7369,
        15.541367390476193
    ],
    [
        72.7473,
        12.25355080952381
    ],
    [
        72.7576,
        12.25355080952381
    ],
    [
        72.768,
        8.96302523809524
    ],
    [
        72.7784,
        8.688664271428573
    ],
    [
        72.7887,
        16.0964103
    ],
    [
        72.7991,
        16.9163327
    ],
    [
        72.8095,
        18.326170947619044
    ],
    [
        72.8198,
        22.808079557142857
    ],
    [
        72.8302,
        15.403042519047622
    ],
    [
        72.8405,
        15.238155042857144
    ],
    [
        72.8509,
        12.770260861904763
    ],
    [
        72.8613,
        9.482444280952382
    ],
    [
        72.8716,
        16.057174442857143
    ],
    [
        72.882,
        14.29574738571429
    ],
    [
        72.8924,
        13.74732645714286
    ],
    [
        72.9027,
        14.734484128571431
    ],
    [
        72.9131,
        14.733129633333336
    ],
    [
        72.9234,
        15.271579271428571
    ],
    [
        72.9338,
        16.915939061904766
    ],
    [
        72.9442,
        16.642631595238097
    ],
    [
        72.9545,
        20.756691561904766
    ],
    [
        72.9649,
        14.175640414285716
    ],
    [
        72.9752,
        15.162256285714287
    ],
    [
        72.9856,
        17.424153990476196
    ],
    [
        72.996,
        17.69896645714286
    ],
    [
        73.0063,
        11.524490266666666
    ],
    [
        73.0167,
        10.704567866666666
    ],
    [
        73.027,
        10.54757517142857
    ],
    [
        73.0374,
        3.149310619047619
    ],
    [
        73.0478,
        8.085098976190475
    ],
    [
        73.0581,
        7.8117915095238075
    ],
    [
        73.0685,
        4.523974928571427
    ],
    [
        73.0788,
        7.8117915095238075
    ],
    [
        73.0892,
        1.8948052666666662
    ],
    [
        73.0996,
        1.5693686714285704
    ],
    [
        73.1099,
        0.582210999999999
    ],
    [
        73.1203,
        1.2865496238095229
    ],
    [
        73.1307,
        -2.1657932333333343
    ],
    [
        73.141,
        -1.9190038142857149
    ],
    [
        73.1514,
        3.014075552380952
    ],
    [
        73.1617,
        2.9141163857142853
    ],
    [
        73.1721,
        2.9132133857142852
    ],
    [
        73.1825,
        8.228261004761904
    ],
    [
        73.1928,
        6.53653250952381
    ],
    [
        73.2032,
        6.741625985714284
    ],
    [
        73.2136,
        10.854782952380953
    ],
    [
        73.2239,
        17.023841157142858
    ],
    [
        73.2343,
        17.022035161904764
    ],
    [
        73.2446,
        17.461072904761906
    ],
    [
        73.255,
        19.928967085714287
    ],
    [
        73.2654,
        14.994533223809523
    ],
    [
        73.2757,
        15.43218637142857
    ],
    [
        73.2861,
        21.187897133333333
    ],
    [
        73.2964,
        15.55047914285714
    ],
    [
        73.3068,
        17.524252685714284
    ],
    [
        73.3172,
        17.84891528095238
    ],
    [
        73.3275,
        13.90028459047619
    ],
    [
        73.3379,
        13.901058590476193
    ],
    [
        73.3482,
        13.244759471428573
    ],
    [
        73.3586,
        12.55923330952381
    ],
    [
        73.369,
        9.270513733333337
    ],
    [
        73.3793,
        8.822051971428573
    ],
    [
        73.3897,
        7.179498180952386
    ],
    [
        73.4001,
        8.03418601428572
    ],
    [
        73.4104,
        8.739298638095242
    ],
    [
        73.4208,
        8.534205161904765
    ],
    [
        73.4311,
        3.5984168000000007
    ],
    [
        73.4415,
        -0.7203980190476204
    ],
    [
        73.4519,
        -3.5971246523809532
    ],
    [
        73.4622,
        -3.7621024285714304
    ],
    [
        73.4726,
        -2.116839642857145
    ],
    [
        73.483,
        1.173234433333332
    ],
    [
        73.4933,
        2.6550545380952366
    ],
    [
        73.5037,
        0.18806335238095176
    ],
    [
        73.514,
        2.537664761904761
    ],
    [
        73.5244,
        0.5638912190476182
    ],
    [
        73.5347,
        0.21210890476190314
    ],
    [
        73.5451,
        0.21752689523809532
    ],
    [
        73.5555,
        -5.423374090476191
    ],
    [
        73.5658,
        -3.162943757142858
    ],
    [
        73.5762,
        -2.067907895238096
    ],
    [
        73.5866,
        -3.712267685714286
    ],
    [
        73.5969,
        1.2181026857142854
    ],
    [
        73.6073,
        2.696130199999999
    ],
    [
        73.6176,
        2.1665217333333335
    ],
    [
        73.628,
        -0.30408144285714284
    ],
    [
        73.6384,
        -0.30442006666666693
    ],
    [
        73.6487,
        -1.9496828523809522
    ],
    [
        73.6591,
        -1.8623177714285724
    ],
    [
        73.6695,
        -0.6290479285714288
    ],
    [
        73.6798,
        3.481851542857144
    ],
    [
        73.6902,
        3.0705358476190487
    ],
    [
        73.7005,
        3.0709873476190475
    ],
    [
        73.7109,
        -4.327277204761903
    ],
    [
        73.7212,
        2.252870947619049
    ],
    [
        73.7316,
        1.840200752380954
    ],
    [
        73.742,
        1.1797478428571446
    ],
    [
        73.7523,
        1.320228571428573
    ],
    [
        73.7627,
        5.016651852380954
    ],
    [
        73.7731,
        12.417625399999999
    ],
    [
        73.7834,
        12.787809528571431
    ],
    [
        73.7938,
        16.077432104761908
    ],
    [
        73.8042,
        17.06404797619048
    ],
    [
        73.8145,
        13.77894039047619
    ],
    [
        73.8249,
        13.779482190476191
    ],
    [
        73.8352,
        14.30841340952381
    ],
    [
        73.8456,
        19.245556266666668
    ],
    [
        73.856,
        18.62925997142857
    ],
    [
        73.8663,
        21.26168042857143
    ],
    [
        73.8767,
        15.624262438095238
    ],
    [
        73.887,
        16.61087830952381
    ],
    [
        73.8974,
        12.50043033809524
    ],
    [
        73.9078,
        12.91084303809524
    ],
    [
        73.9181,
        11.101053971428573
    ],
    [
        73.9285,
        16.579544271428567
    ],
    [
        73.9388,
        12.630371780952382
    ],
    [
        73.9492,
        8.92988500952381
    ],
    [
        73.9596,
        11.193250085714286
    ],
    [
        73.9699,
        9.46558870952381
    ],
    [
        73.9803,
        11.686151671428572
    ],
    [
        73.9907,
        14.156754847619052
    ],
    [
        74.001,
        13.169597176190477
    ],
    [
        74.0114,
        8.853491352380953
    ],
    [
        74.0217,
        7.045147085714286
    ],
    [
        74.0321,
        5.94830522857143
    ],
    [
        74.0425,
        7.922078771428572
    ],
    [
        74.0528,
        5.867080533333334
    ],
    [
        74.0632,
        4.387698523809523
    ],
    [
        74.0735,
        5.209426919047618
    ],
    [
        74.0839,
        9.163475595238094
    ],
    [
        74.0943,
        13.110480885714288
    ],
    [
        74.1046,
        12.617443852380953
    ],
    [
        74.115,
        16.727891823809525
    ],
    [
        74.1253,
        17.551426214285716
    ],
    [
        74.1357,
        19.77117647619048
    ],
    [
        74.1461,
        19.22305654761905
    ],
    [
        74.1564,
        19.881703461904763
    ],
    [
        74.1668,
        23.33567171904762
    ],
    [
        74.1771,
        22.719375423809524
    ],
    [
        74.1875,
        21.48610558095238
    ],
    [
        74.1979,
        21.322121104761905
    ],
    [
        74.2082,
        17.09067136666667
    ],
    [
        74.2186,
        12.154883004761906
    ],
    [
        74.229,
        12.242248085714287
    ],
    [
        74.2393,
        12.076818809523813
    ],
    [
        74.2497,
        11.528397880952385
    ],
    [
        74.2601,
        11.158213752380956
    ],
    [
        74.2704,
        7.046862780952384
    ],
    [
        74.2808,
        6.7644307333333344
    ],
    [
        74.2911,
        5.942702338095238
    ],
    [
        74.3015,
        1.0014959904761902
    ],
    [
        74.3119,
        1.9875700666666691
    ],
    [
        74.3222,
        1.4939912285714283
    ],
    [
        74.3326,
        -3.027772442857144
    ],
    [
        74.3429,
        -7.140026414285715
    ],
    [
        74.3533,
        -7.667893380952382
    ],
    [
        74.3637,
        -8.654509252380953
    ],
    [
        74.374,
        -9.806193200000001
    ],
    [
        74.3844,
        -8.32708209047619
    ],
    [
        74.3947,
        -9.149713485714285
    ],
    [
        74.4051,
        -6.133398376190477
    ],
    [
        74.4155,
        -6.956029771428573
    ],
    [
        74.4258,
        -6.4257440571428575
    ],
    [
        74.4362,
        0.9765839857142857
    ],
    [
        74.4466,
        -0.43286726190476366
    ],
    [
        74.4569,
        1.2586290333333312
    ],
    [
        74.4673,
        6.197126385714283
    ],
    [
        74.4776,
        6.028368333333332
    ],
    [
        74.488,
        8.493553519047618
    ],
    [
        74.4984,
        7.788827895238094
    ],
    [
        74.5087,
        10.256722076190474
    ],
    [
        74.5191,
        12.724616257142857
    ],
    [
        74.5295,
        13.340912552380953
    ],
    [
        74.5398,
        14.328070223809524
    ],
    [
        74.5502,
        11.448860347619046
    ],
    [
        74.5605,
        13.915851533333331
    ],
    [
        74.5709,
        18.14149628095238
    ],
    [
        74.5813,
        19.833224776190477
    ],
    [
        74.5916,
        18.51791754285714
    ],
    [
        74.602,
        16.75784498095238
    ],
    [
        74.6123,
        12.650106004761906
    ],
    [
        74.6227,
        12.650106004761907
    ],
    [
        74.6331,
        10.182211823809528
    ],
    [
        74.6434,
        9.564222404761907
    ],
    [
        74.6538,
        12.030762090476193
    ],
    [
        74.6642,
        13.722490585714286
    ],
    [
        74.6745,
        11.371986176190479
    ],
    [
        74.6849,
        6.433488823809524
    ],
    [
        74.6952,
        6.690385957142857
    ],
    [
        74.7056,
        7.51392034761905
    ],
    [
        74.7159,
        8.130893890476193
    ],
    [
        74.7263,
        3.1978145238095244
    ],
    [
        74.7367,
        5.665708704761905
    ],
    [
        74.747,
        6.283359500000001
    ],
    [
        74.7574,
        6.528794419047619
    ],
    [
        74.7678,
        10.230635690476193
    ],
    [
        74.7781,
        7.7645475047619055
    ],
    [
        74.7885,
        5.3020713142857145
    ],
    [
        74.7988,
        6.242221476190477
    ],
    [
        74.8092,
        8.051107542857142
    ],
    [
        74.8196,
        12.276365290476193
    ],
    [
        74.8299,
        18.02936705714286
    ],
    [
        74.8403,
        15.015760942857147
    ],
    [
        74.8507,
        22.41944348571429
    ],
    [
        74.861,
        23.038110152380955
    ],
    [
        74.8714,
        19.33762337619048
    ],
    [
        74.8817,
        20.81890168571429
    ],
    [
        74.8921,
        27.395437842857145
    ],
    [
        74.9025,
        32.33122620476191
    ],
    [
        74.9128,
        32.613271252380954
    ],
    [
        74.9232,
        32.61236825714287
    ],
    [
        74.9335,
        32.81712310952382
    ],
    [
        74.9439,
        40.218096657142866
    ],
    [
        74.9543,
        37.74749348095239
    ],
    [
        74.9646,
        38.15880917619048
    ],
    [
        74.975,
        34.46103140000001
    ],
    [
        74.9854,
        35.694978490476196
    ],
    [
        74.9957,
        37.33843528095239
    ],
    [
        75.0061,
        36.1031336904762
    ],
    [
        75.0164,
        36.92576508571428
    ],
    [
        75.0268,
        36.64372003809523
    ],
    [
        75.0372,
        29.245455485714285
    ],
    [
        75.0475,
        30.066280880952384
    ],
    [
        75.0579,
        34.17672885238095
    ],
    [
        75.0682,
        30.06312038571428
    ],
    [
        75.0786,
        30.47217859047619
    ],
    [
        75.089,
        24.30650663333334
    ],
    [
        75.0993,
        22.82577012380953
    ],
    [
        75.1097,
        12.959611390476192
    ],
    [
        75.12,
        9.00989710476191
    ],
    [
        75.1304,
        10.490633614285715
    ],
    [
        75.1408,
        12.135896400000004
    ],
    [
        75.1511,
        11.76312230952381
    ],
    [
        75.1615,
        6.827333947619043
    ],
    [
        75.1719,
        2.7168859761904747
    ],
    [
        75.1822,
        2.7150799809523805
    ],
    [
        75.1926,
        6.167422838095239
    ],
    [
        75.2029,
        4.685602733333333
    ],
    [
        75.2133,
        3.040339947619047
    ],
    [
        75.2237,
        2.424720899999999
    ],
    [
        75.234,
        -0.04317328095238141
    ],
    [
        75.2444,
        -0.7482859047619055
    ],
    [
        75.2547,
        2.4239469
    ],
    [
        75.2651,
        -4.1607162380952385
    ],
    [
        75.2755,
        -0.875608652380952
    ],
    [
        75.2858,
        3.2379998142857156
    ],
    [
        75.2962,
        2.4162714190476193
    ],
    [
        75.3065,
        12.28513914761905
    ],
    [
        75.3169,
        12.941438266666667
    ],
    [
        75.3273,
        18.699858023809526
    ],
    [
        75.3376,
        16.068521161904762
    ],
    [
        75.348,
        15.244083771428574
    ],
    [
        75.3583,
        16.066715166666672
    ],
    [
        75.3687,
        13.974304071428575
    ],
    [
        75.3791,
        14.962545342857146
    ],
    [
        75.3894,
        11.677437757142858
    ],
    [
        75.3998,
        7.566086785714286
    ],
    [
        75.4102,
        7.565003190476191
    ],
    [
        75.4205,
        7.4015605095238115
    ],
    [
        75.4309,
        11.512008480952383
    ],
    [
        75.4413,
        10.895034938095241
    ],
    [
        75.4516,
        12.128982028571432
    ],
    [
        75.462,
        9.663796842857145
    ],
    [
        75.4723,
        9.782089614285715
    ],
    [
        75.4827,
        9.790216595238096
    ],
    [
        75.4931,
        6.094470561904762
    ],
    [
        75.5034,
        -3.77168817142857
    ],
    [
        75.5138,
        -2.126425385714285
    ],
    [
        75.5241,
        -4.595674066666668
    ],
    [
        75.5345,
        -3.772591176190478
    ],
    [
        75.5449,
        -3.361952728571431
    ],
    [
        75.5552,
        -0.8940585476190493
    ],
    [
        75.5656,
        -0.06962115714285895
    ],
    [
        75.5759,
        1.5729326333333309
    ],
    [
        75.5863,
        4.2035470952380924
    ],
    [
        75.5967,
        4.447898414285713
    ],
    [
        75.607,
        8.672769161904762
    ],
    [
        75.6174,
        11.688783271428571
    ],
    [
        75.6278,
        11.936656285714285
    ],
    [
        75.6381,
        8.648839704761905
    ],
    [
        75.6485,
        6.888767142857143
    ],
    [
        75.6588,
        8.121359733333334
    ],
    [
        75.6692,
        11.823201004761906
    ],
    [
        75.6796,
        15.110114585714287
    ],
    [
        75.6899,
        19.223271552380954
    ],
    [
        75.7003,
        26.6242451
    ],
    [
        75.7106,
        30.32540912380953
    ],
    [
        75.721,
        36.489726585714294
    ],
    [
        75.7314,
        31.556647219047623
    ],
    [
        75.7417,
        29.79599415714286
    ],
    [
        75.7521,
        26.343380400000008
    ],
    [
        75.7624,
        26.097268228571433
    ],
    [
        75.7728,
        26.260710909523812
    ],
    [
        75.7832,
        18.857028366666672
    ],
    [
        75.7935,
        17.624435771428573
    ],
    [
        75.8039,
        16.637278100000003
    ],
    [
        75.8143,
        16.22731690476191
    ],
    [
        75.8246,
        19.39800171428572
    ],
    [
        75.835,
        23.78567014761905
    ],
    [
        75.8454,
        22.55172305714286
    ],
    [
        75.8557,
        26.003524114285717
    ],
    [
        75.8661,
        26.285569161904764
    ],
    [
        75.8764,
        26.698239357142857
    ],
    [
        75.8868,
        20.118091204761907
    ],
    [
        75.8971,
        20.118542704761904
    ],
    [
        75.9075,
        20.115833709523812
    ],
    [
        75.9179,
        17.64793952857143
    ],
    [
        75.9282,
        13.534331061904762
    ],
    [
        75.9386,
        12.300383971428573
    ],
    [
        75.949,
        9.829780795238097
    ],
    [
        75.9593,
        9.616931214285714
    ],
    [
        75.9697,
        9.780373895238094
    ],
    [
        75.98,
        9.241924257142855
    ],
    [
        75.9904,
        7.550737557142857
    ],
    [
        76.0008,
        12.815217276190475
    ],
    [
        76.0111,
        10.759993290476194
    ],
    [
        76.0215,
        12.403450080952386
    ],
    [
        76.0318,
        6.645030323809527
    ],
    [
        76.0422,
        4.179845138095241
    ],
    [
        76.0526,
        -0.34507902857143014
    ],
    [
        76.0629,
        -5.2808673904761925
    ],
    [
        76.0733,
        -8.73266844761905
    ],
    [
        76.0837,
        -9.014713495238096
    ],
    [
        76.094,
        -12.305239066666667
    ],
    [
        76.1044,
        -10.281203247619048
    ],
    [
        76.1147,
        -16.033753514285717
    ],
    [
        76.1251,
        -18.4989387
    ],
    [
        76.1355,
        -16.03104451904762
    ],
    [
        76.1458,
        -11.917436052380951
    ],
    [
        76.1562,
        -9.44954187142857
    ],
    [
        76.1665,
        -3.897457214285714
    ],
    [
        76.1769,
        -1.9226000714285703
    ],
    [
        76.1873,
        -6.030339047619048
    ],
    [
        76.1976,
        -5.985468242857142
    ],
    [
        76.208,
        -6.925618404761903
    ],
    [
        76.2183,
        -5.609046976190477
    ],
    [
        76.2287,
        -3.14295879047619
    ],
    [
        76.2391,
        0.14937278095238224
    ],
    [
        76.2494,
        5.90688953809524
    ],
    [
        76.2598,
        5.084258142857145
    ],
    [
        76.2702,
        5.2215138761904765
    ],
    [
        76.2805,
        5.224222871428573
    ],
    [
        76.2909,
        8.39452068095238
    ],
    [
        76.3012,
        6.045048266666669
    ],
    [
        76.3116,
        8.512490947619048
    ],
    [
        76.322,
        10.600709100000003
    ],
    [
        76.3323,
        17.176342257142863
    ],
    [
        76.3427,
        13.886268180952383
    ],
    [
        76.353,
        12.123099623809521
    ],
    [
        76.3634,
        9.653850942857142
    ],
    [
        76.3738,
        8.83121954761905
    ],
    [
        76.3841,
        3.2845528809523823
    ],
    [
        76.3945,
        1.8038163714285707
    ],
    [
        76.4049,
        7.968133833333333
    ],
    [
        76.4152,
        9.93974018095238
    ],
    [
        76.4256,
        13.230265752380953
    ],
    [
        76.4359,
        6.654632595238094
    ],
    [
        76.4463,
        5.998333476190475
    ],
    [
        76.4567,
        -3.873243247619049
    ],
    [
        76.467,
        -3.707813971428573
    ],
    [
        76.4774,
        2.0451877952380935
    ],
    [
        76.4877,
        2.202180490476189
    ],
    [
        76.4981,
        9.600445042857142
    ],
    [
        76.5085,
        8.072701023809524
    ],
    [
        76.5188,
        4.781272452380952
    ],
    [
        76.5292,
        4.780820952380951
    ],
    [
        76.5395,
        4.78082095238095
    ],
    [
        76.5499,
        3.6836780952380943
    ],
    [
        76.5603,
        4.505857990476188
    ],
    [
        76.5706,
        2.15586958095238
    ],
    [
        76.581,
        0.5119612952380935
    ],
    [
        76.5914,
        0.5101552999999993
    ],
    [
        76.6017,
        7.908419852380952
    ],
    [
        76.6121,
        6.921262180952381
    ],
    [
        76.6224,
        6.921262180952381
    ],
    [
        76.6328,
        -0.4797113666666673
    ],
    [
        76.6432,
        -0.892381561904763
    ],
    [
        76.6535,
        4.860620204761905
    ],
    [
        76.6639,
        2.229102742857143
    ],
    [
        76.6743,
        7.9879739952380975
    ],
    [
        76.6846,
        7.000816323809524
    ],
    [
        76.695,
        1.0832882809523818
    ],
    [
        76.7053,
        0.7574349142857151
    ],
    [
        76.7157,
        0.7601439095238101
    ],
    [
        76.7261,
        2.816045147619049
    ],
    [
        76.7364,
        8.368807052380955
    ],
    [
        76.7468,
        5.0805389761904785
    ],
    [
        76.7571,
        7.545724161904763
    ],
    [
        76.7675,
        5.3523414476190485
    ],
    [
        76.7779,
        6.174069842857144
    ],
    [
        76.7882,
        8.806490300000004
    ],
    [
        76.7986,
        7.983858904761904
    ],
    [
        76.8089,
        1.404613752380954
    ],
    [
        76.8193,
        -0.07720635238095193
    ],
    [
        76.8297,
        0.9099513190476194
    ],
    [
        76.84,
        2.141189414285714
    ],
    [
        76.8504,
        8.72224056190476
    ],
    [
        76.8608,
        5.0217537904761915
    ],
    [
        76.8711,
        5.0208507904761905
    ],
    [
        76.8815,
        5.431263490476191
    ],
    [
        76.8918,
        5.101217638095239
    ],
    [
        76.9022,
        10.037006
    ],
    [
        76.9126,
        8.556269490476192
    ],
    [
        76.9229,
        9.164167904761905
    ],
    [
        76.9333,
        11.626644095238094
    ],
    [
        76.9436,
        12.861268433333334
    ],
    [
        76.954,
        12.24429489047619
    ],
    [
        76.9644,
        15.533465966666668
    ],
    [
        76.9747,
        11.148506528571428
    ],
    [
        76.9851,
        11.56049947142857
    ],
    [
        76.9954,
        13.20937425238095
    ],
    [
        77.0058,
        10.57875979047619
    ],
    [
        77.0162,
        11.401391185714285
    ],
    [
        77.0265,
        17.569772138095242
    ],
    [
        77.0369,
        18.230766842857147
    ],
    [
        77.0473,
        18.22968324761905
    ],
    [
        77.0576,
        18.23239224285714
    ],
    [
        77.068,
        16.587129457142858
    ],
    [
        77.0783,
        20.288970728571428
    ],
    [
        77.0887,
        24.397612704761904
    ],
    [
        77.0991,
        28.09945397619047
    ],
    [
        77.1094,
        28.42904833333333
    ],
    [
        77.1198,
        21.026720290476195
    ],
    [
        77.1301,
        23.49461447142857
    ],
    [
        77.1405,
        23.329636695238097
    ],
    [
        77.1509,
        19.633890661904765
    ],
    [
        77.1612,
        17.165996480952384
    ],
    [
        77.1716,
        17.78297002380953
    ],
    [
        77.182,
        17.665064252380958
    ],
    [
        77.1923,
        15.88299723333334
    ],
    [
        77.2027,
        16.130193000000006
    ],
    [
        77.213,
        14.893536914285722
    ],
    [
        77.2234,
        17.242106328571438
    ],
    [
        77.2338,
        21.349845304761914
    ],
    [
        77.2441,
        20.93988410952382
    ],
    [
        77.2545,
        19.292815328571436
    ],
    [
        77.2649,
        16.65967247142857
    ],
    [
        77.2752,
        15.424370880952381
    ],
    [
        77.2856,
        16.041344423809523
    ],
    [
        77.2959,
        19.737767704761907
    ],
    [
        77.3063,
        9.871608971428573
    ],
    [
        77.3167,
        8.636307380952381
    ],
    [
        77.327,
        8.362096914285717
    ],
    [
        77.3374,
        9.183373809523811
    ],
    [
        77.3477,
        10.169447885714288
    ],
    [
        77.3581,
        8.360110319047621
    ],
    [
        77.3685,
        7.53657592857143
    ],
    [
        77.3788,
        9.182741714285717
    ],
    [
        77.3892,
        7.333175580952382
    ],
    [
        77.3995,
        7.451081352380951
    ],
    [
        77.4099,
        8.685028442857144
    ],
    [
        77.4203,
        10.906404104761904
    ],
    [
        77.4306,
        11.315913804761905
    ],
    [
        77.441,
        5.675012819047619
    ],
    [
        77.4513,
        -1.7232517333333335
    ],
    [
        77.4617,
        -2.0528460904761907
    ],
    [
        77.4721,
        -2.0528460904761907
    ],
    [
        77.4824,
        -0.40577730952380975
    ],
    [
        77.4928,
        -1.0213963571428577
    ],
    [
        77.5032,
        -1.6383699000000003
    ],
    [
        77.5135,
        -5.746108876190477
    ],
    [
        77.5239,
        -3.2755057
    ],
    [
        77.5342,
        -2.040204109523809
    ],
    [
        77.5446,
        -0.9442652476190473
    ],
    [
        77.555,
        0.7009975380952382
    ],
    [
        77.5653,
        4.648002828571429
    ],
    [
        77.5757,
        6.083868533333333
    ],
    [
        77.5861,
        0.7369902238095231
    ],
    [
        77.5964,
        -2.1424454047619057
    ],
    [
        77.6068,
        0.324094276190476
    ],
    [
        77.6171,
        1.1458226714285702
    ],
    [
        77.6275,
        2.1324385428571415
    ],
    [
        77.6379,
        2.541496747619046
    ],
    [
        77.6482,
        2.5433027428571404
    ],
    [
        77.6586,
        7.479091104761905
    ],
    [
        77.6689,
        14.882773647619047
    ],
    [
        77.6793,
        15.211465004761905
    ],
    [
        77.6897,
        16.856727790476192
    ],
    [
        77.7,
        14.38883360952381
    ],
    [
        77.7104,
        14.759017738095238
    ],
    [
        77.7208,
        15.37599128095238
    ],
    [
        77.7311,
        13.320767295238094
    ],
    [
        77.7415,
        16.334373409523806
    ],
    [
        77.7518,
        8.936108857142855
    ],
    [
        77.7622,
        8.525696157142857
    ],
    [
        77.7726,
        13.456066528571428
    ],
    [
        77.7829,
        9.345618557142856
    ],
    [
        77.7933,
        3.966540152380953
    ],
    [
        77.8036,
        11.370222695238093
    ],
    [
        77.814,
        17.5392809
    ],
    [
        77.8244,
        17.127965204761903
    ],
    [
        77.8347,
        16.100578957142858
    ],
    [
        77.8451,
        10.183592714285716
    ],
    [
        77.8554,
        9.362767314285716
    ],
    [
        77.8658,
        6.074047738095239
    ],
    [
        77.8762,
        3.6061535571428585
    ],
    [
        77.8865,
        -0.8365977666666676
    ],
    [
        77.8969,
        -4.1262203428571445
    ],
    [
        77.9073,
        -4.128026338095238
    ],
    [
        77.9176,
        -3.305394942857142
    ],
    [
        77.928,
        -3.7989737761904765
    ],
    [
        77.9383,
        -6.883841500000001
    ],
    [
        77.9487,
        -5.102827980952381
    ],
    [
        77.9591,
        -7.294705700000001
    ],
    [
        77.9694,
        2.5714530333333347
    ],
    [
        77.9798,
        2.5700985333333355
    ],
    [
        77.9901,
        -0.7150090523809519
    ],
    [
        78.0005,
        -1.5376404476190468
    ],
    [
        78.0109,
        4.214909819047621
    ],
    [
        78.0212,
        2.363650557142858
    ],
    [
        78.0316,
        -5.040031985714284
    ],
    [
        78.042,
        -8.330557561904762
    ],
    [
        78.0523,
        -7.7142612619047615
    ],
    [
        78.0627,
        -1.5499438
    ],
    [
        78.073,
        -2.373478190476191
    ],
    [
        78.0834,
        1.736518280952381
    ],
    [
        78.0938,
        5.848772252380952
    ],
    [
        78.1041,
        6.342892885714285
    ],
    [
        78.1145,
        10.454243857142856
    ],
    [
        78.1248,
        13.746575428571429
    ],
    [
        78.1352,
        16.37791229047619
    ],
    [
        78.1456,
        13.416439271428569
    ],
    [
        78.1559,
        16.871491123809523
    ],
    [
        78.1663,
        17.96833298095238
    ],
    [
        78.1767,
        24.54757813333333
    ],
    [
        78.187,
        20.10482680952381
    ],
    [
        78.1974,
        18.32381329047619
    ],
    [
        78.2077,
        17.382889128571428
    ],
    [
        78.2181,
        18.088001752380954
    ],
    [
        78.2285,
        16.033229266666666
    ],
    [
        78.2388,
        10.480805985714287
    ],
    [
        78.2492,
        12.948700166666667
    ],
    [
        78.2595,
        16.40321021904762
    ],
    [
        78.2699,
        16.815203161904765
    ],
    [
        78.2803,
        16.568413742857143
    ],
    [
        78.2906,
        16.568413742857143
    ],
    [
        78.301,
        15.74713684761905
    ],
    [
        78.3114,
        19.03856541904762
    ],
    [
        78.3217,
        18.873136142857145
    ],
    [
        78.3321,
        19.697573533333333
    ],
    [
        78.3424,
        15.99437776190476
    ],
    [
        78.3528,
        15.830935080952383
    ],
    [
        78.3632,
        17.065559419047617
    ],
    [
        78.3735,
        13.611862066666665
    ],
    [
        78.3839,
        12.788779176190479
    ],
    [
        78.3942,
        9.498253600000002
    ],
    [
        78.4046,
        10.649576352380953
    ],
    [
        78.415,
        12.843260066666666
    ],
    [
        78.4253,
        13.126079114285714
    ],
    [
        78.4357,
        9.954426809523811
    ],
    [
        78.4461,
        12.173635271428571
    ],
    [
        78.4564,
        19.57596331428572
    ],
    [
        78.4668,
        20.398594709523813
    ],
    [
        78.4771,
        19.41197883809524
    ],
    [
        78.4875,
        16.779558380952384
    ],
    [
        78.4979,
        16.614129104761908
    ],
    [
        78.5082,
        17.848076195238097
    ],
    [
        78.5186,
        17.025444804761907
    ],
    [
        78.529,
        10.855709352380952
    ],
    [
        78.5393,
        10.856612352380953
    ],
    [
        78.5497,
        10.032174961904762
    ],
    [
        78.56,
        10.444845157142858
    ],
    [
        78.5704,
        7.978756971428571
    ],
    [
        78.5807,
        10.857289599999998
    ],
    [
        78.5911,
        14.309903357142858
    ],
    [
        78.6015,
        14.721670552380951
    ],
    [
        78.6118,
        14.062481842857142
    ],
    [
        78.6222,
        7.482333690476191
    ],
    [
        78.6326,
        6.454044447619047
    ],
    [
        78.6429,
        5.466886776190476
    ],
    [
        78.6533,
        5.465532276190476
    ],
    [
        78.6636,
        6.944101590476193
    ],
    [
        78.674,
        5.7115089999999995
    ],
    [
        78.6844,
        3.6562850142857144
    ],
    [
        78.6947,
        4.149863852380952
    ],
    [
        78.7051,
        0.20123316190476107
    ],
    [
        78.7154,
        -1.325607857142858
    ],
    [
        78.7258,
        -1.9432586523809536
    ],
    [
        78.7362,
        2.169898314285714
    ],
    [
        78.7465,
        3.9522663333333337
    ],
    [
        78.7569,
        -1.8061534238095245
    ],
    [
        78.7673,
        -2.6269788190476198
    ],
    [
        78.7776,
        -3.5679029809523812
    ],
    [
        78.788,
        -3.1583932809523807
    ],
    [
        78.7983,
        -9.734026438095238
    ],
    [
        78.8087,
        -9.07483772857143
    ],
    [
        78.8191,
        -15.241864185714288
    ],
    [
        78.8294,
        -15.241322390476192
    ],
    [
        78.8398,
        -5.3724546619047615
    ],
    [
        78.8502,
        -5.495849366666667
    ],
    [
        78.8605,
        -6.729119209523809
    ],
    [
        78.8709,
        -3.556499404761903
    ],
    [
        78.8812,
        -6.844315985714285
    ],
    [
        78.8916,
        -7.529842147619046
    ],
    [
        78.902,
        -5.6806146380952365
    ],
    [
        78.9123,
        -8.640733157142856
    ],
    [
        78.9227,
        -2.4709977047619045
    ],
    [
        78.933,
        3.1644852904761906
    ],
    [
        78.9434,
        0.9029262095238098
    ],
    [
        78.9538,
        -3.4165658571428543
    ],
    [
        78.9641,
        -3.142355390476188
    ],
    [
        78.9745,
        0.8073588952380956
    ],
    [
        78.9848,
        0.3137800571428565
    ],
    [
        78.9952,
        -1.6245056571428562
    ],
    [
        79.0056,
        0.8433885238095241
    ],
    [
        79.0159,
        6.478871519047619
    ],
    [
        79.0263,
        4.8336087333333335
    ],
    [
        79.0366,
        4.830899738095239
    ],
    [
        79.047,
        -1.0860865047619046
    ],
    [
        79.0574,
        -4.785218780952381
    ],
    [
        79.0677,
        -2.8122579380952386
    ],
    [
        79.0781,
        3.35409127142857
    ],
    [
        79.0885,
        4.2937254380952385
    ],
    [
        79.0988,
        6.350303923809524
    ],
    [
        79.1092,
        6.788498866666667
    ],
    [
        79.1195,
        1.236075585714285
    ],
    [
        79.1299,
        4.936562357142856
    ],
    [
        79.1403,
        5.347878052380952
    ],
    [
        79.1506,
        -2.0503865000000006
    ],
    [
        79.161,
        2.0600614714285705
    ],
    [
        79.1713,
        2.4309228476190463
    ],
    [
        79.1817,
        6.538661823809524
    ],
    [
        79.1921,
        12.46052426190476
    ],
    [
        79.2024,
        13.775831495238094
    ],
    [
        79.2128,
        16.243725676190472
    ],
    [
        79.2232,
        19.94285795238095
    ],
    [
        79.2335,
        24.17353369047619
    ],
    [
        79.2439,
        24.996165085714285
    ],
    [
        79.2542,
        25.001583076190474
    ],
    [
        79.2646,
        31.16590053809524
    ],
    [
        79.275,
        34.86503281428571
    ],
    [
        79.2853,
        37.3342814952381
    ],
    [
        79.2957,
        33.01749842380952
    ],
    [
        79.306,
        36.308926995238096
    ],
    [
        79.3164,
        37.540165090476194
    ],
    [
        79.3268,
        34.08565503809524
    ],
    [
        79.3371,
        39.56986432857143
    ],
    [
        79.3475,
        36.690654452380954
    ],
    [
        79.3579,
        36.27933875714285
    ],
    [
        79.3682,
        39.36352923333333
    ],
    [
        79.3786,
        39.36623822857142
    ],
    [
        79.3889,
        33.44871018571429
    ],
    [
        79.3993,
        33.45412817619048
    ],
    [
        79.4097,
        30.983524999999997
    ],
    [
        79.42,
        30.161796604761907
    ],
    [
        79.4304,
        33.85821988571428
    ],
    [
        79.4407,
        29.912298190476186
    ],
    [
        79.4511,
        26.62177261428571
    ],
    [
        79.4615,
        23.33395603333333
    ],
    [
        79.4718,
        28.2643264047619
    ],
    [
        79.4822,
        28.016453390476187
    ],
    [
        79.4926,
        18.147585661904763
    ],
    [
        79.5029,
        14.197871376190479
    ],
    [
        79.5133,
        13.581575080952383
    ],
    [
        79.5236,
        11.1123264
    ],
    [
        79.534,
        13.580220580952378
    ],
    [
        79.5444,
        17.281384604761907
    ],
    [
        79.5547,
        17.226542514285715
    ],
    [
        79.5651,
        16.405265619047622
    ],
    [
        79.5754,
        15.993949923809524
    ],
    [
        79.5858,
        20.313441990476193
    ],
    [
        79.5962,
        19.490810595238095
    ],
    [
        79.6065,
        19.485392604761905
    ],
    [
        79.6169,
        17.014789428571426
    ],
    [
        79.6272,
        9.61381588095238
    ],
    [
        79.6376,
        9.407480780952383
    ],
    [
        79.648,
        6.122373195238096
    ],
    [
        79.6583,
        10.06829489047619
    ],
    [
        79.6687,
        10.890926285714286
    ],
    [
        79.6791,
        14.999568261904765
    ],
    [
        79.6894,
        15.616541804761907
    ],
    [
        79.6998,
        13.643310057142859
    ],
    [
        79.7102,
        19.027560180952385
    ],
    [
        79.7205,
        17.054328433333335
    ],
    [
        79.7309,
        12.12124906666667
    ],
    [
        79.7412,
        9.65470938571429
    ],
    [
        79.7516,
        5.269448947619047
    ],
    [
        79.7619,
        6.504073285714286
    ],
    [
        79.7723,
        10.94086481904762
    ],
    [
        79.7827,
        15.052667285714287
    ],
    [
        79.793,
        14.230035890476191
    ],
    [
        79.8034,
        9.842668457142858
    ],
    [
        79.8138,
        9.842668457142858
    ],
    [
        79.8241,
        12.313271633333338
    ],
    [
        79.8345,
        11.495155228571429
    ],
    [
        79.8448,
        15.44486951428572
    ],
    [
        79.8552,
        16.062520309523816
    ],
    [
        79.8656,
        15.121596147619053
    ],
    [
        79.8759,
        11.175674452380951
    ],
    [
        79.8863,
        8.159961342857143
    ],
    [
        79.8966,
        7.339135947619049
    ],
    [
        79.907,
        7.955432247619045
    ],
    [
        79.9174,
        8.94258991904762
    ],
    [
        79.9277,
        10.960667838095238
    ],
    [
        79.9381,
        12.495945438095237
    ],
    [
        79.9485,
        19.898273480952383
    ],
    [
        79.9588,
        20.884347557142856
    ],
    [
        79.9692,
        21.98179141428571
    ],
    [
        79.9795,
        21.98043691428571
    ],
    [
        79.9899,
        18.284690880952375
    ],
    [
        80.0003,
        14.995971304761904
    ],
    [
        80.0106,
        16.64123409047619
    ],
    [
        80.021,
        17.3260830047619
    ],
    [
        80.0313,
        16.228940147619046
    ],
    [
        80.0417,
        17.460178242857143
    ],
    [
        80.0521,
        17.046379304761903
    ],
    [
        80.0624,
        22.96824174285714
    ],
    [
        80.0728,
        26.66466502380952
    ],
    [
        80.0831,
        26.577638571428565
    ],
    [
        80.0935,
        20.657401533333328
    ],
    [
        80.1039,
        26.138299828571423
    ],
    [
        80.1142,
        26.3022843047619
    ],
    [
        80.1246,
        24.246383066666663
    ],
    [
        80.135,
        25.23245714285714
    ],
    [
        80.1453,
        24.411180247619047
    ],
    [
        80.1557,
        24.31176835238095
    ],
    [
        80.166,
        22.82859375238095
    ],
    [
        80.1764,
        20.8553620047619
    ],
    [
        80.1868,
        19.210099219047617
    ],
    [
        80.1971,
        17.978861123809523
    ],
    [
        80.2075,
        17.976829376190476
    ],
    [
        80.2179,
        20.854233257142855
    ],
    [
        80.2282,
        17.565513680952378
    ],
    [
        80.2386,
        18.800138019047616
    ],
    [
        80.2489,
        18.956356714285715
    ],
    [
        80.2593,
        21.83556659047619
    ],
    [
        80.2697,
        21.588370823809523
    ],
    [
        80.28,
        15.007319676190477
    ],
    [
        80.2904,
        5.141160942857143
    ],
    [
        80.3007,
        5.510464642857142
    ],
    [
        80.3111,
        11.676813852380954
    ],
    [
        80.3215,
        7.448847109523809
    ],
    [
        80.3318,
        11.398019600000001
    ],
    [
        80.3422,
        13.864107785714287
    ],
    [
        80.3525,
        11.232770923809525
    ],
    [
        80.3629,
        10.409688033333335
    ],
    [
        80.3733,
        12.42887418095238
    ],
    [
        80.3836,
        8.97761491904762
    ],
    [
        80.394,
        10.344448619047618
    ],
    [
        80.4043,
        9.110501528571428
    ],
    [
        80.4147,
        8.697379838095237
    ],
    [
        80.4251,
        4.996893066666665
    ],
    [
        80.4354,
        4.750103647619049
    ],
    [
        80.4458,
        7.380718109523809
    ],
    [
        80.4562,
        2.447638742857143
    ],
    [
        80.4665,
        2.236878957142856
    ],
    [
        80.4769,
        -4.341463195238096
    ],
    [
        80.4872,
        -4.3409214
    ],
    [
        80.4976,
        -2.695658614285714
    ],
    [
        80.508,
        7.175918109523807
    ],
    [
        80.5183,
        7.423249328571426
    ],
    [
        80.5287,
        6.189979485714288
    ],
    [
        80.5391,
        6.472024533333332
    ],
    [
        80.5494,
        2.770183261904763
    ],
    [
        80.5598,
        2.1133423428571434
    ],
    [
        80.5701,
        2.1142453380952397
    ],
    [
        80.5805,
        1.997178066666667
    ],
    [
        80.5909,
        -1.4567901904761922
    ],
    [
        80.6012,
        1.9955526666666672
    ],
    [
        80.6116,
        4.081061823809525
    ],
    [
        80.6219,
        5.931982457142857
    ],
    [
        80.6323,
        5.109802561904762
    ],
    [
        80.6427,
        5.112511557142857
    ],
    [
        80.653,
        5.112511557142858
    ],
    [
        80.6634,
        4.454045242857144
    ],
    [
        80.6738,
        6.589816671428572
    ],
    [
        80.6841,
        6.47549709047619
    ],
    [
        80.6945,
        16.34436481904762
    ],
    [
        80.7048,
        20.28811931904762
    ],
    [
        80.7152,
        21.275276990476193
    ],
    [
        80.7256,
        11.406409261904763
    ],
    [
        80.7359,
        12.639001852380952
    ],
    [
        80.7463,
        13.87294894285714
    ],
    [
        80.7566,
        13.379099209523806
    ],
    [
        80.767,
        10.911205028571429
    ],
    [
        80.7774,
        10.910663233333334
    ],
    [
        80.7877,
        11.32107593333333
    ],
    [
        80.7981,
        13.083857490476188
    ],
    [
        80.8084,
        15.714291357142853
    ],
    [
        80.8188,
        15.713749557142856
    ],
    [
        80.8292,
        12.425932976190476
    ],
    [
        80.8395,
        12.13801199047619
    ],
    [
        80.8499,
        9.67147230952381
    ],
    [
        80.8603,
        12.136657495238094
    ],
    [
        80.8706,
        11.149499823809522
    ],
    [
        80.881,
        11.807424338095236
    ],
    [
        80.8913,
        12.95901799047619
    ],
    [
        80.9017,
        10.111864552380952
    ],
    [
        80.9121,
        6.410023280952381
    ],
    [
        80.9224,
        2.712922752380951
    ],
    [
        80.9328,
        2.712380952380952
    ],
    [
        80.9431,
        6.41151322857143
    ],
    [
        80.9535,
        4.9310476238095236
    ],
    [
        80.9639,
        2.0536437428571426
    ],
    [
        80.9742,
        2.3829671999999995
    ],
    [
        80.9846,
        3.6169142904761906
    ],
    [
        80.995,
        0.9882864238095236
    ],
    [
        81.0053,
        3.206140390476191
    ],
    [
        81.0157,
        3.191240919047618
    ],
    [
        81.026,
        6.43932557142857
    ],
    [
        81.0364,
        5.4527097
    ],
    [
        81.0468,
        3.837245857142857
    ],
    [
        81.0571,
        3.5081932999999994
    ],
    [
        81.0675,
        5.165646561904761
    ],
    [
        81.0778,
        5.650014814285713
    ],
    [
        81.0882,
        4.682361904761904
    ],
    [
        81.0985,
        8.5258836
    ],
    [
        81.1089,
        2.8871111142857147
    ],
    [
        81.1192,
        2.9507724904761923
    ],
    [
        81.1294,
        1.716825400000001
    ],
    [
        81.1397,
        2.8769523809523814
    ],
    [
        81.15,
        0.6963470904761909
    ],
    [
        81.1602,
        4.3128550285714295
    ],
    [
        81.1704,
        3.3267809523809535
    ],
    [
        81.1806,
        0.22137001904761922
    ],
    [
        81.1907,
        -5.3365841285714275
    ],
    [
        81.2009,
        -4.153430690476189
    ],
    [
        81.211,
        -3.2969368666666674
    ],
    [
        81.2211,
        -1.921309352380953
    ],
    [
        81.2311,
        -4.374304061904761
    ],
    [
        81.2412,
        -13.969563319047618
    ],
    [
        81.2512,
        -13.029542157142854
    ],
    [
        81.2612,
        -9.419129457142855
    ],
    [
        81.2712,
        -10.199319933333332
    ],
    [
        81.2812,
        -10.949711466666665
    ],
    [
        81.2911,
        -10.267180247619043
    ],
    [
        81.3011,
        -11.629262785714284
    ],
    [
        81.311,
        -14.907146385714281
    ],
    [
        81.3209,
        -11.019197180952379
    ],
    [
        81.3308,
        -7.069934395238095
    ],
    [
        81.3406,
        -6.143458204761905
    ],
    [
        81.3505,
        -9.306209523809523
    ],
    [
        81.3604,
        -12.722929099999998
    ],
    [
        81.3702,
        -13.95619894285714
    ],
    [
        81.38,
        -16.252071957142853
    ],
    [
        81.3898,
        -10.357976719047619
    ],
    [
        81.3996,
        -6.276682690476191
    ],
    [
        81.4094,
        -6.3166403619047635
    ],
    [
        81.4191,
        -6.671067166666665
    ],
    [
        81.4289,
        -9.030601557142855
    ],
    [
        81.4386,
        -9.030601557142855
    ],
    [
        81.4483,
        -3.191363461904761
    ],
    [
        81.458,
        0.4061815142857152
    ],
    [
        81.4676,
        -3.0349190142857143
    ],
    [
        81.4773,
        -6.781458695238094
    ],
    [
        81.4869,
        -0.7033775666666661
    ],
    [
        81.4965,
        -2.999927833333332
    ],
    [
        81.5061,
        0.08019914761904826
    ],
    [
        81.5157,
        -2.1160215619047618
    ],
    [
        81.5253,
        -0.5453464285714273
    ],
    [
        81.5348,
        -2.8398649476190485
    ],
    [
        81.5444,
        -2.8691220904761896
    ],
    [
        81.5539,
        0.13921653333333442
    ],
    [
        81.5634,
        4.749925528571429
    ],
    [
        81.5729,
        2.113396428571429
    ],
    [
        81.5824,
        4.409269442857144
    ],
    [
        81.5919,
        1.0399572714285714
    ],
    [
        81.6014,
        -0.5300986619047633
    ],
    [
        81.6108,
        0.5453722380952379
    ],
    [
        81.6203,
        2.2408642999999993
    ],
    [
        81.6297,
        1.3539394333333323
    ],
    [
        81.6391,
        3.5671881095238085
    ],
    [
        81.6485,
        1.5465489571428572
    ],
    [
        81.6579,
        1.4192262047619033
    ],
    [
        81.6673,
        -0.7168161238095256
    ],
    [
        81.6767,
        6.009617738095237
    ],
    [
        81.686,
        3.6880092714285695
    ],
    [
        81.6954,
        3.3593179142857132
    ],
    [
        81.7047,
        -1.7606820857142869
    ],
    [
        81.714,
        -0.38889876666666656
    ],
    [
        81.7233,
        -1.756489595238097
    ],
    [
        81.7326,
        -0.13795555238095214
    ],
    [
        81.7419,
        -2.482680771428573
    ],
    [
        81.7512,
        -5.8052627857142864
    ],
    [
        81.7604,
        -5.263463842857144
    ],
    [
        81.7697,
        -5.218313933333336
    ],
    [
        81.7789,
        -5.759097000000002
    ],
    [
        81.7881,
        -3.1037403857142865
    ],
    [
        81.7972,
        -1.741657842857144
    ],
    [
        81.8064,
        -2.5254603142857146
    ],
    [
        81.8156,
        -3.7965432047619054
    ],
    [
        81.8247,
        -2.4322332714285717
    ],
    [
        81.8338,
        -8.933820571428571
    ],
    [
        81.8429,
        -8.764327804761903
    ],
    [
        81.852,
        -13.174571190476192
    ],
    [
        81.8611,
        -4.50307912857143
    ],
    [
        81.8702,
        -2.4334071714285708
    ],
    [
        81.8792,
        -6.76238071904762
    ],
    [
        81.8883,
        -6.723551795238094
    ],
    [
        81.8973,
        -1.9995165190476192
    ],
    [
        81.9063,
        -3.1986981761904767
    ],
    [
        81.9154,
        1.7583106380952376
    ],
    [
        81.9244,
        2.2988453809523812
    ],
    [
        81.9334,
        2.901686999999999
    ],
    [
        81.9424,
        8.205898642857143
    ],
    [
        81.9513,
        9.770343085714286
    ],
    [
        81.9603,
        12.266230214285716
    ],
    [
        81.9693,
        12.107189647619048
    ],
    [
        81.9782,
        11.67591769047619
    ],
    [
        81.9871,
        12.537919804761904
    ],
    [
        81.996,
        11.10305560952381
    ],
    [
        82.0049,
        13.212459490476189
    ],
    [
        82.0138,
        12.735074423809525
    ],
    [
        82.0227,
        16.187959080952382
    ],
    [
        82.0315,
        17.597087828571425
    ],
    [
        82.0404,
        18.98499611904762
    ],
    [
        82.0492,
        14.473723166666668
    ],
    [
        82.0581,
        10.480845566666666
    ],
    [
        82.0669,
        8.470771495238095
    ],
    [
        82.0757,
        8.854771495238097
    ],
    [
        82.0845,
        10.565953152380954
    ],
    [
        82.0933,
        13.09886320476191
    ],
    [
        82.102,
        8.867413471428572
    ],
    [
        82.1108,
        6.43112422857143
    ],
    [
        82.1196,
        8.302497776190476
    ],
    [
        82.1283,
        2.719259680952382
    ],
    [
        82.137,
        0.6130162952380961
    ],
    [
        82.1458,
        3.649799361904763
    ],
    [
        82.1545,
        4.349622995238096
    ],
    [
        82.1631,
        8.426660033333334
    ],
    [
        82.1718,
        4.360458976190476
    ],
    [
        82.1805,
        5.716762328571429
    ],
    [
        82.1891,
        3.9852632095238114
    ],
    [
        82.1977,
        4.391341514285716
    ],
    [
        82.2064,
        3.1977584428571424
    ],
    [
        82.215,
        1.151564438095238
    ],
    [
        82.2235,
        -2.264026390476191
    ],
    [
        82.2321,
        -1.614425395238096
    ],
    [
        82.2407,
        -2.7003710761904762
    ],
    [
        82.2493,
        1.9462320999999994
    ],
    [
        82.2578,
        1.6362779571428576
    ],
    [
        82.2663,
        -4.452187652380953
    ],
    [
        82.2749,
        -7.087652504761904
    ],
    [
        82.2834,
        -6.513732623809524
    ],
    [
        82.2919,
        -6.487997171428571
    ],
    [
        82.3004,
        -9.516653257142858
    ],
    [
        82.3089,
        -4.152843733333334
    ],
    [
        82.3174,
        -3.1552564333333337
    ],
    [
        82.3259,
        -7.74497071904762
    ],
    [
        82.3344,
        -7.459558776190477
    ],
    [
        82.3429,
        -11.934276238095235
    ],
    [
        82.3513,
        -7.204822976190474
    ],
    [
        82.3598,
        -9.223024038095238
    ],
    [
        82.3682,
        -7.894713633333331
    ],
    [
        82.3766,
        -9.621878357142853
    ],
    [
        82.385,
        -6.60270375238095
    ],
    [
        82.3934,
        -3.9325379666666653
    ],
    [
        82.4018,
        -2.8922839999999996
    ],
    [
        82.4102,
        -4.610238142857143
    ],
    [
        82.4186,
        -7.734250842857142
    ],
    [
        82.4269,
        -7.582772890476189
    ],
    [
        82.4353,
        -8.20132668095238
    ],
    [
        82.4436,
        -4.832240261904762
    ],
    [
        82.452,
        -3.822774233333333
    ],
    [
        82.4603,
        -4.956295019047619
    ],
    [
        82.4686,
        -2.6504890238095244
    ],
    [
        82.4769,
        3.2578284380952374
    ],
    [
        82.4852,
        -2.692478442857143
    ],
    [
        82.4935,
        -3.6900657428571435
    ],
    [
        82.5017,
        -7.081049866666666
    ],
    [
        82.51,
        -6.812665890476192
    ],
    [
        82.5182,
        -6.900234142857142
    ],
    [
        82.5265,
        -3.681496928571429
    ],
    [
        82.5347,
        -2.5353664142857144
    ],
    [
        82.5429,
        -3.9547291428571447
    ],
    [
        82.5512,
        -1.8621210285714294
    ],
    [
        82.5594,
        -3.8146289666666684
    ],
    [
        82.5676,
        -8.977069880952383
    ],
    [
        82.5757,
        -4.150995804761907
    ],
    [
        82.5839,
        -0.9160046238095239
    ],
    [
        82.592,
        4.932714952380952
    ],
    [
        82.6002,
        4.276686733333333
    ],
    [
        82.6083,
        5.603191138095237
    ],
    [
        82.6165,
        7.528383380952381
    ],
    [
        82.6246,
        5.192477442857143
    ],
    [
        82.6327,
        6.702258242857143
    ],
    [
        82.6408,
        4.449277642857142
    ],
    [
        82.6488,
        -1.3560980190476197
    ],
    [
        82.6569,
        1.5411718238095231
    ],
    [
        82.665,
        5.328346428571428
    ],
    [
        82.673,
        8.461569704761905
    ],
    [
        82.681,
        8.66358330952381
    ],
    [
        82.6891,
        9.148831985714285
    ],
    [
        82.6971,
        4.728139619047619
    ],
    [
        82.7051,
        4.998090942857143
    ],
    [
        82.713,
        7.306003933333334
    ],
    [
        82.721,
        10.29727589047619
    ],
    [
        82.729,
        13.1356251
    ],
    [
        82.737,
        16.44446863809524
    ],
    [
        82.7449,
        10.737971280952381
    ],
    [
        82.7529,
        9.564705671428571
    ],
    [
        82.7608,
        5.782949061904762
    ],
    [
        82.7687,
        3.9191607
    ],
    [
        82.7766,
        6.421820314285714
    ],
    [
        82.7846,
        1.4336580523809521
    ],
    [
        82.7924,
        4.2632030285714295
    ],
    [
        82.8003,
        7.024345885714286
    ],
    [
        82.8082,
        7.972494033333335
    ],
    [
        82.8161,
        11.716324719047622
    ],
    [
        82.8239,
        10.307986095238096
    ],
    [
        82.8318,
        7.748889090476191
    ],
    [
        82.8396,
        8.505782214285716
    ],
    [
        82.8475,
        7.016376923809524
    ],
    [
        82.8553,
        7.424231128571429
    ],
    [
        82.8631,
        6.131653652380952
    ],
    [
        82.8709,
        4.1602279
    ],
    [
        82.8787,
        2.8910638809523808
    ],
    [
        82.8865,
        -1.3271795047619035
    ],
    [
        82.8942,
        -2.9604324190476183
    ],
    [
        82.902,
        -2.627183071428572
    ],
    [
        82.9097,
        0.14885925714285722
    ],
    [
        82.9174,
        -0.14648889047619013
    ],
    [
        82.9251,
        1.2672677190476183
    ],
    [
        82.9328,
        4.035860314285713
    ],
    [
        82.9405,
        0.9643118142857139
    ],
    [
        82.9482,
        8.300269490476191
    ],
    [
        82.9559,
        10.987592242857144
    ],
    [
        82.9635,
        7.999029280952382
    ],
    [
        82.9712,
        4.393357323809526
    ],
    [
        82.9788,
        1.3493502714285728
    ],
    [
        82.9864,
        2.7089269904761926
    ],
    [
        82.994,
        2.376172142857145
    ],
    [
        83.0017,
        1.779290309523812
    ],
    [
        83.0093,
        0.7249495666666681
    ],
    [
        83.0169,
        0.31709536190476295
    ],
    [
        83.0245,
        1.2584710238095254
    ],
    [
        83.032,
        6.035331695238095
    ],
    [
        83.0396,
        3.643289366666667
    ],
    [
        83.0472,
        7.655649152380952
    ],
    [
        83.0548,
        7.4129232285714295
    ],
    [
        83.0623,
        8.05815309047619
    ],
    [
        83.0698,
        7.149962614285714
    ],
    [
        83.0774,
        5.8127351
    ],
    [
        83.0849,
        6.768574861904762
    ],
    [
        83.0924,
        5.36067160952381
    ],
    [
        83.0998,
        5.690717461904761
    ],
    [
        83.1073,
        3.8215111095238092
    ],
    [
        83.1148,
        -1.5450074095238102
    ],
    [
        83.1223,
        1.2322539619047617
    ],
    [
        83.1298,
        8.329820099999997
    ],
    [
        83.1372,
        10.403329799999998
    ],
    [
        83.1446,
        8.805564719047618
    ],
    [
        83.1521,
        8.781861014285713
    ],
    [
        83.1594,
        5.88955766190476
    ],
    [
        83.1668,
        7.677494171428569
    ],
    [
        83.1742,
        11.16397036190476
    ],
    [
        83.1816,
        15.52951533333333
    ],
    [
        83.1889,
        11.276393638095236
    ],
    [
        83.1963,
        13.058912157142856
    ],
    [
        83.2036,
        7.772308980952379
    ],
    [
        83.2109,
        8.463102633333332
    ],
    [
        83.2183,
        4.557748138095237
    ],
    [
        83.2256,
        2.8334730047619034
    ],
    [
        83.2329,
        4.86860528095238
    ],
    [
        83.2402,
        2.643940123809523
    ],
    [
        83.2476,
        -0.2960931333333339
    ],
    [
        83.2549,
        0.5802666571428567
    ],
    [
        83.2621,
        -0.025645157142857585
    ],
    [
        83.2694,
        3.439159076190476
    ],
    [
        83.2767,
        -1.2022518571428575
    ],
    [
        83.2839,
        -3.555465261904762
    ],
    [
        83.2912,
        -5.568474080952381
    ],
    [
        83.2985,
        -4.870095242857143
    ],
    [
        83.3057,
        -9.149629633333333
    ],
    [
        83.3129,
        -4.526278661904762
    ],
    [
        83.3202,
        -7.923358028571429
    ],
    [
        83.3274,
        -11.409834219047617
    ],
    [
        83.3346,
        -13.758532628571427
    ],
    [
        83.3418,
        -14.188359785714283
    ],
    [
        83.349,
        -15.870645499999997
    ],
    [
        83.3562,
        -14.140952376190475
    ],
    [
        83.3633,
        -13.991280419047618
    ],
    [
        83.3705,
        -11.08814109047619
    ],
    [
        83.3777,
        -11.039379185714287
    ],
    [
        83.3848,
        -11.521038447619048
    ],
    [
        83.3919,
        -13.147789771428572
    ],
    [
        83.399,
        -10.539705114285715
    ],
    [
        83.4061,
        -10.121842680952382
    ],
    [
        83.4132,
        -12.93332769047619
    ],
    [
        83.4203,
        -15.481137214285715
    ],
    [
        83.4273,
        -16.0030701952381
    ],
    [
        83.4344,
        -16.772123695238097
    ],
    [
        83.4414,
        -16.739615757142865
    ],
    [
        83.4485,
        -18.397520519047628
    ],
    [
        83.4555,
        -14.163361790476193
    ],
    [
        83.4625,
        -16.952723342857144
    ],
    [
        83.4696,
        -14.216096885714286
    ],
    [
        83.4766,
        -13.8494796
    ],
    [
        83.4836,
        -16.626199176190475
    ],
    [
        83.4906,
        -17.14858365714286
    ],
    [
        83.4976,
        -17.110657733333333
    ],
    [
        83.5045,
        -16.017126871428573
    ],
    [
        83.5115,
        -20.1273490952381
    ],
    [
        83.5185,
        -17.934869376190477
    ],
    [
        83.5254,
        -16.912765671428573
    ],
    [
        83.5324,
        -16.76769900476191
    ],
    [
        83.5393,
        -16.678302176190478
    ],
    [
        83.5462,
        -16.659339214285716
    ],
    [
        83.5532,
        -20.327995300000005
    ],
    [
        83.5601,
        -17.845878899999995
    ],
    [
        83.567,
        -13.770196361904757
    ],
    [
        83.5739,
        -12.570498704761901
    ],
    [
        83.5807,
        -12.535131271428568
    ],
    [
        83.5876,
        -13.047582771428571
    ],
    [
        83.5945,
        -11.389678009523811
    ],
    [
        83.6013,
        -11.410672719047618
    ],
    [
        83.6081,
        -10.56072562857143
    ],
    [
        83.615,
        -9.900272719047619
    ],
    [
        83.6218,
        -11.876032861904761
    ],
    [
        83.6287,
        -11.829979952380953
    ],
    [
        83.6355,
        -9.726445561904761
    ],
    [
        83.6423,
        -8.08208577142857
    ],
    [
        83.6491,
        -10.24025155238095
    ],
    [
        83.6559,
        -8.038741852380952
    ],
    [
        83.6627,
        -6.472942909523808
    ],
    [
        83.6695,
        -5.178585238095236
    ],
    [
        83.6762,
        -6.392892114285711
    ],
    [
        83.683,
        -2.6016540190476185
    ],
    [
        83.6897,
        -4.979474123809522
    ],
    [
        83.6965,
        -4.911749252380952
    ],
    [
        83.7032,
        -7.318013799999999
    ],
    [
        83.7099,
        -7.396574647619045
    ],
    [
        83.7166,
        -5.350896642857141
    ],
    [
        83.7234,
        -7.3379442619047595
    ],
    [
        83.7301,
        -7.300018338095237
    ],
    [
        83.7368,
        -6.773570366666665
    ],
    [
        83.7435,
        -8.634423980952379
    ],
    [
        83.7502,
        -10.98515414285714
    ],
    [
        83.757,
        -9.409873719047617
    ],
    [
        83.7636,
        -8.429759433333333
    ],
    [
        83.7703,
        -6.303198585714286
    ],
    [
        83.777,
        -8.328172128571428
    ],
    [
        83.7836,
        -11.462479004761903
    ],
    [
        83.7903,
        -8.834754138095239
    ],
    [
        83.7969,
        -10.887269128571429
    ],
    [
        83.8035,
        -13.69717389047619
    ],
    [
        83.8102,
        -14.338122038095237
    ],
    [
        83.8168,
        -14.381272452380951
    ],
    [
        83.8234,
        -14.397074923809523
    ],
    [
        83.83,
        -11.821362752380951
    ],
    [
        83.8366,
        -8.63829397142857
    ],
    [
        83.8432,
        -8.073468576190479
    ],
    [
        83.8498,
        -10.7233169
    ],
    [
        83.8563,
        -13.32846680952381
    ],
    [
        83.8629,
        -12.259316900000002
    ],
    [
        83.8694,
        -11.701715485714287
    ],
    [
        83.876,
        -11.614305257142856
    ],
    [
        83.8825,
        -9.945383919047622
    ],
    [
        83.889,
        -7.554696085714286
    ],
    [
        83.8955,
        -8.619331004761907
    ],
    [
        83.902,
        -7.9903024333333335
    ],
    [
        83.9084,
        -5.465519366666668
    ],
    [
        83.9149,
        -4.507889738095237
    ],
    [
        83.9214,
        -2.1354876238095244
    ],
    [
        83.9278,
        -3.1915440619047617
    ],
    [
        83.9343,
        -0.5760096714285717
    ],
    [
        83.9407,
        -0.5323755095238097
    ],
    [
        83.9471,
        -1.1375649285714289
    ],
    [
        83.9536,
        -0.6948377952380959
    ],
    [
        83.96,
        0.2948482714285713
    ],
    [
        83.9664,
        0.3094768428571424
    ],
    [
        83.9728,
        0.6863657333333328
    ],
    [
        83.9792,
        0.20822816666666658
    ],
    [
        83.9856,
        -3.3075954666666667
    ],
    [
        83.992,
        -1.2857824190476186
    ],
    [
        83.9984,
        -1.2722374428571426
    ],
    [
        84.0048,
        2.738880719047619
    ],
    [
        84.0111,
        2.12502251904762
    ],
    [
        84.0175,
        4.49701829047619
    ],
    [
        84.0238,
        2.279706119047619
    ],
    [
        84.0301,
        2.0992569714285714
    ],
    [
        84.0365,
        1.7696626142857141
    ],
    [
        84.0428,
        -4.247014633333333
    ],
    [
        84.0491,
        -2.895903523809524
    ],
    [
        84.0554,
        -2.133998761904762
    ],
    [
        84.0617,
        -2.641032271428571
    ],
    [
        84.0679,
        0.8169994761904756
    ],
    [
        84.0742,
        -2.5715659047619055
    ],
    [
        84.0805,
        -1.9663764857142858
    ],
    [
        84.0867,
        -2.336154261904762
    ],
    [
        84.093,
        -3.2523362714285713
    ],
    [
        84.0992,
        -2.648772252380953
    ],
    [
        84.1054,
        -2.5339786047619053
    ],
    [
        84.1117,
        -1.617525695238096
    ],
    [
        84.1179,
        -1.5498008285714295
    ],
    [
        84.1241,
        -1.5714727857142872
    ],
    [
        84.1303,
        -1.6556322238095245
    ],
    [
        84.1365,
        -3.180796242857144
    ],
    [
        84.1427,
        -2.2118791333333343
    ],
    [
        84.1489,
        -8.10936061904762
    ],
    [
        84.1551,
        -3.673381780952382
    ],
    [
        84.1613,
        -1.091408823809525
    ],
    [
        84.1675,
        -1.1762906571428584
    ],
    [
        84.1737,
        0.34074638095237997
    ],
    [
        84.1798,
        0.17725855238095137
    ],
    [
        84.186,
        -0.3058455047619058
    ],
    [
        84.1921,
        0.05614391428571325
    ],
    [
        84.1983,
        -3.8601594380952386
    ],
    [
        84.2044,
        -0.9046462095238099
    ],
    [
        84.2105,
        -0.18473086666666705
    ],
    [
        84.2166,
        -0.295348152380953
    ],
    [
        84.2227,
        0.072713928571428
    ],
    [
        84.2288,
        2.9415393238095233
    ],
    [
        84.2349,
        5.318682180952381
    ],
    [
        84.241,
        7.340134033333333
    ],
    [
        84.2471,
        9.796740738095238
    ],
    [
        84.2531,
        9.390752733333333
    ],
    [
        84.2592,
        10.54478448095238
    ],
    [
        84.2653,
        8.725694533333332
    ],
    [
        84.2713,
        9.181708642857142
    ],
    [
        84.2774,
        10.671655733333331
    ],
    [
        84.2834,
        12.065433509523807
    ],
    [
        84.2894,
        11.989581657142857
    ],
    [
        84.2955,
        11.694978480952377
    ],
    [
        84.3015,
        15.983317104761904
    ],
    [
        84.3075,
        16.112942500000003
    ],
    [
        84.3135,
        15.650607404761905
    ],
    [
        84.3195,
        15.288617985714287
    ],
    [
        84.3255,
        19.080307580952383
    ],
    [
        84.3315,
        20.021231742857143
    ],
    [
        84.3374,
        17.893993647619048
    ],
    [
        84.3434,
        19.31260387619048
    ],
    [
        84.3494,
        17.793219042857146
    ],
    [
        84.3553,
        15.324937866666671
    ],
    [
        84.3613,
        12.22313892857143
    ],
    [
        84.3672,
        12.185213004761904
    ],
    [
        84.3732,
        9.864056038095237
    ],
    [
        84.3791,
        9.507635685714286
    ],
    [
        84.3851,
        8.58969282857143
    ],
    [
        84.391,
        10.338348914285714
    ],
    [
        84.3969,
        7.988747499999999
    ],
    [
        84.4028,
        12.179562314285713
    ],
    [
        84.4087,
        6.566525276190478
    ],
    [
        84.4146,
        2.3499750095238094
    ],
    [
        84.4205,
        3.059054376190475
    ],
    [
        84.4263,
        0.9135305666666669
    ],
    [
        84.4322,
        0.20580570000000062
    ],
    [
        84.4381,
        -0.22221546666666572
    ],
    [
        84.4439,
        0.3266268619047628
    ],
    [
        84.4497,
        -2.892045852380952
    ],
    [
        84.4556,
        -3.2857530809523805
    ],
    [
        84.4614,
        -3.2383456714285717
    ],
    [
        84.4672,
        -5.540539676190476
    ],
    [
        84.473,
        -4.2979237999999995
    ],
    [
        84.4788,
        -5.2418923571428575
    ],
    [
        84.4846,
        -4.330315638095239
    ],
    [
        84.4904,
        -3.0137442095238085
    ],
    [
        84.4961,
        -1.572559023809524
    ],
    [
        84.5019,
        -1.109946080952381
    ],
    [
        84.5076,
        -1.6637097476190479
    ],
    [
        84.5134,
        -5.762418742857143
    ],
    [
        84.5191,
        -7.535907276190477
    ],
    [
        84.5248,
        -11.650870238095239
    ],
    [
        84.5305,
        -6.197663885714286
    ],
    [
        84.5362,
        -3.4737697047619043
    ],
    [
        84.5419,
        -3.4737697047619043
    ],
    [
        84.5476,
        -4.164563357142858
    ],
    [
        84.5533,
        -3.4967961619047623
    ],
    [
        84.559,
        -2.4565421904761897
    ],
    [
        84.5647,
        -2.6245772619047614
    ],
    [
        84.5704,
        -0.9026241238095228
    ],
    [
        84.5761,
        -1.8923101952380954
    ],
    [
        84.5817,
        -1.1960985571428564
    ],
    [
        84.5873,
        1.4925786952380953
    ],
    [
        84.593,
        1.4715839857142865
    ],
    [
        84.5986,
        1.354013614285714
    ],
    [
        84.6043,
        1.116525076190477
    ],
    [
        84.6099,
        -1.9246601095238087
    ],
    [
        84.6155,
        -0.03974159047618997
    ],
    [
        84.6211,
        -1.3759532285714284
    ],
    [
        84.6266,
        -0.1821895619047615
    ],
    [
        84.6322,
        2.285212071428571
    ],
    [
        84.6378,
        3.668153866666667
    ],
    [
        84.6434,
        5.306794666666667
    ],
    [
        84.6489,
        3.931979852380953
    ],
    [
        84.6545,
        2.2608687428571423
    ],
    [
        84.66,
        2.6970168904761898
    ],
    [
        84.6656,
        0.09096398095238033
    ],
    [
        84.6711,
        -1.2289936904761907
    ],
    [
        84.6766,
        -0.3740349619047625
    ],
    [
        84.6822,
        -0.7548422190476195
    ],
    [
        84.6877,
        -3.3812125904761903
    ],
    [
        84.6932,
        -2.9387434523809524
    ],
    [
        84.6987,
        -1.4099674380952383
    ],
    [
        84.7042,
        -3.597029166666667
    ],
    [
        84.7097,
        -3.613283138095239
    ],
    [
        84.7152,
        -2.9522884285714293
    ],
    [
        84.7207,
        -2.750016823809524
    ],
    [
        84.7262,
        -2.6540014333333337
    ],
    [
        84.7317,
        -2.7550469380952385
    ],
    [
        84.7371,
        -2.4164226000000006
    ],
    [
        84.7426,
        -2.2818758619047625
    ],
    [
        84.748,
        -2.0429917809523817
    ],
    [
        84.7534,
        -2.0118383380952385
    ],
    [
        84.7589,
        -1.0213997714285714
    ],
    [
        84.7643,
        -1.906338042857143
    ],
    [
        84.7697,
        -1.8630317523809528
    ],
    [
        84.7751,
        -1.0164709047619045
    ],
    [
        84.7805,
        2.8668730095238097
    ],
    [
        84.7859,
        4.371493819047619
    ],
    [
        84.7913,
        3.3393668333333335
    ],
    [
        84.7967,
        4.189991171428572
    ],
    [
        84.802,
        4.211663128571429
    ],
    [
        84.8074,
        3.787253957142857
    ],
    [
        84.8128,
        3.771902985714286
    ],
    [
        84.8182,
        4.888099104761905
    ],
    [
        84.8236,
        3.9626011619047627
    ],
    [
        84.829,
        3.9626011619047627
    ],
    [
        84.8343,
        5.843997985714286
    ],
    [
        84.8397,
        6.077464076190477
    ],
    [
        84.8451,
        3.1287233333333337
    ],
    [
        84.8504,
        4.077548728571429
    ],
    [
        84.8558,
        6.161668657142857
    ],
    [
        84.8611,
        7.413224214285714
    ],
    [
        84.8665,
        8.196665480952381
    ],
    [
        84.8718,
        6.913956485714286
    ],
    [
        84.8771,
        6.486386819047619
    ],
    [
        84.8824,
        6.770530261904763
    ],
    [
        84.8877,
        6.316773647619046
    ],
    [
        84.893,
        5.039482642857142
    ],
    [
        84.8983,
        2.949041728571429
    ],
    [
        84.9036,
        3.9535369666666664
    ],
    [
        84.9089,
        2.8555814095238095
    ],
    [
        84.9141,
        5.36004701904762
    ],
    [
        84.9194,
        8.246932380952382
    ],
    [
        84.9246,
        9.85697823809524
    ],
    [
        84.9299,
        10.218087233333335
    ],
    [
        84.9351,
        10.008651842857144
    ],
    [
        84.9403,
        10.008651842857144
    ],
    [
        84.9455,
        7.483868776190476
    ],
    [
        84.9507,
        8.301082180952381
    ],
    [
        84.9559,
        10.00774884761905
    ],
    [
        84.9611,
        11.822775304761905
    ],
    [
        84.9663,
        6.865314985714287
    ],
    [
        84.9714,
        5.613759428571428
    ],
    [
        84.9766,
        6.723228214285714
    ],
    [
        84.9818,
        6.316879009523809
    ],
    [
        84.9869,
        6.316879009523809
    ],
    [
        84.9921,
        8.741429276190477
    ],
    [
        84.9972,
        8.722466314285715
    ],
    [
        85.0024,
        7.9142828904761915
    ],
    [
        85.0075,
        7.940018338095239
    ],
    [
        85.0126,
        8.655463842857142
    ],
    [
        85.0178,
        9.38607971904762
    ],
    [
        85.0229,
        7.751201414285716
    ],
    [
        85.028,
        5.633154552380951
    ],
    [
        85.0331,
        2.7720046428571425
    ],
    [
        85.0382,
        2.959828276190476
    ],
    [
        85.0433,
        5.850867433333334
    ],
    [
        85.0484,
        5.053519990476191
    ],
    [
        85.0535,
        4.654846271428572
    ],
    [
        85.0586,
        4.0545987142857145
    ],
    [
        85.0637,
        4.572016704761905
    ],
    [
        85.0688,
        4.501582842857142
    ],
    [
        85.0739,
        4.5693077142857135
    ],
    [
        85.0789,
        5.361237166666665
    ],
    [
        85.084,
        4.752390604761904
    ],
    [
        85.089,
        3.9735546238095245
    ],
    [
        85.0941,
        3.181625171428572
    ],
    [
        85.0991,
        -0.4281102809523819
    ],
    [
        85.1041,
        -0.9747252142857145
    ],
    [
        85.1091,
        1.0186433952380956
    ],
    [
        85.1141,
        1.049796833333333
    ],
    [
        85.1191,
        -0.4210367952380954
    ],
    [
        85.1241,
        -0.31443785238095334
    ],
    [
        85.1291,
        -0.6936971142857143
    ],
    [
        85.1341,
        0.1402862571428573
    ],
    [
        85.1391,
        0.8032374619047619
    ],
    [
        85.1441,
        0.0004720285714279288
    ],
    [
        85.149,
        -2.7344516333333337
    ],
    [
        85.154,
        -1.9371041904761899
    ],
    [
        85.159,
        0.7967229666666666
    ],
    [
        85.164,
        -0.005385738095237664
    ],
    [
        85.1689,
        0.6826989190476193
    ],
    [
        85.1739,
        -1.950443938095238
    ],
    [
        85.1788,
        0.7620337619047622
    ],
    [
        85.1837,
        -0.613684052380953
    ],
    [
        85.1887,
        -0.1455697666666674
    ],
    [
        85.1936,
        3.372059861904762
    ],
    [
        85.1985,
        2.99776709047619
    ],
    [
        85.2035,
        4.764031642857142
    ],
    [
        85.2084,
        4.210794723809523
    ],
    [
        85.2133,
        3.4888476333333323
    ],
    [
        85.2182,
        6.986159804761904
    ],
    [
        85.2231,
        9.55819978095238
    ],
    [
        85.228,
        8.19986468095238
    ],
    [
        85.2329,
        10.134086904761904
    ],
    [
        85.2378,
        6.655737695238096
    ],
    [
        85.2427,
        5.245555447619046
    ],
    [
        85.2476,
        6.012200952380951
    ],
    [
        85.2525,
        5.5794068
    ],
    [
        85.2574,
        6.039935900000001
    ],
    [
        85.2622,
        2.5561687047619053
    ],
    [
        85.2671,
        1.9940523047619054
    ],
    [
        85.272,
        3.8984755857142863
    ],
    [
        85.2768,
        4.412582580952382
    ],
    [
        85.2816,
        3.825160728571429
    ],
    [
        85.2865,
        3.2698168142857145
    ],
    [
        85.2913,
        3.935416814285714
    ],
    [
        85.2961,
        2.1711840095238095
    ],
    [
        85.301,
        1.0726866571428568
    ],
    [
        85.3058,
        -0.0745726047619045
    ],
    [
        85.3106,
        0.9674873571428567
    ],
    [
        85.3154,
        -0.624047033333334
    ],
    [
        85.3202,
        -3.3145302761904762
    ],
    [
        85.3251,
        -5.063186361904762
    ],
    [
        85.3298,
        -4.294734861904763
    ],
    [
        85.3346,
        -3.194883009523809
    ],
    [
        85.3394,
        0.22928630476190462
    ],
    [
        85.3442,
        1.8254259857142858
    ],
    [
        85.3489,
        0.5007275714285714
    ],
    [
        85.3537,
        0.15623374285714303
    ],
    [
        85.3584,
        -0.30429535714285716
    ],
    [
        85.3631,
        3.0629850666666663
    ],
    [
        85.3679,
        1.9969956476190485
    ],
    [
        85.3726,
        1.9157258047619046
    ],
    [
        85.3773,
        -0.538021404761905
    ],
    [
        85.382,
        -0.56022010952381
    ],
    [
        85.3868,
        1.019394704761905
    ],
    [
        85.3915,
        0.992304757142857
    ],
    [
        85.3961,
        -0.3127534428571434
    ],
    [
        85.4008,
        2.319937914285714
    ],
    [
        85.4055,
        2.442926276190477
    ],
    [
        85.4101,
        3.8958504428571423
    ],
    [
        85.4148,
        5.269160261904762
    ],
    [
        85.4194,
        5.399342504761905
    ],
    [
        85.4241,
        4.837226104761905
    ],
    [
        85.4287,
        2.6402313952380956
    ],
    [
        85.4333,
        0.9383054666666665
    ],
    [
        85.438,
        -0.18592733809523843
    ],
    [
        85.4426,
        -0.6334532619047623
    ],
    [
        85.4472,
        0.6515132285714282
    ],
    [
        85.4519,
        1.2183703714285712
    ],
    [
        85.4565,
        0.6745396857142855
    ],
    [
        85.4612,
        -1.302424452380953
    ],
    [
        85.4658,
        0.1875226380952378
    ],
    [
        85.4704,
        -2.385570833333334
    ],
    [
        85.475,
        0.17488066666666677
    ],
    [
        85.4796,
        -0.6303680142857141
    ],
    [
        85.4842,
        0.008683838095238391
    ],
    [
        85.4888,
        -1.8149210999999994
    ],
    [
        85.4934,
        -0.003958138095237797
    ],
    [
        85.498,
        -1.0880075190476186
    ],
    [
        85.5025,
        0.4279459190476193
    ],
    [
        85.5071,
        -1.4209429714285715
    ],
    [
        85.5117,
        -2.093435861904762
    ],
    [
        85.5162,
        -0.784690419047619
    ],
    [
        85.5207,
        -1.4944470333333333
    ],
    [
        85.5253,
        -1.4267221666666667
    ],
    [
        85.5298,
        -1.5501168761904767
    ],
    [
        85.5343,
        -2.60933380952381
    ],
    [
        85.5388,
        -3.6726142333333334
    ],
    [
        85.5433,
        -3.8692421000000015
    ],
    [
        85.5478,
        -3.676677723809524
    ],
    [
        85.5523,
        -2.0749846047619047
    ],
    [
        85.5568,
        -2.0136667238095245
    ],
    [
        85.5613,
        -1.5922245476190482
    ],
    [
        85.5658,
        -1.2323797523809532
    ],
    [
        85.5702,
        -1.3290005619047625
    ],
    [
        85.5747,
        -0.42306758095238145
    ],
    [
        85.5792,
        -1.202677557142858
    ],
    [
        85.5836,
        -1.5354324047619052
    ],
    [
        85.5881,
        -3.027817585714287
    ],
    [
        85.5925,
        -1.7882267571428574
    ],
    [
        85.597,
        -2.8609886619047624
    ],
    [
        85.6014,
        -1.1285865476190482
    ],
    [
        85.6059,
        -2.7897421000000007
    ],
    [
        85.6103,
        -4.895985485714285
    ],
    [
        85.6147,
        -3.490017233333334
    ],
    [
        85.6191,
        -1.8808743761904765
    ],
    [
        85.6235,
        -1.6141286952380955
    ],
    [
        85.628,
        -2.63000170952381
    ],
    [
        85.6323,
        -1.1527868952380953
    ],
    [
        85.6367,
        -2.7179085904761906
    ],
    [
        85.6411,
        -2.659794204761905
    ],
    [
        85.6455,
        -3.5115021428571436
    ],
    [
        85.6499,
        -4.1594678809523815
    ],
    [
        85.6543,
        -5.913735457142857
    ],
    [
        85.6587,
        -4.882962971428571
    ],
    [
        85.663,
        -4.631703714285714
    ],
    [
        85.6674,
        -4.750093233333334
    ],
    [
        85.6718,
        -4.365544985714286
    ],
    [
        85.6761,
        -1.2596825523809525
    ],
    [
        85.6805,
        -0.4991774380952375
    ],
    [
        85.6848,
        -1.2088724857142854
    ],
    [
        85.6891,
        0.8499634952380956
    ],
    [
        85.6935,
        -0.7266714238095242
    ],
    [
        85.6978,
        2.388672490476191
    ],
    [
        85.7021,
        1.3849899523809521
    ],
    [
        85.7064,
        0.010626638095238608
    ],
    [
        85.7107,
        0.869377961904762
    ],
    [
        85.7151,
        -0.8350312095238094
    ],
    [
        85.7194,
        -0.78356030952381
    ],
    [
        85.7237,
        -1.6022185095238097
    ],
    [
        85.728,
        -0.3140915238095235
    ],
    [
        85.7323,
        -0.24443166190476198
    ],
    [
        85.7366,
        0.2239535238095241
    ],
    [
        85.7409,
        0.9741644619047614
    ],
    [
        85.7452,
        2.625567638095239
    ],
    [
        85.7495,
        2.2684318380952373
    ],
    [
        85.7538,
        1.0830209047619053
    ],
    [
        85.758,
        3.3810224190476186
    ],
    [
        85.7623,
        3.363865452380953
    ],
    [
        85.7665,
        1.3077384666666665
    ],
    [
        85.7708,
        0.6798966904761905
    ],
    [
        85.775,
        2.8626076095238098
    ],
    [
        85.7793,
        0.8037716285714287
    ],
    [
        85.7835,
        1.2886816809523798
    ],
    [
        85.7878,
        -1.723720438095238
    ],
    [
        85.792,
        0.32157056666666745
    ],
    [
        85.7962,
        1.6625229476190482
    ],
    [
        85.8004,
        1.32389860952381
    ],
    [
        85.8046,
        2.0056622761904768
    ],
    [
        85.8089,
        4.688470038095239
    ],
    [
        85.8131,
        6.08062241904762
    ],
    [
        85.8173,
        5.827331414285714
    ],
    [
        85.8214,
        6.709560690476191
    ],
    [
        85.8256,
        4.076417833333333
    ],
    [
        85.8298,
        3.3447183619047616
    ],
    [
        85.834,
        4.028855623809524
    ],
    [
        85.8381,
        2.3772718476190478
    ],
    [
        85.8423,
        3.047296538095238
    ],
    [
        85.8465,
        2.0151695523809527
    ],
    [
        85.8506,
        0.9572168190476196
    ],
    [
        85.8548,
        2.9076930095238103
    ],
    [
        85.8589,
        5.141065647619048
    ],
    [
        85.8631,
        5.086885752380953
    ],
    [
        85.8672,
        6.056705857142857
    ],
    [
        85.8713,
        4.0886212
    ],
    [
        85.8754,
        7.041425433333333
    ],
    [
        85.8795,
        6.7993090333333335
    ],
    [
        85.8836,
        6.379640604761906
    ],
    [
        85.8877,
        6.379640604761906
    ],
    [
        85.8918,
        7.674088576190477
    ],
    [
        85.8959,
        6.533376052380953
    ],
    [
        85.9,
        4.585608857142857
    ],
    [
        85.9041,
        3.622561238095238
    ],
    [
        85.9081,
        3.6072102714285714
    ],
    [
        85.9122,
        7.483781700000001
    ],
    [
        85.9163,
        7.426621909523809
    ],
    [
        85.9203,
        9.059758723809523
    ],
    [
        85.9244,
        11.942129095238094
    ],
    [
        85.9285,
        12.897049728571428
    ],
    [
        85.9325,
        13.828943909523808
    ],
    [
        85.9366,
        12.315157666666664
    ],
    [
        85.9406,
        10.364681476190473
    ],
    [
        85.9447,
        6.520617985714285
    ],
    [
        85.9487,
        3.9461700142857126
    ],
    [
        85.9527,
        3.2140641952380946
    ],
    [
        85.9568,
        3.7131964714285703
    ],
    [
        85.9608,
        3.123719223809523
    ],
    [
        85.9648,
        2.423105466666666
    ],
    [
        85.9688,
        1.5532924142857134
    ],
    [
        85.9728,
        3.4333347428571415
    ],
    [
        85.9768,
        1.5289114666666654
    ],
    [
        85.9808,
        2.0104352761904747
    ],
    [
        85.9847,
        3.5956486809523804
    ],
    [
        85.9887,
        2.6840719619047606
    ],
    [
        85.9927,
        1.4171654333333314
    ],
    [
        85.9967,
        0.1231689619047617
    ],
    [
        86.0007,
        -0.12082116190476294
    ],
    [
        86.0046,
        -2.655537214285715
    ],
    [
        86.0086,
        -3.93327971904762
    ],
    [
        86.0125,
        -3.954951676190477
    ],
    [
        86.0165,
        -6.791269138095241
    ],
    [
        86.0204,
        -4.886845857142857
    ],
    [
        86.0243,
        -4.577117461904762
    ],
    [
        86.0283,
        -2.9896465619047623
    ],
    [
        86.0322,
        -2.1730652571428575
    ],
    [
        86.0361,
        -2.5429397857142866
    ],
    [
        86.0401,
        -1.6103683571428578
    ],
    [
        86.044,
        -1.0655895190476194
    ],
    [
        86.048,
        -0.5813567142857143
    ],
    [
        86.0519,
        0.6584598666666662
    ],
    [
        86.0558,
        -2.1426406619047618
    ],
    [
        86.0597,
        -0.28562479047619066
    ],
    [
        86.0636,
        1.545655633333333
    ],
    [
        86.0675,
        0.5550665666666662
    ],
    [
        86.0714,
        2.7964887904761904
    ],
    [
        86.0753,
        3.4213635714285724
    ],
    [
        86.0791,
        2.7973917904761905
    ],
    [
        86.083,
        5.248129004761904
    ],
    [
        86.0869,
        6.783226009523808
    ],
    [
        86.0907,
        5.792456342857142
    ],
    [
        86.0946,
        5.314996028571429
    ],
    [
        86.0984,
        6.24689020952381
    ],
    [
        86.1023,
        8.059207671428572
    ],
    [
        86.1061,
        8.354488095238095
    ],
    [
        86.11,
        8.404797995238095
    ],
    [
        86.1138,
        9.1210562
    ],
    [
        86.1177,
        9.253216442857143
    ],
    [
        86.1215,
        8.341639723809525
    ],
    [
        86.1253,
        7.778981519047619
    ],
    [
        86.1291,
        6.430579399999999
    ],
    [
        86.133,
        5.817443595238095
    ],
    [
        86.1368,
        4.947856290476192
    ],
    [
        86.1406,
        4.389532480952381
    ],
    [
        86.1444,
        0.7676065523809538
    ],
    [
        86.1482,
        0.8775143380952384
    ],
    [
        86.152,
        0.9583778285714295
    ],
    [
        86.1558,
        1.850991585714287
    ],
    [
        86.1595,
        1.552550666666668
    ],
    [
        86.1633,
        0.02512915238095356
    ],
    [
        86.1671,
        -1.4801689095238093
    ],
    [
        86.1709,
        -1.559677904761904
    ],
    [
        86.1747,
        -1.4230994238095236
    ],
    [
        86.1784,
        -1.4230994238095236
    ],
    [
        86.1822,
        -2.9396849619047614
    ],
    [
        86.186,
        -3.8995720857142855
    ],
    [
        86.1897,
        -3.8941540952380946
    ],
    [
        86.1935,
        -4.573434519047619
    ],
    [
        86.1972,
        -4.573434519047619
    ],
    [
        86.201,
        -4.710238752380953
    ],
    [
        86.2047,
        -4.185506476190476
    ],
    [
        86.2084,
        -0.6123424523809514
    ],
    [
        86.2121,
        -1.0511995952380944
    ],
    [
        86.2159,
        1.1789803000000003
    ],
    [
        86.2196,
        1.6966691904761906
    ],
    [
        86.2233,
        3.1947432666666664
    ],
    [
        86.227,
        3.7417666999999994
    ],
    [
        86.2308,
        2.4191000333333337
    ],
    [
        86.2345,
        -0.22623330000000003
    ],
    [
        86.2382,
        0.3625215523809527
    ],
    [
        86.2419,
        -0.3693585190476189
    ],
    [
        86.2456,
        0.8027331904761904
    ],
    [
        86.2493,
        0.9569201428571426
    ],
    [
        86.253,
        -1.3610763285714282
    ],
    [
        86.2567,
        -3.0894149523809524
    ],
    [
        86.2604,
        -3.1704590476190475
    ],
    [
        86.264,
        -3.679750057142857
    ],
    [
        86.2677,
        -3.712451495238095
    ],
    [
        86.2714,
        -3.688554295238095
    ],
    [
        86.275,
        -3.9335248190476184
    ],
    [
        86.2787,
        -2.7758810809523813
    ],
    [
        86.2824,
        -4.2292567428571415
    ],
    [
        86.286,
        -4.291563623809522
    ],
    [
        86.2896,
        -3.85270648095238
    ],
    [
        86.2933,
        -5.144219709523808
    ],
    [
        86.2969,
        -5.678162566666668
    ],
    [
        86.3005,
        -7.089548809523811
    ],
    [
        86.3041,
        -9.079305423809526
    ],
    [
        86.3077,
        -7.962425604761904
    ],
    [
        86.3113,
        -6.2097060285714285
    ],
    [
        86.3149,
        -8.17959668095238
    ],
    [
        86.3185,
        -7.953395623809523
    ],
    [
        86.3221,
        -7.9741645809523805
    ],
    [
        86.3256,
        -7.26350497142857
    ],
    [
        86.3292,
        -5.5378753428571414
    ],
    [
        86.3328,
        -4.231462642857143
    ],
    [
        86.3364,
        -6.123018195238095
    ],
    [
        86.34,
        -4.698989976190476
    ],
    [
        86.3435,
        -4.832407966666667
    ],
    [
        86.3471,
        -4.798287533333332
    ],
    [
        86.3506,
        -4.886523357142856
    ],
    [
        86.3541,
        -5.790063390476189
    ],
    [
        86.3576,
        -4.982782966666666
    ],
    [
        86.3612,
        -5.020708890476191
    ],
    [
        86.3647,
        -5.57514980952381
    ],
    [
        86.3682,
        -3.062557214285713
    ],
    [
        86.3718,
        -5.060169914285713
    ],
    [
        86.3753,
        -3.148974147619046
    ],
    [
        86.3788,
        -1.7272034238095229
    ],
    [
        86.3823,
        -1.969077952380952
    ],
    [
        86.3858,
        -2.7966758380952377
    ],
    [
        86.3893,
        -0.842136157142857
    ],
    [
        86.3928,
        -1.1785029999999996
    ],
    [
        86.3963,
        -2.1485488571428566
    ],
    [
        86.3998,
        -2.569797533333333
    ],
    [
        86.4033,
        -4.206030338095238
    ],
    [
        86.4068,
        -2.957861028571428
    ],
    [
        86.4103,
        -1.8282102380952374
    ],
    [
        86.4138,
        -2.182185542857143
    ],
    [
        86.4172,
        -2.5797305142857136
    ],
    [
        86.4207,
        -3.4064254
    ],
    [
        86.4242,
        -2.5269051190476195
    ],
    [
        86.4276,
        -2.601537923809524
    ],
    [
        86.4311,
        -2.295421519047619
    ],
    [
        86.4345,
        -4.505961204761904
    ],
    [
        86.438,
        -3.543816580952382
    ],
    [
        86.4414,
        -6.014419757142857
    ],
    [
        86.4449,
        -3.5465255761904757
    ],
    [
        86.4483,
        -4.566462085714285
    ],
    [
        86.4518,
        -2.6670052999999996
    ],
    [
        86.4552,
        -4.286984138095237
    ],
    [
        86.4587,
        -3.7812148238095227
    ],
    [
        86.4621,
        -3.786632814285714
    ],
    [
        86.4655,
        -4.0489538
    ],
    [
        86.4689,
        -3.6378638523809523
    ],
    [
        86.4724,
        -4.1760508000000005
    ],
    [
        86.4758,
        -2.1375322809523807
    ],
    [
        86.4792,
        -3.2850624380952373
    ],
    [
        86.4826,
        -2.5367026476190477
    ],
    [
        86.486,
        -2.7425862476190472
    ],
    [
        86.4894,
        -2.3267555619047613
    ],
    [
        86.4928,
        -2.31140459047619
    ],
    [
        86.4962,
        -2.857718523809523
    ],
    [
        86.4996,
        -0.8537396857142854
    ],
    [
        86.503,
        -0.6004486809523807
    ],
    [
        86.5063,
        -0.5887096999999998
    ],
    [
        86.5097,
        -2.59201129047619
    ],
    [
        86.5131,
        -1.5835880095238095
    ],
    [
        86.5165,
        -2.3962864238095234
    ],
    [
        86.5198,
        -1.8099825666666665
    ],
    [
        86.5232,
        -3.2352524047619045
    ],
    [
        86.5265,
        -1.789552228571429
    ],
    [
        86.5299,
        -1.293144
    ],
    [
        86.5333,
        -1.8758487619047621
    ],
    [
        86.5366,
        -0.7524286571428573
    ],
    [
        86.54,
        -0.9757272190476194
    ],
    [
        86.5433,
        -0.4375402714285715
    ],
    [
        86.5466,
        -0.5266661952380955
    ],
    [
        86.55,
        -0.36114661904761924
    ],
    [
        86.5533,
        -0.302677480952381
    ],
    [
        86.5566,
        -0.043516985714285805
    ],
    [
        86.5599,
        0.8766382190476191
    ],
    [
        86.5632,
        1.0985951857142857
    ],
    [
        86.5665,
        0.8421436857142857
    ],
    [
        86.5698,
        0.7933817809523811
    ],
    [
        86.5731,
        0.5102918333333338
    ],
    [
        86.5764,
        0.657029042857143
    ],
    [
        86.5797,
        0.7166269285714286
    ],
    [
        86.5829,
        0.7383956380952382
    ],
    [
        86.5862,
        -0.03502235238095236
    ],
    [
        86.5895,
        -1.3392098047619048
    ],
    [
        86.5927,
        -1.4086277952380952
    ],
    [
        86.596,
        -1.344256919047619
    ],
    [
        86.5992,
        0.00903643809523793
    ],
    [
        86.6025,
        0.7048417333333331
    ],
    [
        86.6057,
        -0.37387996190476197
    ],
    [
        86.609,
        -0.4572396000000002
    ],
    [
        86.6122,
        -0.07527134761904779
    ],
    [
        86.6154,
        -0.3884311380952382
    ],
    [
        86.6187,
        -0.5393221428571431
    ],
    [
        86.6219,
        -0.5447401333333333
    ],
    [
        86.6251,
        -0.5856330571428572
    ],
    [
        86.6283,
        -1.3581480476190477
    ],
    [
        86.6315,
        -1.802694080952381
    ],
    [
        86.6348,
        -1.5462425809523812
    ],
    [
        86.638,
        -1.5868775047619048
    ],
    [
        86.6412,
        -1.3498404666666668
    ],
    [
        86.6444,
        -2.4645917857142865
    ],
    [
        86.6476,
        -2.432083847619048
    ],
    [
        86.6508,
        -2.6522864190476194
    ],
    [
        86.654,
        -1.5803372095238097
    ],
    [
        86.6572,
        -0.21530487619047664
    ],
    [
        86.6604,
        -0.3433048761904767
    ],
    [
        86.6636,
        0.13976692857142817
    ],
    [
        86.6668,
        -1.3881060857142857
    ],
    [
        86.6699,
        -2.142561114285715
    ],
    [
        86.6731,
        -1.3786246047619046
    ],
    [
        86.6762,
        -0.8864002666666664
    ],
    [
        86.6794,
        0.21886957619047634
    ],
    [
        86.6825,
        1.7006896809523813
    ],
    [
        86.6857,
        1.8439052000000005
    ],
    [
        86.6888,
        3.068822304761905
    ],
    [
        86.6919,
        4.326956847619048
    ],
    [
        86.6951,
        3.111972719047619
    ],
    [
        86.6982,
        3.4959727190476193
    ],
    [
        86.7013,
        3.790711342857143
    ],
    [
        86.7045,
        2.778450319047618
    ],
    [
        86.7076,
        1.5345702476190473
    ],
    [
        86.7107,
        4.503628447619047
    ],
    [
        86.7138,
        5.7123818857142865
    ],
    [
        86.7169,
        5.958448904761905
    ],
    [
        86.72,
        5.659917685714286
    ],
    [
        86.7231,
        6.558250428571428
    ],
    [
        86.7262,
        5.09810228095238
    ],
    [
        86.7293,
        4.563301576190477
    ],
    [
        86.7324,
        5.04730862857143
    ],
    [
        86.7355,
        5.782123442857143
    ],
    [
        86.7386,
        6.2643245
    ],
    [
        86.7417,
        5.595654304761906
    ],
    [
        86.7448,
        3.902919609523809
    ],
    [
        86.7479,
        2.7104201380952384
    ],
    [
        86.751,
        3.5985189047619044
    ],
    [
        86.7541,
        1.6372067380952384
    ],
    [
        86.7571,
        0.6430056809523809
    ],
    [
        86.7602,
        2.8359369
    ],
    [
        86.7632,
        2.8447411333333337
    ],
    [
        86.7663,
        2.912330552380952
    ],
    [
        86.7693,
        3.859575704761905
    ],
    [
        86.7724,
        4.832104804761904
    ],
    [
        86.7754,
        1.9307714714285715
    ],
    [
        86.7784,
        2.2255100952380955
    ],
    [
        86.7815,
        1.504466004761905
    ],
    [
        86.7845,
        1.504466004761905
    ],
    [
        86.7875,
        0.6294607142857148
    ],
    [
        86.7906,
        2.2196406095238097
    ],
    [
        86.7936,
        2.1781026904761904
    ],
    [
        86.7967,
        1.8365887619047614
    ],
    [
        86.7997,
        1.2715698666666662
    ],
    [
        86.8027,
        0.319809728571428
    ],
    [
        86.8057,
        1.0851910333333328
    ],
    [
        86.8087,
        0.5863490095238089
    ],
    [
        86.8117,
        -0.40731024761904777
    ],
    [
        86.8147,
        -1.1319663333333336
    ],
    [
        86.8177,
        -0.18020619523809575
    ],
    [
        86.8207,
        -0.31091519047619076
    ],
    [
        86.8237,
        -1.2356498857142857
    ],
    [
        86.8267,
        -1.5729197238095243
    ],
    [
        86.8297,
        -0.5374064952380955
    ],
    [
        86.8326,
        -0.578041414285714
    ],
    [
        86.8356,
        -1.7587116095238091
    ],
    [
        86.8386,
        0.14119668095238064
    ],
    [
        86.8415,
        0.6071437714285711
    ],
    [
        86.8445,
        -0.31842942380952366
    ],
    [
        86.8474,
        -0.31842942380952366
    ],
    [
        86.8504,
        0.022710409523809708
    ],
    [
        86.8533,
        -0.10732133809523793
    ],
    [
        86.8562,
        -0.029745580952380873
    ],
    [
        86.8592,
        0.02474390952380994
    ],
    [
        86.8621,
        -0.11728481428571422
    ],
    [
        86.8651,
        -0.11006082857142874
    ],
    [
        86.868,
        0.06819102380952397
    ],
    [
        86.8709,
        2.145989966666667
    ],
    [
        86.8739,
        2.8503285904761904
    ],
    [
        86.8768,
        3.195048166666667
    ],
    [
        86.8797,
        2.3828012571428574
    ],
    [
        86.8827,
        1.5725860904761904
    ],
    [
        86.8856,
        2.4593948571428577
    ],
    [
        86.8885,
        2.8836234285714277
    ],
    [
        86.8913,
        1.9463112571428571
    ],
    [
        86.8943,
        1.0071930904761908
    ],
    [
        86.8972,
        0.32046293333333375
    ],
    [
        86.9,
        0.31414194285714325
    ],
    [
        86.9029,
        0.5376340047619054
    ],
    [
        86.9058,
        1.7415886571428578
    ],
    [
        86.9087,
        1.9704987095238102
    ],
    [
        86.9116,
        1.4968890380952382
    ],
    [
        86.9145,
        1.9520001476190478
    ],
    [
        86.9174,
        1.624293880952381
    ],
    [
        86.9203,
        2.7818086190476197
    ],
    [
        86.9232,
        3.319350571428572
    ],
    [
        86.9261,
        3.6320889571428574
    ],
    [
        86.929,
        3.8447450428571437
    ],
    [
        86.9319,
        2.0224946000000004
    ],
    [
        86.9348,
        2.1904522714285717
    ],
    [
        86.9377,
        1.3969425714285717
    ],
    [
        86.9406,
        0.3959690238095243
    ],
    [
        86.9434,
        0.5865016523809529
    ],
    [
        86.9463,
        0.1228120619047622
    ],
    [
        86.9491,
        0.06294327619047667
    ],
    [
        86.952,
        -0.39758582380952373
    ],
    [
        86.9548,
        0.47380747142857144
    ],
    [
        86.9576,
        2.1279196380952388
    ],
    [
        86.9604,
        0.3318561476190477
    ],
    [
        86.9633,
        -0.6887576095238094
    ],
    [
        86.9661,
        -0.2704759285714286
    ],
    [
        86.9689,
        -0.7210720476190476
    ],
    [
        86.9717,
        -1.7710784
    ],
    [
        86.9745,
        -2.2261895095238096
    ],
    [
        86.9774,
        -1.326803266666667
    ],
    [
        86.9802,
        -2.681300619047619
    ],
    [
        86.983,
        -4.204916642857143
    ],
    [
        86.9858,
        -5.382727342857143
    ],
    [
        86.9886,
        -5.724737923809523
    ],
    [
        86.9913,
        -5.605090657142857
    ],
    [
        86.9941,
        -5.334191185714286
    ],
    [
        86.9969,
        -5.148173547619048
    ],
    [
        86.9997,
        -3.3656550285714295
    ],
    [
        87.0025,
        -4.408617990476191
    ],
    [
        87.0053,
        -4.369969666666667
    ],
    [
        87.0081,
        -2.7269643761904767
    ],
    [
        87.0109,
        -3.1640155238095247
    ],
    [
        87.0137,
        -4.682987557142859
    ],
    [
        87.0165,
        -4.514849285714287
    ],
    [
        87.0192,
        -2.5305106619047626
    ],
    [
        87.022,
        -3.4996535190476186
    ],
    [
        87.0247,
        -3.7213395857142864
    ],
    [
        87.0275,
        -3.9312866761904752
    ],
    [
        87.0303,
        -2.2801544
    ],
    [
        87.033,
        -0.977127942857143
    ],
    [
        87.0358,
        -2.726235528571429
    ],
    [
        87.0385,
        -2.4011561619047623
    ],
    [
        87.0413,
        -1.7184894952380951
    ],
    [
        87.044,
        -1.0358228285714286
    ],
    [
        87.0467,
        -2.0103836761904765
    ],
    [
        87.0494,
        -1.252316657142858
    ],
    [
        87.0522,
        -2.3372690380952386
    ],
    [
        87.0549,
        -2.074496552380953
    ],
    [
        87.0576,
        -1.8726764476190478
    ],
    [
        87.0604,
        -0.5615230095238095
    ],
    [
        87.0631,
        -0.6101043142857144
    ],
    [
        87.0659,
        -2.0345429857142854
    ],
    [
        87.0686,
        -1.2791849619047622
    ],
    [
        87.0713,
        0.18560718095238066
    ],
    [
        87.074,
        -0.24963796666666685
    ],
    [
        87.0767,
        -1.110646785714286
    ],
    [
        87.0794,
        0.28080899999999975
    ],
    [
        87.0821,
        0.17515820476190452
    ],
    [
        87.0848,
        1.8773098809523812
    ],
    [
        87.0875,
        1.7635321047619048
    ],
    [
        87.0902,
        0.038805471428571416
    ],
    [
        87.0928,
        2.615059442857143
    ],
    [
        87.0955,
        1.973704942857143
    ],
    [
        87.0982,
        2.9387843047619047
    ],
    [
        87.1009,
        4.004322223809523
    ],
    [
        87.1036,
        4.396313757142858
    ],
    [
        87.1062,
        3.5474954142857147
    ],
    [
        87.1089,
        3.7745994714285707
    ],
    [
        87.1116,
        5.031573014285714
    ],
    [
        87.1143,
        3.762408995238095
    ],
    [
        87.1169,
        3.859307652380952
    ],
    [
        87.1196,
        3.5319297428571437
    ],
    [
        87.1222,
        3.9628446047619046
    ],
    [
        87.1249,
        4.901511271428572
    ],
    [
        87.1276,
        3.4133701761904756
    ],
    [
        87.1302,
        3.592550828571428
    ],
    [
        87.1328,
        2.547781871428571
    ],
    [
        87.1355,
        1.8322202666666665
    ],
    [
        87.1381,
        2.1865567761904763
    ],
    [
        87.1408,
        0.6651853476190477
    ],
    [
        87.1434,
        0.7006731761904763
    ],
    [
        87.146,
        0.8115162095238098
    ],
    [
        87.1487,
        -0.2146509857142858
    ],
    [
        87.1513,
        -0.0524499285714283
    ],
    [
        87.154,
        0.040784638095237975
    ],
    [
        87.1566,
        -1.6071871428571431
    ],
    [
        87.1592,
        -1.1098157142857146
    ],
    [
        87.1619,
        -1.3062178285714288
    ],
    [
        87.1645,
        -1.2962848476190476
    ],
    [
        87.1671,
        -1.9410255857142857
    ],
    [
        87.1697,
        -2.5315864333333336
    ],
    [
        87.1723,
        -3.8502417047619053
    ],
    [
        87.1749,
        -2.5115057714285713
    ],
    [
        87.1775,
        -2.2169025952380954
    ],
    [
        87.1801,
        -2.8684158190476183
    ],
    [
        87.1827,
        -2.861191833333333
    ],
    [
        87.1853,
        -2.83719788095238
    ],
    [
        87.1878,
        -2.791144971428572
    ],
    [
        87.1904,
        -2.369444795238096
    ],
    [
        87.193,
        -3.222236328571429
    ],
    [
        87.1956,
        -2.8497495571428577
    ],
    [
        87.1981,
        -3.2492585523809523
    ],
    [
        87.2007,
        -2.786697704761905
    ],
    [
        87.2033,
        -3.235578128571429
    ],
    [
        87.2058,
        -2.9301389714285717
    ],
    [
        87.2083,
        -3.5428232714285715
    ],
    [
        87.2109,
        -1.913814452380953
    ],
    [
        87.2134,
        -2.331618838095239
    ],
    [
        87.216,
        -0.5125288904761911
    ],
    [
        87.2185,
        -0.10347069047619074
    ],
    [
        87.2211,
        -0.715703495238096
    ],
    [
        87.2236,
        -0.7021585238095238
    ],
    [
        87.2262,
        1.7251007380952383
    ],
    [
        87.2287,
        0.8013335428571431
    ],
    [
        87.2313,
        -0.4068780952380952
    ],
    [
        87.2338,
        -1.0123384142857144
    ],
    [
        87.2363,
        0.39272684285714227
    ],
    [
        87.2389,
        0.7882400666666666
    ],
    [
        87.2414,
        1.9991607000000007
    ],
    [
        87.2439,
        2.201883804761905
    ],
    [
        87.2465,
        2.5080002047619048
    ],
    [
        87.249,
        2.6830012619047623
    ],
    [
        87.2515,
        2.539085919047619
    ],
    [
        87.2541,
        2.975234066666667
    ],
    [
        87.2566,
        3.374810785714285
    ],
    [
        87.2591,
        3.1064171333333337
    ],
    [
        87.2616,
        3.476581909523809
    ],
    [
        87.2641,
        2.7808217714285712
    ],
    [
        87.2667,
        2.5616512000000005
    ],
    [
        87.2691,
        1.6507517285714286
    ],
    [
        87.2717,
        0.48317500952380954
    ],
    [
        87.2741,
        1.0656088714285714
    ],
    [
        87.2766,
        3.4386882380952377
    ],
    [
        87.2791,
        1.9426459095238096
    ],
    [
        87.2816,
        1.8743792428571433
    ],
    [
        87.284,
        2.2798254476190474
    ],
    [
        87.2865,
        2.665405695238095
    ],
    [
        87.289,
        1.8463862952380954
    ],
    [
        87.2914,
        0.9598677809523811
    ],
    [
        87.2939,
        0.5760935285714288
    ],
    [
        87.2964,
        0.7397942095238097
    ],
    [
        87.2988,
        0.8080608761904764
    ],
    [
        87.3013,
        -0.6426057904761904
    ],
    [
        87.3037,
        0.2310450047619046
    ],
    [
        87.3061,
        -1.5081296000000004
    ],
    [
        87.3086,
        -0.5545634619047619
    ],
    [
        87.311,
        -0.24655076190476194
    ],
    [
        87.3134,
        -0.5789831142857144
    ],
    [
        87.3158,
        0.09420206666666646
    ],
    [
        87.3182,
        1.628396071428571
    ],
    [
        87.3207,
        2.479697657142857
    ],
    [
        87.3231,
        3.418093423809524
    ],
    [
        87.3255,
        3.1207812571428573
    ],
    [
        87.3279,
        1.7895038571428568
    ],
    [
        87.3303,
        2.222943009523809
    ],
    [
        87.3328,
        1.7026805761904757
    ],
    [
        87.3352,
        2.181721142857142
    ],
    [
        87.3376,
        1.5103419523809523
    ],
    [
        87.34,
        1.3541232571428568
    ],
    [
        87.3424,
        1.451421314285714
    ],
    [
        87.3448,
        1.608181809523809
    ],
    [
        87.3472,
        1.5870419761904757
    ],
    [
        87.3496,
        1.435699471428571
    ],
    [
        87.352,
        2.876884657142858
    ],
    [
        87.3544,
        3.4362920619047608
    ],
    [
        87.3568,
        5.730810580952381
    ],
    [
        87.3592,
        4.014662433333332
    ],
    [
        87.3616,
        4.950620104761904
    ],
    [
        87.364,
        5.676824185714285
    ],
    [
        87.3664,
        5.017861223809523
    ],
    [
        87.3688,
        3.874665457142857
    ],
    [
        87.3712,
        1.6180728666666666
    ],
    [
        87.3736,
        1.6251162523809521
    ],
    [
        87.376,
        1.4982772476190476
    ],
    [
        87.3784,
        0.5539990904761903
    ],
    [
        87.3808,
        0.6332371857142858
    ],
    [
        87.3831,
        0.7000139047619052
    ],
    [
        87.3855,
        -0.693763876190476
    ],
    [
        87.3879,
        -0.407964933333333
    ],
    [
        87.3902,
        -0.3592030285714284
    ],
    [
        87.3926,
        -0.5398026761904763
    ],
    [
        87.3949,
        -0.5343846857142859
    ],
    [
        87.3972,
        -0.40062807142857115
    ],
    [
        87.3996,
        -0.23953318571428592
    ],
    [
        87.4019,
        -0.24901466666666677
    ],
    [
        87.4042,
        -0.2788136047619049
    ],
    [
        87.4065,
        -1.0553920857142853
    ],
    [
        87.4089,
        -1.040492614285714
    ],
    [
        87.4112,
        -2.5331486999999995
    ],
    [
        87.4135,
        -3.645191023809523
    ],
    [
        87.4159,
        -3.0006760333333324
    ],
    [
        87.4182,
        -3.2742844999999994
    ],
    [
        87.4205,
        -2.0503606904761904
    ],
    [
        87.4228,
        -2.0481934952380954
    ],
    [
        87.4251,
        -1.9854222190476194
    ],
    [
        87.4275,
        -1.0974137523809524
    ],
    [
        87.4298,
        -0.9538370333333335
    ],
    [
        87.4321,
        -0.4598518476190475
    ],
    [
        87.4344,
        0.5126772571428572
    ],
    [
        87.4367,
        0.5126772571428572
    ],
    [
        87.439,
        0.019098419047618907
    ],
    [
        87.4413,
        0.6604529190476189
    ],
    [
        87.4436,
        1.1515936571428569
    ],
    [
        87.4459,
        1.9561650857142856
    ],
    [
        87.4483,
        1.9079449809523807
    ],
    [
        87.4506,
        1.0938920714285716
    ],
    [
        87.4529,
        0.1656098809523811
    ],
    [
        87.4552,
        -0.4150179857142856
    ],
    [
        87.4574,
        0.6685798952380955
    ],
    [
        87.4597,
        1.2112818333333333
    ],
    [
        87.462,
        1.2288902952380956
    ],
    [
        87.4643,
        0.7313382666666668
    ],
    [
        87.4665,
        1.2704282142857144
    ],
    [
        87.4688,
        2.219118161904762
    ],
    [
        87.4711,
        1.3825805952380956
    ],
    [
        87.4733,
        2.2288705428571425
    ],
    [
        87.4756,
        2.095768604761905
    ],
    [
        87.4778,
        2.0867386238095236
    ],
    [
        87.4801,
        1.546971423809524
    ],
    [
        87.4823,
        1.1501036952380954
    ],
    [
        87.4845,
        1.268697461904762
    ],
    [
        87.4868,
        1.3089711857142854
    ],
    [
        87.489,
        0.5023680095238097
    ],
    [
        87.4912,
        0.41116519047619055
    ],
    [
        87.4934,
        -0.8476143523809522
    ],
    [
        87.4957,
        -0.4015332238095236
    ],
    [
        87.4979,
        1.191355666666667
    ],
    [
        87.5002,
        0.8799341523809525
    ],
    [
        87.5024,
        1.2246537285714287
    ],
    [
        87.5046,
        0.3433274523809525
    ],
    [
        87.5069,
        0.4264032904761907
    ],
    [
        87.5091,
        -0.08966019999999984
    ],
    [
        87.5114,
        0.043080542857143064
    ],
    [
        87.5136,
        -0.3713956476190474
    ],
    [
        87.5158,
        -1.282004871428571
    ],
    [
        87.5181,
        0.2973390476190477
    ],
    [
        87.5203,
        -1.0314228571428568
    ],
    [
        87.5225,
        -0.6783505476190473
    ],
    [
        87.5247,
        -1.5565163333333332
    ],
    [
        87.5269,
        -1.1414596428571426
    ],
    [
        87.5291,
        -0.724725957142857
    ],
    [
        87.5313,
        -0.5839334809523807
    ],
    [
        87.5335,
        -0.4069458285714284
    ],
    [
        87.5357,
        0.47076845714285703
    ],
    [
        87.5379,
        0.16408768095238097
    ],
    [
        87.5401,
        0.43506924285714277
    ],
    [
        87.5422,
        -0.08325174285714282
    ],
    [
        87.5444,
        -1.3950824333333331
    ],
    [
        87.5466,
        -1.7753575666666666
    ],
    [
        87.5488,
        -1.9060665619047616
    ],
    [
        87.551,
        -1.5584122428571427
    ],
    [
        87.5532,
        -0.7917667380952379
    ],
    [
        87.5553,
        -0.7673857857142858
    ],
    [
        87.5575,
        -0.42605245238095246
    ],
    [
        87.5597,
        0.7469422571428572
    ],
    [
        87.5618,
        0.427667880952381
    ],
    [
        87.5639,
        0.018609676190476154
    ],
    [
        87.5661,
        0.13490295238095226
    ],
    [
        87.5682,
        0.13490295238095226
    ],
    [
        87.5704,
        1.6722574476190477
    ],
    [
        87.5725,
        1.5240367380952382
    ],
    [
        87.5747,
        2.1448480285714284
    ],
    [
        87.5769,
        2.9067527904761903
    ],
    [
        87.579,
        2.762789071428571
    ],
    [
        87.5812,
        2.5650324571428573
    ],
    [
        87.5833,
        1.6774980666666663
    ],
    [
        87.5855,
        1.3817743523809523
    ],
    [
        87.5876,
        1.890162357142857
    ],
    [
        87.5897,
        1.1350300809523812
    ],
    [
        87.5919,
        0.6338660619047621
    ],
    [
        87.594,
        1.1323210904761907
    ],
    [
        87.5961,
        0.9887443714285717
    ],
    [
        87.5983,
        0.10522509523809494
    ],
    [
        87.6004,
        2.1234261523809526
    ],
    [
        87.6025,
        1.6392901000000004
    ],
    [
        87.6046,
        1.116454119047619
    ],
    [
        87.6067,
        1.620327133333333
    ],
    [
        87.6089,
        1.0094488285714285
    ],
    [
        87.611,
        1.354497352380952
    ],
    [
        87.6131,
        1.0239999999999998
    ],
    [
        87.6152,
        0.2546455047619044
    ],
    [
        87.6173,
        0.11455177619047584
    ],
    [
        87.6194,
        -0.3906757380952384
    ],
    [
        87.6215,
        -1.1668027238095242
    ],
    [
        87.6236,
        -2.0007860952380954
    ],
    [
        87.6257,
        -2.2676220714285718
    ],
    [
        87.6278,
        -1.005230538095238
    ],
    [
        87.6299,
        -0.5581109619047617
    ],
    [
        87.632,
        -1.0520509952380952
    ],
    [
        87.6341,
        0.9417691142857145
    ],
    [
        87.6361,
        0.9661500666666669
    ],
    [
        87.6382,
        0.9593775761904763
    ],
    [
        87.6403,
        2.0917373619047614
    ],
    [
        87.6423,
        0.9651825619047617
    ],
    [
        87.6444,
        0.2978668666666665
    ],
    [
        87.6465,
        0.6378843999999999
    ],
    [
        87.6485,
        0.014476995238095139
    ],
    [
        87.6506,
        -1.3048034285714283
    ],
    [
        87.6526,
        0.17024419047619044
    ],
    [
        87.6547,
        0.2990117380952383
    ],
    [
        87.6568,
        1.1184826333333335
    ],
    [
        87.6588,
        0.6769164952380952
    ],
    [
        87.6609,
        0.8170102238095238
    ],
    [
        87.663,
        -0.15280988095238088
    ],
    [
        87.665,
        0.5718462047619048
    ],
    [
        87.6671,
        1.0675922380952383
    ],
    [
        87.6692,
        1.670343557142857
    ],
    [
        87.6712,
        1.9033171
    ],
    [
        87.6733,
        2.612396466666667
    ],
    [
        87.6754,
        2.8006070999999997
    ],
    [
        87.6774,
        1.8172420190476188
    ],
    [
        87.6795,
        2.966307271428571
    ],
    [
        87.6815,
        2.791577114285714
    ],
    [
        87.6835,
        1.695939257142857
    ],
    [
        87.6856,
        2.9998687142857143
    ],
    [
        87.6876,
        3.148411923809524
    ],
    [
        87.6896,
        2.791817919047619
    ],
    [
        87.6916,
        3.3935533666666666
    ],
    [
        87.6936,
        4.698385819047617
    ],
    [
        87.6956,
        3.4016803523809522
    ],
    [
        87.6977,
        3.3134122761904763
    ],
    [
        87.6997,
        2.6326419095238096
    ],
    [
        87.7017,
        2.350906461904762
    ],
    [
        87.7037,
        2.470779476190476
    ],
    [
        87.7057,
        2.4816154523809524
    ],
    [
        87.7077,
        1.5117953476190475
    ],
    [
        87.7097,
        1.9939964047619048
    ],
    [
        87.7117,
        1.831456723809524
    ],
    [
        87.7137,
        0.8751815952380949
    ],
    [
        87.7157,
        -0.2669305761904764
    ],
    [
        87.7178,
        0.1078781904761904
    ],
    [
        87.7198,
        -0.837560961904762
    ],
    [
        87.7218,
        -0.6845027619047622
    ],
    [
        87.7238,
        -1.3156985285714287
    ],
    [
        87.7258,
        -1.3439064761904764
    ],
    [
        87.7278,
        -1.5297951142857147
    ],
    [
        87.7298,
        -1.7702183952380952
    ],
    [
        87.7318,
        -1.8458444952380952
    ],
    [
        87.7338,
        -3.2572307380952377
    ],
    [
        87.7358,
        -4.0432907
    ],
    [
        87.7378,
        -3.568765128571428
    ],
    [
        87.7398,
        -4.042839204761905
    ],
    [
        87.7418,
        -4.319698466666667
    ],
    [
        87.7437,
        -3.375613814285715
    ],
    [
        87.7457,
        -3.0322487333333337
    ],
    [
        87.7477,
        -2.073264604761905
    ],
    [
        87.7497,
        -1.9577937047619052
    ],
    [
        87.7516,
        -2.0501704238095244
    ],
    [
        87.7536,
        -2.137535504761905
    ],
    [
        87.7556,
        -1.356442033333334
    ],
    [
        87.7575,
        -1.320954200000001
    ],
    [
        87.7595,
        -1.2527778333333337
    ],
    [
        87.7615,
        -0.6116490857142859
    ],
    [
        87.7634,
        -0.7413196285714289
    ],
    [
        87.7654,
        -0.5095774476190479
    ],
    [
        87.7673,
        -0.22235950476190466
    ],
    [
        87.7693,
        -1.2726754523809525
    ],
    [
        87.7712,
        -1.3250945
    ],
    [
        87.7731,
        -1.3176447666666666
    ],
    [
        87.775,
        -0.3857505857142858
    ],
    [
        87.777,
        0.9809372428571427
    ],
    [
        87.7789,
        0.5226656380952379
    ],
    [
        87.7808,
        1.0570148476190477
    ],
    [
        87.7827,
        1.8254663476190474
    ],
    [
        87.7846,
        0.9071171428571431
    ],
    [
        87.7866,
        0.1449866333333333
    ],
    [
        87.7885,
        0.1449866333333333
    ],
    [
        87.7904,
        -0.2693766809523811
    ],
    [
        87.7923,
        -0.04890321428571446
    ],
    [
        87.7942,
        -1.1751677619047622
    ],
    [
        87.7961,
        -1.7043247285714287
    ],
    [
        87.798,
        -1.1056369
    ],
    [
        87.7999,
        -1.4108503047619048
    ],
    [
        87.8018,
        -0.20444466190476196
    ],
    [
        87.8037,
        -1.2847917523809524
    ],
    [
        87.8056,
        -1.0224133
    ],
    [
        87.8075,
        -1.2555920714285715
    ],
    [
        87.8093,
        -0.19095715238095273
    ],
    [
        87.8112,
        -0.19474974285714308
    ],
    [
        87.8131,
        -0.09412993809523836
    ],
    [
        87.815,
        -0.5356960761904764
    ],
    [
        87.8169,
        -1.3243521619047618
    ],
    [
        87.8188,
        -1.2419535714285712
    ],
    [
        87.8207,
        -1.1024403476190476
    ],
    [
        87.8225,
        -1.5000605714285722
    ],
    [
        87.8244,
        -0.9389600428571431
    ],
    [
        87.8263,
        0.23493766190476192
    ],
    [
        87.8282,
        -0.2039194809523809
    ],
    [
        87.8301,
        0.5351845714285712
    ],
    [
        87.8319,
        -0.46791746666666656
    ],
    [
        87.8338,
        -0.014160852380952585
    ],
    [
        87.8356,
        0.38731216666666635
    ],
    [
        87.8375,
        -0.2784232809523811
    ],
    [
        87.8393,
        -0.058317461904762044
    ],
    [
        87.8412,
        -1.1057954142857143
    ],
    [
        87.843,
        -0.06373545238095224
    ],
    [
        87.8449,
        -0.418071957142857
    ],
    [
        87.8467,
        -0.048088957142856985
    ],
    [
        87.8486,
        0.23635548571428588
    ],
    [
        87.8504,
        -0.1598349904761902
    ],
    [
        87.8523,
        -0.24961881428571409
    ],
    [
        87.8541,
        0.315787080952381
    ],
    [
        87.856,
        -0.23109122380952374
    ],
    [
        87.8578,
        0.847765919047619
    ],
    [
        87.8596,
        0.2995331190476191
    ],
    [
        87.8615,
        0.5701917904761906
    ],
    [
        87.8633,
        0.8895145428571428
    ],
    [
        87.8651,
        -0.20356482380952357
    ],
    [
        87.8669,
        0.40866798095238105
    ],
    [
        87.8688,
        -0.8957129714285714
    ],
    [
        87.8706,
        -0.8848769952380952
    ],
    [
        87.8724,
        0.42492194285714274
    ],
    [
        87.8742,
        0.03225315714285707
    ],
    [
        87.8761,
        -0.03569746190476201
    ],
    [
        87.8779,
        0.17357238095238087
    ],
    [
        87.8797,
        0.46343481428571415
    ],
    [
        87.8815,
        0.3180520999999999
    ],
    [
        87.8833,
        0.9205325190476188
    ],
    [
        87.8851,
        0.6315730857142857
    ],
    [
        87.887,
        0.19813393333333323
    ],
    [
        87.8888,
        0.13018331428571417
    ],
    [
        87.8906,
        0.2523890761904762
    ],
    [
        87.8924,
        0.20637134761904752
    ],
    [
        87.8942,
        0.6425194952380949
    ],
    [
        87.896,
        -0.38635669523809524
    ],
    [
        87.8978,
        0.5777067952380953
    ],
    [
        87.8996,
        0.6182062666666667
    ],
    [
        87.9014,
        0.6154972714285717
    ],
    [
        87.9032,
        0.4082591761904764
    ],
    [
        87.905,
        -0.6157408238095234
    ],
    [
        87.9068,
        0.45973007619047673
    ],
    [
        87.9086,
        1.2302885714285716
    ],
    [
        87.9104,
        0.5753891047619051
    ],
    [
        87.9122,
        1.6285108000000008
    ],
    [
        87.914,
        1.6375407809523816
    ],
    [
        87.9158,
        1.2081651190476197
    ],
    [
        87.9176,
        1.4862885761904767
    ],
    [
        87.9194,
        1.6962356666666671
    ],
    [
        87.9212,
        1.1287012761904767
    ],
    [
        87.9229,
        1.9630716476190482
    ],
    [
        87.9247,
        2.8055690000000006
    ],
    [
        87.9265,
        2.923500571428572
    ],
    [
        87.9283,
        3.1022641190476192
    ],
    [
        87.93,
        2.1900717190476193
    ],
    [
        87.9318,
        2.051912990476191
    ],
    [
        87.9335,
        3.048281242857145
    ],
    [
        87.9353,
        2.0554346809523816
    ],
    [
        87.9371,
        2.256577538095238
    ],
    [
        87.9388,
        1.933191295238095
    ],
    [
        87.9406,
        2.275066428571429
    ],
    [
        87.9424,
        3.332928861904763
    ],
    [
        87.9441,
        3.395235742857143
    ],
    [
        87.9459,
        3.2125291000000002
    ],
    [
        87.9476,
        2.7939894142857145
    ],
    [
        87.9494,
        2.3740952333333327
    ],
    [
        87.9511,
        3.067597880952381
    ],
    [
        87.9528,
        3.343012342857143
    ],
    [
        87.9546,
        3.3389488476190468
    ],
    [
        87.9563,
        2.921763661904761
    ],
    [
        87.9581,
        2.8533615476190466
    ],
    [
        87.9598,
        2.1226102238095237
    ],
    [
        87.9615,
        1.0019894142857144
    ],
    [
        87.9633,
        1.3746116333333336
    ],
    [
        87.965,
        1.1256550238095238
    ],
    [
        87.9668,
        1.5469037000000008
    ],
    [
        87.9685,
        1.8030972000000003
    ],
    [
        87.9702,
        0.16144640476190494
    ],
    [
        87.972,
        0.13354376190476241
    ],
    [
        87.9737,
        0.1267712761904762
    ],
    [
        87.9755,
        0.2286972000000001
    ],
    [
        87.9772,
        0.21054693809523808
    ],
    [
        87.979,
        0.27601430952380973
    ],
    [
        87.9807,
        0.1401130761904765
    ],
    [
        87.9824,
        0.3423025904761906
    ],
    [
        87.9842,
        0.6391632619047618
    ],
    [
        87.9859,
        -0.05072739047619036
    ],
    [
        87.9876,
        -0.8715527857142856
    ],
    [
        87.9893,
        -0.3396868238095238
    ],
    [
        87.9911,
        -0.5956868238095236
    ],
    [
        87.9928,
        -0.5882370904761901
    ],
    [
        87.9945,
        -0.21832386190476175
    ],
    [
        87.9962,
        0.4636655571428573
    ],
    [
        87.9979,
        0.5426779047619049
    ],
    [
        87.9997,
        -0.056687171428571555
    ],
    [
        88.0014,
        -0.290202519047619
    ],
    [
        88.0031,
        0.32067579047619055
    ],
    [
        88.0048,
        -0.05922847142857137
    ],
    [
        88.0065,
        0.8547186190476194
    ],
    [
        88.0082,
        0.8612202047619049
    ],
    [
        88.0099,
        0.296936604761905
    ],
    [
        88.0116,
        0.091730257142857
    ],
    [
        88.0132,
        0.35206464761904777
    ],
    [
        88.0149,
        -0.7062492857142855
    ],
    [
        88.0165,
        -1.2394697476190477
    ],
    [
        88.0182,
        -1.0087619047619047
    ],
    [
        88.0199,
        -1.257808819047619
    ],
    [
        88.0215,
        -0.985103352380952
    ],
    [
        88.0232,
        -0.3823520333333331
    ],
    [
        88.0248,
        -0.4067329857142857
    ],
    [
        88.0265,
        -0.6997559095238094
    ],
    [
        88.0281,
        -0.24171005238095225
    ],
    [
        88.0298,
        -1.1838984142857143
    ],
    [
        88.0314,
        -2.0805756619047617
    ],
    [
        88.0331,
        -1.7953787190476187
    ],
    [
        88.0348,
        -1.5577998857142856
    ],
    [
        88.0364,
        -0.9013201666666663
    ],
    [
        88.0381,
        -0.9040291619047616
    ],
    [
        88.0397,
        -1.419641152380952
    ],
    [
        88.0414,
        -1.5181808333333333
    ],
    [
        88.043,
        -1.7473617857142858
    ],
    [
        88.0446,
        -2.3682633714285712
    ],
    [
        88.0463,
        -1.5880728952380951
    ],
    [
        88.0479,
        -2.1355607285714284
    ],
    [
        88.0495,
        -0.5697617857142858
    ],
    [
        88.0512,
        -0.17424855714285686
    ],
    [
        88.0528,
        0.44665302857142886
    ],
    [
        88.0544,
        1.3882995904761908
    ],
    [
        88.0561,
        1.000913347619048
    ],
    [
        88.0577,
        1.191897476190476
    ],
    [
        88.0593,
        0.6672555000000003
    ],
    [
        88.061,
        0.4130614952380952
    ],
    [
        88.0626,
        -0.0012340952380951137
    ],
    [
        88.0642,
        0.9339108761904765
    ],
    [
        88.0659,
        0.8932759523809527
    ],
    [
        88.0675,
        0.807190119047619
    ],
    [
        88.0691,
        1.1154737190476194
    ],
    [
        88.0708,
        1.1082497333333334
    ],
    [
        88.0724,
        0.723572485714286
    ],
    [
        88.074,
        1.3926941761904763
    ],
    [
        88.0757,
        1.5809693095238095
    ],
    [
        88.0773,
        1.212546028571428
    ],
    [
        88.0789,
        2.2428670142857143
    ],
    [
        88.0805,
        1.4626765380952382
    ],
    [
        88.0821,
        1.7249975285714285
    ],
    [
        88.0837,
        1.0409763666666667
    ],
    [
        88.0853,
        0.8876472666666667
    ],
    [
        88.0869,
        0.26349488571428564
    ],
    [
        88.0885,
        -0.7731470904761906
    ],
    [
        88.0901,
        -0.4952816333333334
    ],
    [
        88.0917,
        -0.8319193761904764
    ],
    [
        88.0933,
        -1.8514043857142857
    ],
    [
        88.0949,
        -1.6184308428571423
    ],
    [
        88.0965,
        -1.4623927476190477
    ],
    [
        88.0981,
        -1.4304266095238094
    ],
    [
        88.0997,
        -1.4271758142857145
    ],
    [
        88.1013,
        -1.6736943333333334
    ],
    [
        88.1029,
        -1.8253980380952382
    ],
    [
        88.1045,
        -1.8935744047619048
    ],
    [
        88.1061,
        -1.8281070333333336
    ],
    [
        88.1077,
        -1.5355356047619046
    ],
    [
        88.1093,
        -2.4606573
    ],
    [
        88.1109,
        -1.8522170904761905
    ],
    [
        88.1125,
        -1.9831518333333331
    ],
    [
        88.114,
        -1.9831518333333331
    ],
    [
        88.1156,
        -2.2251553619047617
    ],
    [
        88.1171,
        -1.5926050999999997
    ],
    [
        88.1187,
        -1.0708527190476187
    ],
    [
        88.1202,
        -1.4070389619047616
    ],
    [
        88.1218,
        -1.0086361380952376
    ],
    [
        88.1234,
        -1.1393128809523805
    ],
    [
        88.1249,
        -1.5517121761904757
    ],
    [
        88.1264,
        -0.06718307619047614
    ],
    [
        88.128,
        0.8078222142857143
    ],
    [
        88.1295,
        1.049825742857143
    ],
    [
        88.1311,
        0.7193283904761903
    ],
    [
        88.1326,
        0.1471887095238094
    ],
    [
        88.1341,
        0.7553580238095238
    ],
    [
        88.1357,
        0.9955555523809523
    ],
    [
        88.1372,
        0.5706948809523811
    ],
    [
        88.1387,
        0.6948571380952382
    ],
    [
        88.1402,
        0.6307442666666666
    ],
    [
        88.1418,
        1.2240141095238097
    ],
    [
        88.1433,
        1.0110871285714287
    ],
    [
        88.1448,
        0.9917371666666668
    ],
    [
        88.1463,
        0.8136207666666667
    ],
    [
        88.1478,
        0.8244567476190474
    ],
    [
        88.1493,
        0.2158359380952383
    ],
    [
        88.1508,
        -0.29562226190476215
    ],
    [
        88.1524,
        -0.02336829523809534
    ],
    [
        88.1539,
        -0.3468222666666668
    ],
    [
        88.1554,
        -0.465566538095238
    ],
    [
        88.1569,
        -0.34140428095238057
    ],
    [
        88.1584,
        -0.8263143333333335
    ],
    [
        88.1599,
        -1.7277323190476188
    ],
    [
        88.1614,
        -1.731344314285715
    ],
    [
        88.1629,
        -1.4447326761904762
    ],
    [
        88.1644,
        -0.8660914095238097
    ],
    [
        88.1659,
        -0.5206945857142857
    ],
    [
        88.1674,
        -0.4033048142857146
    ],
    [
        88.1689,
        -0.5134706
    ],
    [
        88.1705,
        -0.6018515523809524
    ],
    [
        88.1719,
        -0.633230742857143
    ],
    [
        88.1734,
        -0.48965402380952383
    ],
    [
        88.1749,
        -0.36684626666666675
    ],
    [
        88.1764,
        -0.369555261904762
    ],
    [
        88.1779,
        -0.42531540476190477
    ],
    [
        88.1794,
        -0.41854291904761887
    ],
    [
        88.1809,
        -0.3056681428571426
    ],
    [
        88.1824,
        -0.2617050285714284
    ],
    [
        88.1839,
        -0.2728420047619046
    ],
    [
        88.1854,
        -0.21053512380952366
    ],
    [
        88.1869,
        0.1484066761904763
    ],
    [
        88.1884,
        0.15382466666666655
    ],
    [
        88.1898,
        0.08880879523809543
    ],
    [
        88.1913,
        0.4403008571428573
    ],
    [
        88.1928,
        0.029210909523809662
    ],
    [
        88.1943,
        -0.807326657142857
    ],
    [
        88.1957,
        -0.8956398857142857
    ],
    [
        88.1972,
        -1.504389695238095
    ],
    [
        88.1987,
        -2.092241547619048
    ],
    [
        88.2001,
        -1.7400722333333332
    ],
    [
        88.2016,
        -2.3424849333333335
    ],
    [
        88.203,
        -2.43120450952381
    ],
    [
        88.2045,
        -1.7458288476190478
    ],
    [
        88.206,
        -1.6225695857142857
    ],
    [
        88.2074,
        -1.625665580952381
    ],
    [
        88.2089,
        -1.5631329523809527
    ],
    [
        88.2103,
        -1.0409742238095239
    ],
    [
        88.2118,
        -0.588572104761905
    ],
    [
        88.2132,
        -0.5580378666666669
    ],
    [
        88.2146,
        -0.5742371095238096
    ],
    [
        88.2161,
        -0.5932000714285718
    ],
    [
        88.2175,
        -0.8216586238095241
    ],
    [
        88.219,
        -0.47671329523809547
    ],
    [
        88.2204,
        -0.9887132952380954
    ],
    [
        88.2218,
        -0.48484028095238074
    ],
    [
        88.2233,
        -0.6521207047619052
    ],
    [
        88.2247,
        0.04138194285714292
    ],
    [
        88.2262,
        0.9597311523809524
    ],
    [
        88.2276,
        0.8614333428571426
    ],
    [
        88.229,
        1.3174474523809523
    ],
    [
        88.2304,
        0.9788231142857141
    ],
    [
        88.2319,
        1.3323469238095238
    ],
    [
        88.2333,
        1.4290967333333333
    ],
    [
        88.2347,
        0.6326522904761903
    ],
    [
        88.2362,
        1.424130242857143
    ],
    [
        88.2376,
        0.6582587380952377
    ],
    [
        88.239,
        1.4987243476190475
    ],
    [
        88.2404,
        1.2310176238095234
    ],
    [
        88.2419,
        0.8815573047619047
    ],
    [
        88.2433,
        0.9500948714285714
    ],
    [
        88.2447,
        1.2239496095238094
    ],
    [
        88.2461,
        1.4722741238095236
    ],
    [
        88.2476,
        1.362108338095238
    ],
    [
        88.249,
        1.1645774714285713
    ],
    [
        88.2504,
        1.672513980952381
    ],
    [
        88.2518,
        0.3180166285714284
    ],
    [
        88.2532,
        0.9966198047619046
    ],
    [
        88.2546,
        1.1659319761904758
    ],
    [
        88.256,
        0.47784731904761885
    ],
    [
        88.2574,
        -0.1966923619047621
    ],
    [
        88.2588,
        -0.6463854809523809
    ],
    [
        88.2603,
        -0.17447860476190502
    ],
    [
        88.2617,
        0.052173952380952404
    ],
    [
        88.2631,
        -0.7136975523809527
    ],
    [
        88.2645,
        -0.5196658095238099
    ],
    [
        88.2659,
        -1.3291134285714288
    ],
    [
        88.2674,
        -1.3236954380952384
    ],
    [
        88.2688,
        -2.1587430571428574
    ],
    [
        88.2702,
        -1.379713580952381
    ],
    [
        88.2716,
        -1.664158023809524
    ],
    [
        88.273,
        -1.7761298047619047
    ],
    [
        88.2744,
        -2.195346733333334
    ],
    [
        88.2758,
        -2.2483773571428576
    ],
    [
        88.2772,
        -1.9923773571428574
    ],
    [
        88.2786,
        -1.743827090476191
    ],
    [
        88.28,
        -2.044254604761905
    ],
    [
        88.2814,
        -1.2572916428571428
    ],
    [
        88.2828,
        -1.5139688904761905
    ],
    [
        88.2842,
        -1.7260831761904765
    ],
    [
        88.2856,
        -1.6181748857142861
    ],
    [
        88.287,
        -0.9436352047619048
    ],
    [
        88.2884,
        -0.8808768285714286
    ],
    [
        88.2898,
        -1.0141593666666668
    ],
    [
        88.2912,
        -0.8307002238095238
    ],
    [
        88.2926,
        -0.32479546190476194
    ],
    [
        88.294,
        -1.055208157142857
    ],
    [
        88.2953,
        -0.5952208523809526
    ],
    [
        88.2967,
        -0.14733372380952403
    ],
    [
        88.2981,
        -0.30377817142857144
    ],
    [
        88.2994,
        -0.3362861095238096
    ],
    [
        88.3008,
        0.25698373333333296
    ],
    [
        88.3021,
        0.36895551428571405
    ],
    [
        88.3035,
        0.5439759190476189
    ],
    [
        88.3049,
        0.3263328190476188
    ],
    [
        88.3063,
        0.1958495714285714
    ],
    [
        88.3076,
        -0.2849969904761907
    ],
    [
        88.309,
        -0.15388164761904768
    ],
    [
        88.3104,
        0.3784358142857141
    ],
    [
        88.3117,
        -0.3401250333333335
    ],
    [
        88.3131,
        0.022338457142857104
    ],
    [
        88.3144,
        -0.17728865238095232
    ],
    [
        88.3158,
        -0.10565079047619041
    ],
    [
        88.3171,
        -0.10632803809523816
    ],
    [
        88.3185,
        0.05214815238095231
    ],
    [
        88.3198,
        0.1377824857142857
    ],
    [
        88.3211,
        0.0887948333333333
    ],
    [
        88.3225,
        0.5258459761904762
    ],
    [
        88.3238,
        -0.11483127619047635
    ],
    [
        88.3251,
        0.17819164761904752
    ],
    [
        88.3265,
        0.43042915714285723
    ],
    [
        88.3278,
        -0.15110170476190476
    ],
    [
        88.3292,
        -0.8987842476190476
    ],
    [
        88.3306,
        -0.9877940714285716
    ],
    [
        88.3319,
        -1.002306542857143
    ],
    [
        88.3332,
        -1.1573964904761906
    ],
    [
        88.3346,
        -0.7365993095238095
    ],
    [
        88.3359,
        -0.10404904285714289
    ],
    [
        88.3372,
        -0.2074423428571429
    ],
    [
        88.3385,
        -1.477960861904762
    ],
    [
        88.3398,
        -0.5325217095238093
    ],
    [
        88.3411,
        -1.4779608619047617
    ],
    [
        88.3424,
        -1.2318293428571427
    ],
    [
        88.3437,
        -1.5093508047619044
    ],
    [
        88.345,
        -1.3474883714285713
    ],
    [
        88.3463,
        -1.6603772619047614
    ],
    [
        88.3476,
        -1.2066206476190477
    ],
    [
        88.349,
        -0.3848922523809521
    ],
    [
        88.3503,
        -0.2769839619047616
    ],
    [
        88.3516,
        0.0925475428571429
    ],
    [
        88.3529,
        -0.08760060476190454
    ],
    [
        88.3542,
        0.13897670000000006
    ],
    [
        88.3555,
        -0.033947461904761805
    ],
    [
        88.3568,
        0.2261160333333334
    ],
    [
        88.3581,
        0.470215804761905
    ],
    [
        88.3594,
        1.0187872333333334
    ],
    [
        88.3607,
        1.2282828238095238
    ],
    [
        88.3619,
        1.329418623809524
    ],
    [
        88.3632,
        1.634180528571429
    ],
    [
        88.3645,
        1.7375738285714288
    ],
    [
        88.3658,
        2.2096161571428574
    ],
    [
        88.3672,
        2.0538489619047624
    ],
    [
        88.3685,
        2.523859542857143
    ],
    [
        88.3698,
        2.5231822904761905
    ],
    [
        88.3711,
        2.574653190476191
    ],
    [
        88.3724,
        2.6441840523809526
    ],
    [
        88.3737,
        2.592713152380952
    ],
    [
        88.375,
        1.8260676476190476
    ],
    [
        88.3763,
        1.3400739952380953
    ],
    [
        88.3776,
        1.3818828142857147
    ],
    [
        88.3789,
        1.4776211523809526
    ],
    [
        88.3802,
        1.428182
    ],
    [
        88.3815,
        1.3227569571428575
    ],
    [
        88.3828,
        0.8175294428571431
    ],
    [
        88.3841,
        0.45723314285714295
    ],
    [
        88.3854,
        0.14976224285714293
    ],
    [
        88.3867,
        -0.3757827285714287
    ],
    [
        88.388,
        -0.3590772571428569
    ],
    [
        88.3893,
        -0.3699132333333336
    ],
    [
        88.3906,
        -0.9902730238095239
    ],
    [
        88.3919,
        -0.6868656142857142
    ],
    [
        88.3931,
        -0.730886780952381
    ],
    [
        88.3944,
        -0.7315640285714289
    ],
    [
        88.3957,
        -0.7744564476190474
    ],
    [
        88.397,
        -0.7791971857142858
    ],
    [
        88.3983,
        -0.9241283999999998
    ],
    [
        88.3995,
        -1.5842201095238098
    ],
    [
        88.4008,
        -0.7769396857142856
    ],
    [
        88.4021,
        -0.7708444476190477
    ],
    [
        88.4033,
        -0.8275527333333333
    ],
    [
        88.4046,
        -1.0635061714285716
    ],
    [
        88.4059,
        -0.9219612000000004
    ],
    [
        88.4071,
        -0.8278236333333334
    ],
    [
        88.4084,
        -0.7623562619047619
    ],
    [
        88.4097,
        -0.298440919047619
    ],
    [
        88.4109,
        0.1973051142857143
    ],
    [
        88.4121,
        0.6463209857142859
    ],
    [
        88.4134,
        -0.03363668571428567
    ],
    [
        88.4147,
        0.17062151428571448
    ],
    [
        88.4159,
        -0.27229912380952376
    ],
    [
        88.4172,
        -0.5648705523809523
    ],
    [
        88.4184,
        -1.4480028285714286
    ],
    [
        88.4196,
        -1.247537219047619
    ],
    [
        88.4208,
        -1.3999181714285716
    ],
    [
        88.4221,
        -1.0869389809523808
    ],
    [
        88.4233,
        -1.4273693142857142
    ],
    [
        88.4245,
        -1.3212670238095239
    ],
    [
        88.4258,
        -0.6656903047619048
    ],
    [
        88.427,
        -1.3623534428571429
    ],
    [
        88.4282,
        -1.3120112904761905
    ],
    [
        88.4294,
        -1.3300712571428572
    ],
    [
        88.4307,
        -1.0963452142857142
    ],
    [
        88.4319,
        -1.245339919047619
    ],
    [
        88.4331,
        -1.3313504999999999
    ],
    [
        88.4343,
        -1.375033038095238
    ],
    [
        88.4355,
        -1.3437441476190477
    ],
    [
        88.4367,
        -1.7538859476190478
    ],
    [
        88.438,
        -1.9324086952380948
    ],
    [
        88.4392,
        -1.2556115190476191
    ],
    [
        88.4404,
        -1.6630443238095238
    ],
    [
        88.4416,
        -1.6351416761904762
    ],
    [
        88.4428,
        -1.1520376190476191
    ],
    [
        88.444,
        -0.8621751857142856
    ],
    [
        88.4452,
        -0.29057729999999987
    ],
    [
        88.4465,
        -0.22676542380952366
    ],
    [
        88.4477,
        -0.3920633571428571
    ],
    [
        88.4489,
        -0.3421727047619047
    ],
    [
        88.4501,
        -0.035695099999999994
    ],
    [
        88.4514,
        0.09162765238095243
    ],
    [
        88.4526,
        0.1052371238095239
    ],
    [
        88.4538,
        0.31753200952380956
    ],
    [
        88.455,
        0.41234682380952375
    ],
    [
        88.4562,
        0.3799893857142858
    ],
    [
        88.4574,
        0.5208571095238095
    ],
    [
        88.4586,
        0.7456069190476191
    ],
    [
        88.4598,
        0.6156428952380951
    ],
    [
        88.461,
        1.0130524190476191
    ],
    [
        88.4622,
        1.416421733333333
    ],
    [
        88.4634,
        1.861780461904762
    ],
    [
        88.4646,
        1.1985282571428573
    ],
    [
        88.4658,
        1.4883906904761905
    ],
    [
        88.467,
        1.0365303714285712
    ],
    [
        88.4682,
        0.2798178476190476
    ],
    [
        88.4694,
        0.3818566476190477
    ],
    [
        88.4706,
        -0.04684176666666647
    ],
    [
        88.4718,
        0.4609442428571428
    ],
    [
        88.473,
        -0.054257295238094826
    ],
    [
        88.4742,
        0.6507585761904766
    ],
    [
        88.4754,
        0.819258042857143
    ],
    [
        88.4766,
        0.44090178095238114
    ],
    [
        88.4777,
        1.0860295238095241
    ],
    [
        88.4789,
        0.6953924857142859
    ],
    [
        88.4801,
        0.7396394000000003
    ],
    [
        88.4813,
        0.7238369333333335
    ],
    [
        88.4825,
        0.9879639142857146
    ],
    [
        88.4837,
        1.4573939952380957
    ],
    [
        88.4849,
        1.626164366666667
    ],
    [
        88.4861,
        1.1606687761904764
    ],
    [
        88.4873,
        1.1525417904761908
    ],
    [
        88.4884,
        0.8640338523809529
    ],
    [
        88.4896,
        1.4302137476190477
    ],
    [
        88.4908,
        1.3399139238095241
    ],
    [
        88.492,
        2.0004571333333336
    ],
    [
        88.4931,
        2.4311872952380957
    ],
    [
        88.4943,
        2.5090257428571427
    ],
    [
        88.4954,
        2.5485319142857144
    ],
    [
        88.4966,
        1.8674906428571425
    ],
    [
        88.4978,
        2.524421861904762
    ],
    [
        88.4989,
        1.9609509619047618
    ],
    [
        88.5001,
        1.5875611952380955
    ],
    [
        88.5012,
        1.852139680952381
    ],
    [
        88.5024,
        1.563631742857143
    ],
    [
        88.5035,
        2.1108486761904763
    ],
    [
        88.5047,
        1.8456251952380955
    ],
    [
        88.5058,
        2.1390996190476192
    ],
    [
        88.507,
        1.4076710476190477
    ],
    [
        88.5081,
        1.4049620571428572
    ],
    [
        88.5093,
        1.8432774
    ],
    [
        88.5104,
        1.4757571190476189
    ],
    [
        88.5116,
        1.198085161904762
    ],
    [
        88.5127,
        1.0331073857142858
    ],
    [
        88.5139,
        0.9557075380952381
    ],
    [
        88.515,
        1.3155523333333332
    ],
    [
        88.5162,
        1.405400661904762
    ],
    [
        88.5173,
        1.5408503952380954
    ],
    [
        88.5185,
        1.5906357
    ],
    [
        88.5196,
        1.4864039047619046
    ],
    [
        88.5208,
        1.5958472904761907
    ],
    [
        88.5219,
        1.395652580952381
    ],
    [
        88.5231,
        1.6665520523809525
    ],
    [
        88.5242,
        1.2136984333333334
    ],
    [
        88.5254,
        1.4087460523809523
    ],
    [
        88.5265,
        1.4087460523809525
    ],
    [
        88.5276,
        1.4087460523809527
    ],
    [
        88.5288,
        1.3506961666666668
    ],
    [
        88.5299,
        1.2766503142857146
    ],
    [
        88.5311,
        1.1899624857142859
    ],
    [
        88.5322,
        0.37590957142857145
    ],
    [
        88.5334,
        -0.08416802857142841
    ],
    [
        88.5345,
        0.2878672428571429
    ],
    [
        88.5356,
        0.2878672428571429
    ],
    [
        88.5368,
        -0.001814590476190338
    ],
    [
        88.5379,
        0.01598737142857144
    ],
    [
        88.5391,
        -0.2118807571428571
    ],
    [
        88.5402,
        -0.6629283761904761
    ],
    [
        88.5414,
        -0.7712881666666666
    ],
    [
        88.5425,
        -0.6508884047619047
    ],
    [
        88.5436,
        -0.19539029523809504
    ],
    [
        88.5447,
        -0.2482156904761903
    ],
    [
        88.5459,
        0.03640935238095254
    ],
    [
        88.547,
        -0.23449011904761888
    ],
    [
        88.5481,
        0.38548267142857134
    ],
    [
        88.5492,
        0.02428337619047617
    ],
    [
        88.5504,
        -0.7735155666666665
    ],
    [
        88.5515,
        -0.9382224476190475
    ],
    [
        88.5526,
        -0.8894605428571426
    ],
    [
        88.5538,
        -0.8921695380952379
    ],
    [
        88.5549,
        -0.676533557142857
    ],
    [
        88.556,
        -0.3616744904761905
    ],
    [
        88.5571,
        -0.9824857761904762
    ],
    [
        88.5582,
        -1.3247221095238095
    ],
    [
        88.5593,
        -1.5847856000000002
    ],
    [
        88.5604,
        -1.536475195238095
    ],
    [
        88.5615,
        -1.4240519142857142
    ],
    [
        88.5627,
        -1.5957605047619043
    ],
    [
        88.5638,
        -1.846342519047619
    ],
    [
        88.5649,
        -2.2554007190476195
    ],
    [
        88.566,
        -2.835727585714286
    ],
    [
        88.5671,
        -3.8868175333333332
    ],
    [
        88.5682,
        -3.660616476190476
    ],
    [
        88.5693,
        -4.098570619047619
    ],
    [
        88.5704,
        -3.9251949571428573
    ],
    [
        88.5715,
        -4.088702138095239
    ],
    [
        88.5726,
        -4.261174800000002
    ],
    [
        88.5737,
        -3.685784323809525
    ],
    [
        88.5748,
        -3.858008661904764
    ],
    [
        88.5759,
        -3.696629980952383
    ],
    [
        88.577,
        -3.9738504428571444
    ],
    [
        88.578,
        -3.748823285714288
    ],
    [
        88.5791,
        -4.055555366666669
    ],
    [
        88.5802,
        -4.039301400000001
    ],
    [
        88.5813,
        -2.999047428571429
    ],
    [
        88.5824,
        -2.909650604761905
    ],
    [
        88.5835,
        -3.0396823523809533
    ],
    [
        88.5846,
        -3.0344148619047635
    ],
    [
        88.5857,
        -2.969060361904763
    ],
    [
        88.5868,
        -2.448933376190477
    ],
    [
        88.5879,
        -1.8274899904761905
    ],
    [
        88.589,
        -1.819363004761905
    ],
    [
        88.5901,
        -1.4059704095238093
    ],
    [
        88.5912,
        -1.449991576190476
    ],
    [
        88.5923,
        -1.0831936952380954
    ],
    [
        88.5933,
        -1.3428056904761907
    ],
    [
        88.5944,
        -0.9974088666666668
    ],
    [
        88.5955,
        -0.5675817095238097
    ],
    [
        88.5966,
        -0.7209108095238095
    ],
    [
        88.5977,
        -0.9081700666666667
    ],
    [
        88.5988,
        -0.9828609190476191
    ],
    [
        88.5999,
        -0.5539367571428574
    ],
    [
        88.601,
        -0.9810549238095241
    ],
    [
        88.6021,
        -0.6492030714285715
    ],
    [
        88.6032,
        0.37208793333333334
    ],
    [
        88.6043,
        -0.1480390523809525
    ],
    [
        88.6053,
        -0.10198614285714298
    ],
    [
        88.6064,
        0.327389519047619
    ],
    [
        88.6075,
        0.19770177142857145
    ],
    [
        88.6085,
        0.262379019047619
    ],
    [
        88.6096,
        0.3079393857142857
    ],
    [
        88.6107,
        0.15379759047619052
    ],
    [
        88.6117,
        0.16192457619047615
    ],
    [
        88.6128,
        0.7616960047619047
    ],
    [
        88.6139,
        0.7966871904761906
    ],
    [
        88.615,
        0.8597164666666667
    ],
    [
        88.616,
        0.8233707904761903
    ],
    [
        88.6171,
        0.40076761904761893
    ],
    [
        88.6181,
        0.7267499857142857
    ],
    [
        88.6192,
        0.9255902
    ],
    [
        88.6202,
        1.0549446952380952
    ],
    [
        88.6213,
        0.9323626857142857
    ],
    [
        88.6223,
        0.6750081904761903
    ],
    [
        88.6234,
        1.0535902047619046
    ],
    [
        88.6244,
        0.48741030952380965
    ],
    [
        88.6255,
        -0.019171700000000107
    ],
    [
        88.6265,
        0.1460769761904761
    ],
    [
        88.6275,
        0.16991612857142843
    ],
    [
        88.6286,
        0.1272494619047618
    ],
    [
        88.6296,
        0.36589899523809505
    ],
    [
        88.6306,
        0.5311476714285712
    ],
    [
        88.6317,
        0.48558730476190465
    ],
    [
        88.6327,
        0.46526984285714273
    ],
    [
        88.6338,
        1.1307795428571428
    ],
    [
        88.6348,
        0.4724938285714285
    ],
    [
        88.6358,
        0.06840211428571429
    ],
    [
        88.6369,
        -0.0016705476190476396
    ],
    [
        88.6379,
        -0.11883457142857135
    ],
    [
        88.639,
        0.04641410476190478
    ],
    [
        88.64,
        -0.28859824285714286
    ],
    [
        88.6411,
        -0.548300538095238
    ],
    [
        88.6421,
        -0.34490018571428566
    ],
    [
        88.6431,
        -0.2622758476190476
    ],
    [
        88.6442,
        -0.20267796190476192
    ],
    [
        88.6452,
        -0.3266144714285713
    ],
    [
        88.6462,
        0.16642256666666672
    ],
    [
        88.6472,
        0.16642256666666672
    ],
    [
        88.6482,
        0.0011738904761905158
    ],
    [
        88.6492,
        0.04167336190476187
    ],
    [
        88.6502,
        -0.013570780952380897
    ],
    [
        88.6512,
        -0.6628265095238096
    ],
    [
        88.6522,
        -0.9255989952380952
    ],
    [
        88.6533,
        -0.6844984666666666
    ],
    [
        88.6543,
        -0.5658445000000001
    ],
    [
        88.6553,
        -0.781059080952381
    ],
    [
        88.6563,
        -0.49661463809523826
    ],
    [
        88.6573,
        -0.3372354476190476
    ],
    [
        88.6583,
        -0.35430211428571434
    ],
    [
        88.6593,
        0.3622269857142857
    ],
    [
        88.6604,
        0.844428042857143
    ],
    [
        88.6614,
        0.6037790142857143
    ],
    [
        88.6624,
        0.6859518571428571
    ],
    [
        88.6634,
        0.6439971714285714
    ],
    [
        88.6644,
        0.5875146333333333
    ],
    [
        88.6654,
        1.0019908238095239
    ],
    [
        88.6665,
        1.0019908238095239
    ],
    [
        88.6675,
        0.7608902952380954
    ],
    [
        88.6685,
        0.8408056380952382
    ],
    [
        88.6695,
        0.6827809476190477
    ],
    [
        88.6705,
        0.8029925904761903
    ],
    [
        88.6715,
        0.6814748285714286
    ],
    [
        88.6725,
        1.3189915809523807
    ],
    [
        88.6735,
        1.337503042857143
    ],
    [
        88.6745,
        0.633164419047619
    ],
    [
        88.6755,
        0.819272357142857
    ],
    [
        88.6765,
        1.3469243285714285
    ],
    [
        88.6775,
        1.2250195666666666
    ],
    [
        88.6785,
        1.774042495238095
    ],
    [
        88.6795,
        1.574254138095238
    ],
    [
        88.6805,
        1.3290901190476192
    ],
    [
        88.6815,
        1.0798626047619047
    ],
    [
        88.6825,
        0.6884128666666666
    ],
    [
        88.6835,
        0.3127655999999999
    ],
    [
        88.6845,
        -0.18798165238095224
    ],
    [
        88.6854,
        0.36898765714285725
    ],
    [
        88.6864,
        -0.21954144761904756
    ],
    [
        88.6874,
        -0.21954144761904756
    ],
    [
        88.6884,
        0.13669135714285738
    ],
    [
        88.6894,
        0.12217888571428594
    ],
    [
        88.6904,
        0.18863955714285716
    ],
    [
        88.6914,
        0.22026706666666676
    ],
    [
        88.6924,
        0.19588611428571423
    ],
    [
        88.6933,
        -0.18427614285714283
    ],
    [
        88.6943,
        -0.5576659095238095
    ],
    [
        88.6953,
        -0.09442781428571417
    ],
    [
        88.6962,
        -0.43982463809523803
    ],
    [
        88.6972,
        -0.4614966
    ],
    [
        88.6982,
        -0.23529554285714283
    ],
    [
        88.6991,
        -0.6134712047619046
    ],
    [
        88.7001,
        -0.5498098285714287
    ],
    [
        88.701,
        -0.6909484523809523
    ],
    [
        88.702,
        -1.365488133333333
    ],
    [
        88.7029,
        -1.043117761904762
    ],
    [
        88.7039,
        -0.5383417476190475
    ],
    [
        88.7048,
        -0.1861724333333334
    ],
    [
        88.7058,
        -0.6467015333333334
    ],
    [
        88.7067,
        -0.311463438095238
    ],
    [
        88.7077,
        -0.7503205809523809
    ],
    [
        88.7086,
        -1.0126415714285715
    ],
    [
        88.7096,
        -1.0235259238095236
    ],
    [
        88.7105,
        -1.0782476190476187
    ],
    [
        88.7114,
        -1.3151943523809522
    ],
    [
        88.7124,
        -1.023977419047619
    ],
    [
        88.7133,
        -0.8718222142857144
    ],
    [
        88.7143,
        -0.2722313857142855
    ],
    [
        88.7152,
        -0.4916599571428569
    ],
    [
        88.7161,
        -0.23340246190476185
    ],
    [
        88.717,
        -0.6858045761904762
    ],
    [
        88.718,
        -1.0600973428571427
    ],
    [
        88.7189,
        -1.1505777666666666
    ],
    [
        88.7198,
        -0.6555089857142857
    ],
    [
        88.7207,
        -0.5279153333333333
    ],
    [
        88.7216,
        0.3497989523809524
    ],
    [
        88.7225,
        0.4115640333333333
    ],
    [
        88.7235,
        0.4242060047619048
    ],
    [
        88.7244,
        0.42759224761904757
    ],
    [
        88.7253,
        0.8610314
    ],
    [
        88.7262,
        0.6402483333333332
    ],
    [
        88.7272,
        1.0791054761904764
    ],
    [
        88.7281,
        0.7928550380952379
    ],
    [
        88.729,
        0.3076063619047617
    ],
    [
        88.7299,
        0.6719661523809523
    ],
    [
        88.7308,
        1.2453700285714284
    ],
    [
        88.7317,
        1.1397192333333332
    ],
    [
        88.7326,
        1.1642506857142854
    ],
    [
        88.7336,
        0.5782048285714285
    ],
    [
        88.7345,
        0.8599402761904761
    ],
    [
        88.7354,
        0.7127515619047616
    ],
    [
        88.7363,
        0.5682718428571426
    ],
    [
        88.7372,
        1.155220695238095
    ],
    [
        88.7381,
        1.2097392142857142
    ],
    [
        88.739,
        0.6990937095238097
    ],
    [
        88.7399,
        0.05299847142857165
    ],
    [
        88.7408,
        -0.3831496761904762
    ],
    [
        88.7417,
        0.13210111428571436
    ],
    [
        88.7427,
        0.06979423809523813
    ],
    [
        88.7436,
        -0.03517930952380946
    ],
    [
        88.7445,
        -0.040597299999999864
    ],
    [
        88.7454,
        -0.14760259047619034
    ],
    [
        88.7463,
        0.06776249047619058
    ],
    [
        88.7472,
        0.643875361904762
    ],
    [
        88.7481,
        1.2926795952380954
    ],
    [
        88.749,
        0.646584357142857
    ],
    [
        88.7499,
        0.07318048095238092
    ],
    [
        88.7508,
        0.3941963523809524
    ],
    [
        88.7517,
        0.3701163999999999
    ],
    [
        88.7526,
        0.8712804190476191
    ],
    [
        88.7535,
        1.234285714285714
    ],
    [
        88.7544,
        1.0889029999999997
    ],
    [
        88.7553,
        1.3403880095238097
    ],
    [
        88.7562,
        0.8301940047619047
    ],
    [
        88.7571,
        0.8368213666666667
    ],
    [
        88.758,
        0.8230054952380951
    ],
    [
        88.7589,
        1.1453758619047616
    ],
    [
        88.7598,
        1.2862435857142855
    ],
    [
        88.7608,
        1.0722330047619046
    ],
    [
        88.7617,
        0.863640414285714
    ],
    [
        88.7626,
        0.6509843285714283
    ],
    [
        88.7635,
        0.2833350476190475
    ],
    [
        88.7644,
        0.32880745714285725
    ],
    [
        88.7653,
        0.22044766666666665
    ],
    [
        88.7662,
        0.07777394761904749
    ],
    [
        88.7671,
        -0.35701970476190475
    ],
    [
        88.768,
        -0.35701970476190475
    ],
    [
        88.7689,
        -0.6423671476190476
    ],
    [
        88.7698,
        -1.0195946571428571
    ],
    [
        88.7706,
        -1.019143161904762
    ],
    [
        88.7715,
        -0.936970319047619
    ],
    [
        88.7724,
        -1.3622824904761905
    ],
    [
        88.7733,
        -1.3259368142857142
    ],
    [
        88.7742,
        -1.3725702238095236
    ],
    [
        88.7751,
        -1.4439070809523809
    ],
    [
        88.776,
        -1.6450176904761906
    ],
    [
        88.7769,
        -1.982016633333333
    ],
    [
        88.7778,
        -1.6623552571428568
    ],
    [
        88.7787,
        -1.7568690714285713
    ],
    [
        88.7796,
        -2.0128690714285717
    ],
    [
        88.7805,
        -1.720297642857143
    ],
    [
        88.7814,
        -1.3986045190476193
    ],
    [
        88.7823,
        -1.5280557666666668
    ],
    [
        88.7832,
        -1.5257337714285715
    ],
    [
        88.7841,
        -1.5727541809523813
    ],
    [
        88.785,
        -1.1420240238095243
    ],
    [
        88.7859,
        -0.8548705857142859
    ],
    [
        88.7868,
        -0.8629975714285714
    ],
    [
        88.7877,
        -0.5392727047619048
    ],
    [
        88.7885,
        -0.38147376666666666
    ],
    [
        88.7894,
        -0.30968540476190476
    ],
    [
        88.7903,
        -0.3837312619047618
    ],
    [
        88.7912,
        -0.8036254428571427
    ],
    [
        88.792,
        -0.8327471333333333
    ],
    [
        88.7929,
        -0.7540572857142858
    ],
    [
        88.7938,
        -0.696446
    ],
    [
        88.7947,
        -0.9736664571428572
    ],
    [
        88.7956,
        -0.4665426476190475
    ],
    [
        88.7965,
        -0.7855267714285713
    ],
    [
        88.7974,
        -0.7489854428571429
    ],
    [
        88.7983,
        -0.7909748619047619
    ],
    [
        88.7992,
        -0.45505951904761915
    ],
    [
        88.8001,
        -0.7740436476190475
    ],
    [
        88.8009,
        -0.7049642809523808
    ],
    [
        88.8018,
        -0.5771255285714286
    ],
    [
        88.8027,
        -1.0542955952380955
    ],
    [
        88.8036,
        -1.9103379238095235
    ],
    [
        88.8045,
        -2.397956971428571
    ],
    [
        88.8054,
        -2.381703004761905
    ],
    [
        88.8062,
        -2.6891739047619048
    ],
    [
        88.8071,
        -2.7968564428571425
    ],
    [
        88.808,
        -2.658697714285714
    ],
    [
        88.8089,
        -2.8327506238095235
    ],
    [
        88.8098,
        -2.4952098809523813
    ],
    [
        88.8106,
        -2.2188924238095242
    ],
    [
        88.8115,
        -2.4403075904761913
    ],
    [
        88.8124,
        -2.303435633333334
    ],
    [
        88.8133,
        -1.9680620857142859
    ],
    [
        88.8141,
        -2.2584663190476193
    ],
    [
        88.815,
        -1.9462546809523813
    ],
    [
        88.8159,
        -1.9446292857142857
    ],
    [
        88.8168,
        -1.8606504476190475
    ],
    [
        88.8176,
        -2.2805446285714286
    ],
    [
        88.8185,
        -1.8579414523809528
    ],
    [
        88.8194,
        -2.0611160571428577
    ],
    [
        88.8202,
        -1.7179767285714285
    ],
    [
        88.8211,
        -1.4344352809523804
    ],
    [
        88.822,
        -0.9102448047619041
    ],
    [
        88.8228,
        -0.4930596190476192
    ],
    [
        88.8237,
        -0.07316543809523816
    ],
    [
        88.8246,
        0.3014885285714285
    ],
    [
        88.8254,
        0.24151439523809523
    ],
    [
        88.8263,
        -0.29757555238095235
    ],
    [
        88.8272,
        -0.2603268761904761
    ],
    [
        88.8281,
        0.025201166666666677
    ],
    [
        88.8289,
        -0.5111797857142857
    ],
    [
        88.8298,
        -0.3615529809523809
    ],
    [
        88.8306,
        -0.4946323476190475
    ],
    [
        88.8314,
        -0.28098296666666667
    ],
    [
        88.8323,
        -0.07509936666666665
    ],
    [
        88.8331,
        -0.14779072380952377
    ],
    [
        88.8339,
        -0.3318669142857142
    ],
    [
        88.8348,
        -0.26639954285714273
    ],
    [
        88.8356,
        -0.3646005999999999
    ],
    [
        88.8364,
        -0.29868172857142844
    ],
    [
        88.8373,
        -0.13855003809523814
    ],
    [
        88.8381,
        -0.5503172333333333
    ],
    [
        88.839,
        -0.22433487142857136
    ],
    [
        88.8398,
        -0.7078904238095237
    ],
    [
        88.8407,
        -0.3177951857142856
    ],
    [
        88.8415,
        -0.3177951857142856
    ],
    [
        88.8423,
        -0.37414227619047613
    ],
    [
        88.8432,
        -0.34139353809523804
    ],
    [
        88.844,
        0.05953768095238091
    ],
    [
        88.8448,
        -0.003220695238095317
    ],
    [
        88.8456,
        -0.01405667619047621
    ],
    [
        88.8465,
        0.28799623333333324
    ],
    [
        88.8473,
        0.31824667619047614
    ],
    [
        88.8481,
        0.02025726190476189
    ],
    [
        88.8489,
        -0.11158048095238104
    ],
    [
        88.8497,
        -0.11158048095238104
    ],
    [
        88.8505,
        -0.1486034095238096
    ],
    [
        88.8513,
        -0.24409547142857146
    ],
    [
        88.8521,
        -0.6888221000000001
    ],
    [
        88.8529,
        -0.39963691428571435
    ],
    [
        88.8537,
        -0.653830919047619
    ],
    [
        88.8545,
        -0.6871816571428572
    ],
    [
        88.8554,
        -0.4989065238095239
    ],
    [
        88.8561,
        -0.49890652380952377
    ],
    [
        88.857,
        -0.30656790000000006
    ],
    [
        88.8578,
        -0.5991393285714286
    ],
    [
        88.8586,
        -0.9838165761904762
    ],
    [
        88.8594,
        -1.2696155142857142
    ],
    [
        88.8602,
        -1.3460091666666667
    ],
    [
        88.861,
        -1.2498398523809524
    ],
    [
        88.8618,
        -1.4864253904761906
    ],
    [
        88.8626,
        -1.8643301523809526
    ],
    [
        88.8634,
        -1.3997375571428572
    ],
    [
        88.8642,
        -1.5498932619047618
    ],
    [
        88.865,
        -1.0958657523809525
    ],
    [
        88.8658,
        -1.188648819047619
    ],
    [
        88.8666,
        -1.001728185714286
    ],
    [
        88.8674,
        -1.0462331
    ],
    [
        88.8682,
        -1.2250267523809524
    ],
    [
        88.869,
        -0.9076228761904762
    ],
    [
        88.8698,
        -1.0065011857142856
    ],
    [
        88.8706,
        -0.444384780952381
    ],
    [
        88.8714,
        -0.7364144095238095
    ],
    [
        88.8721,
        -1.047497304761905
    ],
    [
        88.8729,
        -1.6028412190476193
    ],
    [
        88.8737,
        -1.3486472142857142
    ],
    [
        88.8745,
        -1.8145943047619049
    ],
    [
        88.8753,
        -1.7983403380952379
    ],
    [
        88.8761,
        -1.233514942857143
    ],
    [
        88.8768,
        -1.294918823809524
    ],
    [
        88.8776,
        -1.2068765000000001
    ],
    [
        88.8784,
        -1.024019357142857
    ],
    [
        88.8792,
        -0.8384532190476192
    ],
    [
        88.8799,
        -1.575299780952381
    ],
    [
        88.8807,
        -1.4484414285714284
    ],
    [
        88.8815,
        -1.3362890476190477
    ],
    [
        88.8823,
        -1.4290721190476192
    ],
    [
        88.883,
        -1.6528350809523813
    ],
    [
        88.8838,
        -1.514869847619048
    ],
    [
        88.8846,
        -1.0929439190476193
    ],
    [
        88.8853,
        -1.0310885380952384
    ],
    [
        88.8861,
        -1.5024536142857143
    ],
    [
        88.8869,
        -1.7471661380952384
    ],
    [
        88.8877,
        -1.4329227523809525
    ],
    [
        88.8885,
        -0.9200197523809527
    ],
    [
        88.8892,
        -0.49290158571428566
    ],
    [
        88.89,
        -0.6509262809523809
    ],
    [
        88.8908,
        -0.4761961238095238
    ],
    [
        88.8916,
        -0.0698469190476191
    ],
    [
        88.8924,
        -0.31501093809523817
    ],
    [
        88.8932,
        -0.31546243809523816
    ],
    [
        88.894,
        -0.42490582380952385
    ],
    [
        88.8948,
        -0.14791111428571438
    ],
    [
        88.8956,
        -0.5190433904761905
    ],
    [
        88.8964,
        -0.24204868095238105
    ],
    [
        88.8972,
        -0.24204868095238102
    ],
    [
        88.898,
        -0.49037319523809536
    ],
    [
        88.8989,
        -0.48630970476190477
    ],
    [
        88.8997,
        -0.26254674285714286
    ],
    [
        88.9004,
        -0.08510759047619056
    ],
    [
        88.9012,
        -0.07178836666666674
    ],
    [
        88.902,
        -0.2532910142857144
    ],
    [
        88.9028,
        0.3101798809523809
    ],
    [
        88.9036,
        0.13533684761904763
    ],
    [
        88.9043,
        0.30871250952380946
    ],
    [
        88.9051,
        0.2870405523809523
    ],
    [
        88.9059,
        0.1492430190476191
    ],
    [
        88.9067,
        0.3368409047619047
    ],
    [
        88.9074,
        0.8935393190476191
    ],
    [
        88.9082,
        0.7648620714285714
    ],
    [
        88.909,
        1.1847562476190479
    ],
    [
        88.9098,
        1.127415861904762
    ],
    [
        88.9105,
        1.1135999904761906
    ],
    [
        88.9113,
        0.8352507857142858
    ],
    [
        88.912,
        1.3878857095238095
    ],
    [
        88.9128,
        1.539815161904762
    ],
    [
        88.9136,
        1.414117804761905
    ],
    [
        88.9144,
        1.8358179809523814
    ],
    [
        88.9151,
        1.6624423238095243
    ],
    [
        88.9159,
        1.8358179857142862
    ],
    [
        88.9166,
        1.8222730095238096
    ],
    [
        88.9174,
        1.8800648952380952
    ],
    [
        88.9182,
        1.9905918809523808
    ],
    [
        88.9189,
        1.7163061666666668
    ],
    [
        88.9197,
        1.8070574904761905
    ],
    [
        88.9205,
        1.5104225714285715
    ],
    [
        88.9212,
        1.2620980571428573
    ],
    [
        88.922,
        1.2648070523809527
    ],
    [
        88.9227,
        0.9932303333333339
    ],
    [
        88.9235,
        0.45278589047619067
    ],
    [
        88.9242,
        0.7236853619047621
    ],
    [
        88.925,
        0.1873044095238093
    ],
    [
        88.9257,
        0.12635202857142863
    ],
    [
        88.9265,
        0.12499753333333348
    ],
    [
        88.9272,
        0.18820740952380974
    ],
    [
        88.928,
        0.09745608571428568
    ],
    [
        88.9287,
        -0.008759080952381067
    ],
    [
        88.9295,
        0.11639647619047633
    ],
    [
        88.9302,
        -0.05968818095238104
    ],
    [
        88.931,
        0.11097848571428581
    ],
    [
        88.9317,
        -0.19745990952380968
    ],
    [
        88.9325,
        -0.6243974714285716
    ],
    [
        88.9332,
        -0.6234944714285715
    ],
    [
        88.934,
        -0.37778865238095255
    ],
    [
        88.9347,
        -0.40329835238095263
    ],
    [
        88.9355,
        -0.5350232190476192
    ],
    [
        88.9363,
        -0.1828539095238097
    ],
    [
        88.9371,
        0.04379864761904736
    ],
    [
        88.9378,
        0.07143039523809509
    ],
    [
        88.9386,
        0.29070846666666655
    ],
    [
        88.9394,
        0.3807825380952381
    ],
    [
        88.9401,
        0.2593292761904761
    ],
    [
        88.9409,
        0.3947790142857142
    ],
    [
        88.9416,
        0.6629694904761905
    ],
    [
        88.9423,
        0.6033716047619048
    ],
    [
        88.9431,
        0.5509977047619048
    ],
    [
        88.9438,
        0.2841617238095238
    ],
    [
        88.9445,
        0.5046061666666666
    ],
    [
        88.9453,
        0.25483685238095233
    ],
    [
        88.946,
        0.12932010000000002
    ],
    [
        88.9467,
        0.40225131428571426
    ],
    [
        88.9475,
        0.2930530285714285
    ],
    [
        88.9482,
        0.3651122857142858
    ],
    [
        88.9489,
        0.17728865238095232
    ],
    [
        88.9497,
        0.05132039999999997
    ],
    [
        88.9504,
        0.0522234
    ],
    [
        88.9511,
        0.08202233809523808
    ],
    [
        88.9519,
        -0.023357552380952374
    ],
    [
        88.9526,
        -0.19537871904761908
    ],
    [
        88.9533,
        -0.09424291904761908
    ],
    [
        88.9541,
        -0.18329574761904754
    ],
    [
        88.9548,
        -0.009242838095238081
    ],
    [
        88.9555,
        -0.24402238095238088
    ],
    [
        88.9563,
        0.14607285714285717
    ],
    [
        88.957,
        0.058030528571428666
    ],
    [
        88.9577,
        0.058030528571428666
    ],
    [
        88.9584,
        -0.10721814761904762
    ],
    [
        88.9591,
        0.00005804285714284765
    ],
    [
        88.9599,
        -0.08933878095238097
    ],
    [
        88.9606,
        0.20232965238095235
    ],
    [
        88.9613,
        0.03075998571428572
    ],
    [
        88.9621,
        0.2779557523809523
    ],
    [
        88.9628,
        0.3352961380952381
    ],
    [
        88.9635,
        0.16733846666666669
    ],
    [
        88.9643,
        0.2350633333333333
    ],
    [
        88.965,
        0.29827320952380953
    ],
    [
        88.9657,
        0.37593105714285713
    ],
    [
        88.9664,
        0.46103864285714286
    ],
    [
        88.9671,
        0.2264397
    ],
    [
        88.9679,
        -0.27878780952380955
    ],
    [
        88.9686,
        -0.006082342857142862
    ],
    [
        88.9693,
        0.006817633333333332
    ],
    [
        88.97,
        0.08537848095238094
    ],
    [
        88.9707,
        0.42806631428571423
    ],
    [
        88.9714,
        0.1932867714285714
    ],
    [
        88.9721,
        0.2765883571428571
    ],
    [
        88.9728,
        0.1919322714285714
    ],
    [
        88.9735,
        0.698514280952381
    ],
    [
        88.9742,
        0.4368253952380952
    ],
    [
        88.9749,
        0.45985185238095233
    ],
    [
        88.9756,
        0.30295590476190487
    ],
    [
        88.9763,
        0.09346031428571422
    ],
    [
        88.977,
        -0.16118518571428564
    ],
    [
        88.9777,
        -0.49168253809523804
    ],
    [
        88.9784,
        -0.16389418095238084
    ],
    [
        88.9791,
        -0.2107210904761904
    ],
    [
        88.9798,
        -0.2820579523809523
    ],
    [
        88.9805,
        -0.3524918142857143
    ],
    [
        88.9812,
        -0.3184036333333333
    ],
    [
        88.9819,
        -0.31434014285714273
    ],
    [
        88.9826,
        -0.2899591904761905
    ],
    [
        88.9833,
        -0.5166117476190475
    ],
    [
        88.9839,
        -0.7938322047619047
    ],
    [
        88.9846,
        -1.021929557142857
    ],
    [
        88.9853,
        -1.1262258571428572
    ],
    [
        88.986,
        -1.3429454333333335
    ],
    [
        88.9867,
        -1.5318978142857145
    ],
    [
        88.9874,
        -1.4079613047619048
    ],
    [
        88.988,
        -1.655157071428572
    ],
    [
        88.9887,
        -1.3691646333333334
    ],
    [
        88.9893,
        -1.4237960285714288
    ],
    [
        88.99,
        -1.7048542285714288
    ],
    [
        88.9906,
        -1.336430947619048
    ],
    [
        88.9913,
        -1.4465146428571432
    ],
    [
        88.992,
        -1.2785569714285718
    ],
    [
        88.9926,
        -1.3919477476190476
    ],
    [
        88.9933,
        -1.3083989095238096
    ],
    [
        88.9939,
        -1.3744567
    ],
    [
        88.9946,
        -1.2197860000000003
    ],
    [
        88.9953,
        -1.337175766666667
    ],
    [
        88.9959,
        -1.5064879333333336
    ],
    [
        88.9966,
        -1.0893027523809526
    ],
    [
        88.9973,
        -1.0251898761904765
    ],
    [
        88.998,
        -0.8211122761904764
    ],
    [
        88.9986,
        -0.6763165095238095
    ],
    [
        88.9993,
        -1.0664117476190476
    ],
    [
        89,
        -0.9983858809523808
    ],
    [
        89.0007,
        -0.8521001666666665
    ],
    [
        89.0014,
        -0.7830208000000001
    ],
    [
        89.0021,
        -1.0261530761904762
    ],
    [
        89.0028,
        -1.1428333476190478
    ],
    [
        89.0035,
        -1.035376557142857
    ],
    [
        89.0042,
        -0.775313061904762
    ],
    [
        89.0049,
        -0.6642442761904762
    ],
    [
        89.0056,
        -0.9578007952380952
    ],
    [
        89.0063,
        -1.1149224904761905
    ],
    [
        89.007,
        -1.0539056095238095
    ],
    [
        89.0076,
        -1.1962783333333333
    ],
    [
        89.0083,
        -1.0916173666666666
    ],
    [
        89.009,
        -1.466619638095238
    ],
    [
        89.0097,
        -1.3316214047619048
    ],
    [
        89.0104,
        -1.0650563285714287
    ],
    [
        89.011,
        -1.0704743142857143
    ],
    [
        89.0117,
        -1.3941991809523808
    ],
    [
        89.0123,
        -1.6036947714285712
    ],
    [
        89.013,
        -1.6341709619047617
    ],
    [
        89.0137,
        -1.3681476809523807
    ],
    [
        89.0144,
        -1.6402511476190473
    ],
    [
        89.015,
        -1.6808860666666665
    ],
    [
        89.0157,
        -1.7451279428571427
    ],
    [
        89.0164,
        -1.4676494857142854
    ],
    [
        89.0171,
        -1.4581680047619048
    ],
    [
        89.0177,
        -1.302400809523809
    ],
    [
        89.0184,
        -1.0829722380952378
    ],
    [
        89.0191,
        -1.0856812333333328
    ],
    [
        89.0197,
        -0.842548957142857
    ],
    [
        89.0203,
        -0.6258293809523812
    ],
    [
        89.021,
        -0.6526935809523811
    ],
    [
        89.0216,
        -0.7014554857142857
    ],
    [
        89.0223,
        -0.7358016666666668
    ],
    [
        89.023,
        -0.48521965238095244
    ],
    [
        89.0236,
        -0.4107222952380953
    ],
    [
        89.0243,
        -0.3847159428571429
    ],
    [
        89.0249,
        -0.41383763809523816
    ],
    [
        89.0256,
        -0.15512864285714295
    ],
    [
        89.0262,
        0.3189454333333333
    ],
    [
        89.0269,
        -0.03322388095238099
    ],
    [
        89.0276,
        0.02854119999999997
    ],
    [
        89.0282,
        0.18430839523809533
    ],
    [
        89.0289,
        0.18430839523809533
    ],
    [
        89.0295,
        0.14019048095238104
    ],
    [
        89.0302,
        -0.12103400476190468
    ],
    [
        89.0308,
        -0.2781556952380952
    ],
    [
        89.0314,
        -0.5951080761904762
    ],
    [
        89.0321,
        -0.6519969666666667
    ],
    [
        89.0327,
        -0.9066424714285715
    ],
    [
        89.0334,
        -1.1226848
    ],
    [
        89.034,
        -0.9195101952380953
    ],
    [
        89.0346,
        -0.8524625761904762
    ],
    [
        89.0353,
        -0.8483990809523809
    ],
    [
        89.0359,
        -0.8336931095238097
    ],
    [
        89.0366,
        -0.7986696809523809
    ],
    [
        89.0372,
        -1.0132220619047618
    ],
    [
        89.0378,
        -0.9331132190476191
    ],
    [
        89.0385,
        -1.3022137476190476
    ],
    [
        89.0391,
        -1.346641261904762
    ],
    [
        89.0397,
        -1.5303111047619051
    ],
    [
        89.0404,
        -1.1578243285714287
    ],
    [
        89.041,
        -1.1578243285714287
    ],
    [
        89.0416,
        -1.3027555476190475
    ],
    [
        89.0422,
        -1.4011823571428572
    ],
    [
        89.0429,
        -1.3582254428571428
    ],
    [
        89.0435,
        -1.3528074571428572
    ],
    [
        89.0441,
        -0.8936328571428572
    ],
    [
        89.0447,
        -0.9920596666666668
    ],
    [
        89.0453,
        -1.167467076190476
    ],
    [
        89.0459,
        -0.9670014666666669
    ],
    [
        89.0466,
        -0.5965464380952383
    ],
    [
        89.0472,
        -0.8498374428571428
    ],
    [
        89.0478,
        -0.8963418476190477
    ],
    [
        89.0484,
        -0.7842571952380953
    ],
    [
        89.049,
        -0.8438550809523809
    ],
    [
        89.0497,
        -0.7630689857142859
    ],
    [
        89.0503,
        -0.8559875047619049
    ],
    [
        89.0509,
        -0.5947630142857141
    ],
    [
        89.0516,
        -0.36395666666666665
    ],
    [
        89.0522,
        -0.3723545523809523
    ],
    [
        89.0528,
        -0.4934466142857142
    ],
    [
        89.0535,
        -0.8713513761904763
    ],
    [
        89.0541,
        -0.9099545523809525
    ],
    [
        89.0547,
        -0.5690082190476191
    ],
    [
        89.0554,
        -0.3947295571428572
    ],
    [
        89.056,
        -0.5880356761904763
    ],
    [
        89.0567,
        -0.21351715714285713
    ],
    [
        89.0573,
        -0.3969762952380953
    ],
    [
        89.058,
        -0.26265530476190474
    ],
    [
        89.0586,
        -0.3364754095238095
    ],
    [
        89.0592,
        -0.48456711904761895
    ],
    [
        89.0599,
        -0.8550221476190477
    ],
    [
        89.0605,
        -0.8455406666666666
    ],
    [
        89.0611,
        -0.9241709761904763
    ],
    [
        89.0618,
        -0.9604037761904761
    ],
    [
        89.0624,
        -1.1012715
    ],
    [
        89.063,
        -1.2346894904761907
    ],
    [
        89.0637,
        -1.0457371095238097
    ],
    [
        89.0643,
        -1.4060334095238096
    ],
    [
        89.0649,
        -1.4864905523809524
    ],
    [
        89.0655,
        -1.6801836714285714
    ],
    [
        89.0661,
        -1.6693476952380952
    ],
    [
        89.0667,
        -1.0787868523809523
    ],
    [
        89.0673,
        -0.9704270619047619
    ],
    [
        89.068,
        -1.0544058952380955
    ],
    [
        89.0685,
        -1.103660342857143
    ],
    [
        89.0691,
        -1.0508349476190477
    ],
    [
        89.0698,
        -1.2695862714285715
    ],
    [
        89.0703,
        -1.0915451190476193
    ],
    [
        89.0709,
        -1.2683070238095242
    ],
    [
        89.0715,
        -1.0767423952380952
    ],
    [
        89.0721,
        -1.0986401047619045
    ],
    [
        89.0727,
        -0.8981744952380952
    ],
    [
        89.0733,
        -1.0363332238095238
    ],
    [
        89.0739,
        -1.1157276095238096
    ],
    [
        89.0745,
        -1.0125149142857142
    ],
    [
        89.0751,
        -0.9691709999999999
    ],
    [
        89.0758,
        -0.9454672952380951
    ],
    [
        89.0764,
        -0.6914990428571425
    ],
    [
        89.077,
        -0.6346101523809522
    ],
    [
        89.0776,
        -0.6359646476190476
    ],
    [
        89.0782,
        -0.3461022142857142
    ],
    [
        89.0788,
        -0.13547787619047616
    ],
    [
        89.0794,
        -0.3635752285714286
    ],
    [
        89.08,
        -0.4811907476190475
    ],
    [
        89.0806,
        -0.2979466047619046
    ],
    [
        89.0812,
        -0.0373905714285713
    ],
    [
        89.0818,
        0.01949831904761918
    ],
    [
        89.0824,
        0.4434559952380952
    ],
    [
        89.083,
        0.39424259047619054
    ],
    [
        89.0836,
        0.3942425904761905
    ],
    [
        89.0842,
        0.3879054761904761
    ],
    [
        89.0848,
        0.401315
    ],
    [
        89.0854,
        0.4386991285714286
    ],
    [
        89.086,
        0.7922229380952381
    ],
    [
        89.0866,
        0.7533940142857144
    ],
    [
        89.0872,
        0.8669008952380953
    ],
    [
        89.0878,
        1.0218553904761905
    ],
    [
        89.0884,
        0.9534532714285714
    ],
    [
        89.089,
        0.5669700285714286
    ],
    [
        89.0896,
        0.5973709714285714
    ],
    [
        89.0902,
        0.7210172285714285
    ],
    [
        89.0908,
        0.6713523238095238
    ],
    [
        89.0914,
        0.6265923428571428
    ],
    [
        89.092,
        0.5412590095238095
    ],
    [
        89.0926,
        0.6301398333333331
    ],
    [
        89.0932,
        0.34704988571428547
    ],
    [
        89.0938,
        0.05989644761904763
    ],
    [
        89.0944,
        0.1005313666666666
    ],
    [
        89.095,
        -0.22545099999999996
    ],
    [
        89.0956,
        -0.22815999523809521
    ],
    [
        89.0961,
        0.04951196190476191
    ],
    [
        89.0967,
        0.15279238571428572
    ],
    [
        89.0974,
        -0.06717798571428572
    ],
    [
        89.098,
        -0.0650107904761905
    ],
    [
        89.0986,
        0.13410031904761904
    ],
    [
        89.0992,
        0.22981813333333334
    ],
    [
        89.0998,
        0.3720403523809524
    ],
    [
        89.1004,
        0.40761848571428577
    ],
    [
        89.101,
        0.49972430476190477
    ],
    [
        89.1015,
        0.7372128380952382
    ],
    [
        89.1021,
        0.7533163047619048
    ],
    [
        89.1027,
        1.044146238095238
    ],
    [
        89.1033,
        0.8599346000000002
    ],
    [
        89.1039,
        0.7645041047619049
    ],
    [
        89.1044,
        1.0562628333333335
    ],
    [
        89.105,
        1.286140385714286
    ],
    [
        89.1056,
        1.3592832428571429
    ],
    [
        89.1062,
        1.4504860666666666
    ],
    [
        89.1068,
        1.312327338095238
    ],
    [
        89.1074,
        1.5001509714285715
    ],
    [
        89.108,
        1.545300880952381
    ],
    [
        89.1085,
        1.369022723809524
    ],
    [
        89.1091,
        1.2999433571428571
    ],
    [
        89.1097,
        1.8552872761904762
    ],
    [
        89.1102,
        1.8166389523809523
    ],
    [
        89.1108,
        1.6004611761904766
    ],
    [
        89.1114,
        1.5307045619047623
    ],
    [
        89.1119,
        1.3015236095238099
    ],
    [
        89.1125,
        1.0717105571428573
    ],
    [
        89.1131,
        1.0622290761904767
    ],
    [
        89.1137,
        0.9678657619047623
    ],
    [
        89.1143,
        1.0635835761904768
    ],
    [
        89.1149,
        0.7475341952380952
    ],
    [
        89.1154,
        0.8816294333333333
    ],
    [
        89.116,
        0.6743913380952384
    ],
    [
        89.1166,
        0.5028216761904764
    ],
    [
        89.1172,
        0.18677229523809522
    ],
    [
        89.1178,
        0.18744954285714294
    ],
    [
        89.1183,
        0.15042661428571433
    ],
    [
        89.1189,
        0.15584460000000006
    ],
    [
        89.1195,
        -0.2613405857142857
    ],
    [
        89.1201,
        -0.3607993904761904
    ],
    [
        89.1207,
        -0.3897275809523809
    ],
    [
        89.1213,
        -0.3247117095238095
    ],
    [
        89.1219,
        -0.7378334047619047
    ],
    [
        89.1224,
        -0.38385809999999987
    ],
    [
        89.123,
        -0.7094792666666665
    ],
    [
        89.1236,
        -0.37695016666666653
    ],
    [
        89.1241,
        -0.6949861428571429
    ],
    [
        89.1247,
        -0.5581819095238093
    ],
    [
        89.1253,
        -0.4487385238095236
    ],
    [
        89.1258,
        -0.5316208619047617
    ],
    [
        89.1264,
        -0.740213457142857
    ],
    [
        89.127,
        -0.6586856190476189
    ],
    [
        89.1275,
        -0.4505445238095237
    ],
    [
        89.1281,
        -0.11192018571428555
    ],
    [
        89.1287,
        -0.22578005714285712
    ],
    [
        89.1292,
        -0.11696877142857136
    ],
    [
        89.1298,
        -0.049921152380952225
    ],
    [
        89.1303,
        -0.3587465523809523
    ],
    [
        89.1309,
        -0.2260058095238095
    ],
    [
        89.1314,
        0.05031165238095238
    ],
    [
        89.132,
        0.13757998095238091
    ],
    [
        89.1325,
        -0.04595441428571427
    ],
    [
        89.133,
        -0.18140414761904763
    ],
    [
        89.1336,
        0.004703790476190459
    ],
    [
        89.1341,
        -0.2839546428571429
    ],
    [
        89.1346,
        0.1196855714285714
    ],
    [
        89.1351,
        -0.08944881904761909
    ],
    [
        89.1357,
        0.13539774285714284
    ],
    [
        89.1362,
        0.15774694761904762
    ],
    [
        89.1367,
        0.08419774285714283
    ],
    [
        89.1372,
        0.14495662380952382
    ],
    [
        89.1378,
        0.16039789523809522
    ],
    [
        89.1383,
        0.08725503809523809
    ],
    [
        89.1389,
        -0.10305184285714285
    ],
    [
        89.1394,
        -0.03126348095238095
    ],
    [
        89.14,
        -0.00860643333333332
    ],
    [
        89.1405,
        0.187795680952381
    ],
    [
        89.1411,
        0.13686658095238102
    ],
    [
        89.1416,
        0.45788245714285725
    ],
    [
        89.1422,
        0.45788245714285725
    ],
    [
        89.1427,
        0.45788245714285725
    ],
    [
        89.1433,
        0.4186020333333334
    ],
    [
        89.1438,
        0.5838507142857143
    ],
    [
        89.1444,
        0.8412052095238096
    ],
    [
        89.1449,
        0.9205787523809524
    ],
    [
        89.1454,
        0.8848350714285715
    ],
    [
        89.146,
        0.8369761619047621
    ],
    [
        89.1465,
        0.7318671666666666
    ],
    [
        89.1471,
        0.8546749238095236
    ],
    [
        89.1476,
        0.767987095238095
    ],
    [
        89.1482,
        0.8526431809523809
    ],
    [
        89.1487,
        0.9158530571428569
    ],
    [
        89.1493,
        0.9158530571428569
    ],
    [
        89.1498,
        0.9526179857142856
    ],
    [
        89.1504,
        0.7609566095238092
    ],
    [
        89.1509,
        0.6697537857142858
    ],
    [
        89.1514,
        0.8011400285714286
    ],
    [
        89.152,
        0.5054726095238096
    ],
    [
        89.1525,
        0.6412835428571428
    ],
    [
        89.153,
        0.32568565714285724
    ],
    [
        89.1536,
        0.4502994142857144
    ],
    [
        89.1541,
        0.6995269285714287
    ],
    [
        89.1547,
        0.489579842857143
    ],
    [
        89.1552,
        0.3270401571428573
    ],
    [
        89.1557,
        -0.04951010476190473
    ],
    [
        89.1563,
        -0.05899158571428567
    ],
    [
        89.1568,
        -0.11791221904761898
    ],
    [
        89.1574,
        -0.1224272095238095
    ],
    [
        89.1579,
        -0.1061732428571428
    ],
    [
        89.1584,
        -0.1508716523809523
    ],
    [
        89.1589,
        -0.16960886666666658
    ],
    [
        89.1595,
        -0.19218382380952378
    ],
    [
        89.16,
        -0.3407270333333334
    ],
    [
        89.1605,
        -0.28979793333333326
    ],
    [
        89.161,
        -0.5308984619047619
    ],
    [
        89.1615,
        -0.3761847619047619
    ],
    [
        89.162,
        -0.4405028619047619
    ],
    [
        89.1625,
        -0.5427674095238095
    ],
    [
        89.1631,
        -0.5537581904761903
    ],
    [
        89.1636,
        -0.5600791761904761
    ],
    [
        89.1641,
        -0.3505835857142858
    ],
    [
        89.1646,
        -0.4359169190476191
    ],
    [
        89.1651,
        -0.8070491952380949
    ],
    [
        89.1656,
        -0.6235148047619047
    ],
    [
        89.1661,
        -0.42250739523809516
    ],
    [
        89.1667,
        -0.3033116285714286
    ],
    [
        89.1672,
        -0.30466612380952374
    ],
    [
        89.1676,
        -0.1814068666666667
    ],
    [
        89.1681,
        -0.4974562476190475
    ],
    [
        89.1686,
        -0.4220558904761905
    ],
    [
        89.1691,
        -0.5225144476190475
    ],
    [
        89.1696,
        -0.4394386095238095
    ],
    [
        89.1701,
        -0.5287451333333334
    ],
    [
        89.1706,
        -0.3874259095238095
    ],
    [
        89.1711,
        -0.46598675714285714
    ],
    [
        89.1716,
        -0.1632566
    ],
    [
        89.1722,
        0.053161976190476204
    ],
    [
        89.1727,
        0.040531728571428574
    ],
    [
        89.1732,
        0.011410033333333337
    ],
    [
        89.1737,
        0.1365655904761905
    ],
    [
        89.1742,
        0.09863966190476192
    ],
    [
        89.1747,
        0.07809645238095235
    ],
    [
        89.1752,
        0.15394830476190474
    ],
    [
        89.1757,
        0.15665729999999994
    ],
    [
        89.1762,
        0.09638216666666664
    ],
    [
        89.1767,
        0.26379803809523805
    ],
    [
        89.1772,
        0.2035229047619047
    ],
    [
        89.1777,
        -0.15000090476190486
    ],
    [
        89.1782,
        -0.03622312857142869
    ],
    [
        89.1787,
        0.4270149666666666
    ],
    [
        89.1792,
        0.15385799999999986
    ],
    [
        89.1797,
        0.3252019142857141
    ],
    [
        89.1802,
        0.3827680523809522
    ],
    [
        89.1807,
        0.4087743999999998
    ],
    [
        89.1812,
        0.3663334809523809
    ],
    [
        89.1817,
        0.39003718571428564
    ],
    [
        89.1822,
        0.328407557142857
    ],
    [
        89.1827,
        0.08459803333333318
    ],
    [
        89.1832,
        -0.11431957619047635
    ],
    [
        89.1837,
        -0.07458765238095252
    ],
    [
        89.1842,
        -0.07458765238095252
    ],
    [
        89.1846,
        -0.231709342857143
    ],
    [
        89.1851,
        -0.2906299761904763
    ],
    [
        89.1856,
        -0.17549770000000026
    ],
    [
        89.1861,
        0.17396261904761884
    ],
    [
        89.1865,
        0.06018484285714268
    ],
    [
        89.187,
        0.16041764761904734
    ],
    [
        89.1875,
        0.14393792857142843
    ],
    [
        89.188,
        0.43109136666666653
    ],
    [
        89.1885,
        0.0951760238095237
    ],
    [
        89.189,
        -0.17734884761904773
    ],
    [
        89.1895,
        0.09951040952380945
    ],
    [
        89.19,
        -0.08560422857142863
    ],
    [
        89.1905,
        -0.033456080952381013
    ],
    [
        89.191,
        -0.06745396190476198
    ],
    [
        89.1914,
        0.007043395238095094
    ],
    [
        89.1919,
        0.17161482380952373
    ],
    [
        89.1924,
        0.3991703809523809
    ],
    [
        89.1928,
        0.6185989523809523
    ],
    [
        89.1933,
        0.8238053000000001
    ],
    [
        89.1938,
        0.8210963047619048
    ],
    [
        89.1942,
        0.8001015952380951
    ],
    [
        89.1947,
        0.8041650857142857
    ],
    [
        89.1952,
        0.7093502714285717
    ],
    [
        89.1956,
        0.5513255761904761
    ],
    [
        89.1961,
        0.29010108571428567
    ],
    [
        89.1966,
        0.5135931476190475
    ],
    [
        89.197,
        0.29416457619047615
    ],
    [
        89.1975,
        0.37091942857142857
    ],
    [
        89.198,
        0.3606252476190475
    ],
    [
        89.1984,
        0.5443853904761904
    ],
    [
        89.1989,
        0.47828592380952384
    ],
    [
        89.1994,
        0.47413213333333326
    ],
    [
        89.1998,
        0.31520444761904753
    ],
    [
        89.2003,
        -0.019356400000000173
    ],
    [
        89.2007,
        0.26982878095238094
    ],
    [
        89.2012,
        -0.08369502857142874
    ],
    [
        89.2017,
        -0.13516592857142862
    ],
    [
        89.2021,
        -0.41961037619047625
    ],
    [
        89.2026,
        -0.5252611714285713
    ],
    [
        89.2031,
        -0.616689742857143
    ],
    [
        89.2035,
        -0.5787638190476189
    ],
    [
        89.204,
        -0.7501077333333332
    ],
    [
        89.2045,
        -0.6657902714285714
    ],
    [
        89.205,
        -0.22693312857142858
    ],
    [
        89.2055,
        -0.261247061904762
    ],
    [
        89.206,
        0.002686419047618994
    ],
    [
        89.2065,
        -0.223514638095238
    ],
    [
        89.207,
        -0.19100669999999992
    ],
    [
        89.2074,
        -0.2677615523809523
    ],
    [
        89.2079,
        -0.36989065238095237
    ],
    [
        89.2084,
        -0.11750264761904752
    ],
    [
        89.2089,
        -0.08138271904761904
    ],
    [
        89.2094,
        0.10373191904761893
    ],
    [
        89.2098,
        0.10914990476190481
    ],
    [
        89.2103,
        0.38004937619047613
    ],
    [
        89.2108,
        0.1091499095238095
    ],
    [
        89.2113,
        0.38353237142857133
    ],
    [
        89.2118,
        0.1058604142857145
    ],
    [
        89.2123,
        0.16274930476190463
    ],
    [
        89.2127,
        0.1586858142857144
    ],
    [
        89.2132,
        0.2038357238095237
    ],
    [
        89.2137,
        0.05574401428571439
    ],
    [
        89.2141,
        0.2243789333333332
    ],
    [
        89.2146,
        0.20751543809523812
    ],
    [
        89.2151,
        0.02763819047619065
    ],
    [
        89.2156,
        -0.06514487619047611
    ],
    [
        89.2161,
        -0.24122952857142851
    ],
    [
        89.2165,
        -0.1707956666666666
    ],
    [
        89.217,
        -0.31437238571428566
    ],
    [
        89.2175,
        -0.18993922857142856
    ],
    [
        89.2179,
        -0.1316958428571428
    ],
    [
        89.2184,
        -0.3073289999999999
    ],
    [
        89.2188,
        -0.2716605714285714
    ],
    [
        89.2193,
        -0.3800203619047619
    ],
    [
        89.2198,
        -0.15246480476190477
    ],
    [
        89.2202,
        -0.41794628571428577
    ],
    [
        89.2207,
        -0.45496921428571424
    ],
    [
        89.2212,
        -0.3771823666666666
    ],
    [
        89.2217,
        -0.27017707619047615
    ],
    [
        89.2222,
        -0.19251922857142856
    ],
    [
        89.2227,
        -0.5352070571428571
    ],
    [
        89.2232,
        -0.4611612
    ],
    [
        89.2237,
        -0.16588077619047617
    ],
    [
        89.2241,
        -0.3372246904761904
    ],
    [
        89.2246,
        -0.24728606190476188
    ],
    [
        89.2251,
        -0.3152818333333333
    ],
    [
        89.2256,
        -0.2604246904761905
    ],
    [
        89.226,
        -0.27983915238095236
    ],
    [
        89.2265,
        -0.3475640190476191
    ],
    [
        89.227,
        -0.008939680952380936
    ],
    [
        89.2275,
        -0.10321269523809522
    ],
    [
        89.228,
        -0.07544549999999998
    ],
    [
        89.2284,
        -0.18786878095238094
    ],
    [
        89.2289,
        -0.10416084285714285
    ],
    [
        89.2294,
        -0.26561692857142855
    ],
    [
        89.2299,
        -0.19247407142857145
    ],
    [
        89.2303,
        -0.029257138095238132
    ],
    [
        89.2308,
        0.045691714285714266
    ],
    [
        89.2313,
        -0.007810928571428574
    ],
    [
        89.2318,
        0.04975520952380949
    ],
    [
        89.2323,
        0.19694392380952377
    ],
    [
        89.2327,
        0.5423407476190476
    ],
    [
        89.2332,
        0.4691978904761905
    ],
    [
        89.2337,
        0.2031746095238095
    ],
    [
        89.2341,
        0.3440423333333334
    ],
    [
        89.2346,
        0.299073019047619
    ],
    [
        89.2351,
        0.29075253809523816
    ],
    [
        89.2355,
        0.4004668238095239
    ],
    [
        89.236,
        0.3927913380952381
    ],
    [
        89.2365,
        0.4686431904761906
    ],
    [
        89.237,
        0.013532076190476186
    ],
    [
        89.2374,
        0.1368816380952381
    ],
    [
        89.2379,
        -0.007372328571428592
    ],
    [
        89.2383,
        0.0666735285714285
    ],
    [
        89.2388,
        0.022246014285714228
    ],
    [
        89.2393,
        0.12410421904761898
    ],
    [
        89.2397,
        -0.026696485714285765
    ],
    [
        89.2402,
        0.25436171428571425
    ],
    [
        89.2407,
        0.17760686190476188
    ],
    [
        89.2412,
        0.23381849999999998
    ],
    [
        89.2417,
        0.2317867523809523
    ],
    [
        89.2421,
        0.06315182857142859
    ],
    [
        89.2426,
        -0.12512330476190478
    ],
    [
        89.2431,
        -0.01985950952380956
    ],
    [
        89.2436,
        -0.05001965238095242
    ],
    [
        89.2441,
        -0.2965381714285714
    ],
    [
        89.2446,
        -0.518675738095238
    ],
    [
        89.245,
        -0.4429012857142858
    ],
    [
        89.2455,
        -0.7693351476190476
    ],
    [
        89.246,
        -0.8722769476190477
    ],
    [
        89.2465,
        -0.866471961904762
    ],
    [
        89.2469,
        -0.6023449761904762
    ],
    [
        89.2474,
        -0.9039463904761905
    ],
    [
        89.2479,
        -0.731247980952381
    ],
    [
        89.2484,
        -0.5118194095238096
    ],
    [
        89.2488,
        -0.5894772571428571
    ],
    [
        89.2493,
        -0.7547259380952382
    ],
    [
        89.2498,
        -0.6201792
    ],
    [
        89.2502,
        -0.8450257619047619
    ],
    [
        89.2507,
        -0.813240223809524
    ],
    [
        89.2511,
        -0.8538751428571432
    ],
    [
        89.2516,
        -0.5775576809523809
    ],
    [
        89.2521,
        -0.7421291095238098
    ],
    [
        89.2526,
        -0.5579174666666666
    ],
    [
        89.253,
        -0.6956892000000001
    ],
    [
        89.2535,
        -0.4103417571428574
    ],
    [
        89.254,
        -0.29385498571428575
    ],
    [
        89.2544,
        -0.1258973142857143
    ],
    [
        89.2549,
        -0.06196503809523814
    ],
    [
        89.2554,
        0.22789739523809516
    ],
    [
        89.2558,
        0.4134635333333333
    ],
    [
        89.2563,
        0.4011569571428571
    ],
    [
        89.2568,
        0.3645855285714285
    ],
    [
        89.2572,
        0.8142786523809524
    ],
    [
        89.2577,
        0.6849241571428573
    ],
    [
        89.2581,
        0.5377354428571428
    ],
    [
        89.2585,
        0.651513219047619
    ],
    [
        89.259,
        0.5377354428571428
    ],
    [
        89.2595,
        0.44911261428571414
    ],
    [
        89.2599,
        0.23510203333333315
    ],
    [
        89.2604,
        0.3234152619047618
    ],
    [
        89.2609,
        0.35863219523809503
    ],
    [
        89.2613,
        0.032198333333333315
    ],
    [
        89.2618,
        0.07012426190476191
    ],
    [
        89.2622,
        0.07012426190476188
    ],
    [
        89.2627,
        0.38436764761904757
    ],
    [
        89.2631,
        0.1302639476190476
    ],
    [
        89.2636,
        0.45263431904761914
    ],
    [
        89.2641,
        0.7262427857142858
    ],
    [
        89.2645,
        0.7303062761904761
    ],
    [
        89.265,
        0.43231685714285706
    ],
    [
        89.2654,
        0.10046500476190474
    ],
    [
        89.2659,
        0.1823669476190476
    ],
    [
        89.2663,
        0.12818705238095235
    ],
    [
        89.2668,
        0.010345780952380928
    ],
    [
        89.2672,
        0.02930874285714281
    ],
    [
        89.2677,
        0.027502747619047568
    ],
    [
        89.2681,
        -0.07905104285714279
    ],
    [
        89.2686,
        0.1769489571428572
    ],
    [
        89.2691,
        0.00550829047619046
    ],
    [
        89.2695,
        0.008217285714285557
    ],
    [
        89.27,
        -0.24913720952380955
    ],
    [
        89.2705,
        -0.39975731904761913
    ],
    [
        89.2709,
        -0.1857467380952381
    ],
    [
        89.2714,
        -0.09610364285714294
    ],
    [
        89.2718,
        -0.23619737142857145
    ],
    [
        89.2723,
        -0.33236668095238114
    ],
    [
        89.2727,
        -0.3609014285714286
    ],
    [
        89.2731,
        -0.547822061904762
    ],
    [
        89.2736,
        -0.7672506333333333
    ],
    [
        89.274,
        -0.9057479904761901
    ],
    [
        89.2745,
        -0.6140795571428572
    ],
    [
        89.2749,
        -0.600534585714286
    ],
    [
        89.2754,
        -0.5979158904761905
    ],
    [
        89.2758,
        -0.5624526857142856
    ],
    [
        89.2763,
        -0.6523010095238098
    ],
    [
        89.2767,
        -0.6403814333333334
    ],
    [
        89.2772,
        -0.606519
    ],
    [
        89.2776,
        -0.5360851380952381
    ],
    [
        89.2781,
        -0.6715348714285715
    ],
    [
        89.2785,
        -0.517799419047619
    ],
    [
        89.2789,
        -0.40808513333333335
    ],
    [
        89.2794,
        -0.16504960952380948
    ],
    [
        89.2798,
        -0.12278929047619049
    ],
    [
        89.2802,
        -0.3286728857142857
    ],
    [
        89.2806,
        -0.347882119047619
    ],
    [
        89.2811,
        -0.12380955714285709
    ],
    [
        89.2815,
        -0.2576037952380952
    ],
    [
        89.282,
        -0.15878998571428565
    ],
    [
        89.2824,
        -0.13914977619047622
    ],
    [
        89.2828,
        -0.16285348095238097
    ],
    [
        89.2832,
        -0.07176353333333332
    ],
    [
        89.2837,
        -0.04241609047619045
    ],
    [
        89.2841,
        0.11606010000000004
    ],
    [
        89.2845,
        -0.12178963809523805
    ],
    [
        89.2849,
        -0.038057076190476144
    ],
    [
        89.2853,
        -0.055214042857142785
    ],
    [
        89.2857,
        0.026055800000000073
    ],
    [
        89.2861,
        -0.10571744285714282
    ],
    [
        89.2866,
        -0.03799257619047615
    ],
    [
        89.287,
        0.03495678095238102
    ],
    [
        89.2874,
        0.11342087619047625
    ],
    [
        89.2878,
        0.2658018285714286
    ],
    [
        89.2882,
        0.13518958571428574
    ],
    [
        89.2886,
        0.09563826190476195
    ],
    [
        89.289,
        0.25862944285714284
    ],
    [
        89.2894,
        0.06358182380952378
    ],
    [
        89.2898,
        -0.08920547619047618
    ],
    [
        89.2902,
        -0.2574641476190476
    ],
    [
        89.2907,
        -0.1874172857142857
    ],
    [
        89.2911,
        -0.26846137619047616
    ],
    [
        89.2915,
        -0.24475767142857144
    ],
    [
        89.2919,
        -0.3097735428571428
    ],
    [
        89.2924,
        -0.3097735428571428
    ],
    [
        89.2928,
        -0.16755131904761908
    ],
    [
        89.2932,
        -0.033004580952380944
    ],
    [
        89.2936,
        0.08077320000000003
    ],
    [
        89.294,
        0.019369319047619064
    ],
    [
        89.2944,
        0.0417636761904762
    ],
    [
        89.2948,
        -0.11226202380952384
    ],
    [
        89.2952,
        -0.14368636190476186
    ],
    [
        89.2956,
        -0.13841350000000002
    ],
    [
        89.296,
        -0.23064831904761912
    ],
    [
        89.2964,
        -0.24405784285714294
    ],
    [
        89.2968,
        -0.09506313333333336
    ],
    [
        89.2972,
        -0.10427371428571434
    ],
    [
        89.2976,
        -0.10156471904761914
    ],
    [
        89.298,
        0.18829771428571426
    ],
    [
        89.2984,
        0.04986808571428568
    ],
    [
        89.2988,
        0.22324374761904764
    ],
    [
        89.2991,
        0.14345153809523808
    ],
    [
        89.2995,
        0.37823108095238095
    ],
    [
        89.2999,
        0.3287919285714286
    ],
    [
        89.3003,
        0.3956138
    ],
    [
        89.3007,
        0.09626988095238101
    ],
    [
        89.3011,
        0.06917993333333337
    ],
    [
        89.3015,
        0.3355644142857143
    ],
    [
        89.3019,
        0.09175489047619048
    ],
    [
        89.3024,
        0.3482063904761905
    ],
    [
        89.3028,
        0.38568081428571427
    ],
    [
        89.3032,
        0.567183461904762
    ],
    [
        89.3036,
        0.465867061904762
    ],
    [
        89.304,
        0.6842797619047619
    ],
    [
        89.3044,
        0.5578600095238097
    ],
    [
        89.3049,
        0.45546000952380955
    ],
    [
        89.3053,
        0.6031002190476191
    ],
    [
        89.3057,
        0.6518621238095238
    ],
    [
        89.3061,
        0.5850402523809524
    ],
    [
        89.3065,
        0.5236363714285714
    ],
    [
        89.307,
        0.5845887523809524
    ],
    [
        89.3074,
        0.7958903380952381
    ],
    [
        89.3078,
        0.5885291095238095
    ],
    [
        89.3082,
        0.32033863333333334
    ],
    [
        89.3086,
        0.39725473333333333
    ],
    [
        89.309,
        0.1489302142857143
    ],
    [
        89.3094,
        0.32941699047619044
    ],
    [
        89.3098,
        0.2876210714285714
    ],
    [
        89.3102,
        0.05284152857142858
    ],
    [
        89.3106,
        0.2153812095238095
    ],
    [
        89.311,
        -0.02775106666666668
    ],
    [
        89.3114,
        -0.06161350000000001
    ],
    [
        89.3118,
        -0.03452355238095239
    ],
    [
        89.3123,
        -0.01826958571428573
    ],
    [
        89.3127,
        -0.16590979523809524
    ],
    [
        89.313,
        -0.015289690476190464
    ],
    [
        89.3134,
        0.09713359047619044
    ],
    [
        89.3138,
        -0.18866535238095242
    ],
    [
        89.3142,
        -0.18866535238095242
    ],
    [
        89.3146,
        -0.18866535238095242
    ],
    [
        89.315,
        -0.12997046666666667
    ],
    [
        89.3154,
        -0.1516424285714286
    ],
    [
        89.3158,
        -0.5281926952380953
    ],
    [
        89.3162,
        -0.3990639476190476
    ],
    [
        89.3166,
        -0.3113602476190476
    ],
    [
        89.317,
        -0.3756795238095238
    ],
    [
        89.3174,
        -0.19417687619047616
    ],
    [
        89.3178,
        -0.12746788571428563
    ],
    [
        89.3182,
        -0.1840987714285714
    ],
    [
        89.3186,
        -0.24053616190476187
    ],
    [
        89.319,
        -0.31401764285714284
    ],
    [
        89.3194,
        -0.3022335142857142
    ],
    [
        89.3198,
        -0.3211964761904761
    ],
    [
        89.3201,
        -0.5324980619047619
    ],
    [
        89.3205,
        -0.6652388047619048
    ],
    [
        89.3209,
        -0.7126462142857143
    ],
    [
        89.3213,
        -0.6282610285714286
    ],
    [
        89.3217,
        -0.7135943619047619
    ],
    [
        89.322,
        -0.6092980666666667
    ],
    [
        89.3224,
        -0.6217594428571429
    ],
    [
        89.3228,
        -0.5843753142857143
    ],
    [
        89.3232,
        -0.7672324571428571
    ],
    [
        89.3236,
        -0.6168832476190477
    ],
    [
        89.324,
        -0.6141742523809524
    ],
    [
        89.3244,
        -0.5983717857142858
    ],
    [
        89.3247,
        -0.7091019428571429
    ],
    [
        89.3251,
        -0.5855717857142858
    ],
    [
        89.3255,
        -0.6749686095238095
    ],
    [
        89.3259,
        -0.693060823809524
    ],
    [
        89.3263,
        -0.709089042857143
    ],
    [
        89.3267,
        -0.5605458333333335
    ],
    [
        89.3271,
        -0.6753394857142858
    ],
    [
        89.3275,
        -0.6390389571428573
    ],
    [
        89.3278,
        -0.6477077380952383
    ],
    [
        89.3282,
        -0.4836630619047619
    ],
    [
        89.3286,
        -0.2825954523809524
    ],
    [
        89.329,
        -0.31239439523809526
    ],
    [
        89.3293,
        -0.33222810476190484
    ],
    [
        89.3297,
        -0.3769265190476191
    ],
    [
        89.3301,
        -0.5530111714285715
    ],
    [
        89.3305,
        -0.5618831285714285
    ],
    [
        89.3309,
        -0.5708228142857144
    ],
    [
        89.3312,
        -0.5052264428571429
    ],
    [
        89.3316,
        -0.5363798809523811
    ],
    [
        89.332,
        -0.29979434285714285
    ],
    [
        89.3324,
        -0.2198789952380953
    ],
    [
        89.3328,
        -0.16073260952380955
    ],
    [
        89.3331,
        -0.22187848571428578
    ],
    [
        89.3335,
        -0.16824039047619055
    ],
    [
        89.3338,
        -0.19416933809523812
    ],
    [
        89.3342,
        -0.060751352380952355
    ],
    [
        89.3345,
        -0.1212522333333334
    ],
    [
        89.3349,
        -0.07452207142857141
    ],
    [
        89.3353,
        0.012165757142857165
    ],
    [
        89.3357,
        -0.0035464142857142516
    ],
    [
        89.336,
        0.0011053952380952854
    ],
    [
        89.3364,
        0.01675736666666668
    ],
    [
        89.3368,
        0.13595313333333334
    ],
    [
        89.3371,
        0.07465245238095237
    ],
    [
        89.3375,
        0.09677590952380959
    ],
    [
        89.3379,
        0.3033367523809524
    ],
    [
        89.3382,
        0.3604288142857143
    ],
    [
        89.3386,
        0.3072164190476191
    ],
    [
        89.339,
        0.3670465047619048
    ],
    [
        89.3393,
        0.13407295714285714
    ],
    [
        89.3397,
        0.03293715714285718
    ],
    [
        89.3401,
        0.04106413809523822
    ],
    [
        89.3404,
        0.03582674761904764
    ],
    [
        89.3408,
        -0.045701095238095124
    ],
    [
        89.3412,
        0.03272430000000007
    ],
    [
        89.3416,
        0.03272430000000007
    ],
    [
        89.3419,
        0.11670313809523811
    ],
    [
        89.3423,
        0.11670313809523811
    ],
    [
        89.3427,
        0.1056414095238096
    ],
    [
        89.343,
        0.0520033142857143
    ],
    [
        89.3434,
        0.0027899095238095254
    ],
    [
        89.3438,
        0.05027120000000001
    ],
    [
        89.3441,
        0.05501193809523809
    ],
    [
        89.3445,
        -0.07501980476190472
    ],
    [
        89.3448,
        -0.039261076190476175
    ],
    [
        89.3452,
        -0.13046390000000002
    ],
    [
        89.3455,
        -0.011945380952380983
    ],
    [
        89.3459,
        0.032211233333333374
    ],
    [
        89.3462,
        0.0037022904761904755
    ],
    [
        89.3466,
        -0.08605573333333327
    ],
    [
        89.3469,
        0.0046955904761904295
    ],
    [
        89.3473,
        0.07242045714285712
    ],
    [
        89.3476,
        0.19703421428571424
    ],
    [
        89.3479,
        0.3137918857142857
    ],
    [
        89.3483,
        0.3408818333333334
    ],
    [
        89.3486,
        0.35510405714285714
    ],
    [
        89.3489,
        0.528479719047619
    ],
    [
        89.3493,
        0.44450088095238094
    ],
    [
        89.3496,
        0.4228289238095238
    ],
    [
        89.3499,
        0.6594144619047619
    ],
    [
        89.3503,
        0.7997403904761904
    ],
    [
        89.3507,
        0.8570807809523809
    ],
    [
        89.351,
        0.7898977095238096
    ],
    [
        89.3514,
        0.7792874809523809
    ],
    [
        89.3517,
        0.7621305142857141
    ],
    [
        89.3521,
        0.7043386285714284
    ],
    [
        89.3524,
        0.8849382761904762
    ],
    [
        89.3528,
        0.6804091761904761
    ],
    [
        89.3532,
        0.5591816619047618
    ],
    [
        89.3535,
        0.6675414476190475
    ],
    [
        89.3539,
        0.7795132285714285
    ],
    [
        89.3543,
        0.8061516761904761
    ],
    [
        89.3547,
        0.7528747809523809
    ],
    [
        89.355,
        0.6195922428571428
    ],
    [
        89.3554,
        0.6236557333333334
    ],
    [
        89.3558,
        0.5929537904761905
    ],
    [
        89.3561,
        0.7175675476190476
    ],
    [
        89.3565,
        0.6308797142857143
    ],
    [
        89.3568,
        0.5441918857142858
    ],
    [
        89.3572,
        0.5658638428571429
    ],
    [
        89.3575,
        0.29767336666666666
    ],
    [
        89.3579,
        0.2976733666666667
    ],
    [
        89.3582,
        0.3047167523809524
    ],
    [
        89.3586,
        0.30634215238095236
    ],
    [
        89.359,
        0.24231957619047617
    ],
    [
        89.3593,
        0.33316119523809523
    ],
    [
        89.3597,
        0.3568197476190476
    ],
    [
        89.3601,
        0.3279238047619048
    ],
    [
        89.3605,
        0.41257989047619054
    ],
    [
        89.3608,
        0.5089749523809525
    ],
    [
        89.3612,
        0.2886433857142857
    ],
    [
        89.3615,
        0.28412839523809513
    ],
    [
        89.3619,
        0.3015111095238096
    ],
    [
        89.3623,
        0.33040705238095247
    ],
    [
        89.3626,
        0.2695449714285715
    ],
    [
        89.363,
        0.20530310000000002
    ],
    [
        89.3634,
        0.3434618333333333
    ],
    [
        89.3637,
        0.08204384285714286
    ],
    [
        89.3641,
        -0.03218543333333336
    ],
    [
        89.3644,
        0.0008642999999999766
    ],
    [
        89.3647,
        0.0008642999999999766
    ],
    [
        89.3651,
        0.14534401428571428
    ],
    [
        89.3654,
        -0.08582353333333335
    ],
    [
        89.3657,
        -0.20799919523809524
    ],
    [
        89.3661,
        -0.3423653333333333
    ],
    [
        89.3664,
        -0.1343145380952381
    ],
    [
        89.3667,
        -0.11228138095238097
    ],
    [
        89.3671,
        -0.09948138095238096
    ],
    [
        89.3674,
        -0.0821438142857143
    ],
    [
        89.3678,
        -0.2927681523809524
    ],
    [
        89.3681,
        -0.4029339333333334
    ],
    [
        89.3685,
        -0.06701859047619048
    ],
    [
        89.3688,
        -0.16138190952380957
    ],
    [
        89.3692,
        -0.12277873333333336
    ],
    [
        89.3696,
        -0.09478578571428574
    ],
    [
        89.3699,
        -0.15122317619047623
    ],
    [
        89.3703,
        -0.13953580000000004
    ],
    [
        89.3706,
        -0.25114638095238095
    ],
    [
        89.371,
        -0.17123103809523815
    ],
    [
        89.3713,
        -0.14368959047619048
    ],
    [
        89.3717,
        -0.341988
    ],
    [
        89.372,
        -0.17673932380952384
    ],
    [
        89.3724,
        -0.2923230952380952
    ],
    [
        89.3728,
        -0.06928253333333334
    ],
    [
        89.3731,
        0.016050799999999983
    ],
    [
        89.3735,
        0.00792381428571425
    ],
    [
        89.3738,
        -0.3442455
    ],
    [
        89.3742,
        -0.3800644285714285
    ],
    [
        89.3745,
        -0.387627038095238
    ],
    [
        89.3749,
        -0.36162069047619044
    ],
    [
        89.3752,
        -0.22255896190476193
    ],
    [
        89.3755,
        -0.13171734285714284
    ],
    [
        89.3759,
        -0.39178083333333336
    ],
    [
        89.3763,
        -0.2631035857142857
    ],
    [
        89.3766,
        -0.3497914142857143
    ],
    [
        89.377,
        -0.29380552380952385
    ],
    [
        89.3774,
        -0.2680700761904762
    ],
    [
        89.3777,
        -0.3250363666666667
    ],
    [
        89.3781,
        -0.2797961571428571
    ],
    [
        89.3785,
        -0.29577922380952376
    ],
    [
        89.3788,
        -0.29577922380952376
    ],
    [
        89.3792,
        -0.049260704761904715
    ],
    [
        89.3796,
        -0.05467869523809516
    ],
    [
        89.3799,
        0.16836186666666672
    ],
    [
        89.3803,
        -0.036844480952381006
    ],
    [
        89.3806,
        0.04848885238095233
    ],
    [
        89.381,
        0.38440419523809527
    ],
    [
        89.3813,
        0.5962475809523808
    ],
    [
        89.3817,
        0.49842277142857133
    ],
    [
        89.382,
        0.5273187142857141
    ],
    [
        89.3824,
        0.4446943761904761
    ],
    [
        89.3827,
        0.43227814761904765
    ],
    [
        89.383,
        0.41649825714285715
    ],
    [
        89.3834,
        0.558268980952381
    ],
    [
        89.3837,
        0.45322019047619055
    ],
    [
        89.3841,
        0.5399080190476191
    ],
    [
        89.3844,
        0.6265958476190476
    ],
    [
        89.3848,
        0.7154508761904762
    ],
    [
        89.3851,
        0.7172891238095238
    ],
    [
        89.3854,
        0.7287056
    ],
    [
        89.3858,
        0.5740220000000001
    ],
    [
        89.3861,
        0.5327098285714287
    ],
    [
        89.3864,
        0.46646259047619065
    ],
    [
        89.3868,
        0.22400756666666677
    ],
    [
        89.3871,
        0.04099992380952382
    ],
    [
        89.3875,
        0.12226976666666667
    ],
    [
        89.3878,
        0.03693643333333334
    ],
    [
        89.3882,
        -0.1228942523809524
    ],
    [
        89.3885,
        -0.23992282380952384
    ],
    [
        89.3889,
        0.0011777047619047447
    ],
    [
        89.3892,
        0.028719152380952368
    ],
    [
        89.3896,
        0.04516661904761901
    ],
    [
        89.3899,
        0.10713487619047617
    ],
    [
        89.3903,
        0.05573169999999994
    ],
    [
        89.3906,
        0.0033577999999999768
    ],
    [
        89.3909,
        -0.01823890952380956
    ],
    [
        89.3913,
        0.14159177619047614
    ],
    [
        89.3916,
        0.13346479047619045
    ],
    [
        89.392,
        0.048221757142857084
    ],
    [
        89.3923,
        0.23446514285714282
    ],
    [
        89.3927,
        0.23049839999999996
    ],
    [
        89.393,
        0.3837070999999999
    ],
    [
        89.3934,
        0.45536001428571427
    ],
    [
        89.3937,
        0.28050672380952374
    ],
    [
        89.394,
        0.5256707428571429
    ],
    [
        89.3944,
        0.5034720380952381
    ],
    [
        89.3947,
        0.48834681428571436
    ],
    [
        89.395,
        0.44838914285714293
    ],
    [
        89.3954,
        0.5025690380952381
    ],
    [
        89.3957,
        0.5465902047619048
    ],
    [
        89.3961,
        0.4253626952380952
    ],
    [
        89.3964,
        0.5549429428571427
    ],
    [
        89.3967,
        0.5777759
    ],
    [
        89.397,
        0.5318358619047618
    ],
    [
        89.3974,
        0.7203818952380951
    ],
    [
        89.3977,
        0.6445300428571427
    ],
    [
        89.398,
        0.7350556142857142
    ],
    [
        89.3984,
        0.4208122285714285
    ],
    [
        89.3987,
        0.2582725476190476
    ],
    [
        89.3991,
        0.2343430952380952
    ],
    [
        89.3994,
        0.14900976190476195
    ],
    [
        89.3997,
        0.28649124761904765
    ],
    [
        89.4001,
        0.26466879047619046
    ],
    [
        89.4004,
        0.259612
    ],
    [
        89.4007,
        0.5765643809523809
    ],
    [
        89.401,
        0.41402469999999997
    ],
    [
        89.4013,
        0.43153641428571426
    ],
    [
        89.4017,
        0.43623200476190477
    ],
    [
        89.402,
        0.42200978095238095
    ],
    [
        89.4023,
        0.45045422380952377
    ],
    [
        89.4027,
        0.5240034285714286
    ],
    [
        89.403,
        0.4400245904761904
    ],
    [
        89.4033,
        0.3379857857142858
    ],
    [
        89.4036,
        0.17002811428571435
    ],
    [
        89.404,
        0.19531206666666673
    ],
    [
        89.4043,
        -0.0037990476190475483
    ],
    [
        89.4046,
        0.11336497619047622
    ],
    [
        89.4049,
        0.007714185714285779
    ],
    [
        89.4053,
        0.2488147142857143
    ],
    [
        89.4056,
        0.08356603809523812
    ],
    [
        89.406,
        0.32873005714285713
    ],
    [
        89.4063,
        0.41406339047619045
    ],
    [
        89.4067,
        0.20276180000000005
    ],
    [
        89.407,
        0.2944161238095238
    ],
    [
        89.4074,
        0.3178940761904762
    ],
    [
        89.4077,
        0.12988984285714286
    ],
    [
        89.4081,
        0.12988984285714286
    ],
    [
        89.4084,
        0.12253685714285718
    ],
    [
        89.4088,
        0.13093474285714285
    ],
    [
        89.4091,
        0.1318377428571429
    ],
    [
        89.4095,
        0.09052557619047617
    ],
    [
        89.4098,
        0.1354948904761905
    ],
    [
        89.4102,
        0.09824621428571431
    ],
    [
        89.4105,
        0.19486702857142857
    ],
    [
        89.4108,
        0.20841200000000004
    ],
    [
        89.4111,
        0.23369595238095242
    ],
    [
        89.4114,
        0.2591304047619048
    ],
    [
        89.4118,
        0.25438966190476187
    ],
    [
        89.4121,
        0.16047784285714284
    ],
    [
        89.4124,
        -0.002061838095238083
    ],
    [
        89.4128,
        0.19298578095238095
    ],
    [
        89.4131,
        -0.024636795238095206
    ],
    [
        89.4134,
        -0.04630875238095238
    ],
    [
        89.4138,
        -0.013026814285714273
    ],
    [
        89.4141,
        -0.04677637619047617
    ],
    [
        89.4144,
        -0.021040928571428513
    ],
    [
        89.4147,
        -0.039823295238095247
    ],
    [
        89.415,
        -0.011107952380952392
    ],
    [
        89.4153,
        -0.005689961904761903
    ],
    [
        89.4157,
        -0.11540424761904762
    ],
    [
        89.416,
        -0.13662470476190475
    ],
    [
        89.4163,
        -0.14204269523809526
    ],
    [
        89.4166,
        -0.2571749714285714
    ],
    [
        89.4169,
        -0.14001095238095243
    ],
    [
        89.4172,
        -0.29171465714285705
    ],
    [
        89.4175,
        -0.18877285714285716
    ],
    [
        89.4178,
        -0.1382049571428571
    ],
    [
        89.4181,
        -0.19614734285714286
    ],
    [
        89.4184,
        -0.3790044857142857
    ],
    [
        89.4187,
        -0.1528034285714286
    ],
    [
        89.419,
        0.07475212857142854
    ],
    [
        89.4193,
        0.11809604285714285
    ],
    [
        89.4197,
        0.2422583
    ],
    [
        89.42,
        0.0773966238095238
    ],
    [
        89.4203,
        0.17008294285714284
    ],
    [
        89.4206,
        0.11420992857142857
    ],
    [
        89.4209,
        0.05566554285714285
    ],
    [
        89.4212,
        0.05747154285714281
    ],
    [
        89.4215,
        -0.11482051904761907
    ],
    [
        89.4219,
        -0.014587714285714301
    ],
    [
        89.4222,
        -0.09314856190476192
    ],
    [
        89.4225,
        0.12492550952380954
    ],
    [
        89.4228,
        0.12424826190476193
    ],
    [
        89.4231,
        0.13481334285714283
    ],
    [
        89.4234,
        0.16528953333333335
    ],
    [
        89.4237,
        0.16528953333333335
    ],
    [
        89.424,
        0.19129588095238098
    ],
    [
        89.4243,
        0.11544402857142855
    ],
    [
        89.4246,
        0.2350912904761905
    ],
    [
        89.425,
        0.31771562857142865
    ],
    [
        89.4253,
        0.2743717142857142
    ],
    [
        89.4256,
        0.08948282380952384
    ],
    [
        89.4259,
        0.06419887142857145
    ],
    [
        89.4261,
        -0.056351390476190456
    ],
    [
        89.4264,
        -0.014942471428571417
    ],
    [
        89.4267,
        -0.11436258095238093
    ],
    [
        89.427,
        -0.03196399523809521
    ],
    [
        89.4272,
        -0.06726692380952377
    ],
    [
        89.4275,
        -0.019408019047619046
    ],
    [
        89.4278,
        0.1241687
    ],
    [
        89.4281,
        0.11604171428571432
    ],
    [
        89.4283,
        0.2650364238095238
    ],
    [
        89.4287,
        0.0577983285714286
    ],
    [
        89.4289,
        0.0395126142857143
    ],
    [
        89.4292,
        -0.0008514095238095183
    ],
    [
        89.4295,
        -0.2040260095238095
    ],
    [
        89.4298,
        -0.24736992380952377
    ],
    [
        89.4301,
        -0.15418050476190473
    ],
    [
        89.4304,
        -0.15418050476190473
    ],
    [
        89.4307,
        -0.21984137619047617
    ],
    [
        89.4309,
        -0.14435893333333333
    ],
    [
        89.4312,
        -0.22246828095238091
    ],
    [
        89.4315,
        -0.059251347619047594
    ],
    [
        89.4318,
        -0.12833070952380948
    ],
    [
        89.4321,
        -0.06292783809523807
    ],
    [
        89.4323,
        -0.10124076666666666
    ],
    [
        89.4326,
        -0.05769367619047619
    ],
    [
        89.4329,
        -0.14799349523809524
    ],
    [
        89.4332,
        -0.19095040952380948
    ],
    [
        89.4335,
        -0.06633665238095235
    ],
    [
        89.4338,
        -0.04466469523809521
    ],
    [
        89.434,
        -0.13361001904761907
    ],
    [
        89.4343,
        -0.09297510000000002
    ],
    [
        89.4346,
        -0.05423647619047617
    ],
    [
        89.4349,
        -0.13618356666666667
    ],
    [
        89.4351,
        -0.007506319047619077
    ],
    [
        89.4354,
        0.25255717142857137
    ],
    [
        89.4357,
        0.32136563809523805
    ],
    [
        89.4359,
        0.21119985238095235
    ],
    [
        89.4362,
        0.17146792857142854
    ],
    [
        89.4365,
        0.16353444761904765
    ],
    [
        89.4367,
        0.17555253333333334
    ],
    [
        89.437,
        0.18006752380952387
    ],
    [
        89.4373,
        -0.012271100000000031
    ],
    [
        89.4375,
        0.03549750476190476
    ],
    [
        89.4378,
        -0.04886832857142861
    ],
    [
        89.4381,
        -0.0984042285714286
    ],
    [
        89.4383,
        -0.10225223333333334
    ],
    [
        89.4386,
        -0.079677280952381
    ],
    [
        89.4388,
        -0.0173704047619047
    ],
    [
        89.4391,
        -0.09954324285714287
    ],
    [
        89.4393,
        -0.14667975238095238
    ],
    [
        89.4396,
        -0.10727032857142857
    ],
    [
        89.4398,
        -0.1479052476190476
    ],
    [
        89.4401,
        -0.1457380523809524
    ],
    [
        89.4404,
        -0.10058814285714285
    ],
    [
        89.4407,
        -0.15205903809523813
    ],
    [
        89.441,
        -0.31550171904761914
    ],
    [
        89.4412,
        -0.31550171904761914
    ],
    [
        89.4415,
        -0.2826464714285715
    ],
    [
        89.4418,
        -0.21379285238095241
    ],
    [
        89.442,
        -0.15988385714285713
    ],
    [
        89.4423,
        -0.19786998095238098
    ],
    [
        89.4425,
        -0.1326735047619048
    ],
    [
        89.4428,
        -0.13538250000000002
    ],
    [
        89.443,
        -0.09420578095238093
    ],
    [
        89.4433,
        -0.003725357142857129
    ],
    [
        89.4435,
        0.04581054285714286
    ],
    [
        89.4438,
        0.10146807142857142
    ],
    [
        89.4441,
        0.18499540952380955
    ],
    [
        89.4443,
        0.22743632857142856
    ],
    [
        89.4446,
        0.1531647238095238
    ],
    [
        89.4448,
        0.24229065238095235
    ],
    [
        89.4451,
        0.18346676666666661
    ],
    [
        89.4454,
        0.18346676666666661
    ],
    [
        89.4457,
        0.08431756190476193
    ],
    [
        89.4459,
        0.1439154476190476
    ],
    [
        89.4462,
        0.11321350476190475
    ],
    [
        89.4465,
        0.17750698095238093
    ],
    [
        89.4467,
        0.1132135047619047
    ],
    [
        89.447,
        0.0036034095238094686
    ],
    [
        89.4473,
        0.036788590476190444
    ],
    [
        89.4475,
        0.07633990952380956
    ],
    [
        89.4478,
        -0.036835871428571414
    ],
    [
        89.448,
        -0.12596179999999996
    ],
    [
        89.4483,
        0.008133438095238074
    ],
    [
        89.4486,
        0.008133438095238149
    ],
    [
        89.4488,
        -0.08370148571428568
    ],
    [
        89.4491,
        -0.0330045809523809
    ],
    [
        89.4493,
        -0.05648253333333329
    ],
    [
        89.4496,
        -0.1391068714285714
    ],
    [
        89.4498,
        -0.11788641428571422
    ],
    [
        89.4501,
        -0.12669064761904755
    ],
    [
        89.4504,
        -0.3230927666666666
    ],
    [
        89.4506,
        -0.24001692857142856
    ],
    [
        89.4509,
        -0.24001692857142856
    ],
    [
        89.4512,
        -0.05580529047619042
    ],
    [
        89.4515,
        -0.12736789999999992
    ],
    [
        89.4518,
        -0.168454319047619
    ],
    [
        89.452,
        -0.13296649047619036
    ],
    [
        89.4523,
        0.03318518571428576
    ],
    [
        89.4526,
        0.09278306666666668
    ],
    [
        89.4528,
        0.14967195714285716
    ],
    [
        89.4531,
        0.0440211666666667
    ],
    [
        89.4534,
        0.13161199523809522
    ],
    [
        89.4536,
        0.06795061904761908
    ],
    [
        89.4539,
        0.016673219047619026
    ],
    [
        89.4542,
        0.015318723809523805
    ],
    [
        89.4544,
        0.05568274761904762
    ],
    [
        89.4547,
        0.08683618571428574
    ],
    [
        89.455,
        0.06380972857142857
    ],
    [
        89.4552,
        0.04484676666666668
    ],
    [
        89.4555,
        -0.13530138095238095
    ],
    [
        89.4557,
        -0.13530138095238098
    ],
    [
        89.456,
        0.05432825238095239
    ],
    [
        89.4562,
        -0.011951819047619069
    ],
    [
        89.4565,
        0.01852437142857141
    ],
    [
        89.4568,
        -0.10473488571428574
    ],
    [
        89.457,
        -0.04906504761904763
    ],
    [
        89.4573,
        -0.10730843333333331
    ],
    [
        89.4576,
        -0.1377846238095238
    ],
    [
        89.4578,
        -0.3030333
    ],
    [
        89.4581,
        -0.1851920285714286
    ],
    [
        89.4583,
        -0.2461444095238095
    ],
    [
        89.4586,
        -0.2240209523809523
    ],
    [
        89.4588,
        -0.24388691428571427
    ],
    [
        89.4591,
        -0.07863823809523808
    ],
    [
        89.4593,
        -0.15739258571428566
    ],
    [
        89.4596,
        -0.18222503809523807
    ],
    [
        89.4598,
        -0.28083244761904763
    ],
    [
        89.4601,
        -0.4040917095238095
    ],
    [
        89.4603,
        -0.3837742476190476
    ],
    [
        89.4606,
        -0.40409170952380935
    ],
    [
        89.4608,
        -0.1657001761904761
    ],
    [
        89.461,
        -0.14098059999999998
    ],
    [
        89.4613,
        -0.17240493809523802
    ],
    [
        89.4615,
        -0.11673509999999997
    ],
    [
        89.4618,
        -0.1790419761904761
    ],
    [
        89.4621,
        -0.15872451428571427
    ],
    [
        89.4623,
        -0.11754779523809519
    ],
    [
        89.4626,
        0.0639548476190477
    ],
    [
        89.4628,
        0.0653093428571429
    ],
    [
        89.4631,
        0.05989135238095244
    ],
    [
        89.4633,
        -0.040792952380952374
    ],
    [
        89.4636,
        -0.0751971857142857
    ],
    [
        89.4639,
        -0.08716191428571429
    ],
    [
        89.4641,
        -0.00724656666666661
    ],
    [
        89.4644,
        -0.060071961904761904
    ],
    [
        89.4647,
        0.021197880952381003
    ],
    [
        89.4649,
        -0.14766279047619046
    ],
    [
        89.4652,
        0.017585885714285744
    ],
    [
        89.4655,
        0.10833720952380957
    ],
    [
        89.4657,
        0.0363231
    ],
    [
        89.466,
        -0.016502295238095172
    ],
    [
        89.4663,
        -0.09957813333333332
    ],
    [
        89.4665,
        -0.1030772523809524
    ],
    [
        89.4668,
        -0.12854180476190472
    ],
    [
        89.467,
        -0.04117672380952379
    ],
    [
        89.4673,
        -0.02966349999999996
    ],
    [
        89.4675,
        0.07056930476190476
    ],
    [
        89.4678,
        0.04618835238095239
    ],
    [
        89.4681,
        -0.1353142904761905
    ],
    [
        89.4683,
        -0.12470405714285715
    ],
    [
        89.4686,
        -0.20326490476190473
    ],
    [
        89.4688,
        -0.20326490476190473
    ],
    [
        89.469,
        -0.19459611904761906
    ],
    [
        89.4693,
        -0.16411992857142854
    ],
    [
        89.4695,
        -0.20340035238095236
    ],
    [
        89.4698,
        -0.14605996190476186
    ],
    [
        89.4701,
        -0.12303350952380948
    ],
    [
        89.4703,
        0.00428924285714287
    ],
    [
        89.4706,
        -0.1027160476190476
    ],
    [
        89.4708,
        -0.13386948571428567
    ],
    [
        89.4711,
        -0.1033932952380952
    ],
    [
        89.4714,
        0.03584903333333331
    ],
    [
        89.4716,
        -0.02844444285714285
    ],
    [
        89.4719,
        -0.009029980952380971
    ],
    [
        89.4722,
        -0.03950617142857145
    ],
    [
        89.4724,
        -0.12687125238095237
    ],
    [
        89.4727,
        0.023477957142857123
    ],
    [
        89.473,
        0.006320990476190518
    ],
    [
        89.4732,
        -0.06749911428571427
    ],
    [
        89.4735,
        0.077703
    ],
    [
        89.4738,
        0.017073114285714286
    ],
    [
        89.4741,
        0.20399374761904762
    ],
    [
        89.4743,
        0.17870979999999997
    ],
    [
        89.4746,
        0.14620186190476192
    ],
    [
        89.4748,
        0.18006429523809525
    ],
    [
        89.4751,
        0.15478034285714284
    ],
    [
        89.4754,
        0.14484736190476188
    ],
    [
        89.4757,
        0.16787381904761903
    ],
    [
        89.4759,
        0.13672038095238093
    ],
    [
        89.4762,
        0.2667521285714286
    ],
    [
        89.4765,
        0.2667521285714286
    ],
    [
        89.4767,
        0.2667521285714286
    ],
    [
        89.477,
        0.2846314904761905
    ],
    [
        89.4773,
        0.3100960428571429
    ],
    [
        89.4776,
        0.4292918095238096
    ],
    [
        89.4779,
        0.4427400333333332
    ],
    [
        89.4781,
        0.426002319047619
    ],
    [
        89.4784,
        0.2804906
    ],
    [
        89.4787,
        0.2768786047619048
    ],
    [
        89.4789,
        0.28730823333333333
    ],
    [
        89.4792,
        0.3750796619047619
    ],
    [
        89.4795,
        0.4122315904761904
    ],
    [
        89.4797,
        0.46099349523809524
    ],
    [
        89.48,
        0.5525575142857143
    ],
    [
        89.4802,
        0.5241130714285714
    ],
    [
        89.4805,
        0.4624834428571428
    ],
    [
        89.4807,
        0.4904763904761904
    ],
    [
        89.4809,
        0.46988802857142853
    ],
    [
        89.4812,
        0.3876506904761904
    ],
    [
        89.4814,
        0.3578517476190474
    ],
    [
        89.4817,
        0.31315333333333334
    ],
    [
        89.4819,
        0.27342140952380956
    ],
    [
        89.4821,
        0.31405632857142857
    ],
    [
        89.4824,
        0.15151664761904768
    ],
    [
        89.4826,
        0.1276774952380953
    ],
    [
        89.4829,
        0.028799190476190457
    ],
    [
        89.4831,
        -0.07336860952380951
    ],
    [
        89.4834,
        -0.0657060238095238
    ],
    [
        89.4836,
        -0.07867049523809522
    ],
    [
        89.4838,
        -0.17438830952380954
    ],
    [
        89.4841,
        -0.040563971428571405
    ],
    [
        89.4843,
        -0.1940736714285714
    ],
    [
        89.4846,
        -0.1839149380952381
    ],
    [
        89.4848,
        -0.3215318666666667
    ],
    [
        89.4851,
        -0.345371019047619
    ],
    [
        89.4854,
        -0.26003768571428576
    ],
    [
        89.4856,
        -0.20247154761904765
    ],
    [
        89.4858,
        -0.06973080952380953
    ],
    [
        89.4861,
        -0.10016184761904759
    ],
    [
        89.4863,
        -0.06533191904761908
    ],
    [
        89.4866,
        -0.02821869047619046
    ],
    [
        89.4868,
        -0.04718165238095241
    ],
    [
        89.487,
        0.028670199999999996
    ],
    [
        89.4873,
        0.10723104761904757
    ],
    [
        89.4875,
        0.3374955952380952
    ],
    [
        89.4878,
        0.3477897761904762
    ],
    [
        89.488,
        0.3690102333333333
    ],
    [
        89.4883,
        0.48820600000000003
    ],
    [
        89.4885,
        0.5792282190476191
    ],
    [
        89.4888,
        0.48576790000000014
    ],
    [
        89.489,
        0.5426567904761905
    ],
    [
        89.4893,
        0.446148857142857
    ],
    [
        89.4895,
        0.4664663190476189
    ],
    [
        89.4897,
        0.49423351428571427
    ],
    [
        89.49,
        0.47834074285714273
    ],
    [
        89.4902,
        0.3618539714285713
    ],
    [
        89.4905,
        0.34289100476190465
    ],
    [
        89.4907,
        0.19660529047619027
    ],
    [
        89.4909,
        -0.03365925714285713
    ],
    [
        89.4912,
        -0.053073719047619046
    ],
    [
        89.4914,
        -0.05879270476190473
    ],
    [
        89.4917,
        -0.05391651428571431
    ],
    [
        89.4919,
        -0.05391651428571431
    ],
    [
        89.4922,
        -0.09003644285714286
    ],
    [
        89.4924,
        -0.2634121047619048
    ],
    [
        89.4927,
        -0.35389252380952385
    ],
    [
        89.4929,
        -0.35118353333333335
    ],
    [
        89.4931,
        -0.337187061904762
    ],
    [
        89.4934,
        -0.3173211000000001
    ],
    [
        89.4936,
        -0.48934226190476193
    ],
    [
        89.4939,
        -0.3435983476190476
    ],
    [
        89.4941,
        -0.3643673047619047
    ],
    [
        89.4943,
        -0.3789281523809524
    ],
    [
        89.4946,
        -0.30127030476190475
    ],
    [
        89.4948,
        -0.29991580952380953
    ],
    [
        89.495,
        -0.20329499523809522
    ],
    [
        89.4952,
        -0.11064737619047622
    ],
    [
        89.4955,
        -0.107938380952381
    ],
    [
        89.4957,
        -0.05104949523809522
    ],
    [
        89.496,
        0.06272828095238096
    ],
    [
        89.4962,
        0.22255896666666666
    ],
    [
        89.4965,
        0.14836260952380953
    ],
    [
        89.4967,
        0.19116472380952376
    ],
    [
        89.497,
        0.1741367571428571
    ],
    [
        89.4972,
        0.20461294761904758
    ],
    [
        89.4974,
        0.2885917857142856
    ],
    [
        89.4977,
        0.22917449999999998
    ],
    [
        89.498,
        0.21021153809523802
    ],
    [
        89.4982,
        0.19260307142857142
    ],
    [
        89.4985,
        0.17273710952380955
    ],
    [
        89.4987,
        0.2548196476190475
    ],
    [
        89.499,
        0.21246903333333342
    ],
    [
        89.4992,
        0.3415977809523809
    ],
    [
        89.4995,
        0.3131533380952381
    ],
    [
        89.4997,
        0.3741057190476191
    ],
    [
        89.5,
        0.3537882571428572
    ],
    [
        89.5002,
        0.26710042380952387
    ],
    [
        89.5005,
        0.34647396666666663
    ],
    [
        89.5007,
        0.3627279380952381
    ],
    [
        89.501,
        0.4223258238095238
    ],
    [
        89.5012,
        0.4620577476190477
    ],
    [
        89.5015,
        0.32028702380952384
    ],
    [
        89.5017,
        0.3992993714285714
    ],
    [
        89.5019,
        0.36123799523809524
    ],
    [
        89.5022,
        0.37826596190476186
    ],
    [
        89.5024,
        0.3667527333333333
    ],
    [
        89.5027,
        0.29722186666666667
    ],
    [
        89.5029,
        0.45524655714285717
    ],
    [
        89.5031,
        0.46120634761904755
    ],
    [
        89.5034,
        0.4435978857142857
    ],
    [
        89.5036,
        0.4842328047619047
    ],
    [
        89.5039,
        0.5850074095238095
    ],
    [
        89.5041,
        0.5730426809523809
    ],
    [
        89.5043,
        0.5865876523809525
    ],
    [
        89.5046,
        0.5845559047619047
    ],
    [
        89.5048,
        0.5068980571428572
    ],
    [
        89.5051,
        0.5078010571428571
    ],
    [
        89.5053,
        0.5177340380952381
    ],
    [
        89.5056,
        0.5001255714285714
    ],
    [
        89.5058,
        0.4141149857142856
    ],
    [
        89.5061,
        0.4723583714285714
    ],
    [
        89.5063,
        0.4714553714285714
    ],
    [
        89.5066,
        0.5482102238095239
    ],
    [
        89.5069,
        0.49132133333333333
    ],
    [
        89.5071,
        0.5425664857142858
    ],
    [
        89.5074,
        0.5425664857142858
    ],
    [
        89.5076,
        0.629254319047619
    ],
    [
        89.5079,
        0.648397880952381
    ],
    [
        89.5081,
        0.6375619047619049
    ],
    [
        89.5084,
        0.6871365047619049
    ],
    [
        89.5086,
        0.6580669047619049
    ],
    [
        89.5088,
        0.6288097619047619
    ],
    [
        89.5091,
        0.4924570285714285
    ],
    [
        89.5093,
        0.5462305714285713
    ],
    [
        89.5096,
        0.4243258095238095
    ],
    [
        89.5098,
        0.4825691952380952
    ],
    [
        89.51,
        0.5548090523809522
    ],
    [
        89.5102,
        0.4948499714285714
    ],
    [
        89.5105,
        0.5147159333333334
    ],
    [
        89.5107,
        0.5038799571428572
    ],
    [
        89.5109,
        0.49688172380952395
    ],
    [
        89.5112,
        0.452860561904762
    ],
    [
        89.5114,
        0.3959716714285715
    ],
    [
        89.5116,
        0.3575942428571429
    ],
    [
        89.5119,
        0.3845035904761905
    ],
    [
        89.5121,
        0.36644362380952383
    ],
    [
        89.5123,
        0.4802214
    ],
    [
        89.5126,
        0.3447716619047619
    ],
    [
        89.5128,
        0.39312721904761894
    ],
    [
        89.513,
        0.2620118714285714
    ],
    [
        89.5133,
        0.35276319523809524
    ],
    [
        89.5135,
        0.32629840000000004
    ],
    [
        89.5137,
        0.3691005190476191
    ],
    [
        89.514,
        0.4160564285714286
    ],
    [
        89.5142,
        0.36770087619047614
    ],
    [
        89.5144,
        0.4706426761904762
    ],
    [
        89.5147,
        0.49299188095238095
    ],
    [
        89.5149,
        0.3528014047619048
    ],
    [
        89.5152,
        0.3950617190476191
    ],
    [
        89.5154,
        0.3744733571428572
    ],
    [
        89.5157,
        0.3654433761904762
    ],
    [
        89.5159,
        0.3751506047619048
    ],
    [
        89.5161,
        0.30539399047619054
    ],
    [
        89.5163,
        0.38034284761904763
    ],
    [
        89.5165,
        0.3895985809523809
    ],
    [
        89.5167,
        0.3798462
    ],
    [
        89.517,
        0.3375858857142858
    ],
    [
        89.5172,
        0.2321434761904762
    ],
    [
        89.5174,
        0.22311349523809523
    ],
    [
        89.5176,
        0.16893360000000002
    ],
    [
        89.5178,
        0.19168915714285711
    ],
    [
        89.5181,
        0.07520238571428575
    ],
    [
        89.5183,
        0.13683201904761907
    ],
    [
        89.5185,
        0.060438366666666674
    ],
    [
        89.5188,
        0.045809795238095245
    ],
    [
        89.519,
        0.050821433333333346
    ],
    [
        89.5192,
        -0.03153200476190475
    ],
    [
        89.5195,
        -0.11235034285714285
    ],
    [
        89.5197,
        -0.13469954761904762
    ],
    [
        89.5199,
        -0.06372388571428571
    ],
    [
        89.5202,
        0.059806276190476175
    ],
    [
        89.5204,
        0.04174630952380952
    ],
    [
        89.5206,
        0.0925399619047619
    ],
    [
        89.5209,
        0.13693737619047616
    ],
    [
        89.5211,
        0.09720545238095238
    ],
    [
        89.5213,
        0.06943825714285713
    ],
    [
        89.5215,
        0.1254241476190476
    ],
    [
        89.5217,
        0.1223088
    ],
    [
        89.5219,
        0.013740628571428548
    ],
    [
        89.5221,
        0.08778648571428571
    ],
    [
        89.5223,
        0.08778648571428575
    ],
    [
        89.5225,
        0.10494345238095242
    ],
    [
        89.5227,
        0.052118057142857166
    ],
    [
        89.5229,
        0.07582175714285715
    ],
    [
        89.5231,
        0.08216854761904764
    ],
    [
        89.5234,
        0.07410928571428574
    ],
    [
        89.5236,
        0.08211587142857146
    ],
    [
        89.5238,
        0.09764743809523813
    ],
    [
        89.524,
        0.1561165714285715
    ],
    [
        89.5242,
        0.2577038714285715
    ],
    [
        89.5245,
        0.14798958571428575
    ],
    [
        89.5247,
        0.09380969047619049
    ],
    [
        89.5249,
        0.060398757142857154
    ],
    [
        89.5251,
        0.03601780476190478
    ],
    [
        89.5253,
        0.06340874761904763
    ],
    [
        89.5255,
        0.015549842857142893
    ],
    [
        89.5258,
        -0.010185604761904724
    ],
    [
        89.526,
        -0.027794071428571387
    ],
    [
        89.5262,
        -0.027116819047618985
    ],
    [
        89.5265,
        0.17063979523809528
    ],
    [
        89.5267,
        0.13542286190476194
    ],
    [
        89.5269,
        0.16183556190476195
    ],
    [
        89.5271,
        0.19614949523809527
    ],
    [
        89.5273,
        0.2753875904761905
    ],
    [
        89.5275,
        0.1909572571428572
    ],
    [
        89.5277,
        0.19011875714285717
    ],
    [
        89.5279,
        0.18734203809523814
    ],
    [
        89.5281,
        0.1798772523809524
    ],
    [
        89.5283,
        0.16326208571428572
    ],
    [
        89.5285,
        0.12432028571428576
    ],
    [
        89.5287,
        0.1608917142857143
    ],
    [
        89.529,
        0.26518800952380955
    ],
    [
        89.5292,
        0.21642610476190483
    ],
    [
        89.5294,
        0.2677164047619048
    ],
    [
        89.5296,
        0.25620317619047617
    ],
    [
        89.5298,
        0.1478433904761905
    ],
    [
        89.53,
        0.2760691380952381
    ],
    [
        89.5302,
        0.40474638571428573
    ],
    [
        89.5304,
        0.40917107619047616
    ],
    [
        89.5306,
        0.38479012380952377
    ],
    [
        89.5308,
        0.23579541428571427
    ],
    [
        89.531,
        0.130144619047619
    ],
    [
        89.5312,
        0.18297001428571424
    ],
    [
        89.5314,
        0.0312663095238095
    ],
    [
        89.5316,
        0.0305890571428571
    ],
    [
        89.5318,
        0.16513579523809518
    ],
    [
        89.532,
        0.0761904666666666
    ],
    [
        89.5322,
        0.040702638095238095
    ],
    [
        89.5324,
        0.07781586666666664
    ],
    [
        89.5326,
        0.032575657142857144
    ],
    [
        89.5328,
        0.023703699999999918
    ],
    [
        89.5331,
        -0.04808465714285722
    ],
    [
        89.5333,
        -0.13477248571428577
    ],
    [
        89.5335,
        -0.16118518571428575
    ],
    [
        89.5337,
        -0.22918095238095243
    ],
    [
        89.5339,
        -0.21766772380952387
    ],
    [
        89.5341,
        -0.12556190476190485
    ],
    [
        89.5344,
        -0.20683174761904768
    ],
    [
        89.5346,
        -0.34679647619047627
    ],
    [
        89.5348,
        -0.31510123809523816
    ],
    [
        89.535,
        -0.3218737285714286
    ],
    [
        89.5352,
        -0.30561976190476187
    ],
    [
        89.5354,
        -0.18371500000000002
    ],
    [
        89.5357,
        -0.2507626190476191
    ],
    [
        89.5359,
        -0.13388884761904762
    ],
    [
        89.5361,
        -0.15962429523809524
    ],
    [
        89.5363,
        -0.2959770285714286
    ],
    [
        89.5365,
        -0.19032623333333334
    ],
    [
        89.5367,
        -0.10905639523809524
    ],
    [
        89.537,
        -0.015596080952380933
    ],
    [
        89.5372,
        -0.02327156666666668
    ],
    [
        89.5374,
        -0.030992200000000004
    ],
    [
        89.5377,
        -0.022187966666666652
    ],
    [
        89.5379,
        -0.07501336190476189
    ],
    [
        89.5381,
        0.05163214285714285
    ],
    [
        89.5384,
        0.13696547619047617
    ],
    [
        89.5386,
        0.11055277619047618
    ],
    [
        89.5388,
        0.12861274285714286
    ],
    [
        89.539,
        0.05953338095238097
    ],
    [
        89.5393,
        0.07650974761904762
    ],
    [
        89.5395,
        0.02368435238095238
    ],
    [
        89.5397,
        0.09231221904761903
    ],
    [
        89.5399,
        0.11831857142857141
    ],
    [
        89.5402,
        0.0045407952380952365
    ],
    [
        89.5404,
        -0.07876079047619047
    ],
    [
        89.5406,
        -0.042576361904761925
    ],
    [
        89.5408,
        -0.010068423809523833
    ],
    [
        89.5411,
        0.038580604761904704
    ],
    [
        89.5413,
        0.012845157142857087
    ],
    [
        89.5415,
        -0.06707018571428572
    ],
    [
        89.5417,
        -0.07790616190476192
    ],
    [
        89.5419,
        -0.14743702857142857
    ],
    [
        89.5421,
        -0.10535086190476188
    ],
    [
        89.5423,
        -0.1432015380952381
    ],
    [
        89.5425,
        -0.07412217619047619
    ],
    [
        89.5427,
        -0.17435498095238097
    ],
    [
        89.543,
        -0.24298284761904765
    ],
    [
        89.5432,
        -0.18135321428571433
    ],
    [
        89.5434,
        -0.30506397619047626
    ],
    [
        89.5436,
        -0.35518038095238097
    ],
    [
        89.5438,
        -0.3017228857142856
    ],
    [
        89.544,
        -0.32122764761904754
    ],
    [
        89.5442,
        -0.3587020714285714
    ],
    [
        89.5444,
        -0.3383545142857142
    ],
    [
        89.5446,
        -0.3437725047619048
    ],
    [
        89.5449,
        -0.2963650952380953
    ],
    [
        89.5451,
        -0.24624869047619052
    ],
    [
        89.5453,
        -0.32751853333333336
    ],
    [
        89.5455,
        -0.2912857285714286
    ],
    [
        89.5457,
        -0.2989612142857143
    ],
    [
        89.5459,
        -0.2429753238095239
    ],
    [
        89.5461,
        -0.4434409333333334
    ],
    [
        89.5464,
        -0.3404991333333334
    ],
    [
        89.5466,
        -0.3785218095238096
    ],
    [
        89.5468,
        -0.3413483857142857
    ],
    [
        89.547,
        -0.37192132380952386
    ],
    [
        89.5472,
        -0.36048334761904766
    ],
    [
        89.5474,
        -0.42730521904761903
    ],
    [
        89.5476,
        -0.4449136857142858
    ],
    [
        89.5478,
        -0.3490152714285713
    ],
    [
        89.548,
        -0.2332057476190476
    ],
    [
        89.5482,
        -0.297318619047619
    ],
    [
        89.5484,
        -0.2956932238095238
    ],
    [
        89.5486,
        -0.1886879333333333
    ],
    [
        89.5489,
        -0.1551264952380952
    ],
    [
        89.5491,
        -0.06952226190476189
    ],
    [
        89.5493,
        0.0029433428571428825
    ],
    [
        89.5495,
        -0.19481327142857138
    ],
    [
        89.5497,
        -0.11534942857142856
    ],
    [
        89.5499,
        -0.1851060428571428
    ],
    [
        89.5501,
        -0.24921891904761898
    ],
    [
        89.5503,
        -0.24831591904761904
    ],
    [
        89.5505,
        -0.19684501904761906
    ],
    [
        89.5507,
        -0.09119422380952381
    ],
    [
        89.5509,
        -0.05033355238095236
    ],
    [
        89.5511,
        -0.10112719999999997
    ],
    [
        89.5513,
        -0.1003532
    ],
    [
        89.5515,
        -0.10267910476190474
    ],
    [
        89.5516,
        -0.05120820476190476
    ],
    [
        89.5518,
        -0.11486958095238095
    ],
    [
        89.552,
        -0.07938175238095238
    ],
    [
        89.5522,
        -0.10443995238095238
    ],
    [
        89.5524,
        -0.12249991904761906
    ],
    [
        89.5527,
        -0.09243007619047619
    ],
    [
        89.5529,
        -0.2224618238095238
    ],
    [
        89.5531,
        -0.2978621761904762
    ],
    [
        89.5533,
        -0.25614365714285714
    ],
    [
        89.5535,
        -0.18909603809523806
    ],
    [
        89.5537,
        -0.11730768095238091
    ],
    [
        89.5539,
        -0.1019567095238095
    ],
    [
        89.5541,
        -0.08096200000000002
    ],
    [
        89.5543,
        -0.0064646476190476235
    ],
    [
        89.5545,
        0.05042423809523812
    ],
    [
        89.5547,
        0.12988808095238097
    ],
    [
        89.5549,
        0.005274323809523804
    ],
    [
        89.5551,
        0.03687926190476193
    ],
    [
        89.5553,
        0.061937461904761903
    ],
    [
        89.5555,
        0.12559883333333333
    ],
    [
        89.5557,
        0.08668781904761906
    ],
    [
        89.5559,
        0.1469629523809524
    ],
    [
        89.5561,
        0.18024488571428574
    ],
    [
        89.5563,
        0.1672804142857143
    ],
    [
        89.5565,
        0.16159152380952385
    ],
    [
        89.5567,
        0.2997502523809524
    ],
    [
        89.5569,
        0.2613728285714287
    ],
    [
        89.5571,
        0.3304521952380953
    ],
    [
        89.5573,
        0.33406418571428576
    ],
    [
        89.5575,
        0.3205192142857143
    ],
    [
        89.5577,
        0.21378482380952388
    ],
    [
        89.5579,
        0.22281480952380955
    ],
    [
        89.5581,
        0.2038518476190476
    ],
    [
        89.5582,
        0.2878306857142857
    ],
    [
        89.5584,
        0.2688677238095239
    ],
    [
        89.5586,
        0.2742857142857143
    ],
    [
        89.5588,
        0.15689594761904763
    ],
    [
        89.559,
        0.08646208571428574
    ],
    [
        89.5591,
        0.05169665238095241
    ],
    [
        89.5593,
        0.03381728571428574
    ],
    [
        89.5595,
        0.06497072857142858
    ],
    [
        89.5597,
        0.09476967142857141
    ],
    [
        89.5598,
        -0.0034313857142857533
    ],
    [
        89.56,
        0.009339590476190448
    ],
    [
        89.5602,
        -0.03323032857142861
    ],
    [
        89.5604,
        0.008352742857142844
    ],
    [
        89.5606,
        0.01647972857142857
    ],
    [
        89.5608,
        0.06325503809523808
    ],
    [
        89.5609,
        0.027586604761904738
    ],
    [
        89.5611,
        0.041808828571428557
    ],
    [
        89.5613,
        0.03868306190476189
    ],
    [
        89.5615,
        -0.04692117142857145
    ],
    [
        89.5617,
        -0.08304110000000005
    ],
    [
        89.5619,
        -0.012607238095238121
    ],
    [
        89.562,
        -0.2022368714285714
    ],
    [
        89.5622,
        -0.21307284761904766
    ],
    [
        89.5624,
        -0.3403956
    ],
    [
        89.5627,
        -0.23655080476190474
    ],
    [
        89.5629,
        -0.19997937619047623
    ],
    [
        89.5631,
        -0.16792293809523814
    ],
    [
        89.5633,
        -0.17577901904761908
    ],
    [
        89.5635,
        -0.22363792857142867
    ],
    [
        89.5637,
        -0.3211617380952382
    ],
    [
        89.5639,
        -0.20015997619047626
    ],
    [
        89.5641,
        -0.23086191428571434
    ],
    [
        89.5644,
        -0.09812117142857149
    ],
    [
        89.5646,
        -0.12182487619047624
    ],
    [
        89.5648,
        -0.24305239047619054
    ],
    [
        89.565,
        -0.2636407523809524
    ],
    [
        89.5652,
        -0.21487884761904769
    ],
    [
        89.5654,
        -0.1803391666666667
    ],
    [
        89.5656,
        -0.2064705428571429
    ],
    [
        89.5658,
        -0.20376154761904766
    ],
    [
        89.566,
        -0.13739118095238098
    ],
    [
        89.5662,
        -0.25550335238095245
    ],
    [
        89.5664,
        -0.12750335238095237
    ],
    [
        89.5667,
        -0.08912592857142856
    ],
    [
        89.5669,
        -0.04081551904761905
    ],
    [
        89.5671,
        -0.15007830476190478
    ],
    [
        89.5673,
        -0.12298835714285711
    ],
    [
        89.5675,
        -0.16904126666666666
    ],
    [
        89.5677,
        -0.246247619047619
    ],
    [
        89.5679,
        -0.3284204571428571
    ],
    [
        89.5681,
        -0.2158036761904762
    ],
    [
        89.5683,
        -0.29617051904761904
    ],
    [
        89.5685,
        -0.31423048571428575
    ],
    [
        89.5687,
        -0.3927913333333334
    ],
    [
        89.5689,
        -0.30068551428571433
    ],
    [
        89.5691,
        -0.2964865714285714
    ],
    [
        89.5693,
        -0.2666876285714285
    ],
    [
        89.5695,
        -0.34886046666666665
    ],
    [
        89.5697,
        -0.4152308380952381
    ],
    [
        89.5699,
        -0.37893030952380946
    ],
    [
        89.5702,
        -0.2996922142857143
    ],
    [
        89.5704,
        -0.3606445952380953
    ],
    [
        89.5706,
        -0.33023612857142864
    ],
    [
        89.5708,
        -0.3699229
    ],
    [
        89.571,
        -0.380758880952381
    ],
    [
        89.5712,
        -0.3742121476190477
    ],
    [
        89.5714,
        -0.29519980476190477
    ],
    [
        89.5716,
        -0.23966541428571422
    ],
    [
        89.5717,
        -0.23966541428571422
    ],
    [
        89.5719,
        -0.12648963333333332
    ],
    [
        89.5721,
        -0.10007693333333334
    ],
    [
        89.5723,
        -0.02577308095238095
    ],
    [
        89.5725,
        0.003484061904761912
    ],
    [
        89.5726,
        0.09423538571428572
    ],
    [
        89.5728,
        0.15654226190476192
    ],
    [
        89.573,
        0.05205246666666665
    ],
    [
        89.5732,
        0.11625564285714285
    ],
    [
        89.5733,
        -0.016485099999999992
    ],
    [
        89.5735,
        0.08103870952380954
    ],
    [
        89.5737,
        0.22596992857142856
    ],
    [
        89.5739,
        0.16068315238095235
    ],
    [
        89.5741,
        0.28191066666666664
    ],
    [
        89.5743,
        0.3461138428571428
    ],
    [
        89.5745,
        0.4338175476190476
    ],
    [
        89.5746,
        0.4213561714285714
    ],
    [
        89.5748,
        0.46614488571428564
    ],
    [
        89.575,
        0.41489973809523806
    ],
    [
        89.5752,
        0.4056440095238094
    ],
    [
        89.5754,
        0.2864482428571427
    ],
    [
        89.5756,
        0.31624718571428556
    ],
    [
        89.5758,
        0.2816322523809523
    ],
    [
        89.576,
        0.3151560619047617
    ],
    [
        89.5762,
        0.2691031523809524
    ],
    [
        89.5765,
        0.2635497142857143
    ],
    [
        89.5766,
        0.17415288571428572
    ],
    [
        89.5768,
        0.05947210952380957
    ],
    [
        89.577,
        0.08462706190476194
    ],
    [
        89.5772,
        0.02943129523809524
    ],
    [
        89.5774,
        0.13237309523809526
    ],
    [
        89.5776,
        0.12830960476190476
    ],
    [
        89.5778,
        -0.02068510476190477
    ],
    [
        89.578,
        0.05742424285714286
    ],
    [
        89.5782,
        -0.14304136666666664
    ],
    [
        89.5784,
        -0.13681068095238094
    ],
    [
        89.5786,
        -0.26774542380952376
    ],
    [
        89.5787,
        -0.23794648095238094
    ],
    [
        89.5789,
        -0.2574512428571429
    ],
    [
        89.5791,
        -0.1310314904761905
    ],
    [
        89.5793,
        -0.1086822857142857
    ],
    [
        89.5795,
        -0.019285461904761887
    ],
    [
        89.5797,
        -0.04908440476190475
    ],
    [
        89.5799,
        -0.12764525238095237
    ],
    [
        89.5801,
        -0.09005795238095235
    ],
    [
        89.5803,
        -0.11946989523809523
    ],
    [
        89.5805,
        -0.10287730476190474
    ],
    [
        89.5807,
        -0.04625931428571426
    ],
    [
        89.5809,
        0.07113045714285716
    ],
    [
        89.5811,
        0.02675454285714287
    ],
    [
        89.5813,
        0.05621485714285718
    ],
    [
        89.5816,
        0.046281876190476225
    ],
    [
        89.5818,
        0.023932666666666675
    ],
    [
        89.582,
        0.041992633333333355
    ],
    [
        89.5822,
        0.04560462857142858
    ],
    [
        89.5824,
        0.11513549523809526
    ],
    [
        89.5826,
        0.11310375238095241
    ],
    [
        89.5828,
        0.05440886666666671
    ],
    [
        89.583,
        0.10587976666666671
    ],
    [
        89.5831,
        0.06253585238095241
    ],
    [
        89.5833,
        -0.0647869
    ],
    [
        89.5835,
        -0.04108319523809524
    ],
    [
        89.5837,
        -0.13318901428571428
    ],
    [
        89.5839,
        -0.10339007142857143
    ],
    [
        89.5841,
        -0.02347472857142857
    ],
    [
        89.5843,
        -0.09323134285714287
    ],
    [
        89.5845,
        -0.10716331428571431
    ],
    [
        89.5847,
        -0.07770299523809523
    ],
    [
        89.5849,
        -0.13296648571428574
    ],
    [
        89.5851,
        -0.22236330952380948
    ],
    [
        89.5853,
        -0.15915343333333332
    ],
    [
        89.5855,
        -0.1523809476190476
    ],
    [
        89.5857,
        -0.16479717142857142
    ],
    [
        89.5859,
        -0.21784831428571427
    ],
    [
        89.5861,
        -0.10316753809523808
    ],
    [
        89.5863,
        -0.10587653333333327
    ],
    [
        89.5865,
        -0.09197036190476189
    ],
    [
        89.5867,
        -0.09725290476190473
    ],
    [
        89.5869,
        -0.0498455
    ],
    [
        89.5871,
        -0.15278730000000001
    ],
    [
        89.5873,
        -0.1094433857142857
    ],
    [
        89.5875,
        -0.055263490476190474
    ],
    [
        89.5877,
        -0.10876613809523808
    ],
    [
        89.5879,
        -0.11418412857142858
    ],
    [
        89.588,
        -0.2360888904761905
    ],
    [
        89.5882,
        -0.30787724761904767
    ],
    [
        89.5884,
        -0.2757455619047619
    ],
    [
        89.5886,
        -0.32450746666666663
    ],
    [
        89.5888,
        -0.25068736190476193
    ],
    [
        89.589,
        -0.25339635714285713
    ],
    [
        89.5892,
        -0.20327995714285713
    ],
    [
        89.5894,
        -0.2511388619047619
    ],
    [
        89.5896,
        -0.22811240476190478
    ],
    [
        89.5898,
        -0.23736813809523807
    ],
    [
        89.59,
        -0.16061329047619047
    ],
    [
        89.5902,
        -0.14706831904761902
    ],
    [
        89.5904,
        -0.2680700809523809
    ],
    [
        89.5906,
        -0.22824785714285717
    ],
    [
        89.5908,
        -0.2913674333333333
    ],
    [
        89.591,
        -0.2606654904761904
    ],
    [
        89.5912,
        -0.1868453857142857
    ],
    [
        89.5914,
        -0.2843691952380952
    ],
    [
        89.5916,
        -0.27895120476190477
    ],
    [
        89.5918,
        -0.15162845238095238
    ],
    [
        89.592,
        -0.14621046190476192
    ],
    [
        89.5922,
        -0.14350146666666666
    ],
    [
        89.5924,
        -0.034690180952381
    ],
    [
        89.5926,
        -0.05422504285714286
    ],
    [
        89.5928,
        -0.04880705238095241
    ],
    [
        89.593,
        -0.13394688571428567
    ],
    [
        89.5932,
        -0.08518498095238096
    ],
    [
        89.5934,
        -0.22469820476190477
    ],
    [
        89.5936,
        -0.12446539999999998
    ],
    [
        89.5938,
        -0.26262412857142853
    ],
    [
        89.594,
        -0.2194092142857143
    ],
    [
        89.5942,
        -0.2730473095238095
    ],
    [
        89.5943,
        -0.28930127619047613
    ],
    [
        89.5945,
        -0.16062402857142852
    ],
    [
        89.5947,
        -0.15836653333333334
    ],
    [
        89.5949,
        -0.11366811904761905
    ],
    [
        89.5951,
        -0.11931185714285715
    ],
    [
        89.5952,
        -0.22293090476190477
    ],
    [
        89.5954,
        -0.041428257142857125
    ],
    [
        89.5956,
        -0.057682228571428566
    ],
    [
        89.5958,
        -0.1775552428571429
    ],
    [
        89.5959,
        -0.06865365714285715
    ],
    [
        89.5961,
        0.005843695238095245
    ],
    [
        89.5963,
        -0.027792985714285727
    ],
    [
        89.5964,
        -0.008559119047619065
    ],
    [
        89.5966,
        0.019072623809523792
    ],
    [
        89.5968,
        0.006688647619047606
    ],
    [
        89.5969,
        0.027683357142857128
    ],
    [
        89.5971,
        0.043937323809523796
    ],
    [
        89.5973,
        -0.0603589761904762
    ],
    [
        89.5975,
        0.0407768238095238
    ],
    [
        89.5976,
        0.019008119047619024
    ],
    [
        89.5978,
        0.03968678095238093
    ],
    [
        89.598,
        -0.08221798095238096
    ],
    [
        89.5982,
        -0.08357248095238096
    ],
    [
        89.5983,
        -0.13865537619047621
    ],
    [
        89.5985,
        -0.15265184761904763
    ],
    [
        89.5987,
        -0.12183703333333333
    ],
    [
        89.5988,
        -0.04056719047619047
    ],
    [
        89.599,
        -0.12454602857142857
    ],
    [
        89.5992,
        -0.1651809476190476
    ],
    [
        89.5993,
        -0.22410158571428568
    ],
    [
        89.5995,
        -0.2310772476190476
    ],
    [
        89.5997,
        -0.22662675714285713
    ],
    [
        89.5998,
        -0.12571670476190475
    ],
    [
        89.6,
        -0.13655268571428567
    ],
    [
        89.6002,
        -0.047697657142857106
    ],
    [
        89.6004,
        -0.02241370476190473
    ],
    [
        89.6006,
        -0.04340841428571426
    ],
    [
        89.6007,
        0.014564071428571418
    ],
    [
        89.6009,
        0.034881533333333326
    ],
    [
        89.6011,
        -0.05270929523809524
    ],
    [
        89.6013,
        -0.02426485238095239
    ],
    [
        89.6014,
        -0.046839809523809534
    ],
    [
        89.6016,
        0.007340080952380946
    ],
    [
        89.6017,
        -0.10643769523809525
    ],
    [
        89.6019,
        -0.056321290476190486
    ],
    [
        89.602,
        -0.003044395238095246
    ],
    [
        89.6022,
        -0.0575629142857143
    ],
    [
        89.6023,
        -0.07273328571428574
    ],
    [
        89.6025,
        -0.06400430476190479
    ],
    [
        89.6026,
        -0.07077679047619048
    ],
    [
        89.6028,
        -0.012081904761904778
    ],
    [
        89.6029,
        -0.0049707904761904865
    ],
    [
        89.6031,
        -0.010775776190476202
    ],
    [
        89.6033,
        -0.12726255238095238
    ],
    [
        89.6034,
        -0.11155038095238097
    ],
    [
        89.6036,
        -0.20162445714285712
    ],
    [
        89.6038,
        -0.20162445714285718
    ],
    [
        89.6039,
        -0.2151694285714286
    ],
    [
        89.6041,
        -0.1842868857142857
    ],
    [
        89.6043,
        -0.19512286666666664
    ],
    [
        89.6044,
        -0.09895355238095241
    ],
    [
        89.6045,
        -0.10098530000000001
    ],
    [
        89.6047,
        -0.09150381904761908
    ],
    [
        89.6048,
        -0.1416202190476191
    ],
    [
        89.605,
        -0.07524984761904763
    ],
    [
        89.6051,
        -0.06938036190476196
    ],
    [
        89.6053,
        -0.09601880952380955
    ],
    [
        89.6054,
        -0.06621986666666671
    ],
    [
        89.6056,
        -0.05104949523809526
    ],
    [
        89.6058,
        0.01607337619047616
    ],
    [
        89.6059,
        0.06348078095238095
    ],
    [
        89.6061,
        0.010203885714285706
    ],
    [
        89.6063,
        -0.0067273333333333395
    ],
    [
        89.6064,
        -0.01485431904761905
    ],
    [
        89.6066,
        -0.021626804761904768
    ],
    [
        89.6067,
        0.0005869476190476138
    ],
    [
        89.6069,
        0.03783562857142858
    ],
    [
        89.6071,
        -0.02808324285714287
    ],
    [
        89.6072,
        -0.030250442857142866
    ],
    [
        89.6074,
        -0.045962614285714284
    ],
    [
        89.6075,
        0.04885220476190476
    ],
    [
        89.6077,
        0.05088394761904761
    ],
    [
        89.6079,
        -0.054766842857142874
    ],
    [
        89.608,
        -0.1008197523809524
    ],
    [
        89.6082,
        -0.07542292857142859
    ],
    [
        89.6084,
        -0.055406466666666675
    ],
    [
        89.6085,
        -0.0499884761904762
    ],
    [
        89.6087,
        -0.07540142857142858
    ],
    [
        89.6089,
        -0.045602490476190485
    ],
    [
        89.609,
        0.03837634761904761
    ],
    [
        89.6092,
        -0.08217391904761906
    ],
    [
        89.6094,
        -0.11739084761904761
    ],
    [
        89.6096,
        -0.02420142857142858
    ],
    [
        89.6098,
        -0.02420142857142858
    ],
    [
        89.61,
        -0.08650830476190477
    ],
    [
        89.6102,
        -0.0734148285714286
    ],
    [
        89.6104,
        -0.15468466666666672
    ],
    [
        89.6106,
        -0.15265292380952383
    ],
    [
        89.6108,
        -0.08402505714285717
    ],
    [
        89.6109,
        -0.09201659047619051
    ],
    [
        89.6111,
        -0.13400600952380953
    ],
    [
        89.6113,
        -0.21798484761904766
    ],
    [
        89.6115,
        -0.22949807142857145
    ],
    [
        89.6117,
        -0.14849913333333334
    ],
    [
        89.6119,
        -0.08294146190476191
    ],
    [
        89.612,
        -0.09975980476190478
    ],
    [
        89.6122,
        -0.07868984761904763
    ],
    [
        89.6124,
        -0.08230183809523807
    ],
    [
        89.6126,
        -0.0823018380952381
    ],
    [
        89.6128,
        -0.09855580476190477
    ],
    [
        89.613,
        -0.16018543809523805
    ],
    [
        89.6131,
        -0.20488384761904763
    ],
    [
        89.6134,
        -0.20556109523809524
    ],
    [
        89.6135,
        -0.22289866190476185
    ],
    [
        89.6137,
        -0.2716605666666666
    ],
    [
        89.6139,
        -0.1714277666666667
    ],
    [
        89.6141,
        -0.1425318238095238
    ],
    [
        89.6143,
        -0.11544187619047619
    ],
    [
        89.6145,
        -0.11882811904761906
    ],
    [
        89.6146,
        -0.2443448761904762
    ],
    [
        89.6148,
        -0.22064117142857143
    ],
    [
        89.615,
        -0.12718085238095242
    ],
    [
        89.6152,
        -0.03507503333333338
    ],
    [
        89.6154,
        -0.04591101428571432
    ],
    [
        89.6156,
        -0.02125916190476194
    ],
    [
        89.6158,
        -0.05295440000000004
    ],
    [
        89.616,
        0.021091457142857123
    ],
    [
        89.6162,
        -0.027218947619047647
    ],
    [
        89.6164,
        -0.03150819047619052
    ],
    [
        89.6166,
        -0.06749911904761909
    ],
    [
        89.6168,
        -0.11355202857142861
    ],
    [
        89.617,
        -0.10158730000000003
    ],
    [
        89.6172,
        -0.06095238095238099
    ],
    [
        89.6174,
        -0.02806819523809526
    ],
    [
        89.6176,
        -0.04161316666666669
    ],
    [
        89.6179,
        0.02949794285714288
    ],
    [
        89.618,
        -0.03280893333333335
    ],
    [
        89.6183,
        -0.04815990476190478
    ],
    [
        89.6185,
        -0.008879480952380947
    ],
    [
        89.6187,
        -0.056738385714285716
    ],
    [
        89.6189,
        0.05162140476190474
    ],
    [
        89.6191,
        0.03717343333333332
    ],
    [
        89.6193,
        0.02904644761904764
    ],
    [
        89.6195,
        -0.08473132857142855
    ],
    [
        89.6197,
        0.004665495238095256
    ],
    [
        89.6199,
        0.0750993571428572
    ],
    [
        89.6201,
        -0.007524980952380903
    ],
    [
        89.6203,
        -0.035698523809523805
    ],
    [
        89.6205,
        0.06769477142857146
    ],
    [
        89.6206,
        0.01690111904761908
    ],
    [
        89.6208,
        -0.01831581428571426
    ],
    [
        89.621,
        0.0697265142857143
    ],
    [
        89.6212,
        0.017533214285714332
    ],
    [
        89.6214,
        0.07906609523809525
    ],
    [
        89.6215,
        0.040538166666666674
    ],
    [
        89.6217,
        0.08297908095238096
    ],
    [
        89.6219,
        0.048245900000000015
    ],
    [
        89.6221,
        0.13764272380952378
    ],
    [
        89.6223,
        0.1809866428571428
    ],
    [
        89.6225,
        0.07127235714285714
    ],
    [
        89.6226,
        0.18414713809523808
    ],
    [
        89.6228,
        0.19566036190476185
    ],
    [
        89.623,
        0.1943961619047619
    ],
    [
        89.6232,
        0.13660427619047621
    ],
    [
        89.6234,
        0.1702732095238095
    ],
    [
        89.6236,
        0.04024146666666666
    ],
    [
        89.6237,
        -0.07173031428571429
    ],
    [
        89.6239,
        0.03708097142857142
    ],
    [
        89.6241,
        0.0065596285714285656
    ],
    [
        89.6243,
        -0.06495782857142858
    ],
    [
        89.6244,
        -0.037867880952380945
    ],
    [
        89.6246,
        0.046110957142857144
    ],
    [
        89.6248,
        -0.07714830476190478
    ],
    [
        89.625,
        -0.12699380476190475
    ],
    [
        89.6252,
        -0.16324273333333336
    ],
    [
        89.6253,
        -0.10138735238095238
    ],
    [
        89.6255,
        -0.09371186666666668
    ],
    [
        89.6257,
        -0.09541466190476192
    ],
    [
        89.6258,
        -0.1269067238095238
    ],
    [
        89.626,
        -0.22262453809523813
    ],
    [
        89.6261,
        -0.11245875714285715
    ],
    [
        89.6263,
        -0.18424711904761906
    ],
    [
        89.6265,
        -0.21180592857142858
    ],
    [
        89.6266,
        -0.19609375714285715
    ],
    [
        89.6268,
        -0.21821721428571428
    ],
    [
        89.6269,
        -0.22053920952380954
    ],
    [
        89.6271,
        -0.20338224285714285
    ],
    [
        89.6272,
        -0.25124114761904764
    ],
    [
        89.6274,
        -0.23137518571428567
    ],
    [
        89.6275,
        -0.25259564285714287
    ],
    [
        89.6276,
        -0.2508347952380952
    ],
    [
        89.6278,
        -0.2761187476190476
    ],
    [
        89.6279,
        -0.3099811809523809
    ],
    [
        89.6281,
        -0.19213990952380955
    ],
    [
        89.6282,
        -0.05398118095238093
    ],
    [
        89.6284,
        0.0020047095238095156
    ],
    [
        89.6285,
        -0.04028570952380949
    ],
    [
        89.6287,
        -0.06903115238095235
    ],
    [
        89.6288,
        -0.09422480476190473
    ],
    [
        89.629,
        -0.12865161428571426
    ],
    [
        89.6291,
        -0.07988970952380953
    ],
    [
        89.6293,
        -0.09072568571428567
    ],
    [
        89.6294,
        -0.08395319999999998
    ],
    [
        89.6295,
        -0.09702930952380949
    ],
    [
        89.6297,
        -0.09702930952380949
    ],
    [
        89.6298,
        -0.037882923809523836
    ],
    [
        89.63,
        -0.06381187142857143
    ],
    [
        89.6301,
        -0.031868309523809535
    ],
    [
        89.6303,
        0.029354971428571425
    ],
    [
        89.6305,
        -0.01751063809523809
    ],
    [
        89.6306,
        -0.0053201619047619116
    ],
    [
        89.6308,
        -0.013785771428571429
    ],
    [
        89.6309,
        0.0340731380952381
    ],
    [
        89.6311,
        0.05235885238095239
    ],
    [
        89.6312,
        -0.0011437952380952264
    ],
    [
        89.6314,
        -0.07157765714285716
    ],
    [
        89.6315,
        -0.04177871904761904
    ],
    [
        89.6317,
        -0.008969780952380959
    ],
    [
        89.6318,
        -0.08452063333333333
    ],
    [
        89.6319,
        0.02519365238095239
    ],
    [
        89.6321,
        -0.014538266666666667
    ],
    [
        89.6322,
        0.06673157142857143
    ],
    [
        89.6323,
        -0.013635271428571419
    ],
    [
        89.6325,
        -0.06510617142857142
    ],
    [
        89.6326,
        -0.024245499999999993
    ],
    [
        89.6328,
        -0.04501445714285714
    ],
    [
        89.6329,
        -0.1014518476190476
    ],
    [
        89.6331,
        -0.08519788095238094
    ],
    [
        89.6332,
        -0.09366349047619048
    ],
    [
        89.6333,
        -0.0564599619047619
    ],
    [
        89.6335,
        0.02968607142857145
    ],
    [
        89.6336,
        0.09199294761904764
    ],
    [
        89.6338,
        0.15396120000000002
    ],
    [
        89.6339,
        0.13138624285714287
    ],
    [
        89.634,
        0.17044091428571434
    ],
    [
        89.6342,
        0.1814123428571429
    ],
    [
        89.6343,
        0.19279011904761909
    ],
    [
        89.6345,
        0.12479435238095242
    ],
    [
        89.6346,
        0.10583139047619049
    ],
    [
        89.6348,
        0.20606419523809524
    ],
    [
        89.6349,
        0.12996607142857144
    ],
    [
        89.6351,
        0.21123590952380952
    ],
    [
        89.6352,
        0.08662215714285716
    ],
    [
        89.6354,
        0.19091845238095234
    ],
    [
        89.6355,
        0.22342639047619045
    ],
    [
        89.6357,
        0.13312656666666667
    ],
    [
        89.6358,
        0.16635689999999995
    ],
    [
        89.6359,
        0.1406214476190476
    ],
    [
        89.636,
        0.12504472857142854
    ],
    [
        89.6362,
        0.05731986190476187
    ],
    [
        89.6363,
        -0.0018265238095238347
    ],
    [
        89.6364,
        -0.06277890476190476
    ],
    [
        89.6366,
        -0.1413397476190476
    ],
    [
        89.6367,
        -0.2117736095238095
    ],
    [
        89.6369,
        -0.1993896333333333
    ],
    [
        89.637,
        -0.2346065619047619
    ],
    [
        89.6371,
        -0.21239280476190472
    ],
    [
        89.6373,
        -0.29420444285714287
    ],
    [
        89.6374,
        -0.36436740476190477
    ],
    [
        89.6375,
        -0.4293832809523809
    ],
    [
        89.6377,
        -0.49439915714285715
    ],
    [
        89.6378,
        -0.45487246190476194
    ],
    [
        89.638,
        -0.5334333047619048
    ],
    [
        89.6381,
        -0.4824139047619048
    ],
    [
        89.6383,
        -0.5085363523809524
    ],
    [
        89.6385,
        -0.5311113095238095
    ],
    [
        89.6387,
        -0.5013123666666667
    ],
    [
        89.6388,
        -0.49968697142857144
    ],
    [
        89.639,
        -0.4793695095238095
    ],
    [
        89.6391,
        -0.39335892857142846
    ],
    [
        89.6392,
        -0.25790919523809525
    ],
    [
        89.6394,
        -0.24233247619047626
    ],
    [
        89.6395,
        -0.22066051904761913
    ],
    [
        89.6396,
        -0.20034306190476195
    ],
    [
        89.6398,
        -0.18205734761904765
    ],
    [
        89.6399,
        -0.1819799476190476
    ],
    [
        89.6401,
        -0.17952895238095246
    ],
    [
        89.6402,
        -0.19578291904761902
    ],
    [
        89.6404,
        -0.09419561904761913
    ],
    [
        89.6406,
        -0.018343766666666685
    ],
    [
        89.6407,
        0.0568308380952381
    ],
    [
        89.6408,
        0.07850279523809524
    ],
    [
        89.641,
        0.06540931904761907
    ],
    [
        89.6411,
        0.10785023333333335
    ],
    [
        89.6413,
        0.14351866190476192
    ],
    [
        89.6414,
        0.20079454761904764
    ],
    [
        89.6416,
        0.2545229428571429
    ],
    [
        89.6417,
        0.2789038952380953
    ],
    [
        89.6418,
        0.20521923809523815
    ],
    [
        89.642,
        0.2548841428571429
    ],
    [
        89.6421,
        0.1609723285714286
    ],
    [
        89.6422,
        0.060739523809523834
    ],
    [
        89.6424,
        0.014009366666666693
    ],
    [
        89.6425,
        -0.008565590476190467
    ],
    [
        89.6426,
        -0.0028766999999999994
    ],
    [
        89.6428,
        0.012700019047619063
    ],
    [
        89.6429,
        -0.01962731904761906
    ],
    [
        89.643,
        0.051967538095238124
    ],
    [
        89.6432,
        0.08582997142857146
    ],
    [
        89.6433,
        -0.010339338095238095
    ],
    [
        89.6435,
        -0.010339338095238095
    ],
    [
        89.6436,
        0.007946376190476197
    ],
    [
        89.6437,
        0.030521333333333348
    ],
    [
        89.6439,
        -0.0006966047619047537
    ],
    [
        89.644,
        0.03474607619047621
    ],
    [
        89.6441,
        0.010365123809523822
    ],
    [
        89.6443,
        -0.07429096190476188
    ],
    [
        89.6444,
        -0.11537738095238093
    ],
    [
        89.6445,
        -0.07067897142857145
    ],
    [
        89.6447,
        -0.0006966047619047828
    ],
    [
        89.6448,
        0.006075880952380938
    ],
    [
        89.645,
        0.02007235238095237
    ],
    [
        89.6451,
        0.036326323809523796
    ],
    [
        89.6453,
        0.10766318095238095
    ],
    [
        89.6454,
        0.08499792857142857
    ],
    [
        89.6455,
        0.04544660476190477
    ],
    [
        89.6457,
        -0.005572795238095235
    ],
    [
        89.6458,
        0.019410157142857147
    ],
    [
        89.6459,
        -0.046960209523809526
    ],
    [
        89.6461,
        -0.08082264285714286
    ],
    [
        89.6462,
        -0.015806771428571422
    ],
    [
        89.6463,
        -0.015806771428571422
    ],
    [
        89.6464,
        -0.04966920476190476
    ],
    [
        89.6465,
        -0.061408180952380945
    ],
    [
        89.6466,
        -0.050959199999999996
    ],
    [
        89.6467,
        -0.06653591904761905
    ],
    [
        89.6469,
        -0.06653591904761905
    ],
    [
        89.647,
        -0.0496047
    ],
    [
        89.647,
        -0.039671719047619035
    ],
    [
        0,
        -0.07082515714285714
    ],
    [
        0,
        -0.0812096380952381
    ],
    [
        0,
        -0.09836660476190474
    ],
    [
        0,
        -0.0888851238095238
    ],
    [
        0,
        -0.07263115714285713
    ],
    [
        0,
        -0.07895214285714285
    ],
    [
        0,
        -0.06757436666666666
    ],
    [
        0,
        -0.016103466666666663
    ],
    [
        0,
        0.0010535000000000002
    ],
    [
        0,
        -0.004063490476190476
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ],
    [
        0,
        0
    ]
]

export const reversedData = (graph) => {
    const graprev1 = []
    for (let index = 0; index < graph?.length; index++) {
        let grp =["",""]
        grp[0] = graph[index][1]
        grp[1] = graph[index][0]
     
        graprev1.push(grp)
    }
    return graprev1
}
export const VoltageData = (voltage) => {
    const graprev1 = []
    for (let index = 0; index < voltage?.length; index+=10) {
        let grp =["",""]
        grp[0] = voltage[index][2]
        grp[1] = voltage[index][1]
     
        graprev1.push(grp)
    }
    return graprev1
}
export const VoltageData2 = (voltage) => {
    const graprev1 = []
    for (let index = 0; index < voltage?.length; index++) {
        let grp =["",""]
        grp[0] = voltage[index][2]
        grp[1] = voltage[index][0]
     
        graprev1.push(grp)
    }
    return graprev1
}
export const DvaData = (Dva) => {
    const graprev1 = []
    for (let index = 0; index < Dva?.length; index +=10) {
        let grp =["",""]
        grp[0] = Dva[index]?.[0]
        grp[1] = Dva[index]?.[1]
        graprev1.push(grp)
    }
    return graprev1
}
export function findPeaks(Dva) {
    let highestPeak = { x: null, y: -Infinity };
    let lowestPeak = { x: null, y: Infinity };

    for (const [x, y] of Dva) {
        if (y > highestPeak.y) {
            highestPeak = { x, y };
        }
        if (y < lowestPeak.y) {
            lowestPeak = { x, y };
        }
    }

    return {highestPeak, lowestPeak};
}

export const nyquist = (nqst)=>{
    let redData=[];
    let blueData=[];
    for (let index = 0; index < nqst?.length; index++) {
        let blue =["",""]
        let red =["",""]
        blue[0] =nqst[index]?.["Zreal (ohm)"]
        blue[1] = nqst[index]?.[" -Zimag (ohm)"]
        red[0] = nqst[index]?.["Fit1 - Zreal (ohm)"]
        red[1] = nqst[index]?.[" Fit1 - -Zimag (ohm)"]
        blueData.push(blue)
        redData.push(red)
    }
    return {redData,blueData}
}