import React, { useState, useCallback, useEffect } from "react";
import { fieldmap, stepSop } from "../../fieldmap";
import FileField from "../../fieldComponants/FileField";
import { Field, Step } from "../../types";
import { useAppSelector } from "../../../../store/rtctype";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import SopHeader from "../sopHeader";
import ExcelUpload from "../../fieldComponants/ExcelUpload";
import SerialNumberGeneration from "../../fieldComponants/CustomAction/SerialNumberGeneration";
import InputField from "../../fieldComponants/InputField";
import { getPassportList, postVendor } from "../../../../service/apiService";
import SelectField from "../../fieldComponants/SelectField";
import {
  resetStepData,
  resetToggle,
} from "../../../../reducers/inspectionReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormDetails } from "../../formDetails/FormDetails";

export default function VendorForm({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [stepFieldMap, setStepFieldMap] = useState<Step>(fieldmap[step]);
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData, step]);
  const errorHandle = (errors: any) => {
    const new_err = [];
    // for (const error in errors) {
    //   const stepField = fieldmap[step].fields;
    //   const stepFieldIndex = stepField.findIndex(
    //     (field: any) => field.key == error
    //   );

    //   if (stepFieldIndex >= 0) {
    //     new_err.push(`${stepField[stepFieldIndex].name} : ${errors[error]}`);
    //   }
    // }
    new_err.push(errors?.message);
    return new_err;
  };

  useEffect(() => {
    setLoading(true);
    getPassportList()
      .then((res: any) => {
        // console.log("%c Line:138 🍉 any", "color:#42b983", res);
        if (res?.success && fieldmap["step_19"]) {
          const arr: any = [];
          res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.map((obj: any) =>
              arr.push({ packId: obj?.packId, name: obj?.packId })
            );

          fieldmap["step_19"].fields[0]["option"] = arr;
        }
      })
      .catch((e: any) => {
        console.log("%c Line:140 🥃 e", "color:#7f2b82", e);
      })
      .finally(() => setLoading(false));
  }, [step]);

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  const handleSubmit = () => {
    setLoadingBtn(true);
    const data = formData[step];
    const body = {
      packId: data?.packId,
      rejectModuleId: data?.rejected_moduleId,
      replaceModule: data?.replace_module,
      file: data?.vendor_excel,
      image: data?.vendor_image,
    };
    postVendor(body).then((res) => {
      if (res?.success) {
        const currentDataObj = { ...formData[step] };
        const objectKeys = Object.keys(currentDataObj);
        for (let i = 0; i < objectKeys.length; i++) {
          currentDataObj[objectKeys[i]] = "";
        }
        dispatch(resetStepData({ step: step, value: currentDataObj }));
        dispatch(resetToggle(!formData.toggle));
        toast(res?.data?.message);
        setLoadingBtn(false);
      } else {
        setParamError(errorHandle(res.message));
        setErOpen(true);
        setLoadingBtn(false);
      }
    });
  };
  console.log(
    "%c Line:131 🥛 formData",
    "color:#ffdd4d",
    formData,
    stepFieldMap
  );
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={stepFieldMap?.title} link={stepSop[step]} />
          <FormDetails step={step} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {formData["step_19"] &&
                stepFieldMap?.fields?.map((field: Field, index: number) => {
                  if (field.type === "file") {
                    return (
                      <FileField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={
                          formData[step][field.key]
                            ? formData[step][field.key]
                            : ""
                        }
                        key={field.key}
                      />
                    );
                  } else if (field.type === "select") {
                    return (
                      <SelectField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loading}
                        value={
                          formData[step][field.key]
                            ? formData[step][field.key]
                            : ""
                        }
                        key={field.key}
                      />
                    );
                  } else if (field.type === "field") {
                    return (
                      <InputField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step][field.key]}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "custom") {
                    return (
                      <SerialNumberGeneration
                        status={index <= currentActive}
                        data={field}
                        loading={loadingBtn}
                        key={field.key}
                        onClick={handleSubmit}
                      />
                    );
                  } else if (field.type === "excel") {
                    return (
                      <ExcelUpload
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={
                          formData[step][field.key]
                            ? formData[step][field.key]
                            : ""
                        }
                        key={field.key}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      </div>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />
    </React.Fragment>
  );
}
