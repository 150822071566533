import React from "react";
import SopHeader from "./StepForms/sopHeader";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const CustomerPortal = () => {
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"Customer Portal"} link={""} />
        </div>

        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box common common-kpi">
              <div className="graph_heading">
                {/* <h4>Customer Portal</h4> */}
              </div>

              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">Inventory</InputLabel>
                <Select variant="outlined">
                  <MenuItem value="Pack Inventory ">Pack Inventory</MenuItem>
                  <MenuItem
                    value="Packs in recharge & sent back for PM
"
                  >
                    Packs in recharge & sent back for PM
                  </MenuItem>
                  <MenuItem value="Packs at LVT shipment centers ready for field deployment ">
                    Packs at LVT shipment centers ready for field deployment
                  </MenuItem>
                </Select>
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  ColumbAI Link
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">Order Status</InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  IT Ticket & Request Form
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  Points of Contact
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  Customer Communication/Feedback
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">FAQ Section</InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
              <FormControl variant="standard" className="sm-form-control">
                <InputLabel htmlFor="component-simple">
                  Registration form
                </InputLabel>
                <TextField placeholder="Enter Value" />
                <Button variant="contained">Save</Button>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CustomerPortal;
