import React, { useEffect, useState } from "react";
import SopHeader from "../StepForms/sopHeader";
import { fieldmap, stepSop } from "../fieldmap";
import { Step } from "../types";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { getModel, getOEM, getScheduleData } from "../../../service/apiService";
import Pagination from "../StepForms/Battery-Data/Pagination";
import { useAppSelector } from "../../../store/rtctype";

interface paginationData {
  docs?: any[];
  nextPage?: number;
  page?: number;
  pagingCounter?: number;
  prevPage?: number;
  totalDocs?: number;
  totalPages?: number;
}

interface OEMS {
  value: string;
  label: string;
}

interface Model {
  value: string;
  label: string;
  oem_id: string;
}
function Schedular({ step }: { step: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const userData = useAppSelector((state) => state.user).user;
  const [OEM, setOEM] = useState<OEMS[]>([]);
  const [model, setModel] = useState<Model[]>([]);
  const [paginationData, setPaginationData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState<any>(
    moment().format("MM/DD/YYYY")
  );
  // const [endDate, setEndDate] = useState<any>(moment().format("MM/DD/YYYY"));

  const handelData = () => {
    const params: any = {
      page: page,
      sizePerPage: "10",
      // userId: userData?.id,
    };
    if (userData.role !== "Admin") {
      // params.userId = userData?.id;
    }
    if (startDate) {
      params.date = moment(startDate).format("DD/MM/YYYY");
    }
    // if (endDate) {
    //   params.endDate = moment(endDate).format("DD/MM/YYYY");
    // }
    setLoading(true);
    getScheduleData(params)
      .then((res: any) => {
        console.log("%c Line:30 🥝 res", "color:#ffdd4d", res);
        if (res?.success) {
          setData(res?.data?.data?.docs);
          setPaginationData(res?.data?.data);
        }
      })
      .catch((e: any) => {
        console.log("%c Line:33 🍊 e", "color:#42b983", e);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handelData();
  }, [page, startDate]);

  useEffect(() => {
    getOEM()
      .then((res) => {
        // console.log("%c Line:105 🥑 res", "color:#ed9ec7", res)
        if (res?.data) {
          const arr: any = [];

          res?.data?.data?.map((obj: any) => {
            arr.push({ label: obj?.name, value: obj?.code });
          });
          setOEM(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    getModel()
      .then((res) => {
        // console.log("%c Line:105 🥑 res", "color:#ed9ec7", res)
        if (res?.data) {
          const arr: any = [];

          res?.data?.data?.map((obj: any) => {
            arr.push({
              label: obj?.name,
              value: obj?.code,
              oem_id: obj?.oem_id,
            });
          });

          setModel(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getOemName = (
    oem: string,
    oemModel: string,
    oemList: OEMS[],
    modelList: Model[]
  ): string => {
    if (!oem || !oemModel) {
      return "";
    }

    const oemObj = oemList.find((obj) => obj.value === oem);
    const modelObj = modelList.find((obj) => obj.value === oemModel);

    const oemLabel = oemObj?.label || "";
    const modelLabel = modelObj?.label || "";

    return `${oemLabel} ${modelLabel}`.trim();
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };
  return (
    <div>
      <div className="sm-stepForm-banner">
        <SopHeader title={"Scheduler Dashboard"} link={stepSop[step]} />
      </div>
      <div className="sm-stepForm-wrap">
        <div className="sm-colm-12">
          <div className="sm-commen-box">
            <div className="graph_heading">
              <h4></h4>
              <div style={{ display: "flex", gap: "10px" }}>
                {" "}
                <div>
                  <DatePicker
                    selected={startDate}
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    onChange={(date: any) => setStartDate(date)}
                    placeholderText="Date"
                    className="startDate"
                  />
                </div>
                {/* <div>
                  <DatePicker
                    selected={endDate}
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    onChange={(date: any) => setEndDate(date)}
                    placeholderText="Start date"
                    className="startDate"
                  />
                </div> */}
              </div>
            </div>
            <div className="table-data">
              {
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Process</TableCell>
                        <TableCell align="right">OEM/Model</TableCell>
                        <TableCell align="right">Target</TableCell>
                        <TableCell align="right">Completed</TableCell>
                        <TableCell align="right">Workcell</TableCell>
                        <TableCell align="right">Shift</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableCell colSpan={7}>
                          <CircularProgress />
                        </TableCell>
                      ) : data && data?.length !== 0 ? (
                        data?.map((obj: any) => (
                          <TableRow
                            key={obj?._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="right">{obj?.date}</TableCell>

                            <TableCell align="right">{obj?.process}</TableCell>
                            <TableCell align="right">
                              {getOemName(obj?.oem, obj?.oemModel, OEM, model)}
                            </TableCell>
                            <TableCell align="right">{obj?.target}</TableCell>
                            <TableCell align="right">
                              <div
                                style={
                                  (obj?.target - obj?.archive) / obj?.target <=
                                  0
                                    ? {
                                        padding: "5px",
                                        background: "green",
                                        borderRadius: "20px",
                                        color: "white",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        width: "60%",
                                        marginInline: "auto",
                                      }
                                    : (obj?.target - obj?.archive) /
                                        obj?.target <=
                                      0.3
                                    ? {
                                        padding: "5px",
                                        background: "yellow",
                                        borderRadius: "20px",
                                        color: "white",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        width: "60%",
                                        marginInline: "auto",
                                      }
                                    : (obj?.target - obj?.archive) /
                                        obj?.target <=
                                      0.7
                                    ? {
                                        padding: "5px",
                                        background: "orange",
                                        borderRadius: "20px",
                                        color: "white",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        width: "60%",
                                        marginInline: "auto",
                                      }
                                    : (obj?.target - obj?.archive) /
                                        obj?.target <=
                                      1
                                    ? {
                                        padding: "5px",
                                        background: "red",
                                        borderRadius: "20px",
                                        color: "white",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        width: "60%",
                                        marginInline: "auto",
                                      }
                                    : {
                                        padding: "5px",
                                        background: "red",
                                        borderRadius: "20px",
                                        color: "white",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        width: "60%",
                                        marginInline: "auto",
                                      }
                                }
                              >
                                {obj?.archive}
                              </div>
                            </TableCell>
                            <TableCell align="right">{obj?.workcell}</TableCell>
                            <TableCell align="right">{obj?.shift}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableCell colSpan={7}>No Data Found</TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
              {paginationData &&
                // paginationData?.totalDocs &&
                paginationData?.totalDocs > 10 && (
                  <Pagination
                    pageCount={
                      paginationData?.totalPages ? paginationData?.totalDocs : 0
                    }
                    onPageChange={handlePageChange}
                  />
                )}
            </div>

            {/* <div
              className="graph_heading"
              style={{ position: "relative", marginBottom: "30px" }}
            >
              <h4>Work Cell 2</h4>
              <div style={{ position: "absolute", right: "20px" }}>
                <DatePicker
                  selected={startDate2}
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  onChange={(date: any) => setStartDate2(date)}
                  placeholderText="Start date"
                  className="startDate"
                />
              </div>
            </div>
            <div className="schedule_grid">
              <div className="grid_items grid_head "></div>
              <div className="grid_items grid_head ">Team</div>
              <div className="grid_items grid_head ">Workstation</div>
              <div className="grid_items grid_head ">OEM/Model</div>
              <div className="grid_items grid_head ">Target</div>

              <div className="grid_items ">Shift 1</div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items ">Shift 2</div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items ">Shift 3</div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
              <div className="grid_items "></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedular;
