import React from "react";

import Routes from "./routes";
import ScrollTop from "./components/ScrollTop";

function App() {
  return (
    <React.Fragment>
    <ScrollTop>
        <Routes />
      </ScrollTop>
    </React.Fragment>
  );
}

export default App;
