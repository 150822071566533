import React, { useEffect, useState } from "react";
import SopHeader from "../sopHeader";
import {
  Select as MuiSelect,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { FaRegEdit } from "react-icons/fa";
import Detail from "./Detail";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getBatteryData } from "../../../../service/apiService";
import Pagination from "./Pagination";
interface Data {
  docs?: any[];
  nextPage?: number;
  page?: number;
  pagingCounter?: number;
  prevPage?: number;
  totalDocs?: number;
  totalPages?: number;
}
const BatteryData = ({ step }: { step: string }) => {
  const [data, setData] = useState<Data | null>(null);
  const [popUpData, setPopUpData] = useState();
  const [loading, setLoading] = useState(false);

  const [HISData, setHISData] = useState<any>([
    { label: "ALL", value: "ALL" },
    { label: " HIS-1", value: "1" },
    { label: " HIS-2", value: "2" },
    { label: "HIS-3", value: "3" },
    { label: " HIS-4", value: "4" },
    { label: " HIS-5", value: "5" },
    { label: " HIS-6", value: "6" },
  ]);
  const [HIS, setHIS] = useState<any>("ALL");
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e: any) => {
    setHIS(e.target.value);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const getData = () => {
    const params: any = { page: page, sizePerPage: "10" };
    if (HIS !== "ALL") {
      params.completedPhase = HIS;
    }
    if (startDate) {
      params.startDate = moment(startDate).format("DD/MM/YYYY");
    }
    if (endDate) {
      params.endDate = moment(endDate).format("DD/MM/YYYY");
    }
    setLoading(true);
    getBatteryData(params)
      .then((res: any) => {
        console.log("%c Line:70 🍊 res", "color:#ea7e5c", res);
        if (res?.success && res?.data?.status) {
          setData(res?.data?.data);
        }
        setLoading(false);
      })
      .catch((e: any) => {
        console.log("%c Line:73 🥥 e", "color:#465975", e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [page, HIS, startDate, endDate]);

  return (
    <div>
      <div className="sm-stepForm-banner">
        <SopHeader title={"Battery Data"} link={""} />
      </div>
      <div className="sm-commen-box">
        <div className="graph_heading">
          {/* <h4>Battery</h4> */}
          <div className="graph_filter">
            <MuiSelect
              variant="outlined"
              value={HIS}
              onChange={handleChange}
              style={{ minWidth: "150px", height: "38px" }}
            >
              {HISData?.map((option: any) => {
                return (
                  <MenuItem value={option.value} key={option.label}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </MuiSelect>
          </div>{" "}
          <div className="graph_filter">
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              isClearable
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              placeholderText="Start date"
              className="startDate"
            />
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              isClearable
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              placeholderText="End date"
              className="startDate"
            />
          </div>
        </div>
        <div className="table-data">
          {
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Module Id</TableCell>
                    <TableCell align="right">Completed Phase</TableCell>
                    <TableCell align="right">OEM Serial Id</TableCell>
                    <TableCell align="right">View Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableCell colSpan={4}>
                      <CircularProgress />
                    </TableCell>
                  ) : data && data?.docs?.length !== 0 ? (
                    data?.docs?.map((obj: any) => (
                      <TableRow
                        key={obj?._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="obj">
                          {obj?.battery_module}
                        </TableCell>
                        <TableCell align="right">
                          {obj?.completedPhase}
                        </TableCell>
                        <TableCell align="right">{obj.oem_serial_id}</TableCell>
                        <TableCell align="right">
                          <FaRegEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpen(true);
                              setPopUpData(obj);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell colSpan={4}>No Data Found</TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {data && data?.totalDocs && data?.totalDocs > 10 && (
            <Pagination
              pageCount={data?.totalPages ?? 0}
              onPageChange={handlePageChange}
            />
          )}
          <Detail open={open} setOpen={handleClose} data={popUpData} />
        </div>
      </div>
    </div>
  );
};

export default BatteryData;
