import React, { useEffect, useState } from "react";
import SopHeader from "./StepForms/sopHeader";
import ReactApexChart from "react-apexcharts";
import Grid from "@mui/material/Grid";
// import axios from "axios"
import Select from "react-select";
import {
  getCount,
  getGraph1,
  getGraph2,
  getGraph3,
  getGraph4,
  getGraph5,
  getGraphLast,
  getModel,
  getOEM,
} from "../../service/apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Spiner from "../../components/Spiner";
import { Button, Tooltip, Typography } from "@mui/material";
import styled from "@emotion/styled";

const ChartContainer = styled.div`
  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    .apexcharts-xaxis-label,
    .apexcharts-yaxis-label {
      font-size: 10px;
    }
  }
`;
const KPI = () => {
  const [OEM, setOEM] = useState();
  const [model1, setModel1] = useState();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [model2, setModel2] = useState();
  const [model3, setModel3] = useState();
  const [model4, setModel4] = useState();
  const [model5, setModel5] = useState();
  const [model6, setModel6] = useState();
  const [timeArray, setTimeArray] = useState([]);
  const [OEMChart1, setOEMChart1] = useState({ label: "All OEM", value: "" });
  const [modelChart1, setModelChart1] = useState({
    label: "All Model",
    value: "",
    oem_id: "",
  });
  const [chart1Data, setChart1Data] = useState();

  const [OEMChart2, setOEMChart2] = useState({ label: "All OEM", value: "" });
  const [modelChart2, setModelChart2] = useState({
    label: "All Model",
    value: "",
    oem_id: "",
  });

  const [OEMChart3, setOEMChart3] = useState({ label: "All OEM", value: "" });
  const [modelChart3, setModelChart3] = useState({
    label: "All Model",
    value: "",
    oem_id: "",
  });
  const [OEMChart4, setOEMChart4] = useState({ label: "All OEM", value: "" });
  const [modelChart4, setModelChart4] = useState({
    label: "All Model",
    value: "",
    oem_id: "",
  });
  const [OEMChart5, setOEMChart5] = useState({ label: "Nissan", value: "" });
  const [modelChart5, setModelChart5] = useState({
    label: "Gen 4",
    value: "",
    oem_id: "",
  });
  const [graphModel, setGraphModel] = useState({
    label: "Gen 4",
    value: "",
    oem_id: "",
  });

  const [OEMChart6, setOEMChart6] = useState({ label: "All OEM", value: "" });
  const [modelChart6, setModelChart6] = useState({
    label: "All Model",
    value: "",
    oem_id: "",
  });
  const [countData, setCountData] = React.useState<any>([]);
  const [countLoading, setCountLoading] = React.useState<boolean>(false);
  // console.log("%c Line:58 🍿 countData", "color:#f5ce50", countData)
  const [chart5Data, setChart5Data] = useState<[]>([]);
  const [data, setData] = useState<[]>([]);
  // console.log("%c Line:27 🌭 data", "color:#ea7e5c", data)
  const [startDate1, setStartDate1] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");
  const [startDate3, setStartDate3] = useState("");
  const [endDate3, setEndDate3] = useState("");
  const [startDate5, setStartDate5] = useState("");
  const [endDate5, setEndDate5] = useState("");

  const handleDropdown = () => {
    getOEM()
      .then((res) => {
        // console.log("%c Line:105 🥑 res", "color:#ed9ec7", res)
        if (res?.data) {
          const arr: any = [{ label: "All OEM", value: "" }];
          // const arr1 = res?.data?.data?.map((obj: any) => ({
          //   label: obj?.name,
          //   value: obj?.code,
          // }))
          res?.data?.data?.map((obj: any) => {
            arr.push({ label: obj?.name, value: obj?.code });
          });
          setOEM(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // getModel()
    //   .then((res) => {
    //     // console.log("%c Line:105 🥑 res", "color:#ed9ec7", res)
    //     if (res?.data) {
    //       const arr: any = [{ label: "All Model", value: "", oem_id: "" }];

    //       res?.data?.data?.map((obj: any) => {
    //         arr.push({
    //           label: obj?.name,
    //           value: obj?.code,
    //           oem_id: obj?.oem_id,
    //         });
    //       });
    //       // const arr = res?.data?.data?.map((obj: any) => ({
    //       //   label: obj?.name,
    //       //   value: obj?.code,
    //       //   oem_id: obj?.oem_id,
    //       // }))
    //       setModel(arr);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });
  };

  useEffect(() => {
    getModel()
      .then((res) => {
        if (res?.data) {
          console.log("%c Line:132 🍎 res", "color:#ed9ec7", res);
          const arr: any = [{ label: "All Model", value: "", oem_id: "" }];

          // res?.data?.data?.map((obj: any) => {
          //   arr.push({
          //     label: obj?.name,
          //     value: obj?.code,
          //     oem_id: obj?.oem,
          //   });
          // });

          // setModel1(arr);
          // setModel2(arr);
          // setModel3(arr);
          // setModel4(arr);
          // setModel5(arr);
          // setModel6(arr);
          if (OEMChart1?.value) {
            const arr: any = [{ label: "All Model", value: "", oem_id: "" }];
            const filterData: any = res?.data?.data?.filter(
              (obj: any) => obj?.oem === OEMChart1?.value
            );
            filterData?.length > 0 &&
              filterData?.map((obj: any) => {
                arr.push({
                  label: obj?.name,
                  value: obj?.code,
                  oem_id: obj?.oem,
                });
              });
            setModel1(arr);
          }
          if (OEMChart2?.value) {
            const arr: any = [{ label: "All Model", value: "", oem_id: "" }];
            const filterData: any = res?.data?.data?.filter(
              (obj: any) => obj?.oem === OEMChart2?.value
            );
            filterData?.length > 0 &&
              filterData?.map((obj: any) => {
                arr.push({
                  label: obj?.name,
                  value: obj?.code,
                  oem_id: obj?.oem,
                });
              });
            setModel2(arr);
          }
          if (OEMChart3?.value) {
            const arr: any = [{ label: "All Model", value: "", oem_id: "" }];
            const filterData: any = res?.data?.data?.filter(
              (obj: any) => obj?.oem === OEMChart3?.value
            );
            filterData?.length > 0 &&
              filterData?.map((obj: any) => {
                arr.push({
                  label: obj?.name,
                  value: obj?.code,
                  oem_id: obj?.oem,
                });
              });
            setModel3(arr);
          }
          if (OEMChart4?.value) {
            const arr: any = [{ label: "All Model", value: "", oem_id: "" }];
            const filterData: any = res?.data?.data?.filter(
              (obj: any) => obj?.oem === OEMChart4?.value
            );
            filterData?.length > 0 &&
              filterData?.map((obj: any) => {
                arr.push({
                  label: obj?.name,
                  value: obj?.code,
                  oem_id: obj?.oem,
                });
              });
            setModel4(arr);
          }
          if (OEMChart5?.value) {
            const arr: any = [{ label: "All Model", value: "", oem_id: "" }];
            const filterData: any = res?.data?.data?.filter(
              (obj: any) => obj?.oem === OEMChart5?.value
            );
            filterData?.length > 0 &&
              filterData?.map((obj: any) => {
                arr.push({
                  label: obj?.name,
                  value: obj?.code,
                  oem_id: obj?.oem,
                });
              });
            setModel5(arr);
          }
          if (OEMChart6?.value) {
            const arr: any = [{ label: "All Model", value: "", oem_id: "" }];
            const filterData: any = res?.data?.data?.filter(
              (obj: any) => obj?.oem === OEMChart6?.value
            );
            filterData?.length > 0 &&
              filterData?.map((obj: any) => {
                arr.push({
                  label: obj?.name,
                  value: obj?.code,
                  oem_id: obj?.oem,
                });
              });
            setModel6(arr);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [OEMChart1, OEMChart2, OEMChart3, OEMChart4, OEMChart5, OEMChart6]);

  const handleGraph3 = ({ type }: { type: string }) => {
    // const oemValue = (OEMChart3 as any)?.value || ""
    // const modelValue = (modelChart3 as any)?.value || ""

    const params: any = {};
    if (type === "clear") {
      setOEMChart3({ label: "All OEM", value: "" });
      setModelChart3({
        label: "All Model",
        value: "",
        oem_id: "",
      });
      setStartDate3("");
      setEndDate3("");
    }
    if (type === "filter") {
      if (startDate3) {
        params.startDate = moment(startDate3).format("DD/MM/YYYY");
      }
      if (endDate3) {
        params.endDate = moment(endDate3).format("DD/MM/YYYY");
      }
      if (OEMChart3?.value) {
        params.oem = (OEMChart3 as any)?.value;
      }
      if (modelChart3?.value) {
        params.model = (modelChart3 as any)?.value;
      }
    }
    setLoading3(true);
    getGraph3(params)
      .then((res) => {
        console.log("%c Line:105 🥑 res", "color:#ed9ec7", res);
        if (res?.success && res?.data) {
          setChart1Data(res?.data?.data);

          setChart3({
            ...chart3,
            series: [
              {
                name: "Value",

                data: [
                  +res?.data?.data?.incomingBins,
                  +res?.data?.data?.inProgress,
                  +res?.data?.data?.binning,
                  +res?.data?.data?.modulePackaged,
                ] as any,
              },
            ],
          });
          setLoading3(false);
        } else {
          setChart3({
            ...chart3,
            series: [
              {
                name: "Value",
                data: [],
              },
            ],
          });
          setLoading3(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading3(false);
      });
  };
  const handleGraph1 = ({ type }: { type: string }) => {
    // const oemValue = (OEMChart3 as any)?.value || ""
    // const modelValue = (modelChart3 as any)?.value || ""

    const params: any = {};
    if (type === "clear") {
      setOEMChart1({ label: "All OEM", value: "" });
      setModelChart1({
        label: "All Model",
        value: "",
        oem_id: "",
      });
      setStartDate1("");
      setEndDate1("");
    }
    if (type === "filter") {
      if (startDate1) {
        params.startDate = moment(startDate1).format("DD/MM/YYYY");
      }
      if (endDate1) {
        params.endDate = moment(endDate1).format("DD/MM/YYYY");
      }
      if (OEMChart1?.value) {
        params.oem = (OEMChart1 as any)?.value;
      }
      if (modelChart1?.value) {
        params.model = (modelChart1 as any)?.value;
      }
    }
    setLoading1(true);
    getGraph1(params)
      .then((res) => {
        // console.log("%c Line:105 🥑 res", "color:#ed9ec7", res);
        if (res?.success && res?.data) {
          setChart1Data(res?.data?.data);
          setState({
            ...state,
            series: [
              {
                name: "Work cell 1",
                group: "workcell1",
                data: [
                  +res?.data?.data?.dailyPrep,
                  +res?.data?.data?.his1,
                  +res?.data?.data?.his2,
                  +res?.data?.data?.his3,
                  +res?.data?.data?.his4,
                  +res?.data?.data?.his5,
                  +res?.data?.data?.his6,
                  +res?.data?.data?.binning,
                  +res?.data?.data?.packaging,
                ] as any,
              },
              {
                name: "Rejected",
                group: "workcell1",
                data: [
                  null,
                  res?.data?.data?.his1Rejects
                    ? +res?.data?.data?.his1Rejects
                    : null,
                  res?.data?.data?.his2Rejects
                    ? +res?.data?.data?.his2Rejects
                    : null,
                  res?.data?.data?.his3Rejects
                    ? +res?.data?.data?.his3Rejects
                    : null,
                  res?.data?.data?.his4Rejects
                    ? +res?.data?.data?.his4Rejects
                    : null,
                  res?.data?.data?.his5Rejects
                    ? +res?.data?.data?.his5Rejects
                    : null,
                  res?.data?.data?.his6Rejects
                    ? +res?.data?.data?.his6Rejects
                    : null,
                  null,
                  null,
                ] as any,
              },

              {
                name: "Work cell 2",
                group: "workcell2",
                data: ["0", "0", "0", "0", "0", "0", "0", "0", "0"] as any,
              },
            ],
          });
          setLoading1(false);
        } else {
          setState({
            ...state,
            series: [
              {
                name: "Work cell 1",
                group: "workcell1",
                data: [],
              },
              {
                name: "Rejected",
                group: "workcell1",
                data: [],
              },
              {
                name: "Work cell 2",
                group: "workcell2",
                data: [],
              },
            ],
          });
          setLoading1(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading1(false);
      });
  };
  const handleGraph6 = () => {
    const params: any = {};

    if (OEMChart6?.value) {
      params.oem = (OEMChart6 as any)?.value;
    }
    if (modelChart6?.value) {
      params.model = (modelChart6 as any)?.value;
    }
    setCountLoading(true);
    getCount(params)
      .then((res) => {
        console.log("res:", res);
        if (res?.data) {
          setCountData(res?.data?.data);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setCountLoading(false));
  };
  const handleGraph4 = () => {
    const params: any = {};

    if (OEMChart4?.value) {
      params.oem = (OEMChart4 as any)?.value;
    }
    if (modelChart4?.value) {
      params.model = (modelChart4 as any)?.value;
    }

    getGraph4(params)
      .then((res) => {
        console.log("%c Line:105 🥑 res", "color:#ed9ec7", res);
        if (res?.success && res?.data) {
          const array: any = [];
          const today = new Date();
          for (let i = 29; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(date.getDate() - i);
            const dateString = date.toISOString().split("T")[0];

            const apiIndex = res?.data?.data
              ?.map((obj: any) => obj?.date)
              .indexOf(dateString);
            if (apiIndex !== -1) {
              array.push(res?.data?.data[apiIndex]);
            } else {
              array.push({ date: dateString, count: 0 });
            }
          }
          // console.log("%c Line:266 🍐 array", "color:#93c0a4", array)
          setChart4({
            ...chart4,
            series: [
              {
                name: "Work cell 1",
                data: array?.map((obj: any) => +obj?.count) as any,
              },
              {
                name: "Work cell 2",
                data: array?.map((obj: any) => "0") as any,
              },
            ],
            options: {
              ...chart4.options,
              xaxis: {
                labels: {
                  rotate: -45,
                },
                categories: array?.map((obj: any) => obj?.date) as [],
                tickPlacement: "on",
              },
            },
          });
        } else {
          setChart4({
            ...chart4,
            series: [
              {
                name: "",
                // type: "bar",
                data: [],
              },
              {
                name: "Work cell 2",
                // type: "bar",
                data: [],
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleGraph2 = ({ type }: { type: string }) => {
    const params: any = {
      // rejected: "true",
    };
    if (type === "clear") {
      setOEMChart2({ label: "All OEM", value: "" });
      setModelChart2({
        label: "All Model",
        value: "",
        oem_id: "",
      });
      setStartDate2("");
      setEndDate2("");
    }
    if (type === "filter") {
      if (startDate2) {
        params.startDate = moment(startDate2).format("DD/MM/YYYY");
      }
      if (endDate2) {
        params.endDate = moment(endDate2).format("DD/MM/YYYY");
      }
      if (OEMChart2?.value) {
        params.oem = (OEMChart2 as any)?.value;
      }
      if (modelChart2?.value) {
        params.model = (modelChart2 as any)?.value;
      }
    }
    setLoading2(true);
    getGraph2(params)
      .then((res) => {
        console.log("%c Line:105 🍆 res", "color:#42b983", res);
        if (res?.success && res?.data) {
          const arr = [
            { label: "PHY1", value: +res?.data?.data?.PHY1 },
            {
              label: "PHY2",
              value: +res?.data?.data?.PHY2,
            },
            {
              label: "PHY3",
              value: +res?.data?.data?.PHY3,
            },
            {
              label: "PHY4",
              value: +res?.data?.data?.PHY4,
            },
            {
              label: "ECL1",
              value: +res?.data?.data?.ECL1,
            },
            {
              label: "ECL2",
              value: +res?.data?.data?.ECL2,
            },
            {
              label: "ECL3",
              value: +res?.data?.data?.ECL3,
            },
            {
              label: "ELS1",
              value: +res?.data?.data?.ELS1,
            },
            {
              label: "ECH5",
              value: +res?.data?.data?.ECH5,
            },
            {
              label: "ECH6",
              value: +res?.data?.data?.ECH6,
            },
            {
              label: "ECH1",
              value: +res?.data?.data?.ECH1,
            },
            {
              label: "ECH2",
              value: +res?.data?.data?.ECH2,
            },
            {
              label: "ECH3",
              value: +res?.data?.data?.ECH3,
            },
            {
              label: "ECH4",
              value: +res?.data?.data?.ECH4,
            },
            // {
            //   label: " Tolerance ",
            //   value: +res?.data?.data?.tolerance,
            // },
            // {
            //   label: " Insulation Resistance ",
            //   value: +res?.data?.data?.insulationResistance,
            // },
          ];
          const total = arr.reduce(
            (acc: number, crr: any) => acc + crr.value,
            0
          );
          const sortedData = arr
            .slice()
            .sort((a: any, b: any) => b.value - a.value);
          const lineData = sortedData?.map(
            (obj: any) => +((obj?.value / total) * 100)?.toFixed(2)
          );
          const cumulativeTotal: number[] = [];

          lineData.reduce((acc: number, curr: any) => {
            const currentTotal = acc + curr;
            cumulativeTotal.push(currentTotal);

            // console.log("cumulativeTotal: ", cumulativeTotal);
            return currentTotal;
          }, 0);

          const sortedLabels = sortedData.map((item) => item.label);
          const sortedValues = sortedData.map((item) => +item.value);
          // console.log("sortedValues: ", sortedValues);
          setChart2({
            series: [
              {
                name: "Value",
                type: "bar",
                data: sortedValues as [],
              },
              {
                name: "Cumulative",
                type: "line",
                data: cumulativeTotal as [],
              },
            ],
            options: {
              ...chart2.options,
              xaxis: {
                labels: {
                  rotate: -40,
                  // rotateAlways: true,
                  style: {
                    fontSize: "12px",
                  },
                  tickAmount: 10,
                },
                categories: sortedLabels as [],
                tickPlacement: "on",
              },
            },
          });
          setLoading2(false);
        } else {
          setLoading2(false);
          setChart2({
            ...chart2,
            series: [
              {
                name: "Value",
                type: "bar",
                data: [],
              },

              {
                name: "Cumulative",
                type: "line",
                data: [],
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading2(false);
      });
  };
  const handleGraph5 = () => {
    const params: any = {};
    if (startDate5) {
      params.startDate = moment(startDate5).format("DD/MM/YYYY");
    }
    if (endDate5) {
      params.endDate = moment(endDate5).format("DD/MM/YYYY");
    }
    if (OEMChart5?.value) {
      params.oem = (OEMChart5 as any)?.value;
    }
    if (modelChart5?.value) {
      params.model = (modelChart5 as any)?.value;
    }

    getGraph5(params)
      .then((res) => {
        console.log("%c Line:85 🥟 res", "color:#3f7cff", res);
        if (res?.data) {
          setChart5Data(res?.data?.data);
          const filteredArray: [] = res?.data?.data.filter((obj: any) => {
            return (
              obj.dailyPrep !== "0" ||
              obj.his1 !== "0" ||
              obj.his2 !== "0" ||
              obj.his3 !== "0" ||
              obj.his4 !== "0" ||
              obj.his5 !== "0" ||
              obj.his6 !== "0" ||
              obj.packaging !== "0"
            );
          });
          console.log(
            "%c Line:288 🍧 filteredArray",
            "color:#fca650",
            filteredArray
          );
          const arr = filteredArray?.filter(
            (obj: any) => obj?.username !== "zeal" && obj?.username !== "admin"
          );
          setChart5({
            ...chart5,
            series: arr?.map((obj: any) => {
              return {
                name: obj?.username,
                data: [
                  obj?.dailyPrep,
                  obj?.his1,
                  obj?.his2,
                  obj?.his3,
                  obj?.his4,
                  obj?.his5,
                  obj?.his6,
                  obj?.packaging,
                ],
              };
            }),
          });
        } else {
          setChart5Data(res?.data?.data);
          setChart5({
            ...chart5,
            series: res?.data?.map((obj: any) => {
              return {
                name: obj?.username,
                data: [obj?.his1, obj?.his2, obj?.his3, obj?.his4],
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleGraphLast = ({ type }: { type: string }) => {
    const params: any = {};
    if (type === "clear") {
      setOEMChart5({ label: "Nissan", value: "" });
      setModelChart5({
        label: "Gen 4",
        value: "",
        oem_id: "",
      });

      setGraphModel(modelChart5);
      setStartDate5("");
      setEndDate5("");
    }
    if (type === "filter") {
      if (startDate5) {
        params.startDate = moment(startDate5).format("DD/MM/YYYY");
      }
      if (endDate5) {
        params.endDate = moment(endDate5).format("DD/MM/YYYY");
      }
      if (OEMChart5?.value) {
        params.oem = (OEMChart5 as any)?.value;
      }
      if (modelChart5?.value) {
        params.model = (modelChart5 as any)?.value;
      }
      setGraphModel(modelChart5);
    }
    setLoading5(true);
    getGraphLast(params)
      .then((res: any) => {
        // console.log("%c Line:785 🍇 res", "color:#4fff4B", res);
        if (res?.status) {
          const hgavi: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "hgavi");
          const operator: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "operator");
          const myohanna: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "myohanna");
          const hwadikar: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "hwadikar");
          const arr: any = [hgavi, operator, myohanna, hwadikar]?.filter(
            Boolean
          );

          if (arr.length > 0) {
            setTimeArray(arr);
          }
        }
      })
      .catch((e: any) => {
        console.log("%c Line:788 🥝 e", "color:#e41a6a", e);
      })
      .finally(() => setLoading5(false));
  };

  useEffect(() => {
    handleDropdown();
    handleGraph1({ type: "clear" });
    handleGraph2({ type: "clear" });
    handleGraph3({ type: "clear" });
    handleGraphLast({ type: "clear" });
  }, []);

  useEffect(() => {
    // handleGraph1();
    // handleGraph2();
    // handleGraph3();
    handleGraph4();
    handleGraph6();
  }, [OEMChart4, modelChart4, OEMChart6, modelChart6]);

  // useEffect(() => {
  //   handleGraph5();
  // }, [modelChart5, OEMChart5, startDate5, endDate5]);

  const [state, setState] = useState({
    series: [
      {
        name: "Work cell 1",
        group: "workcell1",
        data: data,
      },
      {
        name: "Rejected",
        group: "workcell1",
        data: data,
      },
      {
        name: "Work cell 2",
        group: "workcell2",
        data: data,
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
        stacked: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      graph: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Daily Prep",
          "HIS 1",
          "HIS 2",
          "HIS 3",
          "HIS 4",
          "HIS 5",
          "HIS 6",
          "Binning",
          "Module Packaging",
        ],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Number of Modules",
        },
      },
      colors: ["#008FFB", "#FF4560", "#00e396"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
      tooltip: {
        custom: function ({
          series,
          seriesIndex,
          dataPointIndex,
          w,
        }: {
          series: number[][];
          seriesIndex: number;
          dataPointIndex: number;
          w: {
            config: {
              series: Array<{ name: string; data: number[]; group?: string }>;
            };
            globals: {
              categoryLabels: string[];
            };
          };
        }) {
          const group = w.config.series[seriesIndex]?.group;
          const seriesInGroup = w.config.series.filter(
            (s) => s.group === group
          );

          const categoryLabel =
            w.globals.categoryLabels[dataPointIndex] || "No Label";

          const tooltipItems = seriesInGroup
            .map((s) => {
              const value = s.data[dataPointIndex];
              return (
                value && `<div><span>${s.name}: <b>${value}</b></span></div>`
              );
            })
            .filter(Boolean)
            .join("");

          const totalValue = seriesInGroup.reduce((total, s) => {
            const value = s.data[dataPointIndex];
            return total + (value || 0);
          }, 0);

          return `<div class='tooltip'>
               <h3><b>${categoryLabel}</b></h3>
               <ul>
                 <li>${tooltipItems}</li>
                 <li><span>Total: <b>${totalValue}</b></span></li>
               </ul>
             </div>`;
        },
        enabled: true,
      },
    },
  });
  const [chart4, setChart4] = useState({
    series: [
      {
        name: "Work cell 1",
        data: data,
      },
      {
        name: "Work cell 2",
        data: data,
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: {
          text: "Shift1, Shift2, Shift3",
        },
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Number of Modules",
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return (
            "<div class='tooltip'>" +
            "<h3><b>" +
            w.config.series[seriesIndex].name +
            "</b></h3>" +
            "<ul>" +
            "<li>Shift1 : " +
            "<b>" +
            data +
            "</b>" +
            "</li>" +
            "<li>Shift2 : " +
            "<b>" +
            0 +
            "</b>" +
            "</li>" +
            "<li>Shift3 : " +
            "<b>" +
            0 +
            "</b>" +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });

  // const [chart4, setChart4] = useState({
  //   series: [
  //     {
  //       name: "Work cell 1",
  //       type: "bar",
  //       data: [],
  //     },
  //     {
  //       name: "Work cell 2",
  //       type: "bar",
  //       data: [],
  //     },
  //   ],
  //   options: {
  //     annotations: {
  //       points: [
  //         {
  //           x: "Bananas",
  //           seriesIndex: 0,
  //           label: {
  //             borderColor: "#775DD0",
  //             offsetY: 0,
  //             style: {
  //               color: "#fff",
  //               background: "#775DD0",
  //             },
  //             text: "Bananas are good",
  //           },
  //         },
  //       ],
  //     },
  //     chart: {
  //       height: 350,
  //       type: "bar",
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 0,
  //         columnWidth: "50%",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       width: 2,
  //     },
  //     // title: {
  //     //   text: "Throughput",
  //     // },
  //     grid: {
  //       row: {
  //         colors: ["#fff", "#f2f2f2"],
  //       },
  //     },
  //     xaxis: {
  //       labels: {
  //         rotate: -45,
  //       },
  //       categories: [],
  //       tickPlacement: "on",
  //     },
  //     yaxis: {
  //       title: {
  //         text: "Number of Modules",
  //       },
  //     },
  //     fill: {
  //       type: "gradient",
  //       gradient: {
  //         shade: "light",
  //         type: "vertical",
  //         shadeIntensity: 0.25,
  //         gradientToColors: undefined,
  //         inverseColors: false,
  //         opacityFrom: 0.85,
  //         opacityTo: 0.85,
  //         // stops: [50, 0, 100],
  //       },
  //     },
  //   },
  // })
  const defectsName = (e: any) => {
    if (e === "PHY1") {
      return "Bloated Module(PHY1)";
    } else if (e === "PHY2") {
      return "Physical Damage of casing(PHY2)";
    } else if (e === "PHY3") {
      return "Physical Damage of Terminal(PHY3)";
    } else if (e === "PHY4") {
      return "Corroded Terminals(PHY4)";
    } else if (e === "ECL1") {
      return "Low OCV (ECL1)";
    } else if (e === "ECL2") {
      return "High ACIR (ECL2)";
    } else if (e === "ECL3") {
      return "Continous Both Ways (ECL3)";
    } else if (e === "ELS1") {
      return "Improper insulation (ELS1)";
    } else if (e === "ECH5") {
      return "Interrupted HPPC Test (ECH5)";
    } else if (e === "ECH6") {
      return "Bad EIS sweep (ECH6)";
    } else if (e === "ECH1") {
      return "Low SoH (ECH1)";
    } else if (e === "ECH2") {
      return "High ΔSoH for Linked Modules(ECH2)";
    } else if (e === "ECH3") {
      return "Interrupted Test:Over Heating(ECH3)";
    } else if (e === "ECH4") {
      return "Interrupted Test:Under Control Temperature(ECH4)";
    }
  };

  const [chart2, setChart2] = useState({
    series: [
      {
        name: "Value",
        type: "bar",
        data: [],
      },
      {
        name: "Cumulative",
        type: "line",
        data: [],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },

      chart: {
        height: 400,
        type: "bar",
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -50,
          // rotateAlways: true,
          style: {
            fontSize: "12px",
          },
          tickAmount: 10,
        },
        categories: [
          "Bloated Module (PHY1)",
          "Physical Damage of casing  (PHY2)",
          "Physical Damage of Terminal  (PHY3)",
          "Corroded Terminals (PHY4)",
          "Low OCV (ECL1)",
          "High ACIR (ECL2)",
          "Continuity (ECL3)",
          "Module Casing Shorted (ELS1)",
          "Low SoH (ECH1)",
          "High ΔSoH (ECH2)",
          "Continous Both Ways (ELC3)",
          // "Tolerance",
          "Insulation Resistance",
        ],
        tickPlacement: "on",
      },
      yaxis: [
        {
          title: {
            text: "Number of Modules",
          },
          labels: {
            formatter: (value: number) => {
              return Number.isInteger(value) ? value : "";
            },
          },
        },
        {
          opposite: true,
          max: 100,
          min: 0,
          labels: {
            formatter: (value: any) => `${value.toFixed(0)}%`,
          },
          title: {
            text: "Cumulative percentage",
          },
        },
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            "<div class='tooltip'>" +
            "<h3 >" +
            "<b>" +
            defectsName(w.globals.categoryLabels[dataPointIndex]) +
            "</b>" +
            "</h3>" +
            "<ul>" +
            "<li>Value : " +
            "<b>" +
            data +
            "</b>" +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  // console.log("state: ", state)

  // const [chart4, setChart4] = useState({
  //   series: [
  //     {
  //       name: "series1",
  //       data: [31, 40, 28, 51, 42, 109, 100],
  //     },
  //     {
  //       name: "series2",
  //       data: [11, 32, 45, 32, 34, 52, 41],
  //     },
  //   ],
  //   options: {
  //     // title: {
  //     //   text: "Rejects",
  //     // },
  //     chart: {
  //       height: 350,
  //       type: "area",
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: "smooth",
  //     },
  //     xaxis: {
  //       type: "datetime",
  //       categories: [
  //         "2018-09-19T00:00:00.000Z",
  //         "2018-09-19T01:30:00.000Z",
  //         "2018-09-19T02:30:00.000Z",
  //         "2018-09-19T03:30:00.000Z",
  //         "2018-09-19T04:30:00.000Z",
  //         "2018-09-19T05:30:00.000Z",
  //         "2018-09-19T06:30:00.000Z",
  //       ],
  //     },
  //     tooltip: {
  //       x: {
  //         format: "dd/MM/yy HH:mm",
  //       },
  //     },
  //   },
  // })

  const [chart3, setChart3] = useState({
    series: [
      {
        name: "Value",
        data: [],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Incoming Bins",
          "In-Progress",
          "Outgoing Count",
          "Modules Packaged",
        ],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Modules",
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return (
            "<div class='tooltip'>" +
            "<h3 >" +
            "<b>" +
            w.globals.categoryLabels[dataPointIndex] +
            "</b>" +
            "</h3>" +
            "<ul>" +
            "<li>Value : " +
            "<b>" +
            data +
            "</b>" +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });

  const [chart5, setChart5] = useState({
    series: chart5Data?.map((obj: any) => {
      return {
        name: obj?.username,
        data: [obj?.his1, obj?.his2, obj?.his3, obj?.his4],
      };
    }),
    options: {
      chart: {
        type: "bar",
        height: 350,
        width: "100%",
        stacked: true,
        dropShadow: {
          enabled: true,
          blur: 1,
          opacity: 0.25,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
        },
        heatmap: {
          colorScale: {
            ranges: [
              {
                from: 1,
                to: 5,
                color: "#8B0000",
                // name: 'low',
                show: false,
              },
              {
                from: 6,
                to: 10,
                color: "#FF0000",
                // name: 'low',
                show: false,
              },
              {
                from: 11,
                to: 20,
                color: "#FF8C00",
                // name: 'low',
                show: false,
              },
              {
                from: 21,
                to: 80,
                color: "#FFA500",
                // name: 'low',
                show: false,
              },
              {
                from: 81,
                to: 200,
                color: "#FFD700",
                // name: 'low',
                show: false,
              },
              {
                from: 201,
                to: 500,
                color: "#FFFF00",
                // name: 'medium',
                show: false,
              },
              {
                from: 501,
                to: 1000,
                color: "#7FFF00",
                // name: 'medium',
                show: false,
              },
              {
                from: 1001,
                to: 1500,
                color: "#32CD32",
                // name: 'high',
                show: false,
              },
              {
                from: 1501,
                to: 1000000,
                color: "#00FF00",
                // name: 'high',
                show: false,
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },

      // title: {
      //   text: "Performance Matrix",
      // },
      xaxis: {
        categories: [
          "Daily Prep",
          "HIS 1",
          "HIS 2",
          "HIS 3",
          "HIS 4",
          "HIS 5",
          "HIS 6",
          "Module Packaging",
        ],
      },
      yaxis: {
        title: {
          text: "Operators",
          cssClass: "operator_title",
        },
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
      fill: {
        type: "pattern",
        opacity: 1,
        pattern: {
          style: [
            "circles",
            "slantedLines",
            "verticalLines",
            "horizontalLines",
          ],
        },
      },
      states: {
        hover: {
          filter: "none",
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
    },
  });
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"KPI Dashboard"} link={""} />
        </div>
        <ChartContainer>
          <div className="sm-stepForm-wrap">
            <div className="sm-colm-12">
              <div className="sm-commen-box common common-kpi">
                <div className="graph_heading">
                  <h4>Facility Contribution</h4>
                  <div className="graph_filter">
                    {/* <Select
                    value={OEMChart6}
                    options={OEM}
                    className={` react-select `}
                    classNamePrefix="OEM"
                    placeholder="OEM"
                    isClearable
                    onChange={(e: any) => {
                      setOEMChart6(e)
                    }}
                  />
                  <Select
                    value={modelChart6}
                    options={model}
                    className={` react-select `}
                    classNamePrefix="OEM"
                    placeholder="Model"
                    isClearable
                    onChange={(e: any) => {
                      setModelChart6(e)
                    }}
                  /> */}
                  </div>
                </div>

                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item p={0}>
                    <div
                      className="graph_heading"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="graph_header">
                        <div className="facility_graph">
                          <div
                            className="item"
                            style={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            Daily
                          </div>{" "}
                          <div
                            className="container-graph"
                            style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
                          >
                            <div className="item"></div>
                            <div className="item">Actual</div>
                            <div className="item">Target</div>
                            <div className="item">Delta</div>

                            <div className="item">
                              <div className="sub-item">Work Cell 1</div>
                              <div className="sub-item">Work Cell 2</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && +countData[0]?.count > 20
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countLoading
                                  ? 0
                                  : countData
                                  ? countData[0]?.count
                                  : 0}
                              </div>
                              <div className="sub-item green"> 0</div>
                            </div>
                            <div className="item">
                              <div className="sub-item">20</div>
                              <div className="sub-item">0</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && countData[0]?.count - 20 >= 0
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countData && countData[0]?.count - 20}
                              </div>
                              <div
                                className={`sub-item ${
                                  countData && countData[0]?.count - 0 >= 0
                                    ? "green"
                                    : "green"
                                }`}
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="facility_graph">
                          <div
                            className="item"
                            style={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            Weekly
                          </div>
                          <div className="container-graph">
                            <div className="item  hide-tab"></div>
                            <div className="item">Actual</div>
                            <div className="item">Target</div>
                            <div className="item">Delta</div>

                            <div className="item hide-tab">
                              <div className="sub-item">Work Cell 1</div>
                              <div className="sub-item">Work Cell 2</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && +countData[1]?.count > 100
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countLoading
                                  ? 0
                                  : countData
                                  ? countData[1]?.count
                                  : 0}
                              </div>
                              <div className="sub-item green"> 0</div>
                            </div>
                            <div className="item">
                              <div className="sub-item">100</div>
                              <div className="sub-item">0</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && countData[1]?.count - 100 >= 0
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countData && countData[1]?.count - 100}
                              </div>
                              <div
                                className={`sub-item ${
                                  countData && countData[0]?.count - 0 >= 0
                                    ? "green"
                                    : "green"
                                }`}
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="facility_graph">
                          <div
                            className="item"
                            style={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            Monthly
                          </div>
                          <div className="container-graph">
                            <div className="item  hide-tab"></div>
                            <div className="item">Actual</div>
                            <div className="item">Target</div>
                            <div className="item">Delta</div>
                            <div className="item hide-tab">
                              <div className="sub-item">Work Cell 1</div>
                              <div className="sub-item">Work Cell 2</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && +countData[2]?.count > 400
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countLoading
                                  ? 0
                                  : countData
                                  ? countData[2]?.count
                                  : 0}
                              </div>
                              <div className="sub-item green"> 0</div>
                            </div>
                            <div className="item">
                              <div className="sub-item">400</div>
                              <div className="sub-item">0</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && countData[2]?.count - 400 >= 0
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countData && countData[2]?.count - 400}
                              </div>
                              <div
                                className={`sub-item
                               ${
                                 countData && countData[0]?.count - 0 >= 0
                                   ? "green"
                                   : "green"
                               } 
                              `}
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="container-graph gap-8">
                        <div
                          className="item"
                          style={{ fontWeight: "600", fontSize: "22px" }}
                        >
                          Daily
                        </div>
                        <div
                          className="item"
                          style={{ fontWeight: "600", fontSize: "22px" }}
                        >
                          Weekly
                        </div>
                        <div
                          className="item"
                          style={{ fontWeight: "600", fontSize: "22px" }}
                        >
                          Monthly
                        </div>
                        <div className="container-graph">
                          <div className="item">Actual</div>
                          <div className="item">Target</div>
                          <div className="item">Delta</div>

                          <div className="item">
                            <div
                              className={`sub-item ${
                                countData && +countData[0]?.count > 20
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              Work Cell 1: {countData && countData[0]?.count}
                            </div>
                            <div className="sub-item green">Work Cell 2: 0</div>
                          </div>
                          <div className="item">
                            <div className="sub-item">20</div>
                            <div className="sub-item">0</div>
                          </div>
                          <div className="item">
                            <div
                              className={`sub-item ${
                                countData && countData[0]?.count - 20 >= 0
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              {countData && countData[0]?.count - 20}
                            </div>
                            <div
                              className={`sub-item ${
                                countData && countData[0]?.count - 0 >= 0
                                  ? "green"
                                  : "green"
                              }`}
                            >
                              0
                            </div>
                          </div>
                        </div>
                        <div className="container-graph">
                          <div className="item">Actual</div>
                          <div className="item">Target</div>
                          <div className="item">Delta</div>

                          <div className="item">
                            <div
                              className={`sub-item ${
                                countData && +countData[1]?.count > 100
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              Work Cell 1: {countData && countData[1]?.count}
                            </div>
                            <div className="sub-item green">Work Cell 2: 0</div>
                          </div>
                          <div className="item">
                            <div className="sub-item">100</div>
                            <div className="sub-item">0</div>
                          </div>
                          <div className="item">
                            <div
                              className={`sub-item ${
                                countData && countData[1]?.count - 100 >= 0
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              {countData && countData[1]?.count - 100}
                            </div>
                            <div
                              className={`sub-item ${
                                countData && countData[0]?.count - 0 >= 0
                                  ? "green"
                                  : "green"
                              }`}
                            >
                              0
                            </div>
                          </div>
                        </div>
                        <div className="container-graph">
                          {" "}
                          <div className="item">Actual</div>
                          <div className="item">Target</div>
                          <div className="item">Delta</div>
                          <div className="item">
                            <div
                              className={`sub-item ${
                                countData && +countData[2]?.count > 400
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              Work Cell 1: {countData && countData[2]?.count}
                            </div>
                            <div className="sub-item green">Work Cell 2: 0</div>
                          </div>
                          <div className="item">
                            <div className="sub-item">400</div>
                            <div className="sub-item">0</div>
                          </div>
                          <div className="item">
                            <div
                              className={`sub-item ${
                                countData && countData[2]?.count - 400 >= 0
                                  ? "green"
                                  : "red"
                              }`}
                            >
                              {countData && countData[2]?.count - 400}
                            </div>
                            <div
                              className={`sub-item
                               ${
                                 countData && countData[0]?.count - 0 >= 0
                                   ? "green"
                                   : "green"
                               } 
                              `}
                            >
                              0
                            </div>
                          </div>
                        </div>
                      </div> */}
                        {/* <div className="graph_grid">
                        <p>{countData && countData[0]?.time_interval}</p>
                        <p>{countData && countData[0]?.count}</p>
                      </div>
                      <div className="graph_grid">
                        <p>{countData && countData[1]?.time_interval}</p>
                        <p>{countData && countData[1]?.count}</p>
                      </div>
                      <div className="graph_grid">
                        <p>{countData && countData[2]?.time_interval}</p>
                        <p>{countData && countData[2]?.count}</p>
                      </div> */}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>{" "}
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Monthly Facility Contribution</h4>
                  <div className="graph_filter">
                    {/* <Select
                    value={OEMChart4}
                    options={OEM}
                    className={` react-select `}
                    classNamePrefix="OEM"
                    placeholder="OEM"
                    isClearable
                    onChange={(e: any) => {
                      setOEMChart4(e)
                    }}
                  />
                  <Select
                    value={modelChart4}
                    options={model}
                    className={` react-select `}
                    classNamePrefix="OEM"
                    placeholder="Model"
                    isClearable
                    onChange={(e: any) => {
                      setModelChart4(e)
                    }}
                  /> */}
                  </div>
                </div>
                <ReactApexChart
                  options={chart4.options as any}
                  series={chart4.series as any}
                  type="bar"
                  height={350}
                />
              </div>
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Workstation Throughput</h4>
                  {/* <div className="graph_filter">
                  <DatePicker
                    selected={startDate1}
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    onChange={(date: any) => setStartDate1(date)}
                    placeholderText="Start date"
                    className="startDate"
                  />
                  <DatePicker
                    selected={endDate1}
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    onChange={(date: any) => setEndDate1(date)}
                    placeholderText="End date"
                    className="startDate"
                  />
                </div> */}
                </div>
                <Grid container spacing={3} flexDirection={"column"}>
                  <Grid xs={12} item width={"100%"} maxWidth={"100%"}>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate1}
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          onChange={(date: any) => setStartDate1(date)}
                          placeholderText="Start date"
                          className="startDate"
                        />
                        <DatePicker
                          selected={endDate1}
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          onChange={(date: any) => setEndDate1(date)}
                          placeholderText="End date"
                          className="startDate"
                        />
                      </div>
                      <div className="graph_filter">
                        <Select
                          value={OEMChart1}
                          options={OEM}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          placeholder="OEM"
                          isClearable
                          onChange={(e: any) => {
                            setOEMChart1(e);
                          }}
                        />
                        <Select
                          value={modelChart1}
                          options={model1}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          placeholder="Model"
                          isClearable
                          onChange={(e: any) => {
                            setModelChart1(e);
                          }}
                        />
                      </div>
                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph1({ type: "filter" })}
                          disabled={loading1}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph1({ type: "clear" })}
                          disabled={loading1}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading1 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={state.options as any}
                        series={state.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                  {/* <Grid xs={12} item width={"100%"} maxWidth={"100%"}>
                  <div className="graph_heading">
                    <h4>Defects</h4>
                    <div className="graph_filter">
                      <Select
                        value={OEMChart2}
                        options={OEM}
                        className={` react-select `}
                        classNamePrefix="OEM"
                        isClearable
                        placeholder="OEM"
                        onChange={(e: any) => {
                          setOEMChart2(e)
                        }}
                      />
                      <Select
                        value={modelChart2}
                        options={model}
                        className={` react-select `}
                        isClearable
                        classNamePrefix="OEM"
                        placeholder="Model"
                        onChange={(e: any) => {
                          setModelChart2(e)
                        }}
                      />
                    </div>
                  </div>
                  <ReactApexChart
                    options={chart2.options as any}
                    series={chart2.series as any}
                    type="bar"
                    height={350}
                  />
                </Grid> */}
                </Grid>
              </div>
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Defects</h4>
                </div>
                <Grid container spacing={3} flexDirection={"column"}>
                  {/* <Grid xs={12} item width={"100%"} maxWidth={"100%"}>
                  <div className="graph_heading">
                    <h4></h4>
                    <div className="graph_filter">
                      <Select
                        value={OEMChart1}
                        options={OEM}
                        className={` react-select `}
                        classNamePrefix="OEM"
                        placeholder="OEM"
                        isClearable
                        onChange={(e: any) => {
                          setOEMChart1(e)
                        }}
                      />
                      <Select
                        value={modelChart1}
                        options={model}
                        className={` react-select `}
                        classNamePrefix="OEM"
                        placeholder="Model"
                        isClearable
                        onChange={(e: any) => {
                          setModelChart1(e)
                        }}
                      />
                    </div>
                  </div>
                  <ReactApexChart
                    options={state.options as any}
                    series={state.series as any}
                    type="bar"
                    height={350}
                  />
                </Grid> */}
                  <Grid
                    sm={12}
                    md={12}
                    xs={12}
                    item
                    // width={"100%"}
                    // maxWidth={"100%"}
                    // style={{ paddingLeft: "8px" }}
                  >
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate2}
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          onChange={(date: any) => setStartDate2(date)}
                          placeholderText="Start date"
                          className="startDate"
                        />
                        <DatePicker
                          selected={endDate2}
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          onChange={(date: any) => setEndDate2(date)}
                          placeholderText="End date"
                          className="startDate"
                        />
                      </div>
                      <div className="graph_filter">
                        <Select
                          value={OEMChart2}
                          options={OEM}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          isClearable
                          placeholder="OEM"
                          onChange={(e: any) => {
                            setOEMChart2(e);
                          }}
                        />
                        <Select
                          value={modelChart2}
                          options={model2}
                          className={` react-select `}
                          isClearable
                          classNamePrefix="OEM"
                          placeholder="Model"
                          onChange={(e: any) => {
                            setModelChart2(e);
                          }}
                        />
                      </div>
                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph2({ type: "filter" })}
                          disabled={loading2}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph2({ type: "clear" })}
                          disabled={loading2}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading2 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={chart2.options as any}
                        series={chart2.series as any}
                        type="bar"
                        height={400}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Inventory Snapshot</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          selected={startDate3}
                          onChange={(date: any) => setStartDate3(date)}
                          placeholderText="Start date"
                          className="startDate"
                        />
                        <DatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          selected={endDate3}
                          onChange={(date: any) => setEndDate3(date)}
                          placeholderText="End date"
                          className="startDate"
                        />
                      </div>
                      <div className="graph_filter">
                        <Select
                          value={OEMChart3}
                          options={OEM}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          placeholder="OEM"
                          isClearable
                          onChange={(e: any) => {
                            setOEMChart3(e);
                          }}
                        />
                        <Select
                          value={modelChart3}
                          options={model3}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          placeholder="Model"
                          isClearable
                          onChange={(e: any) => {
                            setModelChart3(e);
                          }}
                        />
                      </div>
                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph3({ type: "filter" })}
                          disabled={loading3}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph3({ type: "clear" })}
                          disabled={loading3}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading3 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={chart3.options as any}
                        series={chart3.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                  {/* <Grid sm={6} md={6} xs={12} item>
                  <div className="graph_heading">
                    <h4>Defects</h4>
                    <Select
                      value={OEMChart2}
                      options={OEM}
                      className={` react-select `}
                      classNamePrefix="OEM"
                      placeholder="OEM"
                      onChange={(e: any) => {
                        setOEMChart2(e)
                      }}
                    />
                    <Select
                      value={modelChart2}
                      options={model}
                      className={` react-select `}
                      classNamePrefix="OEM"
                      placeholder="Model"
                      onChange={(e: any) => {
                        setModelChart2(e)
                      }}
                    />
                  </div>
                  <ReactApexChart
                    options={chart4.options as any}
                    series={chart4.series}
                    type="area"
                    height={350}
                  />
                </Grid> */}
                </Grid>
              </div>
              <div className="sm-commen-box common common-kpi">
                <div className="graph_heading">
                  <h4>Throughput Contribution</h4>
                  {/* <div className="graph_filter">
                  <Select
                    value={OEMChart5}
                    options={OEM}
                    className={` react-select `}
                    classNamePrefix="OEM"
                    placeholder="OEM"
                    isClearable
                    onChange={(e: any) => {
                      setOEMChart5(e);
                    }}
                  />
                  <Select
                    value={modelChart5}
                    options={model5}
                    className={` react-select `}
                    classNamePrefix="OEM"
                    placeholder="Model"
                    isClearable
                    onChange={(e: any) => {
                      setModelChart5(e);
                    }}
                  />
                </div> */}
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          selected={startDate5}
                          onChange={(date: any) => setStartDate5(date)}
                          placeholderText="Start date"
                          className="startDate"
                        />
                        <DatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          isClearable
                          selected={endDate5}
                          onChange={(date: any) => setEndDate5(date)}
                          placeholderText="End date"
                          className="startDate"
                        />
                      </div>
                      <div className="graph_filter">
                        <Select
                          value={OEMChart5}
                          options={OEM}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          placeholder="OEM"
                          isClearable
                          onChange={(e: any) => {
                            setOEMChart5(e);
                          }}
                        />
                        <Select
                          value={modelChart5}
                          options={model5}
                          className={` react-select `}
                          classNamePrefix="OEM"
                          placeholder="Model"
                          isClearable
                          onChange={(e: any) => {
                            setModelChart5(e);
                          }}
                        />
                      </div>
                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraphLast({ type: "filter" })}
                          disabled={loading5}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraphLast({ type: "clear" })}
                          disabled={loading5}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={12} md={13} xs={12} item>
                    {/* <div className="graph_heading">
                    <h4></h4>
                    <div className="graph_filter">
                      <Select
                        value={OEMChart5}
                        options={OEM}
                        className={` react-select `}
                        classNamePrefix="OEM"
                        placeholder="OEM"
                        isClearable
                        onChange={(e: any) => {
                          setOEMChart5(e)
                        }}
                      />
                      <Select
                        value={modelChart5}
                        options={model}
                        className={` react-select `}
                        classNamePrefix="OEM"
                        placeholder="Model"
                        isClearable
                        onChange={(e: any) => {
                          setModelChart5(e)
                        }}
                      />
                    </div>
                  </div> */}
                    {/* <div> */}
                    {/* <ReactApexChart
                      options={chart5.options as any}
                      series={chart5.series as any}
                      type="heatmap"
                      height={350}
                    /> */}
                    <div className="graph-container">
                      <div className="graph-item">Target</div>
                      <div className="graph-item">3:45 Min</div>
                      {graphModel?.value !== "AAB" ? (
                        <div className="graph-item">3:15 Min</div>
                      ) : (
                        <div className="graph-item">1:45 Min</div>
                      )}
                      {graphModel?.value === "AAB" ? (
                        <div className="graph-item">2:00 Min</div>
                      ) : (
                        <div className="graph-item">3:15 Min</div>
                      )}
                      <div className="graph-item">180:00 Min</div>
                      <div className="graph-item">10:00 Min</div>
                      <div className="graph-item">1080:00 Min</div>

                      {timeArray &&
                        timeArray?.length > 0 &&
                        timeArray?.map((obj: any) => {
                          return (
                            <>
                              <div
                                className="graph-item"
                                style={{ textTransform: "capitalize" }}
                              >
                                {obj?.name}
                              </div>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">HIS 1</div>
                                    <div className="tooltip-value">{`Module id: ${obj?.moduleCountsHis1}`}</div>
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.his1minutes - 3.45 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.his1minutes
                                    ? `${(obj?.his1minutes - 3.45).toFixed(
                                        2
                                      )} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">HIS 2</div>
                                    <div className="tooltip-value">{`Module id: ${obj?.moduleCountsHis2}`}</div>
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    graphModel?.value !== "AAB"
                                      ? obj?.his2minutes - 3.15 > 0
                                        ? "red"
                                        : "green"
                                      : obj?.his2minutes - 1.45 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.his2minutes
                                    ? graphModel?.value !== "AAB"
                                      ? `${(obj?.his2minutes - 3.15).toFixed(
                                          2
                                        )} Min`
                                      : `${(obj?.his2minutes - 1.45).toFixed(
                                          2
                                        )}\u00A0Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">HIS 3</div>
                                    <div className="tooltip-value">{`Module id: ${obj?.moduleCountsHis3}`}</div>
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    graphModel?.value === "AAB"
                                      ? obj?.his3minutes - 2.0 > 0
                                        ? "red"
                                        : "green"
                                      : obj?.his3minutes - 3.15 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.his3minutes
                                    ? `${
                                        graphModel?.value === "AAB"
                                          ? (obj?.his3minutes - 2.0).toFixed(2)
                                          : (obj?.his3minutes - 3.15).toFixed(2)
                                      } Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">HIS 4</div>
                                    <div className="tooltip-value">{`Module id: ${obj?.moduleCountsHis4}`}</div>
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.his4minutes - 180 > 0 ? "red" : "green"
                                  } `}
                                >
                                  {obj?.his4minutes
                                    ? `${(obj?.his4minutes - 180).toFixed(
                                        2
                                      )} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">HIS 5</div>
                                    <div className="tooltip-value">{`Module id: ${obj?.moduleCountsHis5}`}</div>
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.his5minutes - 10 > 0 ? "red" : "green"
                                  } `}
                                >
                                  {obj?.his5minutes
                                    ? `${(obj?.his5minutes - 10).toFixed(
                                        2
                                      )} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">HIS 6</div>
                                    <div className="tooltip-value">{`Module id: ${obj?.moduleCountsHis6}`}</div>
                                    {obj?.averageSoh && (
                                      <div className="tooltip-value">{`Ave. SOH: ${obj?.averageSoh.toFixed(
                                        2
                                      )}`}</div>
                                    )}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.his6minutes - 1080 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.his6minutes
                                    ? `${(obj?.his6minutes - 1080).toFixed(
                                        2
                                      )} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                            </>
                          );
                        })}

                      {/* <div className="graph-item">hwadikar</div>
                    <div className="graph-item red">+1 Min</div>
                    <div className="graph-item red">+2 Min</div>
                    <div className="graph-item green">-2 Min</div>
                    <div className="graph-item green">-1 Hour</div>
                    <div className="graph-item red">+3 Min</div>
                    <div className="graph-item green">-1 Hour</div>

                    <div className="graph-item">gavi</div>
                    <div className="graph-item green">-1 Min</div>
                    <div className="graph-item red">+2 Min</div>
                    <div className="graph-item red">+1 Min</div>
                    <div className="graph-item green">-1 Hour</div>
                    <div className="graph-item green">-1 Min</div>
                    <div className="graph-item red">+3 Min</div>

                    <div className="graph-item">operator</div>
                    <div className="graph-item green">-5 Sec</div>
                    <div className="graph-item red">+90 Sec</div>
                    <div className="graph-item green">-30 Sec</div>
                    <div className="graph-item red">+3 Min</div>
                    <div className="graph-item green">-1 Min</div>
                    <div className="graph-item green">-1 Hour</div>

                    <div className="graph-item">myohanna</div>
                    <div className="graph-item green">-1 Min</div>
                    <div className="graph-item green">-30 Sec</div>
                    <div className="graph-item green">-2 Min</div>
                    <div className="graph-item green">-1 Hour</div>
                    <div className="graph-item green">-45 Sec</div>
                    <div className="graph-item green">-1 Hour</div> */}

                      <div className="graph-item"></div>
                      <div className="graph-item ">HIS 1</div>
                      <div className="graph-item ">HIS 2</div>
                      <div className="graph-item ">HIS 3</div>
                      <div className="graph-item ">HIS 4</div>
                      <div className="graph-item ">HIS 5</div>
                      <div className="graph-item ">HIS 6</div>
                    </div>
                    {/* </div> */}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </ChartContainer>
      </div>
    </React.Fragment>
  );
};
export default KPI;
