// src/store/index.tsx
import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "../reducers/sidebarReducer";
import inspectionReducer from "../reducers/inspectionReducer";
import userReducer from "../reducers/authReducer";

export const store = configureStore({
  reducer: {
    sideData: sidebarReducer,
    inspection: inspectionReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
