import {Typography} from "@mui/material"
import React from "react"

function SopHeader({title, link}: {title: string; link: string}) {
  return (
    <div className="sm-stepform-title">
      <Typography variant="h2">{title}</Typography>
      {link && (
        <Typography
          variant="h5"
          onClick={() => window.open(link, "_blank")}
          style={{cursor: "pointer"}}>
          SOP
        </Typography>
      )}
    </div>
  )
}

export default SopHeader
