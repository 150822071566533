import {useNavigate, useRoutes} from "react-router-dom"

import LoginRoutes from "./LoginRoutes"
import MainRoutes from "./MainRoutes"
import {useEffect} from "react"
import PageNotFound from "../components/PageNotFound"
import Passport from "./Passport"

export default function ThemeRoutes() {
  const routes = useRoutes([
    MainRoutes,
    LoginRoutes,
    Passport,
    {
      path: "*",
      element: <PageNotFound />,
    },
  ])
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.pathname === "/" && window.location.hash ==="") {
      navigate("/login")
    }
  }, [])

  return routes
}
