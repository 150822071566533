import ApexChart from "apexcharts";

const gridStyle = {
  show: true,
  xaxis: {
    lines: {
      show: false,
    },
  },
  yaxis: {
    lines: {
      show: true,
    },
  },
};

const chartStyle = {
  height: 350,
  type: "line",
  toolbar: {
    show: false,
  },
  animations: {
    enabled: false,
  },
};

const colorStyle = ["#212121", "#247BA0"];

const genStyle = {
  chart: chartStyle,
  dataLabels: {
    enabled: false,
  },
  grid: gridStyle,
  colors: colorStyle,
  stroke: {
    curve: "smooth",
    width: 4,
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};

export const chartOption: any = {
  his4: {
    ...genStyle,
    yaxis: {
      min: 5,
      max: 10,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        style: {
          colors: "#212121",
          fontSize: "16px",
          fontWeight: 500,
        },
        formatter: function (val: any) {
          return val.toFixed(1);
        },
      },
      title: {
        text: "Voltage (V)",
        style: {
          color: "#212121",
          fontSize: "14px",
        },
      },
    },
    xaxis: {
      tickAmount: 10,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        formatter: function (val: any) {
          return parseInt(val);
        },
        style: {
          colors: "#212121",
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      title: {
        text: "Time (Min.)",
        offsetY: -20,
        style: {
          color: "#212121",
          fontSize: "16px",
        },
      },
    },
  },
  his5: {
    ...genStyle,
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#212121",
          height: 1,
        },
        labels: {
          style: {
            colors: "#212121",
            fontSize: "18px",
            fontWeight: 500,
          },
          formatter: function (val: any) {
            if (val) {
              return val.toExponential(0);
            } else {
              return val;
            }
          },
        },
        title: {
          text: " -Zimag (ohm)",
          style: {
            color: "#212121",
            fontSize: "16px",
          },
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: "#247BA0",
          height: 1,
        },
        labels: {
          formatter: function (val: any) {
            if (val) {
              return val.toExponential(0);
            } else {
              return val;
            }
          },
          style: {
            colors: "#247BA0",
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        title: {
          text: " Fit1 - -Zimag (ohm)",
          style: {
            color: "#247BA0",
            fontSize: "16px",
          },
        },
      },
    ],
    xaxis: {
      tickAmount: 10,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        formatter: function (val: any) {
          if (val) {
            return val.toExponential(0);
          } else {
            return val;
          }
        },
        style: {
          fontSize: "18px",
          fontWeight: 500,
        },
      },
      title: {
        text: "Zreal (ohm)",
        offsetY: -20,
        style: {
          color: "#212121",
          fontSize: "16px",
        },
      },
    },
  },
  his6: {
    ...genStyle,
    yaxis: {
      min: 5,
      max: 10,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        style: {
          colors: "#212121",
          fontSize: "16px",
          fontWeight: 500,
        },
        formatter: function (val: any) {
          return val.toFixed(1);
        },
      },
      title: {
        text: "Voltage (V)",
        style: {
          color: "#212121",
          fontSize: "14px",
        },
      },
    },
    xaxis: {
      tickAmount: 10,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        formatter: function (val: any) {
          return parseInt(val);
        },
        style: {
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      title: {
        text: "Capacity (Ah)",
        offsetY: -20,
        style: {
          color: "#212121",
          fontSize: "16px",
        },
      },
      style: {
        color: "#212121",
        fontSize: "14px",
      },
    },
  },
  his62: {
    ...genStyle,
    yaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        style: {
          colors: "#212121",
          fontSize: "16px",
          fontWeight: 500,
        },
        formatter: function (val: any) {
          return parseFloat(val).toFixed(1);
        },
      },
      title: {
        text: "dQ/dV (Ah/V)",
        style: {
          color: "#212121",
          fontSize: "14px",
        },
      },
    },
    xaxis: {
      tickAmount: 10,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#212121",
        height: 1,
      },
      labels: {
        formatter: function (val: any) {
          return parseFloat(val).toFixed(2);
        },
        style: {
          fontSize: "16px",
          fontWeight: 500,
        },
      },
      title: {
        text: "Capacity (Ah)",
        offsetY: -20,
        style: {
          color: "#212121",
          fontSize: "16px",
        },
      },
      style: {
        color: "#212121",
        fontSize: "14px",
      },
    },
  },
};
