import { useNavigate, useRoutes } from "react-router-dom";

import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import { useEffect } from "react";
import PageNotFound from "../components/PageNotFound";
import Passport from "./Passport";
import ReportPDF2 from "../page/inspection/fieldComponants/CustomAction/Report2/ReportPDF2";
import BatteryReport from "../page/inspection/fieldComponants/CustomAction/BatteryReport/BatteryReport";
import BatteryReportDownload from "../page/inspection/fieldComponants/CustomAction/BatteryReport/BatteryReportDownload";
import ReportPDF_V2 from "../page/inspection/fieldComponants/CustomAction/Report2/ReportPDF_V2";

export default function ThemeRoutes() {
  const routes = useRoutes([
    {
      path: "/passport/:passport",
      element: <ReportPDF_V2 />,
    },
    {
      path: "/passport/v-2/:passport",
      element: <ReportPDF2 />,
    },
    {
      path: "/battery-report/:id",
      element: <BatteryReport />,
    },
    {
      path: "/battery-report-download/:id",
      element: <BatteryReportDownload />,
    },
  ]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (window.location.pathname === "/") {
  //     navigate("/login")
  //   }
  // }, [])

  return routes;
}
