import AuthLayout from "../layout/AuthLayout"
import Login from "../page/login"

const LoginRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
  ],
}

export default LoginRoutes
