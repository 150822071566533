import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

async function creatPdf({
  doc,
  elements,
  moduleName
}: {
  doc: jsPDF;
  elements: HTMLCollectionOf<Element>;
  moduleName:string
}) {
  const padding = 0;
  const marginTop = 0;
  let top = marginTop;


  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i) as HTMLElement;
    const imgData = await htmlToImage.toPng(el, { quality: 0.5 });

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;

    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding * 2;
      elWidth = elWidth * ratio - padding * 2;
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = marginTop;
    }

    doc.addImage(
      imgData,
      "PNG",
      padding,
      top,
      elWidth,
      elHeight,
      `image${i}`,
      "FAST"
    );
    console.log(" `image${i}`: ", `image${i}`);
    top += elHeight + marginTop;
  }
  console.log("doc: ", doc, doc.getNumberOfPages());
  if (doc.getNumberOfPages() === 5) {
    doc.deletePage(1);
  }
  doc.save(`${moduleName}.pdf`);
}
export async function exportMultipleChartsToPdf(moduleName: any) {
  const doc = new jsPDF("p", "px");

  const elements = document.getElementsByClassName("custom-chart");
  console.log("elements: ", elements);

  await creatPdf({ doc, elements, moduleName });
  return true;
}
