import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  handleModule,
  handleStep,
  resetStepData,
  resetToggle,
} from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { skipHisFour } from "../../../service/apiService";
import { fieldmap } from "../fieldmap";

const StatusField = ({
  data,
  step,
  value,
  status,
  loading,
  loadingSign,
  module,
  setHeader,
  onClick,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
  loading: boolean;
  loadingSign?: boolean;
  module?: number;
  setHeader?: any;
  onClick?: () => void;
}) => {
  const [fieldValue, setFieldValue] = React.useState(value);
  const [isRejected, setIsRejected] = React.useState(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [skipLoading, setSkipLoading] = React.useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = React.useState<string>("Accepted");

  const dispatch = useDispatch();
  const formData = useAppSelector((state: any) => state.inspection);

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [formData.toggle]);

  React.useEffect(() => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: "rejection_reason",
      })
    );
  }, [fieldValue]);

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: "rejection_reason",
      })
    );
    handleReset();
  };

  const handleReject = (type: boolean) => {
    setIsError(type);
    if (type) {
      setFieldInfo("Module is not acceptable");
    } else {
      setFieldInfo("Module is accepted");
    }

    dispatch(
      handleFieldData({
        step: step,
        value: type,
        key: "rejected",
      })
    );
    setIsRejected(type);
  };

  const handleReset = () => {
    const currentDataObj = { ...formData["formData"][step] };
    const objectKeys = Object.keys(currentDataObj);
    if (step === "step_18") {
      // for (let i = 0; i < objectKeys.length; i++) {
      //   const key = objectKeys[i];
      //   if (key === "kpi1") {
      //     continue;
      //   }
      //   currentDataObj[key] = "";
      // }
    } else {
      for (let i = 0; i < objectKeys.length; i++) {
        currentDataObj[objectKeys[i]] = "";
      }
    }

    dispatch(resetStepData({ step: step, value: currentDataObj }));
    setIsRejected(false);
    dispatch(resetToggle(!formData.toggle));
    // if (step === "step_18") {
    //   dispatch(handleModule(module ? module + 1 : 0 + 1));
    // }

    if (onClick) {
      onClick();
    }
  };

  const handleSkip = () => {
    setSkipLoading(true);
    const params = {
      battery_module: formData?.formData[step]?.kpi1?.trim(),
    };

    skipHisFour(params)
      .then((res) => {
        if (res?.status) {
          setHeader("");
          const currentDataObj = { ...formData["formData"][step] };
          const objectKeys = Object.keys(currentDataObj);
          for (let i = 0; i < objectKeys.length; i++) {
            currentDataObj[objectKeys[i]] = "";
          }
          dispatch(resetStepData({ step: step, value: currentDataObj }));
          setIsRejected(false);
          dispatch(resetToggle(!formData.toggle));
          fieldmap["step_18"].fields = [
            {
              key: "kpi1",
              name: "Scan Module ID",
              type: "HIS",
            },
          ];
        }
      })
      .catch((e) => {
        console.log("%c Line:134 🥒 e", "color:#ea7e5c", e);
      })
      .finally(() => setSkipLoading(false));
  };

  return (
    <>
      <FormControl variant="standard" className="sm-form-control">
        <InputLabel htmlFor="component-simple">
          {data.name}
          {data.description && (
            <Tooltip className="sm-tooltip" title={data.description} arrow>
              <Button>i</Button>
            </Tooltip>
          )}
          {data.validate && status && (
            <Tooltip
              className={`sm-tootip-true ${isError ? "sm-tootip-false" : ""}`}
              title={fieldInfo}
              arrow
            >
              <Button>
                {isError ? (
                  <ExclamationCircleOutlined />
                ) : (
                  <CheckCircleOutlined />
                )}
              </Button>
            </Tooltip>
          )}
        </InputLabel>
        <Button
          variant="contained"
          style={{ minWidth: "251px", maxWidth: "0" }}
          onClick={() => handleReject(true)}
          disabled={!(status || module === 5)}
        >
          Reject
        </Button>
        {isRejected ? (
          <Button variant="contained" onClick={() => handleReject(false)}>
            Cancel
          </Button>
        ) : !loading ? (
          <Button
            variant="contained"
            onClick={handleReset}
            disabled={!status || loadingSign}
          >
            Continue
          </Button>
        ) : (
          <Button variant="contained">
            <span className="report_loader"></span>Loading
          </Button>
        )}
      </FormControl>
      {module === 3 && (
        <FormControl variant="standard" className="sm-form-control">
          <InputLabel htmlFor="component-simple"> </InputLabel>

          <Button
            variant="contained"
            disabled={skipLoading}
            onClick={handleSkip}
          >
            SKIP
          </Button>
        </FormControl>
      )}
      {isRejected && (
        <FormControl variant="standard" className="sm-form-control">
          <InputLabel htmlFor="component-simple">
            Defect Code
            <Tooltip className="sm-tooltip" title="Defect Code" arrow>
              <Button>i</Button>
            </Tooltip>
          </InputLabel>
          <Select
            variant="outlined"
            size="medium"
            className="sm-form-select"
            onChange={(event) => setFieldValue(event.target.value)}
          >
            {data.option?.map((op: string, index: number) => (
              <MenuItem value={op} key={index}>
                {op}
              </MenuItem>
            ))}
          </Select>
          <Button variant="contained" onClick={handleSave}>
            Next
          </Button>
        </FormControl>
      )}
    </>
  );
};

export default React.memo(StatusField);
