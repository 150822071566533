import React, { useCallback, useEffect, useState } from "react";

import { NavStep } from "../../fieldComponants/NavStep";
import { Field } from "../../types";

import { useAppSelector } from "../../../../store/rtctype";
import { Step } from "../../types";
import SopHeader from "../../StepForms/sopHeader";
import { ScanField } from "../ScanField";
import InputField from "../InputField";
import SelectField from "../SelectField";
import FileField from "../FileField";
import FileReadField from "../FileReadField";
import { fieldmap, stepSop } from "../../fieldmap";
import StatusField from "../StatusField";
import CheckInput from "../CheckInput";
import {
  getSignUrl,
  qisModuleFour,
  qisModuleInspection,
  qisModuleOne,
  qisModulePackaging,
  qisModuleThree,
  qisModuleTwo,
} from "../../../../service/apiService";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import QISInput from "../QISInput";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../../reducers/inspectionReducer";
import ExcelUpload from "../ExcelUpload";

type Step27Data = {
  cyclePack: boolean | string;
  confirmedFreezerTestConducted: boolean | string;
  confirmedHeatingElementActivated: boolean | string;
  QIS: string;
  uploadDataFromCycler: string;
  packQcCapacityVoltageCanComm: string;
};
export default function QisForm({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const dispatch = useDispatch();
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loadingSign, setLoadingSign] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData[step]]);

  const errorHandle = (errors: any) => {
    const new_err = [];
    new_err.push(errors?.message);
    return new_err;
  };

  const handleSubmit = () => {
    if (step === "step_23") {
      setLoading(true);

      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS,
      };
      delete updateObj?.QIS;
      qisModuleInspection(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
      });
    }
    if (step === "step_24") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS,
      };
      delete updateObj?.QIS;
      qisModuleOne(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
      });
    }
    if (step === "step_25") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS,
      };
      delete updateObj?.QIS;
      qisModuleTwo(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
      });
    }
    if (step === "step_26") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS,
      };
      delete updateObj?.QIS;
      qisModuleThree(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
      });
    }
    if (step === "step_27") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS,
      };
      delete updateObj?.QIS;
      qisModuleFour(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
      });
    }
    if (step === "step_28") {
      setLoading(true);
      const updateObj = {
        ...formData[step],
        packId: formData[step]?.QIS,
      };
      delete updateObj?.QIS;
      qisModulePackaging(updateObj)?.then((res) => {
        if (!res.success) {
          setParamError(errorHandle(res.message));
          setErOpen(true);
        } else {
          if (res?.data?.status) {
            toast?.success(res?.data?.message);
          } else {
            toast?.error(res?.data?.message);
          }
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (step === "step_27") {
      if (
        formData["step_27"]?.uploadDataFromCycler &&
        formData.step_27?.uploadDataFromCycler instanceof File
      ) {
        const data: any = formData["step_27"];
        const vFiles = data?.uploadDataFromCycler;
        setLoading(true);
        getSignUrl({
          fileName: data?.uploadDataFromCycler?.name,
          his: "QIS-4",
        }).then((res: any) => {
          if (res?.success && res?.data && res?.data?.data) {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", res?.data?.data?.signUrls, true);
            xhr.setRequestHeader("Content-Type", vFiles?.type);

            xhr.onreadystatechange = () => {
              if (
                xhr.readyState === XMLHttpRequest.DONE &&
                xhr.status === 200
              ) {
                setLoading(false);
                dispatch(
                  handleFieldData({
                    step: "step_27",
                    value: res?.data?.data?.url,
                    key: "uploadDataFromCycler",
                  })
                );
              }
            };

            xhr.send(vFiles);
          }
        });
      }
    }
    if (step === "step_28") {
      if (
        formData["step_28"]?.photoStation &&
        formData.step_28?.photoStation instanceof File
      ) {
        if (formData["step_28"]?.photoStation.type.startsWith("image/")) {
          const data: any = formData["step_28"];
          const vFiles = data?.photoStation;
          getSignUrl({ fileName: data?.photoStation?.name, his: "QIS-P" }).then(
            (res: any) => {
              if (res?.success && res?.data && res?.data?.data) {
                const xhr = new XMLHttpRequest();
                xhr.open("PUT", res?.data?.data?.signUrls, true);
                xhr.setRequestHeader("Content-Type", vFiles?.type);

                xhr.onreadystatechange = () => {
                  if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.status === 200
                  ) {
                    dispatch(
                      handleFieldData({
                        step: "step_28",
                        value: res?.data?.data?.url,
                        key: "photoStation",
                      })
                    );
                  }
                };

                xhr.send(vFiles);
              }
            }
          );
        }
      }
    }
  }, [
    formData["step_27"]?.uploadDataFromCycler,
    formData["step_28"]?.photoStation,
  ]);

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={fieldmap[step]?.title} link={""} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {fieldmap[step] &&
                fieldmap[step]?.fields.map((field: Field, index: number) => {
                  if (field.type === "field") {
                    return (
                      <InputField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "fieldQIS") {
                    return (
                      <QISInput
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        // loading={loadingGet}
                        value={formData[step][field.key]}
                        key={field.key}
                        // handleClick={handleGetData}
                      />
                    );
                  } else if (field.type === "checkbox") {
                    return (
                      <CheckInput
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        loading={loading}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "select") {
                    return (
                      <SelectField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "excel") {
                    return (
                      <ExcelUpload
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step][field.key]}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "file") {
                    return (
                      <FileField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        // loading={loadingGet}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "reset") {
                    return (
                      <NavStep
                        step={step}
                        data={field}
                        loading={loading}
                        status={index <= currentActive}
                        key={field.key}
                        onClick={handleSubmit}
                      />
                    );
                  }

                  return null;
                })}
            </div>
          </div>
        </div>
      </div>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />
    </React.Fragment>
  );
}
