import React, { useEffect, useState } from "react";
import SideBar from "./Sidebar";
import Header from "./Header";
import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/rtctype";
import { getPermission, getUser } from "../../service/apiService";
import {
  handleLoading,
  handlePermissionUser,
  handleSetUser,
} from "../../reducers/authReducer";
import Spiner from "../../components/Spiner";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import SupportModal from "../../page/inspection/fieldComponants/SupportModal";

const MainLayout = () => {
  const location = useLocation();
  // console.log('location: ', location);
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.user).user;
  const { permission, loading } = useAppSelector((state) => state.user);
  // console.log("%c Line:23 🍑 permission", "color:#ea7e5c", permission);
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [noAccess, setNoAccess] = useState(false);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    const authToken = localStorage.getItem("refreshToken");
    if (!userData && authToken) {
      getUser(authToken).then((res) => {
        if (res.status) {
          dispatch(handleSetUser(res.data));
        }
      });
    } else if (!authToken) {
      if (!window.location.hash) {
        navigate("/login");
      }
    }
  }, [location.pathname]);
  React.useEffect(() => {
    if (!permission) {
      const authToken = localStorage.getItem("accessToken") || "";
      setLoader(true);
      dispatch(handleLoading(true));
      getPermission(authToken)
        .then((obj: any) => {
          // console.log("%c Line:53 🍇 obj", "color:#93c0a4", obj);
          if (obj?.success) {
            dispatch(handlePermissionUser(obj?.data?.role?.permission));
          }
        })
        .catch((e) => {
          console.log("%c Line:56 🍇 e", "color:#42b983", e);
        })
        .finally(() => {
          dispatch(handleLoading(false));
          setLoader(false);
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (permission && permission.length > 0) {
      const allNoAccess = permission.every((p: any) => !p.read);
      setNoAccess(allNoAccess);
    }
  }, [permission]);

  return (
    <>
      {" "}
      {loading || loader ? (
        <Spiner />
      ) : (
        <Box
          sx={{
            width: "100%",
            padding: 0,
            boxSizing: "border-box",
            height: "100vh",
          }}
        >
          {!window.location.hash && <Header />}
          {noAccess ? (
            <div className="noAccess">
              No Access for this Id Contact Admin for Access
            </div>
          ) : (
            <Box sx={{ display: "flex", width: "100vw", flexDirection: "row" }}>
              {/* <SideBar /> */}
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: { xs: 2, sm: 3 },
                  height: "calc( 100vh  - 80px)",
                  overflow: "auto",
                }}
              >
                <Outlet />
              </Box>
            </Box>
          )}
          {!window.location.hash && (
            <div
              onClick={() => setOpen(!open)}
              style={{
                background: "#3498d2",
                padding: "10px 13px",
                borderRadius: "50%",
                width: "fit-content",
                position: "absolute",
                bottom: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <TfiHeadphoneAlt
                style={{
                  fill: "white",
                  width: "fit-content",
                  height: "fit-content",
                }}
              />
              <SupportModal open={open} setOpen={setOpen} />
            </div>
          )}
        </Box>
      )}
    </>
  );
};

export default MainLayout;
