import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const SpinnerWrapper = styled("div")(({ theme }) => ({
  height: "100vh",
  backgroundColor: "rFgba(255,255,255,0.3)",
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  display: "grid",
  placeItems: "center",
  zIndex: "1",
  backdropFilter: "blur(3px)",
}));

const Spiner = () => (
  <>
    <SpinnerWrapper>
      <CircularProgress sx={{ color: "#3498d2" }} />
    </SpinnerWrapper>
  </>
);

export default Spiner;
