import React, { useState } from "react";
import QRCode from "react-qr-code";
import { TextField } from "@mui/material";

export default function QRGeneration() {
  const [qrvalue, setQRValue] = useState<string>("samsar");

  return (
    <React.Fragment>
      <div style={{ background: "white", padding: "16px" }}>
        <QRCode value={qrvalue} />
      </div>
      <div className="qrtext_input">
        <TextField
          value={qrvalue}
          label="QR Text"
          variant="outlined"
          onChange={(event) => setQRValue(event.target.value)}
        />
      </div>
    </React.Fragment>
  );
}
