// src/page/inspection/fieldComponants/NavStep.tsx

import React from "react";
import { Button, FormControl } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/rtctype";
import {
  handleStep,
  resetStepData,
  resetToggle,
} from "../../../reducers/inspectionReducer";
import { Field } from "../types";

export const NavStep = ({
  step,
  data,
  status,
  loading,
  onClick, // Add this prop
}: {
  step: string;
  data: Field;
  status: boolean;
  loading?: boolean;
  onClick?: () => void; // Make it optional
}) => {
  const formData = useAppSelector((state: any) => state.inspection);
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(handleStep(stepHandler(step)));
  };

  const stepHandler = (step: string) => {
    if (step) {
      const stepArr: any = step.split("_");
      stepArr[1] = parseInt(stepArr[1]) + 1;
      return stepArr.join("_");
    }
  };

  const handleReset = () => {
    const currentDataObj = { ...formData["formData"][step] };
    const objectKeys = Object.keys(currentDataObj);
    for (let i = 0; i < objectKeys.length; i++) {
      if (typeof currentDataObj[objectKeys[i]] === "boolean") {
        currentDataObj[objectKeys[i]] = false;
      } else {
        currentDataObj[objectKeys[i]] = "";
      }
    }
    dispatch(resetStepData({ step: step, value: currentDataObj }));
    dispatch(resetToggle(!formData.toggle));

    // Call the passed function if it's provided
    if (onClick) {
      onClick();
    }
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      {data.type === "next" ? (
        <Button variant="contained" onClick={handleContinue} disabled={!status}>
          {data.name}
        </Button>
      ) : data.type === "reset" && !loading ? (
        <Button variant="contained" onClick={handleReset} disabled={!status}>
          {data.name}
        </Button>
      ) : (
        <Button variant="contained">
          <span className="report_loader"></span>Loading
        </Button>
      )}
    </FormControl>
  );
};
