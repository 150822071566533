import React, { useState } from "react";
import { Button, Drawer, InputLabel } from "@mui/material";
import BlueLogo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useAppSelector } from "../../../store/rtctype";
import { useDispatch, useSelector } from "react-redux";
import { handleStep } from "../../../reducers/inspectionReducer";
import {
  handlePermissionUser,
  handleSetUser,
} from "../../../reducers/authReducer";
import MenuIcon from "@mui/icons-material/Menu";
import { ProgressSidebar } from "../../../page/inspection/ProgressSidebar";
// import styled from "@emotion/styled";
import { styled, Theme } from "@mui/material/styles";
const StyledDrawer = styled(Drawer)(({ theme }: { theme: Theme }) => ({
  "& .MuiDrawer-paper": {
    width: "80vw",
    [theme?.breakpoints.up("sm")]: {
      width: "45vw",
    },
    overflowY: "scroll",
    overflowX: "hidden",
    paddingInline: "10px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  "& .MuiDrawer-paper::-webkit-scrollbar": {
    display: "none",
  },
}));
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useAppSelector((state) => state.user).user;
  const packId = useAppSelector((state: any) => state.inspection).packId;
  const { permission } = useSelector((state: any) => state.user);

  const [open, setOpen] = useState<boolean>(false);
  const handleLogout = async () => {
    dispatch(handleStep("step_14"));
    dispatch(handleSetUser(null));
    dispatch(handlePermissionUser(null));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  return (
    <React.Fragment>
      <div className="haeder_wrapper">
        <div className="container">
          <div className="row">
            <div className="smr-logo">
              <img src={BlueLogo} alt="samsar-logo-blue" />
            </div>
            {permission?.find(
              (module: any) => module?.module === "QIS Operation"
            )?.read && (
              <InputLabel
                htmlFor="component-simple"
                style={{ marginBlock: "auto", color: "#3498d2" }}
              >
                {/* Pack ID : XXXXXX-XXX-XXX-XXX */}
                {packId ? `Pack ID: ${packId}` : `Pack ID: XXXXXX-XXX-XXX-XXX`}
              </InputLabel>
            )}

            <div className="login_logout">
              <span className="user-name">User : {userData?.username}</span>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Button onClick={handleLogout}>
                  <LogoutRoundedIcon />
                </Button>
                <Button className="menu-btn" onClick={() => setOpen(!open)}>
                  <MenuIcon />
                </Button>
              </div>
            </div>
          </div>
          <StyledDrawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
          >
            <div className="sm-stepForm-wrapper">
              <div className="row">
                <ProgressSidebar type="Drawer" open={open} setOpen={setOpen} />
              </div>
            </div>
          </StyledDrawer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
