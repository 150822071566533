import React, { useRef, useState, useCallback, useEffect } from "react";
import { Button, Modal } from "@mui/material";
import Webcam from "react-webcam";
import { useAppSelector } from "../../../store/rtctype";
import { handleFocus } from "../../../reducers/inspectionReducer";
import { useDispatch } from "react-redux";

type ModelProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setFieldValue: (value: any) => void;
  fieldValue?: any;
};

const videoConstraints = {
  width: 720,
  height: 360,
  facingMode: "user",
};
const Webcame: React.FC<ModelProps> = ({
  open,
  setOpen,
  setFieldValue,
  fieldValue,
}) => {
  const [isCaptureEnable, setCaptureEnable] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);
  const { focus } = useAppSelector((state: any) => state.inspection);
  const dispatch = useDispatch();
  // const [url, setUrl] = useState<string | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc: any = webcamRef.current?.getScreenshot();
      // if (imageSrc) {
      // setUrl(imageSrc);
      // setCaptureEnable(true);
      setCaptureEnable(false);
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob: any) => {
          const file = new File(
            [blob],
            `${Math.floor(Date.now() / 1000)}.jpg`,
            {
              type: "image/png",
            }
          );
          dispatch(handleFocus(!focus));
          setFieldValue(file);
          setOpen(false);
        });
      // setCaptureEnable(true);
      // setUrl(null);
      // setOpen(false)
      // onHandle(imageSrc);
      // }
    }
  }, [webcamRef]);

  // useEffect(() => {
  //   if (open) {
  //     setCaptureEnable(true);
  //   }
  // }, [open]);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && isCaptureEnable) {
      capture();
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="general_popup validation_popup" onKeyDown={handleKeyDown}>
        <div className="modal_header">
          <h1>Web Cam</h1>
        </div>
        {/* {isCaptureEnable || (
          <button onClick={() => setCaptureEnable(true)}>start</button>
        )} */}
        {!fieldValue && (
          <>
            <div>
              <Webcam
                audio={false}
                // width={540}
                // height={360}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={() => setCaptureEnable(true)}
              />
            </div>
          </>
        )}
        {fieldValue && (
          <>
            <div>
              <img src={fieldValue} alt="Screenshot" />
            </div>
          </>
        )}

        <div className="modal_footer" style={{ display: "flex", gap: "10px" }}>
          {fieldValue ? (
            <Button
              variant="contained"
              onClick={() => {
                // setUrl(null);
                // setCaptureEnable(true);
                setFieldValue("");
              }}
            >
              Re-try
            </Button>
          ) : (
            <Button variant="contained" onClick={capture}>
              capture
            </Button>
          )}

          {/* <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              setCaptureEnable(false);
              setUrl(null);
            }}
          >
            Done
          </Button> */}
        </div>
      </div>
    </Modal>
  );
};

export default Webcame;
