import React from 'react'
import logo from "../../../../assets/images/logo-trademark.png";
function Header() {
    return (
        <>
            <div
                style={{
                    backgroundColor: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0px 50px",
                    height: "80px",
                }}
            >

                <div style={{
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}>
                    <img src={logo} alt="logo" style={{ height: "50px", width: "120px" }} />
                </div>


                <div style={{
                    width: "65%"
                }}>
                    <h1 style={{
                        color: "#3498d2",
                        fontSize: "25px",

                    }}>Battery State of Health Report</h1>
                </div>

            </div>
        </>
    )
}

export default Header