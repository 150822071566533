import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "./styles";

const PDFContent = ({
  hisData,
  reportData,
  module_id,
}: {
  hisData: any;
  reportData: any;
  module_id: string;
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.smPageInner}>
          <View
            style={{
              backgroundColor: "#212121",
              padding: "15px 20px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Image
              style={{ width: "100px", maxWidth: "30%" }}
              src={require(`../../assets/images/logo-trademark.png`)}
            />
            <Text style={styles.smPdfheading}>
              Battery Certification Report
            </Text>
          </View>
          <Text style={styles.smPdfSubheading}>{module_id}</Text>
          <View style={{ ...styles.section }}>
            <Text style={styles.contentText}>
              <Text style={{ fontWeight: 600, fontSize: "12px" }}>
                Description
              </Text>
              :- The Device Under Test is a {reportData.oem}{" "}
              {reportData.oem_model} which was deemed fit for further evaluation
              at an Open Circuit Voltage of {reportData.ocv}
              CV with an internal ACIR at 1kHz equal to {reportData.acir}ACIR.
              Below are the snapshot of the battery module followed by its
              health analysis.
            </Text>
          </View>
          <View style={{ padding: "0 10px" }}>
            <View style={styles.smNBCRow}>
              {reportData.images &&
                Object.keys(reportData.images).map((image, index) => {
                  return (
                    <View style={styles.smNBCimageCol} key={index}>
                      <View style={styles.smNBCimageInner}>
                        <Image
                          style={styles.smNBCimage}
                          src={reportData.images[image]}
                        />
                      </View>
                    </View>
                  );
                })}
            </View>
          </View>
          <View style={{ padding: "0 10px" }}>
            <View style={styles.rowSection}>
              <View style={styles.smColmn_6}>
                <View style={styles.innerSecRow}>
                  <Text
                    style={{
                      ...styles.headingText,
                      ...styles.fixWidth,
                      ...styles.blueText,
                    }}
                  >
                    Rated Specs
                  </Text>
                  <Text style={{ ...styles.headingText, ...styles.blueText }}>
                    Value
                  </Text>
                </View>
                <View>
                  <View style={styles.innerSecRow}>
                    <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                      Rated Capacity @C/3, 25°C
                    </Text>
                    <Text style={styles.secondText}>
                      {reportData.rated_capacity} Ah
                    </Text>
                  </View>
                  <View style={styles.innerSecRow}>
                    <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                      Voltage : Minimum
                    </Text>
                    <Text style={styles.secondText}>8.4 V</Text>
                  </View>
                  <View style={styles.innerSecRow}>
                    <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                      Voltage : Maximum
                    </Text>
                    <Text style={styles.secondText}>7.2 V</Text>
                  </View>
                  <View style={styles.innerSecRow}>
                    <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                      Voltage : Nominal
                    </Text>
                    <Text style={styles.secondText}>5 V</Text>
                  </View>
                </View>
              </View>
              <View style={styles.smColmn_6}>
                <View style={styles.innerSecRow}>
                  <Text
                    style={{
                      ...styles.headingText,
                      ...styles.fixWidth,
                      ...styles.blueText,
                    }}
                  >
                    Estimated Specifications
                  </Text>
                  <Text style={{ ...styles.headingText, ...styles.blueText }}>
                    Value
                  </Text>
                </View>
                <View>
                  <View style={styles.innerSecRow}>
                    <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                      Observed Capacity @C/3, 25°C
                    </Text>
                    <Text style={styles.secondText}>
                      {reportData.obresved_capacity.toFixed(2)} Ah
                    </Text>
                  </View>
                  <View style={styles.innerSecRow}>
                    <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                      Estimated SOH
                    </Text>
                    <Text style={styles.secondText}>
                      {reportData.estimated_eoh.toFixed(2)} %
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "1.2",
              fontWeight: 500,
              position: "absolute",
              bottom: "-20px",
              right: "20px",
            }}
          >
            01
          </Text>
        </View>
        <View break style={{ ...styles.smPageInner, ...styles.smPageInner2 }}>
          <View style={{ ...styles.rowSection, ...styles.marinBottom }}>
            <Text style={{ ...styles.chartBoxHeading, ...styles.widthFull }}>
              Energy/Capacity
            </Text>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_8 }}>
              <View style={styles.smChartBox}>
                <View style={styles.chartBoxInner}>
                  <Image src={hisData.his6} style={styles.chartImg} />
                </View>
              </View>
            </View>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_4 }}>
              <View style={styles.innerSecRow}>
                <Text
                  style={{
                    ...styles.headingText,
                    ...styles.fixWidth,
                    ...styles.blueText,
                  }}
                >
                  State of Health Parameter
                </Text>
                <Text style={{ ...styles.headingText, ...styles.blueText }}>
                  Value
                </Text>
              </View>
              <View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Charge Capacity of 1st cycle (Ah)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.charge_capacities[0].toFixed(1)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Charge Capacity 2nd Cycle (Ah)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.charge_capacities[1].toFixed(1)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Charge Capacity 3rd Cycle (Ah)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.charge_capacities[2].toFixed(1)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ ...styles.rowSection, ...styles.marinBottom }}>
            <Text style={{ ...styles.chartBoxHeading, ...styles.widthFull }}>
              Power/Rate Capability
            </Text>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_8 }}>
              <View style={styles.smChartBox}>
                <View style={styles.chartBoxInner}>
                  <Image src={hisData.his4} style={styles.chartImg} />
                </View>
              </View>
            </View>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_4 }}>
              <View style={styles.innerSecRow}>
                <Text
                  style={{
                    ...styles.headingText,
                    ...styles.fixWidth,
                    ...styles.blueText,
                  }}
                >
                  State of Health Parameter
                </Text>
                <Text style={{ ...styles.headingText, ...styles.blueText }}>
                  Value
                </Text>
              </View>
              <View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    DCIR @ 15A (V)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.dcir_values[0].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    DCIR @ 30A (V)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.dcir_values[1].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    DCIR @ 60A (V)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.dcir_values[2].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Relaxation Factor @ rest after 15A Pulse
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.relaxation_factors[0].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Relaxation Factor @ rest after 30 A Pulse
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.relaxation_factors[1].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Relaxation Factor @ rest after 60A Pulse
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.relaxation_factors[2].toFixed(3)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "1.2",
              fontWeight: 500,
              position: "absolute",
              bottom: "-20px",
              right: "20px",
            }}
          >
            02
          </Text>
        </View>
        <View break style={{ ...styles.smPageInner, ...styles.smPageInner2 }}>
          <View style={{ ...styles.rowSection, ...styles.marinBottom }}>
            <Text style={{ ...styles.chartBoxHeading, ...styles.widthFull }}>
              Electrochemical Impedance Spectroscopy(EIS)
            </Text>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_8 }}>
              <View style={styles.smChartBox}>
                <View style={styles.chartBoxInner}>
                  <Image src={hisData.his5} style={styles.chartImg} />
                </View>
              </View>
            </View>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_4 }}>
              <View style={styles.innerSecRow}>
                <Text
                  style={{
                    ...styles.headingText,
                    ...styles.fixWidth,
                    ...styles.blueText,
                  }}
                >
                  State of Health Parameters
                </Text>
                <Text style={{ ...styles.headingText, ...styles.blueText }}>
                  Value
                </Text>
              </View>
              <View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Yo8 (S*s^a)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["Yo8"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    A9
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["a9"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    C2 (F)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["C2"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Rs (ohm)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["Rs"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    L6 (H)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["L6"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    R2 (ohm)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["R2"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    W9 (S*s^(1/2))
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["W9"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    R1 (ohm)
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["R1"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    Goodness of Fit
                  </Text>
                  <Text style={styles.secondText}>
                    {reportData.acir_parameters["Goodness of Fit"].toFixed(3)}
                  </Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    5ECM
                  </Text>
                  <Text style={styles.secondText}>-</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ ...styles.rowSection }}>
            <Text style={{ ...styles.chartBoxHeading, ...styles.widthFull }}>
              Differential Voltage Analysis (DVA)
            </Text>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_8 }}>
              <View style={styles.smChartBox}>
                <View style={styles.chartBoxInner}>
                  <Image src={hisData.his62} style={styles.chartImg} />
                </View>
              </View>
            </View>
            <View style={{ ...styles.smColmn_6, ...styles.smColmn_4 }}>
              <View style={styles.innerSecRow}>
                <Text
                  style={{
                    ...styles.headingText,
                    ...styles.fixWidth,
                    ...styles.blueText,
                  }}
                >
                  State of Health Parameter
                </Text>
                <Text style={{ ...styles.headingText, ...styles.blueText }}>
                  Value
                </Text>
              </View>
              <View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    (dQ/dV , V) 1st Cycle 1st Trough
                  </Text>
                  <Text style={styles.secondText}>00.1</Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    (dQ/dV , V) 2nd Cycle 1st Trough
                  </Text>
                  <Text style={styles.secondText}>00.1</Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    (dQ/dV , V) 3rd Cycle 1st Trough
                  </Text>
                  <Text style={styles.secondText}>00.1</Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    (dQ/dV , V) 1st Cycle 1st Peak
                  </Text>
                  <Text style={styles.secondText}>00.2</Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    (dQ/dV , V) 1st Cycle 2nd Peak
                  </Text>
                  <Text style={styles.secondText}>00.2</Text>
                </View>
                <View style={styles.innerSecRow}>
                  <Text style={{ ...styles.secondText, ...styles.fixWidth }}>
                    (dQ/dV , V) 1st Cycle 3rd Peak
                  </Text>
                  <Text style={styles.secondText}>00.2</Text>
                </View>
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "1.2",
              fontWeight: 500,
              position: "absolute",
              bottom: "-20px",
              right: "20px",
            }}
          >
            03
          </Text>
        </View>
        <View break style={{ ...styles.smPageInner, ...styles.smPageInner2 }}>
          <View>
            <Text
              style={{
                color: "#3498d2",
                fontSize: "18px",
                lineHeight: "1.3",
                fontWeight: 500,
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Terms and Conditions
            </Text>
            <Text style={styles.termsText}>
              SAMSAR RESOURCES EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR ALL
              DAMAGE, INJURY, HARM, COST, EXPENSE, OR LIABILITY ARISING OUT OF
              OR RELATED, IN WHOLE OR IN PART, TO THE USE OR MISUSE OF THE
              BATTERY WHICH IS SUBJECT TO THIS STATE OF HEALTH. TO THE FULLEST
              EXTENT ALLOWABLE BY APPLICABLE LAW, THIS STATE OF HEALTH IS
              PROVIDED WITHOUT WARRANTY OF ANY KIND -- WHETHER EXPRESS, IMPLIED,
              OR STATUTORY --INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
              MERCHANTABILITY AND WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE.
            </Text>
            <Text style={styles.termsText}>
              SAMSAR RESOURCES IS NOT AND WILL NOT BE LIABLE UNDER ANY LEGAL
              THEORY – INCLUDING BUT NOT LIMITED TO ACTIONS SOUNDING WARRANTY,
              CONTRACT, STRICT LIABILITY, TORT, NEGLIGENCE, AND/OR
              MISREPRESENTATION - - FOR ANY FOR ANY AND ALL DAMAGE, INJURY,
              HARM, COST, EXPENSE, OR LIABILITY ARISING OUT OF OR RELATED, IN
              WHOLE OR IN PART, TO THE USE OR MISUSE OF THE BATTERY WHICH IS
              SUBJECT TO THIS STATE OF HEALTH.
            </Text>
            <Text style={styles.termsText}>
              SAMSAR RESOURCES IS NOT AND WILL NOT BE LIABLE FOR DAMAGE TO
              PROPERTY, LOSS OF BUSINESS, LOSS OF INCOME, SPECIAL DAMAGES,
              INCIDENTAL DAMAGES, CONSEQUENTIAL DAMAGES, PUNITIVE DAMAGES, OR
              EXEMPLARY DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE.
            </Text>
            <Text style={styles.termsText}>
              IN NO EVENT WILL SAMSAR RESOURCES’ LIABILITY EXCEED THE VALUE OF
              THE LI-ION BATTERY FOR WHICH THIS STATEMENT OF HEALTH IS ISSUED.
            </Text>
            <Text style={styles.termsText}>
              IMPORTANT: PLEASE READ AND FOLLOW ALL SAFETY WARNINGS AND
              ADVISORIES ISSUED BY THE MANUFACTURER OF THE BATTERY FOR WHICH
              THIS HEALTH CERTIFICATION IS ISSUED. CONTACT THE MANUFACTURER TO
              OBTAIN ALL SUCH SAFETY WARNINGS AND ADVISORIES.
            </Text>
          </View>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "1.2",
              fontWeight: 500,
              position: "absolute",
              bottom: "40px",
              right: "20px",
            }}
          >
            04
          </Text>
        </View>
        <View
          style={{
            width: "100%",
            padding: "20px 0",
            position: "absolute",
            bottom: 0,
            backgroundColor: "#212121",
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#3498d2",
              fontSize: "20px",
              lineHeight: "1.3",
              textAlign: "center",
            }}
          >
            Affordable. Sustainable. Connected.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFContent;
