import { Button, FormControl } from "@mui/material";
import { binnigSortingService } from "../../../../service/apiService";
import { successToast } from "../../serviceCallback";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import { useState } from "react";

export const ConfirmBinBtn = ({
  status,
  module_id,
  bin_id,
  handleSubmit,
}: {
  status: boolean;
  module_id: string;
  bin_id: string;
  handleSubmit: () => void;
}) => {
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const getBinDetails = () => {
    const authToken = localStorage.getItem("accessToken") || "";
    const value = module_id;
    const trimmedValue = value.trim();
    const details = {
      module_id: trimmedValue,
      outgoing_bin_id: bin_id,
    };
    setLoading(true);
    console.log(details);
    binnigSortingService(authToken, details).then((res: any) => {
      if (res.success) {
        successToast("Module moved to bin successfully!");
        setLoading(false);
      } else {
        setErOpen(true);
        setLoading(false);
        setParamError([res?.message?.message]);
      }
    });
  };

  return (
    <>
      {" "}
      <FormControl variant="standard" className="sm-form-control">
        {!loading ? (
          <Button variant="contained" onClick={getBinDetails} disabled={status}>
            Confirm Bin ID
          </Button>
        ) : (
          <Button variant="contained">
            <span className="report_loader"></span>Loading
          </Button>
        )}
      </FormControl>
      <ValidationModel
        open={eropen}
        setOpen={setErOpen}
        validation={paramError}
      />
    </>
  );
};
