import {lazy} from "react"

import Loadable from "../components/Loadable"
import MainLayout from "../layout/MainLayout"
import QRGeneration from "../page/qrgeneration"
import {SamsarInspection} from "../page/inspection"
import ReportPDF2 from "../page/inspection/fieldComponants/CustomAction/Report2/ReportPDF2"

const Projection = Loadable(lazy(() => import("../page/projection")))

const Passport = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/:passport",
      element: <ReportPDF2 />,
    },
  ],
}

export default Passport
