// src/reducer/sidebarReducer.tsx
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dataInitState {
  [key: string]: any;
}

const initialState: dataInitState = {
  sidebar: true,
};

export const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    handleSideBar: (state, action: PayloadAction<any>) => {
      state[action.payload["type"]] = action.payload["value"];
    },
  },
});

export const { handleSideBar } = sideBarSlice.actions;
export default sideBarSlice.reducer;
