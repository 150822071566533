import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  handleFieldData,
  handleModule,
  handleStep,
  resetStepData,
  resetToggle,
} from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const SubmitField = ({
  data,
  step,
  value,
  status,
  loading,
  onClick,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
  loading: boolean;
  onClick?: () => void;
}) => {
  const [fieldValue, setFieldValue] = React.useState(value);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = React.useState<string>("Accepted");

  const dispatch = useDispatch();
  const formData = useAppSelector((state: any) => state.inspection);

  const handleSave = () => {
    // dispatch(
    //   handleFieldData({
    //     step: step,
    //     value: fieldValue,
    //     key: "rejection_reason",
    //   })
    // );

    if (onClick) {
      onClick();
    }
  };

  const handleReject = (type: boolean) => {
    setIsError(type);
    if (type) {
      setFieldInfo("Module is not acceptable");
    } else {
      setFieldInfo("Module is accepted");
    }

    dispatch(
      handleFieldData({
        step: step,
        value: type,
        key: "rejected",
      })
    );
  };

  const handleReset = () => {
    const currentDataObj = { ...formData["formData"][step] };

    const objectKeys = Object.keys(currentDataObj);
    console.log("%c Line:81 🍩 objectKeys", "color:#ed9ec7", objectKeys);
    for (let i = 0; i < objectKeys.length; i++) {
      currentDataObj[objectKeys[i]] = "";
    }
    dispatch(resetStepData({ step: step, value: currentDataObj }));
    dispatch(resetToggle(!formData.toggle));
  };

  return (
    <>
      <FormControl
        variant="standard"
        className="sm-form-control submitField-btn"
        // style={{ width: "49%", marginLeft: "auto" }}
      >
        <Button
          variant="contained"
          onClick={() => handleReset()}
          disabled={!status}
        >
          Reset
        </Button>
        {!loading ? (
          <Button variant="contained" onClick={handleSave} disabled={!status}>
            Continue
          </Button>
        ) : (
          <Button variant="contained">
            <span className="report_loader"></span>Loading
          </Button>
        )}
      </FormControl>
    </>
  );
};

export default React.memo(SubmitField);
