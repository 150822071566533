import React, { useState, useEffect, memo } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { validationHandle } from "../fieldmap";

const CheckInput = ({
  data,
  step,
  value,
  status,
  loading,
}: {
  data: Field;
  step: string;
  value: boolean;
  status: boolean;
  loading: boolean;
}) => {
  const dispatch = useDispatch();
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const [fieldValue, setFieldValue] = useState<boolean>(value);
  const [isError, setIsError] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<string>("");

  useEffect(() => {
    setFieldValue(value);
  }, [value, reset, loading]);

  useEffect(() => {
    handleSave();
  }, [fieldValue]);

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (data.validate) {
      const { isError, infoDetails } = validationHandle(
        data.validate,
        isChecked.toString(),
        data
      );
      setIsError(isError);
      setFieldInfo(infoDetails);
    }
    setFieldValue(isChecked);
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
        {data.validate && fieldValue && (
          <Tooltip
            className={`sm-tooltip-true ${isError ? "sm-tooltip-false" : ""}`}
            title={fieldInfo}
            arrow
          >
            <Button>
              {isError ? (
                <ExclamationCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )}
            </Button>
          </Tooltip>
        )}
      </InputLabel>
      {loading ? (
        <>
          <Checkbox
            disabled={true}
            id={data.key}
            checked={false}
            onChange={handleInputChange}
          />
        </>
      ) : (
        <Checkbox
          disabled={!status}
          id={data.key}
          checked={fieldValue}
          onChange={handleInputChange}
        />
      )}
    </FormControl>
  );
};

export default memo(CheckInput);
