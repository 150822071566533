import { jsoninstancenew } from ".";

type LoginFormValues = {
  username: string;
  password: string;
};

export const login = (param: LoginFormValues) => {
  return jsoninstancenew
    .post("/api/token", param)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return false;
    });
};
